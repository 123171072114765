import * as React from "react";

function HarddriveIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="HardDrives">
        <path
          id="Vector"
          d="M16.25 11.25H3.75C3.40482 11.25 3.125 11.5298 3.125 11.875V15.625C3.125 15.9702 3.40482 16.25 3.75 16.25H16.25C16.5952 16.25 16.875 15.9702 16.875 15.625V11.875C16.875 11.5298 16.5952 11.25 16.25 11.25Z"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M16.25 3.75H3.75C3.40482 3.75 3.125 4.02982 3.125 4.375V8.125C3.125 8.47018 3.40482 8.75 3.75 8.75H16.25C16.5952 8.75 16.875 8.47018 16.875 8.125V4.375C16.875 4.02982 16.5952 3.75 16.25 3.75Z"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M14.0625 7.1875C14.5803 7.1875 15 6.76777 15 6.25C15 5.73223 14.5803 5.3125 14.0625 5.3125C13.5447 5.3125 13.125 5.73223 13.125 6.25C13.125 6.76777 13.5447 7.1875 14.0625 7.1875Z"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
        />
        <path
          id="Vector_4"
          d="M14.0625 14.6875C14.5803 14.6875 15 14.2678 15 13.75C15 13.2322 14.5803 12.8125 14.0625 12.8125C13.5447 12.8125 13.125 13.2322 13.125 13.75C13.125 14.2678 13.5447 14.6875 14.0625 14.6875Z"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
        />
      </g>
    </svg>
  );
}

export default HarddriveIcon;
