import React from "react";

function ShopIcon(props) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M30.5414 25.775L28.7539 9.77504C28.7013 9.28557 28.4689 8.83305 28.1017 8.50513C27.7345 8.1772 27.2587 7.99722 26.7664 8.00004H22.5789C22.3383 6.6064 21.6131 5.34256 20.5313 4.43157C19.4495 3.52058 18.0807 3.021 16.6664 3.021C15.2522 3.021 13.8834 3.52058 12.8016 4.43157C11.7198 5.34256 10.9946 6.6064 10.7539 8.00004H6.56644C6.07416 7.99722 5.59834 8.1772 5.23116 8.50513C4.86398 8.83305 4.63157 9.28557 4.57894 9.77504L2.79144 25.775C2.76117 26.056 2.79002 26.3401 2.87613 26.6092C2.96224 26.8783 3.10371 27.1264 3.29144 27.3375C3.47855 27.5467 3.70785 27.7139 3.96423 27.8281C4.22062 27.9423 4.49828 28.0009 4.77894 28H28.5539C28.8346 28.0009 29.1123 27.9423 29.3687 27.8281C29.625 27.7139 29.8543 27.5467 30.0414 27.3375C30.2292 27.1264 30.3706 26.8783 30.4568 26.6092C30.5429 26.3401 30.5717 26.056 30.5414 25.775ZM12.6664 13C12.6664 13.2653 12.5611 13.5196 12.3736 13.7072C12.186 13.8947 11.9317 14 11.6664 14C11.4012 14 11.1469 13.8947 10.9593 13.7072C10.7718 13.5196 10.6664 13.2653 10.6664 13V11C10.6664 10.7348 10.7718 10.4805 10.9593 10.2929C11.1469 10.1054 11.4012 10 11.6664 10C11.9317 10 12.186 10.1054 12.3736 10.2929C12.5611 10.4805 12.6664 10.7348 12.6664 11V13ZM12.7914 8.00004C13.0117 7.13963 13.5121 6.37701 14.2138 5.8324C14.9154 5.2878 15.7783 4.9922 16.6664 4.9922C17.5546 4.9922 18.4175 5.2878 19.1191 5.8324C19.8207 6.37701 20.3211 7.13963 20.5414 8.00004H12.7914ZM22.6664 13C22.6664 13.2653 22.5611 13.5196 22.3736 13.7072C22.186 13.8947 21.9317 14 21.6664 14C21.4012 14 21.1469 13.8947 20.9593 13.7072C20.7718 13.5196 20.6664 13.2653 20.6664 13V11C20.6664 10.7348 20.7718 10.4805 20.9593 10.2929C21.1469 10.1054 21.4012 10 21.6664 10C21.9317 10 22.186 10.1054 22.3736 10.2929C22.5611 10.4805 22.6664 10.7348 22.6664 11V13Z" />
    </svg>
  );
}

export default ShopIcon;
