import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSubscriptions,
  changeStatus,
  deleteSubscription,
} from "../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
import { useNavigate } from "react-router-dom";
import { getAllStaffUsers } from "../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { ROLE } from "../../../../enums/role";
import { formatDateTime } from "../../../../helpers/dateFormatter";

const PlanTypes = [
  { value: "", label: "All Plan Type" },
  { value: "Basic (ANNUAL)", label: "  Basic (Annual)    " },
  { value: "Professional (ANNUAL)", label: "   Professional (Annual)    " },
  { value: "Enterprise (ANNUAL)", label: "  Enterprise (Annual)    " },
  { value: "Basic (MONTHLY)", label: "Basic (Monthly)" },
  {
    value: "Professional (MONTHLY)",
    label: "Professional (Monthly)",
  },
  { value: "Enterprise (MONTHLY)", label: "Enterprise (Monthly)" },
];

const AllStatus = [
  { value: "", label: "All Status" },
  { value: "active", label: "active" },
  { value: "inactive", label: "inactive" },
  { value: "expired", label: "expired" },
  { value: "cancelled", label: "cancelled" },
];

const SortfromLatest = [
  { value: "", label: "Sort From Latest" },
  { value: "ASC", label: "Ascending" },
  { value: "DESC", label: "Descending" },
];

const headers = [
  { label: "Invoice No", key: "invoiceno" },
  { label: "Start Date", key: "startdate" },
  { label: "End Date", key: "enddate" },
  { label: "Client", key: "client" },
  { label: "Plan", key: "plan" },
  { label: "Account Manager", key: "accountmanager" },
  { label: "Added Branches", key: "addedbranches" },
  { label: "Type of Billing", key: "typeofbilling" },
  { label: "Total Amount", key: "totalamount" },
  { label: "Status", key: "subscriptionStatus" },
  { label: "Payment Method", key: "paymentmethod" },
];

export const useSubscription = (
  startDate,
  endDate,
  selectedPlanType,
  selectedStaffUser,
  selectedStatus,
  selectedTab
) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscriptionId = selectedItem?.id;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSort, setSelectedSort] = useState("");
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const role = storedUserData?.user?.role;
  const subscriptionTableId = selectedItem?.id;

  const openModal = (item, modalType) => {
    setSelectedItem(item);
    setActiveModal(modalType);
  };

  const openAddNewSubscriptionModal = () => {
    setActiveModal("AddNewSubscription");
  };

  const closeModal = () => {
    setActiveModal(null);
    setSelectedItem(null);
  };

  const { data: subscriptiondata, isLoading: subscriptionLoading } =
    useSelector((state) => state.subscriptions.getAllSubscriptions);

  const totalPages = subscriptiondata?.totalPages;

  const changeSubscriptionStatus = (item) => {
    dispatch(
      changeStatus({ subscriptionId: item?.id, successCallBack: handleSuccess })
    );
  };
 
  const reactivateSubscriptionStatus = (item) => {
    dispatch(
      deleteSubscription({ subscriptionId: item?.id, successCallBack: handleSuccess })
    );
  };

  const handleSuccess = () => {
    fetchAllSubscriptions();
  };

  const navigateToDetail = (item) => {
    navigate(`/admin/subscriptions/details/${item?.id}`, {
      state: selectedTab,
    });
  };

  const getPlanName = (planId) => {
    switch (planId) {
      case 1:
        return "Basic";
      case 2:
        return "Professional";
      case 3:
        return "Enterprise";
      default:
        return "Unknown Plan";
    }
  };

  const tableBody = subscriptiondata?.data?.map((sub) => {
    if (selectedTab === "FreeTrial") {
      return {
        ...sub,
        invoiceno: "N/A",
        startdate: sub.startDate,
        enddate: sub.endDate,
        client: sub.clientUser?.profile?.fullName,
        plan: "Free Trial",
        accountmanager: "N/A",
        addedbranches: sub.noOfBranches,
        typeofbilling: "N/A",
        totalamount: "N/A",
        subscriptionStatus: sub.status ?? "N/A",
        paymentmethod: "N/A",
        subData: sub,
      };
    } else {
      return {
        ...sub,
        invoiceno: sub.invoiceNumber,
        startdate: sub.startDate,
        enddate: sub.endDate,
        client: sub.clientUser?.profile?.fullName,
        plan: `${getPlanName(sub.planId)} (${
          sub.plan_tenure === "MONTHLY" ? "Monthly" : "Annual"
        })`,
        accountmanager: sub.managedBy?.fullName,
        addedbranches: sub.branches,
        totalamount: sub.subTotal,
        typeofbilling: (
          <span className="bg-green-200 text-green-800 px-2 py-1 rounded-lg">
            {sub.typeOfBilling}
          </span>
        ),
        status: sub.status ? "Active" : "Inactive" ?? "-",
        paymentmethod: (
          <img className="h-6  w-6" src={sub.paymentMethod} alt="Bank" />
        ),
        subData: sub,
      };
    }
  });

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: { id: "DESC" },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStaffUsers({ payload }));
  }, []);

  const { data: staffUser } = useSelector(
    (state) => state.dashboard.getAllStaffUsers
  );

  const staffUserOptions = [
    { value: "", label: "All Account Manager" },
    ...(staffUser?.data?.data
      ?.filter((user) => user.role === "ACCOUNT_MANAGER")
      .map((user) => ({
        value: user.id,
        label: user.fullName,
      })) || []),
  ];

  const dropdownOptions = [
    {
      label: "View Detail",
      value: "detail",
      textColor: "text-text-primary",
      action: (item) => navigateToDetail(item),
      toShow: [ROLE.SUPER_ADMIN, ROLE.ACCOUNT_MANAGER,ROLE.ACCOUNTING],
      visibleTo: [
        "PaidSubscriptions",
        "inactive",
        "expired",
        "active",
      ],
      visibleKey: "subscriptionStatus",
    },
    {
      label: "Reactivate Subscription",
      value: "delete",
      textColor: "text-text-primary",
      action: (item) => reactivateSubscriptionStatus(item),
      toShow: [ROLE.SUPER_ADMIN, ROLE.ACCOUNT_MANAGER,ROLE.ACCOUNTING],
      visibleTo: [
        "PaidSubscriptions",
        "cancelled",
      ],
      visibleKey: "subscriptionStatus",
    },
    {
      label: "Upgrade Plan",
      value: "upgrade",
      textColor: "text-text-primary",
      action: (item) => openModal(item, "UpgradePlan"),
      toShow: [ROLE.SUPER_ADMIN, ROLE.ACCOUNT_MANAGER, ],
      visibleTo: ["FreeTrial"],
    },
    {
      label: "Change Status",
      value: "edit",
      textColor: "text-text-primary",
      action: (item) => changeSubscriptionStatus(item),
      toShow: [ROLE.SUPER_ADMIN, ROLE.ACCOUNT_MANAGER, ],
      visibleTo: [
        "PaidSubscriptions",
        "inactive",
        "expired",
        "active",
      ],
      visibleKey: "subscriptionStatus",
    },
    {
      label: "Change Subscription",
      value: "delete",
      textColor: "text-text-primary",
      action: (item) => openModal(item, "ChangeSubscription"),
      toShow: [ROLE.SUPER_ADMIN, ROLE.ACCOUNT_MANAGER],
      visibleTo: ["PaidSubscriptions", "expired", "active"],
      visibleKey: "subscriptionStatus",
    },
    {
      label: "Add Branches",
      value: "add",
      textColor: "text-text-primary",
      action: (item) => openModal(item, "AddBranches"),
      toShow: [ROLE.SUPER_ADMIN, ROLE.ACCOUNT_MANAGER,],
      visibleTo: ["PaidSubscriptions", "active"],
      visibleKey: "subscriptionStatus",
    },
    {
      label: "Extend Subscription",
      value: "extend",
      textColor: "text-text-primary",
      action: (item) => openModal(item, "ExtendSubscription"),
      toShow: [ROLE.SUPER_ADMIN, ROLE.ACCOUNT_MANAGER,],
      visibleTo: ["PaidSubscriptions", "expired", "active"],
      visibleKey: "subscriptionStatus",
    },
    {
      label: "Cancel Subscription",
      value: "cancel",
      textColor: "text-red-600",
      action: (item) => openModal(item, "CancelSubscription"),
      toShow: [ROLE.SUPER_ADMIN, ROLE.ACCOUNT_MANAGER],
      visibleTo: ["PaidSubscriptions", "inactive", "expired", "active"],
      visibleKey: "subscriptionStatus",
    },
   
  ];

  const filteredDropdownOptions = dropdownOptions?.filter((option) => {
    const isVisible = option.visibleTo.includes(selectedTab);
    const roleCondition = option.toShow.includes(role);
    const upgradePlanCondition =
      selectedTab === "FreeTrial" ? option.value === "upgrade" : true;
    return roleCondition && upgradePlanCondition && isVisible;
  });

  const fetchAllSubscriptions = () => {
    const condition =
      selectedTab === "PaidSubscriptions"
        ? {
            ...(selectedStaffUser && { managedBy: { id: selectedStaffUser } }),
            ...(selectedPlanType && { planType: selectedPlanType }),
            ...(selectedStatus && { subscriptionStatus: selectedStatus }),
            ...((startDate || endDate) && {
              createdAt: {
                "date-range": `${formatDateTime(
                  startDate,
                  "yyyy-MM-dd"
                )}:${formatDateTime(endDate, "yyyy-MM-dd")}`,
              },
            }),
          }
        : { isFreeTrial: true };

    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: selectedTab === "PaidSubscriptions" ? "createdAt" : "id",
      order: {
        createdAt: selectedSort || "DESC",
      },
      condition,
      attributes: {},
      periodId: 0,
    };
    dispatch(getAllSubscriptions({ payload }));
  };

  useEffect(() => {
    fetchAllSubscriptions();
  }, [
    currentPage,
    selectedPlanType,
    selectedStaffUser,
    selectedStatus,
    startDate,
    endDate,
    selectedSort,
    selectedTab,
  ]);

  return {
    dropdownOptions: filteredDropdownOptions,
    headers,
    tableBody,
    closeModal,
    openModal,
    selectedItem,
    activeModal,
    openAddNewSubscriptionModal,
    subscriptionLoading,
    SortfromLatest,
    AllStatus,
    staffUserOptions,
    PlanTypes,
    endDate,
    startDate,
    currentPage,
    setCurrentPage,
    fetchAllSubscriptions,
    storedUserData,
    subscriptionId,
    setSelectedSort,
    totalPages,
    subscriptionTableId,
  };
};
