import * as React from "react";

function BoxesIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="SquaresFour">
        <path
          id="Vector"
          d="M8.75 3.75H3.75V8.75H8.75V3.75Z"
          stroke={props.strokeColor ? props.strokeColor : "#1A1A1A"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M16.25 3.75H11.25V8.75H16.25V3.75Z"
          stroke={props.strokeColor ? props.strokeColor : "#1A1A1A"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M8.75 11.25H3.75V16.25H8.75V11.25Z"
          stroke={props.strokeColor ? props.strokeColor : "#1A1A1A"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M16.25 11.25H11.25V16.25H16.25V11.25Z"
          stroke={props.strokeColor ? props.strokeColor : "#1A1A1A"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default BoxesIcon;
