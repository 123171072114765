import { useEffect, useState } from "react";

const useSelectedItemCard = (item, updateTotalPrice, selectedItems) => {
  const [note, setNote] = useState(item?.note ?? "");
  const [isNoteVisible, setIsNoteVisible] = useState(item?.note ? true : false);
  const selectionsItems = selectedItems?.selections && Object.values(selectedItems?.selections);
  const [editableQuantity, setEditableQuantity] = useState(
    item?.quantity ?? item?.item?.quantity ?? 1
  );

  useEffect(() => {
    updateTotalPrice(
      item.id,
      editableQuantity * (item.price ?? item.salePrice)
    );
  }, [editableQuantity, item.id, item.price, item.salePrice]);

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      setEditableQuantity(newQuantity);
    }
  };

  const incrementQuantity = () => {
    setEditableQuantity((prev) => prev + 1);
  };

  const decrementQuantity = () => {
    if (editableQuantity > 1) {
      setEditableQuantity((prev) => prev - 1);
    }
  };

  const handleAddNoteClick = () => {
    setIsNoteVisible(true);
  };

  const handleRemoveNoteClick = () => {
    setIsNoteVisible(false);
    setNote("");
  };

  useEffect(() => {
    if (item.quantity) setEditableQuantity(item.quantity);
  }, [item.quantity]);
  return {
    note,
    isNoteVisible,
    setNote,
    handleAddNoteClick,
    handleRemoveNoteClick,
    decrementQuantity,
    incrementQuantity,
    editableQuantity,
    selectionsItems,
    handleQuantityChange,
  };
};

export default useSelectedItemCard;
