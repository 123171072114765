import * as React from "react";

function GreenArrowUp(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 16.5L16.5 5.5"
        stroke="#299C46"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5625 5.5H16.5V14.4375"
        stroke="#299C46"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GreenArrowUp;
