import React from "react";
import LoginView from "../../Login/LoginView";
import AdminLoginForm from "./AdminLoginForm";

function AdminLogin() {
  return (
    <LoginView>
      <AdminLoginForm />
    </LoginView>
  );
}

export default AdminLogin;
