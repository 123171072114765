import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import React, { Fragment } from "react";
import { ArrowDown, CheckIcon } from "../../Icons";
import { showToast } from "../../utils/showToast.util";

const MultiSelecCheckbox = ({
  value,
  onChange,
  options = [],
  placeholder = "",
  className = "",
  limit = 100,
}) => {
  const handleChange = (e) => {
    let newValue = [];
    if (value?.includes(e)) {
      newValue = value?.filter((v) => v !== e); // Remove if already selected
    } else if (value?.length < limit) {
      newValue = [...value, e]; // Add if not already selected and under limit
    } else {
      showToast(`You can only select up to ${limit} items.`, "error");
      return;
    }
    onChange(newValue); // Update the form value
  };

  return (
    <Listbox value={value} onChange={handleChange}>
      {({ open }) => (
        <div className={`relative min-w-36 ${className}`}>
          <ListboxButton className="ps-3 pe-2 py-2 border flex items-center border-border-primary rounded-lg appearance-none w-full max-h-10">
            <div className="flex items-center justify-between w-full">
              <span>
                {value?.length > 0
                  ? `${value?.length}/${options?.length} Selected`
                  : `Select ${placeholder}`}
              </span>
              <ArrowDown className="h-7 leading-[normal]" />
              {/* {open ? <ArrowUp className="arro" /> : <ArrowDown />} */}
            </div>
          </ListboxButton>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute w-full py-1 mt-1 overflow-auto bg-white border border-gray-300 rounded max-h-60 z-50">
              {options?.map((option) => (
                <ListboxOption
                  key={option.value}
                  value={option.value}
                  as={Fragment}
                >
                  {({ focus, selected }) => (
                    <li
                      className={`flex items-center px-4 py-2 cursor-pointer select-none ${
                        focus ? "bg-blue-500 text-white" : "text-gray-900"
                      }`}
                    >
                      <input
                        type="checkbox"
                        checked={value?.includes(option.value)}
                        onChange={() => {}}
                        className={`form-checkbox ${
                          selected
                            ? "text-blue-600"
                            : "text-text-gray !focus:outline-none focus:ring-0 ring-0 outline-none"
                        } rounded`}
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent Listbox onClick from firing
                          handleChange(option.value); // Use handleChange function
                        }}
                      />
                      <span className={`ml-2 ${selected ? "font-medium" : ""}`}>
                        {option?.label}
                      </span>
                      {selected && (
                        <CheckIcon className="h-5 w-5 ml-auto text-blue-500" />
                      )}
                    </li>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
export default MultiSelecCheckbox;
