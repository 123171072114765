import React from "react";
import useAdminNotificationSetting from "../../AdminNotifications/AdminNotificationSetting/useAdminNotificatonSetting";
import { ToggleOff, ToggleOn } from "../../../../../common/Icons";

const AdminNotificationSetting = () => {
  const { settings, handleToggle, isLoader } = useAdminNotificationSetting();

  const getToggleIcon = (isEnabled) =>
    isEnabled ? <ToggleOn /> : <ToggleOff />;

  return (
    <div className="p-4 mb-32">
      {!isLoader &&
        settings.map((setting) => (
          <div
            key={setting.id}
            className="flex items-center justify-between mt-4"
          >
            <label className="text-sm font-semibold">
              {setting.id === 1 && "New Subscriber"}
              {setting.id === 2 && "Successful Subscription Upgrade"}
              {setting.id === 3 && "Expired Subscription"}
              {setting.id === 4 && "Trial Period Ending"}
              {setting.id === 5 && "Client Assigned to Account Manager"}
            </label>
            <div onClick={() => handleToggle(setting.id)}>
              {getToggleIcon(setting.isEnabled)}
            </div>
          </div>
        ))}
    </div>
  );
};

export default AdminNotificationSetting;
