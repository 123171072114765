import { useEffect, useState } from "react";
import useCategoriesList from "../Categories/useCategories";
import { useDispatch, useSelector } from "react-redux";
import { getPosItems } from "../../../../features/Items/ItemsSlice";

const useItems = (selectedCategoryCardId) => {
  const dispatch = useDispatch();
  const [layout, setLayout] = useState("grid");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const { categoryList } = useCategoriesList();

  const { data: itemList, isLoading: itemListLoading } = useSelector(
    (state) => state?.items?.getPosItems
  );

  // Filter itemList to include only "SIMPLE", "VARIABLE", and "COMPOSITING" types
  const filteredItemList =
    itemList?.filter((item) =>
      ["SIMPLE", "VARIABLE", "COMPOSITING"].includes(item.type)
    ) || [];

  const itemsCount = filteredItemList.length;

  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  useEffect(() => {
    if (selectedCategoryCardId) {
      handleCategoryClick(selectedCategoryCardId);
    }
  }, [selectedCategoryCardId]);

  useEffect(() => {
    // Construct the payload for fetching POS items
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {
        category: {
          id: selectedCategoryId || null, // Send selectedCategoryCardId if it exists, otherwise send null
        },
      },
      attributes: {},
      periodId: 0,
    };

    // Dispatch the action to get items based on the constructed payload
    dispatch(getPosItems({ payload }));
  }, [selectedCategoryId, dispatch]); // Include selectedCategoryCardId and dispatch in the dependencies array

  return {
    categoryList,
    itemListLoading,
    itemsCount,
    layout,
    setLayout,
    selectedCategoryId,
    handleCategoryClick,
    itemList: filteredItemList,
    getPosItems,
  };
};

export default useItems;
