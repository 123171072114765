/**
 * Manages state for categories.
 * Defines initial state for each category action.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createPosTablesApi from "./PosTablesService";
import { showToast } from "../../components/common/utils/showToast.util";

/**
 * Initial state structure defining various properties related to category processes.
 * Each property represents a specific category action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllPosTables, getSinglePosTable, updatePosTable, addPosTable, and deletePosTable
 * store data, error, success, loading, and message status for corresponding category actions.
 */
const initialState = {
  getAllPosTables: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSinglePosTable: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updatePosTable: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addPosTable: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deletePosTable: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  resetPosTable: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the category process.
 * These action types are prefixed with the 'categories' base path for better organization.
 */
const BASE = "tables";

export const ActionTypes = {
  GET_ALL_POS_TABLES: `${BASE}/get-all`, // Action type for get all categories
  ADD_POS_TABLE: `${BASE}`,
};

// Creating an instance of the categories service with a base URL 'categories'
const posTableService = createPosTablesApi("tables");

/**
 * Initiates the getAllPosTables process for a category.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting categories
 *    order: order for sorting categories by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllPosTables.
 */
export const getAllPosTables = createAsyncThunk(
  ActionTypes.GET_ALL_POS_TABLES,

  async ({ payload }, thunkAPI) => {
    const response = await posTableService.getAllPosTables(payload);
    return response?.data?.data;
  }
);

/**
 * Initiates the getSinglePosTable process for a category.
 * @param {object} tableId - id of the category
 * @param {function} successCallBack - Callback function upon successful getSinglePosTable.
 */
export const getSinglePosTable = createAsyncThunk(
  `${BASE}/tableId`,

  async ({ tableId }, thunkAPI) => {
    const response = await posTableService.getSinglePosTable(tableId);
    return response?.data?.data;
  }
);

/**
 * Initiates the updatePosTable process for a category.
 * @param {object} tableId - id of the category
 * @param {object} payload - category data to update
 * @param {function} successCallBack - Callback function upon successful updatePosTable.
 */
export const updatePosTable = createAsyncThunk(
  `${BASE}/tableId`,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posTableService.updatePosTable( payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Table updated successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addPosTable process for a category.
 * @param {object} payload - category data to add
 * {
  "name": "Category Name",
  "description": "Category Description"
}
 * @param {function} successCallBack - Callback function upon successful addPosTable.
 */
export const addPosTable = createAsyncThunk(
  ActionTypes.ADD_POS_TABLE,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posTableService.addPosTable(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Table added successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deletePosTable process for a category.
 * @param {object} tableId - id of the category
 * @param {function} successCallBack - Callback function upon successful deletePosTable.
 */
export const deletePosTable = createAsyncThunk(
  `${BASE}/tableId`,

  async ({ tableId, successCallBack }, thunkAPI) => {
    try {
      const response = await posTableService.deletePosTable(tableId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Table deleted successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the getAllPosTables process for a category.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting categories
 *    order: order for sorting categories by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllPosTables.
 */
export const resetPosTable = createAsyncThunk(
"resetPosTable",
  async ( thunkAPI) => {
    try {
      const response = await posTableService.resetPosTable();
      if (response?.data?.Succeeded) {
        showToast("Tables Reset successfully!");
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);



/**
 * Creates a slice for categories related state management.
 * - `name`: Name of the slice (categories)
 * - `initialState`: Initial state defining properties for various category actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getAllPosTables, updatePosTable etc).
 */
export const posTableSlice = createSlice({
  name: "posTables",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllPosTables action.
     */
    reset: (state) => {
      state.getAllPosTables = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSinglePosTable = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.resetPosTable = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllPosTables action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllPosTables.pending, (state) => {
        state.getAllPosTables.isLoading = true;
        state.getAllPosTables.message = "";
        state.getAllPosTables.isError = false;
        state.getAllPosTables.isSuccess = false;
        state.getAllPosTables.data = null;
      })
      /**
       * Updates the state when getAllPosTables action is fulfilled/successful.
       * Updates loading and success flags and sets getAllPosTables data with the payload.
       */
      .addCase(getAllPosTables.fulfilled, (state, action) => {
        state.getAllPosTables.isLoading = false;
        state.getAllPosTables.isSuccess = true;
        state.getAllPosTables.data = action.payload;
      })
      /**
       * Updates the state when getAllPosTables action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllPosTables.rejected, (state, action) => {
        state.getAllPosTables.message = action.payload?.message;
        state.getAllPosTables.isLoading = false;
        state.getAllPosTables.isError = true;
        state.getAllPosTables.data = null;
      })
      /**
       * Updates the state while the getSinglePosTable action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSinglePosTable.pending, (state) => {
        state.getSinglePosTable.isLoading = true;
        state.getSinglePosTable.message = "";
        state.getSinglePosTable.isError = false;
        state.getSinglePosTable.isSuccess = false;
        state.getSinglePosTable.data = null;
      })
      /**
       * Updates the state when getSinglePosTable action is fulfilled/successful.
       * Updates loading and success flags and sets getSinglePosTable data with the payload.
       */
      .addCase(getSinglePosTable.fulfilled, (state, action) => {
        state.getSinglePosTable.isLoading = false;
        state.getSinglePosTable.isSuccess = true;
        state.getSinglePosTable.data = action.payload;
      })
      /**
       * Updates the state when getSinglePosTable action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSinglePosTable.rejected, (state, action) => {
        state.getSinglePosTable.message = action.payload?.message;
        state.getSinglePosTable.isLoading = false;
        state.getSinglePosTable.isError = true;
        state.getSinglePosTable.data = null;
      })
      /**
       * Updates the state while the addPosTable action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addPosTable.pending, (state) => {
        state.addPosTable.isLoading = true;
        state.addPosTable.message = "";
        state.addPosTable.isError = false;
        state.addPosTable.isSuccess = false;
        state.addPosTable.data = null;
      })
      /**
       * Updates the state when addPosTable action is fulfilled/successful.
       * Updates loading and success flags and sets addPosTable data with the payload.
       */
      .addCase(addPosTable.fulfilled, (state, action) => {
        state.addPosTable.isLoading = false;
        state.addPosTable.isSuccess = true;
        state.addPosTable.data = action.payload;
      })
      /**
       * Updates the state when addPosTable action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addPosTable.rejected, (state, action) => {
        state.addPosTable.message = action.payload?.message;
        state.addPosTable.isLoading = false;
        state.addPosTable.isError = true;
        state.addPosTable.data = null;
      })
      /**
       * Updates the state while the getAllPosTables action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(resetPosTable.pending, (state) => {
        state.resetPosTable.isLoading = true;
        state.resetPosTable.message = "";
        state.resetPosTable.isError = false;
        state.resetPosTable.isSuccess = false;
        state.resetPosTable.data = null;
      })
      /**
       * Updates the state when resetPosTable action is fulfilled/successful.
       * Updates loading and success flags and sets resetPosTable data with the payload.
       */
      .addCase(resetPosTable.fulfilled, (state, action) => {
        state.resetPosTable.isLoading = false;
        state.resetPosTable.isSuccess = true;
        state.resetPosTable.data = action.payload;
      })
      /**
       * Updates the state when resetPosTable action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(resetPosTable.rejected, (state, action) => {
        state.resetPosTable.message = action.payload?.message;
        state.resetPosTable.isLoading = false;
        state.resetPosTable.isError = true;
        state.resetPosTable.data = null;
      });
  },
});

/**
 * Destructures the reset action from the categoriesSlice actions.
 * - `reset`: Action function to reset the categories related state.
 */
export const { reset } = posTableSlice.actions;

/**
 * Exports the default reducer generated by categoriesSlice.
 * This reducer handles state updates for categories related actions.
 */
export default posTableSlice.reducer;
