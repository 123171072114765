import { createSlice } from "@reduxjs/toolkit";

/**
 * GlobalSearch Slice for managing GlobalSearch state.
 *
 * @typedef {Object} GlobalSearchState - State object representing the GlobalSearch properties.
 *
 * @param {GlobalSearchState} state - Current state of the GlobalSearch.
 * @param {Object} action - Action object to modify the GlobalSearch state.
 * @param {string} action.type - Type of action.
 * @param {Object} action.payload - Payload data for the action.
 * @returns {void} No return value. Modifies the state based on the action.
 */

const GlobalSearchSlice = createSlice({
  name: "globalSearch",
  initialState: {
    query: "",
  },
  reducers: {
    // Reducer to update the search query
    setSearchQuery(state, action) {
      state.query = action.payload;
    },
    // Optional: Clear the search query
    clearSearchQuery(state) {
      state.query = "";
    },
  },
});

// Destructure and export the actions
export const { setSearchQuery, clearSearchQuery, query } =
  GlobalSearchSlice.actions;
export default GlobalSearchSlice.reducer;

// Sample Usage:
// import { setSearchQuery, clearSearchQuery } from "path/to/GlobalSearchSlice";
//
// const dispatch = useDispatch();
//
// To set the search query
// dispatch(setSearchQuery('search term'));
//
// To clear the search query
// dispatch(clearSearchQuery());
