import * as React from "react";

function ArchiveBox(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="ArchiveBox">
        <path
          id="Vector"
          d="M16.25 17.375H3.75C3.58424 17.375 3.42527 17.3092 3.30806 17.1919C3.19085 17.0747 3.125 16.9158 3.125 16.75V6.125L4.375 3.625H15.625L16.875 6.125V16.75C16.875 16.9158 16.8092 17.0747 16.6919 17.1919C16.5747 17.3092 16.4158 17.375 16.25 17.375Z"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.35156 12.2266L10 14.875L12.6484 12.2266"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M10 8.625V14.875"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M3.125 6.125H16.875"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default ArchiveBox;
