import * as React from "react";

function ArrowUp(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="CaretUp">
        <path
          id="Vector"
          d="M3.75 12.5L10 6.25L16.25 12.5"
          stroke={props.stroke ? "#ffffff" : "#475467"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default ArrowUp;
