import React from "react";
import { NoDataIcon } from "../../../../assets/images/svgs";
import CustomButton from "../Button/Button";
import { Plus } from "../../Icons/fontawesome";

const NoDataAvailable = ({
  btnTitle = "Add",
  module = "data",
  onClick = () => {},
}) => {
  return (
    <div className="bg-neutral-50 rounded flex items-center justify-center h-[60vh] mt-6">
      <div className="flex flex-col space-y-2 justify-center items-center text-center w-fit">
        <img src={NoDataIcon} alt="Cards" width={192} height={160} />
        <span className="text-lg font-semibold to-text-primary">
          No Data Available
        </span>
        <p className="text-sm font-normal text-text-secondary w-80">
          {`No ${module} to display in this page. You can add a new ${module} to populate this list`}
        </p>
        <CustomButton
          text={btnTitle}
          width="w-fit"
          textColor="text-text-primary"
          icon={<Plus color="black" />}
          border="border"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default NoDataAvailable;
