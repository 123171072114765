import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "../../../../../features/Customers/CustomersSlice";
import { checkOutOrder } from "../../../../../features/PosOrder/PosOrderSlice";
import { showToast } from "../../../../common/utils/showToast.util";

export const useSplitByItem = (
  setCheckOutVisible,
  setOrderCheckoutPaid,
  setIsCheckoutModalOpen
) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cash");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const selectedPaymentMethodUpperCase = selectedPaymentMethod.toUpperCase();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef();

  const { data: customerList, isLoading: customerListLoading } = useSelector(
    (state) => state.customers.getAllCustomers
  );

  const { data: paymentMethodsList, isLoading: paymentMethodsListLoading } =
    useSelector((state) => state.payments.getPaymentMethods);

  const orderCheckoutData = useSelector(
    (state) => state.posOrder.orderCheckoutData
  );
  const discountAmount = Number(orderCheckoutData?.discountAmount) || 0;
  const totalAmount = Number(orderCheckoutData?.totalAmount)?.toFixed() || 0;
  let taxAmount = Number(orderCheckoutData?.taxAmount)?.toFixed() || 0;
  const amount = Number(totalAmount) + Number(taxAmount);
  const total = Number(amount) - Number(discountAmount);
  const orderId = orderCheckoutData?.id;
  const selectedCustomerId = selectedCustomer?.id;

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCustomers({ payload }));
  }, [dispatch]);

  useEffect(() => {
    if (paymentMethodsList && !selectedCard) {
      const firstCard = paymentMethodsList
        .filter((method) => method.type === "CARD" && method.paymentCard)
        .flatMap((method) => method.paymentCard.cards)
        .find((card) => card);

      if (firstCard) {
        setSelectedCard(firstCard.name);
      }
    }
  }, [paymentMethodsList, selectedCard]);

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, dropdownRef]);

  const items =
    orderCheckoutData?.orderedItems?.map((orderedItem) => ({
      orderItemId: orderedItem.id,
      id: orderedItem.item.id,
      name: orderedItem.item.englishName,
      price: (orderedItem.price * 1.15).toFixed(2),
      paymentStatus: orderedItem.paymentStatus,
    })) || [];

  const handleSelectItem = (id) => {
    const selectedItem = items.find((item) => item.id === id);
    if (selectedItem) {
    }
    const isItemSelected = selectedItems.some((item) => item.id === id);
    setSelectedItems((prevSelectedItems) =>
      isItemSelected
        ? prevSelectedItems.filter((item) => item.id !== id)
        : [...prevSelectedItems, selectedItem]
    );
  };

  const handleSelectionChange = (value) => {
    setSelectedCard(value);
  };


  const orderItemIds = selectedItems?.map((item) => item.orderItemId);
  const selectedItemsPrice = selectedItems?.reduce((acc, item) => {
    return acc + (item ? parseFloat(item.price) : 0);
  }, 0);

  const handleCheckout = () => {
    if (!orderItemIds || orderItemIds.length === 0) {
      showToast("Please select at least 1 item to proceed", "success");
      return;
    }
    setIsButtonDisabled(true);
    const payload = {
      paymentMethod: selectedPaymentMethodUpperCase ?? "",
      orderId: orderId,
      checkoutType: "SPLIT_BY_ITEM",
      cardType: selectedCard,
      customerPointsid: selectedCustomerId,
      orderItemIds: orderItemIds,
    };
    dispatch(
      checkOutOrder({ payload, successCallBack: successCheckoutCallback })
    );
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  const successCheckoutCallback = (response) => {
    setOrderCheckoutPaid(response?.data?.data);
    setIsCheckoutModalOpen(true);
    setCheckOutVisible(false);
  };

  return {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    customerList,
    customerListLoading,
    setIsOpen,
    isOpen,
    selectedCustomer,
    setSelectedCustomer,
    handleSelectCustomer,
    dropdownRef,
    // totalItems,
    handleSelectItem,
    selectedItems,
    items,
    paymentMethodsList,
    paymentMethodsListLoading,
    handleCheckout,
    total,
    taxAmount,
    totalAmount,
    discountAmount,
    selectedItemsPrice,
    handleSelectionChange,
    selectedCard,
    isButtonDisabled
  };
};
