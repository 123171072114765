import { Menu, MenuButton, MenuItem } from "@headlessui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PosLoginBgImage } from "../../../../assets/images";
import { AppleStore, GooglePlay } from "../../../common/Icons";
import { Loader } from "../../../common/components";
import CustomButton from "../../../common/components/Button/Button";
import { LanguageSwitcher } from "../../../common/components/LanguageSwitcher/LanguageSwitcher";
import Navbar from "../../../common/components/Navbar/Navbar";
import { usePosStores } from "./usePosStores";
import { useUserContext } from "../../../../context/UserContext";
import useStorage from "../../../common/utils/localStorage.util";
import { useNavigate } from "react-router-dom";

const PosStores = () => {
  const {
    onSubmit,
    handleCloseModal,
    isModalOpen,
    handleConfirm,
    selectedStore,
    register,
    handleSubmit,
    storeOptions,
    handleStoreSelection,
    storeListLoading,
  } = usePosStores();
  const { t } = useTranslation();

  const { user } = useUserContext()
  const navigate = useNavigate()
  const isStoreSelected = useStorage.getItem("isStoreSelected");
  

  const isPosUser =
    user?.role === "CASHIER" ||
    user?.role === "SALES MANAGER" ||
    user?.role === "SYSTEM MANAGER";
  useEffect(() => {
    if (user && isPosUser && isStoreSelected) {
      navigate("/pos/dashboard", { replace: true });
    }
  }, [isStoreSelected, user, isPosUser, navigate]);

  return (
    <div className="flex min-h-screen">
      {/* image side */}
      <div className="w-2/4 bg-bright-cerulean pb-10 lg:block  flex flex-col">
        {/* language dropdown */}
        <div className="  mt-3  w-fit pl-32 rtl:pr-12">
          <Menu as="div" className="">
            <MenuButton className="flex items-center">
              <MenuItem className="absolute px-4 end-0  z-10 mt-2.5 min-w-[120px] max-w-[120px] origin-top-right rounded-md bg-white py-4 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <LanguageSwitcher />
              </MenuItem>
            </MenuButton>
          </Menu>
        </div>
        {/* image */}
        <div>
          <img
            src={PosLoginBgImage}
            alt="LoginImage"
            className="ml-10 mt-20 mb-4   left-24 right-0  w-[100%]"
          />
        </div>
        <div className="flex flex-col  items-center   ">
          <span className="text-white text-lg font-medium mb-3">
            {t("pos_dashboard.download_our_app")}
          </span>
          <div className="flex items-center gap-3">
            <AppleStore />
            <GooglePlay />
          </div>
        </div>
      </div>
      {/* email form */}
      <div className="w-2/4  flex flex-col justify-between lg:px-0 px-4 z-10 relative bg-white">
        <div>
          <Navbar />
          <div className="lg:max-w-[400px] mx-auto min-h-[250px] max-h-[700px]">
            <h2 className="text-3xl font-semibold text-text-primary text-center">
              {t("pos_dashboard.login_to_your_store")}
            </h2>
            {storeListLoading ? (
              <div className="flex justify-center items-center h-52">
                <div>
                  <Loader />
                </div>
              </div>
            ) : (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-24 mt-24   overflow-auto"
              >
                <fieldset className=" h-[300px] max-h-[350px] overflow-auto">
                  {storeOptions?.map((store) => (
                    <label
                      key={store.id}
                      className={`flex cursor-pointer border  py-3 px-4 mb-4 rounded-lg justify-between items-center ${
                        selectedStore === store.id
                          ? "bg-info-50 border-text-link"
                          : ""
                      }`}
                    >
                      <span
                        className={` ${
                          selectedStore === store.id
                            ? "text-text-primary font-medium text-sm"
                            : "text-text-primary font-medium text-sm"
                        }`}
                      >
                        {store.branchName}
                      </span>
                      <input
                        type="radio"
                        name="selectedStore"
                        value={store.id}
                        className="form-radio text-text-link"
                        {...register("selectedStore")}
                        onChange={() => handleStoreSelection(store.id)}
                      />
                    </label>
                  ))}
                </fieldset>
                <CustomButton
                  type="submit"
                  text={t("pos_dashboard.confirm")}
                  className="mt-4 w-full px-4 py-2"
                  disabled={!selectedStore}
                />
              </form>
            )}
            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 items-center flex justify-center">
                <div className="bg-white p-4 rounded-lg shadow-lg">
                  <p className="text-center text-base font-normal">
                    {t("pos_dashboard.are_you_sure_about_choosing_the_store")}{" "}
                    <span className="font-bold">
                      "{selectedStore?.branchName}"{" "}
                    </span>
                    {t("pos_dashboard.branch")}
                    {t("pos_dashboard.question")}
                  </p>

                  <div className="flex justify-end gap-3 mt-4">
                    <CustomButton
                      onClick={handleCloseModal}
                      text={t("pos_dashboard.cancel")}
                      width="w-fit "
                      type="submit"
                      bgColor="bg-gray-200"
                      textColor="text-text-primary"
                      hoverBgColor="!bg-gray-500"
                    />
                    <CustomButton
                      onClick={handleConfirm}
                      text={t("pos_dashboard.yes_choose")}
                      width="w-fit "
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <span className="text-sm text-text-primary font-normal justify-center items-center  flex ">
          {t("footer.copyright")} © 2023 TagDev.
        </span>
      </div>
    </div>
  );
};

export default PosStores;
