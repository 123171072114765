import * as React from "react";

function CloudSlash(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M3.75 3.125L16.25 16.875"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1484 15.6641C14.3191 16.0507 13.415 16.2507 12.5 16.25H5.625C4.46468 16.25 3.35188 15.7891 2.53141 14.9686C1.71094 14.1481 1.25 13.0353 1.25 11.875C1.25 10.7147 1.71094 9.60188 2.53141 8.78141C3.35188 7.96094 4.46468 7.5 5.625 7.5C5.99104 7.49986 6.35572 7.54446 6.71094 7.63281"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.79688 4.96094C10.0417 4.03732 11.5854 3.60924 13.1282 3.75983C14.6709 3.91043 16.1027 4.62894 17.1454 5.77587C18.1882 6.9228 18.7675 8.41629 18.771 9.96638C18.7744 11.5165 18.2017 13.0125 17.1641 14.1641"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25002 10C6.24693 8.88374 6.54663 7.78754 7.11721 6.82812"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CloudSlash;
