import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { changePassword } from "../../../../../../features/Auth/AuthSlice";
import { getUser } from "../../../../../common/utils/users.utils";
import { updatePassword } from "../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";

/**
 * Custom hook for handling login form functionality using react-hook-form.
 * Provides methods for registering form inputs, handling form submission, and accessing form errors.
 * @returns {Object} An object containing:
 *   - register: Function to register input fields into the react-hook-form.
 *   - handleSubmit: Function that wraps the form submit handler.
 *   - errors: State object containing form validation errors.
 */
const useUpdatePassword = (onClose) => {
  const dispatch = useDispatch();
  const storedUserData = getUser("user");
  const parsedData = JSON.parse(storedUserData);
  const role = parsedData?.user?.role;
  const staffId = parsedData?.user?.id;

  const passwordSchema = z
    .object({
      currentpassword: z.string().min(1, "Current password is required!"),
      newpassword: z
        .string()
        .min(8, "Password must be at least 8 characters long")
        .regex(
          /^(?=.*[A-Z])/,
          "Password must contain at least one uppercase letter"
        )
        .regex(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .regex(/\d/, "Password must contain at least one numerical digit"),
      confirmpassword: z.string(),
    })
    .refine((data) => data.newpassword === data.confirmpassword, {
      message: "Passwords don't match",
      path: ["confirmpassword"],
    });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(passwordSchema),
  });

  // Handler function for form submission
  const onSubmit = async (data) => {
    const payload = {
      currentPassword: data.currentpassword,
      newPassword: data.newpassword,
      passwordConfirm: data.confirmpassword,
    };

    if (role === "SUPER_ADMIN") {
      dispatch(changePassword({ payload }))
        .then(() => {
          onClose();
        })
        .catch((error) => {
          console.error("Failed to change password", error);
        });
    } else {
      dispatch(
        updatePassword({
          payload,
          staffId: staffId
        })
      )
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error("Failed to change password", error);
      });
    }
  };
  
  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    isDirty,
    isValid,
  };
};

export default useUpdatePassword;
