import api from "../../services/api";

/**
 * Creates customers API endpoints based on the specified base path.
 * @param {string} basePath - The base path for customers endpoints.
 * @returns {Object} Object containing customers API methods.
 */
const createCustomersApi = (basePath) => ({
  /**
   * get all customers
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting customers
   *    order: order for sorting customers by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the customer response.
   */
  getAllCustomers: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * get a single customer
   * @param {object} id - id of the customer
   * @returns {Promise} A Promise that resolves to the customer response.
   */
  getSingleCustomer: async (customerId) => {
    const response = await api().get(`${basePath}/${customerId}`);
    return response;
  },

  /**
   * update a single customer
   * @param {object} id - id of the customer
   * @returns {Promise} A Promise that resolves to the customer response.
   */
  updateCustomer: async (customerId, payload) => {
    const response = await api().put(`${basePath}/${customerId}`, payload);
    return response;
  },

  /**
   * add a customer
   * @param {object} id - id of the customer
   * @returns {Promise} A Promise that resolves to the customer response.
   */
  addCustomer: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * delete a customer
   * @param {object} id - id of the customer
   * @returns {Promise} A Promise that resolves to the customer response.
   */
  deleteCustomer: async (customerId) => {
    const response = await api().delete(`${basePath}/${customerId}`);
    return response;
  },
  /**
   * undo a deleted customer
   * @param {object} id - id of the customer
   * @returns {Promise} A Promise that resolves to the customer response.
   */
  undoCustomer: async (customerId) => {
    const response = await api().get(`${basePath}/undo-customer/${customerId}`);
    return response;
  },
  /**
   * export customer
   * @returns {Promise} A Promise that resolves to the customer response.
   */
  exportCustomer: async (payload, config = {}) => {
    const response = await api().post(
      `${basePath}/export-customer`,
      payload,
      config
    );
    return response;
  },
});

// Export the createCustomersApi

/**
 * Example usage:
 * import createCustomersApi from './path/to/api/customersApi';
 *
 * const customersApi = createCustomersApi('customers');
 *
 * const getAllCustomers = async (payload) => {
 *   try {
 *     const response = await customersApi.getAllCustomers(payload);
 *     // Handle response
 *   } catch (error) {
 *     // Handle error
 *   }
 * };
 */

export default createCustomersApi;
