import * as React from "react";

function InvoiceIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="Scroll">
        <path
          id="Vector"
          d="M15.625 13.75V4.99999C15.626 4.75347 15.5782 4.50919 15.4844 4.28123C15.3905 4.05328 15.2524 3.84617 15.0781 3.67186C14.9038 3.49754 14.6967 3.35947 14.4688 3.26561C14.2408 3.17174 13.9965 3.12395 13.75 3.12499H3.125"
          stroke={props.strokeColor ? props.strokeColor : "#475467"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M8.125 8.125H13.125"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M8.125 10.625H13.125"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M1.72656 6.25001C1.52417 6.0234 1.37988 5.751 1.30611 5.45626C1.23234 5.16152 1.2313 4.85327 1.30308 4.55804C1.37486 4.26281 1.51731 3.98944 1.71817 3.76147C1.91903 3.5335 2.17228 3.35776 2.45612 3.24937C2.73996 3.14099 3.04589 3.1032 3.34757 3.13926C3.64926 3.17533 3.93766 3.28417 4.18795 3.45641C4.43824 3.62866 4.64292 3.85915 4.78438 4.12804C4.92584 4.39693 4.99984 4.69617 5 5.00001V15C5.00016 15.3038 5.07416 15.6031 5.21562 15.872C5.35708 16.1409 5.56176 16.3714 5.81205 16.5436C6.06234 16.7158 6.35074 16.8247 6.65243 16.8607C6.95411 16.8968 7.26004 16.859 7.54388 16.7506C7.82772 16.6423 8.08097 16.4665 8.28183 16.2385C8.48269 16.0106 8.62514 15.7372 8.69692 15.442C8.7687 15.1467 8.76766 14.8385 8.69389 14.5438C8.62012 14.249 8.47583 13.9766 8.27344 13.75H17.0234C17.2648 14.0198 17.4229 14.3538 17.4786 14.7115C17.5343 15.0692 17.4852 15.4354 17.3373 15.7659C17.1895 16.0963 16.9491 16.3769 16.6452 16.5737C16.3414 16.7705 15.987 16.8752 15.625 16.875H6.875"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default InvoiceIcon;
