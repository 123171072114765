import * as React from "react";

function CheckMark(props) {
  return (
    <svg
      width="132"
      height="132"
      viewBox="0 0 132 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.6875 53.625L58.4203 82.5L43.3125 68.0625"
        stroke="#0086FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66 115.5C93.3381 115.5 115.5 93.3381 115.5 66C115.5 38.6619 93.3381 16.5 66 16.5C38.6619 16.5 16.5 38.6619 16.5 66C16.5 93.3381 38.6619 115.5 66 115.5Z"
        stroke="#0086FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckMark;
