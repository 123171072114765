import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createStaffUsers } from "../../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";

export const useAddNewUser = ({ onClose, setUpdateClient }) => {
  const dispatch = useDispatch();
  const businessInfoSchema = z.object({
    fullname: z
      .string()
      .min(3, "Full name is required!")
      .max(100, "Maximum 100 characters are allowed!"),
    phoneNumber: z
      .string()
      .min(3, "Phone Number must be at least 3 digits long")
      .max(20, "Phone Number should not exceet 20 digits!")
      .regex(
        /^\+\d+$/,
        "Phone Number must start with + and be followed by numeric digits!"
      ),
    email: z
      .string()
      .min(3, "Email is required!")
      .max(100, "Maximum 100 characters are allowed!")
      .email("Please provide a proper email address"),
    role: z.string().min(1, "Role is Required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(businessInfoSchema),
  });

  const onCancel = () => {
    onClose();
  };

  const onSubmit = (data) => {
    const payload = {
      fullName: data?.fullname,
      email: data?.email,
      role: data?.role,
      phoneNumber: data?.phoneNumber,
    };
    dispatch(
      createStaffUsers({
        payload,
      })
    )
      .then(() => {
        setUpdateClient((prev) => !prev);
        onClose();
      })
      .catch((error) => {
        console.error("Update profile failed:", error);
      });

    onClose();
  };

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   */

  /**
   * Custom hook for managing the create business account form.
   *
   * @returns {Object} An object containing methods and states for form handling.
   * {
   *   onSubmit: Function - Handles form submission,
   *   handleBack: Function - Handles navigation to the previous step,
   *   handleSubmit: Function from react-hook-form - Handles form validation and submission,
   *   register: Function from react-hook-form - Registers form inputs for validation and usage,
   *   errors: Object - Holds form validation errors from react-hook-form,
   * }
   * Usage Example:
   * Accesses form handling methods:
   * const { onSubmit, handleBack, handleSubmit, register, errors } = useCreateAccountForm();
   *
   * onSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * handleBack:
   *  Example usage: <button onClick={handleBack}>Back</button>
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * register:
   *  Example usage: <input {...register("companyName", { required: true })} />
   * errors:
   *  Example usage: {errors.companyName && <span>Company name is required</span>}
   */

  return {
    onSubmit,
    handleSubmit,
    register,
    errors,
    isSubmitting,
    onCancel,
    isValid
  };
};

export default useAddNewUser;
