// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createPurchaseInvoiceApi from "./PurchaseInvoice";
import { showToast } from "../../../components/common/utils/showToast.util";
import store from "../../../store/store";

/**
 * Manages state for purchase invoices.
 * Defines initial state for each purchase invoice action.
 */
const initialState = {
  getAllPurchaseInvoices: {
    data: null,
    totalPages: 0,
    totalRecords: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSinglePurchaseInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addPurchaseInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updatePurchaseInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deletePurchaseInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the purchase invoice process.
 * These action types are prefixed with the 'purchase-invoice' base path for better organization.
 */
const BASE = "purchase-invoices";

export const ActionTypes = {
  GET_ALL_PURCHASE_INVOICES: `${BASE}/get-all`,
  ADD_PURCHASE_INVOICE: `${BASE}`,
  UPDATE_PURCHASE_INVOICE: `${BASE}/update`,
  DELETE_PURCHASE_INVOICE: `${BASE}/delete`,
};

// Creating an instance of the purchase invoices service with a base URL 'purchase-invoice'
const purchaseInvoiceService = createPurchaseInvoiceApi("purchase-invoices");

/**
 * Initiates the getAllPurchaseInvoices process for purchase invoices.
 * @param {object} payload - Payload containing query parameters.
 */
export const getAllPurchaseInvoices = createAsyncThunk(
  ActionTypes.GET_ALL_PURCHASE_INVOICES,

  async ({ payload }, thunkAPI) => {
    const response = await purchaseInvoiceService.getAllPurchaseInvoices(
      payload
    );
    if (response?.data?.Succeeded) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

/**
 * Initiates the getSinglePurchaseInvoice process for purchase invoices.
 * @param {string} invoiceId - ID of the purchase invoice.
 */
export const getSinglePurchaseInvoice = createAsyncThunk(
  `${BASE}/getSinglePurchaseInvoice`,

  async ({ invoiceId }, thunkAPI) => {
    const response = await purchaseInvoiceService.getSinglePurchaseInvoice(
      invoiceId
    );
    return response?.data?.data;
  }
);

/**
 * Initiates the updatePurchaseInvoice process for purchase invoices.
 * @param {string} invoiceId - ID of the purchase invoice.
 * @param {object} payload - Payload containing updated data.
 */
export const updatePurchaseInvoice = createAsyncThunk(
  ActionTypes.UPDATE_PURCHASE_INVOICE,

  async ({ invoiceId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await purchaseInvoiceService.updatePurchaseInvoice(
        invoiceId,
        payload
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Purchase invoice updated successfully!");
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addPurchaseInvoice process for purchase invoices.
 * @param {object} payload - Payload containing data for the new purchase invoice.
 */
export const addPurchaseInvoice = createAsyncThunk(
  ActionTypes.ADD_PURCHASE_INVOICE,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await purchaseInvoiceService.addPurchaseInvoice(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deletePurchaseInvoice process for purchase invoices.
 * @param {string} invoiceId - ID of the purchase invoice to be deleted.
 */
export const deletePurchaseInvoice = createAsyncThunk(
  ActionTypes.DELETE_PURCHASE_INVOICE,

  async ({ invoiceId, successCallBack }, thunkAPI) => {
    try {
      const response = await purchaseInvoiceService.deletePurchaseInvoice(
        invoiceId
      );
      if (response?.data?.Succeeded) {
        showToast(
          "Purchase invoice deleted successfully!",
          "success",
          async () => {
            const undoResponse =
              await purchaseInvoiceService.undoPurchaseInvoice(invoiceId);
            if (undoResponse?.data?.Succeeded) {
              const payload = {
                page: 1,
                pageSize: 10,
                sortColumn: "id",
                order: {
                  id: "DESC",
                },
                condition: {},
                attributes: {},
              };
              store.dispatch(getAllPurchaseInvoices({ payload }));
            }
          }
        );
        successCallBack(response);
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for purchase invoice related state management.
 */
export const purchaseInvoiceSlice = createSlice({
  name: "purchaseInvoice",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllPurchaseInvoices action.
     */
    reset: (state) => {
      state.getAllPurchaseInvoices = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSinglePurchaseInvoice = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllPurchaseInvoices action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllPurchaseInvoices.pending, (state) => {
        state.getAllPurchaseInvoices.isLoading = true;
        state.getAllPurchaseInvoices.message = "";
        state.getAllPurchaseInvoices.isError = false;
        state.getAllPurchaseInvoices.isSuccess = false;
        state.getAllPurchaseInvoices.data = null;
      })
      /**
       * Updates the state when getAllPurchaseInvoices action is fulfilled/successful.
       * Updates loading and success flags and sets getAllPurchaseInvoices data with the payload.
       */
      .addCase(getAllPurchaseInvoices.fulfilled, (state, action) => {
        state.getAllPurchaseInvoices.isLoading = false;
        state.getAllPurchaseInvoices.isSuccess = true;
        state.getAllPurchaseInvoices.data = action.payload.data;
        state.getAllPurchaseInvoices.totalPages = action.payload.totalPages;
        state.getAllPurchaseInvoices.totalRecords = action.payload.TotalRecords;
      })
      /**
       * Updates the state when getAllPurchaseInvoices action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllPurchaseInvoices.rejected, (state, action) => {
        state.getAllPurchaseInvoices.message = action.payload?.message;
        state.getAllPurchaseInvoices.isLoading = false;
        state.getAllPurchaseInvoices.isError = true;
        state.getAllPurchaseInvoices.data = null;
      })
      /**
       * Updates the state while the getSinglePurchaseInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSinglePurchaseInvoice.pending, (state) => {
        state.getSinglePurchaseInvoice.isLoading = true;
        state.getSinglePurchaseInvoice.message = "";
        state.getSinglePurchaseInvoice.isError = false;
        state.getSinglePurchaseInvoice.isSuccess = false;
        state.getSinglePurchaseInvoice.data = null;
      })
      /**
       * Updates the state when getSinglePurchaseInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets getSinglePurchaseInvoice data with the payload.
       */
      .addCase(getSinglePurchaseInvoice.fulfilled, (state, action) => {
        state.getSinglePurchaseInvoice.isLoading = false;
        state.getSinglePurchaseInvoice.isSuccess = true;
        state.getSinglePurchaseInvoice.data = action.payload;
      })
      /**
       * Updates the state when getSinglePurchaseInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSinglePurchaseInvoice.rejected, (state, action) => {
        state.getSinglePurchaseInvoice.message = action.payload?.message;
        state.getSinglePurchaseInvoice.isLoading = false;
        state.getSinglePurchaseInvoice.isError = true;
        state.getSinglePurchaseInvoice.data = null;
      })
      /**
       * Updates the state while the addPurchaseInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addPurchaseInvoice.pending, (state) => {
        state.addPurchaseInvoice.isLoading = true;
        state.addPurchaseInvoice.message = "";
        state.addPurchaseInvoice.isError = false;
        state.addPurchaseInvoice.isSuccess = false;
        state.addPurchaseInvoice.data = null;
      })
      /**
       * Updates the state when addPurchaseInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets addPurchaseInvoice data with the payload.
       */
      .addCase(addPurchaseInvoice.fulfilled, (state, action) => {
        state.addPurchaseInvoice.isLoading = false;
        state.addPurchaseInvoice.isSuccess = true;
        state.addPurchaseInvoice.data = action.payload;
      })
      /**
       * Updates the state when addPurchaseInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addPurchaseInvoice.rejected, (state, action) => {
        state.addPurchaseInvoice.message = action.payload?.message;
        state.addPurchaseInvoice.isLoading = false;
        state.addPurchaseInvoice.isError = true;
        state.addPurchaseInvoice.data = null;
      })
      /**
       * Updates the state while the updatePurchaseInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(updatePurchaseInvoice.pending, (state) => {
        state.updatePurchaseInvoice.isLoading = true;
        state.updatePurchaseInvoice.message = "";
        state.updatePurchaseInvoice.isError = false;
        state.updatePurchaseInvoice.isSuccess = false;
        state.updatePurchaseInvoice.data = null;
      })
      /**
       * Updates the state when updatePurchaseInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets updatePurchaseInvoice data with the payload.
       */
      .addCase(updatePurchaseInvoice.fulfilled, (state, action) => {
        state.updatePurchaseInvoice.isLoading = false;
        state.updatePurchaseInvoice.isSuccess = true;
        state.updatePurchaseInvoice.data = action.payload;
      })
      /**
       * Updates the state when updatePurchaseInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updatePurchaseInvoice.rejected, (state, action) => {
        state.updatePurchaseInvoice.message = action.payload?.message;
        state.updatePurchaseInvoice.isLoading = false;
        state.updatePurchaseInvoice.isError = true;
        state.updatePurchaseInvoice.data = null;
      })
      /**
       * Updates the state while the deletePurchaseInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(deletePurchaseInvoice.pending, (state) => {
        state.deletePurchaseInvoice.isLoading = true;
        state.deletePurchaseInvoice.message = "";
        state.deletePurchaseInvoice.isError = false;
        state.deletePurchaseInvoice.isSuccess = false;
        state.deletePurchaseInvoice.data = null;
      })
      /**
       * Updates the state when deletePurchaseInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets deletePurchaseInvoice data with the payload.
       */
      .addCase(deletePurchaseInvoice.fulfilled, (state, action) => {
        state.deletePurchaseInvoice.isLoading = false;
        state.deletePurchaseInvoice.isSuccess = true;
        state.deletePurchaseInvoice.data = action.payload;
      })
      /**
       * Updates the state when deletePurchaseInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(deletePurchaseInvoice.rejected, (state, action) => {
        state.deletePurchaseInvoice.message = action.payload?.message;
        state.deletePurchaseInvoice.isLoading = false;
        state.deletePurchaseInvoice.isError = true;
        state.deletePurchaseInvoice.data = null;
      });
  },
});

/**
 * Destructures the reset action from the purchaseInvoiceSlice actions.
 */
export const { reset } = purchaseInvoiceSlice.actions;

/**
 * Exports the default reducer generated by purchaseInvoiceSlice.
 */
export default purchaseInvoiceSlice.reducer;
