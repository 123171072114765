/**
 * Badge component representing a small informational indicator.
 * @param {Object} props - Component properties.
 * @param {ReactNode} props.children - Content inside the badge.
 * @param {string} props.className - Additional classes to apply to the badge.
 * @param {string} props.bgColor - Background color of the badge.
 * @param {string} props.padding - Padding around the badge content.
 * @param {function} props.onClick - Click event handler for the badge.
 * @returns {ReactNode} JSX element representing the badge.
 */
export const Badge = ({
  children,
  className = "",
  bgColor = "bg-neutral-50",
  padding = "px-3 py-1",
  onClick,
}) => {
  return (
    <div
      className={`${bgColor} ${padding} rounded-lg flex items-center justify-between ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

// Sample Usage:
// import React from 'react';
// import { Badge } from './Badge';
