import React from "react";
import {  Loader } from "../../../../common/components";
import { useTranslation } from "react-i18next";
import { CartDown } from "../../../../common/Icons/fontawesome";
import { Coins, CrossIcon } from "../../../../common/Icons";
import { useSplitEqually } from "./useSplitEqually";

function EquallyByPoints() {
  const {
    setIsOpen,
    isOpen,
    selectedCustomer,
    setSelectedCustomer,
    dropdownRef,
    customerListLoading,
    customerList,
    handleSelectCustomer,
  } = useSplitEqually();
  const { t } = useTranslation();
  return (
    <div>
      <p className="text-sm font-medium "> Choose Customer</p>
      <div className="relative flex flex-col">
        <button
          className="w-full px-4 py-2 text-left bg-white border rounded-lg flex justify-between items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>
            {selectedCustomer
              ? `${selectedCustomer?.name} - ${selectedCustomer?.points} Points`
              : t("pos_dashboard.select_customer")}
          </span>
          <CartDown />
          {selectedCustomer && (
            <div
              className="cursor-pointer"
              onClick={() => setSelectedCustomer(null)}
            >
              <CrossIcon />
            </div>
          )}
        </button>

        {isOpen && (
          <div
            ref={dropdownRef}
            className="absolute z-10 w-full bg-white border rounded-lg mt-12 h-28 overflow-auto"
          >
            {customerListLoading ? (
              <div className="flex items-center justify-center h-full">
                <Loader />
              </div>
            ) : (
              customerList?.map((customer) => (
                <div
                  key={customer.id}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
                  onClick={() => handleSelectCustomer(customer)}
                >
                  <span>{customer?.name}</span>
                  <span className="flex items-center gap-2 font-medium">
                    <Coins /> {customer?.points} Points
                  </span>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    
    </div>
  );
}

export default EquallyByPoints;
