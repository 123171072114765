import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdminCards } from "../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import {
  Customer,
  BlueBulb,
  RocketIcon,
  CurrencyCircleDollar,
  GitBranch,
  PresentationChart,
} from "../../../../common/Icons";
import { formatDateTime } from "../../../../../helpers/dateFormatter";
import { getAccessToken } from "../../../../common/utils/access-token.util";
/**
 * Custom hook for managing the create account form.
 * @returns {Object} An object containing methods and states for form handling and image upload validation.
 */
export const useClientProfile = (startDate, endDate) => {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();

  const { data: cardsData, isLoading: cardListLoading } = useSelector(
    (state) => state?.dashboard?.getAllAdminCards
  );

  const statsConfig = {
    activeCustomers: { icon: Customer, title: "Active Subscribers" },
    totalCustomerSales: {
      icon: PresentationChart,
      title: "Total Subscriber Sales",
    },
    branches: { icon: GitBranch, title: "Branches" },
    activeBasicPlans: {
      icon: CurrencyCircleDollar,
      title: "Active Basic Plan",
    },
    activeProfessionalPlans: {
      icon: BlueBulb,
      title: "Active Professional Plan",
    },
    activeEnterprisePlans: {
      icon: RocketIcon,
      title: "Active Enterprise Plan",
    },
  };

  useEffect(() => {
    const payload = {
      startDate: formatDateTime(startDate, "yyyy-mm-dd"),
      endDate: formatDateTime(endDate, "yyyy-mm-dd"),
    };
    if (accessToken) {
      dispatch(getAllAdminCards({ payload }));
    }
  }, [startDate, endDate, dispatch, accessToken]);

  return {
    cardsData,
    cardListLoading,
    statsConfig,
  };
};

export default useClientProfile;
