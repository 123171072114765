/**
 * Single source for all SVG icons.
 * Contains various icon components for different purposes.
 * @returns {Object} An object containing imported icon components.
 */

import AppleStore from "./AppleStore";
import CheckCircle from "./CheckCircle";
import FacebookIcon from "./FacebookIcon";
import GoogleIcon from "./GoogleIcon";
import GooglePlay from "./GooglePlay";
import GreenCheckCircle from "./GreenCheckCircle";
import LockIcon from "./LockIcon";
import SignoutIcon from "./SignoutIcon";
import SearchIcon from "./SearchIcon";
import ArrowUp from "./ArrowUp";
import ArrowDown from "./ArrowDown";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import Logo from "./Logo";
import LogoLg from "./LogoLg";
import QuestionIcon from "./QuestionIcon";
import TickIcon from "./TickIcon";
import EnvelopIcon from "./EnvelopIcon";
import LogoWhite from "./LogoWhite";
import EnvelopSm from "./EnvelopSm";
import CheckIcon from "./CheckIcon";
import LogoGram from "./LogoGram";
import DrawerIcon from "./DrawerIcon";
import ReceiptIcon from "./ReceiptIcon";
import BoxesIcon from "./BoxesIcon";
import DeviceTable from "./DeviceTable";
import SignalIcon from "./SignalIcon";
import SettingsIcon from "./SettingsIcon";
import PlusIcon from "./PlusIcon";
import DownloadIcon from "./DownloadIcon";
import HomeIcon from "./HomeIcon";
import InvoiceIcon from "./InvoiceIcon";
import ReportIcon from "./ReportIcon";
import StackIcon from "./StackIcon";
import HarddriveIcon from "./HarddriveIcon";
import MessagesIcon from "./MessagesIcon";
import CrossIcon from "./CrossIcon";
import ToggleIcon from "./ToggleIcon";
import CaretRight from "./CaretRight";
import LightBulb from "./LightBulb";
import CheckMark from "./CheckMark";
import Rocket from "./Rocket";
import RocketLaunch from "./RocketLaunch";
import CreditCard from "./CreditCard";
import TamaraPayment from "./TamaraPayment";
import Cross from "./Cross";
import ProfilePicture from "./ProfilePicture";
import TrashIcon from "./TrashIcon";
import UserIcon from "./UserIcon";
import ChartLine from "./ChartLine";
import CurrencyDollar from "./CurrencyDollar";
import CaretUpDown from "./CaretUpDown";
import BellIcon from "./BellIcon";
import ProfileIcon from "./ProfileIcon";
import UserList from "./UserList";
import Users from "./Users";
import Notifications from "./Notifications";
import LockSimple from "./LockSimple";
import XcrossIcon from "./XcrossIcon";
import RightArrow from "./RightArrow";
import ToggleOff from "./ToggleOff";
import ToggleOn from "./ToggleOn";
import ThreeDotsIcon from "./ThreeDotsIcon";
import ArrowUpSide from "./ArrowUpSide";
import DeleteIcon from "./DeleteIcon";
import Printer from "./Printer";
import BoundingBox from "./BoundingBox";
import ArchiveBox from "./ArchiveBox";
import CodeSandBox from "./CodeSandBox";
import FluentBoxIcon from "./FluentBoxIcon";
import QrCodeIcon from "./QrCodeIcon";
import Chats from "./Chats";
import GmailIcon from "./Gmail";
import PhoneIcon from "./PhoneIcon";
import VerticalDots from "./VerticalDots";
import PencilIcon from "./PencilIcon";
import LeftArrow from "./LeftArrow";
import NextArrow from "./NextArrow";
import DefaultImg from "./DefaultImg";
import PageNotFound from "./PageNotFound";
import PaymentSucces from "./PaymentSuccess";
import Coins from "./Coins";
import PrinterIcon from "./PrinterIcon";
import UploadIcon from "./UploadIcon";
import Note from "./Note";
import MinusIcon from "./MinusIcon";
import GrayNote from "./GrayNote";
import CurrencySign from "./CurrencySign";
import Card from "./Card";
import VerticalBlackCoin from "./VerticalBlackCoin";
import Truck from "./Truck";
import GreenIndicator from "./GreenIndicator";
import WhiteIndicator from "./WhiteIndicator";
import NoTable from "./NoTable";
import BlueIndicator from "./BlueIndicator";
import CalendarIcon from "./CalendarIcon";
import Customer from "./Customer";
import PresentationChart from "./PresentationChart";
import GitBranch from "./GitBranch";
import CurrencyCircleDollar from "./CurrencyCircleDollar";
import BlueBulb from "./BlueBulb";
import RocketIcon from "./RocketIcon";
import Visa from "./Visa";
import FunnelSimple from "./FunnelSimple";
import GreenArrowUp from "./GreenArrowUp";
import RedArrowDown from "./RedArrowDown";
import EyeIcon from "./EyeIcon";
import CoinIcon from "./CoinIcon";
import CardIcon from "./CardIcon";
import CashIcon from "./CashIcon";
import TruckIcon from "./TruckIcon";
import MasterCard from "./MasterCard";
import EditIcon from "./EditIcon";
import CopyIcon from "./CopyIcon";
import PlusCircle from "./PlusCircle";
import FileIcon from "./FileIcon";
import CheckCircleTick from "./CheckCircleTick";
import CheckCircleError from "./CheckCircleError";
import TamaraIcon from "./TamaraIcon";
import DashboardIcon from "./DashboardIcon";
import PosIcon from "./PosIcon";
import BlueCheck from "./BlueCheck";
import GrayDot from "./GrayDot";
import ChervonLeft from "./ChervonLeft";
import ChervonRight from "./ChervonRight";
import Warning from "./Warning";
import Tag from "./Tag";
import RestaurantIcon from "./RestaurantIcon";
import SuperMarketIcon from "./SuperMarketIcon";
import CarServiceIcon from "./CarServiceIcon";
import ExpressIcon from "./ExpressIcon";
import GiftShopIcon from "./GiftShopIcon";
import CoffeeIcon from "./CoffeeIcon";
import ShopIcon from "./ShopIcon";
import FoodTruckIcon from "./FoodTruckIcon";
import HairDresserIcon from "./HairDresserIcon";
import DeviceMobile from "./DeviceMobile";
import CloudArrowUp from "./CloudArrowUp";
import CloudSlash from "./CloudSlash";
import TrendUp from "./TrendUp";
import ChartPieSlice from "./ChartPieSlice";
import HamburgerIcon from "./HamburgerIcon";
import LocationIcon from "./LocationIcon";
import RedIcon from "./RedIcon";
import GlobeWhite from "./GlobeWhite";
import GraphIcon from "./GraphIcon";
import RocketsIcon from "./RocketsIcon";

export {
  PageNotFound,
  RedIcon,
  Tag,
  BlueCheck,
  Warning,
  GrayDot,
  PlusCircle,
  EditIcon,
  RedArrowDown,
  Visa,
  GreenArrowUp,
  BlueBulb,
  FunnelSimple,
  RocketIcon,
  CurrencyCircleDollar,
  GitBranch,
  PresentationChart,
  CalendarIcon,
  WhiteIndicator,
  Customer,
  BlueIndicator,
  NoTable,
  GreenIndicator,
  Truck,
  Card,
  VerticalBlackCoin,
  GrayNote,
  CurrencySign,
  MinusIcon,
  Note,
  Coins,
  PaymentSucces,
  DefaultImg,
  NextArrow,
  LeftArrow,
  RightArrow,
  GmailIcon,
  PencilIcon,
  VerticalDots,
  PhoneIcon,
  Chats,
  DeleteIcon,
  Printer,
  ToggleOff,
  ArrowUpSide,
  XcrossIcon,
  LockSimple,
  Notifications,
  Users,
  UserList,
  ProfileIcon,
  BellIcon,
  Cross,
  TamaraPayment,
  AppleStore,
  RocketLaunch,
  CheckCircle,
  GreenCheckCircle,
  LockIcon,
  FacebookIcon,
  GoogleIcon,
  GooglePlay,
  SignoutIcon,
  SearchIcon,
  ArrowUp,
  ArrowDown,
  ArrowRight,
  ArrowLeft,
  Logo,
  LogoLg,
  QuestionIcon,
  TickIcon,
  EnvelopIcon,
  LogoWhite,
  EnvelopSm,
  CheckIcon,
  ProfilePicture,
  LogoGram,
  BoxesIcon,
  DrawerIcon,
  ReceiptIcon,
  DeviceTable,
  SignalIcon,
  SettingsIcon,
  PlusIcon,
  DownloadIcon,
  HomeIcon,
  ReportIcon,
  HarddriveIcon,
  InvoiceIcon,
  StackIcon,
  MessagesIcon,
  CrossIcon,
  ToggleIcon,
  CaretRight,
  LightBulb,
  CheckMark,
  Rocket,
  CreditCard,
  TrashIcon,
  UserIcon,
  ChartLine,
  CurrencyDollar,
  CaretUpDown,
  ToggleOn,
  ThreeDotsIcon,
  BoundingBox,
  ArchiveBox,
  FluentBoxIcon,
  CodeSandBox,
  QrCodeIcon,
  PrinterIcon,
  UploadIcon,
  EyeIcon,
  CoinIcon,
  CardIcon,
  CashIcon,
  TruckIcon,
  MasterCard,
  CopyIcon,
  FileIcon,
  CheckCircleTick,
  CheckCircleError,
  TamaraIcon,
  DashboardIcon,
  PosIcon,
  ChervonLeft,
  ChervonRight,
  RestaurantIcon,
  SuperMarketIcon,
  CarServiceIcon,
  ExpressIcon,
  GiftShopIcon,
  CoffeeIcon,
  ShopIcon,
  FoodTruckIcon,
  HairDresserIcon,
  DeviceMobile,
  CloudArrowUp,
  CloudSlash,
  TrendUp,
  ChartPieSlice,
  HamburgerIcon,
  LocationIcon,
  GlobeWhite,
  GraphIcon,
  RocketsIcon,
};

// sampel usage
// import {AppleIcon} from "path/to/icons"
