import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createReportsApi from "./ReportsService";
import { showToast } from "../../components/common/utils/showToast.util";

// Initial state structure defining various properties related to report processes.
const initialState = {
  // State for generating sales report
  generateSalesReport: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  // State for generating purchase report
  generatePurchaseReport: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  generateTaxReport: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  generateProfitLossReport: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  generateExpenseReport: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  generateInventoryReport: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

// Base path for report actions
const BASE = "reports";

// Action types related to report process
export const ActionTypes = {
  GENERATE_SALES_REPORT: `${BASE}/generate-sale-report`,
  GENERATE_PURCHASE_REPORT: `${BASE}/generate-purchase-report`,
  GENERATE_TAX_REPORT: `${BASE}/generate-tax-report`,
  GENERATE_PROFITLOSS_REPORT: `${BASE}/generate-profit-loss-report`,
  GENERATE_EXPENSE_REPORT: `${BASE}/generate-expense-report`,
  GENERATE_INVENTORY_REPORT: `${BASE}/generate-inventory-report`,
};

// Creating an instance of the reports service with a base URL 'reports'
const reportsService = createReportsApi("reports");

// Thunk action to generate sales report
export const generateSalesReport = createAsyncThunk(
  ActionTypes.GENERATE_SALES_REPORT,

  async ({ payload }, thunkAPI) => {
    try {
      const response = await reportsService.generateSalesReport(payload);

      if (response?.data?.Succeeded) {
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

// Thunk action to generate purchase report
export const generatePurchaseReport = createAsyncThunk(
  ActionTypes.GENERATE_PURCHASE_REPORT,

  async ({ payload }, thunkAPI) => {
    try {
      const response = await reportsService.generatePurchaseReport(payload);
      if (response?.data?.Succeeded) {
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

// Thunk action to generate tax report
export const generateTaxReport = createAsyncThunk(
  ActionTypes.GENERATE_TAX_REPORT,

  async ({ payload }, thunkAPI) => {
    const response = await reportsService.generateTaxReport(payload);
    return response?.data?.data;
  }
);

// Thunk action to generate profitloss report
export const generateProfitLossReport = createAsyncThunk(
  ActionTypes.GENERATE_PROFITLOSS_REPORT,

  async ({ payload }, thunkAPI) => {
    const response = await reportsService.generateProfitLossReport(payload);
    return response?.data?.data;
  }
);

// Thunk action to generate expense report
export const generateExpenseReport = createAsyncThunk(
  ActionTypes.generateExpenseReport,

  async ({ payload }, thunkAPI) => {
    const response = await reportsService.generateExpenseReport(payload);
    return response?.data?.data;
  }
);

// Thunk action to generate inventory report
export const generateInventoryReport = createAsyncThunk(
  ActionTypes.GENERATE_INVENTORY_REPORT,

  async ({ payload }, thunkAPI) => {
    const response = await reportsService.generateInventoryReport(payload);
    return response?.data?.data;
  }
);

// Slice for managing report related state
export const reportsSlice = createSlice({
  name: "reports",
  initialState,

  // Reducers to modify the state
  reducers: {
    // Resetting state for report actions
    reset: (state) => {
      state.generateSalesReport = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.generatePurchaseReport = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.generateTaxReport = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.generateProfitLossReport = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.generateExpenseReport = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.generateInventoryReport = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },

  // Handling state updates based on asynchronous actions
  extraReducers: (builder) => {
    builder
      // Handling pending state for generating sales report
      .addCase(generateSalesReport.pending, (state) => {
        state.generateSalesReport.isLoading = true;
        state.generateSalesReport.message = "";
        state.generateSalesReport.isError = false;
        state.generateSalesReport.isSuccess = false;
        state.generateSalesReport.data = null;
      })
      // Handling successful state for generating sales report
      .addCase(generateSalesReport.fulfilled, (state, action) => {
        state.generateSalesReport.isLoading = false;
        state.generateSalesReport.isSuccess = true;
        state.generateSalesReport.data = action.payload;
      })
      // Handling failed state for generating sales report
      .addCase(generateSalesReport.rejected, (state, action) => {
        state.generateSalesReport.message = action?.payload?.message;
        state.generateSalesReport.isLoading = false;
        state.generateSalesReport.isError = true;
        state.generateSalesReport.data = null;
      })
      // Handling pending state for generating purchase report
      .addCase(generatePurchaseReport.pending, (state) => {
        state.generatePurchaseReport.isLoading = true;
        state.generatePurchaseReport.message = "";
        state.generatePurchaseReport.isError = false;
        state.generatePurchaseReport.isSuccess = false;
        state.generatePurchaseReport.data = null;
      })
      // Handling successful state for generating purchase report
      .addCase(generatePurchaseReport.fulfilled, (state, action) => {
        state.generatePurchaseReport.isLoading = false;
        state.generatePurchaseReport.isSuccess = true;
        state.generatePurchaseReport.data = action.payload;
      })
      // Handling failed state for generating purchase report
      .addCase(generatePurchaseReport.rejected, (state, action) => {
        state.generatePurchaseReport.message = action?.payload?.message;
        state.generatePurchaseReport.isLoading = false;
        state.generatePurchaseReport.isError = true;
        state.generatePurchaseReport.data = null;
      })
      // Handling pending state for generating tax report
      .addCase(generateTaxReport.pending, (state) => {
        state.generateTaxReport.isLoading = true;
        state.generateTaxReport.message = "";
        state.generateTaxReport.isError = false;
        state.generateTaxReport.isSuccess = false;
        state.generateTaxReport.data = null;
      })
      // Handling successful state for generating tax report
      .addCase(generateTaxReport.fulfilled, (state, action) => {
        state.generateTaxReport.isLoading = false;
        state.generateTaxReport.isSuccess = true;
        state.generateTaxReport.data = action.payload;
      })
      // Handling failed state for generating tax report
      .addCase(generateTaxReport.rejected, (state, action) => {
        state.generateTaxReport.message = action?.payload?.message;
        state.generateTaxReport.isLoading = false;
        state.generateTaxReport.isError = true;
        state.generateTaxReport.data = null;
      })
      // Handling pending state for generating profit loss report
      .addCase(generateProfitLossReport.pending, (state) => {
        state.generateProfitLossReport.isLoading = true;
        state.generateProfitLossReport.message = "";
        state.generateProfitLossReport.isError = false;
        state.generateProfitLossReport.isSuccess = false;
        state.generateProfitLossReport.data = null;
      })
      // Handling successful state for generating profit loss report
      .addCase(generateProfitLossReport.fulfilled, (state, action) => {
        state.generateProfitLossReport.isLoading = false;
        state.generateProfitLossReport.isSuccess = true;
        state.generateProfitLossReport.data = action.payload;
      })
      // Handling failed state for generating profit loss report
      .addCase(generateProfitLossReport.rejected, (state, action) => {
        state.generateProfitLossReport.message = action?.payload?.message;
        state.generateProfitLossReport.isLoading = false;
        state.generateProfitLossReport.isError = true;
        state.generateProfitLossReport.data = null;
      })
      // Handling pending state for generating expense report
      .addCase(generateExpenseReport.pending, (state) => {
        state.generateExpenseReport.isLoading = true;
        state.generateExpenseReport.message = "";
        state.generateExpenseReport.isError = false;
        state.generateExpenseReport.isSuccess = false;
        state.generateExpenseReport.data = null;
      })
      // Handling successful state for generating expense report
      .addCase(generateExpenseReport.fulfilled, (state, action) => {
        state.generateExpenseReport.isLoading = false;
        state.generateExpenseReport.isSuccess = true;
        state.generateExpenseReport.data = action.payload;
      })
      // Handling failed state for generating expense report
      .addCase(generateExpenseReport.rejected, (state, action) => {
        state.generateExpenseReport.message = action?.payload?.message;
        state.generateExpenseReport.isLoading = false;
        state.generateExpenseReport.isError = true;
        state.generateExpenseReport.data = null;
      })
      // Handling pending state for generating inventory report
      .addCase(generateInventoryReport.pending, (state) => {
        state.generateInventoryReport.isLoading = true;
        state.generateInventoryReport.message = "";
        state.generateInventoryReport.isError = false;
        state.generateInventoryReport.isSuccess = false;
        state.generateInventoryReport.data = null;
      })
      // Handling successful state for generating inventory report
      .addCase(generateInventoryReport.fulfilled, (state, action) => {
        state.generateInventoryReport.isLoading = false;
        state.generateInventoryReport.isSuccess = true;
        state.generateInventoryReport.data = action.payload;
      })
      // Handling failed state for generating inventory report
      .addCase(generateInventoryReport.rejected, (state, action) => {
        state.generateInventoryReport.message = action?.payload?.message;
        state.generateInventoryReport.isLoading = false;
        state.generateInventoryReport.isError = true;
        state.generateInventoryReport.data = null;
      });
  },
});

// Destructuring the reset action from the reportsSlice actions
export const { reset } = reportsSlice.actions;

// Exporting the default reducer generated by reportsSlice
export default reportsSlice.reducer;
