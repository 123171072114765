import InputGroup from "../../../common/components/InputGroup/InputGroup";
import CustomButton from "../../../common/components/Button/Button";
import usePosUpdateProfile from "./usePosUpdateProfile";
import { ToggleOff, ToggleOn } from "../../../common/Icons";
import Loader from "../../../common/components/Loader/Loader";
import { useTranslation } from "react-i18next";

function PosUpdateProfile({ PosUpdateProfile, setModalOpen }) {
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    singleUserLoading,
    setSplitInvoice,
    splitInvoice,
    isDirty,
  } = usePosUpdateProfile({ PosUpdateProfile, setModalOpen });
  const { t } = useTranslation();

  if (singleUserLoading) {
    return <Loader />;
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 ">
        {/* Company Name */}
        <InputGroup
          htmlfor="name"
          labelText={t("pos_dashboard.name")}
          inputName="name"
          inputType="name"
          register={register}
          errors={errors}
        />
        <div>
          <CustomButton
            text={t("pos_dashboard.save_changes")}
            type="submit"
            width="10%"
            padding="px-2 py-3"
            disabled={!isDirty}
          />{" "}
        </div>
      </form>

      {/* typeOfBusiness*/}
      <div>
        <div className="flex items-center justify-between mt-4">
          <label className="text-sm font-semibold">
            {" "}
            {t("pos_dashboard.split_invoice")}
          </label>
          <div onClick={() => setSplitInvoice(!splitInvoice)}>
            {splitInvoice ? <ToggleOn /> : <ToggleOff />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PosUpdateProfile;
