import React, { Suspense, useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { AppLoader } from "../../../common/components";
import { useUserContext } from "../../../../context/UserContext";
import BlurBackground from "../../../common/components/BlurBackground/BlurBackground";

/**
 * ClientDashboardLayout component.
 * Renders the main ClientDashboardLayout structure including Navbar, Sidebar, and child components.
 *
 * @param {Object} children - The child components to be rendered within the ClientDashboardLayout.
 * @returns {JSX.Element} JSX code for rendering the ClientDashboardLayout component.
 */

const ClientDashboardLayout = ({ children }) => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const isPosUser =
    user?.role === "CASHIER" ||
    user?.role === "SALES MANAGER" ||
    user?.role === "SYSTEM MANAGER";

  const isClient = user?.role === "CLIENT";

  const isPosUserOnClient = user?.isPosUser && isPosUser;

  useEffect(() => {
    if (!isClient && !isPosUserOnClient) {
      navigate("/login");
    }
  }, [isClient, navigate, isPosUserOnClient]);

  return (
    <div>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="lg:ps-[265px]">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* <div className="relative"> */}
          {/* <div style={{ position: "fixed", zIndex: 9999, width: "100%" }}> */}
            {/* <BlurBackground /> */}
          {/* </div> */}
          <main className="p-6">
            <Suspense fallback={<AppLoader />}>
              <Outlet />
            </Suspense>
          </main>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ClientDashboardLayout;

// sample usage
// import ClientDashboardLayout from "./path/to/ClientDashboardLayout"
// <ClientDashboardLayout></ClientDashboardLayout>
