import { ArrowLeft, Logo } from "../../Icons";
import CustomButton from "../Button/Button";
import { LanguageSwitcher } from "../LanguageSwitcher/LanguageSwitcher";

/**
 * Navbar component for the application.
 * Displays the application's logo and additional branding or icons.
 *
 */
const Navbar = ({ fastCode = false, handleBackEvent, language, className }) => {
  return (
    <header>
      <nav
        className={`bg-white px-4 py-6 flex justify-between items-center w-full ${className}`}
      >
        {fastCode ? (
          <CustomButton
            text="Back"
            icon={<ArrowLeft />}
            border="border"
            width="w-24"
            textColor="text-text-primary"
            hoverTextColor="text-text-primary"
            padding="pl-0 pr-2 py-2"
            onClick={handleBackEvent}
          />
        ) : (
          <Logo />
        )}
        {language && <LanguageSwitcher />}
      </nav>
    </header>
  );
};

export default Navbar;
