// Setting.jsx
import React, { useState } from "react";
import SettingLayout from "./SettingLayout/SettingLayout";
import UpdatePassword from "./AdminUpdatePassword/UpdatePassword";
import UpdateProfile from "./AdminUpdateProfile/UpdateProfile";
import AdminNotifications from "./Notifications/AdminNotifications";
import CustomAdminModal from "../../../../../components/common/components/AdminModal/CustomAdminModal";
import AdminUsers from "./AdminUsers/AdminUsers";
const Setting = ({ isModalOpen, setModalOpen }) => {
  const [activeComponent, setActiveComponent] = useState("Profile");
  const onClose = () => setModalOpen(false);
  /**
   * The `Setting` component represents the settings modal that includes
   * options for updating profile, password, and notifications.
   * @param {boolean} isModalOpen - Indicates whether the modal is open.
   * @param {function} setModalOpen - Function to toggle the modal's visibility.
   */
  return (
    <CustomAdminModal
      isOpen={isModalOpen}
      onClose={() => setModalOpen(false)}
      width="min-[280px]:w-[100%] min-[1080px]:w-[1080px]"
      height="700px"
      showHeader={false}
      showFooter={false}
    >
      <div className="flex overflow-hidden">
        <SettingLayout
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
          onClose={onClose} // Passing onClose function to SettingLayout
        >
          {activeComponent === "Profile" && <UpdateProfile onClose={onClose} />}
          {activeComponent === "Password" && <UpdatePassword  onClose={onClose}/>}
          {activeComponent === "Notifications" && <AdminNotifications />}
          {activeComponent === "Users" && <AdminUsers />}
        </SettingLayout>
      </div>
    </CustomAdminModal>
  );
};

export default Setting;
