import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Button/Button";

const BlurBackground = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };
  const handlePlanClick = () => {
    navigate("/management/settings?tab=Subscription");
  };

  return (
    <div className="relative w-full h-screen flex items-center justify-center pr-2 ">
      {/* Centered content on top */}
      <div className="p-8 bg-white bg-gray-200 rounded-lg shadow-lg w-[600px] z-10">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Oops!</h2>
        <p className="text-black text-lg mb-4">
          This Feature is not included in your plan. If you want to access this
          feature then press on Upgrade Plan
        </p>

        <div className="flex flex-row justify-center gap-4 items-center">
          <div>
            <CustomButton
              text="Home Page"
              width="w-fit"
              onClick={handleButtonClick}
              bgColor="!bg-surface-primary"
              hoverTextColor="text-white"
              className="hover:!text-white"
              textColor="text-white"
            />
          </div>
          <div>
            <CustomButton
              text="Upgrade Plan"
              width="w-fit"
              bgColor="!bg-surface-primary"
              hoverTextColor="text-white"
              className="hover:!text-white"
              textColor="text-white"
              onClick={handlePlanClick}
            />
          </div>
        </div>
      </div>
      <div className="absolute top-0 w-full h-screen bg-gradient-to-t from-white via-gray-10 to-transparent backdrop-blur-sm"></div>
    </div>
  );
};

export default BlurBackground;
