import React from "react";
import { CustomButton } from "../common/components";
import { AppleStore, ArrowRight, GooglePlay, LogoGram } from "../common/Icons";
import { useTranslation } from "react-i18next";
import { Facebook, Instagram, Twitter, Youtube } from "../../assets/images";
import { LanguageSwitcher } from "../common/components/LanguageSwitcher/LanguageSwitcher";
import { Link } from "react-router-dom";

const Footer = ({ className }) => {
  const { t } = useTranslation();

  return (
    <footer
      className={`footer-landing-page pt-24 pb-12 xl:px-29 px-6 m-4 !rounded-4xl ${className}`}
    >
      <div className="flex flex-col text-center items-center">
        <LogoGram className="w-24 h-24" />
        <h3 className="text-white md:text-5xl text-3xl font-semibold mt-4">
          {t("landingpage.footer.heading")}{" "}
        </h3>
        <p className="text-white font-medium text-base opacity-70 mt-4">
          {t("landingpage.footer.text")}
        </p>
        <div className="flex items-center gap-3 w-fit mx-auto mt-4">
          <CustomButton text={t("landingpage.footer.links.getstarted")} width="w-fit" />
          <CustomButton
            text={t("landingpage.footer.links.lettalk")}
            textColor="text-text-primary"
            border="border"
            width="w-fit"
            icon={<ArrowRight className="rtl:rotate-180" stroke />}
            iconPosition="after"
            padding="px-4 py-2"
          />
        </div>
        {/* social icons */}
        <div className="flex items-center gap-x-5 gap-y-3 mt-8">
          {socialIcons.map((item) => (
            <img src={item.icon} alt={item.alt} key={item.alt} />
          ))}
        </div>
      </div>
      {/* main links */}
      <div className="grid md:grid-cols-5 xs:grid-cols-3 grid-cols-2 py-12 mt-8 md:gap-1 gap-4">
        <div className="flex flex-col">
          <h5 className="text-base text-white font-semibold mb-3">
           {t("landingpage.footer.links.businesstype")}
          </h5>
          <ul className=" rtl:text-right ltr:text-left space-y-3">
            <li className="text-white text-base font-normal opacity-75">
            {t("landingpage.footer.links.restaurant")}

            </li>
            <li className="text-white text-base font-normal opacity-75">
            {t("landingpage.footer.links.coffeeshop")}
            </li>
            <li className="text-white text-base font-normal opacity-75">
            {t("landingpage.footer.links.shop")}

            </li>
            <li className="text-white text-base font-normal opacity-75">
           {t("landingpage.footer.links.supermarket")}
            </li>
            <li className="text-white text-base font-normal opacity-75">
            {t("landingpage.footer.links.foodtruck")}

            </li>
            <li className="text-white text-base font-normal opacity-75">
            {t("landingpage.footer.links.giftshop")}

            </li>
            <li className="text-white text-base font-normal opacity-75" i>
            {t("landingpage.footer.links.carservice")}            </li>
            <li className="text-white text-base font-normal opacity-75">
            {t("landingpage.footer.links.hairdresser")}            </li>
            <li className="text-white text-base font-normal opacity-75">
            {t("landingpage.footer.links.expressservices")}            </li>
          </ul>
        </div>

        <div className="flex flex-col">
          <h5 className="text-base text-white font-semibold mb-3"> {t("landingpage.footer.links.company")}</h5>
          <ul className="rtl:text-right ltr:text-left space-y-3">
            <li className="text-white text-base font-normal opacity-75">
            {t("landingpage.footer.links.about")}
            </li>
            <li className="text-white text-base font-normal opacity-75">
            {t("landingpage.footer.links.careers")}
            </li>
            <li className="text-white text-base font-normal opacity-75">
              {t("landingpage.footer.links.shop")}
            </li>
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/faqs">{t("landingpage.footer.links.faq")}</Link>
            </li>
          </ul>
        </div>

        <div className="flex flex-col">
          <h5 className="text-base text-white font-semibold mb-3">{t("landingpage.footer.links.link")}</h5>
          <ul className="rtl:text-right ltr:text-left space-y-3">
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/features">{t("landingpage.footer.links.features")}</Link>
            </li>
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/pricing">{t("landingpage.footer.links.pricing")}</Link>
            </li>
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/contact-us">{t("landingpage.footer.links.contactus")}</Link>
            </li>
          </ul>
        </div>

        <div className="flex flex-col">
          <h5 className="text-base text-white font-semibold mb-3">{t("landingpage.footer.links.legal")}</h5>
          <ul className="rtl:text-right ltr:text-left space-y-3">
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/terms">{t("landingpage.footer.links.terms")}</Link>
            </li>
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/privacy-policy">{t("landingpage.footer.links.privacy")}</Link>
            </li>
          </ul>
        </div>

        <div className="flex-col space-y-8 xs:flex hidden">
          <div>
            <h5 className="text-base text-white font-semibold mb-3">
            {t("landingpage.footer.links.downloadpos")}
            </h5>
            <div className="flex items-center gap-3">
              <AppleStore />
              <GooglePlay />
            </div>
          </div>
          <div>
            <h5 className="text-base text-white font-semibold mb-3">
            {t("landingpage.footer.links.downloaddashboard")}
            </h5>
            <div className="flex items-center gap-3">
              <AppleStore />
              <GooglePlay />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-col space-y-8 xs:hidden flex pb-12">
        <div>
          <h5 className="text-base text-white font-semibold mb-3">
          {t("landingpage.footer.links.downloadpos")}
          </h5>
          <div className="flex items-center gap-3">
            <AppleStore />
            <GooglePlay />
          </div>
        </div>
        <div>
          <h5 className="text-base text-white font-semibold mb-3">
          {t("landingpage.footer.links.downloaddashboard")}
          </h5>
          <div className="flex items-center gap-3">
            <AppleStore />
            <GooglePlay />
          </div>
        </div>
      </div>
      <hr className="bg-text-gray" />
      {/* copyright */}
      <div className="flex items-center justify-between border-t border-text-gray py-8">
        <p className="text-sm text-white">
        {t("landingpage.footer.links.copyright")}
        </p>
        <LanguageSwitcher
          position="bottom-full end-0"
          className="text-white"
          globeColor="white"
        />
      </div>
    </footer>
  );
};

export default Footer;

const socialIcons = [
  { alt: "Facebook", icon: Facebook },
  { alt: "Instagram", icon: Instagram },
  { alt: "Twitter", icon: Twitter },
  { alt: "Youtube", icon: Youtube },
];
