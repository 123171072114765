import React from "react";
import {
  Card,
  CurrencySign,
  PaymentSucces,
  Truck,
  VerticalBlackCoin,
} from "../../../../common/Icons";
import { CustomButton } from "../../../../common/components";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

function PaymentSuccess({ handleClose, orderCheckoutPaid }) {
console.log('orderCheckoutPaid :', orderCheckoutPaid);
  const { t } = useTranslation();

  // Destructure orderCheckoutPaid for cleaner code
  const {
    orderNo,
    date,
    service,
    customer,
    orderItems,
    paymentDetails,
  } = orderCheckoutPaid;

  const { paymentMethod, checkoutType, subTotal, discountApplied, total, VAT } =
    paymentDetails;

  // Determine the appropriate payment icon and text
  const paymentIcons = {
    CARD: <Card />,
    POINTS: <VerticalBlackCoin />,
    CASH: <CurrencySign />,
    DELIVERY_COMPANIES: <Truck />,
  };

  const paymentTextMapping = {
    CARD: "Card",
    POINTS: "Points",
    CASH: "Cash",
    DELIVERY_COMPANIES: "Delivery Company",
  };

  const paymentIcon = paymentIcons[paymentMethod];
  const paymentText = paymentTextMapping[paymentMethod];

  // Function to render order details based on checkoutType
  const renderOrderDetails = () => {
    return orderItems?.map((item, index) => (
      <div className="flex justify-between pb-2" key={index}>
        <span className="flex-1 text-sm font-medium">{item.itemName}</span>
        <div className="flex-[2]  ">
          <span className="text-sm font-normal text-text-gray">
            x{item.quantity}
          </span>
          <span className="text-sm font-normal text-text-gray">
            {item.slug}
          </span>
        </div>
        <span className=" text-sm font-medium">{item.price} SAR</span>
      </div>
    ));
  };

  // Fetch user details from localStorage
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const store = storedUserData?.user?.store ?? "-";
  const fullName = storedUserData?.user?.fullName ?? "-";

  return (
    <div className="flex justify-center items-center">
      <div className="bg-white p-4 rounded w-full ">
        <div className="justify-center items-center flex">
          <PaymentSucces />
        </div>
        <span className="flex items-center justify-center text-lg font-medium">
          {t("pos_dashboard.payment_successfull")}
        </span>
        <span className="flex items-center justify-center mb-2 text-lg font-medium">
          {store?.branchName}
        </span>
        <span className="flex items-center text-text-gray justify-center mb-4 text-sm font-normal">
          {fullName}, {store?.streetName}, {store?.districtName},{" "}
          {store?.buildingNumber}, {store?.city}
        </span>
        <div className="bg-white rounded-lg mt-5 p-2">
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.order_no")}
            </span>
            <span className="text-sm font-medium">{orderNo}</span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.date")}
            </span>
            <span className="text-sm font-medium">{date}</span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.time")}
            </span>
            <span className="text-sm font-medium">
              {moment()?.format("hh:mm a")}
            </span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.service")}
            </span>
            <span className="text-sm font-medium">{service}</span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.created_by")}
            </span>
            <span className="text-sm font-medium">{fullName}</span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.customer")}
            </span>
            <span className="text-sm font-medium">{customer}</span>
          </div>
          <hr className="border mb-2" />
          <div className="flex justify-between pb-2 pt-4">
            <span className="text-sm font-normal text-text-gray">
              {t("pos_dashboard.order_detail")}
            </span>
          </div>
          <div className="h-auto overflow-auto">{renderOrderDetails()}</div>

          <hr className="border mb-2" />
          <div className="flex justify-between pb-2 pt-4">
            <span className="text-sm font-normal text-text-gray">
              {t("pos_dashboard.payments")}
            </span>
          </div>
          <div className="flex justify-between pb-2 items-center">
            <span className="text-sm font-medium">
              {t("pos_dashboard.payment_method")}
            </span>
            <div className="flex items-center">
              {paymentIcon}
              <span className="text-sm font-medium ml-1">{paymentText}</span>
            </div>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.sub_total")}
            </span>
            <span className="text-sm font-medium">
              {subTotal.toFixed(2)} SAR
            </span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.discount_applied")}
            </span>
            <span className="text-sm font-medium">
              {discountApplied.toFixed(2)} SAR
            </span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.vat")}
            </span>
            <span className="text-sm font-medium">{VAT.toFixed(2)} SAR</span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.total")}
            </span>
            <span className="text-sm font-medium">{total.toFixed(2)} SAR</span>
          </div>
        </div>
        <hr className="border" />
        <div className="w-full pt-2">
          <CustomButton
            text={t("pos_dashboard.done")}
            type="button"
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
