import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Tabs component.
 * Renders a tab navigation UI for selecting different tabs.
 *
 * @param {Object[]} tabs - Array of tab objects containing name and current properties.
 * @param {string} tabs[].name - Name of the tab.
 * @param {boolean} tabs[].current - Flag to denote the current active tab.
 * @param {Function} onTabChange - Function to handle tab change event.
 * @param {string} activeTab - Name of the currently active tab.
 * @returns {JSX.Element} JSX code for rendering the Tabs component.
 */

const Tabs = ({ tabs, onTabChange, activeTab }) => {
  const { t } = useTranslation();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => onTabChange(tab.value)}
                className={classNames(
                  tab.value === activeTab
                    ? "border-surface-primary text-text-primary font-medium"
                    : "border-transparent text-text-secondary hover:border-gray-300 hover:text-gray-700 font-normal",
                  "whitespace-nowrap border-b-2 pb-1 px-1 text-sm"
                )}
                aria-current={tab.value === activeTab ? "page" : undefined}
              >
                {t(tab.name)}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;

// Sample Usage:
// import Tabs from "path/to/Tabs";
//
// const tabs = [
//   { name: "Tab 1", current: true },
//   { name: "Tab 2", current: false },
//   Add more tabs as needed...
// ];
//
// const handleTabChange = (tabName) => {
//   Handle tab change logic
// };
//
// Use the Tabs component with required props
// <Tabs tabs={tabs} onTabChange={handleTabChange} activeTab="Tab 1" />
// (Pass the tabs array, a function to handle tab change, and the active tab name as props)
