import * as React from "react";

function BellIcon(props) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8492_4922)">
        <rect x="2" y="1" width="40" height="40" rx="8" fill="white" />
        <rect
          x="2.5"
          y="1.5"
          width="39"
          height="39"
          rx="7.5"
          stroke="#EAECF0"
        />
        <path
          d="M25.7501 30C25.7501 30.1989 25.671 30.3897 25.5304 30.5303C25.3897 30.671 25.199 30.75 25.0001 30.75H19.0001C18.8011 30.75 18.6104 30.671 18.4697 30.5303C18.3291 30.3897 18.2501 30.1989 18.2501 30C18.2501 29.8011 18.3291 29.6103 18.4697 29.4697C18.6104 29.329 18.8011 29.25 19.0001 29.25H25.0001C25.199 29.25 25.3897 29.329 25.5304 29.4697C25.671 29.6103 25.7501 29.8011 25.7501 30ZM30.7985 27C30.6684 27.2292 30.4794 27.4196 30.2512 27.5514C30.0229 27.6833 29.7636 27.7518 29.5001 27.75H14.5001C14.2364 27.7496 13.9774 27.6798 13.7494 27.5475C13.5213 27.4151 13.3321 27.225 13.2009 26.9963C13.0697 26.7676 13.0011 26.5083 13.0021 26.2446C13.003 25.9809 13.0735 25.7222 13.2063 25.4944C13.7266 24.5981 14.5001 22.0631 14.5001 18.75C14.5001 16.7609 15.2902 14.8532 16.6968 13.4467C18.1033 12.0402 20.0109 11.25 22.0001 11.25C23.9892 11.25 25.8968 12.0402 27.3034 13.4467C28.7099 14.8532 29.5001 16.7609 29.5001 18.75C29.5001 22.0622 30.2744 24.5981 30.7947 25.4944C30.9289 25.7225 30.9999 25.9822 31.0004 26.2468C31.0009 26.5114 30.9309 26.7714 30.7976 27H30.7985ZM29.5001 26.25C28.7754 25.0059 28.0001 22.1297 28.0001 18.75C28.0001 17.1587 27.3679 15.6326 26.2427 14.5074C25.1175 13.3821 23.5913 12.75 22.0001 12.75C20.4088 12.75 18.8826 13.3821 17.7574 14.5074C16.6322 15.6326 16.0001 17.1587 16.0001 18.75C16.0001 22.1306 15.2238 25.0069 14.5001 26.25H29.5001Z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8492_4922"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8492_4922"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8492_4922"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default BellIcon;
