import React, { useState } from "react";
import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import { Eye, EyeSlash } from "../../../../../common/Icons/fontawesome";
import CustomButton from "../../../../../common/components/Button/Button";
import useUpdatePassword from "./useUpdatePassword";

const UpdatePassword = ({ onClose }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, errors, handleSubmit, isDirty, isValid } =
    useUpdatePassword(onClose);

  return (
    <form className="space-y-4 mt-4 px-4 pb-44" onSubmit={handleSubmit}>
      {/* current password */}
      <div className="flex items-center">
        <p className="block text-sm font-normal text-text-primary w-1/4">
          Current Password
        </p>
        <InputGroup
          className="w-3/4"
          inputName="currentpassword"
          placeholder="Enter current password"
          register={register}
          errors={errors}
          inputType={showCurrentPassword ? "text" : "password"}
        >
          <span
            className="absolute right-2 top-1 pr-3 items-center cursor-pointer"
            onClick={() => setShowCurrentPassword((prev) => !prev)}
          >
            {showCurrentPassword ? (
              <Eye color="gray-700" />
            ) : (
              <EyeSlash color="gray-700" />
            )}
          </span>
        </InputGroup>
      </div>
      {/* new password */}
      <div className="flex items-center">
        <p className="block text-sm font-normal text-text-primary w-1/4">
          New Password
        </p>
        <InputGroup
          className="w-3/4"
          inputName="newpassword"
          placeholder="Enter New password"
          register={register}
          errors={errors}
          inputType={showNewPassword ? "text" : "password"}
        >
          <span
            className="absolute right-2 top-1 pr-3 items-center cursor-pointer"
            onClick={() => setShowNewPassword((prev) => !prev)}
          >
            {showNewPassword ? (
              <Eye color="gray-700" />
            ) : (
              <EyeSlash color="gray-700" />
            )}
          </span>
        </InputGroup>
      </div>
      {/* confirm password */}
      <div className="flex items-center">
        <p className="block text-sm font-normal text-text-primary w-1/4">
          Confirm New Password
        </p>
        <InputGroup
          className="w-3/4"
          inputName="confirmpassword"
          placeholder="Confirm New password"
          register={register}
          errors={errors}
          inputType={showConfirmPassword ? "text" : "password"}
        >
          <span
            className="absolute right-2 top-1 pr-3 items-center cursor-pointer"
            onClick={() => setShowConfirmPassword((prev) => !prev)}
          >
            {showConfirmPassword ? (
              <Eye color="gray-700" />
            ) : (
              <EyeSlash color="gray-700" />
            )}
          </span>
        </InputGroup>
      </div>

      {/* buttons */}
      <div className=" pl-52">
        <CustomButton
          type="submit"
          text="Save Changes"
          width="w-fit"
          disabled={!isDirty || !isValid}
        />{" "}
      </div>
    </form>
  );
};

export default UpdatePassword;
