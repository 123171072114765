import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { Loader, SelectGroup } from "../../../../../../common/components";
import { useDispatch, useSelector } from "react-redux";
import { viewClientDetailsSales } from "../../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { useParams } from "react-router-dom";
import { addMonths, format } from "date-fns";

const Role = [
  { value: "6", label: "Last 30 days" },
  { value: "1", label: "Last Month" },
  { value: "3", label: "Last 6 Months" },
  { value: "5", label: "Last Quarter" },
  { value: "2", label: "Last 3 Months" },
  { value: "7", label: "Last Year" },
  { value: "4", label: "Last 12 Months" },
];

function SalesChart() {
  const dispatch = useDispatch();
  const [selectedPeriodId, setSelectedPeriodId] = useState(Role[0].value);
  const { id } = useParams();
  const clientId = id;

  useEffect(() => {
    const payload = { periodId: +selectedPeriodId, userId: +clientId };
    dispatch(
      viewClientDetailsSales({
        payload,
      })
    );
  }, [clientId, selectedPeriodId, dispatch]);

  const { data: viewClientDetail, isLoading: cardListLoading } = useSelector(
    (state) => state.dashboard.viewClientDetailsSales
  );

  let labels = viewClientDetail?.responseObjectAccumulation?.map((item) => item.month) || [];
  let salesData = viewClientDetail?.responseObjectAccumulation?.map((item) => item.monthlySale) || [];

  // Check if there's only one month of data
  if (labels.length === 1 && salesData.length === 1) {
    const singleMonth = new Date(labels[0]);
    const previousMonth = format(addMonths(singleMonth, -1), "MMMM yyyy");
    labels = [previousMonth, ...labels];
    salesData = [0, ...salesData];
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Net Income",
        data: salesData.length > 0 ? salesData : [0],
        fill: false,
        borderColor: "#2E90FA",
        tension: 0.1,
        pointRadius: 2, // Dot size on the line
        pointHoverRadius: 6, // Dot size on hover
      },
    ],
  };

  const options = {
    scales: {
      x: { grid: { display: false } },
      y: {
        beginAtZero: true,
        grid: { borderDash: [5, 5] },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            const sale = context.raw;
            return `${sale} SAR`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  if (cardListLoading) {
    return (
      <div className="items-center flex px-40 py-12">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center space-x-4">
        <span className="flex items-center flex-grow text-text-primary font-semibold text-xl">
          Sales
        </span>
        <SelectGroup
          htmlFor="role"
          inputName="role"
          width="w-fit"
          options={Role}
          textColor="text-text-primary"
          className="!py-1"
          value={selectedPeriodId}
          onChange={(e) => setSelectedPeriodId(e.target.value)}
        />
      </div>
      <div className="flex justify-center mt-4">
        <div className="w-full" style={{ height: "300px" }}>
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default SalesChart;
