import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notficationSetting, toggleSetting } from "../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";

const useAdminNotificationSetting = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);

  const { data: notifications, isLoading: isLoader } = useSelector((state) => state.dashboard.notficationSetting);

  useEffect(() => {
    dispatch(notficationSetting());
  }, [dispatch]);

  useEffect(() => {
    if (notifications) {
      const initialSettings = notifications.map((item) => ({
        id: item.id,
        isEnabled: item.isEnabled,
      }));
      setSettings(initialSettings);
    }
  }, [notifications]);

  const handleToggle = (id) => {
    const updatedSettings = settings.map((item) =>
      item.id === id ? { ...item, isEnabled: !item.isEnabled } : item
    );
    setSettings(updatedSettings);
    dispatch(toggleSetting({ payload: {settings: updatedSettings} }));
  };

  return {
    settings,
    handleToggle,
    isLoader,
  };
};

export default useAdminNotificationSetting;
