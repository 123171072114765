import * as React from "react";

function ArrowLeft(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="ArrowLeft">
        <path
          id="Vector"
          d="M21.5001 12.0004C21.5001 12.1993 21.4211 12.3901 21.2804 12.5307C21.1398 12.6714 20.949 12.7504 20.7501 12.7504H6.06041L11.5307 18.2198C11.6004 18.2895 11.6557 18.3722 11.6934 18.4632C11.7311 18.5543 11.7505 18.6519 11.7505 18.7504C11.7505 18.849 11.7311 18.9465 11.6934 19.0376C11.6557 19.1286 11.6004 19.2114 11.5307 19.281C11.461 19.3507 11.3783 19.406 11.2873 19.4437C11.1962 19.4814 11.0986 19.5008 11.0001 19.5008C10.9016 19.5008 10.804 19.4814 10.7129 19.4437C10.6219 19.406 10.5392 19.3507 10.4695 19.281L3.71948 12.531C3.64974 12.4614 3.59443 12.3787 3.55668 12.2876C3.51894 12.1966 3.49951 12.099 3.49951 12.0004C3.49951 11.9019 3.51894 11.8043 3.55668 11.7132C3.59443 11.6222 3.64974 11.5394 3.71948 11.4698L10.4695 4.71979C10.6102 4.57906 10.8011 4.5 11.0001 4.5C11.1991 4.5 11.39 4.57906 11.5307 4.71979C11.6715 4.86052 11.7505 5.05139 11.7505 5.25042C11.7505 5.44944 11.6715 5.64031 11.5307 5.78104L6.06041 11.2504H20.7501C20.949 11.2504 21.1398 11.3294 21.2804 11.4701C21.4211 11.6107 21.5001 11.8015 21.5001 12.0004Z"
          fill="#1A1A1A"
        />
      </g>
    </svg>
  );
}

export default ArrowLeft;
