import api from "../../services/api";

/**
 * Creates clinet settings related API endpoints.
 * @returns {Object} Object containing client settings-related API methods.
 */

/**
 * get user profile information using profile id
 * @param {id} profileId - profile id for profile information.
 * @returns {Promise} A Promise that resolves to the profile response.
 */
const getClientProfile = async (payload) => {
  const response = await api().post("/user",payload);
  return response;
  
};


/**
 * Creates clinet settings related API endpoints.
 * @returns {Object} Object containing client settings-related API methods.
 */

/**
 * get user profile information using profile id
 * @param {id} profileId - profile id for profile information.
 * @returns {Promise} A Promise that resolves to the profile response.
 */
const getSingleClientProfile = async (profileId) => {
  const response = await api().get(`user/${profileId}`);
  return response;
};

/**
 * get user profile information using profile id
 * @param {id} profileId - profile id for profile information.
 * @returns {Promise} A Promise that resolves to the profile response.
 */
const deleteClientProfile = async (profileId) => {
    const response = await api().delete(`user/${profileId}`);
    return response;
  };

/**
 * update profile information using profileId
 * @param {id} profileId - profileId for profile information.
 * @returns {Promise} A Promise that resolves to the signup response.
 */
const updateClientProfile = async (profileId, payload) => {
  const response = await api().post(`user/${profileId}`, payload);
  return response;
};


/**
 * Object that encapsulates functions for client settings operations.
 */
const clientSettingsService = {
  getClientProfile, // Function for getting user profile
  updateClientProfile, // Function for updating user profile
  deleteClientProfile, // Function for deleting user profile
  getSingleClientProfile
};

export default clientSettingsService; // export clientSettingsService

// sample usage

// import clientSettingsService from "../../path/clientSettingsService";

// Sample user profile ID and business ID
// const userProfileId = "123456";
// const businessId = "789012";

//  Fetch user profile information
// clientSettingsService.getClientProfile(userProfileId)
//   .then((profileResponse) => {
//     console.log("User Profile Information:", profileResponse.data);
//   })
//   .catch((error) => {
//     console.error("Error fetching user profile:", error);
//   });
