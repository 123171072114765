import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUser } from "../../../../common/utils/users.utils";
import { getProfile } from "../../../../../features/Auth/AuthSlice";
import { singleStaffUser } from "../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";

const useSideBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const [userName, setUserName] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [adminId, setAdminId] = useState("");
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);

  useEffect(() => {
    const storedUserData = getUser("user");
    if (storedUserData) {
      const parsedData = JSON.parse(storedUserData);
      const id = parsedData?.user?.profile?.id;
      const staffId = parsedData?.user?.id;
      const role = parsedData?.user?.role;

      if (role === "SUPER_ADMIN" && id) {
        setAdminId(id);
        fetchUserProfile(id);
      } else if (staffId) {
        fetchStaffUserProfile(staffId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStaffUserProfile = (staffId) => {
    dispatch(singleStaffUser({ staffId, successCallBack }));
  };

  const successCallBack = (response) => {
    const userData = response?.data?.data;
    setUserName(userData.fullName);
    setProfilePic(userData.profilePic); 
  };

  const fetchUserProfile = (id) => {
    dispatch(getProfile({ payload: { id } }))
      .then((response) => {
        setUserName(response.payload.data.fullName);
        setProfilePic(response.payload.data.profilePic);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return {
    isDropdownOpen,
    toggleDropdown,
    userName,
    profilePic,
    adminId,
    storedUserData,
  };
};

export default useSideBar;
