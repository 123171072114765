import * as React from "react";

function Visa(props) {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.875"
        y="0.5"
        width="34"
        height="23"
        rx="3.5"
        fill="white"
        stroke="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0003 16.2582H8.87994L7.28995 10.1924C7.21449 9.91334 7.05425 9.66667 6.81854 9.5504C6.23031 9.25823 5.58212 9.0257 4.875 8.90843V8.6749H8.29067C8.76208 8.6749 9.11564 9.0257 9.17457 9.43313L9.99954 13.8086L12.1188 8.6749H14.1802L11.0003 16.2582ZM15.3588 16.2582H13.3563L15.0052 8.6749H17.0077L15.3588 16.2582ZM19.5984 10.7757C19.6573 10.3673 20.0109 10.1337 20.4233 10.1337C21.0715 10.0751 21.7776 10.1924 22.3669 10.4835L22.7204 8.85081C22.1312 8.61727 21.483 8.5 20.8948 8.5C18.9512 8.5 17.537 9.55041 17.537 11.0082C17.537 12.1173 18.5387 12.6996 19.2458 13.0504C20.0109 13.4002 20.3055 13.6338 20.2466 13.9835C20.2466 14.5082 19.6573 14.7418 19.0691 14.7418C18.3619 14.7418 17.6548 14.5669 17.0077 14.2747L16.6541 15.9085C17.3612 16.1996 18.1262 16.3169 18.8334 16.3169C21.0126 16.3745 22.3669 15.3251 22.3669 13.75C22.3669 11.7665 19.5984 11.6502 19.5984 10.7757ZM29.375 16.2582L27.785 8.6749H26.0772C25.7236 8.6749 25.3701 8.90843 25.2522 9.25823L22.3079 16.2582H24.3693L24.7808 15.1502H27.3136L27.5493 16.2582H29.375ZM26.3718 10.7171L26.96 13.5751H25.3111L26.3718 10.7171Z"
        fill="#172B85"
      />
    </svg>
  );
}

export default Visa;
