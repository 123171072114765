import * as React from "react";

function Customer(props) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3706_51621)">
        <rect x="2" y="1" width="40" height="40" rx="8" fill="white" />
        <rect
          x="2.5"
          y="1.5"
          width="39"
          height="39"
          rx="7.5"
          stroke="#EAECF0"
        />
        <path
          d="M30.1172 27.5626C28.951 25.5536 27.0843 24.0458 24.875 23.3282C25.934 22.6985 26.7569 21.7385 27.2174 20.5957C27.6779 19.4529 27.7504 18.1906 27.4239 17.0026C27.0973 15.8146 26.3898 14.7667 25.4099 14.0198C24.43 13.2729 23.2321 12.8684 22 12.8684C20.768 12.8684 19.57 13.2729 18.5901 14.0198C17.6103 14.7667 16.9027 15.8146 16.5762 17.0026C16.2496 18.1906 16.3222 19.4529 16.7826 20.5957C17.2431 21.7385 18.066 22.6985 19.125 23.3282C16.9157 24.0458 15.049 25.5536 13.8828 27.5626C13.8256 27.6567 13.7953 27.7648 13.7953 27.8751C13.7953 27.9853 13.8256 28.0934 13.8828 28.1876C13.9362 28.2833 14.0145 28.3629 14.1094 28.4179C14.2043 28.4729 14.3122 28.5013 14.4219 28.5001H29.5781C29.6878 28.5013 29.7958 28.4729 29.8906 28.4179C29.9855 28.3629 30.0638 28.2833 30.1172 28.1876C30.1745 28.0934 30.2047 27.9853 30.2047 27.8751C30.2047 27.7648 30.1745 27.6567 30.1172 27.5626Z"
          fill="#0086FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3706_51621"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3706_51621"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3706_51621"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Customer;
