import React from "react";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";

/**
 * DynamicButton component.
 * Renders a button with customizable styles using Tailwind CSS classes.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.width='w-full'] - The width of the button (Tailwind CSS class).
 * @param {string} [props.bgColor='bg-surface-primary'] - The background color of the button (Tailwind CSS class).
 * @param {string} [props.textColor='text-white'] - The text color of the button (Tailwind CSS class).
 * @param {string} [props.border='border-none'] - The border of the button (Tailwind CSS class).
 * @param {string} [props.borderRadius='rounded-lg'] - The border radius of the button (Tailwind CSS class).
 * @param {string} [props.padding='px-4 py-2'] - Padding inside the button (Tailwind CSS class).
 * @param {string} [props.fontSize='text-base'] - Font size of the button text (Tailwind CSS class).
 * @param {string} [props.fontWeight='font-medium'] - Font weight of the button text (Tailwind CSS class).
 * @param {string} [props.hoverBgColor='bg-blue-600'] - Background color of the button on hover (Tailwind CSS class).
 * @param {string} [props.hoverTextColor='text-white'] - Text color of the button on hover (Tailwind CSS class).
 * @param {string} [props.focusRingColor='ring-2 ring-blue-300'] - Focus ring color of the button (Tailwind CSS class).
 * @param {boolean} [props.disabled=false] - If true, the button will be disabled.
 * @param {Function} props.onClick - The function to call when the button is clicked.
 * @param {string} [props.text='Button'] - The text content of the button.
 * @param {string} [props.type='button'] - The type attribute for the button element.
 * @param {string} [props.className=''] - The className attribute for the button element.
 * @param {string} [props.icon] - The icon attribute for the button element.
 * @returns {JSX.Element} JSX code for rendering the DynamicButton component.
 */
const CustomButton = ({
  width = "w-full",
  bgColor = "bg-surface-primary",
  textColor = "text-white",
  border = "border-none",
  borderRadius = "rounded-lg",
  padding = "px-4 py-3",
  fontSize = "text-base",
  fontWeight = "font-medium",
  hoverBgColor = "",
  hoverTextColor = "",
  focusRingColor = "ring-2 ring-blue-300",
  disabled = false,
  onClick,
  text = "",
  type = "button",
  icon,
  isLoading = false,
  iconPosition = "before",
  className = "",
  customStyles = {},
  children,
  ...props
}) => {
  const { t } = useTranslation();

  const renderContent = () => {
    return (
      <>
        {iconPosition === "before" && icon}
        {isLoading ? <Loader className="text-white !w-4 !h-4" /> : t(text)}
        {iconPosition === "after" && icon}
      </>
    );
  };
  return (
    <button
      type={type}
      className={`focus:outline-none focus:!ring-0 leading-none flex items-center justify-center ${
        icon &&
        "gap-2 border-border-primary shadow-sm hover:text-text-primary bg-white hover:bg-gray-100"
      } ${width} ${bgColor} ${textColor} ${border} ${borderRadius} 
      ${padding} ${fontSize} ${fontWeight} hover:bg-${hoverBgColor} hover:!text-${hoverTextColor} 
      focus:${focusRingColor} disabled:bg-neutral-300 ${
        disabled
          ? "!bg-neutral-300 !hover:bg-neutral-300 !cursor-not-allowed"
          : ""
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
      {...props}
      style={customStyles}
    >
      {renderContent()}
      {children}
    </button>
  );
};

export default CustomButton;

// Example usage:
// <DynamicButton
//   onClick={() => console.log('Button clicked')}
//   text="Click me"
//   disabled={true} // This button will be disabled
//   iconPosition="after" // Icon will be displayed after the text
// />
