import * as React from "react";

function EditIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M15.9827 5.15936L12.8412 2.0171C12.7367 1.9126 12.6127 1.82971 12.4762 1.77316C12.3397 1.71661 12.1934 1.6875 12.0456 1.6875C11.8978 1.6875 11.7515 1.71661 11.615 1.77316C11.4785 1.82971 11.3545 1.9126 11.25 2.0171L2.57977 10.688C2.47485 10.7921 2.39167 10.916 2.33506 11.0525C2.27844 11.1891 2.24953 11.3355 2.25001 11.4833V14.6255C2.25001 14.9239 2.36853 15.21 2.57951 15.421C2.79049 15.632 3.07664 15.7505 3.37501 15.7505H6.51727C6.66506 15.751 6.81147 15.7221 6.94799 15.6655C7.08451 15.6089 7.20842 15.5257 7.31251 15.4208L15.9827 6.75053C16.0872 6.64606 16.1701 6.52203 16.2267 6.38553C16.2832 6.24902 16.3123 6.10271 16.3123 5.95495C16.3123 5.80719 16.2832 5.66088 16.2267 5.52437C16.1701 5.38786 16.0872 5.26383 15.9827 5.15936ZM6.51727 14.6255H3.37501V11.4833L9.56251 5.29577L12.7048 8.43803L6.51727 14.6255ZM13.5 7.6421L10.3577 4.50053L12.0452 2.81303L15.1875 5.9546L13.5 7.6421Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export default EditIcon;
