import React from "react";
import { LockIcon } from "../../../components/common/Icons";
import Navbar from "../../../components/common/components/Navbar/Navbar";
import Footer from "../../../components/common/components/Footer/Footer";
import RecoverEmail from "../recover-email/recover-email";
import { usePasswordRecovery } from "./use-forgot-password.hook";
import CustomButton from "../../../components/common/components/Button/Button";
import InputGroup from "../../../components/common/components/InputGroup/InputGroup";
import { useTranslation } from "react-i18next";

/**
 * ForgetPassword component.
 * Renders a form to recover a user account by sending an email link to reset the password.
 * Includes a lock icon, input field for email, and a send button.
 * If the form is submitted, it renders the RecoverEmail component.
 *
 * @returns {JSX.Element} JSX code for rendering the ForgetPassword component.
 */

const ForgetPassword = () => {
  const { t } = useTranslation();

  const { isSubmitted, register, handleSubmit, errors, onSubmit } =
    usePasswordRecovery();

  if (isSubmitted) {
    return <RecoverEmail />;
  }

  return (
    <div className="w-full flex flex-col justify-between min-h-screen">
      <Navbar />
      <div className="max-w-[480px] mx-auto">
        {/* lock icon */}
        <LockIcon className="mx-auto" />
        {/* heading */}
        <h2 className="text-3xl font-semibold mb-3 text-center text-text-primary mt-3">
          {t("recover_account.name")}
        </h2>
        <p className="text-text-secondary text-base font-normal text-center">
          {t("recover_account.description")}
        </p>
        {/* form */}
        <form onSubmit={handleSubmit(onSubmit)} className="mt-10">
          {/* email */}
          <InputGroup
            htmlfor="email"
            labelText={t("recover_account.email")}
            inputName="email"
            placeholder={t("recover_account.enter_email")}
            inputType="email"
            register={register}
            errors={errors}
          />
          <CustomButton
            text={t("buttons.send")}
            type="submit"
            className="mt-3"
          />
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ForgetPassword;

// Sample Usage:
// import ForgetPassword  from '.path/ForgetPassword';
// <ForgetPassword />
