import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentMethods } from "../../../../features/Payments/PaymentsSlice";

export const useCheckout = () => {
  const [activeSplitType, setActiveSplitType] = useState("full");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  const orderCheckoutData = useSelector(
    (state) => state.posOrder.orderCheckoutData
  );
  // Styles remain the same
  const activeTabStyle =
    "bg-white my-1 text-blue-500 px-4 w-full py-1 rounded-lg";
  const inactiveTabStyle =
    "bg-gray-100 text-gray-500 px-4 w-full py-1 rounded-lg";

  return {
    setActiveSplitType,
    activeTabStyle,
    inactiveTabStyle,
    activeSplitType,
    orderCheckoutData,
  };
};
