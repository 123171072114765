import * as React from "react";

function Printer(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Printer">
        <path
          id="Vector"
          d="M4.5 5.625V2.8125H13.5V5.625"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M13.5 10.6875H4.5V15.4688H13.5V10.6875Z"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M4.5 12.375H1.96875V6.75C1.96875 6.13125 2.51719 5.625 3.18516 5.625H14.8148C15.4828 5.625 16.0312 6.13125 16.0312 6.75V12.375H13.5"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M13.2188 9C13.6847 9 14.0625 8.62224 14.0625 8.15625C14.0625 7.69026 13.6847 7.3125 13.2188 7.3125C12.7528 7.3125 12.375 7.69026 12.375 8.15625C12.375 8.62224 12.7528 9 13.2188 9Z"
          stroke="#1A1A1A"
        />
      </g>
    </svg>
  );
}

export default Printer;
