import React from "react";
import TopClientSales from './TopClientSales/TopClientSales'
import TopClientSalesTable from "./TopClientSalesTable/TopClientSalesTable";

function AdminSales() {
  return (
    <div>
      <div className="border rounded-lg px-2 ">
        <TopClientSales />
      </div>
      <div className="mt-4">
        <TopClientSalesTable />
      </div>
    </div>
  );
}

export default AdminSales;
