import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllSubscriptions } from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
import { formatDateTime } from "../../../../../helpers/dateFormatter";
import { getAccessToken } from "../../../../common/utils/access-token.util";

/**
 * Custom hook for managing the create account form.
 * @returns {Object} An object containing methods and states for form handling and image upload validation.
 */
export const useBillingHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = getAccessToken();

  const { data: subscriptiondata, isLoading: subscriptionLoading } =
    useSelector((state) => state.subscriptions.getAllSubscriptions);

  useEffect(() => {
    if (accessToken) {
      dispatch(
        getAllSubscriptions({
          payload: {
            page: 1,
            pageSize: 5,
            sortColumn: "id",
            order: {
              id: "DESC",
            },
            condition: {},
            attributes: {},
            periodId: 0,
          },
        })
      );
    }
  }, [accessToken, dispatch]);

  // Function to capitalize the first letter of the word inside parentheses
  const capitalizeBracketWord = (planType) => {
    return planType?.replace(/\(([^)]+)\)/, (match, p1) => {
      return `(${p1.charAt(0).toUpperCase() + p1.slice(1).toLowerCase()})`;
    });
  };

  // Function to handle the click event
  const handleShowAllClick = () => {
    navigate("/admin/subscriptions");
  };

  const tableBody = subscriptiondata?.data?.map((sub) => ({
    ...sub,
    invoiceno: sub.invoiceNumber,
    startdate: (
      <div>
        <span>{formatDateTime(sub.createdAt).split(" ")[0]}</span>
        <br />
        <span>{formatDateTime(sub.startdate).split(" ")[1]}</span>
      </div>
    ),
    client: sub.clientUser?.profile?.fullName,
    plan: capitalizeBracketWord(sub.planType),
    branches: sub.branches,
    billing: (
      <span className="bg-green-200 text-green-800 py-1 px-2 rounded-lg">
        {sub.typeOfBilling ?? "None"}
      </span>
    ),
    totalamount: sub.subTotal,
    status: sub.subscriptionStatus ? "Active" : "Inactive",
    paymentmethod: (
      <img className="h-6 w-6" src={sub.paymentMethod} alt="Bank" />
    ),
    subData: sub,
  }));

  const headers = [
    { label: "Invoice No", key: "invoiceno" },
    { label: "Date/Time", key: "startdate" },
    { label: "Client", key: "client" },
    { label: "Plan", key: "plan" },
    { label: "Add Branches", key: "branches" },
    { label: "Type of Billing", key: "billing" },
    { label: "Total Amount", key: "totalamount" },
    { label: "Status", key: "status" },
    { label: "Payment Method", key: "paymentmethod" },
  ];

  return {
    tableBody,
    headers,
    handleShowAllClick,
    subscriptionLoading,
  };
};

export default useBillingHistory;
