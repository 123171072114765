/**
 * This function takes in any number of class names as arguments and returns a string
 * containing only the truthy class names separated by a space.
 *
 * @param {...string} classes - The class names to be filtered and joined.
 * @returns {string} - The joined class names.
 */

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/**
 * Sample usage:
 * import { classNames } from "./generateClassNames";
 * const classes = classNames("bg-gray-100", "block px-4 py-2 text-sm text-gray-700");
 * console.log(classes); // logs "bg-gray-100 block px-4 py-2 text-sm text-gray-700"
 */
