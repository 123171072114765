// useStorage.js

const useStorage = {
  setItem(key, value) {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
      return true;
    } catch (error) {
      console.error("Error saving to Local Storage:", error);
      return false;
    }
  },

  getItem(key) {
    try {
      const serializedValue = localStorage.getItem(key);
      if (serializedValue === null) {
        return null;
      }
      return JSON.parse(serializedValue);
    } catch (error) {
      console.error("Error retrieving from Local Storage:", error);
      return null;
    }
  },

  removeItem(key) {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      console.error("Error removing from Local Storage:", error);
      return false;
    }
  },

  clearStorage() {
    try {
      localStorage.clear();
      return true;
    } catch (error) {
      console.error("Error clearing Local Storage:", error);
      return false;
    }
  },
};

export default useStorage;
