import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../features/Auth/AuthSlice";

const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

/**
 * Handles input change events for each form field.
 * Updates the formData state with the value of the changed input.
 *
 * @param {object} e - The event object from the input field.
 * This contains information about the input field that triggered the event.
 */
export const useResetPassword = () => {
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [isPasswordValid, setPasswordValid] = useState({
    minLength: false,
    specialChar: false,
    number: false,
    match: false,
  });

  const query = useQuery();
  const tokenFromUrl = query.get("token");
  const [passwordFieldsVisibility, setPasswordFieldsVisibility] = useState({
    password: false,
    confirmPassword: false,
  });
  const dispatch = useDispatch();

  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Function} watch - Function to watch changes in form inputs.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   */
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
  });

  /**
   * Handles form submission.
   * This function should contain the logic for processing form data upon submission.
   * This can include validation, making API calls, and navigating to other pages.
   *
   * @param {object} e - The event object from the form submission.
   */
  const onSubmit = (data) => {
    const payload = {
      token: tokenFromUrl,
      password: data.newPassword,
      passwordConfirm: data.confirmPassword,
    };
    dispatch(resetPassword({ payload, successCallBack: moveSingleRouter }));
  };

  const moveSingleRouter = (data) => {
    if (data?.data?.Succeeded) {
      setPasswordUpdated(true);
    }
  };

  /**
   * Validates the password against defined criteria.
   *
   * @param {string} password - The new password entered by the user.
   * @param {string} confirmPassword - The confirm password entered by the user.
   */
  const validatePassword = (password, confirmPassword) => {
    // Criteria: Minimum length of 8 characters
    const minLength = password.length >= 8;
    // Criteria: At least one special character from the provided set
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    // Criteria: At least one numerical digit
    const number = /\d/.test(password);
    // Criteria: Password matches the confirm password
    const match = password === confirmPassword;
    // Setting validation status based on criteria
    setPasswordValid({ minLength, specialChar, number, match });
  };

  /**
   * Monitors form input changes to validate password fields.
   * @effects
   * - Watches 'newPassword' and 'confirmPassword' fields for changes.
   * - Calls 'validatePassword' for updated values.
   * - Unsubscribes on component unmount to prevent leaks.
   */
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      validatePassword(value.newPassword, value.confirmPassword);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * Toggles the visibility of the password field.
   */
  const togglePasswordVisibility = (fieldName) => {
    setPasswordFieldsVisibility((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };
  /**
   * Returns form handling functions and password validation methods.
   *
   * @returns {Object}
   * {
   *   register: Function - Registers form inputs for validation and usage,
   *   handleSubmit: Function - Handles form submission,
   *   errors: Object - Holds form validation errors,
   *   getValues: Function - Retrieves form input values,
   *   onSubmit: Function - Handles form submission processing,
   *   isPasswordValid: Object - Indicates the validity of the password based on defined criteria,
   *   passwordUpdated: Boolean - Indicates if the password has been updated/reset,
   *   showPassword: Boolean - Indicates whether the password field is visible,
   *   showConfirmPassword: Boolean - Indicates whether the confirm password field is visible,
   *   togglePasswordVisibility: Function - Toggles visibility of the password field,
   *   toggleConfirmPasswordVisibility: Function - Toggles visibility of the confirm password field,
   *   validatePassword: Function - Validates the entered password against defined criteria,
   * }
   * Usage Example:
   * Accesses form handling functions and password validation methods:
   * const { register, handleSubmit, errors, getValues, onSubmit, isPasswordValid, passwordUpdated, showPassword, showConfirmPassword, togglePasswordVisibility, toggleConfirmPasswordVisibility, validatePassword } = useResetPassword();
   *
   * register:
   *  Example usage: <input {...register("password", { required: true })} />
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * errors:
   *  Example usage: {errors.password && <span>Password is required</span>}
   * getValues:
   *  Example usage: const passwordValue = getValues("password");
   * onSubmit:
   *  Example usage: onSubmit(formValues)
   * isPasswordValid:
   *  Example usage: console.log(isPasswordValid)
   * passwordUpdated:
   *  Example usage: {passwordUpdated ? <PasswordUpdatedComponent /> : <PasswordNotUpdatedComponent />}
   * showPassword:
   *  Example usage: {showPassword && <PasswordInput />}
   * showConfirmPassword:
   *  Example usage: {showConfirmPassword && <ConfirmPasswordInput />}
   * togglePasswordVisibility:
   *  Example usage: togglePasswordVisibility()
   * toggleConfirmPasswordVisibility:
   *  Example usage: toggleConfirmPasswordVisibility()
   * validatePassword:
   *  Example usage: validatePassword("newPasswordValue", "confirmPasswordValue")
   * }
   */
  return {
    register,
    handleSubmit,
    errors,
    getValues,
    onSubmit,
    isPasswordValid,
    passwordUpdated,
    togglePasswordVisibility,
    passwordFieldsVisibility,
    validatePassword,
  };
};
