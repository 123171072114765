import React from "react";
import { Outlet } from "react-router-dom";
import PosNavbar from "../components/Pos/PosHome/PosNavbar/PosNavbar";
import { Container } from "../components/common/components";

const Layout = () => {
  return (
    <React.Fragment>
      {/* Navigation bar specific to POS */}
      <PosNavbar />
      {/* Main container wrapping the POS content */}
      <Container>
        <Outlet />
      </Container>
    </React.Fragment>
  );
};

export default Layout;
