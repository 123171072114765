import React from 'react';

function ProgressBar({
  current,
  total,
  trackColor = 'bg-gray-200',
  textColor = 'text-blue-700',
  height = 'h-2.5',
  rounded = 'rounded-lg', // updated to 8px equivalent
  label = 'Paying',
  showLabel = true,
  showProgressText = true
}) {
  const progress = (current / total) * 100;

  // Custom gradient style
  const gradientStyle = {
    background: 'var(--Gradient-Linear-Primary, linear-gradient(270deg, #2BE3B7 0%, #53B1FD 100%))',
    width: `${progress}%`
  };

  return (
    <div>
      {showLabel && (
        <div className={`flex justify-between mb-1 ${textColor}`}>
          <span className={`text-base font-medium ${textColor}`}>
            {label}
          </span>
          {showProgressText && (
            <span className={`text-sm font-medium mb-2 ${textColor}`}>
              {current} SAR of {total} SAR
            </span>
          )}
        </div>
      )}
      <div className={`w-full ${trackColor} ${rounded} ${height}`}>
        <div
          className={`${height} ${rounded}`}
          style={gradientStyle}
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;



//how to call
// <ProgressBar
//   current={30}
//   total={60}
//   barColor="bg-green-500"
//   trackColor="bg-green-100"
//   textColor="text-green-700"
//   height="h-1.5"
//   rounded="rounded"
//   label="Downloading"
//   showLabel={true}
//   showProgressText={true}
// />
