import React from "react";
import { usePosDrawer } from "./usePosDrawer";
import CustomButton from "../../common/components/Button/Button";
import { useTranslation } from "react-i18next";

const PosDrawer = () => {
  const { storeCode, handleKeypadPress } = usePosDrawer();
  const { t } = useTranslation();
  const KeypadButton = ({ value }) => {
    return (
      <button
        onClick={() => handleKeypadPress(value.toString())}
        className="flex items-center justify-center h-16 text-text-primary bg-gray-100 text-2xl font-normal py-6 hover:bg-gray-200"
      >
        {value}
      </button>
    );
  };
  return (
    <div className="flex min-h-screen p-8 justify-center ">
      {/* code form */}
      <div className="lg:min-w-[400px]  lg:max-w-[400px] flex flex-col justify-between lg:px-0 px-4 z-10 relative bg-white rounded-lg lg:min-h-auto  lg:max-h-auto">
        <div>
          <div className="lg:max-w-[350px] mx-auto mt-1">
            <h2 className="text-3xl font-semibold text-text-primary text-center mb-6 mt-2">
              {t("pos_dashboard.enter_fast_access_code")}{" "}
            </h2>
            {/* form */}
            <div className="w-full ">
              <>
                <div
                  className={`grid gap-4 mt-2 ${
                    storeCode ? "grid-cols-4" : "grid-cols-5"
                  }`}
                >
                  {storeCode?.map((num, index) => (
                    <div
                      key={index}
                      className="h-12 border rounded text-2xl text-black font-medium flex items-center justify-center"
                    >
                      {num}
                    </div>
                  ))}
                </div>

                <div className="grid grid-cols-3 gap-0 mt-4 rounded-lg border">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "←"].map(
                    (key, index) => (
                      <KeypadButton key={index} value={key} />
                    )
                  )}
                </div>
                <CustomButton text="Confirm" className="mt-4" />
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosDrawer;
