import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Card,
  CustomButton,
  InputGroup,
  Loader,
  SelectGroup,
} from "../../../common/components";
import useCustomersList from "./useChooseCustomer";
import { Coins, CrossIcon } from "../../../common/Icons";
import { CartDown } from "../../../common/Icons/fontawesome";
import { useTranslation } from "react-i18next";

function ChooseCustomer({ closeModal, selectedCustomer, setSelectedCustomer, }) {
  const { t } = useTranslation();

  const {
    customerList,
    onSubmit,
    isOpen,
    handleSelectCustomer,
    handleCancel,
    dropdownRef,
    setIsOpen,
    onSaveCoustmerSubmit,
    customerListLoading,
  } = useCustomersList(closeModal, setSelectedCustomer);
  // validation schema
  const formSchema = z.object({
    name: z.string().min(3, t("pos_dashboard.full_name_required")),
    email: z
      .string()
      .min(1, t("pos_dashboard.email_required"))
      .email(t("pos_dashboard.invalid_email")),
    phonenumber: z
      .string()
      .min(3, t("pos_dashboard.phone_number_min_length"))
      .max(20, t("pos_dashboard.phone_number_length"))
      .regex(/^\+\d+$/, t("pos_dashboard.phone_number_format")),
    gender: z.string().min(1, t("pos_dashboard.gender_required")),
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });
  const isFormFilled = watch();
  const formFilled = Object.values(isFormFilled).some(
    (value) => value && value !== ""
  );
  return (
    <div className="p-3">
      <Card className="mb-2 !py-2">
        <div className="text-text-primary font-medium text-sm pb-2">
          Choose Customer
        </div>
        <div className="relative flex flex-col">
          <button
            className="w-full px-4 py-2 text-left bg-white border rounded-lg flex justify-between items-center"
            onClick={() => setIsOpen(!isOpen)}
            disabled={formFilled}
          >
            <span>
              {selectedCustomer
                ? `${selectedCustomer?.name} - ${selectedCustomer?.points} Points`
                : "Select Customer"}
            </span>
            <CartDown />
            {selectedCustomer && (
              <div
                className="cursor-pointer"
                onClick={() => setSelectedCustomer(null)}
              >
                <CrossIcon />
              </div>
            )}
          </button>

          {isOpen && (
            <div
              ref={dropdownRef}
              className="absolute z-10 w-full bg-white border rounded-lg mt-12 max-h-28 overflow-auto"
            >
              {customerListLoading ? (
                <div className="flex items-center justify-center h-full">
                  <Loader />
                </div>
              ) : (
                <>
                  {customerList.length === 0 ? (
                    <div className="px-4 py-2 text-center text-danger">
                      No Customer
                    </div>
                  ) : (
                    customerList.map((customer) => (
                      <div
                        key={customer.id}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
                        onClick={() => handleSelectCustomer(customer)}
                      >
                        <span>{customer?.name}</span>
                        <span className="flex items-center gap-2 font-medium">
                          <Coins /> {customer?.points} Points
                        </span>
                      </div>
                    ))
                  )}
                </>
              )}
            </div>
          )}
          <div className="mt-3">
            <CustomButton
              type="submit"
              text={t("pos_dashboard.save_customer")}
              textColor="text-white"
              width="20%"
              onClick={onSaveCoustmerSubmit}
              disabled={!selectedCustomer}
            />
          </div>
        </div>
      </Card>

      <div className="flex flex-col">
        <Card>
          <div className="text-text-primary font-medium text-sm  pb-2">
            {t("pos_dashboard.add_new_customer")}{" "}
          </div>
          <form onSubmit={handleSubmit(onSubmit)} >
            <InputGroup
              labelText={t("pos_dashboard.customer_name")}
              htmlFor="name"
              inputName="name"
              placeholder={t("pos_dashboard.enter_customer_name")}
              register={register}
              errors={errors}
              disabled={!!selectedCustomer}
              className="py-1"
            />
            {/* email */}
            <InputGroup
              labelText={t("pos_dashboard.email")}
              htmlFor="email"
              inputName="email"
              placeholder={t("pos_dashboard.enter_email")}
              register={register}
              errors={errors}
              disabled={!!selectedCustomer}
              className="py-1"

            />
            {/* phone */}
            <InputGroup
              labelText={t("pos_dashboard.phone_number")}
              htmlFor="phonenumber"
              inputName="phonenumber"
              placeholder={t("pos_dashboard.enter_phone_number")}
              register={register}
              errors={errors}
              disabled={!!selectedCustomer}
            />
            {/* gender */}
            <SelectGroup
              labelText={t("pos_dashboard.select_gender")}
              htmlFor="gender"
              inputName="gender"
              options={[
                { value: "", label: t("pos_dashboard.select_gender") },
                { value: "MALE", label: "Male" },
                { value: "FEMALE", label: "Female" },
              ]}
              register={register}
              errors={errors}
              disabled={!!selectedCustomer}
              className="rtl:bg-left py-1"
            />
            <div className="flex  justify-end items-end mt-2 gap-2">
              <CustomButton
                onClick={handleCancel}
                text={t("pos_dashboard.cancel")}
                bgColor="bg-white"
                textColor="text-text-primary"
                border="border"
                hoverBgColor="bg-gray-200"
                width="12px"
              />
              <CustomButton
                type="submit"
                text={t("pos_dashboard.add_customer")}
                bgColor="bg-sky-blue"
                textColor="text-white"
                disabled={isSubmitting || Object.keys(errors).length !== 0}
                width="18px"
              />
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
}

export default ChooseCustomer;
