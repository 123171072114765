import React, { useEffect } from "react";
import ProfileLayout from "../Layout";
import InputGroup from "../../../common/components/InputGroup/InputGroup";
import useCreateAccountForm from "./useBusinessProfile";
import CustomButton from "../../../common/components/Button/Button";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../../context/UserContext";
import { useNavigate } from "react-router-dom";

export const BusinessProfile = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, errors, onSubmit, handleSubmit, isSubmitting, isValid } =
    useCreateAccountForm();
  
  

  useEffect(() => {
    if (user && user?.isBusinessDetailCompleted) {
      navigate("/set-plan");
    }
  }, [user, navigate]);

  const isClient = user?.role === "CLIENT";


  useEffect(() => {
    if (user && isClient) {
      if (!user?.isProfileCompleted) {
       navigate("/create-account")
      }
      if (user?.isProfileCompleted && !user?.isBusinessDetailCompleted) {
        navigate("/business-profile")
      }
      if (user?.isProfileCompleted && user?.isBusinessDetailCompleted && (user?.isFreeTrial
        ? user?.freeTrial?.isFreeTrialEnded
        : !user?.isSubscriptionPlanCompleted)) {
        navigate("/set-plan")
      }
    }
  },[isClient,user,navigate])

  useEffect(() => {
    if (user && user?.isLoginVerified) {
      navigate("/dashboard")
    }
    
  },[user,navigate])

  return (
    <ProfileLayout activePage={t("stepper.complete_business_profile")}>
      <div className="max-w-[480px] mx-auto py-8">
        {/* heading */}
        <h2 className="text-3xl text-center font-semibold mb-3 text-text-primary mt-1">
          {t("business_profile.heading")}
        </h2>{" "}
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 !mt-6">
          {/* company name */}
          <InputGroup
            labelText={"business_profile.company_name"}
            htmlfor="companyname"
            inputName="companyname"
            placeholder={t("business_profile.enter_company_name")}
            register={register}
            errors={errors}
          />
          {/* company address */}
          <InputGroup
            labelText={"business_profile.company_address"}
            htmlfor="companyaddress"
            inputName="companyaddress"
            placeholder={t("business_profile.enter_company_address")}
            register={register}
            errors={errors}
          />
          {/* website */}
          <InputGroup
            labelText={"business_profile.website"}
            htmlfor="website"
            inputName="website"
            placeholder="https://loremipsum.co"
            register={register}
            errors={errors}
          />
          {/* commercial register */}
          <InputGroup
            labelText={"business_profile.commercial_register"}
            htmlfor="commercialregister"
            inputName="commercialregister"
            placeholder={t("business_profile.enter_commercial_register")}
            register={register}
            errors={errors}
          />
          {/* vat number */}
          <InputGroup
            labelText={"business_profile.vat_number"}
            htmlfor="vatnumber"
            inputName="vatnumber"
            placeholder={t("business_profile.enter_vat_number")}
            register={register}
            errors={errors}
          />
          {/* business type */}
          <SelectGroup
            labelText={"business_profile.business_type"}
            htmlfor="businesstype"
            inputName="businesstype"
            options={businessTypes}
            register={register}
            errors={errors}
            className="rtl:bg-left"
          />
          <CustomButton
            text={"business_profile.continue"}
            isLoading={isSubmitting}
            type="submit"
            disabled={!isValid}
          />
        </form>
      </div>
    </ProfileLayout>
  );
};

const businessTypes = [
  { value: "", label: "business_profile.select_business_type" },
  { value: "COFFEE_SHOP", label: "business_profile.coffee_shop" },
  { value: "RESTAURANT", label: "business_profile.restaurant" },
  { value: "SHOP", label: "business_profile.shop" },
  { value: "SUPER_MARKET", label: "business_profile.super_market" },
  { value: "FOOD_TRUCK", label: "business_profile.food_truck" },
  { value: "GIFT_SHOP", label: "business_profile.gift_shop" },
  { value: "HAIRDRESSERS", label: "business_profile.hair_dressers" },
  { value: "CAR_SERVICES", label: "business_profile.car_services" },
  {
    value: "EXPRESS_SERVICES",
    label: "business_profile.express_services",
  },
];
