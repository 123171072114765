import React from "react";

function FoodTruckIcon(props) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M4.8835 12.2625C4.69679 12.028 4.56413 11.7551 4.49499 11.4634C4.42585 11.1717 4.42192 10.8684 4.4835 10.575C5.396 6.1875 10.3835 3 16.3335 3C22.2835 3 27.271 6.1875 28.1835 10.575C28.2469 10.8665 28.2443 11.1686 28.1758 11.459C28.1073 11.7494 27.9747 12.0208 27.7878 12.2533C27.6008 12.4858 27.3642 12.6736 27.0952 12.8028C26.8263 12.932 26.5319 12.9994 26.2335 13H6.4335C6.13689 12.9981 5.84433 12.931 5.57649 12.8036C5.30865 12.6762 5.07208 12.4914 4.8835 12.2625ZM28.4585 19.075L25.946 20.075L23.8335 20.925L19.2085 19.075C19.0897 19.0255 18.9622 19 18.8335 19C18.7048 19 18.5773 19.0255 18.4585 19.075L13.8335 20.925L9.2085 19.075C9.08967 19.0255 8.96222 19 8.8335 19C8.70477 19 8.57732 19.0255 8.4585 19.075L5.946 20.075L3.4585 21.075C3.24465 21.1628 3.06784 21.3221 2.95815 21.5256C2.84846 21.7291 2.81268 21.9643 2.85688 22.1912C2.90109 22.4181 3.02255 22.6227 3.20061 22.7702C3.37867 22.9177 3.60231 22.9989 3.8335 23C3.96216 22.9994 4.08948 22.9739 4.2085 22.925L5.3335 22.475V23C5.3335 24.3261 5.86028 25.5979 6.79796 26.5355C7.73564 27.4732 9.00741 28 10.3335 28H22.3335C23.6596 28 24.9313 27.4732 25.869 26.5355C26.8067 25.5979 27.3335 24.3261 27.3335 23V21.675L29.2085 20.925C29.4382 20.8151 29.6172 20.6217 29.7092 20.3843C29.8012 20.1468 29.7991 19.8833 29.7035 19.6473C29.6078 19.4113 29.4258 19.2208 29.1944 19.1144C28.9631 19.0081 28.6999 18.994 28.4585 19.075ZM29.3335 15H3.3335C3.06828 15 2.81393 15.1054 2.62639 15.2929C2.43885 15.4804 2.3335 15.7348 2.3335 16C2.3335 16.2652 2.43885 16.5196 2.62639 16.7071C2.81393 16.8946 3.06828 17 3.3335 17H29.3335C29.5987 17 29.8531 16.8946 30.0406 16.7071C30.2281 16.5196 30.3335 16.2652 30.3335 16C30.3335 15.7348 30.2281 15.4804 30.0406 15.2929C29.8531 15.1054 29.5987 15 29.3335 15Z" />
    </svg>
  );
}

export default FoodTruckIcon;
