import * as React from "react";

function CheckIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="Check">
        <path
          id="Vector"
          d="M8.1251 15.0001C7.95944 14.9978 7.80061 14.9338 7.67979 14.8204L3.30479 10.4454C3.20512 10.324 3.15419 10.1698 3.1619 10.0129C3.1696 9.856 3.2354 9.70756 3.34648 9.59648C3.45756 9.4854 3.606 9.4196 3.7629 9.4119C3.91981 9.40419 4.07398 9.45512 4.19541 9.55479L8.1251 13.4923L16.4298 5.17979C16.5512 5.08012 16.7054 5.02919 16.8623 5.0369C17.0192 5.0446 17.1676 5.1104 17.2787 5.22148C17.3898 5.33256 17.4556 5.481 17.4633 5.6379C17.471 5.79481 17.4201 5.94898 17.3204 6.07041L8.57041 14.8204C8.44958 14.9338 8.29075 14.9978 8.1251 15.0001Z"
        />
      </g>
    </svg>
  );
}

export default CheckIcon;
