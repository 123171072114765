import { StepperDividerImage } from "../../../../assets/images";
import { CheckIcon } from "../../../common/Icons";

/**
 * Stepper component.
 * Renders a step-by-step progress indicator for a multi-step process.
 *
 * @returns {JSX.Element} JSX code for rendering the Stepper component.
 */
const Stepper = ({ activePage, t }) => {
  const steps = [
    t(`stepper.create_password`),
    t(`stepper.complete_profile`),
    t(`stepper.complete_business_profile`),
    t(`stepper.setup_your_plan`),
  ];

  const getStepStyle = (step) => {
    const index = steps.indexOf(step);
    const activeIndex = steps.indexOf(activePage);

    if (index < activeIndex) {
      // Completed Step
      return "bg-black-pearl"; // Completed style
    } else if (index === activeIndex) {
      // Active Step
      return "bg-black-pearl"; // Active style (same as completed in this case)
    } else {
      // Future Step
      return "bg-white"; // Future step style
    }
  };

  const getStepContent = (step) => {
    const index = steps.indexOf(step);
    const activeIndex = steps.indexOf(activePage);
    return index <= activeIndex ? (
      <CheckIcon className="fill-white" />
    ) : (
      index + 1
    );
  };

  return (
    <div className="relative mt-8 ps-custom">
      <img src={StepperDividerImage} alt="Stepper Divider" />
      <div className="absolute inset-0 ps-1 space-y-12">
        {steps.map((step, index) => (
          <div className="flex items-center gap-2" key={step}>
            <span
              className={`w-9 h-9 flex items-center justify-center rounded-full text-text-primary font-medium text-base ${getStepStyle(
                step
              )}`}
            >
              {getStepContent(step)}
            </span>
            <span className="text-white font-medium text-base">{step}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;

// Sample Usage:
// import Stepper from './path/to/stepper'
// <Stepper />
