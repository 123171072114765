import * as React from "react";

function BlueCheck(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Check">
        <path
          id="Vector"
          d="M10.0837 17.9997C9.88493 17.997 9.69433 17.9201 9.54934 17.7841L4.29934 12.5341C4.17974 12.3884 4.11862 12.2034 4.12787 12.0151C4.13712 11.8268 4.21607 11.6487 4.34937 11.5154C4.48267 11.3821 4.66079 11.3031 4.84908 11.2939C5.03737 11.2846 5.22237 11.3458 5.36809 11.4654L10.0837 16.1904L20.0493 6.21535C20.1951 6.09576 20.3801 6.03464 20.5683 6.04388C20.7566 6.05313 20.9348 6.13208 21.0681 6.26539C21.2014 6.39869 21.2803 6.5768 21.2896 6.76509C21.2988 6.95338 21.2377 7.13838 21.1181 7.2841L10.6181 17.7841C10.4731 17.9201 10.2825 17.997 10.0837 17.9997Z"
          fill="#0086FF"
        />
      </g>
    </svg>
  );
}

export default BlueCheck;
