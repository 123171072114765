import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPosOrders,
  handleDuplicateData,
  returnPosOrder,
} from "../../../../features/PosOrder/PosOrderSlice";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const headers = [
  { label: "Invoice No", key: "invoiceno" },
  { label: "Daily Order No", key: "deleiveryorderno" },
  { label: "Invoice Date", key: "invoicedate" },
  { label: "Created by", key: "createdby" },
  { label: "Customer", key: "customer" },
  { label: "Store", key: "store" },
  { label: "Table Number", key: "tablenumber" },
  { label: "Tax Amount", key: "taxamount" },
  { label: "Total", key: "total" },
  { label: "Payment Method", key: "paymentmethod" },
  { label: "Status", key: "status" },
];

const statusMap = {
  All: "All",
  "On Process": "ON_PROCESS",
  Return: "RETURN",
  Completed: "COMPLETED",
};

const dropdownOptions = [
  {
    label: "Checkout",
    value: "checkout",
    visibleKey: "status",
    visibleTo: ["On Process"],
  },
  {
    label: "View",
    value: "view",
    visibleKey: "status",
    visibleTo: ["Completed", "Return", "On Process"],
  },
  {
    label: "Return",
    value: "return",
    visibleKey: "status",
    visibleTo: ["Completed"],
  },
  {
    label: "Duplicate",
    value: "duplicate",
    visibleKey: "status",
    visibleTo: ["Completed", "Return"],
  },
];

export const usePosProcessingOrder = (setSelectedItem) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState("All");
  const [tableBody, setTableBody] = useState([]);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const { data: orderList, isLoading: orderListLoading } = useSelector(
    (state) => state.posOrder.getAllPosOrders
  );
  const currentDate = format(new Date(), "MM/dd/yyyy");
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const branchName = storedUserData?.user?.store?.branchName;
  const fullName = storedUserData?.user?.fullName;

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllPosOrders({ payload }));
  }, [dispatch]);

  // Exclude 'SAVED' from all tabs and handle the filtering based on the selected tab
  useEffect(() => {
    let filteredOrders;
    if (filterStatus === "All") {
      filteredOrders = orderList?.filter((order) => order?.status !== "SAVED");
    } else {
      filteredOrders = orderList?.filter(
        (order) => order?.status === filterStatus
      );
    }

    if (searchText) {
      filteredOrders = filteredOrders?.filter((order) => {
        const searchableFields = [
          order.invoiceNumber,
          order.dailyOrderNumber,
          order.invoiceDate,
          order.posUser?.createdBy,
          order.customer?.name,
          order.store?.branchName,
        ];

        return searchableFields.some((field) =>
          field?.toString().toLowerCase().includes(searchText.toLowerCase())
        );
      });
    }

    const mappedOrders = filteredOrders?.map((order) => ({
      ...order,
      invoiceno: order.invoiceNumber,
      deleiveryorderno: order.dailyOrderNumber,
      invoicedate: order.invoiceDate,
      createdby: order.posUser?.createdBy || "-",
      customer: order.customer?.name || "-",
      store: order.store?.branchName || "-",
      tablenumber: order.table?.position || "-",
      taxamount: order.taxAmount || "-",
      total: order.totalAmount || "-",
      paymentmethod: order.checkout[0]?.paymentMethod || "-",
      status: order.status
        ? order.status
            .split("_")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")
        : "-",
      orderData: order,
    }));

    setTableBody(mappedOrders);
  }, [orderList, filterStatus, searchText]);

  const openModal = (order) => {
    setSelectedItem(order);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedItem(null);
  };

  const handleTabChange = (selectedTab) => {
    // Convert the tab text to the expected status text
    setFilterStatus(statusMap[selectedTab]);
  };

  const duplicateOrder = (order) => {
    dispatch(
      handleDuplicateData({
        ...order,
        orderedItems: order.orderedItems.map((o) => ({
          price: o.item.price,
          id: o.item.id,
          image: o.item.image,
          englishName: o.item.englishName,
          salePrice: o.item.salePrice,
          quantity: o.quantity,
          note: o.note,
          ...(o.vItem
            ? { selections: o.vItem.variations, vItemId: o.vItem.id }
            : {}),
        })),
      })
    );
    // dispatch(handleDuplicateData(order));
    navigate("/pos/dashboard", { state: { type: "duplicate", id: order.id } });
  };

  const refundOrder = (order) => {
    const { id: orderId } = order;
    dispatch(returnPosOrder({ orderId, successCallBack: successDeleteOrder }));
  };

  const successDeleteOrder = () => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllPosOrders({ payload }));
  };
  const dropdownOptionsWithActions = dropdownOptions?.map((option) => ({
    ...option,
    action: (order) => {
      if (option.value === "checkout") {
        openModal(order);
      } else if (option.value === "duplicate") {
        return duplicateOrder(order);
      } else if (option.value === "view") {
        openModal(order);
      } else if (option.value === "return") {
        return refundOrder(order);
      }
    },
  }));

  return {
    isOpen,
    tableBody,
    closeModal,
    openModal,
    dropdownOptions,
    headers,
    handleTabChange,
    filterStatus,
    dropdownOptionsWithActions,
    orderListLoading,
    fullName,
    branchName,
    currentDate,
    refundOrder,
    setSearchText,
    duplicateOrder,
  };
};
