import React from "react";

function HairDresserIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M30 26H29V13C29 12.4696 28.7893 11.9609 28.4142 11.5858C28.0391 11.2107 27.5304 11 27 11H19V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V26H2C1.73478 26 1.48043 26.1054 1.29289 26.2929C1.10536 26.4804 1 26.7348 1 27C1 27.2652 1.10536 27.5196 1.29289 27.7071C1.48043 27.8946 1.73478 28 2 28H30C30.2652 28 30.5196 27.8946 30.7071 27.7071C30.8946 27.5196 31 27.2652 31 27C31 26.7348 30.8946 26.4804 30.7071 26.2929C30.5196 26.1054 30.2652 26 30 26ZM15 17C15 17.2652 14.8946 17.5196 14.7071 17.7071C14.5196 17.8946 14.2652 18 14 18H10C9.73478 18 9.48043 17.8946 9.29289 17.7071C9.10536 17.5196 9 17.2652 9 17C9 16.7348 9.10536 16.4804 9.29289 16.2929C9.48043 16.1054 9.73478 16 10 16H14C14.2652 16 14.5196 16.1054 14.7071 16.2929C14.8946 16.4804 15 16.7348 15 17ZM8 8H12C12.2652 8 12.5196 8.10536 12.7071 8.29289C12.8946 8.48043 13 8.73478 13 9C13 9.26522 12.8946 9.51957 12.7071 9.70711C12.5196 9.89464 12.2652 10 12 10H8C7.73478 10 7.48043 9.89464 7.29289 9.70711C7.10536 9.51957 7 9.26522 7 9C7 8.73478 7.10536 8.48043 7.29289 8.29289C7.48043 8.10536 7.73478 8 8 8ZM8 21H12C12.2652 21 12.5196 21.1054 12.7071 21.2929C12.8946 21.4804 13 21.7348 13 22C13 22.2652 12.8946 22.5196 12.7071 22.7071C12.5196 22.8946 12.2652 23 12 23H8C7.73478 23 7.48043 22.8946 7.29289 22.7071C7.10536 22.5196 7 22.2652 7 22C7 21.7348 7.10536 21.4804 7.29289 21.2929C7.48043 21.1054 7.73478 21 8 21ZM19 13H27V26H19V13Z" />
      <path d="M24 21H22C21.7348 21 21.4804 21.1054 21.2929 21.2929C21.1054 21.4804 21 21.7348 21 22C21 22.2652 21.1054 22.5196 21.2929 22.7071C21.4804 22.8946 21.7348 23 22 23H24C24.2652 23 24.5196 22.8946 24.7071 22.7071C24.8946 22.5196 25 22.2652 25 22C25 21.7348 24.8946 21.4804 24.7071 21.2929C24.5196 21.1054 24.2652 21 24 21Z" />
      <path d="M22 18H24C24.2652 18 24.5196 17.8946 24.7071 17.7071C24.8946 17.5196 25 17.2652 25 17C25 16.7348 24.8946 16.4804 24.7071 16.2929C24.5196 16.1054 24.2652 16 24 16H22C21.7348 16 21.4804 16.1054 21.2929 16.2929C21.1054 16.4804 21 16.7348 21 17C21 17.2652 21.1054 17.5196 21.2929 17.7071C21.4804 17.8946 21.7348 18 22 18Z" />
    </svg>
  );
}

export default HairDresserIcon;
