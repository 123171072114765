import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../common/utils/users.utils";
import { useDispatch, useSelector } from "react-redux";
import { getSinglePosUser } from "../../../../features/PosUsers/PosUsersSlice";
import useStorage from "../../../common/utils/localStorage.util";
import { useUserContext } from "../../../../context/UserContext";

/**
 * Custom hook to manage the state and interactions for the Point of Sale (POS) Navbar.
 * Handles dropdowns, language selection, and user authentication/logout.
 *
 * @returns {Object} An object containing states and functions to handle Navbar interactions.
 */

const usePosNavbar = () => {
  // Navigation using useNavigate hook from React Router
  const navigate = useNavigate();
  const { updateUser, setToken, setUserProfile } = useUserContext();
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("English");
  const dispatch = useDispatch();
  const parsedData = useStorage.getItem("user");
  const storedUserData = parsedData;
  const storeName = storedUserData?.user?.store?.branchName;
  const userId = storedUserData?.user?.id;
  const subplan=storedUserData?.user?.subscription?.planType;
  const { data: singleUser, isLoading: singleUserLoading } = useSelector(
    (state) => state.posUser.getSinglePosUser
  );

  // Function to toggle the language selection dropdown
  /**
   * Function to toggle the language dropdown visibility.
   * Toggles the 'isLanguageDropdownOpen' state to open/close the language dropdown.
   */
  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  // Function to handle the user logout action
  /**
   * Function to handle user logout.
   * Removes the "user" item from local storage, closes the dropdown, and navigates to the "/pos" route.
   */
  const logout = () => {
    updateUser(null);
    setToken(null);
    setUserProfile(null);
    useStorage.clearStorage();
    useStorage.setItem("logout", Date.now().toString());
    navigate("/pos");
  };

  useEffect(() => {
    if (userId) dispatch(getSinglePosUser({ userId }));
  }, [userId]);

  const profilePic = singleUser?.profilePic;
  const name = singleUser?.fullName;
  const changeLanguage = (language) => {
    setCurrentLanguage(language);
    setIsLanguageDropdownOpen(false);
  };

  return {
    logout,
    isLanguageDropdownOpen,
    setIsLanguageDropdownOpen,
    toggleLanguageDropdown,
    currentLanguage,
    changeLanguage,
    name,
    profilePic,
    singleUserLoading,
    storeName,
    subplan
  };
};

export default usePosNavbar;
