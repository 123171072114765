import api from "../../services/api";

/**
 * Creates reports API endpoints based on the specified base path.
 * @param {string} basePath - The base path for reports endpoints.
 * @returns {Object} Object containing reports API methods.
 */
const createPaymentsApi = (basePath) => ({
  /**
   * get payment methods
   * @returns {Promise} A Promise that resolves to the get paymentmethods response.
   */
  getPaymentMethods: async () => {
    const response = await api().get(`${basePath}`);
    return response;
  },

  /**
   * get cards list
   * @returns {Promise} A Promise that resolves to the cards list response.
   */
  getCardsList: async () => {
    const response = await api().get(`${basePath}/cards-list`);
    return response;
  },

  /**
   * get single payment methods
   * @returns {Promise} A Promise that resolves to the getSinglePaymentMethod response.
   */
  getSinglePaymentMethod: async (id, payload) => {
    const response = await api().get(`${basePath}/${id}`, payload);
    return response;
  },

  /**
   * update payment methods
   * @returns {Promise} A Promise that resolves to the updatePaymentMethod response.
   */
  updatePaymentMethod: async (id, payload) => {
    const response = await api().put(`${basePath}/${id}`, payload);
    return response;
  },

  /**
   * delete payment method
   * @returns {Promise} A Promise that resolves to the deletePaymentMethod response.
   */
  deletePaymentMethod: async (id) => {
    const response = await api().delete(`${basePath}/${id}`);
    return response;
  },

  /**
   * add payment methods
   * @param {object} payload - Payload for adding payment methods
   *    {
    "paymentType": "CARD",
    "paymentPoints": {
        "valuePerPoint": 0,
        "pointPerValue": 0,
        "minReplacementApplication": 0,
        "minReplacementPoints": 0
    },
    "paymentDeliveryNotes": {
        "companyName": "string",
        "feePercentage": 100,
        "amountAdded": 0
    },
    "paymentCard": {
        "names": [
        "string"
        ]
    }
    }
   */
  addPaymentMethods: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },
});

// Export the createPaymentsApi

/**
 * Example usage:
 * import createPaymentsApi from './path/to/api/paymentsApi';
 *
 * const paymentsApi = createPaymentsApi('reports');
 *
 * const getPaymentMethods = async () => {
 *   try {
 *     const response = await paymentsApi.getPaymentMethods();
 *     // Handle response
 *   } catch (error) {
 *     // Handle error
 *   }
 * };
 */

export default createPaymentsApi;
