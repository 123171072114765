import * as React from "react";

function Coins(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6094 5.25937C17.475 3.31875 15.9281 2.25 14.25 2.25H9.75C8.07188 2.25 6.525 3.31875 5.39062 5.25937C4.25625 7.2 3.75 9.46875 3.75 12C3.75 14.5312 4.33125 16.9312 5.39062 18.7406C6.45 20.55 8.07188 21.75 9.75 21.75H14.25C15.9281 21.75 17.475 20.6812 18.6094 18.7406C19.7437 16.8 20.25 14.5312 20.25 12C20.25 9.46875 19.6687 7.06875 18.6094 5.25937ZM18.7313 11.25H15.7313C15.6562 9.6375 15.3469 8.09062 14.8219 6.75H17.6906C18.2625 8.00625 18.6469 9.5625 18.7313 11.25ZM16.8 5.25H14.1094C13.7919 4.70932 13.4145 4.20614 12.9844 3.75H14.25C15.1875 3.75 16.0687 4.3125 16.8 5.25ZM14.25 20.25H12.9844C13.4145 19.7939 13.7919 19.2907 14.1094 18.75H16.8C16.0687 19.6875 15.1875 20.25 14.25 20.25ZM17.6906 17.25H14.8219C15.3469 15.9094 15.6562 14.3625 15.7313 12.75H18.7313C18.6469 14.4375 18.2625 15.9937 17.6906 17.25Z"
        fill="#F79009"
      />
    </svg>
  );
}

export default Coins;
