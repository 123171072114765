import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobeSimple } from "../../../../assets/images";
import { Globe } from "../../../../assets/images/svgs";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { classNames } from "../../../../helpers/generateClassNames";
import { ArrowUp, CheckIcon } from "../../Icons";
import Card from "../Card/Card";

/**
 * LanguageSwitcher component.
 * Displays a language switcher dropdown with options to switch between English and Arabic.
 *
 * @param {Object} props - The props of the component.
 * @param {boolean} props.active - The active state of the component.
 * @param {function} props.onClick - The click event handler of the component.
 * @param {string} props.className - The class name of the component.
 * @param {ReactNode} props.children - The children of the component.
 * @returns {JSX.Element} JSX code for rendering the LanguageSwitcher component.
 */

// React.forwardRef(({ active, onClick, className, children }, ref) => (

export const LanguageSwitcher = React.forwardRef(
  (
    {
      active,
      onClick,
      className,
      children,
      position = "end-0",
      globeColor = "black",
      mobileNav = false,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const { language, updateLanguage } = useLanguageContext();

    const handleLanguageChange = (lng) => {
      updateLanguage(lng);
      i18n.changeLanguage(lng);
    };

    useEffect(() => {
      // Set the document direction based on the selected language
      document.documentElement.dir = language === "en" ? "ltr" : "rtl";
    }, [language]);

    if (mobileNav) {
      return (
        <Card padding="xss:p-3 p-1" className="!mt-3 space-y-5">
          <span className="text-text-secondary block font-medium text-sm">
            {t("buttons.select_language")}
          </span>
          {languages?.map((lng) => (
            <span
              key={lng.code}
              onClick={() => handleLanguageChange(lng.code)}
              tabIndex={0}
              className={
                "text-base hover:bg-gray-50 flex items-center justify-between text-text-primary font-medium cursor-pointer"
              }
            >
              {lng.lang}
              {language === lng.code && <CheckIcon className="fill-lime-600" />}
            </span>
          ))}
        </Card>
      );
    }
    return (
      <Menu as="div" className="relative w-fit">
        {({ open }) => {
          return (
            <>
              <MenuButton className={`flex items-center gap-1 ${className}`}>
                <span className="sr-only">Open user menu</span>
                {globeColor === "black" ? (
                  <img src={Globe} alt="Globe" />
                ) : (
                  <img src={GlobeSimple} alt="Globe" />
                )}

                <span>{language === "en" ? "ENG" : "AR"}</span>

                <ArrowUp
                  stroke={globeColor === "white" ? true : false}
                  className={`w-5 h-5 transition-transform duration-300 ${
                    globeColor === "white" && "stroke-white"
                  } ${!open ? "rotate-180" : "rotate-0"}`}
                />
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems
                  className={`absolute ${position} z-10 mt-8 min-w-60 origin-top-right rounded-2xl bg-white py-4 space-y-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none`}
                >
                  <span className="text-text-secondary block font-medium text-sm ps-4 pb-3">
                    {t("buttons.select_language")}
                  </span>
                  {languages?.map((lng) => (
                    <MenuItem key={lng.code}>
                      {({ focus }) => (
                        <span
                          onClick={() => handleLanguageChange(lng.code)}
                          tabIndex={0}
                          className={classNames(
                            focus ? "bg-gray-50" : "",
                            "text-base flex items-center justify-between px-8 py-2 text-text-primary font-medium cursor-pointer"
                          )}
                        >
                          {lng.lang}
                          {language === lng.code && (
                            <CheckIcon className="fill-lime-600" />
                          )}
                        </span>
                      )}
                    </MenuItem>
                  ))}
                </MenuItems>
              </Transition>
            </>
          );
        }}
      </Menu>
    );
  }
);

const languages = [
  { code: "en", lang: "English (ENG)" },
  { code: "ar", lang: "Arabic (العربية)" },
];

// sample usage
// import LanguageSwitcher from "./path/to/LanguageSwitcher"
// <LanguageSwitcher />
