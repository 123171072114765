import api from "../../services/api";

/**
 * Creates authentication-related API endpoints based on the specified base path.
 * @param {string} basePath - The base path for authentication endpoints.
 * @returns {Object} Object containing authentication-related API methods.
 */
const createAuthApi = (basePath) => ({
  /**
   * Logs in a user using email and password.
   * @param {Object} data - User login data including email and password.
   * @returns {Promise} A Promise that resolves to the login response.
   */
  login: async (data) => {
    const response = await api().post(`${basePath}/login-with-email`, data);
    return response;
  },

  /**
   * Sends a request to reset user password.
   * @param {Object} data - User data including email for password reset.
   * @returns {Promise} A Promise that resolves to the password reset response.
   */

  getProfile: async ({ id }) => {
    const response = await api().get(`/profile/${String(id)}`); // Convert id to string
    return response.data;
  },

  updateProfile: async ({ id, data }) => {
    const response = await api().put(`/profile/${String(id)}`, data);
    return response.data;
  },

  /**
   * Sends a request to reset user password.
   * @param {Object} data - User data including email for password reset.
   * @returns {Promise} A Promise that resolves to the password reset response.
   */
  forgetPassword: async (data) => {
    const response = await api().post(`${basePath}/forgot-password`, data);
    return response;
  },

  /**
   * Sends a request to verify otp.
   * @param {Object} data - User data (opt code).
   * @returns {Promise} A Promise that resolves to the verify otp response.
   */
  verifyOtp: async (data) => {
    const response = await api().post(`${basePath}/verify-otp`, data);
    return response;
  },

  /**
   * Sends an OTP to the user.
   * @param {Object} data - User data (e.g., email, phone number) to send OTP.
   * @returns {Promise} A Promise that resolves to the send OTP response.
   */
  sendOtp: async (data) => {
    const response = await api().post(`${basePath}/send-otp`, data);
    return response;
  },

  /**
   * Signs up a user using email.
   * @param {Object} data - User registration data.
   * @returns {Promise} A Promise that resolves to the signup response.
   */
  signUp: async (data) => {
    const response = await api().post(`${basePath}/sign-up-with-email`, data);
    return response;
  },

  /**
   * Creates a password for the user.
   * @param {Object} data - User data (e.g., email, password) to create a password.
   * @returns {Promise} A Promise that resolves to the create password response.
   */
  createPassword: async (data) => {
    const response = await api().post(`${basePath}/create-password`, data);
    return response;
  },

  /**
   * Resets the user's password.
   * @param {Object} data - User data (e.g., email, new password) to reset the password.
   * @returns {Promise} A Promise that resolves to the reset password response.
   */
  resetPassword: async (data) => {
    const response = await api().post(`${basePath}/reset-password`, data);
    return response;
  },

  /**
   * Change the user's password.
   * @param {Object} data - User data (e.g., email, new password) to change the password.
   * @returns {Promise} A Promise that resolves to the change password response.
   */
  changePassword: async (data) => {
    const response = await api().post(`${basePath}/change-password`, data);
    return response;
  },

  /**
   * Creates a user account/profile.
   * @param {Object} data - User data to create a new account/profile.
   * @returns {Promise} A Promise that resolves to the create account/profile response.
   */
  createAccount: async (data) => {
    const response = await api().post("/profile", data);
    return response;
  },

  /**
   * Creates a user bussiness/profile.
   * @param {Object} data - User data to create a new bussniess/profile.
   * @returns {Promise} A Promise that resolves to the create bussniess/profile response.
   */
  bussniessAccount: async (payload) => {
    const response = await api().post("/business-details", payload);
    return response;
  },

  /**
   * Performs login or signup using OAuth (Google or Facebook).
   * @param {Object} loginType - Type of login (e.g., 'google', 'facebook').
   * @param {string} email - User email.
   * @param {string} accessToken - OAuth access token for login/signup.
   * @returns {Object} Data related to the OAuth login/signup response.
   */
  loginAndSignUpWithOAuth: async ({ loginType, accessToken }) => {
    let response;
    if (loginType === "google") {
      response = await api().get(`${basePath}/google/${accessToken}`);
    }
    if (loginType === "facebook") {
      response = await api().get(`${basePath}/facebook/${accessToken}`);
    }
    if (response.data.Succeeded) {
      localStorage.setItem("user", JSON.stringify(response.data.data));
    }
    return response.data;
  },
});

// Export the createAuthApi

/**
 * Example usage:
 * import createAuthApi from './path/to/api/auth';
 *
 * const authApi = createAuthApi('auth');
 *
 * const loginUser = async (userData) => {
 *   try {
 *     const response = await authApi.login(userData);
      // Handle response
 *   } catch (error) {
      // Handle error
 *   }
 * };
 */

export default createAuthApi;
