import React, { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ThreeDotsIcon } from "../../Icons";
import Loader from "../Loader/Loader";
import { formatDateTime } from "../../../../helpers/dateFormatter";
import _ from "lodash";

const CustomPosTable = ({
  tableHeader,
  tableBody,
  setSelectedItem,
  loading,
  showActionsColumn,
  dropdownOptions = [],
  openModal,
  divRef
}) => {
  const [openUpwards, setOpenUpwards] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const checkDropdownPosition = () => {
      if (!divRef || !divRef.current || !dropdownRef.current) return;

      const dropdownHeight = dropdownRef.current.offsetHeight;
      const buttonRect = divRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;

      // Adjust dropdown position based on available space
      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setOpenUpwards(true);
      } else if (spaceBelow >= dropdownHeight) {
        setOpenUpwards(false);
      } else {
        setOpenUpwards(false); // Default to downward if not enough space above or below
        // Adjust dropdown height to fit within the available space
        dropdownRef.current.style.maxHeight = `${spaceBelow - 10}px`;
        dropdownRef.current.style.overflowY = "auto";
      }
    };

    window.addEventListener('resize', checkDropdownPosition);
    checkDropdownPosition();

    return () => window.removeEventListener('resize', checkDropdownPosition);
  }, [isMenu]);
  return (
    <div className="flow-root bg-white border rounded-lg">
      <div className="inline-block min-w-full  align-middle">
        <div className="shadow ring-1 ring-black  ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full border divide-y divide-gray-300">
            <thead className="bg-gray-50 sticky border top-0">
              <tr className="border">
                {tableHeader?.map((item, index) => (
                  <th
                    key={index}
                    scope="col"
                    className={`py-1.5 text-left text-sm font-semibold text-text-secondary ${index === 0 ? "pr-3 pl-4 sm:pl-6" : "px-3"
                      }`}
                  >
                    {item?.label}
                  </th>
                ))}
                {showActionsColumn && (
                  <th className="relative py-2 pl-3 pr-4 sm:pr-6"></th>
                )}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white max-h-[400px] overflow-y-auto">
              {/* Loading indicator */}
              {loading && (
                <tr>
                  <td
                    className="p-1"
                    colSpan={tableHeader.length + (showActionsColumn ? 1 : 0)}
                  >
                    <Loader />
                  </td>
                </tr>
              )}

              {/* Table body */}
              {tableBody?.length > 0 &&
                tableBody?.map((obj, index) => {
                  return (
                    <tr key={index} className="">
                      {/* Render table data cells */}
                      {tableHeader?.map((heading, subIndex) => (
                        <td
                          key={subIndex}
                          className={`whitespace-nowrap text-sm font-medium text-text-primary ${subIndex === 0 ? "py-2 px-2 sm:pl-6" : "px-3 py-1"
                            }`}
                        >
                          {/* Apply background and text color based on status */}
                          {heading.key === "subscriptionStatus" ? (
                            <span
                              className={`py-1 px-2 rounded-lg ${getStatusColor(
                                obj[heading.key]
                              )}`}
                            >
                              {_.capitalize(obj[heading.key])}
                            </span>
                          ) : (
                            // Render other table data
                            <span>
                              {heading?.key.includes(",")
                                ? heading?.key
                                  .split(",")
                                  ?.map((property) => _.get(obj, property))
                                  .filter(Boolean)
                                  .join(", ")
                                : typeof _.get(obj, heading?.key) !==
                                  "undefined" &&
                                  _.get(obj, heading?.key) !== null
                                  ? typeof _.get(obj, heading?.key) === "boolean"
                                    ? _.get(obj, heading?.key)
                                      ? "Yes"
                                      : "No"
                                    : heading?.type === "date"
                                      ? formatDateTime(_.get(obj, heading?.key))
                                      : _.get(obj, heading?.key)
                                  : "-"}
                            </span>
                          )}
                        </td>
                      ))}
                      {/* Actions column */}

                      {showActionsColumn && (
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex justify-end">
                          <Menu as="div" className="relative">
                            <Menu.Button onClick={() => setIsMenu(!isMenu)} className="flex items-center">
                              <ThreeDotsIcon />
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                ref={dropdownRef}
                                className={`absolute dropDownMenu right-0 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${openUpwards ? "bottom-10" : "top-auto"
                                  }`}
                              >
                                {Array.isArray(dropdownOptions) &&
                                  dropdownOptions.filter(option => (!!option.visibleTo && !!option.visibleKey) ? option.visibleTo.includes(obj[option.visibleKey]) : true).map((option, i) => (
                                    <Menu.Item key={i} as="div">
                                      <button
                                        className={`text-left w-full block px-4 py-2 text-sm ${option.textColor} hover:bg-gray-100`}
                                        onClick={() => option.action(obj)}
                                      >
                                        {option.label}
                                      </button>
                                    </Menu.Item>
                                  ))}
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </td>
                      )}
                    </tr>
                  );
                })}
              {/* No record found */}
              {tableBody?.length === 0 && (
                <tr>
                  <td
                    colSpan={tableHeader.length + (showActionsColumn ? 1 : 0)}
                    className="pl-4"
                  >
                    <p className="p-2 text-text-secondary font-medium justify-center flex items-center">
                      No record found!
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Helper function to get status color
const getStatusColor = (status) => {
  switch (status) {
    case "Completed" || "completed":
    case "Success":
    case "active" || "active" || "active":
      return "bg-success-50 text-positive-600";
    case "On Process" || "on process":
      return "bg-blue-200 text-blue-800";
    case "Return" || "return":
      return "bg-orange-200 text-orange-800";
    case "inactive" || "inactive":
      return "bg-red-200 text-red-800";
    case "cancelled" || "cancelled":
      return "bg-red-200 text-red-800";
    case "expired" || "Expired":
      return "bg-red-200 text-red-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};
const bgColorKey = {
  ACTIVE: "bg-success-50 text-positive-600",
  INACTIVE: "bg-danger-100 text-danger-600",
};

export default CustomPosTable;
