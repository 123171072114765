/**
 * Manages state for customers.
 * Defines initial state for each customer action.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showToast } from "../../components/common/utils/showToast.util";
import createSubscriptionsApi from "./AdminSubscribtionsService";

/**
 * Initial state structure defining various properties related to customer processes.
 * Each property represents a specific customer action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllSubscriptions, getSingleSubscriptions, changeSubscription, addBranches, and changeStatus
 * store data, error, success, loading, and message status for corresponding customer actions.
 */
const initialState = {
  getAllSubscriptions: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleSubscriptions: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  changeSubscription: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addBranches: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  changeStatus: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  extendSubscription: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  createSubscription: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteSubscription: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  createClientSubscription: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  manageClientSubscription: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  freeToUpgrade: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the customer process.
 * These action types are prefixed with the 'customers' base path for better organization.
 */
const BASE = "subscriptions";

export const ActionTypes = {
  GET_ALL_SUBSCRIPTION: `${BASE}/get-all`, // Action type for get all customers
  ADD_BRANCHES: `${BASE}`, // Action type for add customer
  SINGLE_SUBSCRIPTION: `${BASE}/get-subscription/`,
};

// Creating an instance of the customers service with a base URL 'customers'
const SubscriptionService = createSubscriptionsApi("subscriptions");

/**
 * Initiates the getAllSubscriptions process for a customer.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting customers
 *    order: order for sorting customers by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllSubscriptions.
 */
export const getAllSubscriptions = createAsyncThunk(
  "ActionTypes.GET_ALL_SUBSCRIPTION",

  async ({ payload }, thunkAPI) => {
    const response = await SubscriptionService.getAllSubscriptions(payload);
    return response?.data;
  }
);

/**
 * Initiates the getSingleSubscriptions process for a customer.
 * @param {object} subscriptionId - id of the customer
 * @param {function} successCallBack - Callback function upon successful getSingleSubscriptions.
 */
export const getSingleSubscriptions = createAsyncThunk(
  "getSingleSubscriptions",
  async ({ subscriptionId, isFreeTrial }, thunkAPI) => {
    const response = await SubscriptionService.getSingleSubscriptions(
      subscriptionId,
      isFreeTrial
    );
    return response?.data?.data;
  }
);

/**
 * Initiates the changeSubscription process for a customer.
 * @param {object} subscriptionId - id of the customer
 * @param {object} payload - customer data to update
 * @param {function} successCallBack - Callback function upon successful changeSubscription.
 */
export const changeSubscription = createAsyncThunk(
  "changeSubscription",
  async ({ subscriptionId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await SubscriptionService.changeSubscription(
        subscriptionId,
        payload
      );
      if (response?.data?.Succeeded) {
        showToast("Subscription updated successfully!");
        successCallBack(response);

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addBranches process for a customer.
 * @param {object} payload - customer data to add
 * {
  "name": "John Doe",
  "email": "customer@gmail.com",
  "phoneNumber": "+9232478788",
  "gender": "MALE"
}
 * @param {function} successCallBack - Callback function upon successful addBranches.
 */
export const addBranches = createAsyncThunk(
  ActionTypes.ADD_BRANCHES,

  async ({ subscriptionId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await SubscriptionService.addBranches(
        subscriptionId,
        payload
      );
      if (response?.data?.Succeeded) {
        showToast("Branch added successfully!");
        successCallBack(response);

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the changeStatus process for a customer.
 * @param {object} subscriptionId - id of the customer
 * @param {function} successCallBack - Callback function upon successful changeStatus.
 */
export const changeStatus = createAsyncThunk(
  "changeStatus",
  async ({ subscriptionId, successCallBack }, thunkAPI) => {
    try {
      const response = await SubscriptionService.changeStatus(subscriptionId);
      if (response?.data?.Succeeded) {
        showToast("Status Change successfully!");
        successCallBack(response);

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "deleteSubscription",
  async ({ subscriptionId, successCallBack }, thunkAPI) => {
    try {
      const response = await SubscriptionService.deleteSubscription(
        subscriptionId
      );
      if (response?.data?.Succeeded) {
        showToast("Status update successfully!");
        successCallBack(response);

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);
export const createClientSubscription = createAsyncThunk(
  "createClientSubscription",
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await SubscriptionService.createClientSubscription(
        payload
      );
      if (response?.data?.Succeeded) {
        showToast("Subscription added successfully!");
        successCallBack(response);
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const extendSubscription = createAsyncThunk(
  "extendSubscription",
  async ({ subscriptionId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await SubscriptionService.extendSubscription(
        subscriptionId,
        payload
      );
      if (response?.data?.Succeeded) {
        showToast("Subscription Extend successfully!");
        successCallBack(response);

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const manageClientSubscription = createAsyncThunk(
  "manageClientSubscription",
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await SubscriptionService.manageClientSubscription(
        payload
      );
      if (response?.data?.Succeeded) {
        showToast("Subscription Updated successfully!");
        successCallBack(response);

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addBranches process for a customer.
 * @param {object} payload - customer data to add
 * {
  "name": "John Doe",
  "email": "customer@gmail.com",
  "phoneNumber": "+9232478788",
  "gender": "MALE"
}
 * @param {function} successCallBack - Callback function upon successful addBranches.
 */
export const createSubscription = createAsyncThunk(
  "createSubscription",
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await SubscriptionService.createSubscription(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Subscription added successfully!");

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for customers related state management.
 * - `name`: Name of the slice (customers)
 * - `initialState`: Initial state defining properties for various customer actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getAllSubscriptions, changeSubscription etc).
 */
export const SubscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllSubscriptions action.
     */
    reset: (state) => {
      state.getAllSubscriptions = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleSubscriptions = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllSubscriptions action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllSubscriptions.pending, (state) => {
        state.getAllSubscriptions.isLoading = true;
        state.getAllSubscriptions.message = "";
        state.getAllSubscriptions.isError = false;
        state.getAllSubscriptions.isSuccess = false;
        state.getAllSubscriptions.data = null;
      })
      /**
       * Updates the state when getAllSubscriptions action is fulfilled/successful.
       * Updates loading and success flags and sets getAllSubscriptions data with the payload.
       */
      .addCase(getAllSubscriptions.fulfilled, (state, action) => {
        state.getAllSubscriptions.isLoading = false;
        state.getAllSubscriptions.isSuccess = true;
        state.getAllSubscriptions.data = action.payload;
      })
      /**
       * Updates the state when getAllSubscriptions action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllSubscriptions.rejected, (state, action) => {
        state.getAllSubscriptions.message = action?.payload?.message;
        state.getAllSubscriptions.isLoading = false;
        state.getAllSubscriptions.isError = true;
        state.getAllSubscriptions.data = null;
      })
      /**
       * Updates the state while the getSingleSubscriptions action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSingleSubscriptions.pending, (state) => {
        state.getSingleSubscriptions.isLoading = true;
        state.getSingleSubscriptions.message = "";
        state.getSingleSubscriptions.isError = false;
        state.getSingleSubscriptions.isSuccess = false;
        state.getSingleSubscriptions.data = null;
      })
      /**
       * Updates the state when getSingleSubscriptions action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleSubscriptions data with the payload.
       */
      .addCase(getSingleSubscriptions.fulfilled, (state, action) => {
        state.getSingleSubscriptions.isLoading = false;
        state.getSingleSubscriptions.isSuccess = true;
        state.getSingleSubscriptions.data = action.payload;
      })
      /**
       * Updates the state when getSingleSubscriptions action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleSubscriptions.rejected, (state, action) => {
        state.getSingleSubscriptions.message = action.payload?.message;
        state.getSingleSubscriptions.isLoading = false;
        state.getSingleSubscriptions.isError = true;
        state.getSingleSubscriptions.data = null;
      })
      /**
       * Updates the state while the addBranches action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addBranches.pending, (state) => {
        state.addBranches.isLoading = true;
        state.addBranches.message = "";
        state.addBranches.isError = false;
        state.addBranches.isSuccess = false;
        state.addBranches.data = null;
      })
      /**
       * Updates the state when addBranches action is fulfilled/successful.
       * Updates loading and success flags and sets addBranches data with the payload.
       */
      .addCase(addBranches.fulfilled, (state, action) => {
        state.addBranches.isLoading = false;
        state.addBranches.isSuccess = true;
        state.addBranches.data = action.payload;
      })
      /**
       * Updates the state when addBranches action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addBranches.rejected, (state, action) => {
        state.addBranches.message = action.payload?.message;
        state.addBranches.isLoading = false;
        state.addBranches.isError = true;
        state.addBranches.data = null;
      })
      /**
       * Updates the state while the addBranches action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(changeSubscription.pending, (state) => {
        state.changeSubscription.isLoading = true;
        state.changeSubscription.message = "";
        state.changeSubscription.isError = false;
        state.changeSubscription.isSuccess = false;
        state.changeSubscription.data = null;
      })
      /**
       * Updates the state when changeSubscription action is fulfilled/successful.
       * Updates loading and success flags and sets changeSubscription data with the payload.
       */
      .addCase(changeSubscription.fulfilled, (state, action) => {
        state.changeSubscription.isLoading = false;
        state.changeSubscription.isSuccess = true;
        state.changeSubscription.data = action.payload;
      })
      /**
       * Updates the state when changeSubscription action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(changeSubscription.rejected, (state, action) => {
        state.changeSubscription.message = action.payload?.message;
        state.changeSubscription.isLoading = false;
        state.changeSubscription.isError = true;
        state.changeSubscription.data = null;
      })
      /**
       * Updates the state while the addBranches action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(changeStatus.pending, (state) => {
        state.changeStatus.isLoading = true;
        state.changeStatus.message = "";
        state.changeStatus.isError = false;
        state.changeStatus.isSuccess = false;
        state.changeStatus.data = null;
      })
      /**
       * Updates the state when changeStatus action is fulfilled/successful.
       * Updates loading and success flags and sets changeStatus data with the payload.
       */
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.changeStatus.isLoading = false;
        state.changeStatus.isSuccess = true;
        state.changeStatus.data = action.payload;
      })
      /**
       * Updates the state when changeStatus action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(changeStatus.rejected, (state, action) => {
        state.changeStatus.message = action.payload?.message;
        state.changeStatus.isLoading = false;
        state.changeStatus.isError = true;
        state.changeStatus.data = null;
      })
      /**
       * Updates the state while the extendSubscription action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(extendSubscription.pending, (state) => {
        state.extendSubscription.isLoading = true;
        state.extendSubscription.message = "";
        state.extendSubscription.isError = false;
        state.extendSubscription.isSuccess = false;
        state.extendSubscription.data = null;
      })
      /**
       * Updates the state when extendSubscription action is fulfilled/successful.
       * Updates loading and success flags and sets extendSubscription data with the payload.
       */
      .addCase(extendSubscription.fulfilled, (state, action) => {
        state.extendSubscription.isLoading = false;
        state.extendSubscription.isSuccess = true;
        state.extendSubscription.data = action.payload;
      })
      /**
       * Updates the state when extendSubscription action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(extendSubscription.rejected, (state, action) => {
        state.extendSubscription.message = action.payload?.message;
        state.extendSubscription.isLoading = false;
        state.extendSubscription.isError = true;
        state.extendSubscription.data = null;
      })
      /**
       * Updates the state while the deleteSubscription action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(deleteSubscription.pending, (state) => {
        state.deleteSubscription.isLoading = true;
        state.deleteSubscription.message = "";
        state.deleteSubscription.isError = false;
        state.deleteSubscription.isSuccess = false;
        state.deleteSubscription.data = null;
      })
      /**
       * Updates the state when deleteSubscription action is fulfilled/successful.
       * Updates loading and success flags and sets deleteSubscription data with the payload.
       */
      .addCase(deleteSubscription.fulfilled, (state, action) => {
        state.deleteSubscription.isLoading = false;
        state.deleteSubscription.isSuccess = true;
        state.deleteSubscription.data = action.payload;
      })
      /**
       * Updates the state when deleteSubscription action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(deleteSubscription.rejected, (state, action) => {
        state.deleteSubscription.message = action.payload?.message;
        state.deleteSubscription.isLoading = false;
        state.deleteSubscription.isError = true;
        state.deleteSubscription.data = null;
      })
      /**
       * Updates the state while the deleteSubscription action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(createSubscription.pending, (state) => {
        state.createSubscription.isLoading = true;
        state.createSubscription.message = "";
        state.createSubscription.isError = false;
        state.createSubscription.isSuccess = false;
        state.createSubscription.data = null;
      })
      /**
       * Updates the state when createSubscription action is fulfilled/successful.
       * Updates loading and success flags and sets createSubscription data with the payload.
       */
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.createSubscription.isLoading = false;
        state.createSubscription.isSuccess = true;
        state.createSubscription.data = action.payload;
      })
      /**
       * Updates the state when createSubscription action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(createSubscription.rejected, (state, action) => {
        state.createSubscription.message = action.payload?.message;
        state.createSubscription.isLoading = false;
        state.createSubscription.isError = true;
        state.createSubscription.data = null;
      })
      /**
       * Updates the state while the deleteSubscription action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(createClientSubscription.pending, (state) => {
        state.createClientSubscription.isLoading = true;
        state.createClientSubscription.message = "";
        state.createClientSubscription.isError = false;
        state.createClientSubscription.isSuccess = false;
        state.createClientSubscription.data = null;
      })
      /**
       * Updates the state when createClientSubscription action is fulfilled/successful.
       * Updates loading and success flags and sets createClientSubscription data with the payload.
       */
      .addCase(createClientSubscription.fulfilled, (state, action) => {
        state.createClientSubscription.isLoading = false;
        state.createClientSubscription.isSuccess = true;
        state.createClientSubscription.data = action.payload;
      })
      /**
       * Updates the state when createClientSubscription action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(createClientSubscription.rejected, (state, action) => {
        state.createClientSubscription.message = action.payload?.message;
        state.createClientSubscription.isLoading = false;
        state.createClientSubscription.isError = true;
        state.createClientSubscription.data = null;
      })
      /**
       * Updates the state while the deleteSubscription action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(manageClientSubscription.pending, (state) => {
        state.manageClientSubscription.isLoading = true;
        state.manageClientSubscription.message = "";
        state.manageClientSubscription.isError = false;
        state.manageClientSubscription.isSuccess = false;
        state.manageClientSubscription.data = null;
      })
      /**
       * Updates the state when manageClientSubscription action is fulfilled/successful.
       * Updates loading and success flags and sets manageClientSubscription data with the payload.
       */
      .addCase(manageClientSubscription.fulfilled, (state, action) => {
        state.manageClientSubscription.isLoading = false;
        state.manageClientSubscription.isSuccess = true;
        state.manageClientSubscription.data = action.payload;
      })
      /**
       * Updates the state when manageClientSubscription action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(manageClientSubscription.rejected, (state, action) => {
        state.manageClientSubscription.message = action.payload?.message;
        state.manageClientSubscription.isLoading = false;
        state.manageClientSubscription.isError = true;
        state.manageClientSubscription.data = null;
      });
  },
});

export const freeToUpgrade = createAsyncThunk(
  "freeToUpgrade",
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await SubscriptionService.freeToUpgrade(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Subscription Upgraded successfully!");

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Destructures the reset action from the customersSlice actions.
 * - `reset`: Action function to reset the customers related state.
 */
export const { reset } = SubscriptionsSlice.actions;

/**
 * Exports the default reducer generated by customersSlice.
 * This reducer handles state updates for customers related actions.
 */
export default SubscriptionsSlice.reducer;
