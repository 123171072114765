import React from "react";
import {
  Card,
  CurrencySign,
  TrashIcon,
  VerticalBlackCoin,
} from "../../../../common/Icons";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../../../common/components/ProgressBar/ProgressBar";
import { useSplitEqually } from "./useSplitEqually";
import { CustomButton } from "../../../../common/components";
import { Plus } from "../../../../common/Icons/fontawesome";
import EquallyByCard from "./EquallyByCard";
import EquallyByPoints from "./EquallyByPoints";
import { SearchBox } from "../../../../../assets/images/svgs";

function SplitEqually({
  setCheckOutVisible,
  setIsModalOpen,
  setOrderCheckoutPaid,
  setIsCheckoutModalOpen,
}) {
  const {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    parts,
    togglePartSelection,
    addPart,
    deletePart,
    selectedTotal,
    paymentMethodsList,
    totalAmount,
    handleCheckout,
    paidParts,
    pricePerParts,
    checkoutType,
    isButtonDisabled
  } = useSplitEqually(
    setCheckOutVisible,
    setOrderCheckoutPaid,
    setIsCheckoutModalOpen
  );

  const { t } = useTranslation();

  const getIconComponent = (type) => {
    switch (type) {
      case "CASH":
        return CurrencySign;
      case "CARD":
        return Card;
      case "POINTS":
        return VerticalBlackCoin;
      default:
        return null;
    }
  };

  const paymentMethodOptions = paymentMethodsList
    ?.filter((method) => method.type !== "DELIVERY_COMPANIES")
    ?.map((method) => ({
      method: method.type.toLowerCase(),
      label: t(`pos_dashboard.${method.type.toLowerCase()}`),
      IconComponent: getIconComponent(method.type),
    }));
  const hasValidPaymentMethods =
    paymentMethodOptions && paymentMethodOptions.length > 0;

  const renderSelectedPaymentMethod = () => {
    switch (selectedPaymentMethod) {
      case "cash":
        return null;
      case "card":
        return <EquallyByCard />;
      case "points":
        return <EquallyByPoints />;
      default:
        return null;
    }
  };

  return (
    <div className="">
      <div className="font-medium text-sm mb-4">{t("pos_dashboard.items")}</div>
      <div className="max-h-[160px] overflow-auto">
        {parts?.map((part, index) => {
          const isPaidPart = index < paidParts;

          return (
            <div
              key={index}
              className={`flex items-center justify-between mb-2 border p-2 rounded-lg ${
                isPaidPart ? "bg-green-100 text-green-600 font-bold" : ""
              }`}
            >
              <div className="flex items-center">
                {isPaidPart ? (
                  <span className="font-bold text-sm text-yellow-600">
                  Paid
                  </span>
                ) : (
                  <input
                    type="checkbox"
                    checked={part.selected}
                    onChange={() => togglePartSelection(index)}
                    className="form-checkbox h-3 w-3"
                  />
                )}
                <span className="ml-2 font-medium text-sm">{part.name}</span>
              </div>
              <div className="flex items-center">
                <span className="font-medium text-sm">{part.price} SAR</span>
                {part.deletable && !isPaidPart && (
                  <CustomButton
                    onClick={() => deletePart(index)}
                    bgColor="bg-transparent"
                    icon={<TrashIcon color="red" />}
                    width="w-fit"
                    padding="px-2 py-1"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>

      {checkoutType !== "SPLIT_EQUALLY" && (
        <div className="flex justify-center">
          <CustomButton
            icon={<Plus color="black" />}
            width="w-fit"
            border="border"
            bgColor="!bg-white"
            text={t("pos_dashboard.add_part")}
            padding="px-2 py-2"
            textColor="text-text-primary"
            onClick={addPart}
          />
        </div>
      )}
      <p className=" my-2 text-sm font-medium">Choose Payment Method</p>
      {/* Payment Method Options */}
      {hasValidPaymentMethods ? (
        <div>
          <div className="grid grid-cols-2 gap-4 mb-2">
            {paymentMethodOptions?.map((option) => (
              <div
                key={option.method}
                className={`flex items-center justify-between border p-4 rounded-lg cursor-pointer ${
                  selectedPaymentMethod === option.method
                    ? "border-blue-500 bg-blue-100"
                    : "border-gray-300 bg-white"
                }`}
                onClick={() => setSelectedPaymentMethod(option.method)}
              >
                <div className="flex items-center gap-2">
                  {option.IconComponent && (
                    <option.IconComponent
                      className={`text-lg ${
                        selectedPaymentMethod === option.method
                          ? "text-blue-600"
                          : "text-gray-500"
                      }`}
                    />
                  )}
                  <span
                    className={`ml-2 ${
                      selectedPaymentMethod === option.method
                        ? "text-blue-700 font-medium"
                        : "text-gray-700 font-medium"
                    }`}
                  >
                    {option.label}
                  </span>
                </div>
                <div
                  className={`flex items-center justify-center h-5 w-5 rounded-full border-2 ${
                    selectedPaymentMethod === option.method
                      ? "border-blue-500 bg-blue-500"
                      : "border-gray-300"
                  }`}
                >
                  {selectedPaymentMethod === option.method && (
                    <div className="h-3 w-3 bg-white rounded-full"></div>
                  )}
                </div>
              </div>
            ))}
          </div>
          {renderSelectedPaymentMethod()}

          <div className="border p-2 mt-32 rounded-lg">
            <ProgressBar
              current={Math.round(
                (selectedTotal || 0) + (pricePerParts || 0) * (paidParts || 0)
              )}
              total={totalAmount || 0}
              trackColor="bg-gray-300"
              textColor="text-text-primary"
              height="h-1.5"
              rounded="rounded"
              label={t("pos_dashboard.paying")}
              showLabel={true}
              showProgressText={true}
            />
          </div>

          <div className="w-full pt-2">
            <CustomButton
              text={t("pos_dashboard.checkout")}
              type="button"
              onClick={handleCheckout}
              disabled={isButtonDisabled}

            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center mt-12">
          <img
            src={SearchBox}
            alt="Search Box"
            className="ml-32 w-[50%] h-[150px] rounded-lg"
          />
          <p className="text-center text-lg text-red-500 px-12 pb-12 ">
            {" "}
            Please add payment method firstly to proceed with payments.
          </p>
        </div>
      )}
    </div>
  );
}

export default SplitEqually;
