import React from "react";
import Sidebar from "../SettingSideBar/SettingSideBar";
import { CrossIcon } from "../../../../../common/Icons";
import CustomButton from "../../../../../common/components/Button/Button";

const SettingLayout = ({
  children,
  activeComponent,
  setActiveComponent,
  onClose,
}) => {
  return (
    <div className="flex w-[100%]">
      <Sidebar
        activeComponent={activeComponent}
        setActiveComponent={setActiveComponent}
      />
      <div className=" w-[100%]">
        <div className="flex justify-between items-center text-lg font-bold border-b w-auto p-3 mb-4">
          {/* here display the name of the active component */}
          {activeComponent}
          <CustomButton
            onClick={onClose}
            bgColor="bg-transparent"
            icon={<CrossIcon />}
            width="w-fit"
            padding="px-2 py-1"
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default SettingLayout;
