import * as React from "react";

function SearchIcon(props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="magnifyingglass" clipPath="url(#clip0_7055_34843)">
        <g id="Group">
          <path
            id="Vector"
            d="M12.3311 13.5313L12.0309 13.2305L11.6857 13.4782C10.5597 14.2862 9.19674 14.7656 7.70483 14.7656C3.92963 14.7656 0.857178 11.6932 0.857178 7.91797C0.857178 4.14277 3.92963 1.07031 7.70483 1.07031C11.48 1.07031 14.5525 4.14277 14.5525 7.91797C14.5525 9.47967 14.0297 10.9109 13.146 12.0605L12.8793 12.4074L13.1878 12.7177L17.6903 17.2478L17.6914 17.2489C17.7871 17.3446 17.8488 17.4828 17.8488 17.6504C17.8488 18.0113 17.6202 18.2369 17.2899 18.2369C17.1133 18.2369 16.969 18.1785 16.8609 18.0703L12.3311 13.5313ZM7.70483 1.64481C4.24287 1.64481 1.43168 4.456 1.43168 7.91797C1.43168 11.3799 4.24287 14.1911 7.70483 14.1911C11.1668 14.1911 13.978 11.3799 13.978 7.91797C13.978 4.456 11.1668 1.64481 7.70483 1.64481Z"
            fill="#475467"
            stroke="#475467"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7055_34843">
          <rect
            width="17.9916"
            height="18.1666"
            fill="white"
            transform="translate(0.357178 0.570312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SearchIcon;
