import React from "react";
import { Link } from "react-router-dom";
import {
  CaretRight,
  GmailIcon,
  PhoneIcon,
  VerticalDots,
} from "../../../../../common/Icons";
import { Card, CustomButton, Dropdown } from "../../../../../common/components";
import EditClient from "../Edit Clients/EditClient";
import DeleteClient from "../DeleteClient/DeleteClient";
import useUpdateProfile from "./useClientDetail";
import DefaultImg from "../../../../../../assets/images/DefaultImg.png";
import ClientDetailSalesChart from "./ClientDetailSalesChart/ClientDetailSalesChart";
import ClientDetailNetIncomeChart from "./ClientDetailNetIncomeChart/ClientDetailNetIncomeChart";
import ClientDetailExpenseChart from "./ClientDetailExpenseChart/ClientDetailExpenseChart";
import ClientDetailBillingHistory from "./ClientDetailBillingHistory/ClientDetailBillingHistory";
import CustomAdminModel from "../../../../../common/components/AdminModal/CustomAdminModal";
import AddBranch from "../../../Subscription/AddBranch/AddBranch";
import ChangeSubscription from "../../../Subscription/ChangeSubscription/ChangeSubscription";
import ExtendSubscription from "../../../Subscription/ExtendSubscription/ExtendSubscription";
import CancelSubscription from "../../../Subscription/CancelSubscription/CancelSubscription";
import { ROLE } from "../../../../../../enums/role";

function ClientDetail() {
  const {
    clientDetails,
    dropdownOptions,
    toggleDropdown,
    isDeleteModalOpen,
    isModalOpen,
    dropdownVisible,
    setDeleteModalOpen,
    dropdownRef,
    setModalOpen,
    subscribtiondropdownOptions,
    activeModal,
    closeModal,
    id,
    storedUserData,
    subscriptionDetailId,
    getSingleClientDetail,
    status,
    clientStatus,
  } = useUpdateProfile();

  const openWebsiteInNewTab = (url) => {
    window.open(url.includes("http") ? url : "https://" + url);
  };
  return (
    <div id="printable-content">
      <div className="flex items-center space-x-2">
        <Link
          to="/admin/clients"
          className="text-text-gray font-normal text-sm hover:text-sky-blue"
        >
          Clients
        </Link>{" "}
        <CaretRight />
        <span className="text-text-primary font-medium text-sm">
          {clientDetails?.profile?.fullName ?? "__"}
        </span>
      </div>
      <Card className="mt-4">
        <div className="flex items-center ">
          <img
            src={clientDetails?.profile?.profilePic || DefaultImg}
            alt="Pic"
            className="inline-block h-14 w-14 rounded-full mr-6"
          />

          <div className="flex-grow">
            <p className="text-text-primary  text-lg font-semibold">
              {clientDetails?.profile?.fullName ?? "__"} (
              {clientDetails?.profile?.arabicName ?? "__"})
            </p>
            <div className="flex items-center text-text-gray font-normal mt-2">
              <GmailIcon className="mr-2 " />{" "}
              <span className="pl-2 text-text-primary  text-sm font-normal">
                {clientDetails?.email ?? "__"}
              </span>
            </div>{" "}
            <div className="flex items-center text-text-gray font-normal mt-2">
              <PhoneIcon className="mr-2" />{" "}
              <span className="pl-2 text-text-primary  text-sm font-normal">
                {clientDetails?.profile?.phoneNumber ?? "__"}
              </span>
            </div>
          </div>
          <div className="relative ">
            <CustomButton
              icon={<VerticalDots />}
              width="w-fit"
              padding="px-1 py-1"
              textColor="text-text-primary"
              onClick={() => toggleDropdown("edit")}
            />
            {dropdownVisible.isToggle && dropdownVisible.type === "edit" && (
              <div ref={dropdownRef}>
                <Dropdown options={dropdownOptions} />
              </div>
            )}
          </div>
        </div>
        <div className="pt-4 ">
          <span className="text-text-primary font-medium text-base ">
            Business Info
          </span>
          <Card className="p-4 mt-4">
            <div className="grid grid-cols-3 gap-2">
              <div>
                <p className="font-normal text-text-gray text-sm">
                  Company Name
                </p>
                <p className="font-medium text-text-primary text-base">
                  {clientDetails?.businessDetail?.companyName
                    ? `${clientDetails.businessDetail.companyName.substring(
                        0,
                        20
                      )}${
                        clientDetails.businessDetail.companyName.length > 20
                          ? "..."
                          : ""
                      }`
                    : "__"}
                </p>
              </div>
              <div>
                <p className="font-normal text-text-gray text-sm">Address</p>
                <p className="font-medium text-text-primary text-base">
                  {clientDetails?.businessDetail?.companyAddress
                    ? `${clientDetails.businessDetail.companyAddress.substring(
                        0,
                        35
                      )}${
                        clientDetails.businessDetail.companyAddress.length > 35
                          ? "..."
                          : ""
                      }`
                    : "__"}{" "}
                </p>
              </div>
              <div className="pl-12">
                <p className="font-normal text-text-gray text-sm ">
                  Commercial Register
                </p>
                <p className="font-medium text-text-primary text-base">
                  {clientDetails?.businessDetail?.commercialRegister
                    ? `${clientDetails.businessDetail.commercialRegister.substring(
                        0,
                        20
                      )}${
                        clientDetails.businessDetail.commercialRegister.length >
                        20
                          ? "..."
                          : ""
                      }`
                    : "__"}{" "}
                </p>
              </div>
              <div>
                <p className="font-normal text-text-gray text-sm">Website</p>
                <CustomButton
                  text={
                    clientDetails?.businessDetail?.website
                      ? `${clientDetails.businessDetail.website.substring(
                          0,
                          20
                        )}${
                          clientDetails.businessDetail.website.length > 20
                            ? "..."
                            : ""
                        }`
                      : "__"
                  }
                  width="w-fit"
                  padding="px-1 py-1"
                  textColor="text-sky-blue"
                  className="bg-white"
                  onClick={() =>
                    openWebsiteInNewTab(clientDetails?.businessDetail?.website)
                  }
                />
              </div>
              <div>
                <p className="font-normal text-text-gray text-sm">VAT</p>
                <p className="font-medium text-text-primary text-base">
                  {" "}
                  {clientDetails?.businessDetail?.vatNumber ?? "0"}{" "}
                </p>
              </div>
            </div>
          </Card>
        </div>
      </Card>
      {storedUserData?.user?.role === ROLE?.SUPER_ADMIN && (
        <>
          <p className="font-medium my-3">Sales</p>
          <div className="flex flex-row gap-4  w-full ">
            <div className="w-1/3 border rounded-lg p-3  overflow-auto">
              <ClientDetailSalesChart />
            </div>
            <div className="w-1/3 border rounded-lg p-3  overflow-auto">
              <ClientDetailNetIncomeChart />
            </div>
            <div className="w-1/3  overflow-auto border rounded-lg p-3">
              <ClientDetailExpenseChart />
            </div>{" "}
          </div>
        </>
      )}
      <p className="font-medium my-3">Plans</p>
      <Card>
        <div className="max-w-full">
          <div className="flex justify-between">
            <div>
              <p className="text-base font-semibold">Current Plan</p>
              <p className="text-sm font-normal mt-1">
                {clientDetails?.subscription?.planType ?? "-"}
              </p>
            </div>
            <div>
              <p className="text-base font-semibold">No of Branch</p>
              <p className="text-sm font-normal mt-1">
                {clientDetails?.subscription?.branches ?? "-"}
              </p>
            </div>
            <div>
              <p className="text-base font-semibold">Start Date</p>
              <p className="text-sm font-normal mt-1">
                {clientDetails?.subscription?.startDate ?? "-"}
              </p>
            </div>
            <div>
              <p className="text-base font-semibold">End Date</p>
              <p className="text-sm font-normal mt-1">
                {clientDetails?.subscription?.endDate ?? "-"}
              </p>
            </div>
            <div>
              <p className="text-base font-semibold">Account Manager</p>
              <p className="text-sm font-normal mt-1">
                {clientDetails?.subscription?.managedBy?.fullName ?? "-"}
              </p>
            </div>
            <div>
              <p className="text-base font-semibold">Status</p>
              <p
                className={`
    ${
      ["cancelled", "inactive"].includes(status)
        ? "bg-danger-100 text-danger-600"
        : "bg-success-50 text-positive-600"
    }
    p-0.5 rounded-md border `}
              >
                {status}
              </p>
            </div>
            <div>
              {!clientStatus && (
                <div className="relative">
                  <CustomButton
                    icon={<VerticalDots />}
                    width="w-fit"
                    padding="px-1 py-1"
                    textColor="text-text-primary"
                    onClick={() => toggleDropdown("subscription")}
                  />
                  {dropdownVisible.isToggle &&
                    dropdownVisible.type === "subscription" && (
                      <div ref={dropdownRef}>
                        <Dropdown
                          options={subscribtiondropdownOptions}
                          width="w-44"
                        />
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
      <div className="flex items-center space-x-4 my-4 ">
        <div className="flex items-center flex-grow text-text-primary font-medium text-base">
          Billing History
        </div>
      </div>
      <div className="h-[calc(50vh_-_15vh)] flex flex-col justify-between mt-2 overflow-auto">
        <ClientDetailBillingHistory clientDetails={clientDetails} />
      </div>
      {isModalOpen && (
        <EditClient
          clientId={id}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteClient
          data={clientDetails}
          isDeleteModalOpen={isDeleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
        />
      )}
      {activeModal === "AddBranches" && (
        <CustomAdminModel
          isOpen={true}
          onClose={closeModal}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[650px]"
          title="Add Branch"
        >
          <AddBranch
            subscriptionDetailId={subscriptionDetailId}
            closeModal={closeModal}
            getSingleClientDetail={getSingleClientDetail}
          />
        </CustomAdminModel>
      )}
      {activeModal === "ChangeSubscribtion" && (
        <CustomAdminModel
          isOpen={true}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
          onClose={closeModal}
          title="Change Subscribtion"
        >
          <ChangeSubscription
            subscriptionDetailId={subscriptionDetailId}
            closeModal={closeModal}
            getSingleClientDetail={getSingleClientDetail}
          />
        </CustomAdminModel>
      )}
      {activeModal === "ExtendSubscribtion" && (
        <CustomAdminModel
          isOpen={true}
          onClose={closeModal}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
          title="Extend Subscribtion"
        >
          <ExtendSubscription
            subscriptionDetailId={subscriptionDetailId}
            closeModal={closeModal}
            getSingleClientDetail={getSingleClientDetail}
          />
        </CustomAdminModel>
      )}
      {activeModal === "CancelSubscribtion" && (
        <CustomAdminModel
          isOpen={true}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[500px]"
          onClose={closeModal}
          title="Cancel Subscribtion"
        >
          <CancelSubscription
            subscriptionDetailId={subscriptionDetailId}
            closeModal={closeModal}
            getSingleClientDetail={getSingleClientDetail}
          />
        </CustomAdminModel>
      )}
    </div>
  );
}

export default ClientDetail;
