/**
 * Manages state for stores.
 * Defines initial state for each store action.
 */

// Import necessary functions and modules from Redux Toolkit

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createAdminSalesApi from "./AdminSalesService";

/**
 * Initial state structure defining various properties related to stores processes.
 * Each property represents a specific stores action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllPosStores, addStore, deleteStore, etc.
 * store data, error, success, loading, and message status for corresponding stores actions.
 */
const initialState = {
  getSingleClientSale: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the stores process.
 * These action types are prefixed with the 'stores' base path for better organization.
 */
const BASE = "dashboard";

// Creating an instance of the stores service with a base URL 'stores'
const adminSalesService = createAdminSalesApi(BASE);

/**
 * Initiates the getSingleClientSale process for a store.
 * @param {object} storeId id of the store
 * @param {function} successCallBack - Callback function upon successful getSingleClientSale.
 */
export const getSingleClientSale = createAsyncThunk(
  "/single-client-sale",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async (id, thunkAPI) => {
    const response = await adminSalesService.getSingleClientSale(id);
    return response?.data?.data;
  }
);

export const adminsalesSlice = createSlice({
  name: "adminsales",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllstore action.
     */
    reset: (state) => {
      state.getSingleClientSale = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder

      /**
       * Updates the state while the getSingleClientSale action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSingleClientSale.pending, (state) => {
        state.getSingleClientSale.isLoading = true;
        state.getSingleClientSale.message = "";
        state.getSingleClientSale.isError = false;
        state.getSingleClientSale.isSuccess = false;
        state.getSingleClientSale.data = null;
      })
      /**
       * Updates the state when getSingleClientSale action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleClientSale data with the payload.
       */
      .addCase(getSingleClientSale.fulfilled, (state, action) => {
        state.getSingleClientSale.isLoading = false;
        state.getSingleClientSale.isSuccess = true;
        state.getSingleClientSale.data = action.payload;
      })
      /**
       * Updates the state when getSingleClientSale action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleClientSale.rejected, (state, action) => {
        state.getSingleClientSale.message = action.payload?.message;
        state.getSingleClientSale.isLoading = false;
        state.getSingleClientSale.isError = true;
        state.getSingleClientSale.data = null;
      });
    /**
     * Updates the state while the addStore action is pending.
     * Sets loading to true and clears previous messages.
     */
  },
});

/**
 * Destructures the reset action from the storesSlice actions.
 * - `reset`: Action function to reset the stores related state.
 */

// sample usage

// import { reset } from './storesSlice';

// Using the reset action
// const resetStores = () => {
//   // Dispatching the reset action to reset stores state
//   store.dispatch(reset());
// };

export const { reset } = adminsalesSlice.actions;

/**
 * Exports the default reducer generated by storesSlice.
 * This reducer handles state updates for stores related actions.
 */

// sample usage

// import storesReducer from "./storesSlice";

// Using the default reducer
// const initialState = {
// Your initial state for stores actions
// };

// Creating a store with the storeReducer handling stores related actions
// const store = configureStore({
// reducer: {
// stores: storesReducer,
// other reducers...
// },
// preloadedState: {
// auth: initialState,
// other preloaded states...
// },
// });
export default adminsalesSlice.reducer;
