import React from "react";

function RightArrow(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ArrowRight">
        <path
          id="Vector"
          d="M3.75 12H20.25"
          stroke="#0086FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M13.5 5.25L20.25 12L13.5 18.75"
          stroke="#0086FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default RightArrow;
