import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useUserContext } from "../../../../context/UserContext";
import { createAccount } from "../../../../features/Auth/AuthSlice";
import { uploadSingleFile } from "../../../../features/UploadFile/UploadFileSlice";

/**
 * Custom hook for managing the create account form.
 * @returns {Object} An object containing methods and states for form handling and image upload validation.
 */
export const useCreateAccountForm = () => {
  const { updateUser, setUserProfile, user } = useUserContext();

  // State to manage the preview of the uploaded image
  const [imagePreview, setImagePreview] = useState(null);
  // State to track any error related to image upload
  const [imageUploadError, setImageUploadError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const uploadFileData = useSelector((state) => state.uploadFile);

  const phoneNumberSchema = z
    .string()
    .min(1, "client_dashboard.directory.customer.validations.phone_error")
    .refine(
      (value) => /^\+?[0-9]+$/.test(value),
      "client_dashboard.directory.customer.validations.phone_format"
    )
    .refine(
      (value) => !/^\+?0/.test(value),
      "client_dashboard.directory.customer.validations.phone_format_plus"
    )
    .refine((value) => {
      const digitsOnly = value.replace(/\D/g, "");
      return digitsOnly.length >= 7;
    }, "client_dashboard.directory.customer.validations.phone_min")
    .refine((value) => {
      const digitsOnly = value.replace(/\D/g, "");
      return digitsOnly.length <= 15;
    }, "client_dashboard.directory.customer.validations.phone_max");
  // validation schema for update profile
  const profileSchema = z.object({
    fullName: z
      .string()
      .min(
        3,
        "client_dashboard.management.settings.profile.validations.englishname_error"
      )
      .max(
        100,
        "client_dashboard.management.settings.profile.validations.englishname_error_max"
      ),
    arabicName: z.string().optional(),

    phoneNumber: phoneNumberSchema,
    userpic: z.any().optional(),
  });
  // React Hook Form's hook for form handling
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: zodResolver(profileSchema), mode: "onChange" });

  // Function to handle navigation to the previous step
  const handleBack = () => {
    navigate("/create-password");
  };

  /**
   * Handles changes in the file input field for image upload.
   * Sets the image preview and updates formData with the selected file.
   * Validates the file type, size, and dimensions.
   * @param {object} event - The event object from the file input field.
   */
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check file type
      const validTypes = ["image/jpeg", "image/png"];
      if (!validTypes.includes(file.type)) {
        setImageUploadError(
          "client_dashboard.management.settings.profile.validations.img_err_type"
        );
        return;
      }

      // Check file size (10MB = 10 * 1024 * 1024 bytes)
      if (file.size > 2 * 1024 * 1024) {
        setImageUploadError(
          "client_dashboard.management.settings.profile.validations.img_err_size"
        );
        return;
      }

      // Check image dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width < 400 || img.height < 400) {
          setImageUploadError(
            "client_dashboard.management.settings.profile.validations.img_error_dimensions"
          );
          return;
        }

        // Update state and dispatch if all validations pass
        setImagePreview(img.src);
        setImageUploadError(""); // Clear any existing error
        const formImgData = new FormData();
        formImgData.append("file", file);
        dispatch(uploadSingleFile({ payload: formImgData }));
      };
    } else {
      setImageUploadError(
        "client_dashboard.management.settings.profile.validations.img_err_cumpolsory"
      );
    }
  };

  /**
   * Handles form submission.
   * Validates image upload, creates FormData object with form data, and dispatches the createAccount action.
   * @param {object} data - Form data collected from the form fields.
   */
  const onSubmit = (data) => {
    const formDataObj = new FormData();
    Object.keys(data).forEach((key) => formDataObj.append(key, data[key]));
    formDataObj.append("userId", +user?.id);
    formDataObj.append(
      "profilePic",
      uploadFileData?.uploadSingleFile?.data?.url
    );
    //payload for the upload
    const payload = {
      fullName: data.fullName,
      arabicName: data.arabicName,
      phoneNumber: data.phoneNumber,
      profilePic: uploadFileData?.uploadSingleFile?.data?.url || "",
      userId: +user?.id,
    };

    return dispatch(
      createAccount({
        payload: payload,
        successCallBack: completeProfileRouter,
      })
    );
  };
  //after api moving router
  const completeProfileRouter = (data) => {
    if (data?.data?.Succeeded) {
      const userData = data.data.data;
      updateUser(userData);
      setUserProfile(userData?.profile);
      navigate("/business-profile");
    }
  };

  /**
   * Custom hook for managing the create account form functionality.
   *
   * @returns {Object} An object containing methods and states for form handling and image upload validation.
   * {
   *   imagePreview: String - Holds the preview URL of the uploaded image,
   *   handleImageChange: Function - Handles changes in the file input field for image upload,
   *   onSubmit: Function - Handles form submission,
   *   handleBack: Function - Handles navigation to the previous step,
   *   handleSubmit: Function - Handles form submission using react-hook-form,
   *   register: Function - Registers form inputs for validation and usage with react-hook-form,
   *   errors: Object - Holds form validation errors from react-hook-form,
   *   imageUploadError: String - Holds any error message related to image upload,
   * }
   * Usage Example:
   * Accesses form handling methods and image upload validation:
   * const { imagePreview, handleImageChange, onSubmit, handleBack, handleSubmit, register, errors, imageUploadError } = useCreateAccountForm();
   *
   * imagePreview:
   *  Example usage: {imagePreview && <img src={imagePreview} alt="Preview" />}
   * handleImageChange:
   *  Example usage: <input type="file" onChange={handleImageChange} />
   * onSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * handleBack:
   *  Example usage: <button onClick={handleBack}>Back</button>
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * register:
   *  Example usage: <input {...register("fullName", { required: true })} />
   * errors:
   *  Example usage: {errors.fullName && <span>Full name is required</span>}
   * imageUploadError:
   *  Example usage: {imageUploadError && <span>{imageUploadError}</span>}
   */

  return {
    imagePreview,
    handleImageChange,
    onSubmit,
    handleBack,
    handleSubmit,
    register,
    errors,
    imageUploadError,
    isSubmitting,
  };
};

export default useCreateAccountForm;
