import * as React from "react";

function Warning(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="white" />
      <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#EAECF0" />
      <path
        d="M20 29C24.9706 29 29 24.9706 29 20C29 15.0294 24.9706 11 20 11C15.0294 11 11 15.0294 11 20C11 24.9706 15.0294 29 20 29Z"
        stroke="#1A1A1A"
        stroke-miterlimit="10"
      />
      <path
        d="M20 15.5V20.75"
        stroke="#1A1A1A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 25.25C20.6213 25.25 21.125 24.7463 21.125 24.125C21.125 23.5037 20.6213 23 20 23C19.3787 23 18.875 23.5037 18.875 24.125C18.875 24.7463 19.3787 25.25 20 25.25Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export default Warning;
