import * as React from "react";

function TickIcon(props) {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <rect width="96" height="96" rx="48" fill="#F2F4F7" />
      <path
        d="M56.5613 42.4387C56.7007 42.5781 56.8114 42.7435 56.8868 42.9256C56.9623 43.1077 57.0012 43.3029 57.0012 43.5C57.0012 43.6971 56.9623 43.8923 56.8868 44.0744C56.8114 44.2565 56.7007 44.4219 56.5613 44.5613L46.0613 55.0613C45.9219 55.2007 45.7565 55.3114 45.5744 55.3868C45.3923 55.4623 45.1971 55.5012 45 55.5012C44.8029 55.5012 44.6077 55.4623 44.4256 55.3868C44.2435 55.3114 44.0781 55.2007 43.9388 55.0613L39.4388 50.5613C39.1573 50.2798 38.9992 49.898 38.9992 49.5C38.9992 49.102 39.1573 48.7202 39.4388 48.4387C39.7202 48.1573 40.102 47.9992 40.5 47.9992C40.8981 47.9992 41.2798 48.1573 41.5613 48.4387L45 51.8794L54.4388 42.4387C54.5781 42.2993 54.7435 42.1886 54.9256 42.1132C55.1077 42.0377 55.3029 41.9988 55.5 41.9988C55.6971 41.9988 55.8923 42.0377 56.0744 42.1132C56.2565 42.1886 56.4219 42.2993 56.5613 42.4387ZM67.5 48C67.5 51.8567 66.3564 55.6269 64.2137 58.8336C62.071 62.0404 59.0255 64.5397 55.4623 66.0156C51.8992 67.4916 47.9784 67.8777 44.1957 67.1253C40.4131 66.3729 36.9386 64.5157 34.2114 61.7886C31.4843 59.0615 29.6271 55.5869 28.8747 51.8043C28.1223 48.0216 28.5084 44.1008 29.9844 40.5377C31.4603 36.9745 33.9596 33.929 37.1664 31.7863C40.3731 29.6437 44.1433 28.5 48 28.5C53.1701 28.5055 58.1268 30.5617 61.7826 34.2174C65.4383 37.8732 67.4945 42.83 67.5 48ZM64.5 48C64.5 44.7366 63.5323 41.5465 61.7193 38.8331C59.9062 36.1197 57.3293 34.0048 54.3143 32.756C51.2993 31.5071 47.9817 31.1804 44.781 31.817C41.5803 32.4537 38.6403 34.0252 36.3327 36.3327C34.0252 38.6403 32.4537 41.5803 31.8171 44.781C31.1804 47.9817 31.5072 51.2993 32.756 54.3143C34.0048 57.3293 36.1197 59.9062 38.8331 61.7192C41.5465 63.5323 44.7366 64.5 48 64.5C52.3746 64.495 56.5685 62.7551 59.6618 59.6618C62.7551 56.5685 64.495 52.3745 64.5 48Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export default TickIcon;
