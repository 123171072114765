import api from "../../services/api";

/**
 * Creates PosOrder API endpoints based on the specified base path.
 * @param {string} basePath - The base path for PosOrder endpoints.
 * @returns {Object} Object containing PosOrder API methods.
 */
const createPosOrdersApi = (basePath) => ({
  /**
   * get all PosOrder
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting PosOrder
   *    order: order for sorting PosOrder by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the PosOrder response.
   */
  getAllPosOrders: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * get a single PosOrder
   * @param {object} itemId - id of the PosOrder
   * @returns {Promise} A Promise that resolves to the PosOrder response.
   */
  getSinglePosOrder: async (itemId) => {
    const response = await api().get(`${basePath}/${itemId}`);
    return response;
  },

  /**
   * update a single PosOrder
   * @param {object} orderId - id of the PosOrder
   * @returns {Promise} A Promise that resolves to the PosOrder response.
   */
  updatePosOrder: async (orderId, payload) => {
    const response = await api().patch(`${basePath}/${orderId}`, payload);
    return response;
  },

  /**
   * add a PosOrder
   * @param {object} tableId - id of the PosOrder
   * @returns {Promise} A Promise that resolves to the PosOrder response.
   */
  addPosOrder: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * add a PosOrder
   * @param {object} tableId - id of the PosOrder
   * @returns {Promise} A Promise that resolves to the PosOrder response.
   */
  checkOutOrder: async (payload) => {
    const response = await api().post("/checkout", payload);
    return response;
  },
  /**
   * delete a PosOrder
   * @param {object} orderId - id of the PosOrder
   * @returns {Promise} A Promise that resolves to the PosOrder response.
   */
  deletePosOrder: async (orderId) => {
    const response = await api().delete(`${basePath}/${orderId}`);
    return response;
  },

   /**
   * get a single PosOrder
   * @param {object} itemId - id of the PosOrder
   * @returns {Promise} A Promise that resolves to the PosOrder response.
   */
   returnPosOrder: async (orderId) => {
    const response = await api().get(`${basePath}/return/${orderId}`);
    return response;
  },
});

// Export the createPosOrdersApi

/**
 * Example usage:
 * import createPosOrdersApi
 from './path/to/api/PosOrderApi';
 *
 * const PosOrderApi = createPosOrdersApi
('PosOrder');
 *
 * const getAllPosOrder = async (payload) => {
 *   try {
 *     const response = await PosOrderApi.getAllPosOrder(payload);
 *     // Handle response
 *   } catch (error) {
 *     // Handle error
 *   }
 * };
 */

export default createPosOrdersApi;
