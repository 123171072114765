import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import { loginAndSignUpWithOAuth } from "../../features/Auth/AuthSlice";
import { FacebookIcon } from "../common/Icons";
import { CustomButton } from "../common/components";

const FacebookLoginButton = ({title}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { updateUser, setUserProfile, setToken } = useUserContext();

  const handleSuccess = async (tokenResponse) => {
    dispatch(
      loginAndSignUpWithOAuth({
        loginType: "facebook",
        accessToken: tokenResponse?.accessToken,
        successCallBack: (data) => {
          if (data?.user) {
            if (!data.user.isProfileCompleted) {
              navigate("/create-account");
              return;
            }
            if (!data.user.isBusinessDetailCompleted) {
              navigate("/business-profile");
              return;
            }

            // Save user data, token, profile, etc.
            updateUser(data.user);
            setUserProfile(data.user.profile);
            setToken(data.token);

            // Check user role and redirect accordingly
            if (data.user.role === "SUPER_ADMIN") {
              navigate("/admin/dashboard");
            } else if (data.user.role === "CLIENT") {
              navigate("/dashboard");
            }

            console.log("Login/Signup with Facebook succeeded:", data);
          } else {
            // Handle case where user data is not available
            console.error("User data not found:", data);
          }
        },
      })
    );
  };

  const handleFailure = (error) => {
    console.log("Error:", error);
  };

  return (
    <FacebookLogin
      appId="1073861703922356"
      fields="name,email,picture"
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      callback={handleSuccess}
      render={(renderProps) => (
        <CustomButton
          border="border"
          textColor="text-text-primary"
          text={title}
          icon={<FacebookIcon />}
          onClick={renderProps.onClick}
        />
      )}
    />
  );
};

export default FacebookLoginButton;
