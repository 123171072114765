import React, { createContext, useContext, useEffect, useState } from "react";
import useStorage from "../components/common/utils/localStorage.util";

// Create a context to store language information
const LanguageContext = createContext();

// Create a custom hook to access the language context
export const useLanguageContext = () => useContext(LanguageContext);

// Create a LanguageProvide component to wrap your application
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    useStorage.getItem("selectedLanguage")?.language || "en"
  );

  // Function to set language information
  const updateLanguage = (newLang) => {
    setLanguage(newLang);
  };

  useEffect(() => {
    const storage = { language };
    useStorage.setItem("selectedLanguage", storage);
  }, [language]);

  // Value to be passed to the context provider
  const value = {
    language,
    updateLanguage,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};
