/**
 * Import the React library for building UI components.
 */
import React from "react";

/**
 * Import the FontAwesomeIcon component from the Font Awesome React package.
 * This component allows us to render Font Awesome icons in our application.
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Import individual icons (faCoffee and faCheckSquare) from the Font Awesome free-solid-svg-icons package.
 * These icons are part of the free solid icon set provided by Font Awesome.
 * Import more icons here for use
 */
import {
  faCoffee,
  faCheckSquare,
  faEye,
  faEyeSlash,
  faCaretDown,
  faUpload,
  faUser,
  faCheck,
  faHeart,
  faPlus,
  faFilePdf,
  faX,
  faImage,
} from "@fortawesome/free-solid-svg-icons";

/**
 * Common parent component.
 * Renders a FontAwesomeIcon with dynamic classes based on props.
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.icon - The FontAwesome icon to be rendered.
 * @param {string} [props.height='4'] - The height of the icon.
 * @param {string} [props.width='4'] - The width of the icon.
 * @param {string} [props.color='gray-500'] - The color of the icon.
 * @param {string} [props.bgColor='transparent'] - The background color of the icon.
 * @returns {JSX.Element} JSX representing the CommonComponent.
 */
const CommonComponent = ({
  icon,
  height = "4",
  width = "4",
  color = "gray-500",
  bgColor = "transparent",
  hoverColor = "gray-500",
  flip = "horizontal", // horizontal, vertical, both
  spin, // animation: spin, pulse
  border,
  pull = "left",
  transform = "shrink-6 left-4", // transform={{ rotate: 42 }}
  mask = ["far", "circle"], // masking
}) => (
  /**
   * Render the FontAwesomeIcon with dynamic classes based on props.
   */
  <FontAwesomeIcon
    icon={icon}
    className={`w-${width} h-${height} text-${color} hover:!text-${hoverColor} bg-${bgColor}`}
  />
);
/**
 * Example usage:
 * <CommonComponent icon={faCoffee} height="3rem" width="3rem" color="red" />
 * <CommonComponent icon={faCheckSquare} height="2rem" width="2rem" color="blue" />
 */

/**
 * Icon components.
 * All components use the following props
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.height='4'] - The height of icon.
 * @param {string} [props.width='4'] - The width of icon.
 * @param {string} [props.color='gray-500'] - The color of icon.
 * @param {string} [props.bgColor='transparent'] - The background color of icon.
 * @returns {JSX.Element} JSX representing component.
 */

/**
 * Child component for CoffeeCup.
 */
export const CoffeeCup = (props) => (
  /**
   * Render the CommonComponent with specific icon for CoffeeCup.
   */
  <CommonComponent {...props} icon={faCoffee} />
);

/**
 * Child component for CheckSquare.
 */
export const CheckSquare = (props) => (
  /**
   * Render the CommonComponent with specific icon for CheckSquare.
   */
  <CommonComponent {...props} icon={faCheckSquare} />
);

/**
 * Child component for Eye.
 */
export const Eye = (props) => (
  /**
   * Render the CommonComponent with specific icon for Eye.
   */
  <CommonComponent {...props} icon={faEye} />
);

/**
 * Child component for EyeSlash.
 */
export const EyeSlash = (props) => (
  /**
   * Render the CommonComponent with specific icon for EyeSlash.
   */
  <CommonComponent {...props} icon={faEyeSlash} />
);

/**
 * Child component for PdfIcon.
 */
export const PdfIcon = (props) => (
  /**
   * Render the CommonComponent with specific icon for PdfIcon.
   */
  <CommonComponent {...props} icon={faFilePdf} />
);

/**
 * Child component for CartDown.
 */
export const CartDown = (props) => (
  /**
   * Render the CommonComponent with specific icon for CartDown.
   */
  <CommonComponent {...props} icon={faCaretDown} />
);

/**
 * Child component for CartDown.
 */
export const Upload = (props) => (
  /**
   * Render the CommonComponent with specific icon for CartDown.
   */
  <CommonComponent {...props} icon={faUpload} />
);

/**
 * Child component for CartDown.
 */
export const User = (props) => (
  /**
   * Render the CommonComponent with specific icon for CartDown.
   */
  <CommonComponent {...props} icon={faUser} />
);
/**
 * Child component for CartDown.
 */
export const Check = (props) => (
  /**
   * Render the CommonComponent with specific icon for CartDown.
   */
  <CommonComponent {...props} icon={faCheck} />
);
/**
 * Child component for CartDown.
 */
export const Heart = (props) => (
  /**
   * Render the CommonComponent with specific icon for CartDown.
   */
  <CommonComponent {...props} icon={faHeart} />
);

/**
 * Child component for Plus.
 */
export const Plus = (props) => (
  /**
   * Render the CommonComponent with specific icon for Plus.
   */
  <CommonComponent {...props} icon={faPlus} />
);

/**
 * Child component for Cross.
 */
export const Cross = (props) => (
  /**
   * Render the CommonComponent with specific icon for Cross.
   */
  <CommonComponent {...props} icon={faX} />
);
/**
 * Child component for ImageIcon.
 */
export const ImageIcon = (props) => (
  /**
   * Render the CommonComponent with specific icon for ImageIcon.
   */
  <CommonComponent {...props} icon={faImage} />
);
