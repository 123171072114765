import { useState, Fragment } from "react";

export default function Accordion({
  items,
  selectedIndex,
  selectedValues,
  onRadioChange,
}) {
  const [openIndices, setOpenIndices] = useState(
    new Array(items.length).fill(false)
  );

  const toggleItem = (index) => {
    const newOpenIndices = [...openIndices];
    newOpenIndices[index] = !newOpenIndices[index];
    setOpenIndices(newOpenIndices);
  };

  return (
    <div className="flex flex-col space-y-4">
      {items?.map((item, index) =>
        item.options.length === 0 ? null : (
          <Fragment key={index}>
            <button
              className={`flex justify-between items-center p-3 rounded-lg border shadow-sm w-full ${
                openIndices[index]
                  ? "bg-blue-100 border-blue-300"
                  : "bg-white border-gray-300 hover:bg-gray-50"
              }`}
              onClick={() => toggleItem(index)}
            >
              <span className="font-semibold ">{item.title}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-5 w-5 transition-transform ${
                  openIndices[index] ? "rotate-180" : "rotate-0"
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            <div
              className={`p-2 bg-white rounded-lg shadow-sm border border-gray-400 ${
                openIndices[index] ? "block" : "hidden"
              }`}
            >
              {item?.options.length > 0 ? (
                item.options.map((option, idx) => (
                  
                  <div
                    key={idx}
                    className="flex items-center mb-2 last:mb-0 border rounded-lg p-2"
                  >
                    <input
                      type="radio"
                      name={`option-${index}`}
                      value={option.value}
                      checked={selectedValues[index]?.value === option.value}
                      onChange={() => onRadioChange(option, index)}
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                    />
                    <div className="flex justify-between  w-full">
                      <label className="ml-2 text-sm font-medium ">
                        {option.title}
                      </label>
                     
                        <label className="text-sm font-medium text-yellow-600 ">
                      {option.price} {option.type === "PERCENTAGE" ? "%" : option.type === "FIXED_AMOUNT" ? "SAR" : ""}
                    </label>
                    <div>
                      <label className="ml-2 text-sm font-medium text-green-400 ">
                        {option.start}  |
                      </label> <label className="text-sm font-medium text-red-400">
                        {option.end}
                      </label>
                      </div>
                    </div>
                    
                  </div>
                ))
              ) : (
                <div className="text-red-600 flex justify-center">
                  No Promotion for this item...!
                </div>
              )}
            </div>
          </Fragment>
        )
      )}
    </div>
  );
}
