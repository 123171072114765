import React from "react";
import CustomPosTable from "../../../../common/components/PosTable/PosTable";
import useBillingHistory from "./useBillingHistory";
import { CustomButton } from "../../../../common/components";
import { CaretRight } from "../../../../common/Icons";

function BillingHistory() {
  const { tableBody, headers, handleShowAllClick, subscriptionLoading } =
    useBillingHistory();

  return (
    <div>
      <div className="flex items-center space-x-4 mt-2  ">
        <div className="flex items-center flex-grow text-text-primary font-medium text-base">
          Latest Billing History
        </div>

        <CustomButton
          icon={<CaretRight color="blue" />}
          width="w-fit"
          border="none"
          shadow="none"
          text="Show All"
          padding="!p-0"
          textColor="text-text-link"
          iconPosition="after"
          className="text-base"
          onClick={handleShowAllClick}
        />
      </div>
      <div className=" flex flex-col justify-between mt-2 overflow-auto">
        <CustomPosTable
          tableHeader={headers}
          tableBody={tableBody}
          loading={subscriptionLoading}
          showActionsColumn={false}
        />
      </div>
    </div>
  );
}

export default BillingHistory;
