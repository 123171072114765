import React from "react";
import CustomPosTable from "../../../../../../common/components/PosTable/PosTable";

function ClientDetailBillingHistory({ clientDetails }) {
  const { billingHistory } = clientDetails.subscription || {};
  const capitalizePlanType = (planType) => {
    if (!planType) return "";
    return planType
      .replace(/\(([^)]+)\)/, (match, p1) => {
        return `(${p1.charAt(0).toUpperCase() + p1.slice(1).toLowerCase()})`;
      })
      .replace(/^\w/, (c) => c.toUpperCase())
      .replace(/\s+(\w)/g, (c) => c.toLowerCase());
  };

  const tableBody = billingHistory?.map((subscription) => ({
    invoiceno: subscription.invoiceNumber ?? "-",
    startdate: subscription.startDate ?? "-",
    enddate: subscription.endDate ?? "-",
    plan: capitalizePlanType(subscription.planType) ?? "-",
    branches:
      subscription.branches === null
        ? "None"
        : subscription.branches.toString(),
    totalamount: `${subscription.subTotal} SAR` ?? "-",
    status: subscription.subscriptionStatus ? "Active" : "Inactive",
    paymentmethod: (
      <img className="h-6 w-6" src={subscription.paymentMethod} alt="Bank" />
    ),
  }));

  const headers = [
    { label: "Invoice No", key: "invoiceno" },
    { label: "Start Date", key: "startdate" },
    { label: "End Date", key: "enddate" },
    { label: "Plan", key: "plan" },
    { label: "Branches", key: "branches" },
    { label: "Total Amount", key: "totalamount" },
    { label: "Status", key: "status" },
    { label: "Payment Method", key: "paymentmethod" },
  ];

  return (
    <div>
      {billingHistory && billingHistory.length > 0 ? (
        <div className=" flex flex-col justify-between mt-2 overflow-auto">
          <CustomPosTable
            tableHeader={headers}
            tableBody={tableBody}
            loading={false}
            showActionsColumn={false}
          />
        </div>
      ) : (
        <table className="min-w-full divide-y divide-gray-300 border rounded-md">
          <thead className="bg-gray-50 sticky top-0">
            <tr>
              {headers?.map((item, index) => (
                <th
                  key={index}
                  scope="col"
                  className={`py-1 text-left text-sm font-semibold text-text-secondary ${
                    index === 0 ? "pr-3 pl-4 sm:pl-6" : "px-3"
                  }`}
                >
                  {item?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className=" bg-white  ">
            {/* Loading indicator */}

            <p className="p-2  text-text-secondary font-medium justify-center flex items-center">
              No record found!
            </p>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ClientDetailBillingHistory;
