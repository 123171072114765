import { useEffect, useState } from "react";
import { Warning, CurrencyDollar, BlueBulb, RocketIcon } from "../../../../../common/Icons";

const getTimeDifference = (createdAt) => {
  const createdTime = new Date(createdAt);
  const currentTime = new Date();
  const timeDifference = Math.abs(currentTime - createdTime);
  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }
};
const iconList = [Warning, BlueBulb, RocketIcon];
const getRandomIcon = () => {
  const randomIndex = Math.floor(Math.random() * iconList.length);
  return iconList[randomIndex];
};

const useAdminNavNotification = ({ data }) => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    if (data && data !== null) {
      const transformedNotifications = data?.map((msg) => ({
        id: msg.id,
        icon: getRandomIcon(), 
        message: msg.message,
        time: getTimeDifference(msg.createdAt), 
        read: msg.isRead,
      }));
      setNotifications(transformedNotifications);
    }
  }, [data]);

  return {
    notifications,
  };
};

export default useAdminNavNotification;