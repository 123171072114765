import React from "react";

const Dropdown = ({ options, userId, width = 'w-32' }) => {
  return (
    <div className={`absolute right-8 mt-[-2px] bg-white shadow-md rounded border z-50 ${width}`}>
      <div className="origin-top-right w-full">
        {options?.map((option, index) => {
          return (
            <button
              key={index}
              onClick={() => option.action && option.action(userId)}
              className={`flex items-center px-4 hover:bg-gray-100 py-1 text-sm font-normal w-full text-left ${option.textColor || 'text-gray-700'}`}
            >
              {option.icon && (
                <span className="mr-2">
                  {option.icon}
                </span>
              )}
              {option.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;

// Sample Usage:
// import Dropdown from 'path/to/Dropdown';
// <Dropdown options={options} userId={userId} width="w-48" />
