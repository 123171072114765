import { createSlice } from "@reduxjs/toolkit";

/**
 * Modal Slice for managing modal state.
 *
 * @typedef {Object} ModalState - State object representing the modal properties.
 * @property {boolean} isOpen - Flag indicating whether the modal is open or closed.
 * @property {JSX.Element} content - JSX content to be displayed in the modal.
 * @property {string} title - Title for the modal.
 * @property {string} type - Type of the modal.
 * @property {boolean} editable - Flag indicating whether the modal content is editable.
 * @property {Function} handleDelete - Function to handle the delete action.
 * @property {Function} handleEdit - Function to handle the edit action.
 * @property {Function} onClose - Function to handle the modal close action.
 *
 * @param {ModalState} state - Current state of the modal.
 * @param {Object} action - Action object to modify the modal state.
 * @param {string} action.type - Type of action.
 * @param {Object} action.payload - Payload data for the action.
 * @returns {void} No return value. Modifies the state based on the action.
 */

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    isOpen: false,
    content: null,
    title: "",
    type: null,
    editable: false,
    handleDelete: null,
    handleEdit: null,
    onClose: null,
  },
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
      state.content = action.payload;
      state.title = action.payload.title || "";
      state.type = action.payload.type || null;
      state.editable = action.payload.editable || false;
      state.handleDelete = action.payload.handleDelete || null;
      state.handleEdit = action.payload.handleEdit || null;
      state.onClose = action.payload.onClose || null;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.content = null;
      state.title = "";
      state.type = null;
      state.editable = false;
      state.handleDelete = null;
      state.handleEdit = null;
      state.onClose = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;

// Sample Usage:
// import { openModal, closeModal } from "path/to/modalSlice";
//
// const dispatch = useDispatch();
//
//  Open the modal
// dispatch(openModal({
//   title: "Edit User",
//   type: "edit",
//   editable: true,
//   handleEdit: handleEditFunction,
//   onClose: handleCloseFunction,
//    Other modal content...
// }));
//
// Close the modal
// dispatch(closeModal());
