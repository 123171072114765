import * as React from "react";

function FileIcon(props) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M12.4999 5.5001L4.69054 13.4407C4.432 13.7261 4.29309 14.0999 4.30257 14.4848C4.31205 14.8697 4.46919 15.2363 4.74146 15.5086C5.01372 15.7808 5.38027 15.938 5.7652 15.9474C6.15012 15.9569 6.52396 15.818 6.80929 15.5595L16.1187 6.11885C16.6357 5.54817 16.9136 4.80051 16.8946 4.03065C16.8757 3.26079 16.5614 2.52771 16.0168 1.98317C15.4723 1.43864 14.7392 1.12436 13.9694 1.1054C13.1995 1.08645 12.4518 1.36427 11.8812 1.88135L2.57179 11.322C1.7289 12.1649 1.25537 13.3081 1.25537 14.5001C1.25537 15.6921 1.7289 16.8353 2.57179 17.6782C3.41468 18.5211 4.55789 18.9946 5.74992 18.9946C6.94195 18.9946 8.08515 18.5211 8.92804 17.6782L16.6249 10.0001"
        stroke="#1A1A1A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FileIcon;
