import * as React from "react";

function CrossIcon(props) {
  // Destructure width and height from props, defaulting to 24 if not provided
  const { width = 24, height = 24, className, stroke } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="X">
        <path
          id="Vector"
          d="M18.75 5.25L5.25 18.75"
          stroke={stroke ? "#ffffff" : "#1A1A1A"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M18.75 18.75L5.25 5.25"
          stroke={stroke ? "#ffffff" : "#1A1A1A"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default CrossIcon;
