import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Gleap from "gleap";
import { GoogleIcon } from "../common/Icons";
import { CustomButton } from "../common/components";
import { useGoogleLogin } from "@react-oauth/google";
import { loginAndSignUpWithOAuth } from "../../features/Auth/AuthSlice";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../context/UserContext";
import { showToast } from "../common/utils/showToast.util";

const GoogleLoginButton = ({title}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateUser, setUserProfile, setToken } = useUserContext();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      dispatch(
        loginAndSignUpWithOAuth({
          loginType: "google",
          accessToken: tokenResponse?.access_token,
          successCallBack: (data) => {
            if (data?.user) {
              debugger
              const userData = data?.user;
              if (userData) {
                updateUser(userData);
                setUserProfile(userData?.profile);
                setToken(data?.token);
              }

              if (
                userData &&
                ["SUPER_ADMIN", "ACCOUNTING", "ACCOUNT_MANAGER"].includes(
                  userData.role
                )
              ) {
                return setTimeout(() => {
                  navigate("/admin/dashboard");
                }, 1000);
              }

              let isPosUser =
                userData?.role === "CASHIER" ||
                userData?.role === "SALES MANAGER" ||
                userData?.role === "SYSTEM MANAGER";

              let isPosUserOnClient = userData?.isPosUser && isPosUser;

              const isClient = userData?.role === "CLIENT";

              if (isClient && !userData?.isProfileCompleted) {
                return navigate("/create-account");
              }

              if (isClient && !userData?.isBusinessDetailCompleted) {
                return navigate("/business-profile");
              }

              if (
                userData?.isPasswordCreated &&
                isClient &&
                (userData?.isFreeTrial
                  ? userData?.freeTrial?.isFreeTrialEnded
                  : !userData?.isSubscriptionPlanCompleted)
              ) {
                showToast("Please set up your subscription plan!", "error");
                return navigate("/set-plan");
              }

              // First, check if user is an admin and allow immediate access
              if (
                !(userData?.isFreeTrial
                  ? userData?.freeTrial?.isFreeTrialEnded
                  : !userData?.isSubscriptionPlanCompleted)
              ) {
                updateUser({ ...userData, isLoginVerified: true });

                // Same identification logic as above for non-admin users
                Gleap.identify(userData.id, {
                  name: userData?.profile?.fullName,
                  email: userData?.email,
                  phone: userData?.profile?.phoneNumber,
                  companyId: userData?.businessDetail?.commercialRegister,
                  companyName: userData?.businessDetail?.companyName,
                  createdAt: userData?.createdAt,
                  customData: {
                    arabicName: userData?.profile?.arabicName,
                    role: userData.role,
                    companyAddress: userData?.businessDetail?.companyAddress,
                    businessType: userData?.businessDetail?.businessType,
                    website: userData?.businessDetail?.website,
                    vatNumber: userData?.businessDetail?.vatNumber,
                  },
                });

                // Route the user based on their specific role
                const roleBasedRoutes = {
                  CLIENT: "/dashboard",
                  "SALES MANAGER": "/dashboard",
                  "SYSTEM MANAGER": "/dashboard",
                  CASHIER: "/invoices/sales",
                };
                

                const navigatePath =
                  roleBasedRoutes[userData.role] || "/dashboard"; // Default to "/dashboard" if no role match

                setTimeout(() => {
                  navigate(navigatePath);
                }, 2000);
              } else if (
                isPosUserOnClient &&
                (userData?.isFreeTrial
                  ? userData?.freeTrial?.isFreeTrialEnded
                  : !userData?.isSubscriptionPlanCompleted)
              ) {
                showToast(
                  "You can't access this page. Please contact your admin!",
                  "error"
                );
                return null;
              } else {
                // Notify and navigate if no valid subscription or trial
                showToast("Please set up your subscription plan!", "error");
                navigate("/set-plan");
              }
            } else {
              // Handle case where user data is not available
              console.error("User data not found:", data);
            }
          },
        })
      );
      // fetching userinfo can be done on the client or the server
    },
    clientId: "your_client_id",
    onFailure: (error) => {
      console.log("Error:", error);
    },
    isSignedIn: true, // Specify if you need to automatically check if the user is signed in
  });

  return (
    <CustomButton
      onClick={handleGoogleLogin}
      border="border"
      textColor="text-text-primary"
      text={title}
      icon={<GoogleIcon />}
    />
  );
};

export default GoogleLoginButton;
