import * as React from "react";

function PrinterIcon(props) {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M19.1253 3.75H17.75V0.75C17.75 0.551088 17.671 0.360322 17.5303 0.21967C17.3897 0.0790178 17.1989 0 17 0H5C4.80109 0 4.61032 0.0790178 4.46967 0.21967C4.32902 0.360322 4.25 0.551088 4.25 0.75V3.75H2.87469C1.565 3.75 0.5 4.75969 0.5 6V13.5C0.5 13.6989 0.579018 13.8897 0.71967 14.0303C0.860322 14.171 1.05109 14.25 1.25 14.25H4.25V17.25C4.25 17.4489 4.32902 17.6397 4.46967 17.7803C4.61032 17.921 4.80109 18 5 18H17C17.1989 18 17.3897 17.921 17.5303 17.7803C17.671 17.6397 17.75 17.4489 17.75 17.25V14.25H20.75C20.9489 14.25 21.1397 14.171 21.2803 14.0303C21.421 13.8897 21.5 13.6989 21.5 13.5V6C21.5 4.75969 20.435 3.75 19.1253 3.75ZM5.75 1.5H16.25V3.75H5.75V1.5ZM16.25 16.5H5.75V12H16.25V16.5ZM20 12.75H17.75V11.25C17.75 11.0511 17.671 10.8603 17.5303 10.7197C17.3897 10.579 17.1989 10.5 17 10.5H5C4.80109 10.5 4.61032 10.579 4.46967 10.7197C4.32902 10.8603 4.25 11.0511 4.25 11.25V12.75H2V6C2 5.58656 2.39281 5.25 2.87469 5.25H19.1253C19.6072 5.25 20 5.58656 20 6V12.75ZM17.75 7.875C17.75 8.0975 17.684 8.31501 17.5604 8.50002C17.4368 8.68502 17.2611 8.82922 17.0555 8.91436C16.85 8.99951 16.6238 9.02179 16.4055 8.97838C16.1873 8.93498 15.9868 8.82783 15.8295 8.6705C15.6722 8.51316 15.565 8.31271 15.5216 8.09448C15.4782 7.87625 15.5005 7.65005 15.5856 7.44448C15.6708 7.23891 15.815 7.06321 16 6.9396C16.185 6.81598 16.4025 6.75 16.625 6.75C16.9234 6.75 17.2095 6.86853 17.4205 7.0795C17.6315 7.29048 17.75 7.57663 17.75 7.875Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export default PrinterIcon;
