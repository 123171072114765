// Import the 'api' module for making HTTP requests
import api from "../../services/api";

/**
 * Function to perform store login with a store code.
 * @param {object} data - Store login data including the store code.
 * @returns {Promise} - A promise that resolves with the HTTP response.
 */

const posCategories = async ({ data}) => {
  const response = await api().post("pos-user/categories", data);
  return response.data;
};

/**
 * Object that encapsulates functions for POS login-related operations.
 * Provides functions for email, store code, and fast code login.
 */
const posHomeService = {
  posCategories, // Function for store code login
};

export default posHomeService; // Export the posHomeService object
