import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../../context/UserContext";
import {
  fastcode,
  storecode,
} from "../../../../features/PosLogin/PosLoginSlice";

export const usePosLogin = () => {
  const [storeCode, setStoreCode] = useState(Array(5).fill(""));
  const [fastAccessCode, setFastAccessCode] = useState(Array(4).fill(""));
  const [isFastAccess, setIsFastAccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { updateUser, setUserProfile, setToken } = useUserContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();  

  // Function to submit the store code
  const submitStoreCode = () => {
    if (storeCode.some((val) => val === "")) {
      setErrorMessage("Please Enter 5 digits Store Code");
      return;
    }
    setErrorMessage("");
    const storeCodeValue = parseInt(storeCode.join(""), 10);
    const payload = {
      storeCode: storeCodeValue, 
    };
    dispatch(storecode({ payload, successCallBack: moveSingleRouter }));
  };

  const moveSingleRouter = (data) => {
    if (data?.data?.Succeeded) {
      // Extracting the user data from the response
      const userData = data.data.data;
      updateUser({ ...userData, isLoginVerified: true });
      setUserProfile(userData?.profile);
      setToken(userData?.token);
     
      // Toggle to show Fast Access Code screen
      setIsFastAccess(true);

      // Optionally, reset the store code input
      setStoreCode(Array(5).fill(""));
    } else {
      // Handle the scenario where the response is not successful
      // For example, display an error message or log the issue
      setErrorMessage("Please try again.");
    }
  };

  const submitFastAccessCode = () => {
    // Check if fastAccessCode is not filled or is not exactly 4 digits
    if (fastAccessCode.some((val) => val === "" || val.length !== 1)) {
      setErrorMessage("Please Enter 4 digits Fast Code");
      return;
    }

    setErrorMessage("");

    // Concatenate the individual digits of fastAccessCode to form a 4-digit string
    const fastCodeValue = fastAccessCode.join("");

    // Ensure the resulting fastAccessCode is a 4-digit string
    if (fastCodeValue.length !== 4 || isNaN(fastCodeValue)) {
      setErrorMessage("Please Enter 4 digits Fast Code");
      return;
    }

    const payload = {
      fastAccessCode: Number(fastCodeValue), 
    };
    dispatch(fastcode({ payload, successCallBack: posRouter }));
  };

  const posRouter = async (data) => {
    if (data?.data?.Succeeded) {
      // Extracting the user data from the response
      const userData = await data.data.data;
     await updateUser({ ...userData, isLoginVerified: true });
     await setUserProfile(userData?.profile);
     await setToken(userData?.token);
      navigate("/pos/dashboard");
    } else {
      // Stay on the Fast Access Code screen and show error message
      setErrorMessage("Invalid Fast Access Code. Please try again.");
      setFastAccessCode(Array(4).fill(""));
    }
  };

  // Function to toggle fast access mode
  const toggleFastAccess = () => {
    if (isFastAccess) {
      navigate("/pos/dashboard");
    } else {
      setIsFastAccess((prev) => !prev);
      setStoreCode(Array(5).fill(""));
    }
  };

  // Function to handle button click and navigation
  const handleButtonClick = () => {
    navigate("/pos/login");
  };
  // Function to handle keypad button presses

  const handleKeypadPress = (key, isFastAccess) => {
    if (isFastAccess) {
      setFastAccessCode((prevCode) => {
        const nextCode = [...prevCode];
        const firstEmptyIndex = nextCode.findIndex((val) => val === "");
        if (key === "←") {
          if (firstEmptyIndex === -1) nextCode[nextCode.length - 1] = "";
          else if (firstEmptyIndex > 0) nextCode[firstEmptyIndex - 1] = "";
        } else if (firstEmptyIndex !== -1) {
          nextCode[firstEmptyIndex] = key;
        }
        return nextCode;
      });
    } else {
      setStoreCode((prevCode) => {
        const nextCode = [...prevCode];
        const firstEmptyIndex = nextCode.findIndex((val) => val === "");
        if (key === "←") {
          if (firstEmptyIndex === -1) nextCode[nextCode.length - 1] = "";
          else if (firstEmptyIndex > 0) nextCode[firstEmptyIndex - 1] = "";
        } else if (firstEmptyIndex !== -1) {
          nextCode[firstEmptyIndex] = key;
        }
        return nextCode;
      });
    }
  };
 
  /**
   * Custom hook for handling the Point of Sale (POS) login functionality.
   * This hook provides methods and states for managing the POS login form and behavior.
   * @returns {Object} An object containing:
   *   - dropdownOpen: State to track whether the language dropdown is open.
   *   - selectedLanguage: State to store the selected language.
   *   - storeCode: State to store the store code input.
   *   - fastAccessCode: State to store the fast access code input.
   *   - isFastAccess: State to track whether fast access mode is enabled.
   *   - toggleDropdown: Function to toggle the language dropdown's visibility.
   *   - handleSelectLanguage: Function to handle language selection.
   *   - dropdownRef: Reference to the language dropdown element.
   *   - errorMessage: State to store error messages.
   *   - submitStoreCode: Function to handle submission of the store code.
   *   - submitFastAccessCode: Function to handle submission of the fast access code.
   *   - toggleFastAccess: Function to toggle fast access mode.
   *   - handleButtonClick: Function to navigate to a different route.
   *   - handleKeypadPress: Function to handle keypad button presses.
   */
  return {
    storeCode,
    fastAccessCode,
    handleKeypadPress,
    isFastAccess,
    toggleFastAccess,
    submitStoreCode,
    submitFastAccessCode,
    setIsFastAccess,
    handleButtonClick,
    errorMessage,
  };
};
