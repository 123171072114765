import * as React from "react";

function CurrencySign(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1209 7.84638 17.2635 5.78216 15.7407 4.25932C14.2178 2.73648 12.1536 1.87913 10 1.875ZM10.9375 13.75H10.625V14.375C10.625 14.5408 10.5592 14.6997 10.4419 14.8169C10.3247 14.9342 10.1658 15 10 15C9.83424 15 9.67527 14.9342 9.55806 14.8169C9.44085 14.6997 9.375 14.5408 9.375 14.375V13.75H8.125C7.95924 13.75 7.80027 13.6842 7.68306 13.5669C7.56585 13.4497 7.5 13.2908 7.5 13.125C7.5 12.9592 7.56585 12.8003 7.68306 12.6831C7.80027 12.5658 7.95924 12.5 8.125 12.5H10.9375C11.1861 12.5 11.4246 12.4012 11.6004 12.2254C11.7762 12.0496 11.875 11.8111 11.875 11.5625C11.875 11.3139 11.7762 11.0754 11.6004 10.8996C11.4246 10.7238 11.1861 10.625 10.9375 10.625H9.0625C8.48234 10.625 7.92594 10.3945 7.51571 9.9843C7.10547 9.57406 6.875 9.01766 6.875 8.4375C6.875 7.85734 7.10547 7.30094 7.51571 6.8907C7.92594 6.48047 8.48234 6.25 9.0625 6.25H9.375V5.625C9.375 5.45924 9.44085 5.30027 9.55806 5.18306C9.67527 5.06585 9.83424 5 10 5C10.1658 5 10.3247 5.06585 10.4419 5.18306C10.5592 5.30027 10.625 5.45924 10.625 5.625V6.25H11.875C12.0408 6.25 12.1997 6.31585 12.3169 6.43306C12.4342 6.55027 12.5 6.70924 12.5 6.875C12.5 7.04076 12.4342 7.19973 12.3169 7.31694C12.1997 7.43415 12.0408 7.5 11.875 7.5H9.0625C8.81386 7.5 8.57541 7.59877 8.39959 7.77459C8.22378 7.9504 8.125 8.18886 8.125 8.4375C8.125 8.68614 8.22378 8.9246 8.39959 9.10041C8.57541 9.27623 8.81386 9.375 9.0625 9.375H10.9375C11.5177 9.375 12.0741 9.60547 12.4843 10.0157C12.8945 10.4259 13.125 10.9823 13.125 11.5625C13.125 12.1427 12.8945 12.6991 12.4843 13.1093C12.0741 13.5195 11.5177 13.75 10.9375 13.75Z"
        fill="black"
      />
    </svg>
  );
}

export default CurrencySign;
