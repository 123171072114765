import { LoginBgImage } from "../../assets/images";
import Navbar from "../common/components/Navbar/Navbar";
import Footer from "../common/components/Footer/Footer";

/**
 * LoginView component.
 * Renders a view with login-related elements like a form, Navbar, and Footer.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {JSX.Element} JSX code for rendering the LoginView component.
 */

const LoginView = ({ children }) => {
  return (
    <div className="flex min-h-screen">
      {/* form */}
      <div className="w-full flex flex-col justify-between">
        <Navbar language />
        {children}
        <Footer userType="client" login />
      </div>
      {/* image */}
      <div className="lg:min-w-[480px] hidden lg:block max-h-screen overflow-hidden">
        <img
          src={LoginBgImage}
          alt="Login background"
          className="w-full h-full"
        />
      </div>
    </div>
  );
};

export default LoginView;

// Example usage of LoginView component:
// import  LoginView  from './path/LoginView';
// <LoginView>
//   {/* Your login form or content goes here */}
// </LoginView>
