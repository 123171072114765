import * as React from "react";

function ProfilePicture(props) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="Profile Picture">
        <path
          id="Vector"
          d="M40 0C17.92 0 0 17.92 0 40C0 62.08 17.92 80 40 80C62.08 80 80 62.08 80 40C80 17.92 62.08 0 40 0ZM40 12C46.64 12 52 17.36 52 24C52 30.64 46.64 36 40 36C33.36 36 28 30.64 28 24C28 17.36 33.36 12 40 12ZM40 68.8C30 68.8 21.16 63.68 16 55.92C16.12 47.96 32 43.6 40 43.6C47.96 43.6 63.88 47.96 64 55.92C58.84 63.68 50 68.8 40 68.8Z"
          fill="#98A2B3"
        />
      </g>
    </svg>
  );
}

export default ProfilePicture;
