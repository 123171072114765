import * as React from "react";

function FunnelSimple(props) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4337_76160)">
        <rect x="2" y="1" width="40" height="40" rx="8" fill="white" />
        <rect
          x="2.5"
          y="1.5"
          width="39"
          height="39"
          rx="7.5"
          stroke="#EAECF0"
        />
        <path
          d="M16 20.75H28C28.0663 20.75 28.1299 20.7763 28.1768 20.8232C28.2237 20.8701 28.25 20.9337 28.25 21C28.25 21.0663 28.2237 21.1299 28.1768 21.1768C28.1299 21.2237 28.0663 21.25 28 21.25H16C15.9337 21.25 15.8701 21.2237 15.8232 21.1768C15.7763 21.1299 15.75 21.0663 15.75 21C15.75 20.9337 15.7763 20.8701 15.8232 20.8232C15.8701 20.7763 15.9337 20.75 16 20.75Z"
          fill="black"
          stroke="#1A1A1A"
        />
        <path
          d="M31.75 15.75H12.25C12.0511 15.75 11.8603 15.829 11.7197 15.9697C11.579 16.1103 11.5 16.3011 11.5 16.5C11.5 16.6989 11.579 16.8897 11.7197 17.0303C11.8603 17.171 12.0511 17.25 12.25 17.25H31.75C31.9489 17.25 32.1397 17.171 32.2803 17.0303C32.421 16.8897 32.5 16.6989 32.5 16.5C32.5 16.3011 32.421 16.1103 32.2803 15.9697C32.1397 15.829 31.9489 15.75 31.75 15.75Z"
          fill="black"
        />
        <path
          d="M24.25 24.75H19.75C19.5511 24.75 19.3603 24.829 19.2197 24.9697C19.079 25.1103 19 25.3011 19 25.5C19 25.6989 19.079 25.8897 19.2197 26.0303C19.3603 26.171 19.5511 26.25 19.75 26.25H24.25C24.4489 26.25 24.6397 26.171 24.7803 26.0303C24.921 25.8897 25 25.6989 25 25.5C25 25.3011 24.921 25.1103 24.7803 24.9697C24.6397 24.829 24.4489 24.75 24.25 24.75Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4337_76160"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4337_76160"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4337_76160"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default FunnelSimple;
