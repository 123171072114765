import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSinglePosOrder,
  handleCheckoutData,
} from "../../../../features/PosOrder/PosOrderSlice";

export const useCustomerOrderDetail = ({
  selectedItem,
  closeModal,
  setIsCheckoutModalOpen,
}) => {
  // const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [checkoutData, setCheckoutData] = useState(null);
  const currentDate = format(new Date(), "MM/dd/yyyy");
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const branchName = storedUserData?.store?.branchName;
  const fullName = storedUserData?.fullName;
  const discountAmount = Number(selectedItem?.discountAmount) || 0;
  const totalAmount = Number(selectedItem?.subTotal) || 0;
  const taxAmount = Number(selectedItem?.taxAmount) || 0;
  const total = Number(selectedItem?.totalAmount) || 0;

  const orderedItems =
    selectedItem?.data?.orderedItems ?? selectedItem?.orderedItems ?? "0";
  const orderStatus = selectedItem?.status;
  const showCompleteButton = orderStatus !== "COMPLETED";
  const dispatch = useDispatch();
  const itemId = selectedItem?.data?.id ?? selectedItem?.id ?? "0";

  // This function is triggered when the "Complete" button is clicked
  // In useCustomerOrderDetail hook

  const handleCompleteClick = () => {
    if (itemId) {
      dispatch(
        getSinglePosOrder({ itemId, successCallBack: successCompleteClick })
      ).unwrap();
    }
  };

  const successCompleteClick = (response) => {
    setCheckoutData(response?.data?.data);
    setIsCheckoutModalOpen(true);
    closeModal();
    dispatch(handleCheckoutData(response?.data?.data));
  };

  return {
    fullName,
    branchName,
    currentDate,
    orderedItems,
    total,
    taxAmount,
    discountAmount,
    totalAmount,
    checkoutData,
    setCheckoutData,
    handleCompleteClick,
    showCompleteButton,
  };
};

export default useCustomerOrderDetail;
