import * as React from "react";

function PlusCircle(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.76378 3.66004C7.01757 2.82229 8.49161 2.37509 9.99953 2.375C12.0207 2.379 13.9579 3.18368 15.3871 4.61287C16.8163 6.04206 17.621 7.9793 17.625 10.0005C17.6249 11.5084 17.1777 12.9824 16.34 14.2362C15.5021 15.4901 14.3113 16.4675 12.918 17.0446C11.5247 17.6217 9.99155 17.7727 8.51244 17.4785C7.03333 17.1843 5.67469 16.4581 4.60831 15.3917C3.54194 14.3253 2.81573 12.9667 2.52152 11.4876C2.2273 10.0085 2.3783 8.47533 2.95542 7.08204C3.53254 5.68875 4.50986 4.49789 5.76378 3.66004ZM11.125 8.875V6.875C11.125 6.57663 11.0065 6.29048 10.7955 6.0795C10.5845 5.86853 10.2984 5.75 10 5.75C9.70163 5.75 9.41549 5.86853 9.20451 6.0795C8.99353 6.29048 8.875 6.57663 8.875 6.875V8.875H6.875C6.57663 8.875 6.29049 8.99353 6.07951 9.2045C5.86853 9.41548 5.75 9.70163 5.75 10C5.75 10.2984 5.86853 10.5845 6.07951 10.7955C6.29049 11.0065 6.57664 11.125 6.875 11.125H8.875V13.125C8.875 13.4234 8.99353 13.7095 9.20451 13.9205C9.41549 14.1315 9.70164 14.25 10 14.25C10.2984 14.25 10.5845 14.1315 10.7955 13.9205C11.0065 13.7095 11.125 13.4234 11.125 13.125V11.125H13.125C13.4234 11.125 13.7095 11.0065 13.9205 10.7955C14.1315 10.5845 14.25 10.2984 14.25 10C14.25 9.70163 14.1315 9.41548 13.9205 9.2045C13.7095 8.99353 13.4234 8.875 13.125 8.875H11.125Z"
        fill="#0086FF"
        stroke="#0086FF"
      />
    </svg>
  );
}

export default PlusCircle;
