import { useState, useEffect } from "react";
import { z } from "zod";
import { getUser } from "../../../common/utils/users.utils";
import { useDispatch } from "react-redux";
import {
  getAllPosTables,
  addPosTable,
} from "../../../../features/PosTables/PosTablesSlice";

const tableSchema = z.object({
  selectedTable: z.string().min(1, "Please select a table type."),
  tableCount: z.number().min(1, "Please select how many tables."),
});

const useAddTable = (onAddTable, onClose) => {
  const [selectedTable, setSelectedTable] = useState("");
  const [tableCount, setTableCount] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const dispatch = useDispatch();

  // get category list for all categories from the store

  /**
   * Custom hook for managing client/business information editing.
   * This hook provides methods and states for handling the editing form.
   * @returns {Object} An object containing:
   *   - onSubmit: Function to handle form submission.
   *   - handleSubmit: Function from react-hook-form for form validation and submission.
   *   - register: Function from react-hook-form to register form inputs for validation and usage.
   *   - errors: Object holding form validation errors from react-hook-form.
   *   - setValue: Function from react-hook-form to set form input values programmatically.
   */
  useEffect(() => {
    const result = tableSchema.safeParse({
      selectedTable,
      tableCount: Number(tableCount),
    });

    setIsFormValid(result.success);
    if (!result.success) {
      setValidationError(result.error.format());
    } else {
      setValidationError("");
    }
  }, [selectedTable, tableCount]);

  const selectTable = (type) => {
    setSelectedTable(type);
  };

  // Function to handle form submission
  const submitTable = async () => {
    if (isFormValid) {
      onAddTable(selectedTable, parseInt(tableCount, 10));
      //  the user data is stored and contains a storeId
      const parsedData = localStorage.getItem("user");
      const storedUserData = JSON.parse(parsedData);
      const posId = storedUserData?.user?.id;
      const storeId = storedUserData?.user?.store?.id;

      const payload = {
        type: selectedTable.toUpperCase(), // API expects "RECTANGLE", "SQUARE", etc.
        numberOfTables: parseInt(tableCount, 10),
        storeId: storeId, // Use the storeId from user data or fallback to 0
        posId: posId, // Use the posId from user data or fallback to 0
      };

      try {
        await dispatch(
          addPosTable({ payload, successCallBack: updatedPosTables })
        ).unwrap();
        // updatedPosTables(result); // Call the success callback with the result
        onClose(); // Close the modal or form
      } catch (error) {
        console.error("Failed to add table:", error);
      }
    } else {
      console.log("Form is not valid:", validationError);
    }
    onClose();
  };

  const updatedPosTables = () => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllPosTables({ payload }));
    onClose();
  };
  // Function to handle closing the modal
  const handleCancel = () => {
    onClose();
  };

  // Function to check if a table type is selected
  const isSelected = (type) => selectedTable === type;

  const handleTableCountChange = (event) => {
    setTableCount(event.target.value);
  };
  const tableStyles = {
    rectangle: {
      width: "100%",
      height: "50%",
      borderRadius: "5px",
    },
    square: {
      width: "50%",
      height: "50%",
      borderRadius: "5px",
      marginLeft: "46px",
    },
    circle: {
      width: "50%",
      height: "50%",
      borderRadius: "100px",
      marginLeft: "46px",
    },
  };

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   */

  return {
    selectedTable,
    tableCount,
    isFormValid,
    validationError,
    selectTable,
    handleTableCountChange,
    tableStyles,
    submitTable,
    handleCancel,
    isSelected,
  };
};

export default useAddTable;
