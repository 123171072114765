// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createInventoryTransferApi from "./InventoryTransfer";
import { showToast } from "../../components/common/utils/showToast.util";
import store from "../../store/store";

/**
 * Manages state for inventory transfer.
 * Defines initial state for each inventory transfer action.
 */
const initialState = {
  getAllInventoryTransfers: {
    data: null,
    totalPages: 0,
    totalRecords: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleInventoryTransfer: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addInventoryTransfer: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateInventoryTransfer: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deliverInventoryTransfer: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteInventoryTransfer: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the inventory transfer process.
 * These action types are prefixed with the 'inventory-transfer' base path for better organization.
 */
const BASE = "inventory-transfer";

export const ActionTypes = {
  GET_ALL_INVENTORY_TRANSFERS: `${BASE}/get-all`, // Action type for get all inventory transfers
  ADD_INVENTORY_TRANSFER: `${BASE}`, // Action type for add inventory transfer
  UPDATE_INVENTORY_TRANSFER: `${BASE}/update`, // Action type for update inventory transfer
  DELETE_INVENTORY_TRANSFER: `${BASE}/delete`, // Action type for delete inventory transfer
  DELIVER_INVENTORY_TRANSFER: `${BASE}/deliver`, // Action type for delete inventory transfer
};

// Creating an instance of the inventory transfer service with a base URL 'inventory-transfer'
const inventoryTransferService =
  createInventoryTransferApi("inventory-transfer");

/**
 * Initiates the getAllInventoryTransfers process for inventory transfer.
 * @param {object} payload - Payload containing query parameters.
 * @param {number} payload.page - Current page.
 * @param {number} payload.pageSize - Number of items per page.
 * @param {string} payload.sortColumn - Column ID for sorting inventory transfers.
 * @param {string} payload.order - Order for sorting inventory transfers by ascending or descending.
 * @param {object} payload.condition - Additional conditions for filtering.
 * @param {object} payload.attributes - Additional attributes for customization.
 */
export const getAllInventoryTransfers = createAsyncThunk(
  ActionTypes.GET_ALL_INVENTORY_TRANSFERS,

  async ({ payload }, thunkAPI) => {
    const response = await inventoryTransferService.getAllInventoryTransfers(
      payload
    );
    if (response?.data?.Succeeded) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

/**
 * Initiates the getSingleInventoryTransfer process for inventory transfer.
 * @param {string} inventoryTransferId - ID of the inventory transfer.
 */
export const getSingleInventoryTransfer = createAsyncThunk(
  `${BASE}/getSingleInventoryTransfer`,

  async ({ inventoryTransferId }, thunkAPI) => {
    const response = await inventoryTransferService.getSingleInventoryTransfer(
      inventoryTransferId
    );
    return response?.data?.data;
  }
);

/**
 * Initiates the updateInventoryTransfer process for inventory transfer.
 * @param {string} inventoryTransferId - ID of the inventory transfer.
 * @param {object} payload - Payload containing updated data.
 */
export const updateInventoryTransfer = createAsyncThunk(
  ActionTypes.UPDATE_INVENTORY_TRANSFER,

  async ({ inventoryTransferId, payload }, thunkAPI) => {
    try {
      const response = await inventoryTransferService.updateInventoryTransfer(
        inventoryTransferId,
        payload
      );
      if (response?.data?.Succeeded) {
        showToast("Inventory transfer updated successfully!");
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);
/**
 * Initiates the deliverInventoryTransfer process for inventory transfer.
 * @param {string} inventoryTransferId - ID of the inventory transfer.
 */
export const deliverInventoryTransfer = createAsyncThunk(
  ActionTypes.DELIVER_INVENTORY_TRANSFER,

  async ({ inventoryTransferId, successCallBack }, thunkAPI) => {
    try {
      const response = await inventoryTransferService.deliverInventoryTransfer(
        inventoryTransferId
      );
      if (response?.data?.Succeeded) {
        successCallBack(response?.data?.data);
        showToast("Inventory transfer delivered successfully!");
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addInventoryTransfer process for inventory transfer.
 * @param {object} payload - Payload containing data for the new inventory transfer.
 */
export const addInventoryTransfer = createAsyncThunk(
  ActionTypes.ADD_INVENTORY_TRANSFER,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await inventoryTransferService.addInventoryTransfer(
        payload
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Inventory transfer added successfully!");
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deleteInventoryTransfer process for inventory transfer.
 * @param {string} inventoryTransferId - ID of the inventory transfer to be deleted.
 */
export const deleteInventoryTransfer = createAsyncThunk(
  ActionTypes.DELETE_INVENTORY_TRANSFER,

  async ({ inventoryTransferId, successCallBack }, thunkAPI) => {
    try {
      const response = await inventoryTransferService.deleteInventoryTransfer(
        inventoryTransferId
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast(
          "Inventory transfer deleted successfully!",
          "success",
          async () => {
            const undoResponse =
              await inventoryTransferService.undoInventoryTransfer(
                inventoryTransferId
              );
            if (undoResponse?.data?.Succeeded) {
              const payload = {
                page: 1,
                pageSize: 10,
                sortColumn: "id",
                order: {
                  id: "DESC",
                },
                condition: {},
                attributes: {},
              };
              store.dispatch(getAllInventoryTransfers({ payload }));
            }
          }
        );
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for inventory transfer related state management.
 */
export const inventoryTransferSlice = createSlice({
  name: "inventoryTransfer",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllInventoryTransfers action.
     */
    reset: (state) => {
      state.getAllInventoryTransfers = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleInventoryTransfer = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllInventoryTransfers action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllInventoryTransfers.pending, (state) => {
        state.getAllInventoryTransfers.isLoading = true;
        state.getAllInventoryTransfers.message = "";
        state.getAllInventoryTransfers.isError = false;
        state.getAllInventoryTransfers.isSuccess = false;
        state.getAllInventoryTransfers.data = null;
      })
      /**
       * Updates the state when getAllInventoryTransfers action is fulfilled/successful.
       * Updates loading and success flags and sets getAllInventoryTransfers data with the payload.
       */
      .addCase(getAllInventoryTransfers.fulfilled, (state, action) => {
        state.getAllInventoryTransfers.isLoading = false;
        state.getAllInventoryTransfers.isSuccess = true;
        state.getAllInventoryTransfers.data = action.payload.data;
        state.getAllInventoryTransfers.totalPages = action.payload.totalPages;
        state.getAllInventoryTransfers.totalRecords =
          action.payload.TotalRecords;
      })
      /**
       * Updates the state when getAllInventoryTransfers action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllInventoryTransfers.rejected, (state, action) => {
        state.getAllInventoryTransfers.message = action.payload?.message;
        state.getAllInventoryTransfers.isLoading = false;
        state.getAllInventoryTransfers.isError = true;
        state.getAllInventoryTransfers.data = null;
      })
      /**
       * Updates the state while the getSingleInventoryTransfer action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSingleInventoryTransfer.pending, (state) => {
        state.getSingleInventoryTransfer.isLoading = true;
        state.getSingleInventoryTransfer.message = "";
        state.getSingleInventoryTransfer.isError = false;
        state.getSingleInventoryTransfer.isSuccess = false;
        state.getSingleInventoryTransfer.data = null;
      })
      /**
       * Updates the state when getSingleInventoryTransfer action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleInventoryTransfer data with the payload.
       */
      .addCase(getSingleInventoryTransfer.fulfilled, (state, action) => {
        state.getSingleInventoryTransfer.isLoading = false;
        state.getSingleInventoryTransfer.isSuccess = true;
        state.getSingleInventoryTransfer.data = action.payload;
      })
      /**
       * Updates the state when getSingleInventoryTransfer action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleInventoryTransfer.rejected, (state, action) => {
        state.getSingleInventoryTransfer.message = action.payload?.message;
        state.getSingleInventoryTransfer.isLoading = false;
        state.getSingleInventoryTransfer.isError = true;
        state.getSingleInventoryTransfer.data = null;
      })
      /**
       * Updates the state while the addInventoryTransfer action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addInventoryTransfer.pending, (state) => {
        state.addInventoryTransfer.isLoading = true;
        state.addInventoryTransfer.message = "";
        state.addInventoryTransfer.isError = false;
        state.addInventoryTransfer.isSuccess = false;
        state.addInventoryTransfer.data = null;
      })
      /**
       * Updates the state when addInventoryTransfer action is fulfilled/successful.
       * Updates loading and success flags and sets addInventoryTransfer data with the payload.
       */
      .addCase(addInventoryTransfer.fulfilled, (state, action) => {
        state.addInventoryTransfer.isLoading = false;
        state.addInventoryTransfer.isSuccess = true;
        state.addInventoryTransfer.data = action.payload;
      })
      /**
       * Updates the state when addInventoryTransfer action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addInventoryTransfer.rejected, (state, action) => {
        state.addInventoryTransfer.message = action.payload?.message;
        state.addInventoryTransfer.isLoading = false;
        state.addInventoryTransfer.isError = true;
        state.addInventoryTransfer.data = null;
      })
      /**
       * Updates the state while the updateInventoryTransfer action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(updateInventoryTransfer.pending, (state) => {
        state.updateInventoryTransfer.isLoading = true;
        state.updateInventoryTransfer.message = "";
        state.updateInventoryTransfer.isError = false;
        state.updateInventoryTransfer.isSuccess = false;
        state.updateInventoryTransfer.data = null;
      })
      /**
       * Updates the state when updateInventoryTransfer action is fulfilled/successful.
       * Updates loading and success flags and sets updateInventoryTransfer data with the payload.
       */
      .addCase(updateInventoryTransfer.fulfilled, (state, action) => {
        state.updateInventoryTransfer.isLoading = false;
        state.updateInventoryTransfer.isSuccess = true;
        state.updateInventoryTransfer.data = action.payload;
      })
      /**
       * Updates the state when updateInventoryTransfer action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateInventoryTransfer.rejected, (state, action) => {
        state.updateInventoryTransfer.message = action.payload?.message;
        state.updateInventoryTransfer.isLoading = false;
        state.updateInventoryTransfer.isError = true;
        state.updateInventoryTransfer.data = null;
      })
      /**
       * Updates the state while the deliverInventoryTransfer action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(deliverInventoryTransfer.pending, (state) => {
        state.deliverInventoryTransfer.isLoading = true;
        state.deliverInventoryTransfer.message = "";
        state.deliverInventoryTransfer.isError = false;
        state.deliverInventoryTransfer.isSuccess = false;
        state.deliverInventoryTransfer.data = null;
      })
      /**
       * Updates the state when deliverInventoryTransfer action is fulfilled/successful.
       * Updates loading and success flags and sets deliverInventoryTransfer data with the payload.
       */
      .addCase(deliverInventoryTransfer.fulfilled, (state, action) => {
        state.deliverInventoryTransfer.isLoading = false;
        state.deliverInventoryTransfer.isSuccess = true;
        state.deliverInventoryTransfer.data = action.payload;
      })
      /**
       * Updates the state when deliverInventoryTransfer action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(deliverInventoryTransfer.rejected, (state, action) => {
        state.deliverInventoryTransfer.message = action.payload?.message;
        state.deliverInventoryTransfer.isLoading = false;
        state.deliverInventoryTransfer.isError = true;
        state.deliverInventoryTransfer.data = null;
      })
      /**
       * Updates the state while the deleteInventoryTransfer action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(deleteInventoryTransfer.pending, (state) => {
        state.deleteInventoryTransfer.isLoading = true;
        state.deleteInventoryTransfer.message = "";
        state.deleteInventoryTransfer.isError = false;
        state.deleteInventoryTransfer.isSuccess = false;
        state.deleteInventoryTransfer.data = null;
      })
      /**
       * Updates the state when deleteInventoryTransfer action is fulfilled/successful.
       * Updates loading and success flags and sets deleteInventoryTransfer data with the payload.
       */
      .addCase(deleteInventoryTransfer.fulfilled, (state, action) => {
        state.deleteInventoryTransfer.isLoading = false;
        state.deleteInventoryTransfer.isSuccess = true;
        state.deleteInventoryTransfer.data = action.payload;
      })
      /**
       * Updates the state when deleteInventoryTransfer action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(deleteInventoryTransfer.rejected, (state, action) => {
        state.deleteInventoryTransfer.message = action.payload?.message;
        state.deleteInventoryTransfer.isLoading = false;
        state.deleteInventoryTransfer.isError = true;
        state.deleteInventoryTransfer.data = null;
      });
  },
});

/**
 * Destructures the reset action from the inventoryTransferSlice actions.
 */
export const { reset } = inventoryTransferSlice.actions;

/**
 * Exports the default reducer generated by inventoryTransferSlice.
 */
export default inventoryTransferSlice.reducer;
