/**
 * Manages state for Point of Sale (POS) login.
 * Defines initial state for each POS-related action.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import posHomeService from "./PosHomeService";
import { showToast } from "../../components/common/utils/showToast.util";

/**
 * Initial state structure defining various properties related to POS login processes.
 * Each property represents a specific POS login action/status with its associated data, error, success, loading, and message.
 * Properties such as posCategories, storecode, and fastcode store data, error, success, loading, and message status for corresponding POS login actions.
 */

const initialState = {
  posCategories: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Initiates the POS login process.
 * @param {object} payload - Data containing POS login credentials.
 * @param {function} successCallBack - Callback function upon successful POS login.
 */
export const posCategories = createAsyncThunk(
  "posHome",
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posHomeService.posCategories(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast(response?.data?.message);
        return response;
      } else {
        showToast(response?.data?.message, "error");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for POS login-related state management.
 * - `name`: Name of the slice (posCategories)
 * - `initialState`: Initial state defining properties for various POS login actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (posCategories, storecode, fastcode, etc.).
 */
export const posHomeSlice = createSlice({
  name: "posHome",
  initialState,
  reducers: {
    /**
     * Resets the state for the posCategories action.
     */
    reset: (state) => {
      state.posCategories = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the posCategories action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(posCategories.pending, (state) => {
        state.posCategories.isLoading = true;
        state.posCategories.message = "";
        state.posCategories.isError = false;
        state.posCategories.isSuccess = false;
        state.posCategories.data = null;
      })
      /**
       * Updates the state when posCategories action is fulfilled/successful.
       * Updates loading and success flags and sets posCategories data with the payload.
       */
      .addCase(posCategories.fulfilled, (state, action) => {
        state.posCategories.isLoading = false;
        state.posCategories.isSuccess = true;
        state.posCategories.data = action.payload;
      })
      /**
       * Updates the state when posCategories action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(posCategories.rejected, (state, action) => {
        state.posCategories.message = action.payload?.message;
        state.posCategories.isLoading = false;
        state.posCategories.isError = true;
        state.posCategories.data = null;
      });
  },
});

/**
 * Destructures the reset action from the posCategoriesSlice actions.
 * - `reset`: Action function to reset the POS login-related state.
 */

// sample usage

// import { reset } from './yourposCategoriesSlicePath';

// Using the reset action
// const resetposCategories = () => {
//   // Dispatching the reset action to reset POS login state
//   store.dispatch(reset());
// };

export const { reset } = posHomeSlice.actions;

/**
 * Exports the default reducer generated by posCategoriesSlice.
 * This reducer handles state updates for POS login-related actions.
 */

// sample usage

// import posCategoriesReducer from "./yourposCategoriesSlicePath";

// Using the default reducer
// const initialState = {
// Your initial state for POS login
// };

// Creating a store with the posCategoriesReducer handling POS login-related actions
// const store = configureStore({
// reducer: {
// posCategories: posCategoriesReducer,
// other reducers...
// },
// preloadedState: {
// posCategories: initialState,
// other preloaded states...
// },
// });
export default posHomeSlice.reducer;
