import * as React from "react";

function ProfileIcon(props) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3755_47754)">
        <rect x="2" y="1" width="40" height="40" rx="20" fill="white" />
        <rect
          x="2.5"
          y="1.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#EAECF0"
        />
        <path
          d="M22 24.375C24.2782 24.375 26.125 22.5282 26.125 20.25C26.125 17.9718 24.2782 16.125 22 16.125C19.7218 16.125 17.875 17.9718 17.875 20.25C17.875 22.5282 19.7218 24.375 22 24.375Z"
          fill="#98A2B3"
        />
        <path
          d="M22 11.25C20.0716 11.25 18.1866 11.8218 16.5832 12.8932C14.9798 13.9645 13.7301 15.4873 12.9922 17.2688C12.2542 19.0504 12.0611 21.0108 12.4373 22.9021C12.8136 24.7934 13.7422 26.5307 15.1057 27.8943C16.4693 29.2579 18.2066 30.1865 20.0979 30.5627C21.9892 30.9389 23.9496 30.7458 25.7312 30.0078C27.5127 29.2699 29.0355 28.0202 30.1068 26.4168C31.1782 24.8134 31.75 22.9284 31.75 21C31.745 18.4157 30.7162 15.9386 28.8888 14.1112C27.0614 12.2838 24.5843 11.255 22 11.25ZM28.1688 26.475C27.5616 25.6041 26.7793 24.8697 25.8719 24.3187C24.83 25.3169 23.4429 25.8741 22 25.8741C20.5571 25.8741 19.17 25.3169 18.1281 24.3187C17.2207 24.8697 16.4384 25.6041 15.8313 26.475C14.7754 25.286 14.0857 23.8173 13.845 22.2455C13.6044 20.6737 13.8231 19.0659 14.4748 17.6155C15.1266 16.1651 16.1836 14.9339 17.5186 14.0702C18.8537 13.2065 20.4099 12.747 22 12.747C23.5901 12.747 25.1463 13.2065 26.4814 14.0702C27.8164 14.9339 28.8734 16.1651 29.5252 17.6155C30.1769 19.0659 30.3956 20.6737 30.155 22.2455C29.9143 23.8173 29.2246 25.286 28.1688 26.475Z"
          fill="#98A2B3"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3755_47754"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3755_47754"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3755_47754"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default ProfileIcon;
