import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createPaymentsApi from "./PaymentService";
import { showToast } from "../../components/common/utils/showToast.util";

// Initial state structure defining various properties related to payment method processes.
const initialState = {
  // State for getting payment methods
  getPaymentMethods: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getCardsList: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSinglePaymentMethod: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updatePaymentMethod: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deletePaymentMethod: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  // State for adding payment methods
  addPaymentMethods: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

// Base path for payment methods actions
const BASE = "payment-methods";

// Action types related to payment methods process
export const ActionTypes = {
  GET_PAYMENT_METHODS: `${BASE}/get`,
  GET_CARDS_LIST: `${BASE}/cards-list`,
  ADD_PAYMENT_METHODS: `${BASE}/add`,
  GET_SINGLE_PAYMENT_METHOD: `${BASE}/getSingle`,
  UPDATE_PAYMENT_METHOD: `${BASE}/id`,
  DELETE_PAYMENT_METHOD: `${BASE}/delete`,
};

// Creating an instance of the payments service with a base URL 'payment-methods'
const paymentsService = createPaymentsApi("payment-methods");

// Thunk action to get payment methods
export const getPaymentMethods = createAsyncThunk(
  ActionTypes.GET_PAYMENT_METHODS,

  async (thunkAPI) => {
    const response = await paymentsService.getPaymentMethods();
    return response?.data?.data;
  }
);

// Thunk action to get payment methods
export const getCardsList = createAsyncThunk(
  ActionTypes.GET_CARDS_LIST,

  async (thunkAPI) => {
    const response = await paymentsService.getCardsList();
    return response?.data?.data;
  }
);

// Thunk action to get payment methods
export const getSinglePaymentMethod = createAsyncThunk(
  ActionTypes.GET_SINGLE_PAYMENT_METHOD,

  async ({ id }, thunkAPI) => {
    const response = await paymentsService.getSinglePaymentMethod(id);
    return response?.data?.data;
  }
);

// Thunk action to get update methods
export const updatePaymentMethod = createAsyncThunk(
  ActionTypes.UPDATE_PAYMENT_METHOD,

  async ({ id, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await paymentsService.updatePaymentMethod(id, payload);

      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Payment method updated successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

// Thunk action to get payment methods
export const deletePaymentMethod = createAsyncThunk(
  ActionTypes.DELETE_PAYMENT_METHOD,

  async ({ id, successCallBack }, thunkAPI) => {
    try {
      const response = await paymentsService.deletePaymentMethod(id);

      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Payment method deleted successfully!", "success");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

// Thunk action to get payment methods
export const addPaymentMethods = createAsyncThunk(
  ActionTypes.ADD_PAYMENT_METHODS,

  async ({ payload, type, successCallBack }, thunkAPI) => {
    try {
      const response = await paymentsService.addPaymentMethods(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        if (type === "update") {
          showToast("Payment method updated successfully!");
        } else {
          showToast("Payment method added successfully!");
        }

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

// Slice for managing report related state
export const paymentsSlice = createSlice({
  name: "payments",
  initialState,

  // Reducers to modify the state
  reducers: {
    // Resetting state for payments actions
    reset: (state) => {
      state.getPaymentMethods = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getCardsList = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.addPaymentMethods = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSinglePaymentMethod = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.updatePaymentMethod = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },

  // Handling state updates based on asynchronous actions
  extraReducers: (builder) => {
    builder
      // Handling pending state for generating sales report
      .addCase(getSinglePaymentMethod.pending, (state) => {
        state.getSinglePaymentMethod.isLoading = true;
        state.getSinglePaymentMethod.message = "";
        state.getSinglePaymentMethod.isError = false;
        state.getSinglePaymentMethod.isSuccess = false;
        state.getSinglePaymentMethod.data = null;
      })
      // Handling successful state for generating sales report
      .addCase(getSinglePaymentMethod.fulfilled, (state, action) => {
        state.getSinglePaymentMethod.isLoading = false;
        state.getSinglePaymentMethod.isSuccess = true;
        state.getSinglePaymentMethod.data = action.payload;
      })
      // Handling failed state for generating sales report
      .addCase(getSinglePaymentMethod.rejected, (state, action) => {
        state.getSinglePaymentMethod.message = action?.payload?.message;
        state.getSinglePaymentMethod.isLoading = false;
        state.getSinglePaymentMethod.isError = true;
        state.getSinglePaymentMethod.data = null;
      })
      // Handling pending state for updating payment method
      .addCase(updatePaymentMethod.pending, (state) => {
        state.updatePaymentMethod.isLoading = true;
        state.updatePaymentMethod.message = "";
        state.updatePaymentMethod.isError = false;
        state.updatePaymentMethod.isSuccess = false;
        state.updatePaymentMethod.data = null;
      })
      // Handling successful state for updating payment method
      .addCase(updatePaymentMethod.fulfilled, (state, action) => {
        state.updatePaymentMethod.isLoading = false;
        state.updatePaymentMethod.isSuccess = true;
        state.updatePaymentMethod.data = action.payload;
      })
      // Handling failed state for updating payment method
      .addCase(updatePaymentMethod.rejected, (state, action) => {
        state.updatePaymentMethod.message = action?.payload?.message;
        state.updatePaymentMethod.isLoading = false;
        state.updatePaymentMethod.isError = true;
        state.updatePaymentMethod.data = null;
      })
      // Handling pending state for getting cards list
      .addCase(getCardsList.pending, (state) => {
        state.getCardsList.isLoading = true;
        state.getCardsList.message = "";
        state.getCardsList.isError = false;
        state.getCardsList.isSuccess = false;
        state.getCardsList.data = null;
      })
      // Handling successful state for getting cards list
      .addCase(getCardsList.fulfilled, (state, action) => {
        state.getCardsList.isLoading = false;
        state.getCardsList.isSuccess = true;
        state.getCardsList.data = action.payload;
      })
      // Handling failed state for getting cards list
      .addCase(getCardsList.rejected, (state, action) => {
        state.getCardsList.message = action?.payload?.message;
        state.getCardsList.isLoading = false;
        state.getCardsList.isError = true;
        state.getCardsList.data = null;
      })
      // Handling pending state for deleting payment method
      .addCase(deletePaymentMethod.pending, (state) => {
        state.deletePaymentMethod.isLoading = true;
        state.deletePaymentMethod.message = "";
        state.deletePaymentMethod.isError = false;
        state.deletePaymentMethod.isSuccess = false;
        state.deletePaymentMethod.data = null;
      })
      // Handling successful state for deleting payment method
      .addCase(deletePaymentMethod.fulfilled, (state, action) => {
        state.deletePaymentMethod.isLoading = false;
        state.deletePaymentMethod.isSuccess = true;
        state.deletePaymentMethod.data = action.payload;
      })
      // Handling failed state for deleting payment method
      .addCase(deletePaymentMethod.rejected, (state, action) => {
        state.deletePaymentMethod.message = action?.payload?.message;
        state.deletePaymentMethod.isLoading = false;
        state.deletePaymentMethod.isError = true;
        state.deletePaymentMethod.data = null;
      })
      // Handling pending state for getting payment methods
      .addCase(getPaymentMethods.pending, (state) => {
        state.getPaymentMethods.isLoading = true;
        state.getPaymentMethods.message = "";
        state.getPaymentMethods.isError = false;
        state.getPaymentMethods.isSuccess = false;
        state.getPaymentMethods.data = null;
      })
      // Handling successful state for getting payment methods
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.getPaymentMethods.isLoading = false;
        state.getPaymentMethods.isSuccess = true;
        state.getPaymentMethods.data = action.payload;
      })
      // Handling failed state for getting payment methods
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.getPaymentMethods.message = action?.payload?.message;
        state.getPaymentMethods.isLoading = false;
        state.getPaymentMethods.isError = true;
        state.getPaymentMethods.data = null;
      })
      // Handling pending state for adding payment methods
      .addCase(addPaymentMethods.pending, (state) => {
        state.addPaymentMethods.isLoading = true;
        state.addPaymentMethods.message = "";
        state.addPaymentMethods.isError = false;
        state.addPaymentMethods.isSuccess = false;
        state.addPaymentMethods.data = null;
      })
      // Handling successful state for adding payment methods
      .addCase(addPaymentMethods.fulfilled, (state, action) => {
        state.addPaymentMethods.isLoading = false;
        state.addPaymentMethods.isSuccess = true;
        state.addPaymentMethods.data = action.payload;
      })
      // Handling failed state for adding payment methods
      .addCase(addPaymentMethods.rejected, (state, action) => {
        state.addPaymentMethods.message = action?.payload?.message;
        state.addPaymentMethods.isLoading = false;
        state.addPaymentMethods.isError = true;
        state.addPaymentMethods.data = null;
      });
  },
});

// Destructuring the reset action from the paymentsSlice actions
export const { reset } = paymentsSlice.actions;

// Exporting the default reducer generated by paymentsSlice
export default paymentsSlice.reducer;
