import React from "react";
import PosLogins from "../../components/Pos/PosLogin/PosLoginCode/PosLogin";

/**
 * PosLogin component.
 * Renders the POS login component.
 *
 * @returns {JSX.Element} JSX code for rendering the PosLogin component.
 */

const PosLogin = () => {
  return <PosLogins />;
};

export default PosLogin;

// Sample Usage:
// import PosLogin from '.path/PosLogin';
// <PosLogin />
