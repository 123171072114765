import React, { useState } from "react";
import {
  LightBulb,
  MinusIcon,
  PlusIcon,
  Rocket,
  RocketLaunch,
} from "../../../common/Icons";
import { Check } from "../../../common/Icons/fontawesome";
import { CustomButton, Loader } from "../../../common/components";

function SelectPlanCards({
  selectedPlan,
  setSelectedPlan,
  activeTab,
  setActiveTab,
  branchCount,
  setBranchCount,
  pricingData,
  additionalBranchPrice,
  isLoading,
  user
}) {
  
  const toggleTab = (tab) => {
    setActiveTab(tab === "Pay Monthly" ? "MONTHLY" : "ANNUAL");
  };

  const increment = () => {
    setBranchCount(branchCount + 1);
  };

  const decrement = () => {
    setBranchCount(branchCount > 0 ? branchCount - 1 : 0);
  };

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  if (isLoading) {
    return <Loader />;
  }

  const isPlanDisabled = (planName) => {
    const { planType } = user?.subscription || {};
    if (user?.isSubscriptionPlanCompleted) {
      if (
        (planType === "Enterprise (MONTHLY)" || planType === "Enterprise (ANNUAL)") &&
        (planName === "Basic" || planName === "Professional")
      ) {
        return true;
      }
      if (
        (planType === "Professional (MONTHLY)" || planType === "Professional (ANNUAL)") &&
        planName === "Basic"
      ) {
        return true;
      }
      return false;
    }
   
  };

  const renderPlanCard = (plan) => {
    const isSelected = selectedPlan === plan.name;
    const isDisabled = isPlanDisabled(plan.name);
    const displayedPrice = plan.amount;
    const IconComponent = plan.name === "Basic" ? LightBulb : plan.name === "Professional" ? Rocket : RocketLaunch;

    return (
      <div
        key={plan.name}
        className={`relative flex flex-col border space-y-1 w-full justify-between p-3 rounded-lg ${
          isDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
        } ${isSelected ? "border-surface-primary bg-info-50" : "border-border-primary"}`}
        onClick={() => {
          if (!isDisabled) {
            selectPlan(plan.name);
          }
        }}
      >
        <div className="flex justify-between items-start">
          <IconComponent
            className={`h-5 w-5 ${isSelected ? "text-white" : "text-blue-500"}`}
          />
          {isSelected && <Check color="text-link" />}
        </div>
        <div className="space-y-1">
          <h4 className="text-text-primary font-semibold text-base">{plan.name} Plan</h4>
          <div className="text-xs">{displayedPrice} SAR</div>
          <span className="text-xs">Default Branches: {plan.defaultBranch}</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex mb-2 gap-3">
        <CustomButton
          text="Pay Monthly"
          bgColor={activeTab === "MONTHLY" ? "bg-info-50" : "bg-neutral-50"}
          textColor={activeTab === "MONTHLY" ? "text-text-link" : "black-pearl"}
          className="font-normal"
          width="w-fit"
          onClick={() => toggleTab("Pay Monthly")}
        />
        <CustomButton
          text="Pay Annually"
          bgColor={activeTab === "ANNUAL" ? "bg-info-50" : "bg-neutral-50"}
          textColor={activeTab === "ANNUAL" ? "text-text-link" : "black-pearl"}
          width="w-fit"
          className="font-normal"
          onClick={() => toggleTab("Pay Annually")}
        />
      </div>
      <div className="bg-white gap-4 w-full flex justify-between items-start mt-4">
        {pricingData?.map(renderPlanCard)}
      </div>
      <hr className="border-t-2 border-gray-100 px-2 my-4" />
      <div className="border border-border-primary p-3 rounded-lg flex items-center justify-between">
        <div>
          <h6 className="text-text-primary font-semibold text-sm">
            Additional New Branch
          </h6>
          <span className="text-text-secondary text-base">
            {additionalBranchPrice || 0} SAR
          </span>
        </div>
        <div className="flex items-center gap-3">
          <CustomButton
            icon={<MinusIcon />}
            border="border"
            width="w-fit"
            padding="p-1"
            onClick={decrement}
            disabled={selectedPlan === "Basic"}
          />
          <p className="font-medium">{branchCount}</p>
          <CustomButton
            icon={<PlusIcon />}
            border="border"
            width="w-fit"
            padding="p-1"
            onClick={increment}
            disabled={selectedPlan === "Basic"}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectPlanCards;
