import React from "react";
import ClientProfile from "./ClientsProfile/ClientProfile";
import SalesByLocation from "./EmployessDetails/SalesByLocation/SalesByLocation";
import EmployessPerformance from "./EmployessDetails/EmployessPerformance/EmployessPerformance";
import ClientSales from "./ClientsSale/ClientsSale";
import BillingHistory from "./BillingHistory/BillingHistory";
import { ROLE } from "../../../../enums/role";
import { useUserContext } from "../../../../context/UserContext";
const AdminOverView = () => {
  const { user } = useUserContext();

  return (
    <div className="px-2">
      <ClientProfile />
      {user?.role !== ROLE.ACCOUNT_MANAGER && (
        <div className="border rounded-lg px-3 mt-2 w-full">
          <ClientSales />
        </div>
      )}
      {user?.role !== ROLE.ACCOUNT_MANAGER && (
        <div className="flex flex-row gap-4 mt-2 w-full  ">
          <div className="w-1/2 border rounded-lg px-2  overflow-auto sticky top-0 z-99999">
            <EmployessPerformance />
          </div>
          <div className="w-1/2  overflow-auto border rounded-lg px-2">
            <SalesByLocation />
          </div>
        </div>
      )}
      {user?.role !== ROLE.ACCOUNT_MANAGER && <BillingHistory />}
    </div>
  );
};

export default AdminOverView;
