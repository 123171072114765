import * as React from "react";

function ToggleOff(props) {
  return (
    <svg width="42" height="24" viewBox="0 0 42 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="42" height="24" rx="12" fill="#D0D5DD"/>
    <circle cx="12" cy="12" r="10" fill="white"/>
    </svg>
    
  );
}

export default ToggleOff;
