import * as React from "react";

function CaretRight(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="CaretRight">
        <path
          id="Vector"
          d="M6 3L11 8L6 13"
          stroke={props.color || "#475467"} 
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}


export default CaretRight;
