/**
 * Sets a value in localStorage.
 *
 * @param {string} key - The key for the localStorage item.
 * @param {string} value - The value to be stored in localStorage.
 */
export const setUser = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error("Error saving to localStorage:", error);
  }
};
/**
 * Gets a value from localStorage.
 *
 * @param {string} key - The key for the localStorage item.
 * @returns {string | null} The value retrieved from localStorage or null if an error occurs.
 */
export const getUser = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.error("Error retrieving from localStorage:", error);
    return null;
  }
};

// export const getUser = async () => {
//   const token = getAccessToken();
//   if (!token) {
//     return null;
//   }
//   const response = await apiService().get('/auth/me', {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
//   return response.data;
// };

/**
 * Removes a value from localStorage.
 *
 * @param {string} key - The key for the localStorage item to be removed.
 */
export const removeUser = (key) => {
  try {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  } catch (error) {
    console.error("Error removing from localStorage:", error);
  }
};
