import { useTranslation } from "react-i18next";

/**
 * Footer component for displaying footer content.
 * @param {Object} props - Component properties.
 * @param {string} props.userType - Type of user (e.g., 'client').
 * @param {boolean} props.login - Flag indicating if the user is logged in.
 * @returns {JSX.Element} Footer JSX element.
 */
const Footer = ({ userType, login }) => {
  const { t } = useTranslation();
  return (
    <footer
      className={`flex items-center ${
        !login ? "justify-center" : "justify-between"
      } py-4 px-6`}
    >
      <span className="text-sm text-text-primary font-normal">
        {t("footer.copyright")} © 2023 TagDev.
      </span>
    </footer>
  );
};

export default Footer;

// Sample Usage:
// import React from 'react';
// import Footer from './Footer';
//
// Render the Footer component with the required props
// <Footer userType="client" login={true} />
