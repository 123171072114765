import * as React from "react";

function CardIcon(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M17.5 4.25H2.5C2.16848 4.25 1.85054 4.3817 1.61612 4.61612C1.3817 4.85054 1.25 5.16848 1.25 5.5V15.5C1.25 15.8315 1.3817 16.1495 1.61612 16.3839C1.85054 16.6183 2.16848 16.75 2.5 16.75H17.5C17.8315 16.75 18.1495 16.6183 18.3839 16.3839C18.6183 16.1495 18.75 15.8315 18.75 15.5V5.5C18.75 5.16848 18.6183 4.85054 18.3839 4.61612C18.1495 4.3817 17.8315 4.25 17.5 4.25ZM17.5 5.5V7.375H2.5V5.5H17.5ZM17.5 15.5H2.5V8.625H17.5V15.5ZM16.25 13.625C16.25 13.7908 16.1842 13.9497 16.0669 14.0669C15.9497 14.1842 15.7908 14.25 15.625 14.25H13.125C12.9592 14.25 12.8003 14.1842 12.6831 14.0669C12.5658 13.9497 12.5 13.7908 12.5 13.625C12.5 13.4592 12.5658 13.3003 12.6831 13.1831C12.8003 13.0658 12.9592 13 13.125 13H15.625C15.7908 13 15.9497 13.0658 16.0669 13.1831C16.1842 13.3003 16.25 13.4592 16.25 13.625ZM11.25 13.625C11.25 13.7908 11.1842 13.9497 11.0669 14.0669C10.9497 14.1842 10.7908 14.25 10.625 14.25H9.375C9.20924 14.25 9.05027 14.1842 8.93306 14.0669C8.81585 13.9497 8.75 13.7908 8.75 13.625C8.75 13.4592 8.81585 13.3003 8.93306 13.1831C9.05027 13.0658 9.20924 13 9.375 13H10.625C10.7908 13 10.9497 13.0658 11.0669 13.1831C11.1842 13.3003 11.25 13.4592 11.25 13.625Z"
        fill={props?.stroke ? "#0086FF" : "#667085"}
      />
    </svg>
  );
}

export default CardIcon;
