import React, { useState } from "react";
import { DateRange } from "react-date-range";
import Card from "../Card/Card";
import { ArrowDown } from "../../Icons";
import { formatDateTime } from "../../../../helpers/dateFormatter";

const CustomDatePicker = ({
  initialStartDate,
  initialEndDate,
  onChange,
  skipYear = false,
  position = "end-0",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: initialStartDate || new Date(),
      endDate: initialEndDate || new Date(),
      key: "selection",
    },
  ]);

  const handleDateChange = (ranges) => {
    setState([ranges.selection]);
    if (onChange) {
      onChange(ranges.selection);
    }
  };

  const toggleDatePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleCardClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      className="relative border rounded-lg p-2 to-text-primary font-semibold cursor-pointer"
      onClick={toggleDatePicker}
    >
      <div className="flex items-center justify-between gap-3">
        {skipYear ? (
          <span className="text-sm">
            {formatDateTime(state[0]?.startDate, "mmm dd")} -{" "}
            {formatDateTime(state[0]?.endDate, "mmm dd")}
          </span>
        ) : (
          <span className="text-sm">
            {formatDateTime(state[0]?.startDate, "mmm dd, yyyy")} -{" "}
            {formatDateTime(state[0]?.endDate, "mmm dd, yyyy")}
          </span>
        )}{" "}
        <ArrowDown />
      </div>
      {isOpen && (
        <div onClick={handleCardClick}>
          <Card className={`absolute top-12 ${position}`}>
            <DateRange
              editableDateInputs={true}
              onChange={handleDateChange}
              moveRangeOnFirstSelection={false}
              ranges={state}
              retainEndDateOnFirstSelection={true}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
