import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { signUp } from "../../features/Auth/AuthSlice";
import { useDispatch } from "react-redux";
import { setUser } from "../common/utils/users.utils";

/**
 * Custom hook for handling login form functionality using react-hook-form.
 * Provides methods for registering form inputs, handling form submission, and accessing form errors.
 *
 * @returns {Object} An object containing:
 *   - register: Function to register input fields into the react-hook-form.
 *   - handleSubmit: Function that wraps the form submit handler.
 *   - errors: State object containing form validation errors.
 */
const useSignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // valiation schema for login form validation

  const signupFormSchema = z.object({
    email: z
      .string()
      .min(1, "login.validations.email_error")
      .email("login.validations.email_error_invalid"),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} zodResolver - Function that utilizes the 'zod' library for schema-based form validation.
   */
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(signupFormSchema),
  });

  /**
   * Handler function for form submission.
   * This function can be async if needed for API calls or other asynchronous operations.
   *
   * @param {Object} data - Form data provided by react-hook-form upon successful validation.
   */
  const onSubmit = async (data) => {
    const payload = {
      email: data.email,
      role: "CLIENT",
    };
    return dispatch(signUp({ payload, successCallBack: signUpRouter }));
  };

  /**
   * Navigates to confirm email route upon successful signup.
   *
   * @param {Object} data - Response data from the signup process.
   */
  const signUpRouter = (data) => {
    if (data?.data?.Succeeded) {
      const userData = data.data.data;
      setUser(
        "user",
        JSON.stringify({ user: { email: userData?.user?.email } })
      );
      navigate("/confirm-email");
    }
  };

  // Returns form handling functions and login with OAuth method.
  /**
   * @returns {Object}
   * {
   *   register: Function provided by react-hook-form to register input fields,
   *   handleSubmit: Function provided by react-hook-form to handle form submission,
   *   errors: object Function provided by react-hook-form to handle validation errors,
   *   loginWithOAuth: Function to handle login with OAuth method
   * }
   * Usage Example:
   * Accesses the form handling functions, error tracking, and OAuth login method:
   * const { register, handleSubmit, errors, loginWithOAuth } = useCustomHook();
   *
   * regiser:
   *  Example usage: <input {...register("email", { required: true })}
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * errors:
   *  Example usage: {errors.email && <span>Email is required</span>}
   * loginWithOAuth:
   *  Example usage: loginWithOAuth("google", email, accessToken);
   */
  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    isValid,
    isSubmitting,
  };
};

export default useSignup;
