import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CustomButton, InputGroup } from "../../../../common/components";
import { Eye, EyeSlash } from "../../../../common/Icons/fontawesome";
import { CheckCircle, GreenCheckCircle } from "../../../../common/Icons";
import { setUser } from "../../../../common/utils/users.utils";
import { createStaffPassword } from "../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";

const passwordSchema = z
  .object({
    email: z
      .string()
      .min(1, "Email is required")
      .email("Invalid email address"),
    newPassword: z
      .string()
      .min(8, "Password must be at least 8 characters")
      .regex(/^(?=.*[A-Z])/, "Password must contain an uppercase letter")
      .regex(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain a special character"
      )
      .regex(/\d/, "Password must contain a number"),
    confirmPassword: z.string().min(1, "Confirm Password is required"),
  })
  .refine(
    (data) =>
      data.confirmPassword.length === 0 ||
      data.newPassword === data.confirmPassword,
    {
      message: "Passwords don't match!",
      path: ["confirmPassword"],
    }
  );

const SetStaffPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const [passwordFieldsVisibility, setPasswordFieldsVisibility] = useState({
    password: false,
    confirmPassword: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(passwordSchema),
    defaultValues: { email },
  });

  const onSubmit = async (data) => {
    const payload = {
      password: data.newPassword,
      confirmPassword: data.confirmPassword,
    };
    await dispatch(
      createStaffPassword({
        email,
        token,
        payload,
        successCallBack: passwordRouter,
      })
    );
  };

  const passwordRouter = (data) => {
    if (data?.data?.Succeeded) {
      const userData = data.data.data;
      setUser("user", JSON.stringify(userData));
      navigate("/admin/login");
    }
  };

  const togglePasswordVisibility = (fieldName) => {
    setPasswordFieldsVisibility((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  return (
    <div className="max-w-[480px] mx-auto py-12">
      <h2 className="text-3xl text-center font-semibold mb-3 text-text-primary mt-3">
        Create Password
      </h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mt-10">
        <InputGroup
          htmlfor="email"
          labelText="Email"
          inputName="email"
          placeholder="Enter your email"
          inputType="email"
          register={register}
          errors={errors}
          value={email}
          readOnly={true}
        />
        <InputGroup
          htmlfor="newPassword"
          labelText="New Password"
          inputName="newPassword"
          placeholder="Enter new password"
          inputType={passwordFieldsVisibility.password ? "text" : "password"}
          register={register}
          errors={errors}
        >
          <span
            className="absolute end-3 top-6 items-center cursor-pointer"
            onClick={() => togglePasswordVisibility("password")}
          >
            {passwordFieldsVisibility.password ? (
              <Eye color="gray-700" />
            ) : (
              <EyeSlash color="gray-700" />
            )}
          </span>
        </InputGroup>
        <InputGroup
          htmlfor="confirmPassword"
          labelText="Confirm Password"
          inputName="confirmPassword"
          placeholder="Enter password again"
          inputType={
            passwordFieldsVisibility.confirmPassword ? "text" : "password"
          }
          register={register}
          errors={errors}
        >
          <span
            className="absolute end-3 top-6 items-center cursor-pointer"
            onClick={() => togglePasswordVisibility("confirmPassword")}
          >
            {passwordFieldsVisibility.confirmPassword ? (
              <Eye color="gray-700" />
            ) : (
              <EyeSlash color="gray-700" />
            )}
          </span>
        </InputGroup>
        <ul className="list-none space-y-3 !mt-4">
          <li className="flex items-center gap-2">
            {getValues("newPassword")?.length >= 8 ? (
              <GreenCheckCircle />
            ) : (
              <CheckCircle className="fill-custom-gray" />
            )}{" "}
            <span className="text-sm text-text-secondary font-normal">
              Use at least 8 characters
            </span>
          </li>
          <li className="flex items-center gap-2">
            {/[!@#$%^&*(),.?":{}|<>]/.test(getValues("newPassword")) ? (
              <GreenCheckCircle />
            ) : (
              <CheckCircle className="fill-custom-gray" />
            )}{" "}
            <span className="text-sm text-text-secondary font-normal">
              Use a mix of letters, numbers, and special characters
            </span>
          </li>
        </ul>
        <CustomButton
          text="Create Password"
          type="submit"
          className="!mt-8"
          isLoading={isSubmitting}
          disabled={!isValid}
        />
      </form>
    </div>
  );
};

export default SetStaffPassword;
