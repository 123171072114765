import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createSubscription,
  getAllSubscriptions,
} from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
import { useEffect, useState } from "react";
import { getAllStaffUsers } from "../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import {
  calculatePricingSummary,
  getPricing,
} from "../../../../../features/ClientSubscriptions/ClientSubscriptionsSlice";

export const useAddNewSubscription = ({ closeModal }) => {
  const dispatch = useDispatch();
  const businessInfoSchema = z.object({
    companyname: z
      .string()
      .min(3, {
        message:
          "Company name is required and should be at least 3 characters!",
      })
      .max(100, { message: "Company name should not exceed 100 characters!" }),
    companyaddress: z
      .string()
      .min(3, {
        message:
          "Company address is required and should be at least 3 characters!",
      })
      .max(100, {
        message: "Company address should not exceed 100 characters!",
      }),
    fullname: z
      .string()
      .min(3, {
        message: "Full name is required and should be at least 3 characters!",
      })
      .max(100, { message: "Full name should not exceed 100 characters!" }),
    arabicname: z
      .string()

      .max(100, { message: "Arabic name should not exceed 100 characters!" })
      .optional(),
    website: z
      .string()
      .max(100, { message: "Website should not exceed 100 characters!" })
      .optional(),
    commercialregistration: z
      .string()
      .min(3, {
        message:
          "Commercial registration is required and should be at least 3 characters!",
      })
      .max(100, {
        message: "Commercial registration should not exceed 100 characters!",
      }),
    vat: z
      .string()
      .length(15, { message: "VAT Number must be 15 digits long." }),

    phonenumber: z
      .string()
      .min(3, { message: "Phone Number must be at least 3 digits long!" })
      .max(20, { message: "Phone Number should not exceed 20 digits!" })
      .regex(/^\+\d+$/, {
        message:
          "Phone Number must start with + and be followed by numeric digits!",
      }),
    password: z
      .string()
      .min(8, { message: "Password must be at least 8 characters long!" })
      .regex(/^(?=.*[A-Z])/, {
        message: "Password must contain an uppercase letter!",
      })
      .regex(/[!@#$%^&*(),.?":{}|<>]/, {
        message: "Password must contain a special character!",
      })
      .regex(/\d/, { message: "Password must contain a number!" }),
    startDate: z.string().min(1, { message: "Start date is required!" }),
    email: z
      .string()
      .email({ message: "Invalid email format!" })
      .max(100, { message: "Email should not exceed 100 characters!" }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      startDate: new Date().toISOString().substring(0, 10),
    },
    resolver: zodResolver(businessInfoSchema),
  });

  const isLoading = useSelector(
    (state) => state.clientSubscriptions.getPricing.isLoading
  );
  const pricingData = useSelector(
    (state) => state.clientSubscriptions.getPricing.data
  );
  const { data: calculatedPrice, isLoading: priceSummaryLoading } = useSelector(
    (state) => state.clientSubscriptions.calculatePricingSummary
  );

  const [selectedPlan, setSelectedPlan] = useState("Basic");
  const [activeTab, setActiveTab] = useState("MONTHLY");
  const [branchCount, setBranchCount] = useState(0);
  const [additionalBranchPrice, setAdditionalBranchPrice] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const matchedPlan = pricingData?.find(
    (plan) => plan.plan_tenure === activeTab && plan.name === selectedPlan
  );
  // Extract the amount from the matched plan
  const planId = matchedPlan ? matchedPlan.planId : null;
  const totalPriceBeforeTax = calculatedPrice?.totalBeforeTax;
  const tax = calculatedPrice?.taxAmount;
  const totalPrice = calculatedPrice?.subTotal;
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const role = storedUserData?.user?.role;
  const staffId = storedUserData?.user?.id;

  console.log("staffId :", staffId);

  useEffect(() => {
    setAdditionalBranchPrice(0);
    setBranchCount(0);
  }, [selectedPlan]);

  useEffect(() => {
    dispatch(getPricing({ type: activeTab }));
  }, [dispatch, activeTab]);

  useEffect(() => {
    setAdditionalBranchPrice(
      activeTab === "MONTHLY" ? 71 * branchCount : 850 * branchCount
    );
  }, [branchCount, activeTab]);

  const calculatePricing = () => {
    const payload = {
      planId: planId,
      plan_tenure: activeTab,
      totalBranches: branchCount,
    };

    dispatch(
      calculatePricingSummary({
        payload: payload,
        successCallBack: onSuccessCalculatePricing,
      })
    );
  };

  useEffect(() => {
    if (planId) {
      calculatePricing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan, activeTab, planId, branchCount]);

  const onSuccessCalculatePricing = () => {};
  //kaam mera end
  useEffect(() => {
    dispatch(getPricing({ type: activeTab }));
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (pricingData && selectedPlan) {
      const plan = pricingData.find((p) => p.name === selectedPlan);
      if (plan) {
        setAdditionalBranchPrice(plan.additionalBranchPrice);
      }
    }
  }, [pricingData, selectedPlan]);

  const [selectedAccountManager, setSelectedAccountManager] = useState("");

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStaffUsers({ payload }));
  }, []);
  const { data: staffUser } = useSelector(
    (state) => state.dashboard.getAllStaffUsers
  );

  const staffUserOptions = [
    { value: "", label: "Select Account Manager" },
    ...(staffUser?.data?.data
      ?.filter((user) => user.role === "ACCOUNT_MANAGER")
      .map((user) => ({
        value: user.id,
        label: user.fullName,
      })) || []),
  ];

  const handleSelectChange = (event) => {
    setSelectedAccountManager(event.target.value);
  };

  const onSubmit = (data) => {
    const subscriptionData = {
      englishName: data.fullname,
      arabicName: data.arabicname,
      phoneNumber: data.phonenumber,
      email: data.email,
      password: data.password,
      companyName: data.companyname,
      companyAddress: data.companyaddress,
      website: data.website,
      commercialRegistration: data.commercialregistration,
      vatNumber: parseInt(data.vat, 10),
      planId: planId,
      plan_tenure: activeTab,
      startDate: data.startDate,
      branches: branchCount,
      managedBy: role === "ACCOUNT_MANAGER" ? staffId : selectedAccountManager,
      totalBeforeTax: totalPriceBeforeTax,
      // totalDiscount: 12,
      vatAmount: tax,
      subTotal: totalPrice,
    };
    setIsButtonDisabled(true);
    dispatch(
      createSubscription({
        payload: subscriptionData,
        successCallBack: handleSuccess,
      })
    );
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };
  const handleSuccess = () => {
    closeModal();
    fetchAllSubscriptions();
  };

  const fetchAllSubscriptions = () => {
    dispatch(
      getAllSubscriptions({
        payload: {
          page: 1,
          pageSize: 10,
          sortColumn: "id",
          order: { id: "DESC" },
          condition: {},
          attributes: {},
          periodId: 0,
        },
      })
    );
  };
  return {
    onSubmit,
    handleSubmit,
    register,
    errors,
    isSubmitting,
    staffUserOptions,
    selectedPlan,
    setSelectedPlan,
    activeTab,
    setActiveTab,
    branchCount,
    setBranchCount,
    totalPrice,
    tax,
    totalPriceBeforeTax,
    additionalBranchPrice,
    isLoading,
    pricingData,
    isButtonDisabled,
    role,
    handleSelectChange,
    selectedAccountManager,
  };
};

export default useAddNewSubscription;
