import * as React from "react";

function RedArrowDown(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 5.5L5.5 16.5"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4375 16.5H5.5V7.5625"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RedArrowDown;
