import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getSingleClientProfile } from "../../../../../../features/Clients/clientSlice";
import { DeleteIcon, PencilIcon, Printer } from "../../../../../common/Icons";
import {
  changeStatus,
  deleteSubscription,
  getSingleSubscriptions,
} from "../../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";

export const useUpdateProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [clientDetails, setClientDetails] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState({
    isToggle: false,
    type: "",
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const role = storedUserData?.user?.role;
  const status = clientDetails?.subscription?.subscriptionStatus;
  const clientStatus = clientDetails?.blocked;
  const openModal = (item, modalType) => {
    setSelectedItem(item);
    setActiveModal(modalType);
  };

  const dropdownRef = useRef(null);
  const printRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible({ isToggle: false, type: "" });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeModal = () => {
    setActiveModal(null);
    setSelectedItem(null);
  };

  const toggleDropdown = (type) => {
    setDropdownVisible((prev) => ({ isToggle: !prev.isToggle, type: type }));
  };

  const handleEdit = (user) => {
    setModalOpen(true);
    setSelectedItem(user);
  };
  const handlePrint = () => {
    const input = document.getElementById("printable-content");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210;
      const pageHeight = 205;
      const padding = 10;
      const borderPadding = 5;
      const borderThickness = 0.5;
      const borderRadius = 10;
      const contentWidth = imgWidth - padding * 2 - borderPadding * 2;
      const imgHeight = (canvas.height * contentWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = padding + borderPadding;

      pdf.addImage(imgData, "PNG", position, position, contentWidth, imgHeight);
      pdf.setLineWidth(borderThickness);
      pdf.roundedRect(
        padding,
        padding,
        imgWidth - padding * 2,
        pageHeight - padding * 2,
        borderRadius,
        borderRadius
      );
      heightLeft -= pageHeight - padding - borderPadding;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + padding * 2 + borderPadding * 2;
        pdf.addPage();
        pdf.addImage(
          imgData,
          "PNG",
          position,
          position,
          contentWidth,
          imgHeight
        );
        pdf.roundedRect(
          padding,
          padding,
          imgWidth - padding * 2,
          pageHeight - padding * 2,
          borderRadius,
          borderRadius
        );
        heightLeft -= pageHeight;
      }
      pdf.save("download.pdf");
    });
  };

  const handleDelete = (user) => {
    setDeleteModalOpen(true);
    setSelectedItem(user);
  };

  // Define all dropdown options
  const dropdownOptions = [
    { label: "Edit", action: handleEdit, icon: <PencilIcon /> },
    { label: "Print", action: handlePrint, icon: <Printer /> },
    { label: "Status", action: handleDelete, icon: <DeleteIcon /> },
  ];

  if (role === "ACCOUNTING") {
    const index = dropdownOptions.findIndex(
      (option) => option.label === "Delete"
    );
    if (index > -1) {
      dropdownOptions.splice(index, 1);
    }
  }

  const subscriptions = clientDetails?.subscriptions ?? [];
  const subscriptionDetailId = clientDetails?.subscription?.id;
  const lastSubscription = subscriptions[subscriptions.length - 1] || {};

  let subscribtiondropdownOptions = [
    {
      label: "Change Subscription",
      value: "change",
      textColor: "text-text-primary",
      action: () => openModal(lastSubscription, "ChangeSubscribtion"),
    },
    {
      label: "Add Branches",
      value: "add",
      textColor: "text-text-primary",
      action: () => openModal(lastSubscription, "AddBranches"),
    },
    {
      label: "Change Status",
      value: "change",
      textColor: "text-text-primary",
      action: () => changeSubscriptionStatus(lastSubscription),
    },
    {
      label: "Extend Subscription",
      value: "extend",
      textColor: "text-text-primary",
      action: () => openModal(lastSubscription, "ExtendSubscribtion"),
    },
    {
      label: "Cancel Subscription",
      value: "cancel",
      textColor: "text-danger",
      action: () => openModal(lastSubscription, "CancelSubscribtion"),
    },
  ];

  // Filter options based on status
  if (status === "inactive") {
    subscribtiondropdownOptions = [
      {
        label: "Change Status",
        value: "change",
        textColor: "text-text-primary",
        action: () => changeSubscriptionStatus(lastSubscription),
      },
    ];
  } else if (status === "expired") {
    subscribtiondropdownOptions = subscribtiondropdownOptions.filter(
      (option) => option.label !== "Add Branches"
    );
  } else if (status === "inactive") {
    subscribtiondropdownOptions = [
      {
        label: "Change Status",
        value: "change",
        textColor: "text-text-primary",
        action: () => changeSubscriptionStatus(lastSubscription),
      },
      {
        label: "Cancel Subscription",
        value: "cancel",
        textColor: "text-danger",
        action: () => openModal(lastSubscription, "CancelSubscribtion"),
      },
    ];
  } else if (status === "cancelled") {
    subscribtiondropdownOptions = [
      {
        label: "Reactivate Subscription",
        value: "change",
        textColor: "text-text-primary",
        action: () => reactivateSubscriptionStatus(lastSubscription),
      },
    ];
  }

  // Remove "Cancel Subscription" option for ACCOUNTING role
  if (role === "ACCOUNTING") {
    subscribtiondropdownOptions = subscribtiondropdownOptions.filter(
      (option) => option.label !== "Cancel Subscription"
    );
  }

  useEffect(() => {
    dispatch(
      getSingleClientProfile({
        profileId: id,
        successCallBack: onGetSingleClientProfileSuccess,
      })
    );
  }, [id, dispatch]);

  const onGetSingleClientProfileSuccess = (response) => {
    setClientDetails(response);
  };

  const getSingleClientDetail = () => {
    dispatch(
      getSingleClientProfile({
        profileId: id,
        successCallBack: onGetSingleClientProfileSuccess,
      })
    );
  };

  const { data: singlesale, isLoading: singleSaleLoading } = useSelector(
    (state) => state.adminsales.getSingleClientSale
  );

  const changeSubscriptionStatus = () => {
    dispatch(changeStatus({ subscriptionId: subscriptionDetailId })).then(
      () => {
        getSingleClientDetail();
      }
    );
  };

  // const reactivateSubscriptionStatus = (item) => {
  //   dispatch(
  //     deleteSubscription({ subscriptionId: item?.id, successCallBack: handleSuccess })
  //   );
  // };
  const reactivateSubscriptionStatus = () => {
    dispatch(deleteSubscription({ subscriptionId: subscriptionDetailId })).then(
      () => {
        getSingleClientDetail();
      }
    );
  };
  useEffect(() => {
    if (subscriptionDetailId)
      dispatch(
        getSingleSubscriptions({ subscriptionDetailId, isFreeTrial: false })
      );
  }, [dispatch, subscriptionDetailId]);
  return {
    clientDetails,
    dropdownOptions,
    toggleDropdown,
    isDeleteModalOpen,
    isModalOpen,
    dropdownVisible,
    setDeleteModalOpen,
    dropdownRef,
    setModalOpen,
    subscribtiondropdownOptions,
    activeModal,
    closeModal,
    singleSaleLoading,
    singlesale,
    selectedItem,
    id,
    storedUserData,
    subscriptionDetailId,
    getSingleClientDetail,
    printRef,
    role,
    status,
    clientStatus,
  };
};

export default useUpdateProfile;
