import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../components/LandingPage/Footer";
import Navbar from "../components/LandingPage/Navbar";

import {
  ArrowDown,
  CheckIcon,
  EnvelopSm,
  LocationIcon,
  PhoneIcon,
} from "../components/common/Icons";
import {
  Card,
  CustomButton,
  InputGroup,
} from "../components/common/components";
import { classNames } from "../helpers/generateClassNames";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="w-full faqpagehero 1xl:px-29 px-4 relative">
        <Navbar />
        <div className="text-center xs:max-w-2xl mx-auto space-y-5">
          <h1 className="font-bold text-5xl md:leading-wider leading-tight lg:pt-44 pt-36">
          {t("landingpage.buttons.contactus")}{" "}

          </h1>
          <p className="mt-2 md:text-base text-sm text-gray-600 font-medium">
          {t("landingpage.buttons.contacttext")}{" "}

          </p>
        </div>
        <div className="md:w-[37rem] mx-auto mt-16">
          {/* contact info */}
          <Card padding="p-4 space-y-4">
            <h2 className="text-2xl font-semibold text-text-primary leading-none">
            {t("landingpage.buttons.information")}{" "}
            </h2>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
              <span className="py-2 md:justify-center justify-start px-2 bg-surface-gray rounded-lg border border-border-primary text-text-primary font-medium text-sm flex items-center gap-2">
                <PhoneIcon />
                <span>+966544335595</span>
              </span>
              <span className="py-2 md:justify-center justify-start px-2 bg-surface-gray rounded-lg border border-border-primary text-text-primary font-medium text-sm flex items-center gap-2">
                <EnvelopSm stroke className="w-5 h-5" />
                <span>hello@tagdev.sa</span>
              </span>
              <span className="py-2 md:justify-center justify-start px-2 bg-surface-gray rounded-lg border border-border-primary text-text-primary font-medium text-sm flex items-center gap-2">
                <LocationIcon />
                <span>Al-Azizia Al-Khobar</span>
              </span>
            </div>
          </Card>
          {/* separator */}
          <div className="bg-border-primary h-px my-6 relative">
            <span className="absolute left-0 right-0 py-0 -top-3 mx-auto w-fit px-4 bg-white">
              Or
            </span>
          </div>

          {/* contact form */}
          <Card padding="p-4">
            <form className="space-y-4">
              {/* name */}
              <InputGroup
                labelText={"landingpage.buttons.name"}
                htmlFor="name"
                inputName="name"
                placeholder={"landingpage.buttons.entername"}
                // register={register}
                // errors={errors}
              />
              {/* branch name */}
              <InputGroup
                labelText={"landingpage.buttons.storeName"}
                htmlFor="branchname"
                inputName="branchname"
                placeholder={"landingpage.buttons.enterstore"}
                // register={register}
                // errors={errors}
              />
              {/* phone */}
              <InputGroup
                labelText={"landingpage.buttons.phone"}
                htmlFor="phonenumber"
                inputName="phonenumber"
                placeholder={"landingpage.buttons.enterphone"}
                // register={register}
                // errors={errors}
              />
              <Dropdown />
              {/* message */}
              <InputGroup
                labelText={"landingpage.buttons.message"}
                htmlFor="message"
                inputType="textarea"
                placeholder={"landingpage.buttons.messagetype"}
                inputName="message"
                // register={register}
                // errors={errors}
              />
              <CustomButton type="submit" text={"landingpage.buttons.sendmessage"}/>
            </form>
          </Card>
        </div>
      </div>
      {/* footer */}
    </React.Fragment>
  );
};

export default ContactUs;

const businessTypes = [
  { value: "", label: "landingpage.buttons.bussniess" },
  { value: "COFFEE_SHOP", label: "business_profile.coffee_shop" },
  { value: "RESTAURANT", label: "business_profile.restaurant" },
  { value: "SHOP", label: "business_profile.shop" },
  { value: "SUPER_MARKET", label: "business_profile.super_market" },
  { value: "FOOD_TRUCK", label: "business_profile.food_truck" },
  { value: "GIFT_SHOP", label: "business_profile.gift_shop" },
  { value: "HAIRDRESSERS", label: "business_profile.hair_dressers" },
  { value: "CAR_SERVICES", label: "business_profile.car_services" },
  {
    value: "EXPRESS_SERVICES ",
    label: "business_profile.express_services",
  },
];

function Dropdown() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(businessTypes[0]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div>
          <Label className="`block text-sm font-medium text-text-primary">
          {t("landingpage.buttons.bussniess")}
          </Label>
          <div className="relative mt-1">
            <ListboxButton className="relative block w-full px-4 py-2 text-text-primary bg-white border border-border-primary rounded-lg appearance-none focus:border-border-primary focus:ring-0 focus:outline-none text-left">
              <span className="block truncate">{t(selected.label)}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ArrowDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                {businessTypes.map((type) => (
                  <ListboxOption
                    key={type.value}
                    className={({ focus }) =>
                      classNames(
                        focus ? "bg-surface-gray text-text-primary" : "",
                        !focus ? "text-text-primary" : "",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={type.value}
                  >
                    {({ selected, focus }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {t(type.label)}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon
                              className="h-5 w-5 !fill-text-primary"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
