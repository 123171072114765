import React, { useEffect, useState } from "react";
import Stepper from "./Stepper/SignUpStepper";
import Navbar from "../../common/components/Navbar/Navbar";
import { SignoutIcon, LogoWhite, EnvelopSm } from "../../common/Icons";
import Footer from "../../common/components/Footer/Footer";
import { getUser } from "../../common/utils/users.utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useStorage from "../../common/utils/localStorage.util";
import { useUserContext } from "../../../context/UserContext";
/**
 * ProfileLayout component.
 * Renders a layout for user profile, including a navbar, user details, and footer.
 * Manages the display of steps in a process and highlights completed and active steps.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The children components rendered inside the layout.
 * @param {string} props.activePage - The current active step in the process.
 * @returns {JSX.Element} JSX code for rendering the ProfileLayout component.
 */

const ProfileLayout = ({ activePage, children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateUser, setUserProfile, setToken } = useUserContext();

  const user = getUser("user");
  const parsedUserData = JSON.parse(user);
  const email = parsedUserData?.user?.email;

  const [completedSteps, setCompletedSteps] = useState([]);

  const stepsOrder = [
    t(`stepper.create_password`),
    t(`stepper.complete_profile`),
    t(`stepper.complete_business_profile`),
    t(`stepper.setup_your_plan`),
  ];

  useEffect(() => {
    const activeStepIndex = stepsOrder.indexOf(activePage);
    const newCompletedSteps = stepsOrder.slice(0, activeStepIndex + 1);
    setCompletedSteps(newCompletedSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  const handleLogout = () => {
    updateUser(null);
    setToken(null);
    setUserProfile(null);
    useStorage.clearStorage();
    useStorage.setItem("logout", Date.now().toString());
    navigate("/");
  };

  return (
    <section className="min-h-screen">
      <div className="flex flex-col justify-between w-2/3">
        <Navbar language className="border-b" />
        <div>{children}</div>
        <Footer />
      </div>
      <div className="hidden lg:block w-1/3 bg-bright-cerulean fixed top-0 end-0 min-h-screen h-full overflow-y-scroll">
        <div className="py-4 px-6">
          <LogoWhite />
        </div>
        <div className="py-5 px-8 flex flex-col">
          {/* email */}
          <div className="flex items-center justify-between rounded-lg bg-[#41BCE8] py-3 px-6">
            <div className="flex items-center gap-3">
              <EnvelopSm />
              <div className="ml-4">
                <p className="text-sm font-normal text-white">
                  {t("profile_layout.your_email")}
                </p>
                <p className="text-base font-semibold text-white">{email}</p>
              </div>
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={handleLogout}
            >
              <SignoutIcon strokeColor="#fff" />
            </div>
          </div>
          <h3 className="text-white text-3xl font-semibold 1xl:mt-14 mt-6">
            {t(`profile_layout.start_create_account`)}
          </h3>
          <Stepper
            t={t}
            activePage={activePage}
            completedSteps={completedSteps}
          />
        </div>
      </div>
    </section>
  );
};

export default ProfileLayout;

// Sample Usage:
// import ProfileLayout  from '.path/ProfileLayout';
// <ProfileLayout>
//   {/* Your profile content goes here */}
// </ProfileLayout>
