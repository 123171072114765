// Import necessary functions and modules from Redux Toolkit
import { configureStore } from "@reduxjs/toolkit";

// Import reducers for authentication and file upload features
import authReducer from "../features/Auth/AuthSlice"; // Reducer for authentication
import uploadFileReducer from "../features/UploadFile/UploadFileSlice"; // Reducer for file upload
import posLoginReducer from "../features/PosLogin/PosLoginSlice"; // Reducer for authentication
import posHomeReducer from "../features/PosHome/PosHomeSlice";
import clientSettingsReducer from "../features/ClientSettings/clientSettingsSlice";
import clientReducer from "../features/Clients/clientSlice";
import userDetailReducer from "../features/Users/UsersSlice";
import posUserReducer from "../features/PosUsers/PosUsersSlice";
import categoriesReducer from "../features/Categories/CategoriesSlice";
import modalReducer from "../features/Modal/ModalSlice";
import customerReducer from "../features/Customers/CustomersSlice";
import storesReducer from "../features/Stores/StoresSlice";
import posstoresReducer from "../features/PosStores/PosStoresSlice";
import posTablesReducer from "../features/PosTables/PosTablesSlice";
import posOrderReducer from "../features/PosOrder/PosOrderSlice";
import promotionReducer from "../features/Promotions/PromotionsSlice";
import suppliersReducer from "../features/Suppliers/SuppliersSlice";
import itemsReducer from "../features/Items/ItemsSlice";
import itemsModalReducer from "../features/ItemsModal/ItemsModalSlice";
import paymentsModalReducer from "../features/PaymentsModal/PaymentsModalSlice";
import stocktakingReducer from "../features/Stocktaking/StocktakingSlice";
import inventoryTransferReducer from "../features/InventoryTransfer/InventoryTransferSlice";
import saleInvoicesReducer from "../features/Invoices/SaleInvoices/SaleInvoicesSlice";
import purchaseInvoiceReducer from "../features/Invoices/PurchaseInvoices/PurchaseInvoiceSlice";
import expenseInvoiceReducer from "../features/Invoices/ExpenseInvoices/ExpenseInvoiceSlice";
import reportsReducer from "../features/Reports/ReportsSlice";
import paymentsReducer from "../features/Payments/PaymentsSlice";
import dashboardReducer from "../features/AdminDashboardCards/AdminDashboardCardsSlice";
import adminsalesReducer from "../features/AdminSales/AdminSalesSlice";
import clientDashboardReducer from "../features/ClientDashboard/ClientDashboardSlice";
import searchReducer from "../features/GlobalSearch/GlobalSearchSlice";
import subscriptionsReducer from "../features/AdminSubscribtions/AdminSubscribtionsSlice";
import clientSubscriptionsReducer from "../features/ClientSubscriptions/ClientSubscriptionsSlice";

// Create the Redux store using configureStore
/**
 * Configures and creates the Redux store.
 * Combines reducers for authentication and file upload features.
 * Defines slices of the store to use specific reducers.
 *
 * @returns {Object} The created Redux store.
 */
const store = configureStore({
  reducer: {
    auth: authReducer, // Set the 'auth' slice of the store to use the 'authReducer'
    uploadFile: uploadFileReducer, // Set the 'uploadFile' slice of the store to use the 'uploadFileReducer'
    posLogin: posLoginReducer,
    posHome: posHomeReducer,
    clientSettings: clientSettingsReducer, // Set the 'clientSettings' slice of the store to use the 'clientSettingsReducer'
    client: clientReducer, // Set the 'clientSettings' slice of the store to use the 'clientSettingsReducer'
    userDetail: userDetailReducer, // Set the 'userDetail' slice of the store to use the 'userDetailReducer'
    posUser: posUserReducer, // Set the 'posUser' slice of the store to use the 'posUserReducer'
    modal: modalReducer, // set the 'modal' slice of the store to use the 'modalReducer'
    customers: customerReducer, // set the 'modal' slice of the store to use the 'customerReducer'
    subscriptions: subscriptionsReducer, // set the 'modal' slice of the store to use the 'customerReducer'
    suppliers: suppliersReducer, // set the 'modal' slice of the store to use the 'customerReducer'
    stores: storesReducer, // set the 'modal' slice of the store to use the 'modalReducer'
    posTables: posTablesReducer, // set the 'modal' slice of the store to use the 'posTableReducer'
    posOrder: posOrderReducer, // set the 'modal' slice of the store to use the 'posTableReducer'
    promotion: promotionReducer, // Set the 'posUser' slice of the store to use the 'posUserReducer'
    itemsModal: itemsModalReducer, // set the 'itemsModal' slice of the store to use the 'itemsModalReducer'
    paymentsModal: paymentsModalReducer, // set the 'paymentsModal' slice of the store to use the 'paymentsModalReducer'
    categories: categoriesReducer, // set the 'modal' slice of the store to use the 'modalReducer'
    items: itemsReducer, // set the 'items' slice of the store to use the 'itemsReducer'
    stocktaking: stocktakingReducer, // set the 'stocktaking' slice of the store to use the 'stocktakingReducer'
    inventoryTransfer: inventoryTransferReducer, // set the 'inventoryTransfer' slice of the store to use the 'inventoryTransferReducer'
    saleInvoices: saleInvoicesReducer, // set the 'saleInvoices' slice of the store to use the 'saleInvoicesReducer'
    purchaseInvoices: purchaseInvoiceReducer, // set the 'purchaseInvoices' slice of the store to use the 'purchaseInvoicesReducer'
    expenseInvoices: expenseInvoiceReducer, // set the 'expenseInvoices' slice of the store to use the 'expenseInvoiceReducer'
    reports: reportsReducer, // set the 'reports' slice of the reports to use the 'reportsReducer'
    payments: paymentsReducer, // set the 'payments' slice of the payments to use the 'paymentsReducer'
    posstores: posstoresReducer, // set the 'modal' slice of the store to use the 'modalReducer'
    dashboard: dashboardReducer, // set the 'modal' slice of the store to use the 'modalReducer'
    adminsales: adminsalesReducer, // set the 'modal' slice of the store to use the 'modalReducer'
    clientDashboard: clientDashboardReducer, // set the 'client dashboard' slice of the store to  use the 'clientDashboard'
    search: searchReducer, // set the 'search' slice of the store to  use the 'searchReducer'
    clientSubscriptions: clientSubscriptionsReducer, // set the 'search' slice of the store to  use the 'searchReducer'
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Export the created Redux store
export default store;
