// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userDetailService from "./UsersService";
import { showToast } from "../../components/common/utils/showToast.util";

/**
 * Initial state structure defining various properties related to user detail processes.
 * Each property represents a specific setting action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllUsers, updateUserDetail
 * store data, error, success, loading, and message status for corresponding settings actions.
 */
const initialState = {
  getAllUsers: {
    data: null,
    totalPages: 0,
    totalRecords: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateUserDetail: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  loginPosUser: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Initiates the get all users process.
 */
export const getAllUsers = createAsyncThunk(
  "getAllUsers",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload }, thunkAPI) => {
    const response = await userDetailService.getAllUsers(payload);
    if (response?.data?.Succeeded) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

/**
 * Initiates the update user details for the specified user
 * @param {id} userID - userId
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const updateUserDetail = createAsyncThunk(
  "updateUserDetail",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ userId, payload, successCallBack }, thunkAPI) => {
    const response = await userDetailService.updateUserDetail(userId, payload);
    successCallBack(response);
    return response?.data?.data;
  }
);

/**
 * Initiates the update user details for the specified user
 * @param {id} userID - userId
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const loginPosUser = createAsyncThunk(
  "loginPosUser",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await userDetailService.loginPosUser(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);

        return response;
      } else {
        showToast(response?.data?.message, "error");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for user detail related state management.
 * - `name`: Name of the slice (users)
 * - `initialState`: Initial state defining properties for various user detail actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getBusinessDetails, updateBusinessDetails, etc.).
 */
export const userDetailSlice = createSlice({
  name: "users",
  initialState,

  reducers: {
    /**
     * Resets the state for the user detail action.
     */
    reset: (state) => {
      state.getAllUsers = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.updateUserDetail = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.loginPosUser = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllUsers action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllUsers.pending, (state) => {
        state.getAllUsers.isLoading = true;
        state.getAllUsers.message = "";
        state.getAllUsers.isError = false;
        state.getAllUsers.isSuccess = false;
        state.getAllUsers.data = null;
      })
      /**
       * Updates the state when getAllUsers action is fulfilled/successful.
       * Updates loading and success flags and sets getAllUsers data with the payload.
       */
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.getAllUsers.isLoading = false;
        state.getAllUsers.isSuccess = true;
        state.getAllUsers.data = action.payload;
      })
      /**
       * Updates the state when getAllUsers action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllUsers.rejected, (state, action) => {
        state.getAllUsers.isLoading = false;
        state.getAllUsers.isError = true;
        state.getAllUsers.data = null;
      })
      /**
       * Updates the state while the updateUserDetail action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(updateUserDetail.pending, (state) => {
        state.updateUserDetail.isLoading = true;
        state.updateUserDetail.message = "";
        state.updateUserDetail.isError = false;
        state.updateUserDetail.isSuccess = false;
        state.updateUserDetail.data = null;
      })
      /**
       * Updates the state when updateUserDetail action is fulfilled/successful.
       * Updates loading and success flags and sets updateUserDetail data with the payload.
       */
      .addCase(updateUserDetail.fulfilled, (state, action) => {
        state.updateUserDetail.isLoading = false;
        state.updateUserDetail.isSuccess = true;
        state.updateUserDetail.data = action.payload;
      })
      /**
       * Updates the state when updateUserDetail action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateUserDetail.rejected, (state, action) => {
        state.updateUserDetail.isLoading = false;
        state.updateUserDetail.isError = true;
        state.updateUserDetail.data = null;
      })
      /**
       * Updates the state while the loginPosUser action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(loginPosUser.pending, (state) => {
        state.loginPosUser.isLoading = true;
        state.loginPosUser.message = "";
        state.loginPosUser.isError = false;
        state.loginPosUser.isSuccess = false;
        state.loginPosUser.data = null;
      })
      /**
       * Updates the state when loginPosUser action is fulfilled/successful.
       * Updates loading and success flags and sets loginPosUser data with the payload.
       */
      .addCase(loginPosUser.fulfilled, (state, action) => {
        state.loginPosUser.isLoading = false;
        state.loginPosUser.isSuccess = true;
        state.loginPosUser.data = action.payload;
      })
      /**
       * Updates the state when loginPosUser action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(loginPosUser.rejected, (state, action) => {
        state.loginPosUser.isLoading = false;
        state.loginPosUser.isError = true;
        state.loginPosUser.data = null;
      });
  },
});

/**
 * Destructures the reset action from the userDetailSlice actions.
 * - `reset`: Action function to reset the user detail  related state.
 */

// sample usage

// import { reset } from './youruserDetailSlicePath';

// Using the reset action
// const reset = () => {
//   store.dispatch(reset());
// };

export const { reset } = userDetailSlice.actions;

/**
 * Exports the default reducer generated by userDetailSlice.
 * This reducer handles state updates for user detail related actions.
 */

// sample usage

// import userDetailReducer from "./youruserDetailSlicePath";

// Using the default reducer
// const initialState = {
// Your initial state for user detail // };

// Creating a store with the userDetailReducer handling user detail related actions
// const store = configureStore({
// reducer: {
// userDetailReducer: userDetailReducer,
// other reducers...
// },
// preloadedState: {
// auth: initialState,
// other preloaded states...
// },
// });
export default userDetailSlice.reducer;
