/**
 * Manages state for stores.
 * Defines initial state for each store action.
 */

// Import necessary functions and modules from Redux Toolkit

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createStoresApi from "./PosStoresService";

/**
 * Initial state structure defining various properties related to stores processes.
 * Each property represents a specific stores action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllPosStores, addStore, deleteStore, etc.
 * store data, error, success, loading, and message status for corresponding stores actions.
 */
const initialState = {
  getAllPosStores: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getPosSingleStore: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the stores process.
 * These action types are prefixed with the 'stores' base path for better organization.
 */
const BASE = "pos-user";

export const ActionTypes = {
  GET_ALL_STORES: `${BASE}/get-stores`, // Action type for get all stores
};

// Creating an instance of the stores service with a base URL 'stores'
const storesService = createStoresApi(BASE);

/**
 * Initiates the getAllPosStores process
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting stores
 *    order: order for sorting stores by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllPosStores.
 */
export const getAllPosStores = createAsyncThunk(
  ActionTypes.GET_ALL_STORES,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async (_, thunkAPI) => {
    const response = await storesService.getAllPosStores();
    return response?.data?.data;
  }
);

/**
 * Initiates the getPosSingleStore process for a store.
 * @param {object} storeId id of the store
 * @param {function} successCallBack - Callback function upon successful getPosSingleStore.
 */
export const getPosSingleStore = createAsyncThunk(
  `${BASE}/select-store/storeId`,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ storeId, successCallBack }, thunkAPI) => {
    const response = await storesService.getPosSingleStore(storeId);
    return response?.data?.data;
  }
);

export const storesSlice = createSlice({
  name: "posstores",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllstore action.
     */
    reset: (state) => {
      state.getAllPosStores = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getPosSingleStore = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllPosStores action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllPosStores.pending, (state) => {
        state.getAllPosStores.isLoading = true;
        state.getAllPosStores.message = "";
        state.getAllPosStores.isError = false;
        state.getAllPosStores.isSuccess = false;
        state.getAllPosStores.data = null;
      })
      /**
       * Updates the state when getAllPosStores action is fulfilled/successful.
       * Updates loading and success flags and sets getAllPosStores data with the payload.
       */
      .addCase(getAllPosStores.fulfilled, (state, action) => {
        state.getAllPosStores.isLoading = false;
        state.getAllPosStores.isSuccess = true;
        state.getAllPosStores.data = action.payload;
      })
      /**
       * Updates the state when getAllPosStores action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllPosStores.rejected, (state, action) => {
        state.getAllPosStores.message = action.payload?.message;
        state.getAllPosStores.isLoading = false;
        state.getAllPosStores.isError = true;
        state.getAllPosStores.data = null;
      })
      /**
       * Updates the state while the getPosSingleStore action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getPosSingleStore.pending, (state) => {
        state.getPosSingleStore.isLoading = true;
        state.getPosSingleStore.message = "";
        state.getPosSingleStore.isError = false;
        state.getPosSingleStore.isSuccess = false;
        state.getPosSingleStore.data = null;
      })
      /**
       * Updates the state when getPosSingleStore action is fulfilled/successful.
       * Updates loading and success flags and sets getPosSingleStore data with the payload.
       */
      .addCase(getPosSingleStore.fulfilled, (state, action) => {
        state.getPosSingleStore.isLoading = false;
        state.getPosSingleStore.isSuccess = true;
        state.getPosSingleStore.data = action.payload;
      })
      /**
       * Updates the state when getPosSingleStore action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getPosSingleStore.rejected, (state, action) => {
        state.getPosSingleStore.message = action.payload?.message;
        state.getPosSingleStore.isLoading = false;
        state.getPosSingleStore.isError = true;
        state.getPosSingleStore.data = null;
      });
    /**
     * Updates the state while the addStore action is pending.
     * Sets loading to true and clears previous messages.
     */
  },
});

/**
 * Destructures the reset action from the storesSlice actions.
 * - `reset`: Action function to reset the stores related state.
 */

// sample usage

// import { reset } from './storesSlice';

// Using the reset action
// const resetStores = () => {
//   // Dispatching the reset action to reset stores state
//   store.dispatch(reset());
// };

export const { reset } = storesSlice.actions;

/**
 * Exports the default reducer generated by storesSlice.
 * This reducer handles state updates for stores related actions.
 */

// sample usage

// import storesReducer from "./storesSlice";

// Using the default reducer
// const initialState = {
// Your initial state for stores actions
// };

// Creating a store with the storeReducer handling stores related actions
// const store = configureStore({
// reducer: {
// stores: storesReducer,
// other reducers...
// },
// preloadedState: {
// auth: initialState,
// other preloaded states...
// },
// });
export default storesSlice.reducer;
