import React, { useState } from "react";
import PosSaveOrder from "./PosSaveOrder/PosSaveOrder";
import PosProcessingOrder from "./PosProcessingOrder/PosProcessingOrder";
import CustomAdminModel from "../../common/components/AdminModal/CustomAdminModal";
import Checkout from "../PosHome/Checkout/Checkout";
import PaymentSuccess from "../PosHome/Checkout/PaymentSuccess/PaymentSuccess";

function PosOrder() {
  const [checkOutVisible, setCheckOutVisible] = useState(false);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [orderCheckoutPaid, setOrderCheckoutPaid] = useState(null);
  const handleClose = () => {
    setIsCheckoutModalOpen(false);
    window.location.reload(); // This will reload the page
  }; 
  return (
    <div className="p-3 bg-white">
      <PosSaveOrder
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        isCheckoutModalOpen={checkOutVisible}
        setIsCheckoutModalOpen={setCheckOutVisible}
      />
      <PosProcessingOrder
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        isCheckoutModalOpen={checkOutVisible}
        setIsCheckoutModalOpen={setCheckOutVisible}
      />
      {checkOutVisible && <CustomAdminModel
        isOpen={checkOutVisible}
        onClose={() => setCheckOutVisible(false)}
        title="Checkout"
        
        width="min-[280px]:w-[80%] min-[1080px]:w-[580px]"
      >
        <Checkout
          setIsModalOpen={setCheckOutVisible}
          selectedItem={selectedItem}
          closeCheckoutModal={() => setIsCheckoutModalOpen(false)}
          setCheckOutVisible={setCheckOutVisible}
          setOrderCheckoutPaid={setOrderCheckoutPaid}
          setIsCheckoutModalOpen={setIsCheckoutModalOpen}
        />
      </CustomAdminModel>}
      {isCheckoutModalOpen && <CustomAdminModel
      
        isOpen={isCheckoutModalOpen}
        onClose={() => setIsCheckoutModalOpen(false)}
        showHeader={false}
        width="min-[340px]:w-[35%] max-[1080px]:w-[400px]"
        >
        <PaymentSuccess
            handleClose={handleClose}
            orderCheckoutPaid={orderCheckoutPaid}
        />
      </CustomAdminModel>}
    </div>
  );
}

export default PosOrder;
