import * as React from "react";

function EnvelopIcon(props) {
  return (
    <svg
      width="136"
      height="136"
      viewBox="0 0 136 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <rect width="136" height="136" rx="68" fill="#F2F4F7" />
      <path
        d="M95 45.5H41C40.4033 45.5 39.831 45.7371 39.409 46.159C38.9871 46.581 38.75 47.1533 38.75 47.75V86C38.75 87.1935 39.2241 88.3381 40.068 89.182C40.9119 90.0259 42.0565 90.5 43.25 90.5H92.75C93.9435 90.5 95.0881 90.0259 95.932 89.182C96.7759 88.3381 97.25 87.1935 97.25 86V47.75C97.25 47.1533 97.0129 46.581 96.591 46.159C96.169 45.7371 95.5967 45.5 95 45.5ZM89.2147 50L68 69.4484L46.7853 50H89.2147ZM92.75 86H43.25V52.8659L66.4784 74.1594C66.8935 74.5404 67.4365 74.7519 68 74.7519C68.5635 74.7519 69.1065 74.5404 69.5216 74.1594L92.75 52.8659V86Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export default EnvelopIcon;
