import api from "../../services/api";

/**
 * Creates items API endpoints based on the specified base path.
 * @param {string} basePath - The base path for items endpoints.
 * @returns {Object} Object containing items API methods.
 */
const createItemsApi = (basePath) => ({
  /**
   * get all items
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting items
   *    order: order for sorting items by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the item response.
   */
  getAllItems: async (payload) => {
    const response = await api().post(`${basePath}/list-items`, payload);
    return response;
  },

  /**
   * get all items
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting items
   *    order: order for sorting items by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the item response.
   */
  getSaleItems: async () => {
    const response = await api().get(`${basePath}/get/sale-items`);
    return response;
  },

  /**
   * get a single item
   * @param {object} id - id of the item
   * @returns {Promise} A Promise that resolves to the item response.
   */
  getSingleItem: async (itemId, vItemId) => {
    const url = vItemId
      ? `${basePath}/${itemId}?vItemId=${vItemId}`
      : `${basePath}/${itemId}`;
    const response = await api().get(url);
    return response;
  },

  /**
   * update a single item
   * @param {object} id - id of the item
   * @returns {Promise} A Promise that resolves to the item response.
   */
  updateItem: async (itemId, payload) => {
    const response = await api().put(`${basePath}/${itemId}`, payload);
    return response;
  },

  /**
   * add an item
   * @param {object} id - id of the item
   * @returns {Promise} A Promise that resolves to the item response.
   */
  addItem: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * get all items
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting items
   *    order: order for sorting items by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the item response.
   */
  getPosItems: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },

  getBarCode: async (isVariable) => {
    const response = await api().get(
      `${basePath}/generate-barcode/${isVariable}`
    );
    return response;
  },

  /**
   * delete an item
   * @param {object} id - id of the item
   * @returns {Promise} A Promise that resolves to the item response.
   */
  deleteItem: async (itemId, vItemId) => {
    const url = vItemId
      ? `${basePath}/${itemId}?vItemId=${vItemId}`
      : `${basePath}/${itemId}`;
    const response = await api().delete(url);
    return response;
  },
  /**
   * undo a deleted item
   * @param {object} id - id of the item
   * @returns {Promise} A Promise that resolves to the item response.
   */
  undoItem: async (id, vItemId) => {
    const url = vItemId
      ? `${basePath}/undo-item/${id}?vItemId=${vItemId}`
      : `${basePath}/undo-item/${id}`;
    const response = await api().get(url);
    return response;
  },
});

// Export the createItemsApi

/**
 * Example usage:
 * import createItemsApi from './path/to/api/itemsApi';
 *
 * const itemsApi = createItemsApi('items');
 *
 * const getAllItems = async (payload) => {
 *   try {
 *     const response = await itemsApi.getAllItems(payload);
 *     // Handle response
 *   } catch (error) {
 *     // Handle error
 *   }
 * };
 */

export default createItemsApi;
