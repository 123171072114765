import React, { useEffect } from "react";
import {
  DeleteIcon,
  Note,
  PlusIcon,
  MinusIcon,
  GrayNote,
} from "../../../../../common/Icons";
import { CustomButton } from "../../../../../common/components";
import useSelectedItemCard from "./useSelectedItemCard";
import { useTranslation } from "react-i18next";

const SelectedItemCard = ({
  item,
  selectedItems,
  setSelectedItems,
  onDelete,
  onQuantityChange,
  selectedPromotions,
}) => {
  const {
    note,
    isNoteVisible,
    setNote,
    handleAddNoteClick,
    handleRemoveNoteClick,
    decrementQuantity,
    incrementQuantity,
    handleQuantityChange,
    selectionsItems,
    editableQuantity,
  } = useSelectedItemCard(item, onQuantityChange,selectedItems);
  const totalPrice = (item.price ?? item.salePrice) * editableQuantity;

  // Step 1: Choose the correct price
  let priceToUse = item.price;
  if (!item.vItemId) {
    priceToUse = item.salePrice;
  }

  // Step 2: Determine if "Promotions on All Items" should be applied
  const allItemsPromotion = selectedPromotions.find(
    (promo) =>
      promo.accordion === "Promotions on All Items" && promo.selectedValue
  );

  let matchingPromotion = null;
  
  if (allItemsPromotion && allItemsPromotion.selectedValue) {
    // Apply this promotion to the item if found
    matchingPromotion = allItemsPromotion;
  } else {
    // If "Promotions on All Items" is empty, check for specific item promotion
    if (item.vItemId) {
      // Check if the item's vItemId matches any promotion's vItems
      matchingPromotion = selectedPromotions.find((promo) =>
        promo.selectedValue?.promotion?.vItems?.some(
          (vItem) => vItem.id === item.vItemId && vItem.item.englishName===promo.accordion
        )
      );
    }
    else{
        // If vItemId didn't match, compare englishName and accordion
        matchingPromotion = selectedPromotions.find(
          (promo) => promo.accordion === item.englishName && promo.selectedValue
        );
      }
        }

  // Step 3: Calculate the discount and final price
  let discountPrice = null;
  let finalPrice = priceToUse * item.quantity;
  
  if (matchingPromotion && matchingPromotion.selectedValue) {
    const promotion = matchingPromotion.selectedValue;

    if (promotion.type === "FIXED_AMOUNT") {
      discountPrice = (priceToUse - promotion.price) * item.quantity;
    } else if (promotion.type === "PERCENTAGE") {
      discountPrice = priceToUse * (1 - promotion.price / 100) * item.quantity;
    }

    finalPrice = discountPrice;
  }

  const isDiscountApplied = priceToUse * item.quantity !== finalPrice;


  const { t } = useTranslation();
  const updateItem = ({ id, name, value }) => {
    setSelectedItems((s) =>
      s.map((item, i) => {
        if (item.id === id) {
          return { ...item, [name]: value };
        }
        return item;
      })
    );
  };

  const handleChange = (e) => {
    const newNote = e.target.value;
    setNote(newNote);
    updateItem({ id: item.id, name: "note", value: newNote });
  };

  useEffect(() => {
    updateItem({ id: item.id, name: "quantity", value: editableQuantity });
  }, [editableQuantity]);
  useEffect(() => {
    updateItem({ id: item.id, name: "note", value: note });
  }, [item.id, note]);

  return (
    <div className="w-full border rounded-lg bg-gray-100  mb-2 p-3">
      <div className="flex flex-row justify-between">
        <span className="text-sm font-medium">
          {item.englishName ?? item?.item?.englishName}
        </span>
        <div>
          {discountPrice !== null && isDiscountApplied && (
            <span className="text-sm font-medium pr-2 text-red-500">
              {discountPrice} SAR
            </span>
          )}
          <span
            className={`text-sm font-medium pr-2 ${
              isDiscountApplied && discountPrice !== null
                ? "line-through text-gray-500"
                : ""
            }`}
          >
            {totalPrice} SAR
          </span>
        </div>
      </div>

      <div className="text-gray-500 text-sm ">
        {selectionsItems
          ? selectionsItems.map(
              (list, i) =>
                list + `${i !== selectionsItems.length - 1 ? "-" : ""}`
            )
          : ""}
      </div>
      {/* Note functionality */}
      {!isNoteVisible && (
        <div onClick={handleAddNoteClick} className="cursor-pointer">
          <div className="flex flex-row gap-2 ">
            <Note />
            <span className="cursor-pointer text-text-link text-xs font-medium">
              {t("pos_dashboard.add_note")}
            </span>
          </div>
        </div>
      )}
      {isNoteVisible && (
        <>
          <div className="flex items-center  gap-2">
            <GrayNote />
            <textarea
              value={note}
              onChange={handleChange}
              placeholder="Add a note"
              className="w-full p-2 rounded border-none"
            />
          </div>
          <div
            onClick={handleRemoveNoteClick}
            className="cursor-pointer text-text-link text-sm font-medium"
          >
            <span>{t("pos_dashboard.remove_note")}</span>
          </div>
        </>
      )}
      {/* Quantity adjustment */}
      <div className="flex items-center justify-between mt-1  bg-gray-100">
        <div className="flex items-center">
          <CustomButton
            onClick={decrementQuantity}
            icon={<MinusIcon />}
            width="w-6"
            padding="p-0"
            border="border"
            borderRadius="none"
            className="inline-block border mr-2 rounded-md"
            disabled={editableQuantity === 1}
          />
          <input
            type="text"
            value={editableQuantity}
            onChange={handleQuantityChange}
            className="font-medium w-12 text-link border-gray-500 rounded-md text-sm h-5 py-1 text-center"
          />
          <CustomButton
            onClick={incrementQuantity}
            icon={<PlusIcon />}
            width="w-6"
            border="none"
            padding="p-0"
            borderRadius="none"
            className="inline-block ml-2 shadow-none rounded-md"
          />
        </div>
        <div className="flex items-center">
          <span className="text-sm mr-4">
            {editableQuantity} {t("pos_dashboard.item")}
          </span>
          <CustomButton
            onClick={onDelete}
            icon={<DeleteIcon />}
            bgColor="!bg-gray-100"
            width="w-6"
            padding="p-0"
            borderRadius="none"
            className="inline-block shadow-none"
          />
        </div>
      </div>
    </div>
  );
};

export default SelectedItemCard;
