import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchBox } from "../../../../assets/images/svgs";
import { BoxesIcon, DrawerIcon } from "../../../common/Icons";
import { Card, Loader } from "../../../common/components";
import CustomSearch from "../../../common/components/CustomSearch/CustomSearch";
import { ItemCard } from "./ItemCard";
import useItems from "./useItems";

const lineColors = ["bg-red-500", "bg-gray-500", "bg-green-500"];

/**
 * Items component to display a list/grid of items with switching layout.
 * @returns {JSX.Element} JSX element representing the Items component.
 */
const Items = ({ selectedCategoryCardId, selectedItems, setSelectedItems }) => {
  const {
    categoryList,
    itemListLoading,
    itemsCount,
    layout,
    itemList,
    setLayout,
    handleCategoryClick,
    selectedCategoryId,
  } = useItems(selectedCategoryCardId);
  const { t } = useTranslation();

  const [query, setQuery] = useState("");

  const filteredItems = itemList.filter((item) => {
    if (query.length === 0) return false;

    if (item.barcode === query) {
      return true;
    }

    return item.variableItem?.some((variable) => variable.barcode === query);
  });

  let selectedItem;
  let salePrice;
  let selections;
  let itemId;
  let vitemid;

  filteredItems.forEach((item) => {
    if (item.barcode === query) {
      selectedItem = item;
      salePrice = item.salePrice;
      selections = {};
      vitemid = null;
    } else {
      const matchingVariable = item.variableItem.find(
        (variable) => variable.barcode === query
      );
      if (matchingVariable) {
        selectedItem = item;
        salePrice = matchingVariable.salePrice;
        selections = matchingVariable.variations;
        vitemid = matchingVariable.id;
      }
    }
  });

  const id = vitemid ?? selectedItem?.id;
  const image = selectedItem?.image;
  const englishName = selectedItem?.englishName;

  useEffect(() => {
    if (selectedItem) {
      setSelectedItems &&
        setSelectedItems((prev) => {
          const existingItemIndex =
            selectedItem.type === "VARIABLE"
              ? prev.findIndex((item) => item.selections === selections)
              : prev.findIndex((item) => item.id === id);

          if (existingItemIndex > -1) {
            const updatedItems = [...prev];
            updatedItems[existingItemIndex].quantity += 1;
            return updatedItems;
          } else {
            return [
              ...prev,
              {
                id,
                image,
                englishName,
                salePrice,
                quantity: 1,
                selections,
              },
            ];
          }
        });
    }
  }, [id, image, englishName, salePrice]);

  return (
    <div className="mt-5 relative">
      {/* Category List and other UI elements remain unchanged... */}
      <div className="flex justify-between items-center mb-4  top-0">
        <div className="text-lg font-semibold text-text-primary">
          {t("pos_dashboard.items")} ({itemsCount})
        </div>
        
        {/* switch grid/list */}
        <div className="flex items-center bg-surface-gray  gap-1 p-1 ">
          <div className="mr-2">
          <CustomSearch
          placeholder={t("pos_dashboard.Search_item")}
          inputName="search"
          paddingY="py-1"
          onChange={(e) => setQuery(e.target.value)}
        />
          </div>
       
          <span
            onClick={() => setLayout("grid")}
            className={`cursor-pointer  ${
              layout === "grid" && "bg-white shadow-2xl rounded-sm p-1"
            }`}
          >
            <BoxesIcon className="min-w-8 min-h-2" />
          </span>
          <span
            onClick={() => setLayout("list")}
            className={`cursor-pointer ${
              layout === "list" && "bg-white shadow-2xl rounded-sm p-1"
            }`}
          >
            <DrawerIcon className="min-w-8 min-h-2" />
          </span>
        </div>
      </div>
      <div className="py-2 mb-4 overflow-auto w-[720px] grayScrollNoBg overflow-x-auto">
        <ul className="flex space-x-4 justify-start gap-3">
          {/* 'All' category button with a click handler */}
          <li
            className={`px-1 py-1 rounded-lg cursor-pointer text-sm font-medium ${
              selectedCategoryId === null
                ? "bg-gray-200 text-text-primary font-medium"
                : "text-text-secondary"
            } `}
            onClick={() => handleCategoryClick(null)}
          >
            All
          </li>

          {/* Mapping over categoryList to create buttons */}
          {categoryList &&
            categoryList?.map((category) => (
              <li
                key={category.id}
                className={`px-1 py-1 cursor-pointer text-sm rounded-lg font-medium ${
                  selectedCategoryId === category.id
                    ? "bg-gray-200 text-text-primary font-medium"
                    : "text-text-secondary"
                } `}
                onClick={() => handleCategoryClick(category.id)}
              >
                {category.englishName}
              </li>
            ))}
        </ul>
      </div>
      <div
        className={`w-full max-h-[500px] min-h-[450px] overflow-auto pr-3 ${
          layout === "grid"
            ? "grid xl:grid-cols-4 grid-cols-2 gap-3"
            : "flex flex-col gap-4"
        }`}
      >
        {/* Check if itemList is loading */}
        {itemListLoading ? (
           <div>
            {/* <Loader/> */}
          </div>
        ) : (
          <>
            {/* Check if itemList has items */}
            {(filteredItems && filteredItems.length > 0
              ? filteredItems
              : itemList
            )?.map((item, index) => {
              return layout === "grid" ? (
                <div
                  key={item.id}
                  className={`${layout === "grid" && "rounded-lg "}`}
                >
                  <ItemCard
                    layout={layout}
                    englishName={item.englishName}
                    salePrice={item.salePrice}
                    image={item.image}
                    index={index}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                    color={lineColors[index % lineColors.length]}
                    type={item.type}
                    id={item.id}
                    variablePairs={item.variablePairs}
                    variableItem={item.variableItem}
                    quantity={item.quantity}
                    selections={selections}
                  />
                </div>
              ) : (
                <Card
                  key={item.id}
                  padding="p-1"
                  className="border-none !shadow-none rounded-none"
                >
                  <ItemCard
                    layout={layout}
                    englishName={item.englishName}
                    salePrice={item.salePrice}
                    image={item.image}
                    index={index}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                    color={lineColors[index % lineColors.length]}
                    type={item.type}
                    id={item.id}
                    variablePairs={item.variablePairs}
                    variableItem={item.variableItem}
                    quantity={item.quantity}
                    selections={selections}
                  />
                </Card>
              );
            })}

            {/* Render fallback content if both filteredItems and itemList are empty */}
            {filteredItems?.length === 0 && itemList?.length === 0 && (
              <div className="flex flex-col items-center py-10 my-4 min-w-[600px]  max-w-[800px] rounded-lg">
                <img
                  src={SearchBox}
                  alt="Search Box"
                  className="ml-52 mt-24 rounded-lg"
                />
                <span className="text-text-primary text-xl ml-40 font-bold">
                  No Item to display
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Items;
