import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImageIcon } from "../../../../assets/images";
import { uploadSingleFile } from "../../../../features/UploadFile/UploadFileSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";

const CustomFileUploader = ({ selected, isLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [files, setFiles] = useState(selected ? [{ preview: selected }] : []);
  const [error, setError] = useState("");

  const clearFiles = (event) => {
    setFiles([]);
    setError("");
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setError("");
      },
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpeg", ".jpg"],
      },
      maxFiles: 1,
      maxSize: 10 * 1024 * 1024,
      validator: (file) => {
        if (file.name.endsWith(".jfif")) {
          return {
            code: "file-invalid-type",
            message: "validations.unsupported_format",
          };
        }
        if (file.size > 10 * 1024 * 1024) {
          return {
            code: "file-too-large",
            message: "validations.file_size",
          };
        }
        return null;
      },
    });

  useEffect(() => {
    if (fileRejections.length > 0) {
      const { errors } = fileRejections[0];
      const errorMessages = errors.map((error) => error.message).join(" ");
      setError(errorMessages);
    }
  }, [fileRejections]);

  useEffect(() => {
    if (files.length > 0 && !selected) {
      const formImgData = new FormData();
      formImgData.append("file", files[0]);
      dispatch(uploadSingleFile({ payload: formImgData }));
    }
  }, [files, dispatch, selected]);

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${
        isDragActive ? "active" : ""
      } border border-sky-blue rounded-lg py-8 px-6`}
    >
      <input {...getInputProps()} />
      {!files.length > 0 && (
        <div className="flex flex-col text-center items-center justify-center space-y-2">
          <img src={ImageIcon} alt="Icon" width={64} height={64} />
          <p className="text-text-link font-semibold text-base">
            {t("buttons.drag_or_click")}
          </p>
          <p className="text-sm to-text-secondary">
            {t("validations.supported_format")}: JPG, JPEG, PNG. Max Size:
            10mb
          </p>
        </div>
      )}
      {error && <p className="text-red-500 text-center">{t(error)}</p>}
      {isLoading ? (
        <Loader center margin="mt-2" />
      ) : (
        <>
          {files.length > 0 && (
            
            <div className="relative w-fit mx-auto mt-3">
              <p className="my-2 font-semibold">Selected Image</p>
              <img
                src={files[0]?.preview}
                alt="Preview"
                className="w-32 h-32"
              />
              <button
                className="ml-4 bg-red-500 leading-none text-white rounded-full w-7 h-7 absolute top-3 -right-3"
                onClick={(e) => {
                  e.stopPropagation();
                  clearFiles();
                }}
              >
                X
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomFileUploader;
