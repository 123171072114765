import api from "../../services/api";

/**
 * Creates subscriptions API endpoints based on the specified base path.
 * @param {string} basePath - The base path for subscriptions endpoints.
 * @returns {Object} Object containing subscriptions API methods.
 */

const createClientSubscriptionsApi = (basePath) => ({
  /**
   * subscribe to free trial
   
   * @returns {Promise} A Promise that resolves to the free trial subscribe
   */
  subscribeToFreeTrial: async () => {
    const response = await api().post(`${basePath}/free-trial`);
    return response;
  },


   /**
   * get a single price
   * @param {object} id - id of the price
   * @returns {Promise} A Promise that resolves to the price response.
   */
   getPricing: async (type) => {
    const response = await api().get(`/plans/get-all/${type}`);
    return response;
  },


  /**
   * upgrade subscription
   
   * @returns {Promise} A Promise that resolves to the upgrade subscription plan
   */
  upgradePlan: async (payload) => {
    const response = await api().post(
      `${basePath}/free-trial-upgrade`,
      payload
    );
    return response;
  },

  /**
   * calculate pricing summary
   
   * @returns {Promise} A Promise that resolves to the calculate pricing summary
   */
  calculatePricingSummary: async (payload) => {
    const response = await api().post(
      `${basePath}/calc-subscription-summary`,
      payload
    );
    return response;
  },
});

// Export the createClientSubscriptionsApi

/**
 * Example usage:
 * import createClientSubscriptionsApi from './path/to/api/clientDashboardApi';
 *
 * const clientDashboardApi = createClientSubscriptionsApi('customers');
 *
 * const getClientSalesCost = async (payload) => {
 *   try {
 *     const response = await clientDashboardApi.getClientSalesCost(payload);
 *     // Handle response
 *   } catch (error) {
 *     // Handle error
 *   }
 * };
 */

export default createClientSubscriptionsApi;
