/**
 * Retrive access token from local storage
 * @returns string | undefined
 */
export const getAccessToken = () => {
  if (typeof window !== "object") return undefined;

  const user = window.localStorage?.getItem("user");
  if (!user || user === "undefined") return "";

  try {
    const userData = JSON.parse(user);
    return userData?.token ?? "";
  } catch (error) {
    console.error("Failed to parse user data:", error);
    return "";
  }
};

/**
 * Retrive isLoginVerified Status
 * @returns bool
 */
export const isLoginVerified = () => {
  if (typeof window === "object" && window?.localStorage?.getItem("user")) {
    return JSON.parse(localStorage.getItem("user")).isLoginVerified;
  }
  return false;
};
