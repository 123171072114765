import React, { useEffect, useState } from "react";
import { Card, CustomButton, Loader } from "../../../../common/components";
import {
  LightBulb,
  PhoneIcon,
  Rocket,
  RocketLaunch,
  ToggleOff,
  ToggleOn,
} from "../../../../common/Icons";
import {
  getSingleSubscriptions,
  changeSubscription,
  getAllSubscriptions,
} from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Check } from "../../../../common/Icons/fontawesome";
import { DefaultImg } from "../../../../../assets/images";
import { getPricing } from "../../../../../features/ClientSubscriptions/ClientSubscriptionsSlice";

function ChangeSubscription({
  selectedItem,
  closeModal,
  subscriptionDetailId,
  selectedTab,
  getSingleClientDetail,
  clientProfile,
  subscriptionPlanId,
  subscriptionTableId,
}) {
  const dispatch = useDispatch();
  const subscriptionId =
    subscriptionDetailId ?? subscriptionPlanId ?? subscriptionTableId;
  const [trialPeriodEnding, setTrialPeriodEnding] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { data: singleSubscriptiondata, isLoading: subscriptionLoading } =
    useSelector((state) => state.subscriptions.getSingleSubscriptions);

  useEffect(() => {
    if (clientProfile?.id === undefined || clientProfile.id === null) {
      if (subscriptionId) {
        dispatch(
          getSingleSubscriptions({
            subscriptionId,
            isFreeTrial: "PaidSubscriptions" === "FreeTrial",
          })
        );
      }
    }
  }, []);
  const profile = singleSubscriptiondata?.clientUser?.profile ?? "-";
  const ProvidedBy = singleSubscriptiondata?.managedBy?.fullName ?? "-";
  const plan = singleSubscriptiondata?.plan_tenure ?? "-";
  const planId = singleSubscriptiondata?.planId;
  const startDate = singleSubscriptiondata?.startDate ?? "-";
  const endDate = singleSubscriptiondata?.endDate ?? "-";
  const pricingData = useSelector(
    (state) => state.clientSubscriptions.getPricing.data
  );
  const planType = singleSubscriptiondata?.planType;

  useEffect(() => {
    // Fetch pricing data based on the current plan tenure (MONTHLY/ANNUAL)
    const tenureType = trialPeriodEnding ? "ANNUAL" : "MONTHLY";
    dispatch(getPricing({ type: tenureType }));
  }, [dispatch, trialPeriodEnding]);

  useEffect(() => {
    if (plan) {
      setTrialPeriodEnding(plan === "ANNUAL");
    }
    if (planId) {
      setSelectedPlan(planId.toString());
    }
  }, [plan, planId]);

  const selectPlan = (planId) => {
    const selectedPlanData = pricingData.find(
      (plan) => plan.planId.toString() === planId
    );
    if (!selectedPlanData.disabled) {
      setSelectedPlan(planId);
      setIsChanged(true);
    }
  };

  const togglePlanType = () => {
    setTrialPeriodEnding(!trialPeriodEnding);
    setIsChanged(true);
  };

  const handleConfirmClick = () => {
    const planTenure = trialPeriodEnding ? "ANNUAL" : "MONTHLY";
    const selectedPlanData = pricingData.find(
      (plan) => plan.planId.toString() === selectedPlan
    );

    const payload = {
      planId: +selectedPlan,
      planTenure: planTenure,
      planAmount: selectedPlanData?.amount,
    };
    setIsButtonDisabled(true);
    dispatch(
      changeSubscription({
        subscriptionId,
        payload,
        successCallBack: handleSuccess,
      })
    );
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  const handleSuccess = () => {
    closeModal();
    fetchAllSubscriptions();
    getSingleClientDetail();
  };

  const fetchAllSubscriptions = () => {
    dispatch(
      getAllSubscriptions({
        payload: {
          page: 1,
          pageSize: 10,
          sortColumn: "id",
          order: { id: "DESC" },
          condition: {},
          attributes: {},
          periodId: 0,
        },
      })
    );
  };

  // Render the PlanCard using the disabled logic from above
  const PlanCard = ({
    title,
    price,
    IconComponent,
    isSelected,
    onClick,
    disabled,
  }) => (
    <div
      className={`relative flex flex-col w-full p-4 rounded-lg cursor-pointer ${
        isSelected
          ? "bg-blue-200 text-text-primary font-bold border-blue-400"
          : "bg-gray-100 text-gray-700"
      } ${disabled && "opacity-50 cursor-not-allowed"}`}
      onClick={() => !disabled && onClick()}
    >
      <div className="flex justify-between items-start">
        <IconComponent
          className={`h-5 w-5 ${isSelected ? "text-white" : "text-gray-200"}`}
        />
        {isSelected && (
          <div className="text-gray-200">
            <Check />
          </div>
        )}
      </div>
      <div>
        <h4 className="text-base font-semibold">{title}</h4>
        <div className="text-xs">{price} SAR</div>
      </div>
    </div>
  );

  if (subscriptionLoading) {
    return (
      <div className="mx-60 mt-12">
        <Loader />
      </div>
    );
  }

  const currentPlanId = parseInt(singleSubscriptiondata?.planId);
  const currentPlanTenure = singleSubscriptiondata?.plan_tenure;

  // Function to determine if a plan should be disabled based on the current selection
  const isDisabled = (plan) => {
    // Disable if it's a lower planId regardless of tenure
    if (plan.planId < currentPlanId) {
      return true;
    }
    // Allow switching tenure for the same planId
    if (
      plan.planId === currentPlanId &&
      plan.plan_tenure !== currentPlanTenure
    ) {
      return false;
    }
    // Disable lower or same plans unless switching tenure
    return (
      plan.planId <= currentPlanId && plan.plan_tenure === currentPlanTenure
    );
  };

  // Adjust how plans are filtered and mapped to include the disabled logic
  const filteredPlans = pricingData
    ?.filter(
      (plan) =>
        plan.plan_tenure === (trialPeriodEnding ? "ANNUAL" : "MONTHLY") ||
        plan.planId === currentPlanId
    )
    .map((plan) => ({
      ...plan,
      disabled: isDisabled(plan),
    }));

  return (
    <div className="p-4">
      <div className="flex items-center mt-4 ">
        <img
          src={profile?.profilePic || DefaultImg}
          alt="Pic"
          className="inline-block h-12 w-12 rounded-full border mr-3"
        />
        <div className="flex-grow ">
          <p className="text-text-primary text-lg font-semibold">
            {profile?.fullName ?? "-"}
          </p>
          <div className="flex items-center text-text-gray font-normal mt-2">
            <PhoneIcon className="mr-2" />{" "}
            <span className="pl-2 text-text-primary  text-sm font-normal">
              {profile?.phoneNumber ?? "-"}
            </span>
          </div>
        </div>
      </div>
      <p className="mt-4 text-base font-normal text-text-primary">
        Plan Detail
      </p>

      <Card className="my-2 !p-4">
        <div className="md:flex gap-6">
          <div>
            <p className=" text-base font-medium text-text-primary">
              Current Plan
            </p>
            <p className="text-base font-normal text-text-secondary">
              {planType}
            </p>
          </div>
          <div>
            <p className="text-base text-text-primary font-medium">Provider</p>
            <p className="text-base font-normal text-text-secondary">
              {ProvidedBy ?? "-"}
            </p>
          </div>
          <div>
            <p className="text-base  text-text-primary font-medium">
              Start Date
            </p>
            <p className="text-base font-normal text-text-secondary">
              {startDate ?? "-"}
            </p>
          </div>
          <div>
            <p className="text-base  text-text-primary font-medium">End Date</p>
            <p className="text-base font-normal text-text-secondary">
              {endDate ?? "-"}
            </p>
          </div>
        </div>
      </Card>

      <Card>
        <p className="text-text-primary font-semibold text-base">
          Update to New Plan
        </p>
        <div className="flex items-center justify-between mt-2">
          <label className="text-sm font-normal">Annual Plan</label>
          <div onClick={togglePlanType}>
            {trialPeriodEnding ? <ToggleOn /> : <ToggleOff />}
          </div>
        </div>

        <div className="bg-white p-1 gap-4 flex justify-between items-start mt-2 ">
          {filteredPlans?.map((plan) => (
            <PlanCard
              key={plan.planId}
              title={plan.name + " Plan"}
              price={plan.amount}
              IconComponent={
                plan.name === "Basic"
                  ? LightBulb
                  : plan.name === "Professional"
                  ? Rocket
                  : RocketLaunch
              }
              isSelected={selectedPlan === plan.planId.toString()}
              onClick={() => selectPlan(plan.planId.toString())}
              disabled={plan.disabled}
            />
          ))}
        </div>
      </Card>

      <div className="flex justify-end space-x-2 mt-4">
        <CustomButton
          width="20px"
          text="Cancel"
          bgColor="bg-white"
          textColor="text-text-primary"
          border="border"
          onClick={closeModal}
          className="hover:!bg-gray-200"
        />
        <CustomButton
          type="button"
          width="20px"
          text="Confirm"
          textColor="text-white"
          onClick={handleConfirmClick}
          disabled={!isChanged || isButtonDisabled}
        />
      </div>
    </div>
  );
}

export default ChangeSubscription;
