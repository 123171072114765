import React, { createContext, useContext, useEffect, useState } from "react";
import useStorage from "../components/common/utils/localStorage.util";

// Create a context to store user information
const UserContext = createContext();

// Create a custom hook to access the user context
export const useUserContext = () => useContext(UserContext);

// Create a UserProvider component to wrap your application
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(useStorage.getItem("user")?.user || null);
  const [userProfile, setUserProfile] = useState(
    useStorage.getItem("user")?.userProfile || null
  );
  const [token, setToken] = useState(useStorage.getItem("user")?.token || null);

  // Function to set user information
  const updateUser = (newUser) => {
    setUser(newUser);
  };

  useEffect(() => {
    const storage = { user, userProfile, token };
    useStorage.setItem("user", storage);
  }, [user, userProfile, token]);

  // Value to be passed to the context provider
  const value = {
    user,
    updateUser,
    userProfile,
    setUserProfile,
    token,
    setToken,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
