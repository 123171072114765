import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../common/components";
import { Dollars, Headphone, Tag } from "../../assets/images";

const WhyTagDev = () => {
  const { t } = useTranslation();
  const cards = [
    {
      heading: t("landingpage.whytagdev.cards.one.heading"),
      text: t("landingpage.whytagdev.cards.one.text"),
      icon: Headphone,
    },
    {
      heading: t("landingpage.whytagdev.cards.two.heading"),
      text: t("landingpage.whytagdev.cards.two.text"),
      icon: Dollars,
    },
    {
      heading: t("landingpage.whytagdev.cards.three.heading"),
      text: t("landingpage.whytagdev.cards.three.text"),
      icon: Tag,
    },
  ];
  return (
    <div className="md:py-16 py-10 px-24 xl:px-29">
      <div className="space-y-3 text-center">
        <h2 className="text-text-primary font-semibold xs:text-5xl text-3xl xs:leading-wider">
          {t("landingpage.whytagdev.heading")}
        </h2>
        <p className="text-text-secondary font-medium text-base">
          {t("landingpage.whytagdev.text")}
        </p>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 mx-auto w-fit md:mt-14 mt-10">
        {cards.map((card) => (
          <Card key={card.heading}>
            <div className="flex gap-3">
              <img
                src={card.icon}
                alt="Headphone"
                className="flex-shrink-0 w-12 h-11"
              />
              <div className="">
                <h5 className="text-lg font-semibold text-text-primary">
                  {card.heading}
                </h5>
                <p className="text-text-secondary font-normal text-base">
                  {card.text}
                </p>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default WhyTagDev;
