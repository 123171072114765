import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateStaffUser } from "../../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";

export const useAdminEditUser = ({ user, onClose, setUpdateClient }) => {
  const dispatch = useDispatch();
  const roleMapping = {
    "Account Manager": "ACCOUNT_MANAGER",
    Accounting: "ACCOUNTING",
  };
  const businessInfoSchema = z.object({
    fullname: z
      .string()
      .min(3, "Full name is required!")
      .max(100, "Maximum 100 characters are allowed!"),
    phoneNumber: z
      .string()
      .min(3, "Phone Number must be at least 3 digits long")
      .max(20, "Phone Number should not exceed 20 digits!")
      .regex(
        /^\+\d+$/,
        "Phone Number must start with + and be followed by numeric digits!"
      ),
    email: z
      .string()
      .min(3, "Email is required!")
      .max(100, "Maximum 100 characters are allowed!")
      .email("Please provide a proper email address"),
    role: z.string().min(1, "Role is Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(businessInfoSchema),
    defaultValues: {
      fullname: user?.fullName,
      phoneNumber: user?.phoneno,
      email: user?.email,
      role: roleMapping[user?.role] || "",
    },
  });
  const staffId = user?.id;

  const onSubmit = (data) => {
    const payload = {
      fullName: data?.fullname,
      email: data?.email,
      role: data?.role,
      phoneNumber: data?.phoneNumber,
    };
    dispatch(
      updateStaffUser({
        staffId,
        payload,
      })
    )
      .then(() => {
        setUpdateClient((prev) => !prev);
        onClose();
      })
      .catch((error) => {
        console.error("Update profile failed:", error);
      });
    onClose();
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  return {
    onSubmit,
    handleSubmit,
    register,
    errors,
    isSubmitting,
    onCancel,
    isDirty,
    reset,
  };
};

export default useAdminEditUser;
