import * as React from "react";

function PhoneIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3727 12.3797L13.6922 10.7305L13.682 10.7258C13.491 10.6441 13.2825 10.6113 13.0756 10.6304C12.8687 10.6495 12.6698 10.7199 12.4969 10.8352C12.4765 10.8486 12.457 10.8632 12.4383 10.8789L10.5367 12.5C9.33204 11.9149 8.08829 10.6805 7.50313 9.49143L9.12657 7.56096C9.14219 7.54143 9.15704 7.5219 9.1711 7.50081C9.28394 7.32839 9.35239 7.13074 9.37039 6.92547C9.38838 6.7202 9.35534 6.51366 9.27423 6.32424V6.31487L7.62032 2.62815C7.51309 2.3807 7.3287 2.17457 7.09468 2.04052C6.86067 1.90648 6.58958 1.85171 6.32188 1.8844C5.26326 2.0237 4.29155 2.54359 3.58824 3.34697C2.88492 4.15035 2.49809 5.18228 2.50001 6.25002C2.50001 12.4531 7.54688 17.5 13.75 17.5C14.8177 17.5019 15.8497 17.1151 16.6531 16.4118C17.4564 15.7085 17.9763 14.7368 18.1156 13.6782C18.1484 13.4105 18.0937 13.1395 17.9598 12.9055C17.8259 12.6715 17.62 12.4871 17.3727 12.3797ZM13.75 16.25C11.0987 16.2471 8.55687 15.1926 6.68214 13.3179C4.8074 11.4432 3.7529 8.9013 3.75001 6.25002C3.74707 5.48713 4.02192 4.74924 4.52324 4.17417C5.02456 3.5991 5.71806 3.22617 6.47423 3.12502C6.47392 3.12814 6.47392 3.13128 6.47423 3.1344L8.11485 6.80627L6.50001 8.73909C6.48362 8.75795 6.46873 8.77806 6.45548 8.79924C6.33791 8.97965 6.26894 9.18736 6.25525 9.40226C6.24157 9.61716 6.28362 9.83194 6.37735 10.0258C7.08516 11.4735 8.54376 12.9211 10.007 13.6281C10.2023 13.721 10.4184 13.7616 10.634 13.746C10.8497 13.7304 11.0576 13.6591 11.2375 13.5391C11.2576 13.5256 11.2769 13.511 11.2953 13.4953L13.1945 11.875L16.8664 13.5196C16.8664 13.5196 16.8727 13.5196 16.875 13.5196C16.7751 14.2768 16.4027 14.9717 15.8275 15.4742C15.2524 15.9768 14.5138 16.2526 13.75 16.25Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export default PhoneIcon;
