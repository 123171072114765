import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AiAnalysis,
  CloudSystem,
  ContinuosDevelopment,
  OfflineOperations,
  PhoneBusiness,
} from "../../assets/images";
import useTabs from "../../hooks/useTabs";
import {
  ChartPieSlice,
  CloudArrowUp,
  CloudSlash,
  DeviceMobile,
  TrendUp,
} from "../common/Icons";



const TabsSection = () => {
  const { t } = useTranslation();
  const SecureCloudSystem = () => (
  
    <TabContentWrapper
      title= {t("landingpage.footer.links.secure")}
      description={t("landingpage.footer.links.securetext")}
      imageSrc={CloudSystem}
    />
  );
  
  const ContinuosOperations = () => (
    <TabContentWrapper
    title= {t("landingpage.footer.links.operationheading")}
    description={t("landingpage.footer.links.operationtext")}
    imageSrc={OfflineOperations}
    />
  );
  
  const AiDataAnalysis = () => (
    <TabContentWrapper
    title= {t("landingpage.footer.links.data")}
    description={t("landingpage.footer.links.datatext")}
      imageSrc={AiAnalysis}
    />
  );
  
  const ContinuosDevelopments = () => (
    <TabContentWrapper
    title= {t("landingpage.footer.links.developemnt")}
    description={t("landingpage.footer.links.developemnttext")}
      imageSrc={ContinuosDevelopment}
    />
  );
  
  const ViaPhone = () => (
    <TabContentWrapper
    title= {t("landingpage.footer.links.phoneheading")}
    description={t("landingpage.footer.links.phonetext")}
      imageSrc={PhoneBusiness}
    />
  );
  const tabs = [
    {
      title: t("landingpage.footer.links.secure"),

      content: SecureCloudSystem,
      icon: CloudArrowUp,
    },
    {
      title: t("landingpage.footer.links.operation"),
      content: ContinuosOperations,
      icon: CloudSlash,
    },
    {
      title: t("landingpage.footer.links.data"),
      content: AiDataAnalysis,
      icon: ChartPieSlice,
    },
    {
      title: t("landingpage.footer.links.developemnt"),
      content: ContinuosDevelopments,
      icon: TrendUp,
    },
    {
      title: t("landingpage.footer.links.phone"),
      content: ViaPhone,
      icon: DeviceMobile,
    },
  ];

  const { TabHeader, TabContent } = useTabs(0, tabs);
  const texts = [
    t("landingpage.footer.links.optimize"),
    t("landingpage.footer.links.grow"),
    t("landingpage.footer.links.technology"),
    t("landingpage.footer.links.sales"),
    t("landingpage.footer.links.inventory"),
  ];

  return (
    <div className="py-16 px-24 xl:px-29">
      <h2 className="text-text-primary font-semibold xs:text-5xl text-3xl xs:leading-wider text-center tab-section-heading">
        {t("landingpage.footer.links.personal")},
        <br /> {t("landingpage.footer.links.assistant")} <br />
        <AnimatedText texts={texts} />
      </h2>
      <div className="w-fit mx-auto md:mt-14 mt-10">
        <TabHeader padding="py-3 1xl:px-14 xl:px-8 px-4" showHeading={false} />
        <TabContent />
      </div>
    </div>
  );
};

export default TabsSection;

const TabContentWrapper = ({ title, description, imageSrc }) => (
  <div className="grid lg:grid-cols-2 grid-cols-1 mt-8 gap-5">
    <div className="flex flex-col justify-between">
      <div className="space-y-2 max-w-lg pt-8">
        <h3 className="text-text-primary font-semibold text-2x8">{title}</h3>
        <p className="text-text-secondary text-base">{description}</p>
      </div>
    </div>
    <div className="xl:min-h-72">
      <img
        src={imageSrc}
        alt={title}
        className="xs:h-auto h-64 mx-auto"
        loading="lazy"
      />
    </div>
  </div>
);

const AnimatedText = ({ texts }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const currentText = texts[index % texts.length];
    if (displayedText.length < currentText.length) {
      const timeoutId = setTimeout(
        () => setDisplayedText(currentText.slice(0, displayedText.length + 1)),
        100
      );
      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        setDisplayedText("");
        setIndex((index + 1) % texts.length);
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [displayedText, index, texts]);

  useEffect(() => {
    const blinkInterval = setInterval(() => setBlink((prev) => !prev), 500);
    return () => clearInterval(blinkInterval);
  }, []);

  return (
    <span className="inline-block">
      <motion.span
        className="gradient-text"
        animate={{ opacity: [0, 1], transition: { duration: 0.5 } }}
      >
        {displayedText}
      </motion.span>
      <motion.span style={{ visibility: blink ? "visible" : "hidden" }}>
        |
      </motion.span>
    </span>
  );
};
