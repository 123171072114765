import api from "../../services/api";

/**
 * Creates Users related API endpoints.
 * @returns {Object} Object containing user actions API methods.
 */

/**
 * get all users
 * @returns {Promise} A Promise that resolves to the profile response.
 */
const getAllUsers = async (payload) => {
  const response = await api().post(`user`, payload);
  return response;
};

/**
 * Create Users related API endpoints.
 * @returns {Object} Object containing user actions API methods.
 */

/**
 * get all users
 * @returns {Promise} A Promise that resolves to the profile response.
 */
const loginPosUser = async (data) => {
  const response = await api().post(`pos-user/signIn-with-email`, data);
  return response;
};

/**
 * update user information using userId
 * @param {id} userId - userId for user information.
 * @returns {Promise} A Promise that resolves to the signup response.
 */
const updateUserDetail = async (userId, payload) => {
  const response = await api().put(`user/${userId}`, payload);
  return response;
};

/**
 * Object that encapsulates functions for user detail operations.
 */
const userDetailService = {
  getAllUsers, // fuction for getting all users
  updateUserDetail, // fuction to update user detail
  loginPosUser,
};

export default userDetailService; // export user detail service

// sample usage

// import userDetailService from "../../path/userDetailService";

//  getAllUsers
// userDetailService.getAllUsers()
//   .then((profileResponse) => {
//     console.log("User Profile Information:", profileResponse.data);
//   })
//   .catch((error) => {
//     console.error("Error fetching user profile:", error);
//   });
