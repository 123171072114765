import React from "react";
import ClientDetail from "../components/Admin/AdminDashboard/ManageClients/Clients/DetailOfClients/ClientDetail";
import AdminLayout from "../components/Admin/AdminDashboard/SuperAdminLayout/AdminLayout/Layout";
import AdminLogin from "../components/Admin/AdminLogin/AdminLogin";
import ClientDashboardLayout from "../components/Client/Dashboard/Layout/Layout";

import ConfirmEmail from "../components/ConfirmEmail/ConfirmEmail";
import PosDrawer from "../components/Pos/PosDrawer/PosDrawer";
import PosHome from "../components/Pos/PosHome";
import PosLoginEmail from "../components/Pos/PosLogin/PosLoginEmail/PosLoginEmail";
import PosStores from "../components/Pos/PosLogin/PosStores/PosStores";
import PosOrder from "../components/Pos/PosOrder/PosOrder";
import PosTable from "../components/Pos/PosTable/PosTable";
import { BusinessProfile } from "../components/Signup/ClientProfile/BusinessProfile/BusinessProfile";
import { CreateAccount } from "../components/Signup/ClientProfile/CompleteProfile/CompleteProfile";
import { CreatePassword } from "../components/Signup/ClientProfile/CreatePassword/CreatePassword";
import { SetPlan } from "../components/Signup/ClientProfile/SetPlan/SetPlan";
import Signup from "../components/Signup/Signup";
import NotFoundPage from "../components/common/components/ErrorPage/ErrorPage";
import PosLayout from "../layout";
import AdminDashboard from "../pages/AdminDashboard";
import Login from "../pages/auth/Login";
import PosLogin from "../pages/auth/PosLogin";
import ForgetPassword from "../pages/auth/forget-password/forget-password";
import ResetPassword from "../pages/auth/reset-password/reset-password";

import AdminSales from "../components/Admin/AdminDashboard/AdminSales/AdminSales";
import ClientSalesDetail from "../components/Admin/AdminDashboard/AdminSales/ClientSalesDetail/ClientSalesDetail";
import Clients from "../components/Admin/AdminDashboard/ManageClients/Clients/Clients";
import Subscription from "../components/Admin/AdminDashboard/Subscription/Subscribtion";
import SubscriptionClientDetail from "../components/Admin/AdminDashboard/Subscription/SubscriptionClientDetail/SubscribtionClientDetail";
import ActivateAccount from "../components/Admin/AdminLogin/StaffUser/ActivateAccount";
import SetStaffPassword from "../components/Admin/AdminLogin/StaffUser/SetStaffPassword/SetStaffPassword";
import { LandingPageLayout } from "../components/LandingPage";
import FinishScreen from "../components/Signup/ClientProfile/FinishScreen/FinishScreen";
import ContactUs from "../pages/ContactUs";
import Faqs from "../pages/Faqs";
import Features from "../pages/Features";
import HomePage from "../pages/HomePage";
import Pricing from "../pages/Pricing";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Terms from "../pages/Terms";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import UnautherizedPage from "../components/common/components/UnautherizPage/UnautherizePage";

const Dashboard = React.lazy(() =>
  import("../components/Client/Dashboard/ClientDashboard/Dashboard")
);
const CategoriesList = React.lazy(() =>
  import("../components/Client/Dashboard/Directory/Categories/CategoriesList")
);
const CustomersList = React.lazy(() =>
  import("../components/Client/Dashboard/Directory/Customers/CustomersList")
);
const ItemsList = React.lazy(() =>
  import("../components/Client/Dashboard/Directory/Items/ItemsList")
);
const SuppliersList = React.lazy(() =>
  import("../components/Client/Dashboard/Directory/Suppliers/SuppliersList")
);
const InventoryTransactions = React.lazy(() =>
  import(
    "../components/Client/Dashboard/Inventory/InventoryTransactions/InventoryTransactions"
  )
);
const InventoryTransferList = React.lazy(() =>
  import(
    "../components/Client/Dashboard/Inventory/InventoryTransfer/InventoryTransferList"
  )
);
const StocktakingList = React.lazy(() =>
  import("../components/Client/Dashboard/Inventory/Stocktaking/StocktakingList")
);
const ExpenseInvoiceList = React.lazy(() =>
  import(
    "../components/Client/Dashboard/Invoices/ExpenseInvoice/ExpenseInvoiceList"
  )
);
const PurchaseInvoicesList = React.lazy(() =>
  import(
    "../components/Client/Dashboard/Invoices/PurchaseInvoice/PurchaseInvoiceList"
  )
);
const SaleInvoicesList = React.lazy(() =>
  import(
    "../components/Client/Dashboard/Invoices/SaleInvoices/SaleInvoicesList"
  )
);
const PaymentsList = React.lazy(() =>
  import(
    "../components/Client/Dashboard/Management/Payments/PaymentsList/PaymentsList"
  )
);

const PromotionsList = React.lazy(() =>
  import(
    "../components/Client/Dashboard/Management/Promotions/PromotionsList/PromotionsList"
  )
);
const Settings = React.lazy(() =>
  import("../components/Client/Dashboard/Management/Settings/index")
);
const StoresList = React.lazy(() =>
  import(
    "../components/Client/Dashboard/Management/Stores/StoresList/StoresList"
  )
);
const UsersList = React.lazy(() =>
  import("../components/Client/Dashboard/Management/Users/UsersList/UsersList")
);

const Expenses = React.lazy(() =>
  import("../components/Client/Dashboard/Reports/Expenses/Expenses")
);
const Inventory = React.lazy(() =>
  import("../components/Client/Dashboard/Reports/Inventory/Inventory")
);
const ProfitLoss = React.lazy(() =>
  import("../components/Client/Dashboard/Reports/ProfitLoss/ProfitLoss")
);
const Purchase = React.lazy(() =>
  import("../components/Client/Dashboard/Reports/Purchase/Purchase")
);
const SalesReports = React.lazy(() =>
  import("../components/Client/Dashboard/Reports/Sales/Sales")
);
const Taxes = React.lazy(() =>
  import("../components/Client/Dashboard/Reports/Taxes/Taxes")
);

/**
 * Array of routes for the dashboard section.
 * Each route object contains information about the path and corresponding component.
 */
export const routes = [
  // public
  { path: "unauthorized", element: <UnautherizedPage /> },
  // login
  {
    path: "login",
    element: <PublicRoute component={Login} />,
  },
  {
    path: "/pos/login",
    element: <PublicRoute component={PosLoginEmail} />,
  },
  {
    path: "/admin/login",
    element: <PublicRoute component={AdminLogin} />,
  },
  {
    path: "pos/stores",
    element: (
      <ProtectedRoute
        roles={["CASHIER", "SALES MANAGER", "SYSTEM MANAGER"]}
        component={PosStores}
      />
    ),
  },
  {
    path: "/pos",
    element: <PublicRoute component={PosLogin} />,
  },

  {
    path: "forget-password",
    element: <PublicRoute component={ForgetPassword} />,
  },
  {
    path: "reset-password",
    element: <PublicRoute component={ResetPassword} />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },

  // landing page

  {
    path: "/",
    element: <PublicRoute component={LandingPageLayout} />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "faqs",
        element: <Faqs />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "features",
        element: <Features />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
    ],
  },

  {
    path: "activate-account",
    element: <ActivateAccount />,
  },
  {
    path: "set-staff-password",
    element: <SetStaffPassword />,
  },

  //   signup flow
  {
    path: "signup",
    element: <PublicRoute component={Signup} />,
  },

  {
    path: "confirm-email",
    element: <PublicRoute component={ConfirmEmail} />,
  },
  {
    path: "create-password",
    element: <PublicRoute component={CreatePassword} />,
  },
  {
    path: "create-account",
    element: <PublicRoute component={CreateAccount} />,
    // element: <ProtectedRoute roles={["CLIENT"]} component={CreateAccount} />,
  },
  {
    path: "business-profile",
    // element: <ProtectedRoute roles={["CLIENT"]} component={BusinessProfile} />,
    element: <PublicRoute component={BusinessProfile} />,
  },
  {
    path: "set-plan",
    // element: <ProtectedRoute roles={["CLIENT"]} component={SetPlan} />,
    element: <PublicRoute component={SetPlan} />,
  },
  {
    path: "thank-you",
    element: <FinishScreen />,
  },
  //    client
  {
    path: "/",
    element: (
      <ProtectedRoute
        roles={["CLIENT", "CASHIER", "SALES MANAGER", "SYSTEM MANAGER"]}
        component={ClientDashboardLayout}
      />
    ),
    children: [
      {
        path: "/dashboard",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={Dashboard}
          />
        ),
      },
      {
        path: "invoices/sales",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "CASHIER", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={SaleInvoicesList}
          />
        ),
      },

      {
        path: "invoices/purchase",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={PurchaseInvoicesList}
          />
        ),
      },
      {
        path: "invoices/expense",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={ExpenseInvoiceList}
          />
        ),
      },
      {
        path: "inventory/stocktaking",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "CASHIER", "SYSTEM MANAGER"]}
            component={StocktakingList}
          />
        ),
      },
      {
        path: "inventory/inventory-transfer",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "CASHIER", "SYSTEM MANAGER"]}
            component={InventoryTransferList}
          />
        ),
      },
      {
        path: "inventory/inventory-transaction",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "CASHIER", "SYSTEM MANAGER"]}
            component={InventoryTransactions}
          />
        ),
      },
      {
        path: "management/settings",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SYSTEM MANAGER"]}
            component={Settings}
          />
        ),
      },
      {
        path: "management/promotions",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={PromotionsList}
          />
        ),
      },
      {
        path: "management/users",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SYSTEM MANAGER"]}
            component={UsersList}
          />
        ),
      },
      {
        path: "management/stores",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SYSTEM MANAGER"]}
            component={StoresList}
          />
        ),
      },
      {
        path: "management/payments",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SYSTEM MANAGER"]}
            component={PaymentsList}
          />
        ),
      },
      {
        path: "directory/customers",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "CASHIER", "SYSTEM MANAGER"]}
            component={CustomersList}
          />
        ),
      },
      {
        path: "directory/supplier",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SYSTEM MANAGER"]}
            component={SuppliersList}
          />
        ),
      },
      {
        path: "directory/categories",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SYSTEM MANAGER"]}
            component={CategoriesList}
          />
        ),
      },
      {
        path: "directory/items",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SYSTEM MANAGER"]}
            component={ItemsList}
          />
        ),
      },
      {
        path: "report/sales",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={SalesReports}
          />
        ),
      },
      {
        path: "report/purchase",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={Purchase}
          />
        ),
      },
      {
        path: "report/profitloss",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={ProfitLoss}
          />
        ),
      },
      {
        path: "report/taxes",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={Taxes}
          />
        ),
      },
      {
        path: "report/expense",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={Expenses}
          />
        ),
      },
      {
        path: "report/inventory",
        element: (
          <ProtectedRoute
            roles={["CLIENT", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={Inventory}
          />
        ),
      },
    ],
  },
  //    admin
  {
    path: "/",
    element: <AdminLayout />,

    children: [
      {
        path: "admin/dashboard",
        element: (
          <ProtectedRoute
            roles={["SUPER_ADMIN", "ACCOUNT_MANAGER", "ACCOUNTING"]}
            component={AdminDashboard}
          />
        ),
      },
      {
        path: "admin/sales",
        element: (
          <ProtectedRoute
            roles={["SUPER_ADMIN", "ACCOUNT_MANAGER", "ACCOUNTING"]}
            component={AdminSales}
          />
        ),
      },
      {
        path: "admin/clients",
        element: (
          <ProtectedRoute
            roles={["SUPER_ADMIN", "ACCOUNT_MANAGER", "ACCOUNTING"]}
            component={Clients}
          />
        ),
      },
      {
        path: "admin/subscriptions",
        element: (
          <ProtectedRoute
            roles={["SUPER_ADMIN", "ACCOUNT_MANAGER", "ACCOUNTING"]}
            component={Subscription}
          />
        ),
      },
      {
        path: "admin/subscriptions/details/:id",
        element: (
          <ProtectedRoute
            roles={["SUPER_ADMIN", "ACCOUNT_MANAGER", "ACCOUNTING"]}
            component={SubscriptionClientDetail}
          />
        ),
      },
      {
        path: "admin/clients/details/:id",
        element: (
          <ProtectedRoute
            roles={["SUPER_ADMIN", "ACCOUNT_MANAGER", "ACCOUNTING"]}
            component={ClientDetail}
          />
        ),
      },
      {
        path: "admin/sales/details/:id",
        element: (
          <ProtectedRoute
            roles={["SUPER_ADMIN", "ACCOUNT_MANAGER", "ACCOUNTING"]}
            component={ClientSalesDetail}
          />
        ),
      },
      {
        path: "activate-account",
        element: <ActivateAccount />,
      },
      {
        path: "set-staff-password",
        element: <SetStaffPassword />,
      },
    ],
  },
  //    pos

  {
    path: "/",
    element: (
      <ProtectedRoute
        roles={["CASHIER", "SALES MANAGER", "SYSTEM MANAGER"]}
        component={PosLayout}
      />
    ),
    children: [
      {
        path: "pos/dashboard",
        element: (
          <ProtectedRoute
            roles={["CASHIER", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={PosHome}
          />
        ),
      },
      {
        path: "pos/order",
        element: (
          <ProtectedRoute
            roles={["CASHIER", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={PosOrder}
          />
        ),
      },
      {
        path: "pos/table",
        element: (
          <ProtectedRoute
            roles={["CASHIER", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={PosTable}
          />
        ),
      },
      {
        path: "pos/drawer",
        element: (
          <ProtectedRoute
            roles={["CASHIER", "SALES MANAGER", "SYSTEM MANAGER"]}
            component={PosDrawer}
          />
        ),
      },
    ],
  },
];
