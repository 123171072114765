import { useRef, useState } from "react";

function useAccordion() {
  const AccordionRefs = useRef([]);
  const [currentAccordion, setCurrentAccordion] = useState(0);

  function closeCurrent(e) {
    const button = e.target.closest("button");
    const buttonParent = button?.parentElement?.parentElement;
    const parent = buttonParent?.parentElement;
    const index = Array.prototype.indexOf.call(parent?.children, buttonParent);

    for (let i = 0; i < AccordionRefs.current.length; i++) {
      if (
        AccordionRefs.current[i].querySelector("button") &&
        AccordionRefs.current[i]
          .querySelector("button")
          .getAttribute("aria-expanded") === "true" &&
        currentAccordion !== index
      ) {
        AccordionRefs.current[i].querySelector("button").click();
      }
    }
    setCurrentAccordion(index);
  }

  return { AccordionRefs, closeCurrent };
}

export default useAccordion;
