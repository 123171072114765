import React from "react";
import AddTable from "./AddTable/AddTable";
import DeleteTable from "./DeleteTable/DeleteTable";
import {
  WhiteIndicator,
  GreenIndicator,
  TrashIcon,
  NoTable,
} from "../../common/Icons";
import CustomButton from "../../common/components/Button/Button";
import usePosTable from "./usePosTable";
import Loader from "../../common/components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { Plus } from "../../common/Icons/fontawesome";

const PosTable = () => {
  const {
    isAddModalOpen,
    setIsAddModalOpen,
    isConfirmModalOpen,
    handleAddTable,
    handleDeleteTable,
    confirmDelete,
    tableStyles,
    tables,
    setIsConfirmModalOpen,
    posTablesListLoading,
    reservedCount,
    availableCount,
    draggingIndex,
    handleDrop,
    handleDragOver,
    handleDragStart,
    showSaveCancel,
    handleSaveChanges,
    handleCancel,
    handleResetTables,
  } = usePosTable();
  const { t } = useTranslation();

  const renderActionButton = () => {
    if (showSaveCancel) {
      return (
        <>
          <CustomButton
            text={t("pos_dashboard.save_changes")}
            width="w-fit"
            textColor="text-text-primary"
            bgColor="bg-white"
            padding="py-2 px-2 ml-4"
            border="border"
            className="mr-1 hover:bg-gray-200"
            onClick={handleSaveChanges}
          />
          <CustomButton
            text={t("pos_dashboard.cancel")}
            width="w-fit"
            textColor="text-text-primary"
            bgColor="bg-white"
            padding="py-2.5 px-2 ml-4"
            border="border"
            className="mr-6 hover:bg-gray-200"
            onClick={handleCancel}
          />
        </>
      );
    } else {
      return (
        <>
          <CustomButton
            text={t("pos_dashboard.add_new_table")}
            width="w-fit"
            textColor="text-primary"
            border="border"
            className="hover:bg-gray-200"
            icon={<Plus color="black" />}
            onClick={() => setIsAddModalOpen(true)}
          />
          <CustomButton
            text={t("pos_dashboard.reset_table")}
            width="w-fit"
            textColor="text-primary"
            border="border"
            className="hover:bg-gray-200"
            bgColor="bg-white"
            onClick={handleResetTables}
          />
        </>
      );
    }
  };

  if (posTablesListLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="max-h-screen overflow-auto">
      {/* Button to open add table modal */}
      <div className="flex flex-row justify-between items-center bg-white py-2">
        {/* Left side - indicators */}
        <div className="flex justify-between items-center gap-1 mx-4 pl-4">
          <GreenIndicator />{" "}
          <span className="text-sm font-normal">
            {t("pos_dashboard.available")} ({availableCount})
          </span>
          <WhiteIndicator />{" "}
          <span className="text-sm font-normal">
            {t("pos_dashboard.reserved")} ({reservedCount})
          </span>
        </div>
        <div className="flex justify-between items-center gap-1 mt-2">
          {renderActionButton()}
        </div>
      </div>

      {/* Render tables */}
      {tables?.length > 0 ? (
        <div className="flex flex-wrap mt-4 px-24 py-8 gap-8">
          {tables?.map((table, index) => (
            <div
              key={table.id}
              // drag and drop table
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDrop={(e) => handleDrop(e, index)}
              //
              className={`relative p-[46px] gap-4 m-2 bg-white shadow-md border-2 ${
                draggingIndex === index ? "table-item-dragging" : "table-item"
              } ${table.shape === "circle" ? "rounded-full" : ""}`}
              style={{
                ...tableStyles[table?.shape],
                ...(table.isReserved && { backgroundColor: "#2BE3B7" }),
              }}
            >
              <span className="font-normal">{`${table.position}`}</span>
              {!table.isReserved && (
                <div
                  onClick={() => handleDeleteTable(table.id)}
                  className="absolute top-0 right-0 my-4 mx-3 text-red-500 hover:text-red-700 cursor-pointer"
                >
                  <TrashIcon />
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-[600px]">
          <span className="flex justify-center items-center">
            <NoTable />
          </span>
          <div className="text-center mt-4 text-xl font-medium">
            {t("pos_dashboard.no_table_added")}
          </div>
          <div className="text-center text-text-secondary mt-2 text-sm font-normal">
            {t("pos_dashboard.add_table_text")}
          </div>
          <div className="flex justify-center items-center mt-4">
            <CustomButton
              text={t("pos_dashboard.add_new_table")}
              width="w-fit"
              textColor="text-white"
              bgColor="!bg-blue-600"
              padding="py-2 px-4"
              border="border"
              hoverBgColor="bg-gray-200"
              icon={<Plus color="white" />}
              onClick={() => setIsAddModalOpen(true)}
              className="hover:text-gray-200"
            />
          </div>
        </div>
      )}

      {/* Add Table Modal */}
      <AddTable
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAddTable={handleAddTable}
      />

      {/* Confirm Deletion Modal */}
      <DeleteTable
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default PosTable;
