/**
 * Manages state for client dashboard api's.
 * Defines initial state for each client dashboard api action.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createClientDashboardApi from "./ClientDashboardService";

/**
 * Initial state structure defining various properties related to client dashboard api's processes.
 * Each property represents a specific client dashboard api's action/status with its associated data, error, success, loading, and message.
 * Properties such as getClientSalesCost, getClientNetIncome
 * store data, error, success, loading, and message status for corresponding dashboard api's actions.
 */
const initialState = {
  getClientSalesCost: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getClientNetIncome: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getClientExpense: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getClientSalesByItems: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getClientSalesByCategories: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getClientSalesByStores: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getClientSalesByCachier: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the client dashboard api's process.
 * These action types are prefixed with the 'client dashboard api's' base path for better organization.
 */
const BASE = "dashboard";

export const ActionTypes = {
  CLIENT_SALES_COST: `${BASE}/client-sale-cost`, // Action type for getting sales and cost of client
  CLIENT_NET_INCOME: `${BASE}/client-net-income`, // Action type for geting net income of client
  CLIENT_EXPENSE: `${BASE}/client-expense`, // Action type for geting expense of client
  CLIENT_SALES_BY_ITEM: `${BASE}/client-sales-by-items`, // Action type for geting client sales by item
  CLIENT_SALES_BY_CATEGORIES: `${BASE}/client-sales-by-categories`, // Action type for geting client sales by categories
  CLIENT_SALES_BY_STORES: `${BASE}/client-sales-by-stores`, // Action type for geting client sales by stores
  CLIENT_SALES_BY_CASHIER: `${BASE}/client-sales-by-cashier`, // Action type for geting client sales by cashier
};

// Creating an instance of the customers service with a base URL 'dashboard'
const clientDashboardService = createClientDashboardApi("dashboard");

/**
 * Initiates the getClientSalesCost process for a client.
 *  @param {object} payload
        {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
        }
 * @param {function} successCallBack - Callback function upon successful getClientSalesCost.
 */
export const getClientSalesCost = createAsyncThunk(
  ActionTypes.CLIENT_SALES_COST,

  async ({ payload }, thunkAPI) => {
    const response = await clientDashboardService.getClientSalesCost(payload);
    return response?.data?.data;
  }
);
/**
 * Initiates the getClientNetIncome process for a client.
 *  @param {object} payload
        {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
        }
 * @param {function} successCallBack - Callback function upon successful getClientNetIncome.
 */
export const getClientNetIncome = createAsyncThunk(
  ActionTypes.CLIENT_NET_INCOME,

  async ({ payload }, thunkAPI) => {
    const response = await clientDashboardService.getClientNetIncome(payload);
    return response?.data?.data;
  }
);
/**
 * Initiates the getClientExpense process for a client.
 *  @param {object} payload
        {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
        }
 * @param {function} successCallBack - Callback function upon successful getClientExpense.
 */
export const getClientExpense = createAsyncThunk(
  ActionTypes.CLIENT_EXPENSE,

  async ({ payload }, thunkAPI) => {
    const response = await clientDashboardService.getClientExpense(payload);
    return response?.data?.data;
  }
);
/**
 * Initiates the getClientSalesByItems process for a client.
 *  @param {object} payload
        {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
        }
 * @param {function} successCallBack - Callback function upon successful getClientSalesByItems.
 */
export const getClientSalesByItems = createAsyncThunk(
  ActionTypes.CLIENT_SALES_BY_ITEM,

  async ({ payload }, thunkAPI) => {
    const response = await clientDashboardService.getClientSalesByItems(
      payload
    );
    return response?.data?.data;
  }
);
/**
 * Initiates the getClientSalesByCategories process for a client.
 *  @param {object} payload
        {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
            "id", 1,
        }
 * @param {function} successCallBack - Callback function upon successful getClientSalesByCategories.
 */
export const getClientSalesByCategories = createAsyncThunk(
  ActionTypes.CLIENT_SALES_BY_CATEGORIES,

  async ({ payload }, thunkAPI) => {
    const response = await clientDashboardService.getClientSalesByCategories(
      payload
    );
    return response?.data?.data;
  }
);
/**
 * Initiates the getClientSalesByStores process for a client.
 *  @param {object} payload
        {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
            "id", 1,
        }
 * @param {function} successCallBack - Callback function upon successful getClientSalesByStores.
 */
export const getClientSalesByStores = createAsyncThunk(
  ActionTypes.CLIENT_SALES_BY_STORES,

  async ({ payload }, thunkAPI) => {
    const response = await clientDashboardService.getClientSalesByStores(
      payload
    );
    return response?.data?.data;
  }
);
/**
 * Initiates the getClientSalesByCachier process for a client.
 *  @param {object} payload
        {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
            "id", 1,
        }
 * @param {function} successCallBack - Callback function upon successful getClientSalesByCachier.
 */
export const getClientSalesByCachier = createAsyncThunk(
  ActionTypes.CLIENT_SALES_BY_CASHIER,

  async ({ payload }, thunkAPI) => {
    const response = await clientDashboardService.getClientSalesByCachier(
      payload
    );
    return response?.data?.data;
  }
);

/**
 * Creates a slice for clientDashboard related state management.
 * - `name`: Name of the slice (clientDashboard)
 * - `initialState`: Initial state defining properties for various clientDashboard actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getClientSalesCost, getClientNetIncome etc).
 */
export const clientDashboardSlice = createSlice({
  name: "clientDashboard",
  initialState,

  reducers: {
    reset: (state) => {
      state.getClientSalesCost = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getClientNetIncome = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getClientExpense = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getClientSalesByItems = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getClientSalesByCategories = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getClientSalesByStores = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getClientSalesByCachier = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getClientSalesCost action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getClientSalesCost.pending, (state) => {
        state.getClientSalesCost.isLoading = true;
        state.getClientSalesCost.message = "";
        state.getClientSalesCost.isError = false;
        state.getClientSalesCost.isSuccess = false;
        state.getClientSalesCost.data = null;
      })
      /**
       * Updates the state when getClientSalesCost action is fulfilled/successful.
       * Updates loading and success flags and sets getClientSalesCost data with the payload.
       */
      .addCase(getClientSalesCost.fulfilled, (state, action) => {
        state.getClientSalesCost.isLoading = false;
        state.getClientSalesCost.isSuccess = true;
        state.getClientSalesCost.data = action.payload;
      })
      /**
       * Updates the state when getClientSalesCost action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getClientSalesCost.rejected, (state, action) => {
        state.getClientSalesCost.message = action?.payload?.message;
        state.getClientSalesCost.isLoading = false;
        state.getClientSalesCost.isError = true;
        state.getClientSalesCost.data = null;
      })
      /**
       * Updates the state while the getClientNetIncome action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getClientNetIncome.pending, (state) => {
        state.getClientNetIncome.isLoading = true;
        state.getClientNetIncome.message = "";
        state.getClientNetIncome.isError = false;
        state.getClientNetIncome.isSuccess = false;
        state.getClientNetIncome.data = null;
      })
      /**
       * Updates the state when getClientNetIncome action is fulfilled/successful.
       * Updates loading and success flags and sets getClientNetIncome data with the payload.
       */
      .addCase(getClientNetIncome.fulfilled, (state, action) => {
        state.getClientNetIncome.isLoading = false;
        state.getClientNetIncome.isSuccess = true;
        state.getClientNetIncome.data = action.payload;
      })
      /**
       * Updates the state when getClientNetIncome action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getClientNetIncome.rejected, (state, action) => {
        state.getClientNetIncome.message = action.payload?.message;
        state.getClientNetIncome.isLoading = false;
        state.getClientNetIncome.isError = true;
        state.getClientNetIncome.data = null;
      })
      /**
       * Updates the state while the getClientExpense action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getClientExpense.pending, (state) => {
        state.getClientExpense.isLoading = true;
        state.getClientExpense.message = "";
        state.getClientExpense.isError = false;
        state.getClientExpense.isSuccess = false;
        state.getClientExpense.data = null;
      })
      /**
       * Updates the state when getClientExpense action is fulfilled/successful.
       * Updates loading and success flags and sets getClientExpense data with the payload.
       */
      .addCase(getClientExpense.fulfilled, (state, action) => {
        state.getClientExpense.isLoading = false;
        state.getClientExpense.isSuccess = true;
        state.getClientExpense.data = action.payload;
      })
      /**
       * Updates the state when getClientExpense action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getClientExpense.rejected, (state, action) => {
        state.getClientExpense.message = action.payload?.message;
        state.getClientExpense.isLoading = false;
        state.getClientExpense.isError = true;
        state.getClientExpense.data = null;
      })
      /**
       * Updates the state while the getClientSalesByItems action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getClientSalesByItems.pending, (state) => {
        state.getClientSalesByItems.isLoading = true;
        state.getClientSalesByItems.message = "";
        state.getClientSalesByItems.isError = false;
        state.getClientSalesByItems.isSuccess = false;
        state.getClientSalesByItems.data = null;
      })
      /**
       * Updates the state when getClientSalesByItems action is fulfilled/successful.
       * Updates loading and success flags and sets getClientSalesByItems data with the payload.
       */
      .addCase(getClientSalesByItems.fulfilled, (state, action) => {
        state.getClientSalesByItems.isLoading = false;
        state.getClientSalesByItems.isSuccess = true;
        state.getClientSalesByItems.data = action.payload;
      })
      /**
       * Updates the state when getClientSalesByItems action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getClientSalesByItems.rejected, (state, action) => {
        state.getClientSalesByItems.message = action.payload?.message;
        state.getClientSalesByItems.isLoading = false;
        state.getClientSalesByItems.isError = true;
        state.getClientSalesByItems.data = null;
      })
      /**
       * Updates the state while the getClientSalesByCategories action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getClientSalesByCategories.pending, (state) => {
        state.getClientSalesByCategories.isLoading = true;
        state.getClientSalesByCategories.message = "";
        state.getClientSalesByCategories.isError = false;
        state.getClientSalesByCategories.isSuccess = false;
        state.getClientSalesByCategories.data = null;
      })
      /**
       * Updates the state when getClientSalesByCategories action is fulfilled/successful.
       * Updates loading and success flags and sets getClientSalesByCategories data with the payload.
       */
      .addCase(getClientSalesByCategories.fulfilled, (state, action) => {
        state.getClientSalesByCategories.isLoading = false;
        state.getClientSalesByCategories.isSuccess = true;
        state.getClientSalesByCategories.data = action.payload;
      })
      /**
       * Updates the state when getClientSalesByCategories action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getClientSalesByCategories.rejected, (state, action) => {
        state.getClientSalesByCategories.message = action.payload?.message;
        state.getClientSalesByCategories.isLoading = false;
        state.getClientSalesByCategories.isError = true;
        state.getClientSalesByCategories.data = null;
      })
      /**
       * Updates the state while the getClientSalesByStores action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getClientSalesByStores.pending, (state) => {
        state.getClientSalesByStores.isLoading = true;
        state.getClientSalesByStores.message = "";
        state.getClientSalesByStores.isError = false;
        state.getClientSalesByStores.isSuccess = false;
        state.getClientSalesByStores.data = null;
      })
      /**
       * Updates the state when getClientSalesByStores action is fulfilled/successful.
       * Updates loading and success flags and sets getClientSalesByStores data with the payload.
       */
      .addCase(getClientSalesByStores.fulfilled, (state, action) => {
        state.getClientSalesByStores.isLoading = false;
        state.getClientSalesByStores.isSuccess = true;
        state.getClientSalesByStores.data = action.payload;
      })
      /**
       * Updates the state when getClientSalesByStores action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getClientSalesByStores.rejected, (state, action) => {
        state.getClientSalesByStores.message = action.payload?.message;
        state.getClientSalesByStores.isLoading = false;
        state.getClientSalesByStores.isError = true;
        state.getClientSalesByStores.data = null;
      })
      /**
       * Updates the state while the getClientSalesByCachier action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getClientSalesByCachier.pending, (state) => {
        state.getClientSalesByCachier.isLoading = true;
        state.getClientSalesByCachier.message = "";
        state.getClientSalesByCachier.isError = false;
        state.getClientSalesByCachier.isSuccess = false;
        state.getClientSalesByCachier.data = null;
      })
      /**
       * Updates the state when getClientSalesByCachier action is fulfilled/successful.
       * Updates loading and success flags and sets getClientSalesByCachier data with the payload.
       */
      .addCase(getClientSalesByCachier.fulfilled, (state, action) => {
        state.getClientSalesByCachier.isLoading = false;
        state.getClientSalesByCachier.isSuccess = true;
        state.getClientSalesByCachier.data = action.payload;
      })
      /**
       * Updates the state when getClientSalesByCachier action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getClientSalesByCachier.rejected, (state, action) => {
        state.getClientSalesByCachier.message = action.payload?.message;
        state.getClientSalesByCachier.isLoading = false;
        state.getClientSalesByCachier.isError = true;
        state.getClientSalesByCachier.data = null;
      });
  },
});

/**
 * Destructures the reset action from the clientDashboardSlice actions.
 * - `reset`: Action function to reset the customers related state.
 */
export const { reset } = clientDashboardSlice.actions;

/**
 * Exports the default reducer generated by clientDashboardSlice.
 * This reducer handles state updates for customers related actions.
 */
export default clientDashboardSlice.reducer;
