import React from "react";
import { useCheckout } from "./useCheckout";
import { useTranslation } from "react-i18next";
import FullPay from "./FullPay/FullPay";
import SplitEqually from "./SplitEqually/SplitEqually";
import SplitByItem from "./SplitByItem/SplitByItem";

function Checkout({ setIsModalOpen, setCheckOutVisible, setOrderCheckoutPaid, setIsCheckoutModalOpen }) {
  const {
    setActiveSplitType,
    activeTabStyle,
    inactiveTabStyle,
    activeSplitType,
    orderCheckoutData,
  } = useCheckout();

  const { t } = useTranslation();
  const paidType = orderCheckoutData?.checkout?.checkoutType;
  const orderType = orderCheckoutData?.orderType;

  const renderSplitComponent = () => {
    if (orderType === "TAKEAWAY") {
      return (
        <FullPay
          setIsModalOpen={setIsModalOpen}
          setCheckOutVisible={setCheckOutVisible}
          setOrderCheckoutPaid={setOrderCheckoutPaid}
          setIsCheckoutModalOpen={setIsCheckoutModalOpen}
        />
      );
    } else if (orderCheckoutData && paidType) {
      switch (paidType) {
        case "FULL_PAY":
          return (
            <FullPay
              setIsModalOpen={setIsModalOpen}
              setCheckOutVisible={setCheckOutVisible}
              setOrderCheckoutPaid={setOrderCheckoutPaid}
              setIsCheckoutModalOpen={setIsCheckoutModalOpen}
            />
          );
        case "SPLIT_BY_ITEM":
          return (
            <SplitByItem
              setIsModalOpen={setIsModalOpen}
              setCheckOutVisible={setCheckOutVisible}
              setOrderCheckoutPaid={setOrderCheckoutPaid}
              setIsCheckoutModalOpen={setIsCheckoutModalOpen}
            />
          );
        case "SPLIT_EQUALLY":
          return (
            <SplitEqually
              setIsModalOpen={setIsModalOpen}
              setCheckOutVisible={setCheckOutVisible}
              setOrderCheckoutPaid={setOrderCheckoutPaid}
              setIsCheckoutModalOpen={setIsCheckoutModalOpen}
            />
          );
        default:
          return null;
      }
    } else {
      switch (activeSplitType) {
        case "full":
          return (
            <FullPay
              setIsModalOpen={setIsModalOpen}
              setCheckOutVisible={setCheckOutVisible}
              setOrderCheckoutPaid={setOrderCheckoutPaid}
              setIsCheckoutModalOpen={setIsCheckoutModalOpen}
            />
          );
        case "item":
          return (
            <SplitByItem
              setIsModalOpen={setIsModalOpen}
              setCheckOutVisible={setCheckOutVisible}
              setOrderCheckoutPaid={setOrderCheckoutPaid}
              setIsCheckoutModalOpen={setIsCheckoutModalOpen}
            />
          );
        case "equal":
          return (
            <SplitEqually
              setIsModalOpen={setIsModalOpen}
              setCheckOutVisible={setCheckOutVisible}
              setOrderCheckoutPaid={setOrderCheckoutPaid}
              setIsCheckoutModalOpen={setIsCheckoutModalOpen}
            />
          );
        default:
          return null;
      }
    }
  };

  return (
    <div className=" p-3">
      <div className="flex bg-gray-100 px-1 rounded-lg text-sm font-medium">
        {orderType === "TAKEAWAY" ? (
          <button
            className={
              activeSplitType === "full" ? activeTabStyle : inactiveTabStyle
            }
            onClick={() => setActiveSplitType("full")}
          >
            {t("pos_dashboard.full_pay")}
          </button>
        ) : orderCheckoutData && paidType ? (
          <button
            className={
              activeSplitType === paidType.toLowerCase()
                ? activeTabStyle
                : inactiveTabStyle
            }
            onClick={() => setActiveSplitType(paidType.toLowerCase())}
          >
            {t(`pos_dashboard.${paidType.toLowerCase()}`)}
          </button>
        ) : (
          <>
            <button
              className={
                activeSplitType === "full" ? activeTabStyle : inactiveTabStyle
              }
              onClick={() => setActiveSplitType("full")}
            >
              {t("pos_dashboard.full_pay")}
            </button>
            <button
              className={
                activeSplitType === "item" ? activeTabStyle : inactiveTabStyle
              }
              onClick={() => setActiveSplitType("item")}
            >
              {t("pos_dashboard.split_by_item")}
            </button>
            <button
              className={
                activeSplitType === "equal" ? activeTabStyle : inactiveTabStyle
              }
              onClick={() => setActiveSplitType("equal")}
            >
              {t("pos_dashboard.split_equally")}
            </button>
          </>
        )}
      </div>
      {renderSplitComponent()}
    </div>
  );
}

export default Checkout;
