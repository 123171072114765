import * as React from "react";

function AppleStore(props) {
  return (
    <svg
      width="132"
      height="44"
      viewBox="0 0 132 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="Mobile app store badge">
        <rect x="0.5" y="0.5" width="131" height="43" rx="6.5" fill="black" />
        <rect
          x="0.5"
          y="0.5"
          width="131"
          height="43"
          rx="6.5"
          stroke="#A6A6A6"
        />
        <g id="App Store">
          <path
            d="M89.6784 21.121V23.6411H88.0988V25.2938H89.6784V30.9093C89.6784 32.8268 90.5459 33.5938 92.7281 33.5938C93.1116 33.5938 93.4769 33.5481 93.7965 33.4933V31.8589C93.5225 31.8863 93.349 31.9046 93.0477 31.9046C92.0707 31.9046 91.6416 31.448 91.6416 30.4071V25.2938H93.7965V23.6411H91.6416V21.121H89.6784Z"
            fill="white"
          />
          <path
            d="M99.3557 33.7307C102.259 33.7307 104.04 31.7859 104.04 28.5627C104.04 25.3577 102.25 23.4037 99.3557 23.4037C96.4521 23.4037 94.6624 25.3577 94.6624 28.5627C94.6624 31.7859 96.443 33.7307 99.3557 33.7307ZM99.3557 31.9867C97.6482 31.9867 96.6895 30.7358 96.6895 28.5627C96.6895 26.4078 97.6482 25.1477 99.3557 25.1477C101.054 25.1477 102.022 26.4078 102.022 28.5627C102.022 30.7267 101.054 31.9867 99.3557 31.9867Z"
            fill="white"
          />
          <path
            d="M105.563 33.539H107.526V27.6678C107.526 26.2708 108.576 25.3303 110.065 25.3303C110.412 25.3303 110.996 25.3943 111.16 25.449V23.5133C110.95 23.4585 110.576 23.4311 110.284 23.4311C108.987 23.4311 107.882 24.1433 107.599 25.1203H107.453V23.5955H105.563V33.539Z"
            fill="white"
          />
          <path
            d="M116.035 25.0747C117.487 25.0747 118.436 26.0882 118.482 27.6496H113.46C113.57 26.0973 114.583 25.0747 116.035 25.0747ZM118.473 30.8545C118.108 31.6306 117.295 32.0598 116.108 32.0598C114.537 32.0598 113.524 30.955 113.46 29.211V29.1014H120.482V28.4166C120.482 25.2938 118.811 23.4037 116.044 23.4037C113.241 23.4037 111.46 25.4217 111.46 28.5992C111.46 31.7767 113.204 33.7307 116.053 33.7307C118.327 33.7307 119.915 32.635 120.363 30.8545H118.473Z"
            fill="white"
          />
          <path
            d="M76.8045 29.867C76.9559 32.3087 78.9907 33.8702 82.0191 33.8702C85.2557 33.8702 87.281 32.233 87.281 29.6209C87.281 27.5673 86.1264 26.4316 83.3251 25.7786L81.8203 25.4095C80.0411 24.9931 79.3219 24.4347 79.3219 23.46C79.3219 22.2297 80.4386 21.4252 82.1137 21.4252C83.7037 21.4252 84.8015 22.2107 85.0002 23.4694H87.0633C86.9403 21.1697 84.915 19.5514 82.1421 19.5514C79.161 19.5514 77.1736 21.1697 77.1736 23.6019C77.1736 25.6083 78.2998 26.8007 80.7699 27.378L82.5301 27.8039C84.3377 28.2298 85.1327 28.8544 85.1327 29.8954C85.1327 31.1068 83.8835 31.9869 82.18 31.9869C80.3535 31.9869 79.0853 31.1636 78.9055 29.867H76.8045Z"
            fill="white"
          />
          <path
            d="M56.4684 23.4311C55.1171 23.4311 53.9483 24.1068 53.3457 25.239H53.1996V23.5955H51.3095V36.8444H53.2726V32.0324H53.4279C53.9483 33.0824 55.0714 33.7033 56.4867 33.7033C58.9977 33.7033 60.5956 31.7219 60.5956 28.5627C60.5956 25.4034 58.9977 23.4311 56.4684 23.4311ZM55.9114 31.9411C54.2679 31.9411 53.2361 30.6445 53.2361 28.5718C53.2361 26.49 54.2679 25.1934 55.9206 25.1934C57.5824 25.1934 58.5777 26.4626 58.5777 28.5627C58.5777 30.6719 57.5824 31.9411 55.9114 31.9411Z"
            fill="white"
          />
          <path
            d="M67.4649 23.4311C66.1135 23.4311 64.9448 24.1068 64.3422 25.239H64.1961V23.5955H62.306V36.8444H64.2691V32.0324H64.4243C64.9448 33.0824 66.0679 33.7033 67.4832 33.7033C69.9942 33.7033 71.5921 31.7219 71.5921 28.5627C71.5921 25.4034 69.9942 23.4311 67.4649 23.4311ZM66.9079 31.9411C65.2644 31.9411 64.2326 30.6445 64.2326 28.5718C64.2326 26.49 65.2644 25.1934 66.9171 25.1934C68.5789 25.1934 69.5741 26.4626 69.5741 28.5627C69.5741 30.6719 68.5789 31.9411 66.9079 31.9411Z"
            fill="white"
          />
          <path
            d="M47.7873 33.539H50.0397L45.109 19.8826H42.8282L37.8976 33.539H40.0742L41.3329 29.9143H46.5381L47.7873 33.539ZM43.8598 22.3622H44.0207L45.9986 28.1351H41.8724L43.8598 22.3622Z"
            fill="white"
          />
        </g>
        <g id="Download on the">
          <path
            d="M39.2162 9.58208V16.17H41.5948C43.558 16.17 44.6948 14.9602 44.6948 12.8555C44.6948 10.7828 43.5488 9.58208 41.5948 9.58208H39.2162ZM40.2389 10.5134H41.4807C42.8458 10.5134 43.6538 11.3809 43.6538 12.8692C43.6538 14.3803 42.8595 15.2386 41.4807 15.2386H40.2389V10.5134Z"
            fill="white"
          />
          <path
            d="M48.1764 16.2659C49.6282 16.2659 50.5184 15.2934 50.5184 13.6818C50.5184 12.0794 49.6236 11.1024 48.1764 11.1024C46.7246 11.1024 45.8297 12.0794 45.8297 13.6818C45.8297 15.2934 46.72 16.2659 48.1764 16.2659ZM48.1764 15.3939C47.3226 15.3939 46.8433 14.7684 46.8433 13.6818C46.8433 12.6044 47.3226 11.9744 48.1764 11.9744C49.0255 11.9744 49.5095 12.6044 49.5095 13.6818C49.5095 14.7638 49.0255 15.3939 48.1764 15.3939Z"
            fill="white"
          />
          <path
            d="M58.0998 11.1982H57.1182L56.2325 14.9921H56.1549L55.1322 11.1982H54.1918L53.1691 14.9921H53.0961L52.2058 11.1982H51.2105L52.5802 16.17H53.5891L54.6118 12.5085H54.6894L55.7166 16.17H56.7347L58.0998 11.1982Z"
            fill="white"
          />
          <path
            d="M59.2302 16.17H60.2117V13.2618C60.2117 12.4857 60.6729 12.0018 61.3988 12.0018C62.1247 12.0018 62.4716 12.3989 62.4716 13.1979V16.17H63.4532V12.9514C63.4532 11.7689 62.8414 11.1024 61.732 11.1024C60.9833 11.1024 60.4902 11.4356 60.2483 11.9881H60.1752V11.1982H59.2302V16.17Z"
            fill="white"
          />
          <path
            d="M64.9991 16.17H65.9806V9.25793H64.9991V16.17Z"
            fill="white"
          />
          <path
            d="M69.6722 16.2659C71.124 16.2659 72.0143 15.2934 72.0143 13.6818C72.0143 12.0794 71.1195 11.1024 69.6722 11.1024C68.2204 11.1024 67.3256 12.0794 67.3256 13.6818C67.3256 15.2934 68.2159 16.2659 69.6722 16.2659ZM69.6722 15.3939C68.8185 15.3939 68.3391 14.7684 68.3391 13.6818C68.3391 12.6044 68.8185 11.9744 69.6722 11.9744C70.5214 11.9744 71.0053 12.6044 71.0053 13.6818C71.0053 14.7638 70.5214 15.3939 69.6722 15.3939Z"
            fill="white"
          />
          <path
            d="M74.9389 15.4258C74.4048 15.4258 74.0167 15.1656 74.0167 14.7182C74.0167 14.2799 74.3271 14.0471 75.012 14.0014L76.2264 13.9238V14.3393C76.2264 14.9556 75.6785 15.4258 74.9389 15.4258ZM74.6878 16.2522C75.3407 16.2522 75.884 15.9691 76.1807 15.4715H76.2583V16.17H77.2034V12.7733C77.2034 11.7233 76.5003 11.1024 75.2539 11.1024C74.1263 11.1024 73.3228 11.6502 73.2223 12.504H74.1719C74.2815 12.1524 74.6604 11.9515 75.2083 11.9515C75.8794 11.9515 76.2264 12.2483 76.2264 12.7733V13.2025L74.8796 13.2801C73.6971 13.3531 73.0306 13.869 73.0306 14.7638C73.0306 15.6724 73.7291 16.2522 74.6878 16.2522Z"
            fill="white"
          />
          <path
            d="M80.5343 16.2522C81.2191 16.2522 81.7989 15.928 82.0957 15.3847H82.1733V16.17H83.1138V9.25793H82.1322V11.9881H82.0592C81.7898 11.4402 81.2146 11.1161 80.5343 11.1161C79.2788 11.1161 78.4707 12.1113 78.4707 13.6818C78.4707 15.2569 79.2697 16.2522 80.5343 16.2522ZM80.8128 11.9972C81.6346 11.9972 82.1505 12.65 82.1505 13.6864C82.1505 14.7273 81.6391 15.371 80.8128 15.371C79.9819 15.371 79.4843 14.7364 79.4843 13.6818C79.4843 12.6364 79.9865 11.9972 80.8128 11.9972Z"
            fill="white"
          />
          <path
            d="M89.4789 16.2659C90.9307 16.2659 91.821 15.2934 91.821 13.6818C91.821 12.0794 90.9261 11.1024 89.4789 11.1024C88.0271 11.1024 87.1323 12.0794 87.1323 13.6818C87.1323 15.2934 88.0225 16.2659 89.4789 16.2659ZM89.4789 15.3939C88.6252 15.3939 88.1458 14.7684 88.1458 13.6818C88.1458 12.6044 88.6252 11.9744 89.4789 11.9744C90.3281 11.9744 90.812 12.6044 90.812 13.6818C90.812 14.7638 90.3281 15.3939 89.4789 15.3939Z"
            fill="white"
          />
          <path
            d="M93.1203 16.17H94.1018V13.2618C94.1018 12.4857 94.563 12.0018 95.2889 12.0018C96.0148 12.0018 96.3617 12.3989 96.3617 13.1979V16.17H97.3433V12.9514C97.3433 11.7689 96.7315 11.1024 95.6221 11.1024C94.8734 11.1024 94.3803 11.4356 94.1384 11.9881H94.0653V11.1982H93.1203V16.17Z"
            fill="white"
          />
          <path
            d="M101.864 9.96101V11.2211H101.074V12.0474H101.864V14.8552C101.864 15.8139 102.298 16.1974 103.389 16.1974C103.581 16.1974 103.763 16.1746 103.923 16.1472V15.33C103.786 15.3437 103.699 15.3528 103.549 15.3528C103.06 15.3528 102.846 15.1245 102.846 14.6041V12.0474H103.923V11.2211H102.846V9.96101H101.864Z"
            fill="white"
          />
          <path
            d="M105.241 16.17H106.222V13.2664C106.222 12.5131 106.67 12.0063 107.473 12.0063C108.167 12.0063 108.537 12.4081 108.537 13.2025V16.17H109.518V12.9605C109.518 11.7781 108.866 11.1069 107.806 11.1069C107.058 11.1069 106.533 11.4402 106.291 11.9972H106.213V9.25793H105.241V16.17Z"
            fill="white"
          />
          <path
            d="M113.059 11.9378C113.785 11.9378 114.26 12.4446 114.283 13.2253H111.772C111.827 12.4492 112.333 11.9378 113.059 11.9378ZM114.278 14.8278C114.096 15.2158 113.689 15.4304 113.096 15.4304C112.311 15.4304 111.804 14.878 111.772 14.006V13.9512H115.283V13.6088C115.283 12.0474 114.447 11.1024 113.064 11.1024C111.662 11.1024 110.772 12.1113 110.772 13.7001C110.772 15.2889 111.644 16.2659 113.068 16.2659C114.205 16.2659 115 15.718 115.223 14.8278H114.278Z"
            fill="white"
          />
        </g>
        <g id="Apple logo">
          <path
            d="M27.2459 22.3309C27.2697 20.4818 28.2628 18.7322 29.8381 17.7637C28.8443 16.3443 27.1797 15.4444 25.4478 15.3902C23.6007 15.1963 21.8099 16.4955 20.8686 16.4955C19.9091 16.4955 18.4599 15.4095 16.8993 15.4416C14.8651 15.5073 12.9687 16.6638 11.9791 18.4422C9.85167 22.1255 11.4385 27.5386 13.4764 30.5159C14.496 31.9738 15.6876 33.6023 17.2468 33.5445C18.7726 33.4813 19.3424 32.5716 21.1841 32.5716C23.0088 32.5716 23.5434 33.5445 25.1342 33.5078C26.7714 33.4813 27.803 32.0435 28.7868 30.5718C29.5194 29.533 30.0831 28.3849 30.4571 27.17C28.533 26.3562 27.2481 24.42 27.2459 22.3309Z"
            fill="white"
          />
          <path
            d="M24.2411 13.4322C25.1337 12.3606 25.5735 10.9832 25.467 9.59253C24.1032 9.73577 22.8434 10.3876 21.9387 11.4181C21.054 12.425 20.5936 13.7781 20.6806 15.1156C22.0449 15.1297 23.3861 14.4955 24.2411 13.4322Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}

export default AppleStore;
