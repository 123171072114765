/**
 * Manages state for client dashboard api's.
 * Defines initial state for each client dashboard api action.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createClientSubscriptionsApi from "./ClientSubscriptions";
import { showToast } from "../../components/common/utils/showToast.util";

/**
 * Initial state structure defining various properties related to client subscriptions api's processes.
 * Each property represents a specific client subscriptions api's action/status with its associated data, error, success, loading, and message.
 * Properties such as subscribeToFreeTrial
 * store data, error, success, loading, and message status for corresponding subscriptions api's actions.
 */
const initialState = {
  subscribeToFreeTrial: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  upgradePlan: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  calculatePricingSummary: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },

  getPricing: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the client cilent-subscriptions api's process.
 * These action types are prefixed with the 'client cilent-subscriptions api's' base path for better organization.
 */
const BASE = "client-subscriptions";

export const ActionTypes = {
  SUBSCRIBE_FREE_TRIAL: `${BASE}/free-trial`, // Action type for subscribing free trial
  UPGRADE_PLAN: `${BASE}/free-trial-upgrade`, // Action type for upgrading free trial
  CALCULATE_PRICING_SUMMARY: `${BASE}/calc-subscription-summary`, // Action type for calculating pricing summary
};

// Creating an instance of the customers service with a base URL 'client-subscriptions'
const clientSubscriptionsService = createClientSubscriptionsApi(
  "client-subscriptions"
);

/**
 * Initiates the subscribeToFreeTrial process for a client.
 
 * @param {function} successCallBack - Callback function upon successful subscribeToFreeTrial.
 */
export const subscribeToFreeTrial = createAsyncThunk(
  ActionTypes.SUBSCRIBE_FREE_TRIAL,

  async ({ successCallBack, errorCallBack }, thunkAPI) => {
    try {
      const response = await clientSubscriptionsService.subscribeToFreeTrial();
      if (response?.data?.Succeeded) {
        successCallBack(response);
        return response?.data?.data;
      } else {
        errorCallBack();
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);



/**
 * Initiates the getPricing process for a plan.
 * @param {object} type - id of the plan
 * @param {function} successCallBack - Callback function upon successful getPricing.
 */
export const getPricing = createAsyncThunk(
  "getPricing",
  async ({ type }, thunkAPI) => {
    const response = await clientSubscriptionsService.getPricing(type);
    return response?.data?.data;
  }
);
/**
 * Initiates the upgradePlan process for a client.
 
 * @param {function} successCallBack - Callback function upon successful upgradePlan.
 */
export const upgradePlan = createAsyncThunk(
  ActionTypes.UPGRADE_PLAN,

  async ({ payload, successCallBack, errorCallBack }, thunkAPI) => {
    try {
      const response = await clientSubscriptionsService.upgradePlan(payload);
      if (response?.data?.Succeeded) {
        showToast("Subscription Updated successfully!");
        successCallBack(response);
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the calculatePricingSummary process for a client.
 
 * @param {function} successCallBack - Callback function upon successful calculatePricingSummary.
 */
export const calculatePricingSummary = createAsyncThunk(
  ActionTypes.CALCULATE_PRICING_SUMMARY,

  async ({ payload, successCallBack, errorCallBack }, thunkAPI) => {
    try {
      const response = await clientSubscriptionsService.calculatePricingSummary(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for clientDashboard related state management.
 * - `name`: Name of the slice (clientDashboard)
 * - `initialState`: Initial state defining properties for various clientDashboard actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (subscribeToFreeTrial, getClientNetIncome etc).
 */
export const clientSubscriptionsSlice = createSlice({
  name: "clientSubscriptions",
  initialState,

  reducers: {
    reset: (state) => {
      state.subscribeToFreeTrial = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.upgradePlan = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.calculatePricingSummary = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getPricing = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the subscribeToFreeTrial action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(subscribeToFreeTrial.pending, (state) => {
        state.subscribeToFreeTrial.isLoading = true;
        state.subscribeToFreeTrial.message = "";
        state.subscribeToFreeTrial.isError = false;
        state.subscribeToFreeTrial.isSuccess = false;
        state.subscribeToFreeTrial.data = null;
      })
      /**
       * Updates the state when subscribeToFreeTrial action is fulfilled/successful.
       * Updates loading and success flags and sets subscribeToFreeTrial data with the payload.
       */
      .addCase(subscribeToFreeTrial.fulfilled, (state, action) => {
        state.subscribeToFreeTrial.isLoading = false;
        state.subscribeToFreeTrial.isSuccess = true;
        state.subscribeToFreeTrial.data = action.payload;
      })
      /**
       * Updates the state when subscribeToFreeTrial action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(subscribeToFreeTrial.rejected, (state, action) => {
        state.subscribeToFreeTrial.message = action?.payload?.message;
        state.subscribeToFreeTrial.isLoading = false;
        state.subscribeToFreeTrial.isError = true;
        state.subscribeToFreeTrial.data = null;
      })
      /**
       * Updates the state while the upgradePlan action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(upgradePlan.pending, (state) => {
        state.upgradePlan.isLoading = true;
        state.upgradePlan.message = "";
        state.upgradePlan.isError = false;
        state.upgradePlan.isSuccess = false;
        state.upgradePlan.data = null;
      })
      /**
       * Updates the state when upgradePlan action is fulfilled/successful.
       * Updates loading and success flags and sets upgradePlan data with the payload.
       */
      .addCase(upgradePlan.fulfilled, (state, action) => {
        state.upgradePlan.isLoading = false;
        state.upgradePlan.isSuccess = true;
        state.upgradePlan.data = action.payload;
      })
      /**
       * Updates the state when upgradePlan action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(upgradePlan.rejected, (state, action) => {
        state.upgradePlan.message = action?.payload?.message;
        state.upgradePlan.isLoading = false;
        state.upgradePlan.isError = true;
        state.upgradePlan.data = null;
      })
      /**
       * Updates the state while the calculatePricingSummary action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(calculatePricingSummary.pending, (state) => {
        state.calculatePricingSummary.isLoading = true;
        state.calculatePricingSummary.message = "";
        state.calculatePricingSummary.isError = false;
        state.calculatePricingSummary.isSuccess = false;
        state.calculatePricingSummary.data = null;
      })
      /**
       * Updates the state when calculatePricingSummary action is fulfilled/successful.
       * Updates loading and success flags and sets calculatePricingSummary data with the payload.
       */
      .addCase(calculatePricingSummary.fulfilled, (state, action) => {
        state.calculatePricingSummary.isLoading = false;
        state.calculatePricingSummary.isSuccess = true;
        state.calculatePricingSummary.data = action.payload;
      })
      /**
       * Updates the state when calculatePricingSummary action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(calculatePricingSummary.rejected, (state, action) => {
        state.calculatePricingSummary.message = action?.payload?.message;
        state.calculatePricingSummary.isLoading = false;
        state.calculatePricingSummary.isError = true;
        state.calculatePricingSummary.data = null;
      })
      /**
       * Updates the state while the getPricing action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getPricing.pending, (state) => {
        state.getPricing.isLoading = true;
        state.getPricing.message = "";
        state.getPricing.isError = false;
        state.getPricing.isSuccess = false;
        state.getPricing.data = null;
      })
      /**
       * Updates the state when getPricing action is fulfilled/successful.
       * Updates loading and success flags and sets getPricing data with the payload.
       */
      .addCase(getPricing.fulfilled, (state, action) => {
        state.getPricing.isLoading = false;
        state.getPricing.isSuccess = true;
        state.getPricing.data = action.payload;
      })
      /**
       * Updates the state when getPricing action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getPricing.rejected, (state, action) => {
        state.getPricing.message = action?.payload?.message;
        state.getPricing.isLoading = false;
        state.getPricing.isError = true;
        state.getPricing.data = null;
      });
  },
});

/**
 * Destructures the reset action from the clientSubscriptionsSlice actions.
 * - `reset`: Action function to reset the customers related state.
 */
export const { reset } = clientSubscriptionsSlice.actions;

/**
 * Exports the default reducer generated by clientSubscriptionsSlice.
 * This reducer handles state updates for customers related actions.
 */
export default clientSubscriptionsSlice.reducer;
