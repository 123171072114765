import api from "../../services/api";

/**
 * Creates stocktaking API endpoints based on the specified base path.
 * @param {string} basePath - The base path for stocktaking endpoints.
 * @returns {Object} Object containing stocktaking API methods.
 */
const createStocktakingApi = (basePath) => ({
  /**
   * get all stocktakings
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stocktakings
   *    order: order for sorting stocktakings by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the stocktaking response.
   */
  getAllStocktakings: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * get single stocktaking
   * @param {object} id id of the stocktaking
   * @returns {Promise} A Promise that resolves to the stocktaking response.
   */
  getSingleStocktaking: async (stocktakingId) => {
    const response = await api().get(`${basePath}/${stocktakingId}`);
    return response;
  },

  /**
   * update a single stocktaking
   * @param {object} id id of the stocktaking
   * @returns {Promise} A Promise that resolves to the stocktaking response.
   */
  updateStocktaking: async (stocktakingId, payload) => {
    const response = await api().put(`${basePath}/${stocktakingId}`, payload);
    return response;
  },

  /**
   * add a stocktaking
   * @param {object} id id of the stocktaking
   * @returns {Promise} A Promise that resolves to the stocktaking response.
   */
  addStocktaking: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * delete a stocktaking
   * @param {object} id id of the stocktaking
   * @returns {Promise} A Promise that resolves to the stocktaking response.
   */
  deleteStocktaking: async (stocktakingId) => {
    const response = await api().delete(`${basePath}/${stocktakingId}`);
    return response;
  },
  /**
   * undo deleted a stocktaking
   * @param {object} id id of the stocktaking
   * @returns {Promise} A Promise that resolves to the stocktaking response.
   */
  undoStocktaking: async (stocktakingId) => {
    const response = await api().get(
      `${basePath}/undo-stock-taking/${stocktakingId}`
    );
    return response;
  },
});

// Export the createStocktakingApi

/**
 * Example usage:
 * import createStocktakingApi from './path/to/api/stocktakingApi';
 *
 * const stocktakingApi = createStocktakingApi('stocktaking');
 *
 * const getAllStocktakings = async (payload) => {
 *   try {
 *     const response = await stocktakingApi.getAllStocktakings(payload);
      // Handle response
 *   } catch (error) {
      // Handle error
 *   }
 * };
 */

export default createStocktakingApi;
