import api from "../../services/api";

/**
 * Creates reports API endpoints based on the specified base path.
 * @param {string} basePath - The base path for reports endpoints.
 * @returns {Object} Object containing reports API methods.
 */
const createReportsApi = (basePath) => ({
  /**
   * generate sales report
   * @param {object} payload - Payload for generating sales report
   *    startDate: start date for the sales report
   *    endDate: end date for the sales report
   *    filters: any additional filters for the report
   * @returns {Promise} A Promise that resolves to the sales report response.
   */
  generateSalesReport: async (payload) => {
    const response = await api().post(
      `${basePath}/generate-sale-report`,
      payload
    );
    return response;
  },

  /**
   * generate taxes report
   * @param {object} payload - Payload for generating taxes report
   *    startDate: start date for the taxes report
   *    endDate: end date for the taxes report
   *    filters: any additional filters for the report
   * @returns {Promise} A Promise that resolves to the taxes report response.
   */
  generateTaxReport: async (payload) => {
    const response = await api().post(
      `${basePath}/generate-tax-report`,
      payload
    );
    return response;
  },

  /**
   * generate profitloss report
   * @param {object} payload - Payload for generating profitloss report
   *    startDate: start date for the profitloss report
   *    endDate: end date for the profitloss report
   *    filters: any additional filters for the report
   * @returns {Promise} A Promise that resolves to the profitloss report response.
   */
  generateProfitLossReport: async (payload) => {
    const response = await api().post(
      `${basePath}/generate-profit-loss-report`,
      payload
    );
    return response;
  },

  /**
   * generate purchase report
   * @param {object} payload - Payload for generating purchase report
   *    startDate: start date for the purchase report
   *    endDate: end date for the purchase report
   *    filters: any additional filters for the report
   * @returns {Promise} A Promise that resolves to the purchase report response.
   */
  generatePurchaseReport: async (payload) => {
    const response = await api().post(
      `${basePath}/generate-purchase-report`,
      payload
    );
    return response;
  },

  /**
   * generate sales report
   * @param {object} payload - Payload for generating sales report
   *    startDate: start date for the sales report
   *    endDate: end date for the sales report
   *    filters: any additional filters for the report
   * @returns {Promise} A Promise that resolves to the sales report response.
   */
  generateInventoryReport: async (payload) => {
    const response = await api().post(
      `${basePath}/generate-inventory-report`,
      payload
    );
    return response;
  },
  /**
   * generate sales report
   * @param {object} payload - Payload for generating sales report
   *    startDate: start date for the sales report
   *    endDate: end date for the sales report
   *    filters: any additional filters for the report
   * @returns {Promise} A Promise that resolves to the sales report response.
   */
  generateExpenseReport: async (payload) => {
    const response = await api().post(
      `${basePath}/generate-expense-report`,
      payload
    );
    return response;
  },
});

// Export the createReportsApi

/**
 * Example usage:
 * import createReportsApi from './path/to/api/reportsApi';
 *
 * const reportsApi = createReportsApi('reports');
 *
 * const generateSalesReport = async (payload) => {
 *   try {
 *     const response = await reportsApi.generateSalesReport(payload);
 *     // Handle response
 *   } catch (error) {
 *     // Handle error
 *   }
 * };
 */

export default createReportsApi;
