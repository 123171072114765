import { toast } from "react-toastify";
import { CustomButton } from "../components";

/**
 * Utility functions for displaying toasts using react-toastify.
 *
 * @typedef {Object} ToastOptions - Object containing functions to show toasts for different types.
 * @property {Function} success - Function to show a success toast.
 * @property {Function} error - Function to show an error toast.
 * @property {Function} warning - Function to show a warning toast.
 * @property {Function} info - Function to show an info toast.
 *
 * @typedef {Function} ShowToastFunction - Function to display a toast with a specified message and type.
 * @param {string} message - Message to be displayed in the toast.
 * @param {string} [type="success"] - Type of the toast (success, error, warning, info).
 *
 * @param {ToastOptions} toastOptions - Object containing functions to show toasts for different types.
 *
 * @example
 *  Import showToast and toastOptions from "path/to/toastUtility";
 * Call showToast with a message and type (optional, defaults to 'success').
 * showToast("Operation Successful", "success");
 * showToast("Error Occurred", "error");
 * showToast("Warning Message", "warning");
 * showToast("Information", "info");
 */

const toastOptions = {
  success: (message) => toast.success(message),
  error: (message) => toast.error(message),
  warning: (message) => toast.warning(message),
  info: (message) => toast.info(message),
  // You can add more types and their respective functions here
};

/**
 * Displays a toast with the specified message and type.
 * @param {string} message - Message to be displayed in the toast.
 * @param {string} [type="success"] - Type of the toast (success, error, warning, info).
 */

// export const showToast = (message, type = "success") => {
//   const showToastFn = toastOptions[type] || toast; // Default to 'toast' if type not found
//   showToastFn(message);
// };

export const showToast = (message, type = "success", undoAction) => {
  toast.dismiss();
  const toastContent = (
    <div className="flex items-center gap-3">
      {message}
      {undoAction && (
        <CustomButton
          padding="py-2 px-3"
          icon
          bgColor="bg-white"
          textColor="text-text-primary"
          text="Undo"
          onClick={undoAction}
          width="w-fit"
          className="whitespace-nowrap mr-2 h-8"
        />
      )}
    </div>
  );

  const showToastFn = toastOptions[type] || toast; // Default to 'toast' if type not found
  showToastFn(toastContent);
};
