import api from "../../services/api";

/**
 * Creates stores API endpoints based on the specified base path.
 * @param {string} basePath - The base path for authentication endpoints.
 * @returns {Object} Object containing stores API methods.
 */
const createStoresApi = (basePath) => ({
  /**
   * get all stores
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the store response.
   */
  getAllPosStores: async (payload) => {
    const response = await api().get(`${basePath}/get-stores`, payload);
    return response;
  },
  /**
   * get single store
   * @param {object} storeId storeId to retrieve a store
   * @returns {Promise} A Promise that resolves to the store response.
   */
  getPosSingleStore: async (storeId) => {
    const response = await api().get(`${basePath}/select-store/${storeId}`);
    return response;
  },

});

// Export the createStoresApi

/**
 * Example usage:
 * import createStoresApi from './path/to/api/posstoresApi';
 *
 * const storesApi = createStoresApi('stores');
 *
 * const getAllPosStores = async (payload) => {
 *   try {
 *     const response = await storesApi.addStore(payload);
      // Handle response
 *   } catch (error) {
      // Handle error
 *   }
 * };
 */

export default createStoresApi;
