import { SearchIcon } from "../../Icons";

/**
 * CustomSearch component.
 * Renders a group of input elements with input field, error message.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.placeholder - The placeholder text for the input.
 * @param {string} props.inputName - The name of the input element.
 * @param {string} props.value - The value of the input element.
 * @param {Function} props.onChange - The function to handle input changes.
 * @param {string} props.className - The additional CSS classes for the input.
 * @param {string} props.paddingY - The additional CSS classes for the input.
 * @returns {JSX.Element} JSX code for rendering the CustomSearch component.
 */

const CustomSearch = ({
  placeholder = "",
  inputName = "",
  value,
  onChange,
  className,
  paddingY = "py-2",
}) => {
  return (
    <div className="relative">
      <input
        type="text"
        name={inputName}
        placeholder={placeholder}
        className={`xl:min-w-64 min-w-56 pr-3 pl-10 text-base bg-surface-gray rounded-lg border-none ${className} ${paddingY}`}
        onChange={onChange}
        value={value}
        autoComplete={inputName}
      />

      <SearchIcon className="absolute left-4 top-2 w-4" />
    </div>
  );
};

export default CustomSearch;

// Sample Usage:
// import CustomSearch from '.path/CustomSearch';
// <CustomSearch
//
//   placeholder="Search item"
//   inputName="search"
//   register={register}
//   errors={errors}
// />
