import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePosTable,
  getAllPosTables,
  resetPosTable,
  updatePosTable,
} from "../../../features/PosTables/PosTablesSlice";
/**
 * Custom hook for managing POS table functionality.
 * Provides methods for adding and deleting POS tables, and handling modal states.
 * @returns {Object} An object containing:
 *   - isAddModalOpen: Boolean indicating whether the add table modal is open.
 *   - setIsAddModalOpen: Function to toggle the add table modal state.
 *   - isConfirmModalOpen: Boolean indicating whether the delete confirmation modal is open.
 *   - handleAddTable: Function to add POS tables of specified shape and count.
 *   - handleDeleteTable: Function to initiate the deletion of a POS table.
 *   - confirmDelete: Function to confirm the deletion of a POS table.
 *   - tableStyles: Object containing styles for different table shapes (rectangle, square, round).
 *   - tables: Array of objects representing the POS tables with their IDs, shapes, and positions.
 *   - posTablesListLoading: Boolean indicating whether POS tables data is currently being loaded.
 */
const usePosTable = () => {
  const [tables, setTables] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [tableToDelete, setTableToDelete] = useState(null);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [showSaveCancel, setShowSaveCancel] = useState(false);

  const dispatch = useDispatch();
  const { data: posTablesList, isLoading: posTablesListLoading } = useSelector(
    (state) => state.posTables.getAllPosTables
  );

  /**
   * Function to fetch all POS tables
   */
  const fetchAllPosTables = () => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "position",
      order: {
        position: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllPosTables({ payload }));
  };

  useEffect(() => {
    fetchAllPosTables();
  }, [dispatch]);

  useEffect(() => {
    if (posTablesList) {
      const formattedTables = posTablesList?.map((table) => ({
        id: table.id.toString(),
        shape: table.type.toLowerCase(),
        position: table.tableNumber,
        isReserved: table.isReserved,
      }));
      setTables(formattedTables);
    }
  }, [posTablesList]);

  /**
   * Function to handle saving changes
   */
  const handleSaveChanges = () => {
    const payload = {
      tablePositions: tables?.map((table, index) => ({
        id: +table.id,
        position: tables.length - index,
      })),
    };
    dispatch(updatePosTable({ payload }));
    setShowSaveCancel(false);
  };

  /**
   * Function to handle canceling changes
   */
  const handleCancel = () => {
    setShowSaveCancel(false);
    fetchAllPosTables();
  };

  /**
   * Function to handle adding a table
   * @param {string} shape - The shape of the POS tables (rectangle, square, round).
   * @param {number} count - The number of POS tables to add.
   */
  const handleAddTable = (shape, count) => {
    const newTables = Array.from({ length: count }, (_, index) => ({
      id: `${shape}-${tables.length + index + 1}`,
      shape,
    }));

    setTables([...tables, ...newTables]);
  };

  /**
   * Function to initiate the deletion of a POS table.
   * @param {string} tableId - The ID of the POS table to delete.
   */
  const handleDeleteTable = (tableId) => {
    setTableToDelete(tableId);
    setIsConfirmModalOpen(true);
  };

  /**
   * Function to confirm the deletion of a POS table.
   */
  const confirmDelete = () => {
    if (tableToDelete) {
      dispatch(
        deletePosTable({
          tableId: tableToDelete,
          successCallBack: fetchAllPosTables,
        })
      ).unwrap();
      setIsConfirmModalOpen(false);
      setTableToDelete(null); // Reset the tableToDelete after handling
    }
  };

  /**
   * Function to handle resetting tables
   */
  const handleResetTables = async () => {
    try {
      // Dispatch the resetPosTable action and wait for it to complete
      await dispatch(resetPosTable()).unwrap();
      // Fetch all POS tables after successfully resetting
      fetchAllPosTables();
    } catch (error) {
      console.error("Failed to reset tables:", error);
    }
  };
  

  const reservedCount = tables?.filter((table) => table.isReserved).length;
  const availableCount = tables?.length - reservedCount;

  const tableStyles = {
    rectangle: {
      width: "300px",
      height: "120px",
      borderRadius: "5px",
      paddingLeft: "150px",
      paddingTop: "50px",
    },
    square: {
      width: "120px",
      height: "115px",
      borderRadius: "5px",
      padding: "45px",
    },
    round: {
      width: "150px",
      height: "140px",
      borderRadius: "100px",
      paddingLeft: "55px",
      paddingTop: "55px",
    },
  };

  const handleDragStart = (e, index) => {
    setDraggingIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const draggedOverItem = tables[draggingIndex];
    const updatedItems = [...tables];
    updatedItems.splice(draggingIndex, 1);
    updatedItems.splice(index, 0, draggedOverItem);
    setTables(updatedItems);
    setDraggingIndex(null);
    setShowSaveCancel(true);
  };

  return {
    isAddModalOpen,
    setIsAddModalOpen,
    isConfirmModalOpen,
    handleAddTable,
    handleDeleteTable,
    confirmDelete,
    tableStyles,
    tables,
    setIsConfirmModalOpen,
    posTablesListLoading,
    reservedCount,
    availableCount,
    draggingIndex,
    handleDrop,
    handleDragOver,
    handleDragStart,
    showSaveCancel,
    handleSaveChanges,
    handleCancel,
    handleResetTables,
  };
};

export default usePosTable;
