import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllPosStores,
  getPosSingleStore,
} from "../../../../features/PosStores/PosStoresSlice";
import { useUserContext } from "../../../../context/UserContext";
import useStorage from "../../../common/utils/localStorage.util";

export const usePosStores = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { updateUser, setUserProfile, setToken,user } = useUserContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const [selectedStore, setSelectedStore] = useState();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    setDropdownOpen(false);
  };
  // get sigle store information from the store
  const { data: singleStore, isLoading: loader } = useSelector(
    (state) => state.posstores.getPosSingleStore
  );
  // get store list for all stores from the redux store

  const { data: storesList, isLoading: storeListLoading } = useSelector(
    (state) => state.posstores.getAllPosStores
  );

  const storeOptions = storesList
    ?.map((user) => {
      return user?.stores?.map((store) => {
        return { id: store.id, branchName: store.branchName };
      });
    })
    .flat();

  useEffect(() => {
    dispatch(getAllPosStores());
  }, []);

  const onSubmit = (data) => {
    const storeId = data.selectedStore; // Retrieve the store ID from form data
    if (storeId) {
      dispatch(getPosSingleStore({ storeId }))
        .then((action) => {
          if (action.payload) {
            setIsModalOpen(true);
            const storeDetails = action.payload;
            updateUser({ ...storeDetails, isLoginVerified: true });

            setUserProfile(storeDetails?.profile);
            setToken(storeDetails?.token);
          }
        })
        .catch((error) => {
          console.error("Error fetching store details:", error);
        });
    }
  };

  // Assuming store.id is unique
  const handleStoreSelection = (storeId) => {
    const store = storeOptions.find((store) => store.id === storeId);
    setSelectedStore(store);
  };

  const handleConfirm = () => {
    useStorage.setItem("isStoreSelected", true);
    updateUser({ ...user, isLoginVerified: true });
    setIsModalOpen(false);
    navigate("/pos/dashboard");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  /**
   * Handler function for form submission.
   * This function can be async if needed for API calls or other asynchronous operations.
   * @param {Object} data - Form data provided by react-hook-form upon successful validation.
   */
  return {
    dropdownOpen,
    selectedLanguage,
    toggleDropdown,
    handleSelectLanguage,
    dropdownRef,
    onSubmit,
    handleCloseModal,
    isModalOpen,
    handleConfirm,
    selectedStore,
    register,
    handleSubmit,
    storeListLoading,
    storeOptions,
    setSelectedStore,
    handleStoreSelection,
    singleStore,
    loader,
  };
};
