import * as React from "react";

function Users({ stroke = "#667085" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Users">
        <path
          id="Vector"
          d="M6.875 12.5C9.11866 12.5 10.9375 10.6812 10.9375 8.4375C10.9375 6.19384 9.11866 4.375 6.875 4.375C4.63134 4.375 2.8125 6.19384 2.8125 8.4375C2.8125 10.6812 4.63134 12.5 6.875 12.5Z"
        stroke={stroke}
          stroke-miterlimit="10"
        />
        <path
          id="Vector_2"
          d="M12.1406 4.52344C12.4997 4.42604 12.8701 4.37613 13.2422 4.375C14.3196 4.375 15.3529 4.80301 16.1148 5.56488C16.8767 6.32675 17.3047 7.36006 17.3047 8.4375C17.3047 9.51494 16.8767 10.5483 16.1148 11.3101C15.3529 12.072 14.3196 12.5 13.2422 12.5"
        stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_3"
          d="M1.25 15.4222C1.88433 14.5195 2.72659 13.7827 3.70563 13.2741C4.68467 12.7655 5.77173 12.5 6.875 12.5C7.97827 12.5 9.06533 12.7655 10.0444 13.2741C11.0234 13.7827 11.8657 14.5195 12.5 15.4222"
        stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_4"
          d="M13.2422 12.5C14.3455 12.4993 15.4328 12.7645 16.412 13.2731C17.3911 13.7817 18.2333 14.5188 18.8672 15.4219"
        stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

export default Users;
