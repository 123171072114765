import React from "react";
import { CustomButton } from "../../../../../../common/components";

function DeleteAdminUser({ user, onClose, onDelete }) {
  return (
    <div>
      <div className="p-5 ">
        <div className="pb-6">Are you sure you want to Block this user?</div>
        <div className="pb-6 text-base font-normal">
          This action cannot be undone. If you proceed, all your data and
          settings will be lost.
        </div>
        <div className="bg-surface-gray rounded-lg p-5 shadow">
          <h2 className="text-lg font-semibold pb-3">User Details</h2>
          <hr></hr>
          <div className="flex justify-between items-center py-1">
            <span className="text-gray-600 font-normal text-sm">Name</span>
            <span className="text-gray-800 font-medium text-sm">
              {user?.fullName}
            </span>
          </div>
          <div className="flex justify-between items-center py-1">
            <span className="text-gray-600 font-normal text-sm">
              Phone Number
            </span>
            <span className="text-gray-800 font-medium text-sm">
              {user?.phoneno}{" "}
            </span>
          </div>
          <div className="flex justify-between items-center py-1">
            <span className="text-gray-600 font-normal text-sm">Email</span>
            <span className="text-gray-800 font-medium text-sm">
              {user?.email}
            </span>
          </div>
          <div className="flex justify-between items-center py-1">
            <span className="text-gray-600 font-normal text-sm">Roles</span>
            <span className="text-gray-800 font-medium text-sm">
              {" "}
              {user?.role}
            </span>
          </div>
        </div>

        <div className="pt-4">
          <div className="flex justify-end space-x-2">
            <CustomButton
              width="20px"
              text="Dismiss"
              bgColor="bg-white"
              textColor="text-text-primary"
              border="border"
              hoverTextColor="text-text-primary"
              onClick={onClose}
            />
            <CustomButton
              type="button"
              width="20px"
              text="Delete"
              bgColor="bg-danger"
              textColor="text-white"
              onClick={() => onDelete(user.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAdminUser;
