import React, { useEffect, useState } from "react";
import OrderDetail from "../PosHome/OrderDetail/OrderDetail";
import Items from "../PosHome/Items/Items";
import Categories from "../PosHome/Categories/Categories";
import { Card } from "../../common/components";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/UserContext";
import useStorage from "../../common/utils/localStorage.util";
import { useSelector } from "react-redux";
/**
 * PosHome component rendering the Point of Sale (POS) interface.
 * @returns {JSX.Element} JSX element representing the PosHome component.
 */
const PosHome = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const isStoreSelected = useStorage.getItem("isStoreSelected");

  const orderDuplicateData = useSelector(
    (state) => state.posOrder.orderDuplicateData
  );
  const [selectedItems, setSelectedItems] = useState(
    orderDuplicateData?.orderedItems ?? []
  );
  const [selectedCategoryCardId, setSelectedCategoryCardId] = useState(null);
  const isPosUser =
    user?.role === "CASHIER" ||
    user?.role === "SALES MANAGER" ||
    user?.role === "SYSTEM MANAGER";

  useEffect(() => {
    if (
      user &&
      isPosUser &&
      user?.posUserLoggedinFromEmail &&
      !isStoreSelected
    ) {
      return navigate("/pos/stores");
    }
  }, [isStoreSelected, user, navigate, isPosUser]);

  const handleItemSelected = (item) => {
    setSelectedItems((prevItems) => [...prevItems, item]);
  };
  const handleItemDelete = (index) => {
    setSelectedItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };
  const handleCategorySelected = (categoryId) => {
    setSelectedCategoryCardId(categoryId);
  };
  return (
    <div className="flex lg:flex-nowrap flex-wrap gap-3">
      <Card className="w-full">
        <Categories onCategorySelected={handleCategorySelected} />
        <Items
          onItemSelected={handleItemSelected}
          selectedCategoryCardId={selectedCategoryCardId}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
        />
      </Card>
      <Card className="lg:w-auto w-full">
        <OrderDetail
          selectedItems={selectedItems}
          onItemDelete={handleItemDelete}
          setSelectedItems={setSelectedItems}
          orderDuplicateData={orderDuplicateData}
        />
      </Card>
    </div>
  );
};
export default PosHome;
// Sample Usage:
// import React from 'react';
// import { PosHome } from './PosHome';
//
//  Render the PosHome component
// <PosHome />
