import React from "react";
import { SearchBox } from "../../../../../assets/images/svgs";
import { useTranslation } from "react-i18next";

function OrderNote() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex flex-col items-center py-10  bg-gray-100 rounded-lg">
        <img
          src={SearchBox}
          alt="Search Box"
          className="ml-2 w-[40%] rounded-lg"
        />
        <span className="text-text-primary text-xl font-bold">
          {t("pos_dashboard.no_item")}{" "}
        </span>
        <div className="w-[376px] text-center text-gray-500 text-[16px] font-normal leading-tight">
          {t("pos_dashboard.empty_state_for_order")}
        </div>
      </div>
    </div>
  );
}

export default OrderNote;
