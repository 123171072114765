import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { createPassword, sendOtp } from "../../../../features/Auth/AuthSlice";
import { getUser, setUser } from "../../../common/utils/users.utils";
import { useUserContext } from "../../../../context/UserContext";

/**
 * Custom hook for managing the reset password functionality.
 * @returns {Object} An object containing methods and states for form handling and password validation.
 */

export const useResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateUser, setUserProfile, setToken } = useUserContext();

  const [isPasswordValid, setPasswordValid] = useState({
    minLength: false,
    specialChar: false,
    number: false,
    match: false,
  });

  const [otpResponse, setOtpResponse] = useState(null);
  const [otpError, setOtpError] = useState(null);
  const [email, setEmail] = useState(""); // State to store the retrieved email

  /**
   * Dispatches the sendOtp action.
   *
   * @param {string} email - The email to send the OTP.
   */

  const handleSendOtp = async (email) => {
    try {
      const response = await dispatch(sendOtp({ email }));
      setOtpResponse(response);
      setOtpError(null);
    } catch (error) {
      setOtpError(error);
      setOtpResponse(null);
    }
  };

  useEffect(() => {
    const storedUserData = getUser("user");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      const userEmail = parsedUserData?.user?.email;
      if (userEmail) {
        setEmail(userEmail); // Set email in state
      }
    }
  }, []);

  // validation schema for password
  const passwordSchema = z
    .object({
      newPassword: z
        .string()
        .min(
          8,
          "client_dashboard.management.settings.password.validations.newpassword_error"
        )
        .regex(
          /^(?=.*[A-Z])/,
          "client_dashboard.management.settings.password.validations.newpassword_error_uppercase"
        )
        .regex(
          /[!@#$%^&*(),.?":{}|<>]/,
          "client_dashboard.management.settings.password.validations.newpassword_error_special"
        )
        .regex(
          /\d/,
          "client_dashboard.management.settings.password.validations.newpassword_error_numeric"
        ),
      confirmPassword: z
        .string()
        .min(
          1,
          "client_dashboard.management.settings.password.validations.newpassword_error"
        ),
    })
    .refine((data) => data.newPassword === data.confirmPassword, {
      message:
        "client_dashboard.management.settings.password.validations.passwords_match_error",
      path: ["confirmPassword"],
    });

  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Function} watch - Function to watch changes in form inputs.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   */
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(passwordSchema),
  });

  /**
   * Handles the form submission for resetting the password.
   * Constructs a payload for password reset and dispatches an action.
   * Sets flags for password update and navigates to the account creation page.
   *
   * @param {Object} data - Form data containing new password and confirmation.
   */
  const onSubmit = (data) => {
    // Construct payload for password reset
    const payload = {
      email: email,
      password: data.newPassword,
      passwordConfirm: data.confirmPassword,
    };
    // Dispatch action to create/reset the password
    return dispatch(
      createPassword({ payload, successCallBack: passwordRouter })
    );
  };

  // Callback function on successful password update for navigation purposes
  const passwordRouter = (data) => {
    // Redirects the user upon successful password reset
    if (data?.data?.Succeeded) {
      const userData = data?.data?.data;

      // Only keep the profile from user data
      const profile = userData?.user?.profile;

      // Update the user with an empty object
      updateUser(userData?.user);

      // Set the profile separately
      setUserProfile(profile);

      // Set the token
      setToken(userData?.token);
      navigate("/create-account");
    }
  };
  /**
   * Validates the password against defined criteria.
   * @param {string} password - The new password entered by the user.
   * @param {string} confirmPassword - The confirm password entered by the user.
   */
  const validatePassword = (password, confirmPassword) => {
    const minLength = password.length >= 8;
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const number = /\d/.test(password);
    const match = password === confirmPassword;

    setPasswordValid({ minLength, specialChar, number, match });
  };

  /**
   * Monitors form input changes to validate password fields.
   * @effects
   * - Watches 'newPassword' and 'confirmPassword' fields for changes.
   * - Calls 'validatePassword' for updated values.
   * - Unsubscribes on component unmount to prevent leaks.
   */
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      validatePassword(value.newPassword, value.confirmPassword);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * Returns various methods and states for managing password reset functionality.
   *
   * @returns {Object}
   * {
   *   register: Function - Registers form inputs for validation and usage,
   *   handleSubmit: Function - Handles form submission,
   *   errors: Object - Holds form validation errors,
   *   getValues: Function - Retrieves form input values,
   *   onSubmit: Function - Handles the form submission for password reset,
   *   isPasswordValid: Object - Flags indicating password validation criteria (minLength, specialChar, number, match),
   *   passwordUpdated: Boolean - Indicates if the password has been updated,
   *   showPassword: Boolean - Indicates if the password field should be displayed,
   *   showConfirmPassword: Boolean - Indicates if the confirm password field should be displayed,
   *   togglePasswordVisibility: Function - Toggles the visibility of the password field,
   *   toggleConfirmPasswordVisibility: Function - Toggles the visibility of the confirm password field,
   *   validatePassword: Function - Validates the password against defined criteria,
   *   handleSendOtp: Function - Handles the sending of OTP (One-Time Password),
   *   otpResponse: Object - Holds the response from sending OTP,
   *   otpError: Object - Holds the error response if there is an issue sending OTP,
   * }
   * Usage Example:
   * Accesses form handling methods, validation flags, and OTP handling:
   * const { register, handleSubmit, errors, getValues, onSubmit, isPasswordValid, passwordUpdated, showPassword, showConfirmPassword, togglePasswordVisibility, toggleConfirmPasswordVisibility, validatePassword, handleSendOtp, otpResponse, otpError } = useResetPassword();
   *
   * register:
   *  Example usage: <input {...register("newPassword", { required: true })} />
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * errors:
   *  Example usage: {errors.newPassword && <span>New password is required</span>}
   * getValues:
   *  Example usage: const { newPassword, confirmPassword } = getValues();
   * onSubmit:
   *  Example usage: onSubmit(formData)
   * isPasswordValid:
   *  Example usage: isPasswordValid.minLength
   * passwordUpdated:
   *  Example usage: {passwordUpdated && <span>Password updated successfully!</span>}
   * showPassword:
   *  Example usage: {showPassword && <input type="password" />}
   * showConfirmPassword:
   *  Example usage: {showConfirmPassword && <input type="password" />}
   * togglePasswordVisibility:
   *  Example usage: togglePasswordVisibility()
   * toggleConfirmPasswordVisibility:
   *  Example usage: toggleConfirmPasswordVisibility()
   * validatePassword:
   *  Example usage: validatePassword(newPassword, confirmPassword)
   * handleSendOtp:
   *  Example usage: handleSendOtp(email)
   * otpResponse:
   *  Example usage: console.log(otpResponse)
   * otpError:
   *  Example usage: console.error(otpError)
   * }
   */
  return {
    register,
    handleSubmit,
    errors,
    getValues,
    onSubmit,
    isPasswordValid,
    validatePassword,
    handleSendOtp,
    otpResponse,
    otpError,
    isSubmitting,
  };
};
