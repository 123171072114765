import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getclientsSales } from "../../../../../features/Invoices/SaleInvoices/SaleInvoicesSlice";
import { getClientProfile } from "../../../../../features/Clients/clientSlice";
import { formatDateTime } from "../../../../../helpers/dateFormatter";

export const useTopClientSalesGraph = (startDate, endDate) => {
  const dispatch = useDispatch();
  const [dropdownOptions, setDropdownOptions] = useState([
    { value: null, label: "Select Client" },
  ]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [clientNames, setClientNames] = useState([]);

  const { data: getclientsSale, isLoading: saleInvoicesListLoading } = useSelector((state) => state.saleInvoices.getclientsSales);

  useEffect(() => {
    dispatch(
      getClientProfile({
        payload: {
          page: 1,
          pageSize: 5,
          sortColumn: "id",
          order: {
            id: "DESC",
          },
          condition: {},
          attributes: {},
        },
        successCallBack: onGetClientProfileSuccess,
      })
    );
  }, [dispatch]);

  const onGetClientProfileSuccess = (response) => {
    const clientOptions = response?.data?.data?.slice(0, 20).map((client) => ({
      value: client?.id,
      label: client?.profile?.fullName,
    }));
    setDropdownOptions([{ value: null, label: "Select Client" }, ...clientOptions]);
    setClientNames(clientOptions.map((client) => client.label));
  };

  useEffect(() => {
    if (startDate && endDate) {
      const payload = {
        getClientSalesByTimeFilterDto: {
          startDate: formatDateTime(startDate, "yyyy-MM-dd"),
          endDate: formatDateTime(endDate, "yyyy-MM-dd"),
        },
        userId: selectedClientId !== null ? +selectedClientId : null,
      };
      dispatch(getclientsSales({ payload }));
    }
  }, [startDate, endDate, selectedClientId, dispatch]);

  return {
    getclientsSale,
    dropdownOptions,
    saleInvoicesListLoading,
    selectedClientId,
    setSelectedClientId,
    clientNames,
  };
};
