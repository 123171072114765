import * as React from "react";

function ToggleOn(props) {
  return (
    <svg width="42" height="24" viewBox="0 0 42 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="42" height="24" rx="12" fill="#0086FF"/>
    <circle cx="30" cy="12" r="10" fill="white"/>
    </svg>
    
  );
}

export default ToggleOn;
