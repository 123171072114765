import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Card, CustomButton, Loader } from "../../../../common/components";
import {
  extendSubscription,
  getAllSubscriptions,
  getSingleSubscriptions,
} from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
import { DefaultImg } from "../../../../../assets/images";
import { PhoneIcon } from "../../../../common/Icons";

function ExtendSubscription({
  selectedItem,
  closeModal,
  subscriptionDetailId,
  selectedTab,
  getSingleClientDetail,
  clientProfile,
  subscriptionPlanId,
  subscriptionTableId,
}) {
  const dispatch = useDispatch();
  const subscriptionId =
    subscriptionDetailId ?? subscriptionPlanId ?? subscriptionTableId;
  const [customError, setCustomError] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const watchedEndDate = watch("endDate");

  useEffect(() => {
    if (clientProfile?.id === undefined || clientProfile.id === null) {
      if (subscriptionId) {
        dispatch(
          getSingleSubscriptions({
            subscriptionId,
            isFreeTrial: "PaidSubscriptions" === "FreeTrial",
          })
        );
      }
    }
  }, []);

  const { data: singleSubscriptiondata, isLoading: subscriptionLoading } =
  useSelector((state) => state.subscriptions.getSingleSubscriptions);
  const profile = singleSubscriptiondata?.clientUser?.profile ?? "-";
  const ProvidedBy = singleSubscriptiondata?.managedBy?.fullName ?? "-";
  const plan = singleSubscriptiondata?.planType ?? "-";
  const startDate = singleSubscriptiondata?.startDate ?? "-";
  const planendDate = singleSubscriptiondata?.endDate ?? "-";

  useEffect(() => {
    // Set the initial value of endDate to planendDate when the component loads
    setValue("endDate", planendDate);
  }, [planendDate, setValue]);

  useEffect(() => {
    if (watchedEndDate) {
      const endDateValue = new Date(watchedEndDate);
      const currentEndDate = new Date(planendDate);
      if (endDateValue <= currentEndDate) {
        setCustomError("Extend Date must be after the End Date.");
        return;
      }

      const diffTime = Math.abs(endDateValue - currentEndDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (singleSubscriptiondata?.plan_tenure === "ANNUAL" && diffDays > 20) {
        setCustomError("Annual plans must not have more than 20 days gap.");
      } else if (
        singleSubscriptiondata?.plan_tenure === "MONTHLY" &&
        diffDays > 10
      ) {
        setCustomError("Monthly plans must not have more than 10 days gap.");
      } else {
        setCustomError("");
      }
    }
  }, [watchedEndDate, planendDate]);
  const handleConfirm = (formData) => {
    if (!customError) {
      const payload = {
        subscriptionId,
        payload: { extendDate: formData.endDate },
        successCallBack: handleSuccess,
      };
      dispatch(extendSubscription(payload));
    }
  };

  const handleSuccess = () => {
    closeModal();
    fetchAllSubscriptions();
    getSingleClientDetail();
    
  };

  const fetchAllSubscriptions = () => {
    dispatch(
      getAllSubscriptions({
        payload: {
          page: 1,
          pageSize: 10,
          sortColumn: "id",
          order: { id: "DESC" },
          condition: {},
          attributes: {},
          periodId: 0,
        },
      })
    );
  };

  if (subscriptionLoading) {
    return <Loader />;
  }

  return (
    <div className="p-4">
      <div className="flex items-center">
        <img
          src={profile?.profilePic || DefaultImg}
          alt="Profile Pic"
          className="inline-block h-12 w-12 rounded-full border mr-3"
        />
        <div className="flex-grow">
          <p className="text-text-primary text-lg font-semibold">
            {profile?.fullName ?? "-"}
          </p>
          <div className="flex items-center text-text-gray font-normal mt-2">
            <PhoneIcon className="mr-2" />
            <span className="pl-2 text-text-primary text-sm font-normal">
              {profile?.phoneNumber ?? "-"}
            </span>
          </div>
        </div>
      </div>
      <p className="mt-4 text-base font-normal text-text-primary">
        Current Plan
      </p>
      <Card className="my-2 !p-4">
        <div className="md:flex gap-6">
          <div>
            <p className=" text-base font-medium text-text-primary">
              Current Plan
            </p>
            <p className="text-base font-normal text-text-secondary">
              {plan}
            </p>
          </div>
          <div>
            <p className="text-base text-text-primary font-medium">Provider</p>
            <p className="text-base font-normal text-text-secondary">
              {ProvidedBy ?? "-"}
            </p>
          </div>
          <div>
            <p className="text-base  text-text-primary font-medium">Start Date</p>
            <p className="text-base font-normal text-text-secondary">
              {startDate ?? "-"}
            </p>
          </div>
          <div>
            <p className="text-base  text-text-primary font-medium">End Date</p>
            <p className="text-base font-normal text-text-secondary">
              {planendDate ?? "-"}
            </p>
          </div>
        </div>
      </Card>
  

      <Card>
        <p className="text-text-primary font-semibold text-base">New Date</p>
        <div className="mt-4">
          <p className="text-text-primary font-normal text-base">End Date</p>
          <input
            className="w-full rounded-lg border-gray-200 mt-2"
            type="date"
            {...register("endDate", { required: "End date is required" })}
            min={new Date().toISOString().split("T")[0]}
          />
          {errors.endDate && (
            <p className="text-red-500">{errors.endDate.message}</p>
          )}
          {customError && <p className="text-red-500">{customError}</p>}
        </div>
      </Card>
      <div className="flex justify-end space-x-2 mt-4">
        <CustomButton
          text="Cancel"
          bgColor="bg-white"
          textColor="text-text-primary"
          border="border"
          width="20px"
          onClick={closeModal}
          className="hover:!bg-gray-200"
        />
        <CustomButton
          text="Confirm"
          width="20px"
          textColor="text-white"
          onClick={handleSubmit(handleConfirm)}
          disabled={!watchedEndDate || customError} 
        />
      </div>
    </div>
  );
}

export default ExtendSubscription;
