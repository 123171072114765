// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clientSettingsService from "./clientSettings";
import { showToast } from "../../components/common/utils/showToast.util";

/**
 * Initial state structure defining various properties related to client settings processes.
 * Each property represents a specific setting action/status with its associated data, error, success, loading, and message.
 * Properties such as getBusinessDetails, updateBusinessDetails
 * store data, error, success, loading, and message status for corresponding settings actions.
 */
const initialState = {
  getUserProfile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateUserProfile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getBusinessDetail: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateBusinessDetail: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Initiates the get profile detail process for a user.
 * @param {id} profileId - profile id
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const getUserProfile = createAsyncThunk(
  "getUserProfile",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ profileId }, thunkAPI) => {
    const response = await clientSettingsService.getUserProfile(profileId);
    return response?.data?.data;
  }
);

/**
 * Initiates the update profile detail process for a user.
 * @param {id} profileId - profile id
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ profileId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await clientSettingsService.updateUserProfile(
        profileId,
        payload
      );

      if (response?.data?.Succeeded) {
        showToast("User updated successfully!");
        successCallBack(response);
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the get business detail process for a user.
 * @param {id} businessId - business id
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const getBusinessDetail = createAsyncThunk(
  "getBusinessDetail",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ businessId }, thunkAPI) => {
    const response = await clientSettingsService.getBusinessDetail(businessId);
    return response?.data?.data;
  }
);

/**
 * Initiates the update business detail process for a user.
 * @param {id} businessId - business id
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const updateBusinessDetail = createAsyncThunk(
  "updateBusinessDetail",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ businessId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await await clientSettingsService.updateBusinessDetail(
        businessId,
        payload
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Business profile updated successfully!");

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for client setting related state management.
 * - `name`: Name of the slice (clientSettings)
 * - `initialState`: Initial state defining properties for various client setting actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getBusinessDetails, updateBusinessDetails, etc.).
 */
export const clientSettingsSlice = createSlice({
  name: "clientSettings",
  initialState,

  reducers: {
    /**
     * Resets the state for the client setting action.
     */
    reset: (state) => {
      state.getUserProfile = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.updateUserProfile = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getBusinessDetail = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.updateBusinessDetail = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getUserProfile action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getUserProfile.pending, (state) => {
        state.getUserProfile.isLoading = true;
        state.getUserProfile.message = "";
        state.getUserProfile.isError = false;
        state.getUserProfile.isSuccess = false;
        state.getUserProfile.data = null;
      })
      /**
       * Updates the state when getUserProfile action is fulfilled/successful.
       * Updates loading and success flags and sets getUserProfile data with the payload.
       */
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.getUserProfile.isLoading = false;
        state.getUserProfile.isSuccess = true;
        state.getUserProfile.data = action.payload;
      })
      /**
       * Updates the state when getUserProfile action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getUserProfile.rejected, (state, action) => {
        state.getUserProfile.isLoading = false;
        state.getUserProfile.isError = true;
        state.getUserProfile.data = null;
      })
      /**
       * Updates the state while the updateUserProfile action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(updateUserProfile.pending, (state) => {
        state.updateUserProfile.isLoading = true;
        state.updateUserProfile.message = "";
        state.updateUserProfile.isError = false;
        state.updateUserProfile.isSuccess = false;
        state.updateUserProfile.data = null;
      })
      /**
       * Updates the state when updateUserProfile action is fulfilled/successful.
       * Updates loading and success flags and sets updateUserProfile data with the payload.
       */
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.updateUserProfile.isLoading = false;
        state.updateUserProfile.isSuccess = true;
        state.updateUserProfile.data = action.payload;
      })
      /**
       * Updates the state when updateUserProfile action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.updateUserProfile.isLoading = false;
        state.updateUserProfile.isError = true;
        state.updateUserProfile.data = null;
      })
      /**
       * Updates the state while the getBusinessDetail action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getBusinessDetail.pending, (state) => {
        state.getBusinessDetail.isLoading = true;
        state.getBusinessDetail.message = "";
        state.getBusinessDetail.isError = false;
        state.getBusinessDetail.isSuccess = false;
        state.getBusinessDetail.data = null;
      })
      /**
       * Updates the state when getBusinessDetail action is fulfilled/successful.
       * Updates loading and success flags and sets getBusinessDetail data with the payload.
       */
      .addCase(getBusinessDetail.fulfilled, (state, action) => {
        state.getBusinessDetail.isLoading = false;
        state.getBusinessDetail.isSuccess = true;
        state.getBusinessDetail.data = action.payload;
      })
      /**
       * Updates the state when getBusinessDetail action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getBusinessDetail.rejected, (state, action) => {
        state.getBusinessDetail.isLoading = false;
        state.getBusinessDetail.isError = true;
        state.getBusinessDetail.data = null;
      })
      /**
       * Updates the state while the updateBusinessDetail action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(updateBusinessDetail.pending, (state) => {
        state.updateBusinessDetail.isLoading = true;
        state.updateBusinessDetail.message = "";
        state.updateBusinessDetail.isError = false;
        state.updateBusinessDetail.isSuccess = false;
        state.updateBusinessDetail.data = null;
      })
      /**
       * Updates the state when updateBusinessDetail action is fulfilled/successful.
       * Updates loading and success flags and sets updateBusinessDetail data with the payload.
       */
      .addCase(updateBusinessDetail.fulfilled, (state, action) => {
        state.updateBusinessDetail.isLoading = false;
        state.updateBusinessDetail.isSuccess = true;
        state.updateBusinessDetail.data = action.payload;
      })
      /**
       * Updates the state when updateBusinessDetail action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateBusinessDetail.rejected, (state, action) => {
        state.updateBusinessDetail.isLoading = false;
        state.updateBusinessDetail.isError = true;
        state.updateBusinessDetail.data = null;
      });
  },
});

/**
 * Destructures the reset action from the clientSettingsSlice actions.
 * - `reset`: Action function to reset the client setting related state.
 */

// sample usage

// import { reset } from './yourclientSettingsSlicePath';

// Using the reset action
// const reset = () => {
//   store.dispatch(reset());
// };

export const { reset } = clientSettingsSlice.actions;

/**
 * Exports the default reducer generated by clientSettingsSlice.
 * This reducer handles state updates for client settingrelated actions.
 */

// sample usage

// import clientSettingsReducer from "./yourclientSettingsSlicePath";

// Using the default reducer
// const initialState = {
// Your initial state for client setting// };

// Creating a store with the authReducer handling client settingrelated actions
// const store = configureStore({
// reducer: {
// clientSettingsReducer: clientSettingsReducer,
// other reducers...
// },
// preloadedState: {
// auth: initialState,
// other preloaded states...
// },
// });
export default clientSettingsSlice.reducer;
