import React, { useEffect, useState } from "react";
import { SettingsIcon, BellIcon, ToggleIcon } from "../../../../common/Icons";
import { CustomButton } from "../../../../common/components";
import AdminNavNotifications from "../AdminNotifications/AdminNavNotification/AdminNavNotifications";

const Navbar = ({ sidebarOpen, setSidebarOpen, onSettingsClick, activeMenuItem }) => {
  const [isNotificationOpen, setNotificationOpen] = useState(false);

  const onNotificationClick = () => {
    setNotificationOpen(true);
  };

  return (
    <div className="sticky top-0 z-40 flex py-2 px-6 shrink-0 items-center border-b border-neutral-200 bg-white">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <span className="sr-only">Open sidebar</span>
        <ToggleIcon strokeColor />
      </button>
      <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
      <span className="font-semibold text-xl">{activeMenuItem}</span>
      <div className="ml-auto flex items-center space-x-4">
        <CustomButton
          width="w-fit"
          textColor="text-text-primary"
          border="border"
          icon={<BellIcon />}
          onClick={onNotificationClick}
          padding="px-0 py-0"
          className="border-none"
        />
        <CustomButton
          width="w-fit"
          textColor="text-text-primary"
          border="border"
          onClick={onSettingsClick}
          padding="px-0 py-0"
          className="!border-none"
          icon={<SettingsIcon />}
        />
      </div>
      {isNotificationOpen && (
        <AdminNavNotifications
          isNotificationOpen={isNotificationOpen}
          setNotificationOpen={setNotificationOpen}

        />
      )}
    </div>
  );
};

export default Navbar;
