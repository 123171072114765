import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDown, MinusIcon, PlusIcon } from "../components/common/Icons";
import useAccordion from "../hooks/useAccordian";

const Faqs = () => {
    const { t } = useTranslation();

  const faqs = {
    "General Account Management": [
      {
        question:   t("landingpage.general.question1"),
  
        answer:   t("landingpage.general.answer1"),
      },
      {
        question:   t("landingpage.general.question2"),
  
        answer:   t("landingpage.general.answer2"),
      },
      {
        question:   t("landingpage.general.question3"),
  
        answer:   t("landingpage.general.answer3"),
      },
      {
        question:   t("landingpage.general.question4"),
  
        answer:   t("landingpage.general.answer4"),
      },
      {
        question:   t("landingpage.general.question5"),
  
        answer:   t("landingpage.general.answer5"),
      },
      {
        question:   t("landingpage.general.question6"),
  
        answer:   t("landingpage.general.answer6"),
      },
      {
        question:   t("landingpage.general.question7"),
  
        answer:   t("landingpage.general.answer7"),
      },
      {
        question:   t("landingpage.general.question8"),
  
        answer:   t("landingpage.general.answer8"),
      },
      {
        question:   t("landingpage.general.question9"),
  
        answer:   t("landingpage.general.answer9"),
      },
      {
        question:   t("landingpage.general.question10"),
  
        answer:   t("landingpage.general.answer10"),
      },
    ],
    "Subscription and Billing": [
      {
        question:   t("landingpage.subscriptionBilling.question1"),
  
        answer:   t("landingpage.subscriptionBilling.answer1"),
      },
      {
        question:   t("landingpage.subscriptionBilling.question2"),
  
        answer:   t("landingpage.subscriptionBilling.answer2"),
      },
    ],
    "Dashboard and Analytics": [
      {
        question:   t("landingpage.dashboardAnalytics.question1"),
  
        answer:   t("landingpage.dashboardAnalytics.answer1"),
      },
      {
        question:   t("landingpage.dashboardAnalytics.question2"),
  
        answer:   t("landingpage.dashboardAnalytics.answer2"),
      },
    ],
    "Invoice and Inventory Management": [
      {
        question:   t("landingpage.invoiceInventoryManagement.question1"),
  
        answer:   t("landingpage.invoiceInventoryManagement.answer1"),
      },
      {
        question:   t("landingpage.invoiceInventoryManagement.question2"),
  
        answer:   t("landingpage.invoiceInventoryManagement.answer2"),
      },
    ],
    "Reports and Analytics": [
      {
        question:   t("landingpage.reportsAnalytics.question"),
  
        answer:   t("landingpage.reportsAnalytics.answer"),
      },
    ],
    "User and Store Management": [
      {
        question:   t("landingpage.userStoreManagement.question"),
  
        answer:   t("landingpage.userStoreManagement.answer"),
      },
    ],
    "Promotions and Settings": [
      {
        question:   t("landingpage.promotionsSettings.question1"),
  
        answer:   t("landingpage.promotionsSettings.answer1"),
      },
      {
        question:   t("landingpage.promotionsSettings.question2"),
  
        answer:   t("landingpage.promotionsSettings.answer2"),
      },
    ],
  };
  const { AccordionRefs, closeCurrent } = useAccordion();
  const [activeTab, setActiveTab] = useState(Object.keys(faqs)[0]);
  const [activeFaq, setActiveFaq] = useState(0);

  useEffect(() => {
    setActiveFaq(0);
  }, [activeTab]);
 
  return (
    <React.Fragment>
      <div className="w-full faqpagehero 1xl:px-29 px-4 relative">
        <div className="text-center xs:max-w-2xl mx-auto space-y-5">
          <h1 className="font-bold text-5xl md:leading-wider leading-tight lg:pt-44 pt-36">
          {t("landingpage.faqsHeader.title")}{" "}
          </h1>
          <p className="mt-2 md:text-base text-sm text-gray-600 font-medium">
          {t("landingpage.faqsHeader.tagline")}{" "}

          </p>
        </div>
        <div className="grid grid-cols-12 lg:mt-20 mt-16">
          {/* desktop */}
          <div className="p-3 rounded-xl bg-neutral-50 space-y-1 xl:col-span-3 lg:col-span-4 lg:block hidden self-start">
            {Object.keys(faqs).map((tab) => (
              <button
                className={`p-2 block w-full text-left rounded-lg bg-transparent text-sm font-medium ${
                  tab === activeTab
                    ? "text-text-link bg-white shadow"
                    : "text-text-primary"
                }`}
                key={tab}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          {/* mobile */}
          <div className="lg:hidden col-span-12 relative">
            <Listbox value={activeTab} onChange={setActiveTab}>
              <ListboxButton className="p-3 flex justify-between items-center w-full text-left rounded-lg border border-border-primary bg-white text-base text-text-primary">
                <span>{activeTab}</span>
                <ArrowDown />
              </ListboxButton>
              <ListboxOptions className="absolute z-10 mt-2 w-full bg-white rounded-md shadow-lg">
                {Object.keys(faqs).map((tab) => (
                  <ListboxOption key={tab} value={tab}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-neutral-100" : ""
                        } block px-4 py-2 text-sm text-text-primary w-full text-left`}
                        onClick={() => setActiveTab(tab)}
                      >
                        {tab}
                      </button>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Listbox>
          </div>
          {/* faq content */}
          <div className="xl:col-span-9 lg:col-span-8 col-span-12">
            <dl>
              {faqs[activeTab]?.map((faq, index) => (
                <Disclosure
                  as="div"
                  key={faq.question}
                  className="lg:p-5 p-0 lg:mt-0 mt-3"
                  defaultOpen={index === activeFaq}
                >
                  {({ open, close }) => {
                    return (
                      <>
                        <dt
                          className={
                            open
                              ? "border border-border-primary bg-white lg:p-6 p-4 rounded-xl"
                              : ""
                          }
                          onClick={closeCurrent}
                          ref={(el) => (AccordionRefs.current[index] = el)}
                        >
                          <DisclosureButton className="flex w-full items-start gap-4 cursor-pointer">
                            <span className="flex h-7 items-center lg:mt-0 mt-2">
                              {open ? (
                                <span
                                  className="p-2 cursor-pointer border border-border-primary rounded-md"
                                  tabIndex={0}
                                >
                                  <MinusIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <span
                                  className="p-2 cursor-pointer border border-border-primary rounded-md"
                                  tabIndex={0}
                                >
                                  <PlusIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </span>
                            <div className="flex flex-col text-left">
                              <span
                                className={`lg:text-xl text-lg font-semibold leading-snug ${
                                  open ? "text-text-link" : "text-text-primary"
                                }`}
                              >
                                {faq.question}
                              </span>
                              <DisclosurePanel as="dd" className="mt-2">
                                <p className="text-base text-text-secondary">
                                  {faq.answer}
                                </p>
                              </DisclosurePanel>
                            </div>
                          </DisclosureButton>
                        </dt>
                      </>
                    );
                  }}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
      {/* footer */}
    </React.Fragment>
  );
};

export default Faqs;


