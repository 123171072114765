import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllClients } from "../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { formatDateTime } from "../../../../../helpers/dateFormatter";

export const useTopClientSalesTable = (startDate, endDate) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: sales, isLoading: cardListLoading } = useSelector(
    (state) => state.dashboard.getAllClients
  );

  useEffect(() => {
    dispatch(
      getAllClients({
        payload: {
          page: 1,
          pageSize: 10000,
          sortColumn: "id",
          order: {
            id: "DESC",
          },
          condition: {
            startDate: formatDateTime(startDate, "yyyy-mm-dd"),
            endDate: formatDateTime(endDate, "yyyy-mm-dd"),
          },
          attributes: {},
          periodId: 0,
        },
      })
    );
  }, [dispatch, startDate, endDate]);

  const handleViewClick = (id) => {
    navigate(`/admin/sales/details/${id}`);
  };

  const headers = [
    { label: "Client", key: "client" },
    { label: "Subscription Date", key: "subscriptiondate" },
    { label: "Invoice No", key: "invoiceno" },
    { label: "Total Sales", key: "totalsales" },
    { label: "Net Profit", key: "netprofit" },
    { label: "Latest Sale", key: "latestsale" },
    { label: "Last Inventory", key: "lastinventory" },
    { label: "Latest Purchases", key: "latestpurchases" },
    { label: "Latest Expense Transaction", key: "latestexpensetransaction" },
  ];

  const tableBody = sales?.map((sale) => ({
    id: sale.id,
    client: sale.name,
    subscriptiondate: formatDateTime(sale.subscriptionStartDate, "yyyy-mm-dd"),
    invoiceno: sale.subscriptionInvoiceNumber,
    totalsales: `${sale.totalSales} SAR`,
    netprofit: `${sale.netProfit} SAR`,
    latestsale: formatDateTime(sale.latestSaleDate, "yyyy-mm-dd"),
    lastinventory: formatDateTime(sale.lastInventory, "yyyy-mm-dd"),
    latestpurchases: formatDateTime(sale.latestPurchaseDate, "yyyy-mm-dd"),
    latestexpensetransaction: formatDateTime(
      sale.latestExpenseDate,
      "yyyy-mm-dd"
    ),
  }));

  const dropdownOptions = [
    {
      label: "View Detail",
      value: "view",
      action: (id) => handleViewClick(id?.id),
    },
  ];

  return {
    dropdownOptions,
    headers,
    handleViewClick,
    tableBody,
    cardListLoading,
  };
};
