import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  changeStatus,
  getSingleSubscriptions,
} from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";

/**
 * Custom hook for managing the create account form.
 * @returns {Object} An object containing methods and states for form handling and image upload validation.
 */
export const useSubscribtionClientDetail = () => {
  const { id: subscriptionId } = useParams();
  const dispatch = useDispatch();
  const { state: selectedTab   } = useLocation();

  const { data: singleSubscriptiondata, isLoading: subscriptionLoading } =
  useSelector((state) => state.subscriptions.getSingleSubscriptions);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const role = storedUserData?.user?.role;
  const subscriptionPlanId =subscriptionId
  const openModal = (item, modalType) => {
    // setSelectedItem(item);
    setActiveModal(modalType);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside of the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeModal = () => {
    setActiveModal(null);
    setSelectedItem(null);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const changeSubscriptionStatus = (item) => {
    dispatch(changeStatus({ subscriptionId: item?.id }));
  };
  useEffect(() => {
    if (subscriptionId) dispatch(getSingleSubscriptions({ subscriptionId, isFreeTrial: selectedTab === 'FreeTrial' ? true : false }));
  }, [ subscriptionId]);

  const profile = singleSubscriptiondata?.clientUser?.profile ?? "-";
  const businessDetail =
    singleSubscriptiondata?.clientUser?.businessDetail ?? "-";
  const email = singleSubscriptiondata?.clientUser?.email ?? "-";
  const PrivideBy = singleSubscriptiondata?.managedBy?.fullName ?? "-";
  const subTotal = singleSubscriptiondata?.subTotal ?? "-";
  const plan = singleSubscriptiondata?.plan_tenure ?? "-";
  const planType = singleSubscriptiondata?.planType ?? "-";

  const startDate = singleSubscriptiondata?.startDate ?? "-";
  const endDate = singleSubscriptiondata?.endDate ?? "-";
  const typeOfBusiness =
    singleSubscriptiondata?.clientUser?.businessDetail?.typeOfBusiness ?? "-";
  const commercialRegister =
    singleSubscriptiondata?.clientUser?.businessDetail?.commercialRegister ??
    "-";
  const website =
    singleSubscriptiondata?.clientUser?.businessDetail?.website ?? "-";
  const companyAddress =
    singleSubscriptiondata?.clientUser?.businessDetail?.companyAddress ?? "-";
  const companyName =
    singleSubscriptiondata?.clientUser?.businessDetail?.companyName ?? "-";
  const vatAmount = singleSubscriptiondata?.vatAmount ?? "-";
  const status = singleSubscriptiondata?.subscriptionStatus;

  let subscribtiondropdownOptions = [
    {
      label: "Change Status",
      value: "edit",
      textColor: "text-text-primary",
      action: (item) => changeSubscriptionStatus(singleSubscriptiondata),
    },
    {
      label: "Change Subscription",
      value: "delete",
      textColor: "text-text-primary",
      action: () => setActiveModal("ChangeSubscription"),

    },
    {
      label: "Add Branches",
      value: "add",
      textColor: "text-text-primary",
      action: () => setActiveModal("AddBranches"),
    },
    {
      label: "Extend Subscription",
      value: "extend",
      textColor: "text-text-primary",
      action: () => setActiveModal("ExtendSubscription"),

    },
    {
      label: "Cancel Subscription",
      value: "cancel",
      textColor: "text-red-500",
      action: () => setActiveModal("CancelSubscription"),

    },
  ];
  
  // Filter options based on status
  if ( status === "inactive") {
    subscribtiondropdownOptions = subscribtiondropdownOptions.filter(option => option.label === "Change Status");
  } else if (status === "expired") {
    subscribtiondropdownOptions = subscribtiondropdownOptions.filter(option => option.label !== "Add Branches");
  }
  else if (status === "cancelled") {
    subscribtiondropdownOptions = subscribtiondropdownOptions.filter(option => option.label === "");
  }
  
  // Remove "Cancel Subscription" option for ACCOUNTING role
  if (role === "ACCOUNTING") {
    subscribtiondropdownOptions = subscribtiondropdownOptions.filter(option => option.label !== "Cancel Subscription");
  }
  
  return {
    toggleDropdown,
    isDeleteModalOpen,
    isModalOpen,
    dropdownVisible,
    setDeleteModalOpen,
    dropdownRef,
    setModalOpen,
    subscribtiondropdownOptions,
    activeModal,
    closeModal,
    endDate,
    startDate,
    plan,
    subTotal,
    PrivideBy,
    companyName,
    companyAddress,
    website,
    commercialRegister,
    typeOfBusiness,
    vatAmount,
    status,
    selectedItem,
    profile,
    businessDetail,
    email,
    selectedTab,
    subscriptionLoading,singleSubscriptiondata,
    planType,
    subscriptionPlanId,
    role,
    
  };
};

export default useSubscribtionClientDetail;
