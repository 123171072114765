import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import useStorage from "../components/common/utils/localStorage.util";

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
  const { user, updateUser, setToken, setUserProfile } = useUserContext();
  const navigate = useNavigate();

  const isClient = user?.role === "CLIENT";

  useEffect(() => {
    const checkTrialAndSubscriptionStatus = () => {
      const currentDate = new Date();

      if (user && isClient) {
        if (user.isFreeTrial) {
          const trialEndDate = new Date(user.freeTrial.endDate);
          if (currentDate > trialEndDate) {
            console.log(currentDate > trialEndDate, "logout");
            handleLogout();
            return;
          }
        }

        if (user.isSubscriptionPlanCompleted) {
          const subscriptionEndDate = new Date(user.subscription.endDate);
          if (currentDate > subscriptionEndDate) {
            console.log(
              "currentDate > subscriptionEndDate and logout",
              currentDate > subscriptionEndDate
            );
            handleLogout();
            return;
          }
        }

        if (!user.isFreeTrial && !user.isSubscriptionPlanCompleted) {
          console.log(
            "!user.isFreeTrial && !user.isSubscriptionPlanCompleted and logout",
            !user.isFreeTrial && !user.isSubscriptionPlanCompleted
          );
          handleLogout();
        }
      }
    };

    const handleLogout = () => {
      updateUser(null);
      setToken(null);
      setUserProfile(null);
      useStorage.clearStorage();
      useStorage.setItem("logout", Date.now().toString());
      navigate("/");
    };

    checkTrialAndSubscriptionStatus();
    const intervalId = setInterval(
      checkTrialAndSubscriptionStatus,
      24 * 60 * 60 * 1000
    );

    return () => clearInterval(intervalId);
  }, [user, updateUser, setToken, setUserProfile, navigate, isClient]);

  return user && roles.includes(user.role) ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

export default ProtectedRoute;
