/**
 * Manages state for pos user.
 * Defines initial state for each user action.
 */

// Import necessary functions and modules from Redux Toolkit

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createPromotionApi from "./PromotionsService";
import { showToast } from "../../components/common/utils/showToast.util";
import store from "../../store/store";

/**
 * Initial state structure defining various properties related to pos user processes.
 * Each property represents a specific pos user action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllPromotions, signUp, verifyOtp, sendOtp, forgetPassword, createPassword, resetPassword, createAccount, and getAllPromotionsAndSignUpWithOAuth
 * store data, error, success, loading, and message status for corresponding pos user actions.
 */
const initialState = {
  getAllPromotions: {
    data: null,
    totalPages: 0,
    totalRecords: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSinglePromotion: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addPromotions: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the pos user process.
 * These action types are prefixed with the 'pos-user' base path for better organization.
 */
const BASE = "promotion";

export const ActionTypes = {
  GET_ALL_PROMOTIONS: `${BASE}/get-all`, // Action type for get all users
  ADD_PROMOTIONS: `${BASE}`, // Action type for get all users
};

// Creating an instance of the pos-user service with a base URL 'auth'
const promotionService = createPromotionApi("promotion");

/**
 * Initiates the getAllPromotions process for a user.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting users
 *    order: order for sorting users by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllPromotions.
 */
export const getAllPromotions = createAsyncThunk(
  ActionTypes.GET_ALL_PROMOTIONS,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload }, thunkAPI) => {
    const response = await promotionService.getAllPromotions(payload);
    if (response?.data?.Succeeded) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

/**
 * Initiates the getAllPromotions process for a user.
 * @param {object} userId id of the user
 * @param {function} successCallBack - Callback function upon successful getSinglePromotion.
 */
export const getSinglePromotion = createAsyncThunk(
  `${BASE}/userId`,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ userId }, thunkAPI) => {
    const response = await promotionService.getSinglePromotion(userId);
    return response?.data?.data;
  }
);

/**
 * Initiates the updatePostUser process for a user.
 * @param {object} userId id of the user
 * @param {object} payload
 *    {
      fullName: name of the user
      email: email of the user
      fastAccessCode: fast access code of the user
      password: password of the user
      role: role of the user
      accessToDashboard: access to dashboard true or false
      accessToPos: access to dashboard true or false
}
 * @param {function} successCallBack - Callback function upon successful updatePromotion.
 */
export const updatePromotion = createAsyncThunk(
  `${BASE}/userId`,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ userId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await promotionService.updatePromotion(userId, payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Promotion updated successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addPromotions process for a user.
 * @param {object} userId id of the user
 * @param {object} payload
 *    {
      fullName: name of the user
      email: email of the user
      fastAccessCode: fast access code of the user
      password: password of the user
      role: role of the user
      accessToDashboard: access to dashboard true or false
      accessToPos: access to dashboard true or false
}
 * @param {function} successCallBack - Callback function upon successful addPromotions.
 */
export const addPromotions = createAsyncThunk(
  ActionTypes.ADD_PROMOTIONS,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await promotionService.addPromotions(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Promotion added successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addPromotions process for a user.
 * @param {object} userId id of the user
 * @param {object} payload
 *    {
      fullName: name of the user
      email: email of the user
      fastAccessCode: fast access code of the user
      password: password of the user
      role: role of the user
      accessToDashboard: access to dashboard true or false
      accessToPos: access to dashboard true or false
}
 * @param {function} successCallBack - Callback function upon successful addPromotions.
 */
export const deletePromotions = createAsyncThunk(
  "deletePromotions",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ userId, successCallBack }, thunkAPI) => {
    try {
      const response = await promotionService.deletePromotions(userId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Promotion deleted successfully!", "success", async () => {
          const undoResponse = await promotionService.undoPromotion(userId);
          if (undoResponse?.data?.Succeeded) {
            const payload = {
              page: 1,
              pageSize: 10,
              sortColumn: "id",
              order: {
                id: "DESC",
              },
              condition: {},
              attributes: {},
            };
            store.dispatch(getAllPromotions({ payload }));
          }
        });

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for pos-user related state management.
 * - `name`: Name of the slice (posUser)
 * - `initialState`: Initial state defining properties for various pos user actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getAllPromotions, updatePromotion etc).
 */
export const promotionSlice = createSlice({
  name: "promotion",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllPosUser action.
     */
    reset: (state) => {
      state.getAllPromotions = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSinglePromotion = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllPromotions action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllPromotions.pending, (state) => {
        state.getAllPromotions.isLoading = true;
        state.getAllPromotions.message = "";
        state.getAllPromotions.isError = false;
        state.getAllPromotions.isSuccess = false;
        state.getAllPromotions.data = null;
      })
      /**
       * Updates the state when getAllPromotions action is fulfilled/successful.
       * Updates loading and success flags and sets getAllPromotions data with the payload.
       */
      .addCase(getAllPromotions.fulfilled, (state, action) => {
        state.getAllPromotions.isLoading = false;
        state.getAllPromotions.isSuccess = true;
        state.getAllPromotions.data = action.payload.data;
        state.getAllPromotions.totalPages = action.payload.totalPages;
        state.getAllPromotions.totalRecords = action.payload.TotalRecords;
      })
      /**
       * Updates the state when getAllPromotions action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllPromotions.rejected, (state, action) => {
        state.getAllPromotions.message = action.payload?.message;
        state.getAllPromotions.isLoading = false;
        state.getAllPromotions.isError = true;
        state.getAllPromotions.data = null;
      })
      /**
       * Updates the state while the getSinglePromotion action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSinglePromotion.pending, (state) => {
        state.getSinglePromotion.isLoading = true;
        state.getSinglePromotion.message = "";
        state.getSinglePromotion.isError = false;
        state.getSinglePromotion.isSuccess = false;
        state.getSinglePromotion.data = null;
      })
      /**
       * Updates the state when getSinglePromotion action is fulfilled/successful.
       * Updates loading and success flags and sets getSinglePromotion data with the payload.
       */
      .addCase(getSinglePromotion.fulfilled, (state, action) => {
        state.getSinglePromotion.isLoading = false;
        state.getSinglePromotion.isSuccess = true;
        state.getSinglePromotion.data = action.payload;
      })
      /**
       * Updates the state when getSinglePromotion action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSinglePromotion.rejected, (state, action) => {
        state.getSinglePromotion.message = action.payload?.message;
        state.getSinglePromotion.isLoading = false;
        state.getSinglePromotion.isError = true;
        state.getSinglePromotion.data = null;
      })
      /**
       * Updates the state while the addPromotions action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addPromotions.pending, (state) => {
        state.addPromotions.isLoading = true;
        state.addPromotions.message = "";
        state.addPromotions.isError = false;
        state.addPromotions.isSuccess = false;
        state.addPromotions.data = null;
      })
      /**
       * Updates the state when addPromotions action is fulfilled/successful.
       * Updates loading and success flags and sets addPromotions data with the payload.
       */
      .addCase(addPromotions.fulfilled, (state, action) => {
        state.addPromotions.isLoading = false;
        state.addPromotions.isSuccess = true;
        state.addPromotions.data = action.payload;
      })
      /**
       * Updates the state when addPromotions action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addPromotions.rejected, (state, action) => {
        state.addPromotions.message = action.payload?.message;
        state.addPromotions.isLoading = false;
        state.addPromotions.isError = true;
        state.addPromotions.data = null;
      });
  },
});

/**
 * Destructures the reset action from the promotionSlice actions.
 * - `reset`: Action function to reset the pos-user related state.
 */

// sample usage

// import { reset } from './promotionSlice';

// Using the reset action
// const resetAuth = () => {
//   // Dispatching the reset action to reset pos-user state
//   store.dispatch(reset());
// };

export const { reset } = promotionSlice.actions;

/**
 * Exports the default reducer generated by promotionSlice.
 * This reducer handles state updates for pos-user related actions.
 */

// sample usage

// import posUserReducer from "./promotionSlice";

// Using the default reducer
// const initialState = {
// Your initial state for pos-user actions
// };

// Creating a store with the posUserReducer handling pos-user related actions
// const store = configureStore({
// reducer: {
// auth: posUserReducer,
// other reducers...
// },
// preloadedState: {
// auth: initialState,
// other preloaded states...
// },
// });
export default promotionSlice.reducer;
