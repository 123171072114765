import React from "react";
import {
  CustomButton,
  InputGroup,
  SelectGroup,
} from "../../../../../../common/components";
import useAddNewUser from "./useAddNewUser";

const Role = [
  { value: "", label: "Select Role" },
  { value: "ACCOUNT_MANAGER", label: "Account Manager" },
  { value: "ACCOUNTING", label: "Accounting" },
];

function AddNewUser({ onClose, setUpdateClient }) {
  const { onSubmit, handleSubmit, register, errors, isValid, onCancel } =
    useAddNewUser({ onClose, setUpdateClient });

  return (
    <div className="p-3">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 ">
        {/* full name */}
        <InputGroup
          labelText="Full Name"
          htmlfor="fullname"
          placeholder="Enter Full Name"
          inputName="fullname"
          register={register}
          errors={errors}
        />
        {/* phone number  */}
        <InputGroup
          labelText="Phone Number"
          htmlfor="phoneNumber"
          inputName="phoneNumber"
          placeholder="Enter Phone Number"
          register={register}
          errors={errors}
        />
        {/* email */}
        <InputGroup
          labelText="Email"
          htmlfor="email"
          inputName="email"
          placeholder="Enter Email"
          register={register}
          errors={errors}
        />
        <SelectGroup
          labelText="Role"
          htmlfor="role"
          inputName="role"
          options={Role}
          register={register}
          errors={errors}
        />
        <div className="flex justify-end space-x-2 pt-4 ">
          <CustomButton
            width="20px"
            text="Cancel"
            bgColor="bg-white"
            textColor="text-text-primary"
            border="border"
            onClick={onCancel}
          />
          <CustomButton
            width="20px"
            text="Add"
            type="submit"
            textColor="text-white"
            disabled={!isValid}
          />
        </div>
      </form>
    </div>
  );
}

export default AddNewUser;
