import { useTranslation } from "react-i18next";
import { CaretRight } from "../../Icons";

/**
 * Breadcrumb component.
 * Renders a breadcrumb navigation based on the provided pages data.
 *
 * @param {Object[]} pages - Array of pages for breadcrumb navigation.
 * @param {string} pages[].name - Name of the page.
 * @param {string} pages[].href - URL of the page.
 * @param {boolean} pages[].current - Indicates if the page is current or active.
 * @returns {JSX.Element} JSX code for rendering the Breadcrumb component.
 */

export default function Breadcrumb({ pages }) {
  const { t } = useTranslation();

  return (
    <nav className="flex flex-wrap" aria-label="Breadcrumb">
      <ol className="flex flex-wrap items-center space-x-0 lg:space-x-1">
        {pages &&
          pages?.map((page, i) => (
            <li key={page.name}>
              <div className="flex items-center space-x-1">
                {i !== 0 && <CaretRight />}
                <a
                  href={page.href}
                  className={`text-sm font-medium text-gray-500 hover:text-gray-700 whitespace-nowrap ${
                    page.current && "!font-bold"
                  }`}
                  aria-current={page.current ? "page" : undefined}
                >
                  {t(page.name)}
                </a>
              </div>
            </li>
          ))}
      </ol>
    </nav>
  );
}

// Sample Usage:
// import Breadcrumb from "path/to/Breadcrumb";
// const pages = [
//   { name: "Home", href: "/", current: false },
//   { name: "Products", href: "/products", current: true },
//   { name: "Shoes", href: "/products/shoes", current: false },
// ];
// <Breadcrumb pages={pages} />
