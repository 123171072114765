import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "../../../../../features/Customers/CustomersSlice";
import { checkOutOrder } from "../../../../../features/PosOrder/PosOrderSlice";
import { showToast } from "../../../../common/utils/showToast.util";

export const useSplitEqually = (
  setCheckOutVisible,
  setOrderCheckoutPaid,
  setIsCheckoutModalOpen
) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cash");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef();

  const { data: customerList, isLoading: customerListLoading } = useSelector(
    (state) => state.customers.getAllCustomers
  );

  const { data: paymentMethodsList, isLoading: paymentMethodsListLoading } =
    useSelector((state) => state.payments.getPaymentMethods);

  const orderCheckoutData = useSelector(
    (state) => state.posOrder.orderCheckoutData
  );

  const orderType = orderCheckoutData?.orderType;
  const totalAmount = Number(orderCheckoutData?.totalAmount)?.toFixed() || 0;
  const selectedCustomerId = selectedCustomer?.id;
  const pricePerParts = orderCheckoutData?.checkout?.perPartPrice;
  const totalParts = orderCheckoutData?.checkout?.splitByEqually?.totalParts;
  const remainingParts =
    orderCheckoutData?.checkout?.splitByEqually?.remainingParts;
  const paidParts = orderCheckoutData?.checkout?.splitByEqually?.paidParts;
  const checkoutType = orderCheckoutData?.checkout?.checkoutType;

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCustomers({ payload }));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCard && paymentMethodsList?.length > 0) {
      const firstCard = paymentMethodsList?.find(
        (method) => method?.type === "CARD" && method?.paymentCard
      )?.paymentCard?.cards?.[0]?.name;
      setSelectedCard(firstCard || null);
    }
  }, [paymentMethodsList, selectedCard]);

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsOpen(false);
  };

  const handleSelectionChange = (value) => {
    setSelectedCard(value);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const [parts, setParts] = useState(() => {
    return Array.from({ length: 2 }, (_, index) => ({
      name: `Part ${index + 1}`,
      price: (totalAmount / 2).toFixed(2),
      selected: false,
      deletable: false,
    }));
  });

  useEffect(() => {
    if (totalParts) {
      setParts(
        Array.from({ length: totalParts }, (_, index) => ({
          name: `Part ${index + 1}`,
          price: (totalAmount / totalParts).toFixed(2),
          selected: index < paidParts ? true : false,
          deletable: false,
        }))
      );
    }
  }, []);

  const addPart = useCallback(() => {
    setParts((currentParts) => {
      const newLength = currentParts.length + 1;
      const newPrice = (totalAmount / newLength).toFixed(2);
      return currentParts
        .map((part) => ({
          ...part,
          price: newPrice,
        }))
        .concat({
          name: `Part ${newLength}`,
          price: newPrice,
          selected: false,
          deletable: true,
        });
    });
  }, [totalAmount]);

  const deletePart = useCallback(
    (index) => {
      setParts((currentParts) => {
        if (currentParts.length <= 2) return currentParts;
        const newParts = currentParts.filter((_, i) => i !== index);
        const newLength = newParts.length;
        const newPrice = (totalAmount / newLength).toFixed(2);
        return newParts.map((part) => ({
          ...part,
          price: newPrice,
        }));
      });
    },
    [totalAmount]
  );

  const togglePartSelection = useCallback((index) => {
    setParts((currentParts) =>
      currentParts.map((part, i) => ({
        ...part,
        selected: i === index ? !part.selected : part.selected,
      }))
    );
  }, []);
  const handleCheckout = () => {
    // Calculate the number of selected parts that are not already paid
    const selectedPartsCount = parts.filter(
      (part, index) => part.selected && index >= (paidParts || 0)
    ).length;
    if (!selectedPartsCount || selectedPartsCount.length === 0) {
      showToast("Please select minimum 1 part to proceed", "success");
      // showToast("User updated successfully!", "success");

      return;
    }
    setIsButtonDisabled(true);
    // Construct the payload
    const payload = {
      paymentMethod: selectedPaymentMethod.toUpperCase() ?? "",
      orderId: orderCheckoutData?.id,
      checkoutType: "SPLIT_EQUALLY",
      cardType: selectedCard,
      customerPointsid: selectedCustomerId,
      totalParts: parts.length, // Total parts remain the same
      paidParts: selectedPartsCount, // Number of newly selected parts (excluding already paid parts)
    };

    dispatch(
      checkOutOrder({ payload, successCallBack: successCheckoutCallback })
    );
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  const isPaidPart = (index) => index < (paidParts || 0);
  const isRemainingPart = (index) =>
    index >= (paidParts || 0) &&
    index < (paidParts || 0) + (remainingParts || 0);

  const selectedTotal = parts.reduce((total, part, index) => {
    // Only add the price of selected parts that are not paid
    if (!part.selected || index < paidParts) {
      return total;
    }
    return total + parseFloat(part.price);
  }, 0);

  const successCheckoutCallback = (response) => {
    setOrderCheckoutPaid(response?.data?.data);
    setIsCheckoutModalOpen(true);
    setCheckOutVisible(false);
  };

  return {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    customerList,
    customerListLoading,
    setIsOpen,
    isOpen,
    selectedCustomer,
    setSelectedCustomer,
    handleSelectCustomer,
    dropdownRef,
    paymentMethodsListLoading,
    paymentMethodsList,
    parts,
    togglePartSelection,
    addPart,
    deletePart,
    selectedTotal,
    totalAmount,
    orderType,
    handleSelectionChange,
    selectedCard,
    setSelectedCard,
    handleCheckout,
    remainingParts,
    paidParts,
    totalParts,
    pricePerParts,
    checkoutType,
    isPaidPart,
    isRemainingPart,
    isButtonDisabled
  };
};
