import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProfile } from "../../../../../../features/Auth/AuthSlice";
import useStorage from "../../../../../common/utils/localStorage.util";
import { singleStaffUser } from "../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";

const useSettingSideBar = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const storedUserData = useStorage.getItem("user");
  const id = storedUserData?.user?.profile?.id;
  const staffId = storedUserData?.user?.id;
  const role = storedUserData?.user?.role;

  useEffect(() => {
    if (role === "SUPER_ADMIN" && id) {
      fetchUserProfile(id);
    } else if (staffId) {
      fetchStaffUserProfile(staffId);
    }
  }, [id, staffId, role]);

  const fetchUserProfile = (id) => {
    dispatch(getProfile({ payload: { id } }))
      .then((response) => {
        setUserName(response.payload.data.fullName);
        setProfilePic(response.payload.data.profilePic);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  };

  const fetchStaffUserProfile = (staffId) => {
    dispatch(singleStaffUser({ staffId, successCallBack }));
  };

  const successCallBack = (response) => {
    const userData = response?.data?.data;
    setUserName(userData.fullName);
    setProfilePic(userData.profilePic); // Adjusted from 'userData.p' to 'userData.profilePic'
  };

  /**
   * Custom hook for handling user profile data for a sidebar component.
   * This hook fetches and provides user-related data for use in the sidebar.
   * @returns {Object} An object containing:
   *   - userName: State to store the user's full name.
   *   - profilePic: State to store the user's profile picture.
   *   - storedUserData: The stored user data from localStorage.
   */
  return {
    userName,
    profilePic,
    storedUserData,
  };
};

export default useSettingSideBar;
