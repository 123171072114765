/**
 * Single source for all SVG images.
 * Contains various images for different purposes.
 * @returns {Object} An object containing imported images.
 */

import Avatar from "./Avatar.svg";
import Bowl from "./Bowl.svg";
import Burger from "./Burger.svg";
import Cake from "./Cake.svg";
import CaretDown from "./CaretDown.svg";
import Carrot from "./Carrot.svg";
import Check from "./Check.svg";
import Dev from "./Dev.svg";
import DineIn from "./DineIn.svg";
import Directory from "./Directory.svg";
import EnvelopeSample from "./EnvolpeSample.svg";
import Food2 from "./Food2.svg";
import Food3 from "./Food3.svg";
import Food4 from "./Food4.svg";
import Fruit from "./Fruit.svg";
import Glass from "./Glass.svg";
import Group from "./Group.svg";
import Inventory from "./Inventory.svg";
import Invoices from "./Invoices.svg";
import ListDashes from "./ListDashes.svg";
import Login from "./Login.svg";
import Managements from "./Managements.svg";
import MessageIcon from "./MessageIcon.svg";
import PlusCircle from "./PlusCircle.svg";
import SearchBox from "./SearchBox.svg";
import Setting from "./Setting.svg";
import SignOut from "./SignOut.svg";
import Tick from "./Tick.svg";
import PosSignOut from "./PosSignOut.svg";
import SignUpImage from "./SignUpImage.svg";
import Pin from "./Pin.svg";
import Globe from "./Globe.svg";
import NoDataIcon from "./NoDataIcon.png";
import Barcode from "./Barcode.svg";
export {
  Avatar,
  Bowl,
  Burger,
  Cake,
  CaretDown,
  Carrot,
  Check,
  Dev,
  DineIn,
  Directory,
  EnvelopeSample,
  Food2,
  Food3,
  Food4,
  Fruit,
  Glass,
  Group,
  Inventory,
  Invoices,
  ListDashes,
  Login,
  Managements,
  MessageIcon,
  PlusCircle,
  PosSignOut,
  SearchBox,
  Setting,
  SignOut,
  SignUpImage,
  Tick,
  Pin,
  Globe,
  NoDataIcon,
  Barcode
};
