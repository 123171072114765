import * as React from "react";

function RocketLaunch(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.87988 13.8281C7.80246 13.8012 7.72046 13.79 7.63866 13.7951C7.55686 13.8003 7.4769 13.8216 7.40344 13.858C7.32999 13.8944 7.26452 13.945 7.21084 14.0069C7.15717 14.0689 7.11636 14.1409 7.09082 14.2188C6.5752 15.7578 4.91113 16.2344 3.9502 16.3828C4.09863 15.4219 4.5752 13.7578 6.11426 13.2422C6.19853 13.2218 6.27763 13.1841 6.34654 13.1314C6.41544 13.0788 6.47264 13.0124 6.51449 12.9364C6.55634 12.8605 6.58193 12.7767 6.58964 12.6903C6.59735 12.6039 6.587 12.5169 6.55925 12.4348C6.53151 12.3526 6.48697 12.2771 6.42847 12.2131C6.36998 12.1491 6.29881 12.098 6.21948 12.063C6.14015 12.028 6.05441 12.0098 5.9677 12.0098C5.88099 12.0097 5.79521 12.0276 5.71582 12.0625C4.43457 12.4844 3.49707 13.5 2.99707 14.9922C2.77553 15.6634 2.65443 16.3637 2.6377 17.0703C2.6377 17.2361 2.70354 17.3951 2.82075 17.5123C2.93796 17.6295 3.09694 17.6953 3.2627 17.6953C3.96933 17.6786 4.66961 17.5575 5.34082 17.3359C6.83301 16.8359 7.84863 15.8984 8.27051 14.6172C8.29741 14.5398 8.30863 14.4578 8.30351 14.376C8.29838 14.2942 8.27701 14.2142 8.24065 14.1408C8.20428 14.0673 8.15365 14.0018 8.09171 13.9482C8.02977 13.8945 7.95776 13.8537 7.87988 13.8281Z"
        fill="#0086FF"
      />
      <path
        d="M18.1143 3.2657C18.0777 3.00098 17.9556 2.75547 17.7666 2.5665C17.5776 2.37753 17.3321 2.25545 17.0674 2.21882C16.1846 2.08601 13.8955 2.02351 11.6612 4.25789L10.958 4.9532H6.16898C5.83801 4.95546 5.5211 5.08727 5.28616 5.32039L2.76273 7.85164C2.60271 8.01077 2.48908 8.21049 2.43402 8.42935C2.37896 8.6482 2.38457 8.87792 2.45023 9.09382C2.51537 9.3113 2.63851 9.50692 2.80643 9.6597C2.97435 9.81248 3.18071 9.91664 3.40335 9.96101L6.48929 10.5782L9.75491 13.8438L10.3721 16.9298C10.4165 17.1524 10.5206 17.3588 10.6734 17.5267C10.8262 17.6946 11.0218 17.8177 11.2393 17.8829C11.3554 17.9201 11.4767 17.9385 11.5987 17.9376C11.763 17.939 11.9259 17.9073 12.0776 17.8442C12.2293 17.7811 12.3667 17.6879 12.4815 17.5704L15.0127 15.0469C15.2458 14.812 15.3777 14.4951 15.3799 14.1641V9.37507L16.0752 8.67195C18.3096 6.43757 18.2471 4.14851 18.1143 3.2657ZM6.16898 6.2032H9.70804L6.59085 9.32039L3.64554 8.73445L6.16898 6.2032ZM14.1299 14.1641L11.5987 16.6876L11.0127 13.7423L14.1299 10.6251V14.1641Z"
        fill="#0086FF"
      />
    </svg>
  );
}

export default RocketLaunch;
