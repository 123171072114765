import api from "../../services/api";

/**
 * Creates pos-user API endpoints based on the specified base path.
 * @param {string} basePath - The base path for authentication endpoints.
 * @returns {Object} Object containing pos-user API methods.
 */
const createPosUserApi = (basePath) => ({
  /**
   * get all pos users
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting users
   *    order: order for sorting users by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the user response.
   */
  getAllPosUsers: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * get single pos user
   * @param {object} id id of the user
   * @returns {Promise} A Promise that resolves to the user response.
   */
  getSinglePosUser: async (userId) => {
    const response = await api().get(`${basePath}/${userId}`);
    return response;
  },

  /**
   * update a single user
   * @param {object} id id of the user
   * @returns {Promise} A Promise that resolves to the user response.
   */
  updatePosUser: async (userId, payload) => {
    const response = await api().put(`${basePath}/${userId}`, payload);
    return response;
  },

  /**
   * add a user
   * @param {object} id id of the user
   * @returns {Promise} A Promise that resolves to the user response.
   */
  addPosUser: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * delete a user
   * @param {object} id id of the user
   * @returns {Promise} A Promise that resolves to the user response.
   */
  deletePosUser: async (userId) => {
    const response = await api().delete(`${basePath}/${userId}`);
    return response;
  },
  /**
   * undo a deleted user
   * @param {object} id id of the user
   * @returns {Promise} A Promise that resolves to the user response.
   */
  undoPosUser: async (id) => {
    const response = await api().get(`${basePath}/undoUser/${id}`);
    return response;
  },
});

// Export the createPosUserApi

/**
 * Example usage:
 * import createPosUserApi from './path/to/api/posUsersApi';
 *
 * const posUserApi = createPosUserApi('pos-user');
 *
 * const getAllPosUser = async (payload) => {
 *   try {
 *     const response = await authApi.addPosUser(payload);
      // Handle response
 *   } catch (error) {
      // Handle error
 *   }
 * };
 */

export default createPosUserApi;
