import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../../context/UserContext";
import {
  calculatePricingSummary,
  getPricing,
  subscribeToFreeTrial,
  upgradePlan,
} from "../../../../features/ClientSubscriptions/ClientSubscriptionsSlice";
import api from "../../../../services/api";
import { showToast } from "../../../common/utils/showToast.util";
import { setPlanSchema } from "./Validator";

/**
 * Custom hook for managing the create account form.
 * @returns {Object} An object containing methods and states for form handling and image upload validation.
 */
export const useSetPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFreeTrialLoading, setIsFreeTrialLoading] = useState(false);

  // State to keep track of the number of branches
  const [showFinish, setShowFinish] = useState(false);
  const { updateUser, setUserProfile, user } = useUserContext();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(setPlanSchema),
  });
  const isLoading = useSelector((state) => state.clientSubscriptions.getPricing.isLoading);
  const pricingData = useSelector((state) => state.clientSubscriptions.getPricing.data);
  const { data: calculatedPrice, isLoading: priceSummaryLoading } = useSelector(
    (state) => state.clientSubscriptions.calculatePricingSummary
  );

  const curPlan = user?.isSubscriptionPlanCompleted &&
  (user?.subscription?.planType.includes("Basic")
    ? "Basic"
    : user?.subscription?.planType.includes("Professional")
    ? "Professional"
      : "Enterprise");

  const [selectedPlan, setSelectedPlan] = useState(curPlan || "Basic");
  const [activeTab, setActiveTab] = useState("MONTHLY");
  const [branchCount, setBranchCount] = useState(0);
  const [additionalBranchPrice, setAdditionalBranchPrice] = useState(0);
  const matchedPlan = pricingData?.find(
    (plan) => plan.plan_tenure === activeTab && plan.name === selectedPlan
  );


  // Extract the amount from the matched plan
  const planId = matchedPlan ? matchedPlan.planId : null;
  const totalPriceBeforeTax = calculatedPrice?.totalBeforeTax;
  const tax = calculatedPrice?.taxAmount;
  const totalPrice = calculatedPrice?.subTotal;

  useEffect(() => {
    setAdditionalBranchPrice(0);
    setBranchCount(0);
  }, [selectedPlan]);

  useEffect(() => {
    dispatch(getPricing({ type: activeTab }));
  }, [dispatch, activeTab]);

  useEffect(() => {
    setAdditionalBranchPrice(
      activeTab === "MONTHLY" ? 71 * branchCount : 850 * branchCount
    );
  }, [branchCount, activeTab]);


  // Function to trigger the finish screen display
  const handleButtonClick = (response) => {
    setShowFinish(true);
  };

  function extractErrorMessages(errorObject) {
    const errorMessages = [];
    if (errorObject && typeof errorObject === "object" && errorObject.errors) {
      for (const [key, messages] of Object.entries(errorObject.errors)) {
        // Assuming each field could have more than one error message
        messages.forEach((message) => {
          errorMessages.push(`${key}: ${message}`);
        });
      }
    }
    return errorMessages;
  }

  /**
   * Handles form submission.
   * Validates image upload, creates FormData object with form data, and dispatches the createAccount action.
   * @param {object} data - Form data collected from the form fields.
   */
  // const onSubmit = async (data) => {
  //   try {
  //     const apiUrl = "https://api.moyasar.com/v1/payments";
  //     const userId = user?.id;
  //     const metadata = {
  //       userId: userId,
  //       plan_tenure: activeTab,
  //       planId,
  //       branches: branchCount,
  //       totalBeforeTax: totalPriceBeforeTax,
  //       totalDiscount: discountPrice,
  //       taxAmount: tax,
  //       subTotal: totalPrice,
  //     };
  //     const paymentData = {
  //       amount: totalPrice,
  //       currency: "SAR",
  //       description: "amount to be paid for. It is optional",
  //       callback_url: "http://localhost:3000/thank-you",
  //       source: {
  //         type: "creditcard",
  //         name: "abc",
  //         number: data?.cardNumber,
  //         cvc: data?.cvv,
  //         month: "1",
  //         year: "26",
  //       },
  //     };
  //     const username = "sk_test_C8fHbZ9baJbNtfnp67wiZsdK5btpccqqNW6uyNTt";
  //     const password = "";
  //     const credentials = `${username}:${password}`;
  //     const encodedCredentials = btoa(credentials);
  //     const response = await fetch(apiUrl, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Basic ${encodedCredentials}`,
  //       },
  //       body: JSON.stringify({ metadata, ...paymentData }),
  //     });

  //     if (!response.ok) {
  //       setError(await response.json());

  //       throw new Error("Failed to make payment");
  //     }

  //     const responseData = await response.json();
  //     const transactionUrl = responseData.source.transaction_url;
  //     window.location.href = transactionUrl;
  //   } catch (error) {
  //     console.error(`Failed to make payment: ${error}`);
  //   }
  // };

  const onSubmit = (data) => {
    const subscriptionData = {
      plan_tenure: activeTab,
      planId: planId,
      branches: branchCount,
      paymentMethod: "987.5",
      userId: user?.id,
      totalBeforeTax: totalPriceBeforeTax,
      // totalDiscount: discountPrice,
      taxAmount: tax,
      subTotal: totalPrice,
    };

    return dispatch(
      upgradePlan({
        payload: subscriptionData,
        successCallBack: onSubscriptionSuccess,
      })
    );
  };

  // Function to trigger the finish screen display
  const onSubscriptionSuccess = async (data) => {
    if (data.data.Succeeded) {
      const response = await api().get(`user/me`);
      if (response.data.Succeeded) {
        const userData = response?.data?.data;
        const profile = userData?.profile;
        updateUser(userData);
        setUserProfile(profile);
        setTimeout(() => {
          navigate("/thank-you");
        }, 2000);
      }
    }
  };

  // handle free trial subscription

  const handleFreeTrialSubscription = () => {
    setIsFreeTrialLoading(true);
    return dispatch(
      subscribeToFreeTrial({
        successCallBack: onSuccessFreeTrial,
        errorCallBack: () => {
          setIsFreeTrialLoading(false);
        },
      })
    );
  };

  const onSuccessFreeTrial = async (data) => {
    try {
      if (data.data.Succeeded) {
        const response = await api().get(`user/me`);
        if (response.data.Succeeded) {
          showToast("Free trial subscribed successfully!", "success");
          setIsFreeTrialLoading(false);
          const userData = response?.data?.data;

          // Only keep the profile from user data
          const profile = userData?.profile;

          // Update the user with an empty object
          updateUser(userData);

          // Set the profile separately
          setUserProfile(profile);

          setTimeout(() => {
            navigate("/thank-you");
          }, 2000);
        }
      }
    } catch (error) {
      setIsFreeTrialLoading(false);
      console.error(`Failed to handle free trial subscription: ${error}`);
    }
  };

  const calculatePricing = () => {
    const payload = {
      planId: planId,
      plan_tenure: activeTab,
      totalBranches: branchCount,
    };

    dispatch(
      calculatePricingSummary({
        payload: payload,
        successCallBack: onSuccessCalculatePricing,
      })
    );
  };

  useEffect(() => {
    if (planId) {
      calculatePricing();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan, activeTab, planId, branchCount]);

  const onSuccessCalculatePricing = () => {};

  /**
   * Custom hook for managing the plan selection and branch count functionality.
   *
   * @returns {Object} An object containing methods and states for plan selection and branch count handling.
   * {
   *   register: Function - Registers form inputs for validation and usage with react-hook-form,
   *   handleSubmit: Function - Handles form submission using react-hook-form,
   *   onSubmit: Function - Handles the actual form submission event,
   *   increment: Function - Increments the branch count,
   *   decrement: Function - Decrements the branch count,
   *   selectPlan: Function - Selects a plan,
   *   handleButtonClick: Function - Handles the logic to be executed when a button is clicked, e.g., to show a finish screen,
   *   errors: Object - Holds form validation errors from react-hook-form,
   *   branchCount: Number - The current count of branches,
   *   selectedPlan: String|null - The currently selected plan,
   *   showFinish: Boolean - State to control the display of the finish screen.
   * }
   * Usage Example:
   * Accesses form handling methods and plan selection functionality:
   * const { register, handleSubmit, onSubmit, increment, decrement, selectPlan, handleButtonClick, errors, branchCount, selectedPlan, showFinish } = useSetPlan();
   *
   * register:
   *  Example usage: <input {...register("fullName", { required: true })} />
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * increment:
   *  Example usage: <button onClick={increment}>+</button>
   * decrement:
   *  Example usage: <button onClick={decrement}>-</button>
   * selectPlan:
   *  Example usage: <div onClick={() => selectPlan('Basic')}>Basic Plan</div>
   * handleButtonClick:
   *  Example usage: <button onClick={handleButtonClick}>Finish</button>
   * errors:
   *  Example usage: {errors.fullName && <span>Full name is required</span>}
   * branchCount:
   *  Example usage: <span>{branchCount}</span>
   * selectedPlan:
   *  Example usage: {selectedPlan && <div>Selected Plan: {selectedPlan}</div>}
   * showFinish:
   *  Example usage: {showFinish && <FinishScreen />}
   */

  return {
    onSubmit,
    handleSubmit: handleSubmit(onSubmit),
    register,
    errors,
    showFinish,
    handleButtonClick,
    isSubmitting,
    setBranchCount,
    branchCount,
    setActiveTab,
    activeTab,
    setSelectedPlan,
    selectedPlan,
    additionalBranchPrice,
    totalPrice,
    tax,
    totalPriceBeforeTax,
    // discountPrice,
    handleFreeTrialSubscription,
    isFreeTrialLoading,
    control,
    pricingData,
    isLoading,
    calculatePricing,
    priceSummaryLoading,
    user,
  };
};

export default useSetPlan;
