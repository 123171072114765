import React from "react";
import PosSettingSidebar from "../PosSettingSideBar/PosSettingSideBar";

const PosSettingLayout = ({ children, activeComponent, setActiveComponent }) => {
  return (
    <div className="flex w-[100%] h-[400px]">
      <PosSettingSidebar activeComponent={activeComponent} setActiveComponent={setActiveComponent} />
      <div className="p-4 w-[100%] h-[400px]">
        {children}
      </div>
    </div>
  );
};

export default PosSettingLayout;
