import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../Button/Button";
import { ArrowUpSide } from "../../Icons";
import DefaultImg from "../../../../assets/images/DefaultImg.png";

import Dropdown from "../DropDown/DropDown";
const CustomAdminTable = ({
  tableBody,
  headers,
  showSecondLastColumn = false,
  showImages = false,
  handleRowClick = () => {},
  dropdownOptions,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(null);

  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the click is outside the dropdown, close it
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(null);
      }
    };

    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const isLastRow = (index) => {
    // Check if the current index is the last one in the tableBody array
    return index === tableBody.length - 1;
  };

  return (
    <div className=" relative mt-4 flow-root">
      <div className="inline-block min-w-full align-middle ">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg h-full overflow-y-auto">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50 ">
              <tr>
                {headers?.map((header, index) => (
                  <th
                    key={index}
                    scope="col"
                    className={`py-2 text-left pl-6 text-xs font-medium text-text-secondary ${
                      index === 0 ? "pr-8 sm:pl-6" : "px-3"
                    }`}
                  >
                    {header}
                  </th>
                ))}
                {showSecondLastColumn && <th scope="col" className="py-2"></th>}{" "}
                {/* Second-last column header */}
                <th scope="col" className="py-2"></th>{" "}
                {/* Last column header */}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {tableBody?.map((user, index) => (
                <tr className="h-10" key={index}>
                  {headers?.map((header, i) => (
                    <td
                      onClick={() => handleRowClick(user?.id)}
                      key={i}
                      className={`whitespace-nowrap cursor-pointer py-3 pl-4 pr-3 pt-2 text-sm font-normal text-gray-900 sm:pl-6 ${
                        i === 0 && showImages ? "flex items-center" : ""
                      }`}
                    >
                      {i === 0 && showImages && (
                        <img
                          src={user.imageSrc || DefaultImg}
                          alt="Pic"
                          className="inline-block h-8 w-8 rounded-full mr-2"
                        />
                      )}
                      {user[header.toLowerCase().replace(/ /g, "")]}
                    </td>
                  ))}
                  {showSecondLastColumn && (
                    <td className="py-1">
                      <CustomButton
                        fontWeight="font-medium"
                        bgColor="bg-transparent"
                        icon={<ArrowUpSide />}
                        width="w-fit"
                        border="border"
                        text="Open Message"
                        padding="px-2 py-1"
                        borderRadius="rounded-lg"
                        fontSize="text-sm"
                        textColor="text-text-primary"
                        iconPosition="after"
                      />
                    </td>
                  )}
                  <td className="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-lg font-medium sm:pr-6">
                    <Link
                      to="#"
                      className="text-text-primary font-medium text-lg"
                      onClick={() => toggleDropdown(index)}
                    >
                      ...
                    </Link>
                    {dropdownVisible === index && (
                      <div
                        ref={dropdownRef}
                        className={`absolute right-0 ${
                          isLastRow(index) ? "bottom-24 right-2" : "top-4"
                        } w-36 bg-white shadow-md rounded-md z-10`}
                      >
                        <Dropdown
                          options={dropdownOptions}
                          userId={user}
                          showEdit={true}
                          showDelete={true}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
              {tableBody?.length < 1 && (
                <tr>
                  <td colSpan={2} className="pl-4">
                    <p className="p-2 text-text-secondary font-medium">No record found!</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomAdminTable;
