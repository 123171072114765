import * as React from "react";

function VerticalBlackCoin(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="CoinVertical">
        <path
          id="Vector"
          d="M15.5078 4.38281C14.5625 2.76562 13.2734 1.875 11.875 1.875H8.125C6.72656 1.875 5.4375 2.76562 4.49219 4.38281C3.54687 6 3.125 7.89062 3.125 10C3.125 12.1094 3.60938 14.1094 4.49219 15.6172C5.375 17.125 6.72656 18.125 8.125 18.125H11.875C13.2734 18.125 14.5625 17.2344 15.5078 15.6172C16.4531 14 16.875 12.1094 16.875 10C16.875 7.89062 16.3906 5.89062 15.5078 4.38281ZM15.6094 9.375H13.1094C13.0469 8.03125 12.7891 6.74219 12.3516 5.625H14.7422C15.2188 6.67188 15.5391 7.96875 15.6094 9.375ZM14 4.375H11.7578C11.4933 3.92443 11.1788 3.50512 10.8203 3.125H11.875C12.6562 3.125 13.3906 3.59375 14 4.375ZM11.875 16.875H10.8203C11.1788 16.4949 11.4933 16.0756 11.7578 15.625H14C13.3906 16.4062 12.6562 16.875 11.875 16.875ZM14.7422 14.375H12.3516C12.7891 13.2578 13.0469 11.9688 13.1094 10.625H15.6094C15.5391 12.0312 15.2188 13.3281 14.7422 14.375Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default VerticalBlackCoin;
