import React, { useEffect, useState } from "react";
import useChooseDiscount from "./useChooseDiscount";
import { CustomButton } from "../../../common/components";
import { useTranslation } from "react-i18next";
import Accordion from "../../../common/components/Accordion/Accordion";

function ChooseDiscount({
  onSave,
  onCancel,
  selectedPromotions,
  selectedItems,
}) {
  const { ItemsPromotions } = useChooseDiscount(selectedItems);
  const { t } = useTranslation();

  // Initialize a state array to store the selected value for each accordion
  const [selectedValues, setSelectedValues] = useState(
    new Array(
      ItemsPromotions.filter((item) => item.data?.length !== 0).length
    ).fill(null)
  );

  useEffect(() => {
    selectedPromotions.length !== 0 &&
      setSelectedValues(selectedPromotions.map((p) => p.selectedValue || null));
  }, [selectedPromotions]);
  const accordionItems = ItemsPromotions.filter(
    (item) => item.data?.length !== 0
  ).map((promotion, index) => ({
    title: promotion.title,
    options: promotion.data.map((list) => ({
      title: `${list.name}`,
      price: `${list.value} `,
      value: list.id,
      type: list.type,
      start: list.startDate,
      end: list.endDate,
      promotion: list,
    })),
  }));

  const handleRadioChange = (value, index) => {
    let newSelectedValues = selectedValues.slice();
    if (index === 0) {
      // Selecting from the first accordion, clear all other selections
      newSelectedValues = newSelectedValues.map((_, idx) =>
        idx === 0 ? value : null
      );
    } else {
      // Selecting from any other accordion, clear the first accordion selection
      newSelectedValues[0] = null;
      newSelectedValues[index] = value;
    }

    setSelectedValues(newSelectedValues);
  };

  const handleSavePromotions = () => {
    const result = accordionItems.map((item, index) => ({
      accordion: item.title,
      selectedValue: selectedValues[index] || "",
    }));
    onSave(result); 
  };

  return (
    <div className="p-3">
      <div className="font-semibold text-sm mb-4 px-1">
        {t("pos_dashboard.choose_promotion")}
      </div>
      <div className="h-[400px] overflow-auto grayScrollNoBg pr-1 p-3">
        <Accordion
          items={accordionItems}
          selectedValues={selectedValues}
          onRadioChange={handleRadioChange}
        />
      </div>
      <div className="flex justify-end mt-4 gap-2">
        <CustomButton
          width="20px"
          text={t("pos_dashboard.cancel")}
          onClick={onCancel}
          bgColor="bg-white"
          textColor="text-text-primary"
          border="border"
          hoverBgColor="bg-gray-200"
        />
        <CustomButton
          type="submit"
          width="20px"
          text={t("pos_dashboard.save_promotion")}
          onClick={handleSavePromotions}
          bgColor="bg-sky-blue"
          textColor="text-white"
        />
      </div>
    </div>
  );
}

export default ChooseDiscount;
