import React, { useEffect, useState } from "react";
import { camelToPascalWithSpaces } from "../../../../helpers/camelToPascalWithSpaces";
import { PlusIcon } from "../../Icons";

const getPrices = (keys, variables) =>
  variables.find((v) =>
    Object.values(v.variations).every((b) => keys.includes(b))
  );

const VariableSelector = ({
  options = {},
  showPrices = true,
  onChange = () => {},
  variableItem = [],
  onSelectionComplete,
}) => {
  const [selections, setSelections] = useState(() => {
    const initialSelections = {};
    Object.entries(options || {}).forEach(([variable, values]) => {
      if (values && values.length > 0) {
        initialSelections[variable] = values[0];
      }
    });
    return initialSelections;
  });

  const handleSelection = (variableName, value) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [variableName]: value,
    }));
  };

  const priceObject = getPrices(Object.values(selections), variableItem);
  useEffect(() => {
    onChange(priceObject);
  }, [priceObject, onChange]);

  const handlePlusClick = () => {
    if (onSelectionComplete) {
      onSelectionComplete({
        selections,
        vItemId: priceObject.id,
        price: priceObject?.salePrice,
      });
    }
  };

  return (
    <React.Fragment>
      {Object.entries(options || {})?.map(([variable, value]) => (
        <div key={variable} className=" ">
          <div className=" text-text-primary text-sm capitalize">
            {variable}
          </div>
          <div className="flex gap-2 mb-1.5">
            {value.map((val) => (
              <button
                key={val}
                className={`border px-2 py-1  rounded-lg ${
                  selections[variable] === val
                    ? "bg-blue-500 text-white text-sm"
                    : "bg-white text-sm"
                }`}
                onClick={() => handleSelection(variable, val)}
              >
                {val}
              </button>
            ))}
          </div>
        </div>
      ))}

      {priceObject ? (
        <button
          onClick={handlePlusClick}
          className="float-right border rounded-md bg-gray-200 mb-2"
        >
          <PlusIcon />
        </button>
      ) : (
        <span className="text-red-500 border-red-500 mb-1 bg-red-100 font-semibold text-base rounded-lg px-1 py-1 float-right">
          Not Available
        </span>
      )}

      {showPrices &&
        priceObject &&
        Object.entries({
          Price: priceObject.salePrice || 0,
        })?.map(([key, val]) => (
          <div key={key} className="flex flex-row space-x-1">
            <span className=" font-semibold text-sm !p-0">
              {camelToPascalWithSpaces(key)}:
            </span>
            <span className="text-link font-semibold text-sm ">{val}</span>
          </div>
        ))}
    </React.Fragment>
  );
};

export default VariableSelector;
