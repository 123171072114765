import React from "react";

function RocketIcon() {
  return (
    <div>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_3706_51646)">
          <rect x="2" y="1" width="40" height="40" rx="8" fill="white" />
          <rect
            x="2.5"
            y="1.5"
            width="39"
            height="39"
            rx="7.5"
            stroke="#EAECF0"
          />
          <path
            d="M23.25 27.875H20.75C20.5842 27.875 20.4253 27.9408 20.3081 28.0581C20.1908 28.1753 20.125 28.3342 20.125 28.5C20.125 28.6658 20.1908 28.8247 20.3081 28.9419C20.4253 29.0592 20.5842 29.125 20.75 29.125H23.25C23.4158 29.125 23.5747 29.0592 23.6919 28.9419C23.8092 28.8247 23.875 28.6658 23.875 28.5C23.875 28.3342 23.8092 28.1753 23.6919 28.0581C23.5747 27.9408 23.4158 27.875 23.25 27.875Z"
            fill="#0086FF"
          />
          <path
            d="M29.1562 22.2422L26.7968 19.4062C26.72 18.4721 26.5095 17.5539 26.1718 16.6797C25.2656 14.3203 23.664 12.7734 22.7812 12.0625C22.561 11.8825 22.2857 11.7835 22.0013 11.7821C21.7169 11.7807 21.4407 11.8769 21.2187 12.0547C20.3281 12.7656 18.7031 14.3125 17.789 16.6718C17.4384 17.5642 17.2251 18.5046 17.1562 19.4609L14.8437 22.2422C14.721 22.3887 14.6334 22.5612 14.5873 22.7466C14.5413 22.9321 14.5381 23.1256 14.5781 23.3125L15.5468 27.6562C15.5924 27.8634 15.6903 28.0555 15.8311 28.2141C15.972 28.3728 16.1511 28.4927 16.3515 28.5625C16.4874 28.6085 16.6299 28.6323 16.7734 28.6328C17.0546 28.6312 17.3271 28.5349 17.5468 28.3593L19.7187 26.625H24.2812L26.4531 28.3593C26.6728 28.5349 26.9453 28.6312 27.2265 28.6328C27.37 28.6323 27.5125 28.6085 27.6484 28.5625C27.8487 28.4927 28.0279 28.3728 28.1687 28.2141C28.3096 28.0555 28.4075 27.8634 28.4531 27.6562L29.4218 23.3125C29.4617 23.1256 29.4586 22.9321 29.4126 22.7466C29.3665 22.5612 29.2789 22.3887 29.1562 22.2422ZM16.7656 27.3828L15.8046 23.039L17.2031 21.3593C17.3854 22.8229 17.8828 24.3177 18.6953 25.8437L16.7656 27.3828ZM21.9999 19.4375C21.8145 19.4375 21.6333 19.3825 21.4791 19.2795C21.3249 19.1765 21.2048 19.03 21.1338 18.8587C21.0628 18.6874 21.0443 18.4989 21.0805 18.3171C21.1166 18.1352 21.2059 17.9682 21.337 17.8371C21.4681 17.7059 21.6352 17.6167 21.817 17.5805C21.9989 17.5443 22.1874 17.5629 22.3587 17.6338C22.53 17.7048 22.6764 17.8249 22.7794 17.9791C22.8825 18.1333 22.9374 18.3145 22.9374 18.5C22.9374 18.7486 22.8387 18.9871 22.6629 19.1629C22.487 19.3387 22.2486 19.4375 21.9999 19.4375ZM27.2343 27.3828L25.2968 25.8359C26.1093 24.2968 26.5937 22.789 26.7656 21.3203L28.1953 23.039L27.2343 27.3828Z"
            fill="#0086FF"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_3706_51646"
            x="0"
            y="0"
            width="44"
            height="44"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3706_51646"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_3706_51646"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default RocketIcon;
