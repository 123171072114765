import { createSlice } from "@reduxjs/toolkit";

/**
 * Modal Slice for managing modal state.
 *
 * @typedef {Object} ItemsModalState - State object representing the items modal properties.
 * @property {boolean} isModalOpen - Flag indicating whether the items modal is open or closed.
 * @property {JSX.Element} content - JSX content to be displayed in the items modal.
 * @property {string} title - Title for the items modal.
 * @property {string} type - Type of the items modal.
 * @property {boolean} editable - Flag indicating whether the items modal content is editable.
 *
 * @param {ItemsModalState} state - Current state of the itemsModal.
 * @param {Object} action - Action object to modify the itemsModal state.
 * @param {string} action.type - Type of action.
 * @param {Object} action.payload - Payload data for the action.
 * @returns {void} No return value. Modifies the state based on the action.
 */

const itemsModalSlice = createSlice({
  name: "itemsModal",
  initialState: {
    isModalOpen: false,
    title: "",
    activeStep: 1,
    type: null,
    editable: false,
    onClose: null,
    selectedOption: "SIMPLE",
    variableOptions: {},
  },
  reducers: {
    openModal: (state, action) => {
      const { title, activeStep, type, editable } = action.payload;
      state.isModalOpen = true;
      state.content = action.payload;
      state.title = title || state.selectedOption || "";
      state.activeStep = activeStep || state.activeStep || 1;
      state.type = type || null;
      state.editable = editable || false;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
      state.content = null;
      state.title = "";
      state.activeStep = 1;
      state.type = null;
      state.editable = false;
      state.selectedOption = "SIMPLE";
      state.variableOptions = {};
    },
    setSelectedOption: (state, action) => {
      const selectedOption = action.payload;
      state.selectedOption = selectedOption;
    },
    updateTitle: (state, action) => {
      const title = action.payload;
      state.title = title;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setVariableOptions: (state, action) => {
      state.variableOptions = action.payload;
    },
  },
});

export const {
  openModal,
  closeModal,
  setSelectedOption,
  updateTitle,
  setActiveStep,
  activeStep,
  selectedOption,
  type,
  title,
  isModalOpen,
  variableOptions,
  setVariableOptions,
} = itemsModalSlice.actions;
export default itemsModalSlice.reducer;

// Sample Usage:
// import { openModal, closeModal } from "path/to/itemsModalSlice";
//
// const dispatch = useDispatch();
//
//  Open the modal
// dispatch(openModal({
//   title: "Edit User",
//   type: "edit",
//   editable: true,
//   handleEdit: handleEditFunction,
//   onClose: handleCloseFunction,
//    Other modal content...
// }));
//
// Close the modal
// dispatch(closeModal());
