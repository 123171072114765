import { UserProvider } from "../context/UserContext";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import store from "../store/store";
import { LanguageProvider } from "../context/LanguageContext";

const AppProvider = ({ children }) => {
  return (
    <GoogleOAuthProvider clientId="916422187363-6ci4062f10nea43qpgctb6ndadvoibgb.apps.googleusercontent.com">
      <Provider store={store}>
        <UserProvider>
          <LanguageProvider>{children}</LanguageProvider>
        </UserProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
};

export default AppProvider;
