import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSignup from "./useSignup";
import { SignUpMainImage } from "../../assets/images";
import Navbar from "../common/components/Navbar/Navbar";
import Footer from "../common/components/Footer/Footer";
import ConfirmEmail from "../ConfirmEmail/ConfirmEmail";
import CustomButton from "../common/components/Button/Button";
import InputGroup from "../common/components/InputGroup/InputGroup";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from "../Login/GoogleLoginButton";
import FacebookLoginButton from "../Login/FacebookLoginButton";

/**
 * Signup component.
 * Renders a signup form and handles user signup functionality.
 *
 * @returns {JSX.Element} JSX code for rendering the Signup component.
 */

const Signup = () => {
  const { t } = useTranslation();
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

  const onEmailConfirm = () => {
    setIsEmailConfirmed(true);
  };

  const { register, handleSubmit, errors, isValid, isSubmitting } =
    useSignup(onEmailConfirm);

  return (
    <div className="flex max-h-screen overflow-hidden">
      <div className="w-full flex flex-col justify-between">
        <Navbar language />
        <div className="lg:min-w-[480px]  mx-auto">
          <h2 className="text-3xl font-semibold mb-3 text-center text-text-primary">
            {t(`signup.title`)}
          </h2>
          <p className="text-text-secondary text-base font-normal text-center">
            {t(`signup.welcome`)}
          </p>

          <div className="space-y-4 mt-3">
            {/* google login */}
            <GoogleLoginButton title={"buttons.signup_with_google"} />

            {/* facebook login */}
            <FacebookLoginButton title={"buttons.signup_with_facebook"} />

            <div className="flex items-center justify-center">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="mx-4 text-text-primary font-normal">
                {t(`buttons.or`)}
              </span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>

            {isEmailConfirmed ? (
              <ConfirmEmail />
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4 !mt-6">
                <InputGroup
                  htmlfor="email"
                  labelText={t(`signup.email`)}
                  inputName="email"
                  placeholder={t(`signup.email_placeholder`)}
                  inputType="email"
                  register={register}
                  errors={errors}
                />
                <CustomButton
                  text={t(`buttons.continue`)}
                  isLoading={isSubmitting}
                  type="submit"
                  disabled={!isValid}
                />
              </form>
            )}

            <div className="text-text-secondary text-base text-center !mt-6">
              {t(`signup.already_have_account`)}
              <Link to="/login" className="text-text-link hover:underline">
                {" "}
                {t(`login.title`)}
              </Link>
            </div>
          </div>
        </div>
        <div className="text-sm text-center lg:max-w-[480px] mx-auto text-text-secondary">
          {t("signup.agreement")}{" "}
          <Link to="#" className="text-text-link hover:underline">
            {" "}
            {t("signup.terms_and_conditions")}
          </Link>{" "}
          {t("signup.and")}{" "}
          <Link to="#" className="text-text-link hover:underline">
            {" "}
            {t("signup.privacy_notice")}.{" "}
          </Link>
        </div>
        <Footer userType="client" login />
      </div>
      {/* image */}
      <div className="lg:min-w-[640px] hidden lg:block bg-bright-cerulean">
        <h3 className="text-center text-white text-3xl font-bold leading-[48px] mt-28">
          Maximize Your Sales Momentum
        </h3>
        <p className="text-white text-base text-center font-normal leading-normal m-3">
          Elevate Your Business and Maximize Your Revenue Potential. Get Started
          Today!
        </p>
        <div>
          <img
            src={SignUpMainImage}
            alt="Signup"
            className="rounded-r-2xl h-full mt-14 mb-14 ml-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
// Sample Usage:
// import Signup from "./path/to/Signup"
// <Signup />
