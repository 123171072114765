import * as React from "react";

function Rocket(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.917 16.875H9.41699C9.25123 16.875 9.09226 16.9408 8.97505 17.0581C8.85784 17.1753 8.79199 17.3342 8.79199 17.5C8.79199 17.6658 8.85784 17.8247 8.97505 17.9419C9.09226 18.0592 9.25123 18.125 9.41699 18.125H11.917C12.0828 18.125 12.2417 18.0592 12.3589 17.9419C12.4761 17.8247 12.542 17.6658 12.542 17.5C12.542 17.3342 12.4761 17.1753 12.3589 17.0581C12.2417 16.9408 12.0828 16.875 11.917 16.875Z"
        fill="#0086FF"
      />
      <path
        d="M17.8237 11.2423L15.4643 8.40634C15.3875 7.47227 15.177 6.55405 14.8393 5.67977C13.933 3.3204 12.3315 1.77353 11.4487 1.06259C11.2285 0.882623 10.9532 0.783664 10.6688 0.782242C10.3844 0.78082 10.1082 0.877021 9.88617 1.05478C8.99554 1.76571 7.37054 3.31259 6.45648 5.67196C6.1059 6.56435 5.89254 7.50471 5.82367 8.46103L3.51117 11.2423C3.3885 11.3888 3.30083 11.5613 3.25481 11.7468C3.20878 11.9322 3.20561 12.1257 3.24554 12.3126L4.21429 16.6563C4.25987 16.8635 4.35775 17.0556 4.49862 17.2143C4.63948 17.3729 4.81863 17.4928 5.01898 17.5626C5.15489 17.6087 5.29736 17.6324 5.44086 17.6329C5.72209 17.6314 5.99458 17.535 6.21429 17.3595L8.38617 15.6251H12.9487L15.1205 17.3595C15.3403 17.535 15.6128 17.6314 15.894 17.6329C16.0375 17.6324 16.18 17.6087 16.3159 17.5626C16.5162 17.4928 16.6954 17.3729 16.8362 17.2143C16.9771 17.0556 17.075 16.8635 17.1205 16.6563L18.0893 12.3126C18.1292 12.1257 18.1261 11.9322 18.08 11.7468C18.034 11.5613 17.9463 11.3888 17.8237 11.2423ZM5.43304 16.3829L4.47211 12.0392L5.87054 10.3595C6.05284 11.823 6.55023 13.3178 7.36273 14.8438L5.43304 16.3829ZM10.6674 8.43759C10.482 8.43759 10.3007 8.3826 10.1466 8.27959C9.9924 8.17658 9.87224 8.03016 9.80128 7.85885C9.73033 7.68755 9.71176 7.49905 9.74793 7.31719C9.78411 7.13533 9.87339 6.96829 10.0045 6.83718C10.1356 6.70606 10.3027 6.61678 10.4845 6.5806C10.6664 6.54443 10.8549 6.56299 11.0262 6.63395C11.1975 6.70491 11.3439 6.82507 11.4469 6.97924C11.5499 7.13341 11.6049 7.31467 11.6049 7.50009C11.6049 7.74873 11.5061 7.98719 11.3303 8.163C11.1545 8.33882 10.9161 8.43759 10.6674 8.43759ZM15.9018 16.3829L13.9643 14.836C14.7768 13.297 15.2612 11.7892 15.433 10.3204L16.8627 12.0392L15.9018 16.3829Z"
        fill="#0086FF"
      />
    </svg>
  );
}

export default Rocket;
