import React from "react";
import { Card } from "../../../../../common/components";

function ClientSalesHistory({ viewClientDetail }) {
  // Dynamically creating the stats array based on viewClientDetail
  const dynamicStats = [
    {
      title: "Total Sales",
      value: `${viewClientDetail?.totalSale ?? "0"} SAR`,
    },
    { title: "Net Profit", value: `${viewClientDetail?.netProfit ?? "0"} SAR` },
    {
      title: "Expense",
      value: `${
        viewClientDetail?.latestExpenseInvoice?.expenseInvoiceItem?.total ?? "0"
      } SAR`,
    },
    {
      title: "Last Inventory",
      value: viewClientDetail?.latestPurchaseInvoice?.supplyDate ?? "-",
    },
    {
      title: "Latest Purchases",
      value: viewClientDetail?.latestPurchaseInvoice?.supplyDate ?? "-",
    },
    {
      title: "Latest Expense Transaction",
      value: viewClientDetail?.latestExpenseInvoice?.supplyDate ?? "-",
    },
  ];

  return (
    <div className="grid grid-cols-3 gap-3">
      {dynamicStats.map((stat, index) => (
        <Card key={index} className="flex gap-5 items-start !p-3">
          <div>
            <p className="text-sm font-medium text-gray-600">{stat.title}</p>
            <p className="text-lg font-semibold">{stat.value}</p>
          </div>
        </Card>
      ))}
    </div>
  );
}

export default ClientSalesHistory;
