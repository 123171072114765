import React, { useState, useEffect } from "react";
import CustomPosTable from "../../../../common/components/PosTable/PosTable";
import { useTopClientSalesTable } from "./useTopClientSalesTable";
import { CustomButton } from "../../../../common/components";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";
import CustomSearch from "../../../../common/components/CustomSearch/CustomSearch";
import { UploadIcon } from "../../../../common/Icons";
import { handleExport } from "../../../../../helpers/handleExport";

function TopClientSalesTable() {
  const currentDate = new Date(); // This is today's date, which will be used as the endDate.
  const sixMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate()); // Calculate the date six months before today.

  // Set startDate to six months before today and endDate to today.
  const [startDate, setStartDate] = useState(sixMonthsAgo);
  const [endDate, setEndDate] = useState(currentDate);
  const [searchTerm, setSearchTerm] = useState("");

  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };

  const { tableBody, dropdownOptions, cardListLoading, headers, openModal } =
    useTopClientSalesTable(startDate, endDate);

  const filteredTableBody = tableBody?.filter((item) =>
    Object.values(item).some((val) =>
      String(val).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div>
      <div className="flex items-center !space-x-0 py-2">
        <span className="flex items-center flex-grow text-text-primary font-medium text-base">
          <CustomSearch
            placeholder="Search here..."
            inputName="search"
            paddingY="py-2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </span>
        <div className="flex gap-2">
          <CustomButton
            text="Export"
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<UploadIcon />}
            onClick={() =>
              handleExport("dashboard/export-all-clients", "Clients Sales")
            }
          />

          <CustomDatePicker
            initialStartDate={startDate}
            initialEndDate={endDate}
            onChange={(selection) => {
              handleDateChange(selection);
            }}
          />
        </div>
      </div>
      <div className="min-h-[200px] max-h-[280px] flex flex-col  rounded-lg justify-between my-2 border border-e-0 overflow-auto">
        <CustomPosTable
          tableHeader={headers}
          tableBody={filteredTableBody}
          dropdownOptions={dropdownOptions}
          loading={cardListLoading}
          showActionsColumn={true}
          openModal={openModal}
        />
      </div>
    </div>
  );
}

export default TopClientSalesTable;
