import React, { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../AdminNavbar/Navbar";
import Sidebar from "../AdminSidebar/Sidebar";
import Setting from "../AdminSetting/Setting";
// import AdminNavNotifications from "../AdminNotifications/AdminNavNotification/AdminNavNotifications";

const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  // const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("Overview");

  return (
    <div>
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        setActiveMenuItem={setActiveMenuItem}
        activeMenuItem={activeMenuItem}
      />
      <div className="lg:pl-60">
        <Navbar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          onSettingsClick={() => setModalOpen(true)}
          onNotificationClick={() => { }}
          activeMenuItem={activeMenuItem}
        />
        <main className="p-6">
          <Suspense fallback="Loading....">
            <Outlet />
          </Suspense>
          {isModalOpen && <Setting isModalOpen={isModalOpen} setModalOpen={setModalOpen} />}
        </main>
      </div>
    </div>
  );
};

export default Layout;

// {isNotificationOpen && (
//   <AdminNavNotifications
//     isNotificationOpen={isNotificationOpen}
//     setNotificationOpen={setNotificationOpen}
//   />
// )}