import React from "react";
import Card from "../Card/Card";
import Barcode from "react-barcode";

const RecursiveCard = ({ label, value, Comp }) => {
  if (Comp) return <Comp />;
  return Array.isArray(value) ? (
    <div className="space-y-3">
      <span className="text-text-secondary text-sm capitalize">{label}</span>
      {value.map((v, i) => (
        <RecursiveCard key={i} label={v.name} value={v.value} Comp={v?.Comp} />
      ))}
    </div>
  ) : (
    <div key={label} className={"flex flex-col space-y-1 "}>
      <span className="text-text-secondary text-sm capitalize">{label}</span>
      <p className="text-text-primary font-medium text-sm capitalize">
        {value}
      </p>
    </div>
  );
};

/**
 * DetailCard component.
 * Renders a modal with user details in a Card component.
 *
 * @param {Object} props - Props for the DetailCard component.
 * @param {Object} props.detail - Object containing user details.
 * @returns {JSX.Element} JSX code for rendering the DetailCard component.
 */
const DetailCard = ({
  detail,
  otherDetail,
  image,
  otherDetailTitle = "",
  title = "",
}) => {
  // const barCode = otherDetail['Bar Code'] || '';
  return (
    <React.Fragment>
      {detail && (
        <>
          {detail && Array.isArray(detail) ? (
            detail.map((det, i) => (
              <Card className="space-y-4 overflow-hidden break-all" key={i}>
                {title && (
                  <span className="text-sm font-medium to-text-primary">
                    {title}
                  </span>
                )}
                {Object.entries(det)?.map(([key, value]) => (
                  <div
                    key={key}
                    className={`flex ${
                      key.includes("[image]")
                        ? "flex-row items-center space-x-2"
                        : "flex-col space-y-1"
                    } `}
                  >
                    {key.includes("[image]") ? (
                      <div className="border-2 border-border-primary rounded-md w-9 h-6 flex items-center justify-center">
                        <img src={key.split("[image]")[1]} alt="" width={25} />
                      </div>
                    ) : !key.includes("!!") ? (
                      <span className="text-text-secondary text-sm capitalize">
                        {key}
                      </span>
                    ) : null}
                    <p className="text-text-primary font-medium text-sm">
                      {value || "-"}
                    </p>
                  </div>
                ))}
              </Card>
            ))
          ) : (
            <Card className="space-y-4 overflow-hidden break-all">
              {title && (
                <span className="text-sm font-medium to-text-primary">
                  {title}
                </span>
              )}

              {Object.entries(detail)?.map(([key, value]) => (
                <div
                  key={key}
                  className={`flex ${
                    key.includes("[image]")
                      ? "flex-row items-center space-x-2"
                      : "flex-col space-y-1"
                  } `}
                >
                  {key.includes("[image]") ? (
                    <div className="border-2 border-border-primary rounded-md w-9 h-6 flex items-center justify-center">
                      <img src={key.split("[image]")[1]} alt="" width={25} />
                    </div>
                  ) : !key.includes("!!") ? (
                    <span className="text-text-secondary text-sm capitalize">
                      {key}
                    </span>
                  ) : null}
                  {typeof value === "string" ? (
                    <p className="text-text-primary font-medium text-sm">
                      {value || "-"}
                    </p>
                  ) : typeof value === "function" ? (
                    value()
                  ) : (
                    JSON.stringify(value)
                  )}
                </div>
              ))}
            </Card>
          )}
        </>
      )}

      {otherDetail && (
        <Card className="space-y-4">
          <h6 className="text-text-primary font-semibold font-base">
            {otherDetailTitle}
          </h6>
          <div className="flex gap-4">
            {image && (
              <div className="flex flex-col mt-1">
                <img src={image} alt="Item" className="min-w-44 h-36 " />
                {/* {barCode && barCode && (
                  <div className="mt-2">
                    <Barcode value={barCode} />
                  </div>
                )} */}
              </div>
            )}

            <div className="flex flex-col space-y-4">
              {Object.entries(otherDetail)?.map(([key, value]) => (
                <RecursiveCard key={key} label={key} value={value} />
              ))}
            </div>
          </div>
        </Card>
      )}
    </React.Fragment>
  );
};

export default DetailCard;

// Sample Usage:
// import { DetailCard } from "path/to/DetailCard";
// const userDetail = {
//   firstName: "John",
//   lastName: "Doe",
//   age: 30,
//   email: "john.doe@example.com",
// };
// <DetailCard detail={userDetail} />
