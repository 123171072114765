import React from "react";
import CustomAdminModel from "../../../../../common/components/AdminModal/CustomAdminModal";
import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import useEditClient from "./useEditClient";
import CustomButton from "../../../../../common/components/Button/Button";
import { SelectGroup } from "../../../../../common/components";

function EditClient({ clientId, isModalOpen, setModalOpen, setUpdateClient }) {
  const {
    register,
    errors,
    onSubmit,
    handleCancel,
    handleSubmit,
    isDirty,
    staffUserOptions,
  } = useEditClient({
    clientId,
    isModalOpen,
    setModalOpen,
    setUpdateClient,
  });

  return (
    <CustomAdminModel
      isOpen={isModalOpen}
      onClose={() => setModalOpen(false)}
      title="Edit Client Detail"
      border="border-b"
      width="min-[280px]:w-[80%] min-[1080px]:w-[720px]"
      cancelButtonText="Cancel"
      saveButtonText="Save"
      cancelButtonBgColor="bg-gray-100"
      saveButtonBgColor="bg-surface-primary"
      cancelButtonTextColor="text-text-primary"
      saveButtonTextColor="text-white"
    >
      <form className="space-y-2 p-6" onSubmit={handleSubmit(onSubmit)}>
        {/* full name */}
        <span className="font-semibold text-base">Client Detail</span>
        <InputGroup
          labelText="Full Name"
          htmlfor="fullName"
          inputName="fullName"
          placeholder="Enter Full name"
          register={register}
          errors={errors}
        />
        {/* Arabic Name */}
        <InputGroup
          htmlfor="arabicName"
          labelText="Arabic Name"
          inputName="arabicName"
          placeholder="Enter Arabic Name"
          inputType="text"
          register={register}
          errors={errors}
        />

        {/* Phone Number */}
        <InputGroup
          htmlfor="phoneNumber"
          labelText="Phone Number"
          inputName="phoneNumber"
          placeholder="Enter Phone Number"
          inputType="text"
          register={register}
          errors={errors}
        />
        {/* email */}
        <InputGroup
          labelText="Email"
          htmlfor="email"
          inputName="email"
          placeholder="Enter email"
          register={register}
          errors={errors}
          readOnly={true}
        />
        {/* account */}
        <SelectGroup
          labelText="Account Manager"
          htmlFor="account"
          inputName="account"
          width="w-fit"
          options={staffUserOptions}
          textColor="text-text-primary"
          className="!py-1 mt-2"
          register={register}
          errors={errors}
        />
        <h3 className="font-semibold text-base my-3">Company Detail</h3>
        <InputGroup
          labelText="Company Name"
          htmlfor="companyname"
          inputName="companyname"
          placeholder="Company name"
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText="Company Address"
          htmlfor="companyaddress"
          inputName="companyaddress"
          placeholder="Company address"
          inputType="text"
          register={register}
          errors={errors}
        />
        {/* website */}
        <InputGroup
          labelText="Website"
          htmlfor="website"
          inputName="website"
          placeholder="Website"
          register={register}
          errors={errors}
        />
        {/* commercial register */}
        <InputGroup
          labelText="Commercial Registration"
          htmlfor="commercialregister"
          inputName="commercialregister"
          placeholder="Commercial register"
          register={register}
          errors={errors}
        />
        {/* here tax field will be added but BE is not giving it in response so i add and comment it bottom */}

        {/* Buttons with a horizontal line above */}
        <div className="flex justify-end space-x-2 ">
          <CustomButton
            width="20px"
            onClick={handleCancel}
            text="Cancel"
            bgColor="bg-white"
            textColor="text-text-primary"
            border="border"
            className="hover:!bg-gray-200"
          />
          <CustomButton
            width="20px"
            type="submit"
            text="Save"
            bgColor="bg-blue-600"
            textColor="text-white"
            border="border"
            disabled={!isDirty}
          />
        </div>
      </form>
    </CustomAdminModel>
  );
}

export default EditClient;
