import * as React from "react";

function WhiteIndicator(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4500_76654)">
        <circle cx="7" cy="6" r="5" fill="white" />
        <circle cx="7" cy="6" r="4.5" stroke="#EAECF0" />
      </g>
      <defs>
        <filter
          id="filter0_d_4500_76654"
          x="0"
          y="0"
          width="14"
          height="14"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4500_76654"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4500_76654"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default WhiteIndicator;
