import React from "react";
import {
  CustomButton,
  Loader,
  SelectGroup,
} from "../../../../common/components";
import useUpgradeFreeTrail from "./useUpgradeFreeTrail";
import { useUserContext } from "../../../../../context/UserContext";
import SelectPlanCards from "../../../../Signup/ClientProfile/SetPlan/SelectPlanCards";
function UpgradeFreeTrail({ closeModal, selectedItem }) {
  const { user } = useUserContext();
  const {
    setBranchCount,
    branchCount,
    setActiveTab,
    activeTab,
    setSelectedPlan,
    selectedPlan,
    additionalBranchPrice,
    totalPrice,
    tax,
    totalPriceBeforeTax,
    pricingData,
    isLoading,
    priceSummaryLoading,
    staffUserOptions,
    errors,
    onSubmit,
    handleSelectChange,
    selectedAccountManager,
    isButtonDisabled
  } = useUpgradeFreeTrail({ closeModal, selectedItem });

  return (
    <div className="p-4">
      <div className="space-y-4 !mt-3">
        <div className="bg-white border rounded-lg    p-4 ">
          <SelectPlanCards
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            branchCount={branchCount}
            setBranchCount={setBranchCount}
            additionalBranchPrice={additionalBranchPrice}
            pricingData={pricingData}
            isLoading={isLoading}
            user={user}
          />
          <SelectGroup
            labelText="Subscriber Account Manager is"
            htmlFor="subscriptionprovider"
            inputName="subscriptionprovider"
            width="w-fit"
            options={staffUserOptions}
            textColor="text-text-primary"
            className="!py-2"
            value={selectedAccountManager}
            onChange={handleSelectChange}
          />
          {errors.subscriptionprovider && (
            <p className="text-red-500">
              {errors.subscriptionprovider.message}
            </p>
          )}
        </div>

        <div className="bg-white border rounded-lg p-6 mx-auto mt-2">
          <h4 className="text-text-primary font-semibold mb-5">Summary</h4>
          {priceSummaryLoading ? (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-col w-full mt-4 space-y-2">
              <div className="flex justify-between items-center">
                <span className="text-text-secondary font-normal text-base">
                  Total Before Tax
                </span>
                <span className="text-text-primary text-base font-semibold">
                  {totalPriceBeforeTax ?? "0"} SAR
                </span>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-text-secondary font-normal text-base">
                  VAT Amount
                </span>
                <span className="text-text-primary text-base font-semibold">
                  {tax ?? "0"} SAR
                </span>
              </div>
              <div className="flex justify-between items-center mt-2">
                <span className="text-text-primary font-semibold">
                  Subtotal
                </span>
                <span className="text-text-link text-lg font-semibold">
                  {totalPrice ?? "0"} SAR
                </span>
              </div>
            </div>
          )}
          {/* divider */}
          <hr className="border-t-2 border-gray-100 px-2 my-4" />
        </div>
          <div className="flex items-center gap-3">
            <CustomButton
              text="Confirm"
              type="submit"
              onClick={onSubmit}
              className="mt-2"
              disabled={isButtonDisabled}
            />
          </div>
      </div>
    </div>
  );
}
export default UpgradeFreeTrail;
