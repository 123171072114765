// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createSaleInvoicesAPi from "./SaleInvoices";
import { showToast } from "../../../components/common/utils/showToast.util";

/**
 * Manages state for sale invoices.
 * Defines initial state for each sale invoice action.
 */
const initialState = {
  getAllSaleInvoices: {
    data: null,
    totalPages: 0,
    totalRecords: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleSaleInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addSaleInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateSaleInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  returnSaleInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteSaleInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getclientsSales: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the sale invoice process.
 * These action types are prefixed with the 'sale-invoices' base path for better organization.
 */
const BASE = "sale-invoices";

export const ActionTypes = {
  GET_ALL_SALE_INVOICES: `${BASE}/get-all`, // Action type for get all sale invoices
  ADD_SALE_INVOICE: `${BASE}`, // Action type for add sale invoice
  UPDATE_SALE_INVOICE: `${BASE}/update`, // Action type for update sale invoice
  DELETE_SALE_INVOICE: `${BASE}/delete`, // Action type for delete sale invoice
  RETURN_SALE_INVOICE: `${BASE}/return-sale-invoice`, // Action type for return sale invoice
};

// Creating an instance of the invoices service with a base URL 'sale-invoices'
const saleInvoicesService = createSaleInvoicesAPi("sale-invoices");

/**
 * Initiates the getAllSaleInvoices process for sale invoices.
 * @param {object} payload - Payload containing query parameters.
 * @param {number} payload.page - Current page.
 * @param {number} payload.pageSize - Number of items per page.
 * @param {string} payload.sortColumn - Column ID for sorting sale invoices.
 * @param {string} payload.order - Order for sorting sale invoices by ascending or descending.
 * @param {object} payload.condition - Additional conditions for filtering.
 * @param {object} payload.attributes - Additional attributes for customization.
 */
export const getAllSaleInvoices = createAsyncThunk(
  ActionTypes.GET_ALL_SALE_INVOICES,

  async ({ payload, successCallBack }, thunkAPI) => {
    const response = await saleInvoicesService.getAllSaleInvoices(payload);
    if (response?.data?.Succeeded) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

/**
 * Initiates the getSingleSaleInvoice process for sale invoices.
 * @param {string} invoiceId - ID of the sale invoice.
 */
export const getSingleSaleInvoice = createAsyncThunk(
  `${BASE}/getSingleSaleInvoice`,

  async ({ invoiceId }, thunkAPI) => {
    const response = await saleInvoicesService.getSingleSaleInvoice(invoiceId);
    return response?.data?.data;
  }
);

/**
 * Initiates the updateSaleInvoice process for sale invoices.
 * @param {string} invoiceId - ID of the sale invoice.
 * @param {object} payload - Payload containing updated data.
 */
export const updateSaleInvoice = createAsyncThunk(
  ActionTypes.UPDATE_SALE_INVOICE,

  async ({ invoiceId, payload }, thunkAPI) => {
    try {
      const response = await saleInvoicesService.updateSaleInvoice(
        invoiceId,
        payload
      );
      if (response?.data?.Succeeded) {
        showToast("Sale invoice updated successfully!");
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);
/**
 * Initiates the returnSaleInvvoice process for sale invoices.
 * @param {string} invoiceId - ID of the sale invoice.
 */
export const returnSaleInvoice = createAsyncThunk(
  ActionTypes.RETURN_SALE_INVOICE,

  async ({ invoiceId, successCallBack }, thunkAPI) => {
    try {
      const response = await saleInvoicesService.returnSaleInvoice(invoiceId);
      if (response?.data?.Succeeded) {
        successCallBack(response.data);
        showToast("Sale invoice returned successfully!");
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addSaleInvoice process for sale invoices.
 * @param {object} payload - Payload containing data for the new sale invoice.
 */
export const addSaleInvoice = createAsyncThunk(
  ActionTypes.ADD_SALE_INVOICE,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await saleInvoicesService.addSaleInvoice(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const getclientsSales = createAsyncThunk(
  "getclientsSales",
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await saleInvoicesService.getclientsSales(payload);
      return response?.data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deleteSaleInvoice process for sale invoices.
 * @param {string} invoiceId - ID of the sale invoice to be deleted.
 */
export const deleteSaleInvoice = createAsyncThunk(
  ActionTypes.DELETE_SALE_INVOICE,

  async ({ invoiceId, successCallBack }, thunkAPI) => {
    try {
      const response = await saleInvoicesService.deleteSaleInvoice(invoiceId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Sale invoice deleted successfully!");
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for sale invoice related state management.
 */
export const saleInvoicesSlice = createSlice({
  name: "saleInvoices",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllSaleInvoices action.
     */
    reset: (state) => {
      state.getAllSaleInvoices = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleSaleInvoice = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getclientsSales = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllSaleInvoices action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllSaleInvoices.pending, (state) => {
        state.getAllSaleInvoices.isLoading = true;
        state.getAllSaleInvoices.message = "";
        state.getAllSaleInvoices.isError = false;
        state.getAllSaleInvoices.isSuccess = false;
        state.getAllSaleInvoices.data = null;
      })
      /**
       * Updates the state when getAllSaleInvoices action is fulfilled/successful.
       * Updates loading and success flags and sets getAllSaleInvoices data with the payload.
       */
      .addCase(getAllSaleInvoices.fulfilled, (state, action) => {
        state.getAllSaleInvoices.isLoading = false;
        state.getAllSaleInvoices.isSuccess = true;
        state.getAllSaleInvoices.data = action.payload.data;
        state.getAllSaleInvoices.totalPages = action.payload.totalPages;
        state.getAllSaleInvoices.totalRecords = action.payload.TotalRecords;
      })
      /**
       * Updates the state when getAllSaleInvoices action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllSaleInvoices.rejected, (state, action) => {
        state.getAllSaleInvoices.message = action.payload?.message;
        state.getAllSaleInvoices.isLoading = false;
        state.getAllSaleInvoices.isError = true;
        state.getAllSaleInvoices.data = null;
      })
      /**
       * Updates the state while the getSingleSaleInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSingleSaleInvoice.pending, (state) => {
        state.getSingleSaleInvoice.isLoading = true;
        state.getSingleSaleInvoice.message = "";
        state.getSingleSaleInvoice.isError = false;
        state.getSingleSaleInvoice.isSuccess = false;
        state.getSingleSaleInvoice.data = null;
      })
      /**
       * Updates the state when getSingleSaleInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleSaleInvoice data with the payload.
       */
      .addCase(getSingleSaleInvoice.fulfilled, (state, action) => {
        state.getSingleSaleInvoice.isLoading = false;
        state.getSingleSaleInvoice.isSuccess = true;
        state.getSingleSaleInvoice.data = action.payload;
      })
      /**
       * Updates the state when getSingleSaleInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleSaleInvoice.rejected, (state, action) => {
        state.getSingleSaleInvoice.message = action.payload?.message;
        state.getSingleSaleInvoice.isLoading = false;
        state.getSingleSaleInvoice.isError = true;
        state.getSingleSaleInvoice.data = null;
      })
      /**
       * Updates the state while the addSaleInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addSaleInvoice.pending, (state) => {
        state.addSaleInvoice.isLoading = true;
        state.addSaleInvoice.message = "";
        state.addSaleInvoice.isError = false;
        state.addSaleInvoice.isSuccess = false;
        state.addSaleInvoice.data = null;
      })
      /**
       * Updates the state when addSaleInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets addSaleInvoice data with the payload.
       */
      .addCase(addSaleInvoice.fulfilled, (state, action) => {
        state.addSaleInvoice.isLoading = false;
        state.addSaleInvoice.isSuccess = true;
        state.addSaleInvoice.data = action.payload;
      })
      /**
       * Updates the state when addSaleInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addSaleInvoice.rejected, (state, action) => {
        state.addSaleInvoice.message = action.payload?.message;
        state.addSaleInvoice.isLoading = false;
        state.addSaleInvoice.isError = true;
        state.addSaleInvoice.data = null;
      })
      /**
       * Updates the state while the updateSaleInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(updateSaleInvoice.pending, (state) => {
        state.updateSaleInvoice.isLoading = true;
        state.updateSaleInvoice.message = "";
        state.updateSaleInvoice.isError = false;
        state.updateSaleInvoice.isSuccess = false;
        state.updateSaleInvoice.data = null;
      })
      /**
       * Updates the state when updateSaleInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets updateSaleInvoice data with the payload.
       */
      .addCase(updateSaleInvoice.fulfilled, (state, action) => {
        state.updateSaleInvoice.isLoading = false;
        state.updateSaleInvoice.isSuccess = true;
        state.updateSaleInvoice.data = action.payload;
      })
      /**
       * Updates the state when updateSaleInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateSaleInvoice.rejected, (state, action) => {
        state.updateSaleInvoice.message = action.payload?.message;
        state.updateSaleInvoice.isLoading = false;
        state.updateSaleInvoice.isError = true;
        state.updateSaleInvoice.data = null;
      })
      /**
       * Updates the state while the returnSaleInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(returnSaleInvoice.pending, (state) => {
        state.returnSaleInvoice.isLoading = true;
        state.returnSaleInvoice.message = "";
        state.returnSaleInvoice.isError = false;
        state.returnSaleInvoice.isSuccess = false;
        state.returnSaleInvoice.data = null;
      })
      /**
       * Updates the state when returnSaleInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets returnSaleInvoice data with the payload.
       */
      .addCase(returnSaleInvoice.fulfilled, (state, action) => {
        state.returnSaleInvoice.isLoading = false;
        state.returnSaleInvoice.isSuccess = true;
        state.returnSaleInvoice.data = action.payload;
      })
      /**
       * Updates the state when returnSaleInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(returnSaleInvoice.rejected, (state, action) => {
        state.returnSaleInvoice.message = action.payload?.message;
        state.returnSaleInvoice.isLoading = false;
        state.returnSaleInvoice.isError = true;
        state.returnSaleInvoice.data = null;
      })
      /**
       * Updates the state while the deleteSaleInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(deleteSaleInvoice.pending, (state) => {
        state.deleteSaleInvoice.isLoading = true;
        state.deleteSaleInvoice.message = "";
        state.deleteSaleInvoice.isError = false;
        state.deleteSaleInvoice.isSuccess = false;
        state.deleteSaleInvoice.data = null;
      })
      /**
       * Updates the state when deleteSaleInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets deleteSaleInvoice data with the payload.
       */
      .addCase(deleteSaleInvoice.fulfilled, (state, action) => {
        state.deleteSaleInvoice.isLoading = false;
        state.deleteSaleInvoice.isSuccess = true;
        state.deleteSaleInvoice.data = action.payload;
      })
      /**
       * Updates the state when deleteSaleInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(deleteSaleInvoice.rejected, (state, action) => {
        state.deleteSaleInvoice.message = action.payload?.message;
        state.deleteSaleInvoice.isLoading = false;
        state.deleteSaleInvoice.isError = true;
        state.deleteSaleInvoice.data = null;
      })
      /**
       * Updates the state while the getclientsSales action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getclientsSales.pending, (state) => {
        state.getclientsSales.isLoading = true;
        state.getclientsSales.message = "";
        state.getclientsSales.isError = false;
        state.getclientsSales.isSuccess = false;
        state.getclientsSales.data = null;
      })
      /**
       * Updates the state when getclientsSales action is fulfilled/successful.
       * Updates loading and success flags and sets getclientsSales data with the payload.
       */
      .addCase(getclientsSales.fulfilled, (state, action) => {
        state.getclientsSales.isLoading = false;
        state.getclientsSales.isSuccess = true;
        state.getclientsSales.data = action.payload;
      })
      /**
       * Updates the state when getclientsSales action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getclientsSales.rejected, (state, action) => {
        state.getclientsSales.message = action.payload?.message;
        state.getclientsSales.isLoading = false;
        state.getclientsSales.isError = true;
        state.getclientsSales.data = null;
      });
  },
});

/**
 * Destructures the reset action from the saleInvoicesSlice actions.
 */
export const { reset } = saleInvoicesSlice.actions;

/**
 * Exports the default reducer generated by saleInvoicesSlice.
 */
export default saleInvoicesSlice.reducer;
