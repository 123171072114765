import * as React from "react";

function Tag(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5032 2.42804L3.93754 3.93741L2.42816 11.503C2.40451 11.6238 2.41095 11.7486 2.44692 11.8663C2.48289 11.984 2.54728 12.0911 2.63441 12.178L12.4219 21.9655C12.4909 22.0361 12.5733 22.0922 12.6642 22.1305C12.7552 22.1688 12.8529 22.1885 12.9516 22.1885C13.0503 22.1885 13.148 22.1688 13.239 22.1305C13.3299 22.0922 13.4123 22.0361 13.4813 21.9655L21.9657 13.4812C22.0362 13.4122 22.0923 13.3298 22.1306 13.2388C22.1689 13.1479 22.1886 13.0502 22.1886 12.9515C22.1886 12.8528 22.1689 12.7551 22.1306 12.6641C22.0923 12.5732 22.0362 12.4908 21.9657 12.4218L12.1782 2.63429C12.0912 2.54716 11.9842 2.48277 11.8664 2.4468C11.7487 2.41083 11.624 2.40439 11.5032 2.42804V2.42804Z"
        stroke="#1A1A1A"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.875 9C8.49632 9 9 8.49632 9 7.875C9 7.25368 8.49632 6.75 7.875 6.75C7.25368 6.75 6.75 7.25368 6.75 7.875C6.75 8.49632 7.25368 9 7.875 9Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export default Tag;
