import * as React from "react";

function DeviceTable(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M16.25 16.25V3.75C16.25 3.05964 15.6904 2.5 15 2.5L5 2.5C4.30964 2.5 3.75 3.05964 3.75 3.75V16.25C3.75 16.9404 4.30964 17.5 5 17.5H15C15.6904 17.5 16.25 16.9404 16.25 16.25Z"
        strokeLinecap="round"
        stroke={props.strokeColor || "#667085"} 
        strokeLinejoin="round"
      />
      <path
        d="M3.75 5H16.25"
        stroke={props.strokeColor || "#667085"} 
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 15H16.25"
        stroke={props.strokeColor || "#667085"} 
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeviceTable;
