import React, { useState } from "react";

import { Radio, RadioGroup } from "@headlessui/react";
import {
  DirectoryFeature,
  InventoryFeature,
  InvoicesFeature,
  ReportFeature,
} from "../assets/images";
import { Card } from "../components/common/components";
import { classNames } from "../helpers/generateClassNames";
import { useTranslation } from "react-i18next";

const Features = () => {
  const { t } = useTranslation();

  const [currentFeature, setCurrentFeature] = useState(
    featureTabs.find((f) => f.value === "inventory")
  );

  return (
    <React.Fragment>
      <div className="w-full faqpagehero 1xl:px-29 px-4 relative">
        <div className="text-center xs:max-w-2xl mx-auto space-y-5">
          <h1 className="font-bold text-5xl md:leading-wider leading-tight lg:pt-44 pt-36">
            {t("landingpage.features.feature")}{" "}
          </h1>
          <p className="mt-2 md:text-base text-sm text-gray-600 font-medium">
            {t("landingpage.features.text")}{" "}
          </p>
        </div>
        <FeaturesToggle
          currentFeature={currentFeature}
          setCurrentFeature={setCurrentFeature}
        />
        <FeatureCards currentFeature={currentFeature} />
      </div>
    </React.Fragment>
  );
};

export default Features;

const FeaturesToggle = ({ currentFeature, setCurrentFeature }) => {
  return (
    <div className="w-fit mx-auto text-center space-y-3 mt-16 relative md:min-w-72">
      <fieldset aria-label="Feature" className="!mt-1">
        <RadioGroup
          value={currentFeature}
          onChange={setCurrentFeature}
          className="flex w-fit max-h-16 overflow-hidden items-center mx-auto gap-2 !mt-1 border border-border-primary p-1 rounded-full bg-surface-gray"
        >
          {featureTabs.map((option) => (
            <Radio
              key={option.value}
              value={option}
              as="button"
              className={({ checked }) =>
                classNames(
                  "rounded-full md:h-12 h-10 text-sm font-medium flex items-center md:gap-3 gap-2 xs:px-3 px-1",
                  checked
                    ? "bg-blue-500 text-white xs:px-3 px-2"
                    : "text-text-primary"
                )
              }
            >
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      </fieldset>
    </div>
  );
};

function FeatureCards({ currentFeature }) {
  const { t } = useTranslation();

  const featuresInfo = [
    {
      heading: "Inventory",
      id: "feature-inventory",
      href: "#",
      featurePoints: [
        {
          point: t("landingpage.features.invtrf"),
          subPoints: [
            t("landingpage.features.int1"),
            t("landingpage.features.int2"),
            t("landingpage.features.int3"),
          ],
        },
        {
          point: t("landingpage.features.stocktaking"),
          subPoints: [
            t("landingpage.features.stk1"),
            t("landingpage.features.stk2"),
            t("landingpage.features.stk3"),
          ],
        },
        {
          point: t("landingpage.features.inventorytransaction"),
          subPoints: [
            t("landingpage.features.invtrf1"),
            t("landingpage.features.invtrf2"),
            t("landingpage.features.invtrf3"),
          ],
        },
      ],
      featureImage: InventoryFeature,
    },
    {
      heading: "Invoices",
      id: "feature-invoices",
      href: "#",
      featurePoints: [
        {
          point: t("landingpage.invoices.salesinvoices"),

          subPoints: [
            t("landingpage.invoices.salesinvoices1"),
            t("landingpage.invoices.salesinvoices2"),
            t("landingpage.invoices.salesinvoices3"),
          ],
        },
        {
          point: t("landingpage.invoices.purchaseinvoices"),
          subPoints: [
            t("landingpage.invoices.purchaseinvoices1"),
            t("landingpage.invoices.purchaseinvoices2"),
            t("landingpage.invoices.purchaseinvoices3"),
          ],
        },
        {
          point: t("landingpage.invoices.expenseinvoices"),
          subPoints: [
            t("landingpage.invoices.expenseinvoices1"),
            t("landingpage.invoices.expenseinvoices2"),
            t("landingpage.invoices.expenseinvoices3"),
          ],
        },
      ],
      featureImage: InvoicesFeature,
    },
    {
      heading: "Directory",
      id: "feature-directory",
      href: "#",
      featurePoints: [
        {
          point: t("landingpage.directory.customerdirectory"),
          subPoints: [
            t("landingpage.directory.customerdirectory1"),
            t("landingpage.directory.customerdirectory2"),
            t("landingpage.directory.customerdirectory3"),
          ],
        },
        {
          point: t("landingpage.directory.supplierdirectory"),
          subPoints: [
            t("landingpage.directory.supplierdirectory1"),
            t("landingpage.directory.supplierdirectory2"),
          ],
        },
        {
          point: t("landingpage.directory.itemsdirectory"),
          subPoints: [
            t("landingpage.directory.itemsdirectory1"),
            t("landingpage.directory.itemsdirectory2"),
          ],
        },
        {
          point: t("landingpage.directory.categorydirectory"),

          subPoints: [
            t("landingpage.directory.categorydirectory1"),
            t("landingpage.directory.categorydirectory2"),
            t("landingpage.directory.categorydirectory3"),
          ],
        },
      ],
      featureImage: DirectoryFeature,
    },
    {
      heading: "Report",
      id: "feature-report",
      href: "#",
      featurePoints: [
        {
          point: t("landingpage.reports.salesreports"),
          subPoints: [
            t("landingpage.reports.salesreports1"),
            t("landingpage.reports.salesreports2"),
            t("landingpage.reports.salesreports3"),
          ],
        },
        {
          point: t("landingpage.reports.purchasereports"),
          subPoints: [
            t("landingpage.reports.purchasereports1"),
            t("landingpage.reports.purchasereports2"),
          ],
        },
        {
          point: t("landingpage.reports.taxesreports"),
          subPoints: [
            t("landingpage.reports.taxesreports1"),
            t("landingpage.reports.taxesreports2"),
          ],
        },
        {
          point: t("landingpage.reports.profitlossreports"),
          subPoints: [
            t("landingpage.reports.profitlossreports1"),
            t("landingpage.reports.profitlossreports2"),
            t("landingpage.reports.profitlossreports3"),
          ],
        },
        {
          point: t("landingpage.reports.expensereports"),
          subPoints: [
            t("landingpage.reports.expensereports1"),
            t("landingpage.reports.expensereports2"),
            t("landingpage.reports.expensereports3"),
          ],
        },
        {
          point: t("landingpage.reports.inventoryreports"),
          subPoints: [
            t("landingpage.reports.inventoryreports1"),
            t("landingpage.reports.inventoryreports2"),
            t("landingpage.reports.inventoryreports3"),
          ],
        },
      ],
      featureImage: ReportFeature,
    },
  ];
  // Find the feature information corresponding to the current feature selected
  const featureDetails = featuresInfo.find(
    (feature) => feature.heading.toLowerCase() === currentFeature.value
  );

  return (
    <React.Fragment>
      <Card padding="md:p-8 p-4" className="grid grid-cols-12 mt-10 gap-6">
        <div className="lg:col-span-7 col-span-12 lg:order-1 order-2">
          <h5 className="text-text-primary font-semibold text-2xl">
            {featureDetails.heading}
          </h5>
          <ol className="list-decimal pl-5 space-y-4 mt-6">
            {featureDetails.featurePoints.map((point, index) => (
              <li key={index}>
                <h2 className="text-base text-text-primary font-semibold">
                  {point.point}
                </h2>
                <ul className="list-disc mt-3 text-base text-text-secondary pl-1 tracking-tight">
                  {point.subPoints.map((subPoint, subIndex) => (
                    <li key={subIndex}>{subPoint}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ol>
        </div>
        <div className="lg:col-span-5 col-span-12 lg:order-2 order-1">
          <img
            src={featureDetails.featureImage}
            className="lg:ml-auto mx-auto"
            alt={featureDetails.heading}
          />
        </div>
      </Card>
    </React.Fragment>
  );
}

const featureTabs = [
  { value: "inventory", label: "Inventory" },
  { value: "invoices", label: "Invoices" },
  { value: "directory", label: "Directory" },
  { value: "report", label: "Report" },
];
