import React from "react";
import CustomAdminModal from "../../../common/components/AdminModal/CustomAdminModal";
import CustomButton from "../../../common/components/Button/Button";
const DeleteTable = ({ isOpen, onClose, onConfirm }) => {
  return (
    <CustomAdminModal
      isOpen={isOpen}
      onClose={onClose}
      title="Confirm Deletion"
      width="min-[280px]:w-[80%] min-[1080px]:w-[420px]"
    >
      <div className="text-center p-4">
        <p>Are you sure you want to delete this table?</p>
        <div className="flex justify-end gap-4 mt-4">
          <CustomButton
            text="Yes"
            width="w-fit"
            textColor="text-white"
            bgColor="bg-red-500"
            hoverTextColor="text-text-white"
            padding="py-2 px-4"
            border="border"
            hoverBgColor="hover:bg-red-700"
            onClick={onConfirm}
          />
          <CustomButton
            text="No"
            width="w-fit"
            textColor="text-white"
            bgColor="bg-gray-300"
            hoverTextColor="text-text-white"
            padding="py-2 px-4"
            border="border"
            hoverBgColor="hover:bg-gray-600"
            onClick={onClose}
          />
        </div>
      </div>
    </CustomAdminModal>
  );
};

export default DeleteTable;
