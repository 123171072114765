import { useEffect, useState } from "react";
import { LeftArrow, NextArrow } from "../../Icons";
import CustomButton from "../Button/Button";

export default function PaginationBar({
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    const generatePageNumbers = () => {
      const pageNumbersArray = [];
      const maxPagesToShow = Math.min(5, totalPages); // Ensure we don't exceed the total pages
      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      let endPage = startPage + maxPagesToShow - 1;
  
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }
  
      for (let i = startPage; i <= endPage; i++) {
        pageNumbersArray.push(i);
      }
  
      setPageNumbers(pageNumbersArray);
    };
  
    generatePageNumbers();
  }, [currentPage, totalPages]);
  

  const handleNextClick = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePreviousClick = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
 
  
  return (
    <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={handlePreviousClick}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={handleNextClick}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div className="mx-auto">
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePreviousClick}
              disabled={currentPage === 1}
              className={`relative inline-flex gap-2 order-1 rtl:order-3 items-center rounded-l-md px-3 py-2 disabled:text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:pointer-events-none disabled:!cursor-not-allowed`}
            >
              <LeftArrow className="h-5 w-5" aria-hidden="true" />
              <span className="leading-none text-sm font-medium">Previous</span>
            </button>

            {pageNumbers?.map((number) => (
              <button
                key={number}
                onClick={() => handlePageClick(number)}
                className={`relative inline-flex order-2 items-center px-4 py-2 text-sm font-semibold ${
                  currentPage === number ? "bg-surface-gray" : "text-gray-900"
                } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
              >
                {number}
              </button>
            ))}
            <button
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
              className="relative inline-flex order-3 rtl:order-1 gap-2 items-center rounded-r-md px-3 py-2 disabled:text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <NextArrow className="h-5 w-5" aria-hidden="true" />
              <span className="leading-none text-sm font-medium">Next</span>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
