import * as React from "react";

function SignalIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6125 20.7625C12.7926 19.6303 14.3646 18.9982 16 18.9982C17.6353 18.9982 19.2074 19.6303 20.3875 20.7625"
        stroke="#12B76A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.375 16.5125C9.68176 14.2616 12.777 13.0017 16 13.0017C19.223 13.0017 22.3182 14.2616 24.625 16.5125"
        stroke="#12B76A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.13751 12.275C6.56608 8.89627 11.1864 7.00226 16 7.00226C20.8136 7.00226 25.4339 8.89627 28.8625 12.275"
        stroke="#12B76A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 26.5C16.8284 26.5 17.5 25.8284 17.5 25C17.5 24.1716 16.8284 23.5 16 23.5C15.1716 23.5 14.5 24.1716 14.5 25C14.5 25.8284 15.1716 26.5 16 26.5Z"
        fill="#12B76A"
      />
    </svg>
  );
}

export default SignalIcon;
