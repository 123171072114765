import React from "react";
import { Link } from "react-router-dom";
import { CaretRight, Chats, GrayDot } from "../../../../common/Icons";
import { CustomButton, Loader } from "../../../../common/components";
import ClientSalesHistory from "./ClientSalesHistory/ClientSalesHistory";

import ExpenseChart from "./ClientDetailCharts/ExpenseChart/ExpenseChart";
import SalesChart from "./ClientDetailCharts/SalesChart/SalesChart";
import NetIncomeChart from "./ClientDetailCharts/NetIncomeChart/NetIncomeChart";
import { useClientSales } from "./useClientSalesDetail";
import { DefaultImg } from "../../../../../assets/images";

function ClientSalesDetail() {
  const { clientDetails, viewClientDetail } = useClientSales();

  return (
    <div>
      <div className="flex items-center space-x-2">
        <Link
          to="/admin/sales"
          className="text-text-gray font-normal text-sm hover:text-sky-blue"
        >
          Sales
        </Link>{" "}
        <CaretRight />
        <span className="text-text-primary font-medium text-sm">
          {clientDetails?.profile?.fullName??"-"}
        </span>
      </div>
      <div className="border rounded-lg p-4 mt-8">
        <div className="flex items-center py-4">
          <img
            src={clientDetails?.profile?.profilePic || DefaultImg}
            alt="Pic"
            className="inline-block h-14 w-14 rounded-full border mr-3"
          />

          <div className="flex-grow">
            <p className="text-text-primary text-lg font-semibold">
              {clientDetails?.profile?.fullName??"-"}
            </p>
            <div className="flex gap-3">
              {" "}
              <p className="text-text-secondary text-sm font-normal">
                {clientDetails?.profile?.arabicName??"-"}
              </p>{" "}
              <p className="text-text-secondary text-sm font-normal mt-2">
                <GrayDot />
              </p>
              <p className="text-text-secondary text-sm font-normal">
                {clientDetails?.email??"-"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <ClientSalesHistory viewClientDetail={viewClientDetail} />
        </div>

        <div className="flex flex-row gap-4 mt-8 w-full ">
          <div className="w-1/3 border rounded-lg p-3  overflow-auto">
            <SalesChart />
          </div>
          <div className="w-1/3 border rounded-lg p-3  overflow-auto">
            <NetIncomeChart />
          </div>
          <div className="w-1/3  overflow-auto border rounded-lg p-3">
            <ExpenseChart />
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default ClientSalesDetail;
