import React from "react";
import {
  CustomButton,
  InputGroup,
  SelectGroup,
} from "../../../../../../common/components";
import useAdminEditUser from "./useAdminEditUser";

function AdminEditUser({ user, onClose,setUpdateClient }) {
  const { onSubmit, handleSubmit, register, errors, isDirty } =
    useAdminEditUser({ user, onClose,setUpdateClient });
  return (
    <div className="p-3">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 ">
        {/* full name */}
        <InputGroup
          labelText="Full Name"
          htmlfor="fullname"
          placeholder="Enter Full Name"
          inputName="fullname"
          register={register}
          errors={errors}
        />
        {/* phone number  */}
        <InputGroup
          labelText="Phone Number"
          htmlfor="phoneNumber"
          inputName="phoneNumber"
          placeholder="Enter Phone Number"
          register={register}
          errors={errors}
        />
        {/* email */}
        <InputGroup
          labelText="Email"
          htmlfor="email"
          inputName="email"
          placeholder="Enter Email"
          register={register}
          errors={errors}
          readOnly={true}
        />
        <SelectGroup
          labelText="Role"
          htmlfor="role"
          inputName="role"
          options={Role}
          register={register}
          errors={errors}
          disabled={true}

        />
        <div className="flex justify-end space-x-2 pt-4 ">
          <CustomButton
            width="20px"
            text="Cancel"
            bgColor="bg-white"
            textColor="text-text-primary"
            border="border"
            onClick={onClose}
            />
          <CustomButton
            width="20px"
            text="Save"
            type="submit"
            textColor="text-white"
            disabled={!isDirty}

          />
        </div>{" "}
      </form>
    </div>
  );
}

export default AdminEditUser;
const Role = [
  { value: "", label: "Select Role" },
  { value: "ACCOUNT_MANAGER", label: "Account Manager" },
  { value: "ACCOUNTING", label: "Accounting" },
];
