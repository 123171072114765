import React from "react";
import { ArrowLeft, ToggleOff, ToggleOn } from "../../../../../common/Icons";
import { CustomButton } from "../../../../../common/components";
import useAdminNotificationSetting from "./useAdminNotificatonSetting";

const AdminNotificationSetting = ({ handleBackClick }) => {
  const {
    settings,
    handleToggle,
    isLoader,
  } = useAdminNotificationSetting();

  const getToggleIcon = (isEnabled) => (isEnabled ? <ToggleOn /> : <ToggleOff />);

  return (
    <div className="p-4">
      <div className="flex gap-2 mb-4">
        <CustomButton
          border="border"
          width="w-fit"
          icon={<ArrowLeft stroke />}
          iconPosition="after"
          padding="px-2 py-2"
          onClick={handleBackClick}
        />
        <h3 className="text-lg font-medium mt-2 text-gray-900">
          Notification Settings
        </h3>
      </div>
      {!isLoader && settings.map((setting) => (
        <div key={setting.id} className="flex items-center justify-between mt-6">
          <label className="text-sm font-semibold">
            {setting.id === 1 && "New Subscriber"}
            {setting.id === 2 && "Successful Subscription Upgrade"}
            {setting.id === 3 && "Expired Subscription"}
            {setting.id === 4 && "Trial Period Ending"}
            {setting.id === 5 && "Client Assigned to Account Manager"}
          </label>
          <div onClick={() => handleToggle(setting.id)}>
            {getToggleIcon(setting.isEnabled)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdminNotificationSetting;
