/**
 * Manages state for suppliers.
 * Defines initial state for each supplier action.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createSuppliersApi from "./SuppliersService";
import { showToast } from "../../components/common/utils/showToast.util";
import store from "../../store/store";

/**
 * Initial state structure defining various properties related to supplier processes.
 * Each property represents a specific supplier action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllSuppliers, getSingleSupplier, updateSupplier, addSupplier, and deleteSupplier
 * store data, error, success, loading, and message status for corresponding supplier actions.
 */
const initialState = {
  getAllSuppliers: {
    data: null,
    totalPages: 0,
    totalRecords: 0,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleSupplier: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateSupplier: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addSupplier: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteSupplier: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the supplier process.
 * These action types are prefixed with the 'suppliers' base path for better organization.
 */
const BASE = "suppliers";

export const ActionTypes = {
  GET_ALL_SUPPLIERS: `${BASE}/get-all`, // Action type for get all suppliers
  ADD_SUPPLIER: `${BASE}`, // Action type for add customer
};

// Creating an instance of the suppliers service with a base URL 'suppliers'
const suppliersService = createSuppliersApi("suppliers");

/**
 * Initiates the getAllSuppliers process for a customer.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting suppliers
 *    order: order for sorting suppliers by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllSuppliers.
 */
export const getAllSuppliers = createAsyncThunk(
  ActionTypes.GET_ALL_SUPPLIERS,

  async ({ payload }, thunkAPI) => {
    const response = await suppliersService.getAllSuppliers(payload);
    if (response?.data?.Succeeded) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

/**
 * Initiates the getSingleSupplier process for a supplier.
 * @param {object} supplierId - id of the supplier
 * @param {function} successCallBack - Callback function upon successful getSingleSupplier.
 */
export const getSingleSupplier = createAsyncThunk(
  `${BASE}/supplierId`,

  async ({ supplierId }, thunkAPI) => {
    const response = await suppliersService.getSingleSupplier(supplierId);
    return response?.data?.data;
  }
);

/**
 * Initiates the updateSupplier process for a supplier.
 * @param {object} supplierId - id of the supplier
 * @param {object} payload - supplier data to update
 * @param {function} successCallBack - Callback function upon successful updateSupplier.
 */
export const updateSupplier = createAsyncThunk(
  `${BASE}/supplierId`,

  async ({ supplierId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await suppliersService.updateSupplier(
        supplierId,
        payload
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Supplier updated successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addSupplier process for a supplier.
 * @param {object} payload - supplier data to add
 * {
  "name": "John Doe",
  "email": "supplier@gmail.com",
  "phoneNumber": "+9232478788",
  "gender": "MALE"
}
 * @param {function} successCallBack - Callback function upon successful addSupplier.
 */
export const addSupplier = createAsyncThunk(
  ActionTypes.ADD_SUPPLIER,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await suppliersService.addSupplier(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Supplier added successfully!");

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deleteSupplier process for a supplier.
 * @param {object} supplierId - id of the supplier
 * @param {function} successCallBack - Callback function upon successful deleteSupplier.
 */
export const deleteSupplier = createAsyncThunk(
  `${BASE}/supplierId`,

  async ({ supplierId, successCallBack }, thunkAPI) => {
    try {
      const response = await suppliersService.deleteSupplier(supplierId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Supplier deleted successfully!", "success", async () => {
          const undoResponse = await suppliersService.undoSupplier(supplierId);
          if (undoResponse?.data?.Succeeded) {
            const payload = {
              page: 1,
              pageSize: 10,
              sortColumn: "id",
              order: {
                id: "DESC",
              },
              condition: {},
              attributes: {},
            };
            store.dispatch(getAllSuppliers({ payload }));
          }
        });

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for suppliers related state management.
 * - `name`: Name of the slice (suppliers)
 * - `initialState`: Initial state defining properties for various supplier actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getAllSuppliers, updateSupplier etc).
 */
export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllSuppliers action.
     */
    reset: (state) => {
      state.getAllSuppliers = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleSupplier = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllSuppliers action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllSuppliers.pending, (state) => {
        state.getAllSuppliers.isLoading = true;
        state.getAllSuppliers.message = "";
        state.getAllSuppliers.isError = false;
        state.getAllSuppliers.isSuccess = false;
        state.getAllSuppliers.data = null;
      })
      /**
       * Updates the state when getAllSuppliers action is fulfilled/successful.
       * Updates loading and success flags and sets getAllSuppliers data with the payload.
       */
      .addCase(getAllSuppliers.fulfilled, (state, action) => {
        state.getAllSuppliers.isLoading = false;
        state.getAllSuppliers.isSuccess = true;
        state.getAllSuppliers.data = action.payload.data;
        state.getAllSuppliers.totalPages = action.payload.totalPages;
        state.getAllSuppliers.totalRecords = action.payload.TotalRecords;
      })
      /**
       * Updates the state when getAllSuppliers action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllSuppliers.rejected, (state, action) => {
        state.getAllSuppliers.message = action.payload?.message;
        state.getAllSuppliers.isLoading = false;
        state.getAllSuppliers.isError = true;
        state.getAllSuppliers.data = null;
      })
      /**
       * Updates the state while the getSingleSupplier action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSingleSupplier.pending, (state) => {
        state.getSingleSupplier.isLoading = true;
        state.getSingleSupplier.message = "";
        state.getSingleSupplier.isError = false;
        state.getSingleSupplier.isSuccess = false;
        state.getSingleSupplier.data = null;
      })
      /**
       * Updates the state when getSingleSupplier action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleSupplier data with the payload.
       */
      .addCase(getSingleSupplier.fulfilled, (state, action) => {
        state.getSingleSupplier.isLoading = false;
        state.getSingleSupplier.isSuccess = true;
        state.getSingleSupplier.data = action.payload;
      })
      /**
       * Updates the state when getSingleSupplier action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleSupplier.rejected, (state, action) => {
        state.getSingleSupplier.message = action.payload?.message;
        state.getSingleSupplier.isLoading = false;
        state.getSingleSupplier.isError = true;
        state.getSingleSupplier.data = null;
      })
      /**
       * Updates the state while the addSupplier action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addSupplier.pending, (state) => {
        state.addSupplier.isLoading = true;
        state.addSupplier.message = "";
        state.addSupplier.isError = false;
        state.addSupplier.isSuccess = false;
        state.addSupplier.data = null;
      })
      /**
       * Updates the state when addSupplier action is fulfilled/successful.
       * Updates loading and success flags and sets addSupplier data with the payload.
       */
      .addCase(addSupplier.fulfilled, (state, action) => {
        state.addSupplier.isLoading = false;
        state.addSupplier.isSuccess = true;
        state.addSupplier.data = action.payload;
      })
      /**
       * Updates the state when addSupplier action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addSupplier.rejected, (state, action) => {
        state.addSupplier.message = action.payload?.message;
        state.addSupplier.isLoading = false;
        state.addSupplier.isError = true;
        state.addSupplier.data = null;
      });
  },
});

/**
 * Destructures the reset action from the suppliersSlice actions.
 * - `reset`: Action function to reset the customers related state.
 */
export const { reset } = suppliersSlice.actions;

/**
 * Exports the default reducer generated by suppliersSlice.
 * This reducer handles state updates for customers related actions.
 */
export default suppliersSlice.reducer;
