import * as React from "react";

function BoundingBox(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="BoundingBox">
        <path
          id="Vector"
          d="M16.25 3.625H13.75C13.4048 3.625 13.125 3.90482 13.125 4.25V6.75C13.125 7.09518 13.4048 7.375 13.75 7.375H16.25C16.5952 7.375 16.875 7.09518 16.875 6.75V4.25C16.875 3.90482 16.5952 3.625 16.25 3.625Z"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M6.25 3.625H3.75C3.40482 3.625 3.125 3.90482 3.125 4.25V6.75C3.125 7.09518 3.40482 7.375 3.75 7.375H6.25C6.59518 7.375 6.875 7.09518 6.875 6.75V4.25C6.875 3.90482 6.59518 3.625 6.25 3.625Z"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M16.25 13.625H13.75C13.4048 13.625 13.125 13.9048 13.125 14.25V16.75C13.125 17.0952 13.4048 17.375 13.75 17.375H16.25C16.5952 17.375 16.875 17.0952 16.875 16.75V14.25C16.875 13.9048 16.5952 13.625 16.25 13.625Z"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M6.25 13.625H3.75C3.40482 13.625 3.125 13.9048 3.125 14.25V16.75C3.125 17.0952 3.40482 17.375 3.75 17.375H6.25C6.59518 17.375 6.875 17.0952 6.875 16.75V14.25C6.875 13.9048 6.59518 13.625 6.25 13.625Z"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M5 13.625V7.375"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M13.125 15.5H6.875"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_7"
          d="M15 7.375V13.625"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_8"
          d="M6.875 5.5H13.125"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default BoundingBox;
