import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../../features/Auth/AuthSlice";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

/**
 * Custom hook for handling password recovery functionality.
 * Uses react-hook-form for form validation and Redux for state management.
 * @returns {Object} An object containing states and methods for the password recovery form.
 */
export const usePasswordRecovery = () => {
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const forgetPasswordSchema = z.object({
    email: z
      .string()
      .min(1, "Email is required!")
      .email("Please use a valid email address!"),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Function} watch - Function to watch changes in form inputs.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(forgetPasswordSchema),
  });

  /**
   * Handler function for form submission.
   * Dispatches a password recovery action and updates the submission state.
   * @param {Object} data - Form data provided by react-hook-form.
   */
  const onSubmit = (data) => {
    const payload = {
      email: data.email,
    };
    dispatch(
      forgetPassword({ payload, successCallBack: onForgetPasswordSuccess })
    );
  };

  const onForgetPasswordSuccess = (data) => {
    setIsSubmitted(true);
  };

  // Returns form handling functions and login with OAuth method.
  /**
   * @returns {Object}
   * {
   *   isSubmitted: Intial state value for the form submission,
   *   setIsSubmitted: State updater function for the form submission,
   *   register: Function provided by react-hook-form to register input fields,
   *   handleSubmit: Function provided by react-hook-form to handle form submission,
   *   errors: object Function provided by react-hook-form to handle validation errors,
   *   onSubmit: Custom function to handle form submission logic
   * }
   * Usage Example:
   * Accesses states and methods for handling password recovery form functionality:
   * const {
   *   isSubmitted,
   *   setIsSubmitted,
   *   register,
   *   handleSubmit,
   *   errors,
   *   onSubmit
   * } = usePasswordRecovery();
   */
  return {
    isSubmitted,
    setIsSubmitted,
    register,
    handleSubmit,
    errors,
    onSubmit,
  };
};
