import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { setUser } from "../../../common/utils/users.utils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginPosUser } from "../../../../features/Users/UsersSlice";
import { useUserContext } from "../../../../context/UserContext";

export const usePosLoginEmail = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const { updateUser, setUserProfile, setToken } = useUserContext();

  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    setDropdownOpen(false);
  };

  const loginSchema = z.object({
    email: z.string().email("Invalid email address!"),
    password: z.string().min(1, "Password is required!"),
    // If you have other fields that need validation, you can add them here.
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(loginSchema), // Add the zodResolver here
  });

  const handleButtonClick = () => {
    // Navigate to the desired route
    navigate("/pos");
  };

  /**
   * Handler function for form submission.
   * This function can be async if needed for API calls or other asynchronous operations.
   * @param {Object} data - Form data provided by react-hook-form upon successful validation.
   */
  const onSubmit = async (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    };
    return dispatch(
      loginPosUser({ payload, successCallBack: moveSingleRouter })
    );
  };

  /**
   * Simple login
   * Navigates to the dashboard route upon successful login.
   *
   * @param {Object} data - Response data from the login process.
   */
  const moveSingleRouter = (data) => {
    if (data?.data?.Succeeded) {
      const userData = data.data.data;
      updateUser({ ...userData, isLoginVerified: true, posUserLoggedinFromEmail: true });
      setUserProfile(userData?.profile);
      setToken(userData?.token);
      setTimeout(() => {
        navigate("/pos/stores");
      }, 3000);
    }
  };

  return {
    dropdownOpen,
    selectedLanguage,
    toggleDropdown,
    handleSelectLanguage,
    dropdownRef,
    isSubmitting,
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    handleButtonClick,
  };
};
