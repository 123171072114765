import * as React from "react";

function CaretRight(props) {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M1.625 12.625V13.125H2.125H16.5C16.5332 13.125 16.5649 13.1382 16.5884 13.1616C16.6118 13.1851 16.625 13.2168 16.625 13.25C16.625 13.2832 16.6118 13.3149 16.5884 13.3384C16.5649 13.3618 16.5332 13.375 16.5 13.375H1.5C1.46685 13.375 1.43505 13.3618 1.41161 13.3384C1.38817 13.3149 1.375 13.2832 1.375 13.25V0.75C1.375 0.716848 1.38817 0.685054 1.41161 0.661612C1.43505 0.63817 1.46685 0.625 1.5 0.625C1.53315 0.625 1.56495 0.638169 1.58839 0.661612C1.61183 0.685054 1.625 0.716848 1.625 0.75V8.125V9.2275L2.45441 8.50115L6.41535 5.0324L6.41535 5.0324L6.4169 5.03103C6.43847 5.01199 6.46594 5.00097 6.49469 4.99983C6.52345 4.99869 6.55171 5.0075 6.57471 5.02478L6.575 5.025L11.1688 8.47031L11.4929 8.71346L11.798 8.44664L16.4151 4.40758L16.417 4.40599C16.4293 4.39508 16.4437 4.38671 16.4593 4.38136C16.4748 4.37601 16.4913 4.37378 16.5078 4.3748C16.5242 4.37582 16.5403 4.38007 16.5551 4.3873C16.5699 4.39453 16.5831 4.40461 16.594 4.41695C16.6049 4.4293 16.6133 4.44367 16.6186 4.45925C16.624 4.47483 16.6262 4.49131 16.6252 4.50776C16.6242 4.52419 16.6199 4.54027 16.6127 4.55508C16.6056 4.56967 16.5957 4.58273 16.5836 4.59354C16.5834 4.5937 16.5832 4.59386 16.583 4.59401L11.5848 8.96746L11.5831 8.96897C11.5615 8.98801 11.5341 8.99903 11.5053 9.00017C11.4766 9.00131 11.4483 8.99249 11.4253 8.97521L11.425 8.975L6.83125 5.52969L6.50718 5.28664L6.20219 5.55323L1.79594 9.4048L1.625 9.55421V9.78125V12.625Z"
        fill="#667085"
        stroke={props.strokeColor ? props.strokeColor : "#1A1A1A"}
        />
    </svg>
  );
}

export default CaretRight;
