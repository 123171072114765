import * as React from "react";

function LogoWhite(props) {
  return (
    <svg
      width="121"
      height="32"
      viewBox="0 0 121 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M61.3763 3.33594V7.26256H53.5567V24.8734H49.5627V7.26256H41.7432V3.33594H61.3763Z"
            fill="white"
          />
          <path
            id="Vector_2"
            d="M73.1059 7.2627H76.9315V24.8903H73.1059V21.9917H72.9374C71.8252 23.6601 69.8871 24.8735 67.275 24.8735C62.5732 24.9409 59.0679 21.3176 59.0679 16.0934C59.0679 10.9365 62.4889 7.24584 67.275 7.27954C69.8534 7.31325 71.842 8.45921 72.9374 10.0602H73.1059V7.2627ZM73.0723 16.0428C73.0723 13.1947 71.0668 10.9197 68.0502 10.9197C64.9999 10.9197 62.9608 13.161 62.9608 16.0428C62.9608 18.9246 64.9999 21.166 68.0502 21.166C71.0668 21.166 73.0723 18.8572 73.0723 16.0428Z"
            fill="white"
          />
          <path
            id="Vector_3"
            d="M93.666 7.26244H97.4915V26.036C97.4915 29.2886 95.6209 31.0918 92.3684 31.0918H79.6448V27.2663H91.6606C92.9077 27.2663 93.6323 26.5585 93.6323 25.3619L93.666 22.8509V21.9746H93.4975C92.3852 23.643 90.4472 24.8564 87.8351 24.8564C83.1332 24.9238 79.6279 21.3005 79.6279 16.0763C79.6279 10.9194 83.049 7.22873 87.8351 7.26244C90.4135 7.29614 92.4021 8.44211 93.4975 10.0431H93.666V7.26244ZM93.6323 16.0426C93.6323 13.1945 91.6269 10.9194 88.6103 10.9194C85.56 10.9194 83.5208 13.1608 83.5208 16.0426C83.5208 18.9243 85.56 21.1657 88.6103 21.1657C91.6269 21.1657 93.6323 18.8569 93.6323 16.0426Z"
            fill="white"
          />
          <g id="Group_3">
            <path
              id="Vector_4"
              d="M101.485 7.24609H104.249C106.204 7.24609 107.619 8.6617 107.619 10.5829V10.6166C107.619 12.5378 106.204 13.9534 104.249 13.9534H101.485V7.24609ZM102.681 8.40892V12.7737H104.215C105.479 12.7737 106.389 11.8805 106.389 10.5997V10.566C106.389 9.28524 105.479 8.40892 104.215 8.40892H102.681Z"
              fill="white"
            />
            <path
              id="Vector_5"
              d="M108.293 13.9365V7.24609H113.349V8.40892H109.49V9.94248H112.962V11.0716H109.49V12.7737H113.332V13.9365H108.293Z"
              fill="white"
            />
            <path
              id="Vector_6"
              d="M119.702 7.24609H121L118.169 13.9365H116.854L114.023 7.24609H115.337L117.545 12.4872L119.702 7.24609Z"
              fill="white"
            />
          </g>
        </g>
        <g id="Group_4">
          <path
            id="Vector_7"
            d="M19.2792 2.46023V3.40397C19.2792 3.47138 19.2286 3.53879 19.1612 3.55564L6.28596 6.7239C6.21855 6.74075 6.16799 6.80815 6.16799 6.87556V7.81931C6.16799 7.88672 6.11744 7.95412 6.05003 7.97097L0.117969 9.38658C0.0505594 9.40343 0 9.47085 0 9.53826V17.7285C0 17.8297 0.0842632 17.8971 0.185378 17.8802L6.06688 16.4646C6.13429 16.4478 6.18485 16.3803 6.18485 16.3129V15.3692C6.18485 15.3018 6.23541 15.2344 6.30281 15.2175L8.35881 14.7119C8.45993 14.6951 8.54419 14.7625 8.54419 14.8636L8.52734 27.1491C8.52734 27.2333 8.59474 27.3007 8.679 27.3007H16.8019C16.8861 27.3007 16.9536 27.2333 16.9536 27.1491L16.9704 12.7234C16.9704 12.656 17.021 12.5885 17.0884 12.5717L19.1949 12.0493C19.2623 12.0324 19.3129 11.965 19.3129 11.8976V10.9539C19.3129 10.8864 19.3634 10.819 19.4309 10.8022L25.4809 9.40343C25.5483 9.38658 25.5989 9.33603 25.5989 9.25176V1.06147C25.5989 0.960359 25.5146 0.892955 25.4135 0.909808L19.4309 2.29171C19.3297 2.32541 19.2792 2.39282 19.2792 2.46023ZM14.4257 24.9245H11.0215C10.9372 24.9245 10.8698 24.8571 10.8698 24.7729V14.5771C10.8698 14.5097 10.9204 14.4423 10.9878 14.4255L14.392 13.5828C14.4931 13.566 14.5774 13.6334 14.5774 13.7345V24.756C14.5774 24.8571 14.51 24.9245 14.4257 24.9245ZM23.0878 7.54966L19.3972 8.37543C19.3298 8.39229 19.2792 8.44285 19.2792 8.52711V9.47084C19.2792 9.53825 19.2286 9.60567 19.1612 9.62252L6.28596 12.7908C6.21855 12.8076 6.16799 12.875 6.16799 12.9424V13.8862C6.16799 13.9536 6.11744 14.021 6.05003 14.0378L2.52787 14.8805C2.42675 14.8973 2.34249 14.8299 2.34249 14.7288V11.392C2.34249 11.3246 2.39305 11.2572 2.46046 11.2403L6.05003 10.3809C6.11744 10.364 6.16799 10.2966 6.16799 10.2292V9.28547C6.16799 9.21806 6.21855 9.15065 6.28596 9.13379L19.1612 5.96554C19.2286 5.94869 19.2792 5.88127 19.2792 5.81386V4.87013C19.2792 4.80272 19.3298 4.73531 19.3972 4.71845L23.0373 3.90954C23.1384 3.89269 23.2227 3.9601 23.2227 4.06122V7.398C23.2058 7.48226 23.1721 7.53281 23.0878 7.54966Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}

export default LogoWhite;
