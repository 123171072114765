import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllPromotions } from "../../../../features/Promotions/PromotionsSlice";

const useChooseDiscount = (selectedItems) => {
  const dispatch = useDispatch();
  const { data: promotionList, isLoading: promotionListLoading } = useSelector(
    (state) => state.promotion.getAllPromotions
  );
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });
  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {
        status: "ACTIVE",
      },
      attributes: {},
    };

    if (!promotionList) {
      dispatch(getAllPromotions({ payload }));
    }
  }, []);

  const AllItemsPromotions = (promotionList || [])?.filter(
    (promotion) => promotion?.isAllItem
  );

  const ItemsPromotions = useMemo(
    () =>
      [
        {
          title: "Promotions on All Items",
          subTitle: "All Items here",
          data: AllItemsPromotions,
        },
      ]?.concat(
        selectedItems?.map((item) => ({
          data: (promotionList || [])?.filter((p) =>
            item.vItemId
              ? p.vItems.some((v) => v.id === item.vItemId)
              : p.items.some((v) => v.id === item.id)
          ),
          subTitle: item.vItemId
            ? Object.values(item?.selections || {}).join(", ")
            : "",

          title: item.englishName,
        }))
      ),
    [promotionList]
  );

  const onSubmit = async (data) => {};

  return {
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    isSubmitting,
    watch,
    promotionListLoading,
    ItemsPromotions,
  };
};

export default useChooseDiscount;
