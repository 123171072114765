/**
 * Manages state for Point of Sale (POS) login.
 * Defines initial state for each POS-related action.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import posLoginService from "./PosLoginService";
import { showToast } from "../../components/common/utils/showToast.util";

/**
 * Initial state structure defining various properties related to POS login processes.
 * Each property represents a specific POS login action/status with its associated data, error, success, loading, and message.
 * Properties such as posLogin, storecode, and fastcode store data, error, success, loading, and message status for corresponding POS login actions.
 */
const initialState = {
  posLogin: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  storecode: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  fastcode: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getPosProfile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  posUpdateProfile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Initiates the POS login process.
 * @param {object} payload - Data containing POS login credentials.
 * @param {function} successCallBack - Callback function upon successful POS login.
 */
export const posLogin = createAsyncThunk(
  "posLogin",
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posLoginService.posLogin(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast(response?.data?.message);
        return response;
      } else {
        showToast(response?.data?.message, "error");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the process to retrieve store code.
 * @param {object} payload - Data for retrieving store code.
 * @param {function} successCallBack - Callback function upon successful store code retrieval.
 */
export const storecode = createAsyncThunk(
  "storecode",
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posLoginService.storecode(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast(response?.data?.message);
        return response;
      } else {
        showToast(response?.data?.message, "error");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the process to retrieve fast code.
 * @param {object} payload - Data for retrieving fast code.
 * @param {function} successCallBack - Callback function upon successful fast code retrieval.
 */
export const fastcode = createAsyncThunk(
  "fastcode",
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posLoginService.fastcode(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast(response?.data?.message);
        return response;
      } else {
        showToast(response?.data?.message, "error");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const posUpdateProfile = createAsyncThunk(
  "posUpdateProfile",
  async ({ profileId, payload, successCallBack }, thunkAPI) => {
    const response = await posLoginService.posUpdateProfile({
      profileId,
      payload,
    });
    // Handle success or error
    return successCallBack(response?.data?.data);
  }
);

/**
 * Initiates the get business detail process for a user.
 * @param {id} businessId - business id
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const getPosProfile = createAsyncThunk(
  "getPosProfile",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ userId, successCallBack }, thunkAPI) => {
    const response = await posLoginService.getPosProfile({ userId });
    return successCallBack(response?.data?.data);
  }
);

/**
 * Creates a slice for POS login-related state management.
 * - `name`: Name of the slice (posLogin)
 * - `initialState`: Initial state defining properties for various POS login actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (posLogin, storecode, fastcode, etc.).
 */
export const posLoginSlice = createSlice({
  name: "posLogin",
  initialState,
  reducers: {
    /**
     * Resets the state for the posLogin action.
     */
    reset: (state) => {
      state.posLogin = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.storecode = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.fastcode = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getPosProfile = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.posUpdateProfile = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the posLogin action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(posLogin.pending, (state) => {
        state.posLogin.isLoading = true;
        state.posLogin.message = "";
        state.posLogin.isError = false;
        state.posLogin.isSuccess = false;
        state.posLogin.data = null;
      })
      /**
       * Updates the state when posLogin action is fulfilled/successful.
       * Updates loading and success flags and sets posLogin data with the payload.
       */
      .addCase(posLogin.fulfilled, (state, action) => {
        state.posLogin.isLoading = false;
        state.posLogin.isSuccess = true;
        state.posLogin.data = action.payload;
      })
      /**
       * Updates the state when posLogin action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(posLogin.rejected, (state, action) => {
        state.posLogin.message = action.payload?.message;
        state.posLogin.isLoading = false;
        state.posLogin.isError = true;
        state.posLogin.data = null;
      })

      /**
       * Updates the state while the storecode action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(storecode.pending, (state) => {
        state.storecode.isLoading = true;
        state.storecode.message = "";
        state.storecode.isError = false;
        state.storecode.isSuccess = false;
        state.storecode.data = null;
      })
      /**
       * Updates the state when storecode action is fulfilled/successful.
       * Updates loading and success flags and sets storecode data with the payload.
       */
      .addCase(storecode.fulfilled, (state, action) => {
        state.storecode.isLoading = false;
        state.storecode.isSuccess = true;
        state.storecode.data = action.payload;
      })
      /**
       * Updates the state when storecode action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(storecode.rejected, (state, action) => {
        state.storecode.message = action.payload?.message;
        state.storecode.isLoading = false;
        state.storecode.isError = true;
        state.storecode.data = null;
      })
      /**
       * Updates the state while the fastcode action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(fastcode.pending, (state) => {
        state.fastcode.isLoading = true;
        state.fastcode.message = "";
        state.fastcode.isError = false;
        state.fastcode.isSuccess = false;
        state.fastcode.data = null;
      })
      /**
       * Updates the state when fastcode action is fulfilled/successful.
       * Updates loading and success flags and sets fastcode data with the payload.
       */
      .addCase(fastcode.fulfilled, (state, action) => {
        state.fastcode.isLoading = false;
        state.fastcode.isSuccess = true;
        state.fastcode.data = action.payload;
      })
      /**
       * Updates the state when fastcode action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(fastcode.rejected, (state, action) => {
        state.fastcode.message = action.payload?.message;
        state.fastcode.isLoading = false;
        state.fastcode.isError = true;
        state.fastcode.data = null;
      })
      .addCase(posUpdateProfile.pending, (state) => {
        state.posUpdateProfile.isLoading = true;
        state.posUpdateProfile.message = "";
        state.posUpdateProfile.isError = false;
        state.posUpdateProfile.isSuccess = false;
        state.posUpdateProfile.data = null;
      })
      /**
       * Updates the state when posUpdateProfile action is fulfilled/successful.
       * Updates loading and success flags and sets posUpdateProfile data with the payload.
       */
      .addCase(posUpdateProfile.fulfilled, (state, action) => {
        state.posUpdateProfile.isLoading = false;
        state.posUpdateProfile.isSuccess = true;
        state.posUpdateProfile.data = action.payload;
      })
      /**
       * Updates the state when posUpdateProfile action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(posUpdateProfile.rejected, (state, action) => {
        state.posUpdateProfile.message = action.payload?.message;
        state.posUpdateProfile.isLoading = false;
        state.posUpdateProfile.isError = true;
        state.posUpdateProfile.data = null;
      })
      .addCase(getPosProfile.pending, (state) => {
        state.getPosProfile.isLoading = true;
        state.getPosProfile.message = "";
        state.getPosProfile.isError = false;
        state.getPosProfile.isSuccess = false;
        state.getPosProfile.data = null;
      })
      /**
       * Updates the state when getPosProfile action is fulfilled/successful.
       * Updates loading and success flags and sets getPosProfile data with the payload.
       */
      .addCase(getPosProfile.fulfilled, (state, action) => {
        state.getPosProfile.isLoading = false;
        state.getPosProfile.isSuccess = true;
        state.getPosProfile.data = action.payload;
      })
      /**
       * Updates the state when getPosProfile action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getPosProfile.rejected, (state, action) => {
        state.getPosProfile.message = action?.payload?.message;
        state.getPosProfile.isLoading = false;
        state.getPosProfile.isError = true;
        state.getPosProfile.data = null;
      });
  },
});

/**
 * Destructures the reset action from the posLoginSlice actions.
 * - `reset`: Action function to reset the POS login-related state.
 */

// sample usage

// import { reset } from './yourPosLoginSlicePath';

// Using the reset action
// const resetPosLogin = () => {
//   // Dispatching the reset action to reset POS login state
//   store.dispatch(reset());
// };

export const { reset } = posLoginSlice.actions;

/**
 * Exports the default reducer generated by posLoginSlice.
 * This reducer handles state updates for POS login-related actions.
 */

// sample usage

// import posLoginReducer from "./yourPosLoginSlicePath";

// Using the default reducer
// const initialState = {
// Your initial state for POS login
// };

// Creating a store with the posLoginReducer handling POS login-related actions
// const store = configureStore({
// reducer: {
// posLogin: posLoginReducer,
// other reducers...
// },
// preloadedState: {
// posLogin: initialState,
// other preloaded states...
// },
// });
export default posLoginSlice.reducer;
