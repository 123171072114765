import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleClientProfile } from "../../../../../features/Clients/clientSlice";
import { viewClientDetailsCards,viewClientDetailsSales } from "../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { useParams } from "react-router-dom";

export const useClientSales = () => {
  const [clientDetails, setClientDetails] = useState({});
  const [clientDetailsCard, setClientDetailsCard] = useState({});
  const { id } = useParams();

  const { data: viewClientDetail } = useSelector(
    (state) => state?.dashboard?.viewClientDetailsCards
  );
  const dispatch = useDispatch();
  const clientId = id;

  useEffect(() => {
    dispatch(
      getSingleClientProfile({
        profileId: clientId,
        successCallBack: onGetSingleClientProfileSuccess,
      })
    );
  }, [clientId]);
  
  useEffect(() => {
    dispatch(
      viewClientDetailsCards({
        userId: clientId,
        successCallBack: onSuccess,
      })
    );
  }, [clientId]);

  const onSuccess = (response) => {
    setClientDetailsCard(response);
  };
  const onGetSingleClientProfileSuccess = (response) => {
    setClientDetails(response);
  };

  return { clientDetails, viewClientDetail, clientDetailsCard };
};
