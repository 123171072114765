import * as React from "react";

function QrCodeIcon(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="QrCode">
        <path
          id="Vector"
          d="M10.25 4.5H5.75C5.33579 4.5 5 4.83579 5 5.25V9.75C5 10.1642 5.33579 10.5 5.75 10.5H10.25C10.6642 10.5 11 10.1642 11 9.75V5.25C11 4.83579 10.6642 4.5 10.25 4.5Z"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M10.25 13.5H5.75C5.33579 13.5 5 13.8358 5 14.25V18.75C5 19.1642 5.33579 19.5 5.75 19.5H10.25C10.6642 19.5 11 19.1642 11 18.75V14.25C11 13.8358 10.6642 13.5 10.25 13.5Z"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M19.25 4.5H14.75C14.3358 4.5 14 4.83579 14 5.25V9.75C14 10.1642 14.3358 10.5 14.75 10.5H19.25C19.6642 10.5 20 10.1642 20 9.75V5.25C20 4.83579 19.6642 4.5 19.25 4.5Z"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M14 13.5V16.5"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M14 19.5H17V13.5"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M17 15H20"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_7"
          d="M20 18V19.5"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default QrCodeIcon;
