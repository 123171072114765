import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Gleap from "gleap";
import { login } from "../../../features/Auth/AuthSlice";
import { useUserContext } from "../../../context/UserContext";
import { showToast } from "../../common/utils/showToast.util";
/**
 * Custom hook for handling login form functionality using react-hook-form.
 * Provides methods for registering form inputs, handling form submission, and accessing form errors.
 * @returns {Object} An object containing:
 *   - register: Function to register input fields into the react-hook-form.
 *   - handleSubmit: Function that wraps the form submit handler.
 *   - errors: State object containing form validation errors.
 */
const useAdminLogin = (userType) => {
  const { updateUser, setUserProfile, setToken } = useUserContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // valiation schema for login form validation

  const loginFormSchema = z.object({
    email: z
      .string()
      .min(1, "login.validations.email_error")
      .email("login.validations.email_error_invalid"),
    password: z
      .string()
      .min(8, "login.validations.password_error_min")
      .regex(/^(?=.*[A-Z])/, "login.validations.password_error_uppercase")
      .regex(
        /[!@#$%^&*(),.?":{}|<>]/,
        "login.validations.password_error_special_char"
      )
      .regex(/[a-zA-Z]/, "login.validations.password_error_alphabet"),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Function} watch - Function to watch changes in form inputs.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   * @property {Function} zodResolver - Function that utilizes the 'zod' library for schema-based form validation.
   * @property {Propery} isValid - is a property provided by react-hook-form that indicates whether the form is currently valid or not.
   * 
    The 'zodResolver' function, imported as part of the 'useForm' hook, utilizes the 'zod' library
    for schema-based form validation. This function is crucial in defining and enforcing the structure
    and constraints of the form data according to the 'loginFormSchema' provided. It acts as the bridge
    between the form and the Zod schema, ensuring that the form inputs adhere to the specified validation rules.
    Zod is a powerful TypeScript-first schema declaration and validation library, enhancing the robustness
    and reliability of form validation in React applications.
    */
  const {
    register,
    handleSubmit,
    formState: { isValid, errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(loginFormSchema),
  });

  /**
   * Handler function for form submission.
   * This function can be async if needed for API calls or other asynchronous operations.
   * @param {Object} data - Form data provided by react-hook-form upon successful validation.
   */
  const onSubmit = async (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    };
    return dispatch(login({ payload, successCallBack: onLoginSuccess }));
  };

  /**
   * Simple login
   * Navigates to the dashboard route upon successful login.
   *
   * @param {Object} data - Response data from the login process.
   */
  const onLoginSuccess = (data) => {
    const userData = data?.data?.data;
    if (userData) {
      updateUser(userData);
      setUserProfile(userData?.profile);
      setToken(userData?.token);
    }

    // First, check if user is an admin and allow immediate access
    if (
      userData &&
      ["SUPER_ADMIN", "ACCOUNTING", "ACCOUNT_MANAGER"].includes(userData.role)
    ) {
      // Assuming Gleap and navigation is needed for all users including admins
      Gleap.identify(userData.id, {
        name: userData?.profile?.fullName,
        email: userData?.email,
        phone: userData?.profile?.phoneNumber,
        companyId: userData?.businessDetail?.commercialRegister,
        companyName: userData?.businessDetail?.companyName,
        createdAt: userData?.createdAt,
        customData: {
          arabicName: userData?.profile?.arabicName,
          role: userData.role, // role is assumed to be always available if userData exists
          companyAddress: userData?.businessDetail?.companyAddress,
          businessType: userData?.businessDetail?.businessType,
          website: userData?.businessDetail?.website,
          vatNumber: userData?.businessDetail?.vatNumber,
        },
      });
      showToast("You are successfully logged in!");
      setTimeout(() => {
        navigate("/admin/dashboard");
      }, 1000);
    }
  };

  /**
   * Returns form handling functions and login with OAuth method.
   *
   * @returns {Object}
   * {
   *   register: Function provided by react-hook-form to register input fields,
   *   handleSubmit: Function provided by react-hook-form to handle form submission,
   *   errors: Object Function provided by react-hook-form to handle validation errors,
   *   loginWithOAuth: Function to handle login with OAuth method
   *   isValid is a property provided by react-hook-form that indicates whether the form is currently valid or not.
   * }
   * Usage Example:
   * Accesses the form handling functions, error tracking, and OAuth login method:
   * const { register, handleSubmit, errors, loginWithOAuth } = useCustomHook();
   *
   * regiser:
   *  Example usage: <input {...register("email", { required: true })}
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * errors:
   *  Example usage: {errors.email && <span>Email is required</span>}
   * loginWithOAuth:
   *  Example usage: loginWithOAuth("google", email, accessToken);
   * isValid:
   *  Example usage: <CustomButton disabled={!isValid} />
   */
  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    isValid,
    isSubmitting,
  };
};

export default useAdminLogin;
