import { useState, useEffect, useCallback } from "react";
import { getUser } from "../../../common/utils/users.utils";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getSinglePosUser,
  updatePosUser,
} from "../../../../features/PosUsers/PosUsersSlice";
import { showToast } from "../../../common/utils/showToast.util";

function usePosUpdateProfile({ setModalOpen }) {
  const dispatch = useDispatch();
  const [splitInvoice, setSplitInvoice] = useState(false);
  const { data: singleUser, isLoading: singleUserLoading } = useSelector(
    (state) => state.posUser.getSinglePosUser
  );
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const storeId = storedUserData?.user?.store?.id;
  const userId = storedUserData?.user?.id;

  const loginSchema = z.object({
    name: z
      .string()
      .min(3, { message: "Name must be at least 3 characters long." })
      .max(100, { message: "Name must be no longer than 100 characters." }),
  });
  // This should come from fetched data
  const userInitialValues = { name: "" };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: userInitialValues,
    resolver: zodResolver(loginSchema),
  });

  useEffect(() => {
    if (singleUser) {
      setValue("name", singleUser.fullName);
      // Ensure to reset the form state after setting values to align initial state with fetched data
      reset({ name: singleUser.fullName });
    }
  }, [singleUser, setValue, reset]);

  // Watching the name field
  const watchedName = watch("name");

  useEffect(() => {
    if (userId) {
      dispatch(getSinglePosUser({ userId }));
    }
  }, [dispatch]);

  const onSubmit = useCallback(
    async (data) => {
      if (userId) {
        dispatch(
          updatePosUser({
            userId: userId,
            payload: {
              fullName: data.name,
              storeIds: [storeId],
            },
            successCallBack: () => {
              setModalOpen(false);
              dispatch(getSinglePosUser({ userId }));

            }
          })
        );
      }
    },
    [dispatch, setModalOpen, userId, storeId]
  );

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    singleUserLoading,
    setSplitInvoice,
    splitInvoice,
    isDirty,
    watchedName,
  };
}

export default usePosUpdateProfile;
