import React from "react";
import Navbar from "../../../components/common/components/Navbar/Navbar";
import Footer from "../../../components/common/components/Footer/Footer";
import { TickIcon } from "../../../components/common/Icons";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/common/components/Button/Button";

/**
 * PasswordSuccess component.
 * Renders a success message for password change and provides a button to navigate back to the login page.
 *
 * @returns {JSX.Element} JSX code for rendering the PasswordSuccess component.
 */

const PasswordSuccess = () => {
  const navigate = useNavigate();
  const redirectToLogin = () => {
    navigate("/");
  };

  return (
    <div className="w-full flex flex-col justify-between">
      <div>
        <Navbar />
        <div className="max-w-[480px] mx-auto py-12">
          {/* tick icon */}
          <TickIcon className="mt-20 mx-auto" />
          {/* heading */}
          <h2 className="text-3xl font-semibold mb-3 text-center text-text-primary mt-3">
            Password Successfully Change
          </h2>
          <p className="text-text-secondary text-base font-normal text-center">
            Your password has been successfully updated. If you have any
            concerns or didn't initiate this change, please contact our support
            team immediately.
          </p>
          {/* form */}
          <div className="my-10">
            <CustomButton
              text="Back to Log In"
              onClick={redirectToLogin}
              className="mt-3"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordSuccess;

// Sample Usage:
// import PasswordSuccess  from '.path/PasswordSuccess';
// <PasswordSuccess />
