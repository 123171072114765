import * as React from "react";

function UploadIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M18 11.2501V16.5001C18 16.8979 17.842 17.2795 17.5607 17.5608C17.2794 17.8421 16.8978 18.0001 16.5 18.0001H1.5C1.10218 18.0001 0.720644 17.8421 0.43934 17.5608C0.158035 17.2795 0 16.8979 0 16.5001V11.2501C0 11.0512 0.0790178 10.8604 0.21967 10.7198C0.360322 10.5791 0.551088 10.5001 0.75 10.5001C0.948912 10.5001 1.13968 10.5791 1.28033 10.7198C1.42098 10.8604 1.5 11.0512 1.5 11.2501V16.5001H16.5V11.2501C16.5 11.0512 16.579 10.8604 16.7197 10.7198C16.8603 10.5791 17.0511 10.5001 17.25 10.5001C17.4489 10.5001 17.6397 10.5791 17.7803 10.7198C17.921 10.8604 18 11.0512 18 11.2501ZM5.78063 5.03073L8.25 2.56041V11.2501C8.25 11.449 8.32902 11.6398 8.46967 11.7804C8.61032 11.9211 8.80109 12.0001 9 12.0001C9.19891 12.0001 9.38968 11.9211 9.53033 11.7804C9.67098 11.6398 9.75 11.449 9.75 11.2501V2.56041L12.2194 5.03073C12.3601 5.17146 12.551 5.25052 12.75 5.25052C12.949 5.25052 13.1399 5.17146 13.2806 5.03073C13.4214 4.89 13.5004 4.69912 13.5004 4.5001C13.5004 4.30108 13.4214 4.11021 13.2806 3.96948L9.53063 0.219477C9.46097 0.149744 9.37825 0.0944251 9.2872 0.0566819C9.19616 0.0189387 9.09856 -0.000488281 9 -0.000488281C8.90144 -0.000488281 8.80384 0.0189387 8.7128 0.0566819C8.62175 0.0944251 8.53903 0.149744 8.46937 0.219477L4.71937 3.96948C4.57864 4.11021 4.49958 4.30108 4.49958 4.5001C4.49958 4.69912 4.57864 4.89 4.71937 5.03073C4.86011 5.17146 5.05098 5.25052 5.25 5.25052C5.44902 5.25052 5.63989 5.17146 5.78063 5.03073Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}

export default UploadIcon;
