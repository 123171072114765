import api from "../../services/api";

/**
 * Creates clinet settings related API endpoints.
 * @returns {Object} Object containing client settings-related API methods.
 */

/**
 * get user profile information using profile id
 * @param {id} profileId - profile id for profile information.
 * @returns {Promise} A Promise that resolves to the profile response.
 */
const getUserProfile = async (profileId) => {
  const response = await api().get(`profile/${profileId}`);
  return response;
};

/**
 * update profile information using profileId
 * @param {id} profileId - profileId for profile information.
 * @returns {Promise} A Promise that resolves to the signup response.
 */
const updateUserProfile = async (profileId, payload) => {
  const response = await api().put(`profile/${profileId}`, payload);
  return response;
};
/**
 * get business information using business id
 * @param {id} businessId - business id for business information.
 * @returns {Promise} A Promise that resolves to the business info response.
 */
const getBusinessDetail = async (businessId) => {
  const response = await api().get(`business-details/${businessId}`);
  return response;
};

/**
 * update business information using business id
 * @param {id} businessId - business id for business information.
 * @returns {Promise} A Promise that resolves to the business info response.
 */
const updateBusinessDetail = async (businessId, payload) => {
  const response = await api().put(`business-details/${businessId}`, payload);
  return response;
};

/**
 * Object that encapsulates functions for client settings operations.
 */
const clientSettingsService = {
  getUserProfile, // Function for getting user profile
  updateUserProfile, // Function for updating user profile
  getBusinessDetail, // Function for getting business detail
  updateBusinessDetail, // Function for updating business detail
};

export default clientSettingsService; // export clientSettingsService

// sample usage

// import clientSettingsService from "../../path/clientSettingsService";

// Sample user profile ID and business ID
// const userProfileId = "123456";
// const businessId = "789012";

//  Fetch user profile information
// clientSettingsService.getUserProfile(userProfileId)
//   .then((profileResponse) => {
//     console.log("User Profile Information:", profileResponse.data);
//   })
//   .catch((error) => {
//     console.error("Error fetching user profile:", error);
//   });
