import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addPosOrder,
  handleCheckoutData,
  updatePosOrder,
} from "../../../../features/PosOrder/PosOrderSlice";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import { showToast } from "../../../common/utils/showToast.util";

const useOrderDetail = (
  selectedItems,
  orderDuplicateData,
  setSelectedItems
) => {
  const { state } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [saveOrderModalVisible, setSaveOrderModalVisible] = useState(false);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [itemTotals, setItemTotals] = useState({});
  const [checkOutVisible, setCheckOutVisible] = useState(false);
  const [isCheckoutModelOpen, setIsCheckoutModelOpen] = useState(false);
  const [isChooseDiscountModalOpen, setIsChooseDiscountModalOpen] =
    useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [orderCheckoutPaid, setOrderCheckoutPaid] = useState(null);
  const [selectedPromotions, setSelectedPromotions] = useState([]);
  const closeCheckoutModal = () => setIsCheckoutModelOpen(false);
  const dispatch = useDispatch();
  const TableModal = () => setIsTableOpen(!isTableOpen);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [checkoutData, setCheckoutData] = useState(null);
  // Initialize the total discount
  let totalDiscount = 0;

  selectedItems.forEach((item) => {
    // Find the matching promotion for the item or a global promotion
    const matchingPromotion = selectedPromotions.find(
      (promo) =>
        promo.accordion === item.englishName ||
        (promo.accordion === "Promotions on All Items" && promo.selectedValue)
    );

    // Calculate the discount for the current item
    if (matchingPromotion && matchingPromotion.selectedValue) {
      let discountAmount = 0;

      if (matchingPromotion.selectedValue.type === "FIXED_AMOUNT") {
        discountAmount = matchingPromotion.selectedValue.price;
      } else if (matchingPromotion.selectedValue.type === "PERCENTAGE") {
        discountAmount =
          (item.salePrice * matchingPromotion.selectedValue.price) / 100;
      }
      // Add the discount for this item (considering quantity)
      totalDiscount += discountAmount * item.quantity;
    }
  });

  const [selectedCustomer, setSelectedCustomer] = useState(
    orderDuplicateData?.orderData?.customer ?? null
  );
  const [selectedTables, setSelectedTables] = useState(
    orderDuplicateData?.table ?? null
  );
  const currentDate = format(new Date(), "MM/dd/yyyy");
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const branchName = storedUserData?.user?.store?.branchName;
  const fullName = storedUserData?.user?.fullName;
  const storeId = storedUserData?.user?.store?.id;
  const subplan = storedUserData?.user?.subscription?.planType;

  const OrderTabs = [
    { label: "Dine In", value: "DINE_IN" },
    { label: "Takeaway", value: "TAKEAWAY" },
  ];
  const [activeTab, setActiveTab] = useState(
    OrderTabs.find(
      (option) => option.value === orderDuplicateData?.orderType
    ) ?? OrderTabs[0]
  );

  const handleTabClick = (tab) => {
    if (
      tab.value === "DINE_IN" &&
      !["Enterprise (ANNUAL)", "Enterprise (MONTHLY)"].includes(subplan)
    ) {
      showToast("Only accessible in Enterprise Plan", "error");
    } else {
      setActiveTab(tab);
    }
  };
  const shouldShowButton = (buttonType) => {
    return activeTab !== "TAKEAWAY";
  };

  const handleClose = () => {
    setIsCheckoutModalOpen(false);
    window.location.reload();
  };
  const updateItemTotal = (itemId, total) => {
    setItemTotals((prev) => ({ ...prev, [itemId]: total }));
  };

  const subtotal = Object.values(itemTotals).reduce(
    (acc, total) => acc + total,
    0
  );
  const VAT_RATE = 0.15;
  const vat = subtotal * VAT_RATE;
  const grandTotal = subtotal + vat - totalDiscount;

  // Function to handle selection of promotion and closing the modal
  const handleSavePromotion = (newPromotion) => {
    setSelectedPromotions(newPromotion);
    setIsChooseDiscountModalOpen(false);
  };

  const handleDeletePromotion = () => {
    setSelectedPromotions([]);
  };

  const handleChangeDiscount = (promotionId) => {
    setIsChooseDiscountModalOpen(true);
  };

  const openChooseDiscountModal = () => {
    setIsChooseDiscountModalOpen(true);
  };
  const closeChooseDiscountModal = () => {
    setIsChooseDiscountModalOpen(false);
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(false);
  };

  // Function to remove the selected customer
  const handleRemoveCustomer = () => {
    setSelectedCustomer(null);
  };

  // Function to remove the selected tables
  const handleRemoveTables = () => {
    setSelectedTables(null);
  };

  const toggleSaveOrderModal = () => {
    let missingElements = [];

    if (selectedItems.length === 0) {
      missingElements.push("Item");
    }
    // if (selectedCustomer === null) {
    //   missingElements.push("Customer");
    // }
    if (activeTab.value === "DINE_IN" && selectedTables === null) {
      missingElements.push("Table");
    }

    // Construct and show error message if any elements are missing
    if (missingElements.length > 0) {
      const formattedMessage = `Please add ${missingElements.join(", ")}`;
      return showToast(formattedMessage, "error");
    }
    // Only toggle the modal visibility if all conditions are met
    setSaveOrderModalVisible(!saveOrderModalVisible);
  };

  const resetFields = () => {
    setSelectedItems([]);
    setSelectedPromotions([]);
    setSelectedCustomer(null);
    setSelectedTables(null);
    setItemTotals({});
  };

  const toggleCheckOutVisible = () => {
    let missingElements = [];

    if (selectedItems.length === 0) {
      missingElements.push("Item");
    }
    if (selectedCustomer === null) {
      missingElements.push("Customer");
    }
    if (activeTab.value === "DINE_IN" && selectedTables === null) {
      missingElements.push("Table");
    }

    if (missingElements.length > 0) {
      const formattedMessage = `Please add ${missingElements.join(", ")}`;
      return showToast(formattedMessage, "error");
    }

    if (!checkOutVisible) {
      const payload = {
        tableId:
          activeTab.value !== "TAKEAWAY" ? selectedTables?.id : undefined,
        customerId: selectedCustomer?.id,
        storeId,
        orderType: activeTab?.value,
        orderedItems: selectedItems.map((s) => ({
          quantity: +s?.quantity || 1,
          note: s?.note || "",
          itemId: s?.id,
          vItemId: s?.vItemId || undefined,
          promotionId: selectedPromotions?.id || undefined,
        })),
      };
      if (state?.type === "edit") {
        dispatch(
          updatePosOrder({
            orderId: state?.id,
            payload,
            successCallBack: handleSuccess,
          })
        );
      } else if (state?.type === "duplicate") {
        dispatch(addPosOrder({ payload, successCallBack: handleSuccess }));
      } else {
        dispatch(addPosOrder({ payload, successCallBack: handleSuccess }));
      }
    } else {
      setCheckOutVisible(!checkOutVisible);
    }
  };

  const handleSuccess = (response) => {
    setCheckoutData(response?.data?.data);
    dispatch(handleCheckoutData(response?.data?.data));
    setCheckOutVisible(true);
    resetFields();
  };

  return {
    closeChooseDiscountModal,
    openChooseDiscountModal,
    handleChangeDiscount,
    handleDeletePromotion,
    handleSavePromotion,
    shouldShowButton,
    toggleCheckOutVisible,
    toggleSaveOrderModal,
    toggleModal,
    closeCheckoutModal,
    selectedPromotions,
    isCheckoutModelOpen,
    isChooseDiscountModalOpen,
    setActiveTab,
    useOrderDetail,
    saveOrderModalVisible,
    activeTab,
    setIsCheckoutModelOpen,
    checkOutVisible,
    setIsChooseDiscountModalOpen,
    isModalOpen,
    OrderTabs,
    TableModal,
    isTableOpen,
    setIsTableOpen,
    setIsModalOpen,
    orderCheckoutPaid,
    setOrderCheckoutPaid,
    handleRemoveTables,
    handleRemoveCustomer,
    handleSelectCustomer,
    selectedTables,
    selectedCustomer,
    setSelectedTables,
    setSelectedCustomer,
    vat,
    setCheckOutVisible,
    grandTotal,
    subtotal,
    updateItemTotal,
    storeId,
    branchName,
    fullName,
    currentDate,
    checkoutData,
    isCheckoutModalOpen,
    setIsCheckoutModalOpen,
    resetFields,
    handleClose,
    handleTabClick,
    totalDiscount,
    isButtonDisabled,
    setIsButtonDisabled
  };
};

export default useOrderDetail;
