import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "../../../../../features/Customers/CustomersSlice";
import { checkOutOrder } from "../../../../../features/PosOrder/PosOrderSlice";

export const useFullPay = (setCheckOutVisible, setOrderCheckoutPaid, setIsCheckoutModalOpen) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cash");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef();


  const { data: customerList, isLoading: customerListLoading } = useSelector(
    (state) => state.customers.getAllCustomers
  );
  const { data: paymentMethodsList, isLoading: paymentMethodsListLoading } =
    useSelector((state) => state.payments.getPaymentMethods);

  const orderCheckoutData = useSelector(
    (state) => state.posOrder.orderCheckoutData
  );

  const orderType = orderCheckoutData?.orderType;
  const discountAmount = Number(orderCheckoutData?.discountAmount) || 0;
  const totalAmount = Number(orderCheckoutData?.subTotal)?.toFixed() || 0;
  let taxAmount = Number(orderCheckoutData?.taxAmount)?.toFixed() || 0;
  const total = Number(orderCheckoutData?.totalAmount)?.toFixed() || 0;
  const orderId = orderCheckoutData?.id;
  const orderItemIds = orderCheckoutData?.orderedItems?.map((item) => item.id);
  const selectedCustomerId = selectedCustomer?.id;

  const handleSelectionChange = useCallback((company) => {
    setSelectedCompany(company);
  }, []);

  const handleSelectionCard = useCallback((value) => {
    setSelectedCard(value);
  }, []);

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCustomers({ payload }));
  }, []);

  const handleCheckout = () => {
    setIsButtonDisabled(true);
    const payload = {
      paymentMethod: selectedPaymentMethod ?? "",
      orderId: orderId,
      checkoutType: "FULL_PAY",
      cardType: selectedCard ?? "",
      customerPointsid: selectedCustomerId,
      orderItemIds: orderItemIds,
    };
    dispatch(
      checkOutOrder({ payload, successCallBack: successCheckoutCallback })
    );
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 2000);
  };

  const successCheckoutCallback = (response) => {
    setOrderCheckoutPaid(response?.data?.data);
    setCheckOutVisible(false)
    setIsCheckoutModalOpen(true);
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    customerList,
    customerListLoading,
    setIsOpen,
    isOpen,
    selectedCustomer,
    setSelectedCustomer,
    handleSelectCustomer,
    dropdownRef,
    paymentMethodsList,
    paymentMethodsListLoading,
    orderCheckoutData,
    total,
    orderType,
    totalAmount,
    taxAmount,
    discountAmount,
    setSelectedCompany,
    selectedCompany,
    handleSelectionChange,
    handleCheckout,
    selectedCard,
    handleSelectionCard,
    setSelectedCard,
    setIsButtonDisabled,
    isButtonDisabled
  };
};
