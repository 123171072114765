/**
 * Card component.
 * Renders a card UI with customizable padding, border, and shadow.
 *
 * @param {Object} props - Props for the Card component.
 * @param {string} props.children - Content to be rendered inside the card.
 * @param {string} [props.className=""] - Additional CSS classes for customization.
 * @param {string} [props.padding="px-5 py-5"] - Padding for the card content.
 * @returns {JSX.Element} JSX code for rendering the Card component.
 */
const Card = ({
  children,
  className = "",
  padding = "px-5 py-5",
  shadow = "sm",
  onClick,
  id = "",
}) => {
  return (
    <div
      id={id}
      className={`bg-white border border-border-primary shadow-${shadow} rounded-xl ${padding} ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;

// Sample Usage:
// import Card from "path/to/Card";
// <Card padding="px-8 py-6" className="custom-card">
// </Card>
