import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/common/components/Navbar/Navbar";
import Footer from "../../../components/common/components/Footer/Footer";
import { TickIcon } from "../../../components/common/Icons";
import CustomButton from "../../../components/common/components/Button/Button";

/**
 * RecoverEmail component.
 * Renders a confirmation message for email recovery and provides a button to navigate back to the login page.
 *
 * @returns {JSX.Element} JSX code for rendering the RecoverEmail component.
 */
const RecoverEmail = () => {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate("/");
  };

  return (
    <div className="w-full flex flex-col justify-between">
      <div>
        <Navbar />
        <div className="max-w-[480px] mx-auto py-12">
          {/* tick icon */}
          <TickIcon className="mt-20 mx-auto" />
          {/* heading */}
          <h2 className="text-3xl font-semibold mb-3 text-center text-text-primary mt-3">
            We’ve Sent a Recovery Email
          </h2>
          <p className="text-text-secondary text-base font-normal text-center">
            Check your email for a link to reset your password. If it doesn't
            appear within a few minutes, check your spam folder.
          </p>
          {/* form */}
          <div className="my-10">
            <CustomButton
              onClick={redirectToLogin}
              text="Back to Log In"
              className="mt-3"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RecoverEmail;

// Sample Usage:
// import RecoverEmail  from '.path/RecoverEmail';
// <RecoverEmail />
