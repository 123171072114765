import * as React from "react";

function UserList({ stroke = "#667085" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 11.25C7.97589 11.25 9.375 9.85089 9.375 8.125C9.375 6.39911 7.97589 5 6.25 5C4.52411 5 3.125 6.39911 3.125 8.125C3.125 9.85089 4.52411 11.25 6.25 11.25Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 6.25H19.375"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 10H19.375"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 13.75H19.375"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.40625 15C1.68163 13.9245 2.30713 12.9712 3.18414 12.2905C4.06115 11.6097 5.13979 11.2402 6.25 11.2402C7.36021 11.2402 8.43885 11.6097 9.31586 12.2905C10.1929 12.9712 10.8184 13.9245 11.0938 15"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UserList;
