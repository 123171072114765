/**
 * Container component representing a box container.
 * @param {Object} props - Component properties.
 * @param {ReactNode} props.children - Content inside the container.
 * @param {string} props.className - Additional classes to apply to the container.
 * @returns {ReactNode} JSX element representing the container.
 */
const Container = ({ children, className = "" }) => {
  return (
    <div
      className={`bg-neutral-50 border border-border-primary p-3  ${className}`}
    >
      {children}
    </div>
  );
};

export default Container;

// Sample Usage:
// import React from 'react';
// import Container from './Container';
//
// const ExampleComponent = () => {
//   return (
//     <div>
//       {/* Using the Container component */}
//       <Container className="rounded-md">
//         {/* Content goes here */}
//       </Container>
//     </div>
//   );
// };
//
// export default ExampleComponent;
