import api from "../../services/api";

/**
 * Creates stores API endpoints based on the specified base path.
 * @param {string} basePath - The base path for authentication endpoints.
 * @returns {Object} Object containing stores API methods.
 */
const createDashboardApi = (basePath) => ({
  /**
   * get all stores
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the store response.
   */
  getAllAdminCards: async (payload) => {
    const response = await api().post(`${basePath}/get-statistics`, payload);
    return response;
  },

  getAllStaffUsers: async (payload) => {
    const response = await api().post(`${basePath}/get-staff-users`, payload);
    return response;
  },

  createStaffUsers: async (payload) => {
    const response = await api().post(`${basePath}/create-staff-user`, payload);
    return response;
  },

  singleStaffUser: async (staffId) => {
    const response = await api().post(`${basePath}/get-staff-user/${staffId}`);
    return response;
  },

  /**
   * delete a staff
   * @param {object} staffId - id of the staff
   * @returns {Promise} A Promise that resolves to the staff response.
   */
  deleteStaffUser: async (id) => {
    const response = await api().post(`${basePath}/block-unblock/${id}`);
    return response;
  },
  /**
   * update a single staff
   * @param {object} id - id of the staff
   * @returns {Promise} A Promise that resolves to the staff response.
   */
  updateStaffUser: async (staffId, payload) => {
    const response = await api().post(
      `${basePath}/update-staff/${staffId}`,
      payload
    );
    return response;
  },

  getAllBillingHistory: async (payload) => {
    const response = await api().post(
      `${basePath}/latest-billing-history`,
      payload
    );
    return response;
  },

  getAllSubscriptions: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  getAllClientSales: async (payload) => {
    const response = await api().post(`${basePath}/get-sales`, payload);
    return response;
  },

  createStaffPassword: async ({ email, token, payload }) => {
    const response = await api().post(
      `${basePath}/manage-staff-password/${email}/${token}`,
      payload
    );
    return response;
  },
  updatePassword: async (staffId, payload) => {
    const response = await api().post(
      `${basePath}/update-password/${staffId}`,
      payload
    );
    return response;
  },
  getTopAccountManager: async (payload) => {
    const response = await api().post(
      `${basePath}/top-account-managers`,
      payload
    );
    return response;
  },
  getAllClients: async (payload) => {
    const response = await api().post(`${basePath}/get-all-clients`, payload);
    return response;
  },

  viewClientDetailsCards: async (userId) => {
    const response = await api().get(
      `${basePath}/view-client-details-cards/${userId}`
    );
    return response;
  },
  viewClientDetailsSales: async (payload) => {
    const response = await api().post(`${basePath}/view-client-details-sales`, payload);
    return response;
  },
  ViewClientDetailsIncome: async (payload) => {
    const response = await api().post(`${basePath}/view-client-details-income`, payload);
    return response;
  },
  viewClientDetailsExpenses: async (payload) => {
    const response = await api().post(`${basePath}/view-client-details-expenses`, payload);
    return response;
  },
  notficationSetting: async () => {
    const response = await api().get("/notification-setting");
    return response;
  },
  toggleSetting: async (payload) => {
    const response = await api().post("/notification-setting/toggle-settings", payload);
    return response;
  },
  notficationMessages: async () => {
    const response = await api().get("/notifications");
    return response;
  },
  markasRead: async () => {
    const response = await api().put("/notifications/mark-all-as-read");
    return response;
  },
  staffUserProfileUpdate: async (staffId, payload) => {
    const response = await api().post(
      `${basePath}/update-me/${staffId}`,
      payload
    );
    return response;
  },

});

export default createDashboardApi;
