import api from "../../services/api";

/**
 * Creates Subscriptions API endpoints based on the specified base path.
 * @param {string} basePath - The base path for Subscriptions endpoints.
 * @returns {Object} Object containing Subscriptions API methods.
 */

// Cancel Subscription
// Change Status
// Change Subscription
// Add Branches
// Extend Subscription
//getAlll

const createSubscriptionsApi = (basePath) => ({
  /**
   * get all Subscriptions
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting Subscriptions
   *    order: order for sorting Subscriptions by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the Subscription response.
   */
  getAllSubscriptions: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * get a single Subscription
   * @param {object} id - id of the Subscription
   * @returns {Promise} A Promise that resolves to the Subscription response.
   */
  getSingleSubscriptions: async (subscriptionId, isFreeTrial) => {
    const response = await api().get(
      `${basePath}/get-subscription/${subscriptionId}?isFreeTrial=${isFreeTrial}`
    );
    return response;
  },

  /**
   * add a Subscription
   * @param {object} id - id of the Subscription
   * @returns {Promise} A Promise that resolves to the Subscription response.
   */
  createClientSubscription: async (payload) => {
    const response = await api().post(
      "/client-subscriptions/manage-subscription",
      payload
    );
    return response;
  },
  /**
   * update a single Subscription
   * @param {object} id - id of the Subscription
   * @returns {Promise} A Promise that resolves to the Subscription response.
   */
  changeSubscription: async (subscriptionId, payload) => {
    const response = await api().post(
      `${basePath}/change/${subscriptionId}`,
      payload
    );
    return response;
  },

  /**
   * add a Subscription
   * @param {object} id - id of the Subscription
   * @returns {Promise} A Promise that resolves to the Subscription response.
   */
  addBranches: async (subscriptionId, payload) => {
    const response = await api().post(
      `${basePath}/add-branch/${subscriptionId}`,
      payload
    );
    return response;
  },

  /**
   * update a single Subscription
   * @param {object} id - id of the Subscription
   * @returns {Promise} A Promise that resolves to the Subscription response.
   */
  changeStatus: async (subscriptionId) => {
    const response = await api().put(
      `${basePath}/change-status/${subscriptionId}`
    );
    return response;
  },

  /**
   * add a Subscription
   * @param {object} id - id of the Subscription
   * @returns {Promise} A Promise that resolves to the Subscription response.
   */
  extendSubscription: async (subscriptionId, payload) => {
    const response = await api().post(
      `${basePath}/extend/${subscriptionId}`,
      payload
    );
    return response;
  },
  /**
   * delete a Subscription
   * @param {object} id - id of the Subscription
   * @returns {Promise} A Promise that resolves to the Subscription response.
   */
  deleteSubscription: async (subscriptionId) => {
    const response = await api().delete(`${basePath}/delete/${subscriptionId}`);
    return response;
  },

  /**
   * add a Subscription
   * @param {object} id - id of the Subscription
   * @returns {Promise} A Promise that resolves to the Subscription response.
   */
  createSubscription: async (payload) => {
    const response = await api().post(`${basePath}/create`, payload);
    return response;
  },

  freeToUpgrade: async (payload) => {
    const response = await api().post(
      "client-subscriptions/free-trial-upgrade",
      payload
    );
    return response;
  },

  /**
   * add a Subscription
   * @param {object} id - id of the Subscription
   * @returns {Promise} A Promise that manageClientSubscription to the Subscription response.
   */
  manageClientSubscription: async (payload) => {
    const response = await api().post(
      "/client-subscriptions/manage-subscription",
      payload
    );
    return response;
  },
});

export default createSubscriptionsApi;
