import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleSubscriptions,
  addBranches,
  getAllSubscriptions,
} from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";

export const useAddBranch = ({
  clientProfile,
  closeModal,
  subscriptionDetailId,
  selectedTab,
  getSingleClientDetail,
  subscriptionTableId,
  subscriptionPlanId,
}) => {
  const dispatch = useDispatch();
  const [branchCount, setBranchCount] = useState(1);
  const [branchPrice, setBranchPrice] = useState(71);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const discount = 0;
  const subscriptionId = subscriptionDetailId??subscriptionPlanId??subscriptionTableId;
  const tax = branchCount * branchPrice * 0.15;
  const incrementBranch = () => setBranchCount(branchCount + 1);
  const decrementBranch = () => {
    if (branchCount > 1) {
      setBranchCount(branchCount - 1);
    }
  };

  const { data: singleSubscriptiondata, isLoading: subscriptionLoading } =
    useSelector((state) => state.subscriptions.getSingleSubscriptions);

  const planTenure = singleSubscriptiondata?.plan_tenure;
  const planType = singleSubscriptiondata?.planType;
  // Update branchPrice based on planTenure
  useEffect(() => {
    if (planTenure === "ANNUAL") {
      setBranchPrice(850);
    } else {
      setBranchPrice(71);
    }
  }, [planTenure]);

  const subtotal = branchCount * branchPrice;

  const handleConfirmClick = () => {
    const payload = {
      totalBranches: branchCount,
      totalDiscount: discount,
      subTotal: subtotal,
      planId: singleSubscriptiondata?.planId,
      planTenure: planTenure,
      planType: singleSubscriptiondata?.planType,
    };
    setIsButtonDisabled(true);
    dispatch(
      addBranches({ subscriptionId, payload, successCallBack: handleSuccess })
    );
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  const handleSuccess = () => {
    closeModal();
    fetchAllSubscriptions();
    getSingleClientDetail();
    
  };

  const fetchAllSubscriptions = () => {
    dispatch(
      getAllSubscriptions({
        payload: {
          page: 1,
          pageSize: 10,
          sortColumn: "id",
          order: { id: "DESC" },
          condition: {},
          attributes: {},
          periodId: 0,
        },
      })
    );
  };

  console.log(clientProfile?.id, "clientProfile?.id");
  useEffect(() => {
    if (clientProfile?.id === undefined || clientProfile.id === null) {
      if (subscriptionId) {
        dispatch(
          getSingleSubscriptions({
            subscriptionId,
            isFreeTrial: "PaidSubscriptions" === "FreeTrial",
          })
        );
      }
    }
  }, []);

  return {
    subtotal,
    incrementBranch,
    decrementBranch,
    branchCount,
    branchPrice,
    singleSubscriptiondata,
    subscriptionLoading,
    discount,
    handleConfirmClick,
    // closeModal,
    profile: singleSubscriptiondata?.clientUser?.profile ?? "-",
    PrivideBy: singleSubscriptiondata?.managedBy?.fullName ?? "-",
    branches: singleSubscriptiondata?.branches ?? "-",
    subTotal: singleSubscriptiondata?.subTotal ?? "-",
    plan: singleSubscriptiondata?.plan_tenure ?? "-",
    planTenure,
    planType,
    isButtonDisabled,
    tax,
  };
};
