import api from "../../../services/api";

/**
 * Creates purchase invoices API endpoints based on the specified base path.
 * @param {string} basePath - The base path for purchase invoices endpoints.
 * @returns {Object} Object containing purchase invoices API methods.
 */
const createPurchaseInvoiceApi = (basePath) => ({
  /**
   * Retrieves all purchase invoices.
   * @param {object} payload - Payload containing query parameters.
   * @param {number} payload.page - Current page.
   * @param {number} payload.pageSize - Number of items per page.
   * @param {string} payload.sortColumn - Column ID for sorting purchase invoices.
   * @param {string} payload.order - Order for sorting purchase invoices by ascending or descending.
   * @param {object} payload.condition - Additional conditions for filtering.
   * @param {object} payload.attributes - Additional attributes for customization.
   * @returns {Promise} A Promise that resolves to the purchase invoices response.
   */
  getAllPurchaseInvoices: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * Retrieves a single purchase invoice.
   * @param {string} invoiceId - ID of the purchase invoice.
   * @returns {Promise} A Promise that resolves to the purchase invoice response.
   */
  getSinglePurchaseInvoice: async (invoiceId) => {
    const response = await api().get(`${basePath}/${invoiceId}`);
    return response;
  },

  /**
   * Updates a single purchase invoice.
   * @param {string} invoiceId - ID of the purchase invoice.
   * @param {object} payload - Payload containing updated data.
   * @returns {Promise} A Promise that resolves to the purchase invoice response.
   */
  updatePurchaseInvoice: async (invoiceId, payload) => {
    const response = await api().put(`${basePath}/${invoiceId}`, payload);
    return response;
  },

  /**
   * Adds a purchase invoice.
   * @param {object} payload - Payload containing data for the new purchase invoice.
   * @returns {Promise} A Promise that resolves to the purchase invoice response.
   */
  addPurchaseInvoice: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * Deletes a purchase invoice.
   * @param {string} invoiceId - ID of the purchase invoice to be deleted.
   * @returns {Promise} A Promise that resolves to the deletion response.
   */
  deletePurchaseInvoice: async (invoiceId) => {
    const response = await api().delete(`${basePath}/${invoiceId}`);
    return response;
  },
  /**
   * undo a deleted purchase invoice.
   * @param {string} invoiceId - ID of the purchase invoice to be undo.
   * @returns {Promise} A Promise that resolves to the deletion response.
   */
  undoPurchaseInvoice: async (invoiceId) => {
    const response = await api().get(
      `${basePath}/undo-purchase-invoice/${invoiceId}`
    );
    return response;
  },
});

// Export the createPurchaseInvoiceApi

/**
 * Example usage:
 * import createPurchaseInvoiceApi from './path/to/api/purchaseInvoiceApi';
 *
 * const purchaseInvoiceApi = createPurchaseInvoiceApi('purchaseInvoices');
 *
 * const getAllPurchaseInvoices = async (payload) => {
 *   try {
 *     const response = await purchaseInvoiceApi.getAllPurchaseInvoices(payload);
      // Handle response
 *   } catch (error) {
      // Handle error
 *   }
 * };
 */

export default createPurchaseInvoiceApi;
