/**
 * Manages state for stores.
 * Defines initial state for each store action.
 */

// Import necessary functions and modules from Redux Toolkit

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createDashboardApi from "./AdminDashboardCardsService";
import { showToast } from "../../components/common/utils/showToast.util";
/**
 * Initial state structure defining various properties related to stores processes.
 * Each property represents a specific stores action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllAdminCards, addStore, deleteStore, etc.
 * store data, error, success, loading, and message status for corresponding stores actions.
 */
const initialState = {
  getAllAdminCards: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getAllBillingHistory: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getAllSubscriptions: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getAllClientSales: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getAllStaffUsers: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  createStaffUsers: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteStaffUser: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateStaffUser: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  createStaffPassword: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updatePassword: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getTopAccountManager: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getAllClients: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  viewClientDetailsCards: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  viewClientDetailsExpenses: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  ViewClientDetailsIncome: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  viewClientDetailsSales: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  notficationSetting: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  toggleSetting: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  notficationMessages: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  markasRead: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  singleStaffUser: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  staffUserProfileUpdate: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the stores process.
 * These action types are prefixed with the 'stores' base path for better organization.
 */
const BASE = "dashboard";

export const ActionTypes = {
  GET_ALL_CARDS: `${BASE}/get-statistics`,
  GET_BILLING_HISTORY: `${BASE}/latest-billing-history`,
  GET_ALL_SUBSCRIPTIONS: `${BASE}/get-all-subscriptions`,
  GET_ALL_CLIENT_SALES: `${BASE}/get-sales`,
  GET_ALL_STAFF_USERS: `${BASE}/get-staff-users`,
  CREATE_STAFF_USERS: `${BASE}/create-staff-user`,
  UPDATE_PASSWORD: `${BASE}/update-password`,
  GET_ALL_CLIENTS: `${BASE}/get-all-clients`,
};

// Creating an instance of the stores service with a base URL 'stores'
const dashboardService = createDashboardApi(BASE);

/**
 * Initiates the getAllAdminCards process
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting stores
 *    order: order for sorting stores by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllAdminCards.
 */
export const getAllAdminCards = createAsyncThunk(
  ActionTypes.GET_ALL_CARDS,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.getAllAdminCards(payload);
    return response?.data?.data;
  }
);

export const notficationSetting = createAsyncThunk(
  "notficationSetting",
  async () => {
    const response = await dashboardService.notficationSetting();
    return response?.data?.data;
  }
);

export const notficationMessages = createAsyncThunk(
  "notficationMessages",
  async () => {
    const response = await dashboardService.notficationMessages();
    return response?.data?.data;
  }
);

export const markasRead = createAsyncThunk("markasRead", async () => {
  const response = await dashboardService.markasRead();
  return response?.data?.data;
});

export const getAllClients = createAsyncThunk(
  ActionTypes.GET_ALL_CLIENTS,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.getAllClients(payload);
    return response?.data?.data;
  }
);
/**
 * Initiates the getAllAdminCards process
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting stores
 *    order: order for sorting stores by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllAdminCards.
 */
export const getAllBillingHistory = createAsyncThunk(
  ActionTypes.GET_BILLING_HISTORY,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.getAllBillingHistory(payload);
    return response?.data?.data;
  }
);

export const getAllSubscriptions = createAsyncThunk(
  ActionTypes.GET_ALL_SUBSCRIPTIONS,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.getAllSubscriptions(payload);
    return response?.data?.data;
  }
);

export const getAllClientSales = createAsyncThunk(
  ActionTypes.GET_ALL_CLIENT_SALES,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.getAllClientSales(payload);
    return response?.data?.data;
  }
);

export const toggleSetting = createAsyncThunk(
  "toggleSetting",
  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.toggleSetting(payload);
    return response?.data?.data;
  }
);

export const getAllStaffUsers = createAsyncThunk(
  ActionTypes.GET_ALL_STAFF_USERS,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.getAllStaffUsers(payload);
    return response;
  }
);

export const createStaffUsers = createAsyncThunk(
  ActionTypes.CREATE_STAFF_USERS,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await dashboardService.createStaffUsers(payload);
      if (response?.data?.Succeeded) {
        showToast("Invited email send successfully!");
        successCallBack(response);
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const deleteStaffUser = createAsyncThunk(
  `${BASE}/delete-staff-member/id`,

  async ({ id, successCallBack }, thunkAPI) => {
    try {
      const response = await dashboardService.deleteStaffUser(id);
      if (response?.data?.Succeeded) {
        showToast("Status updated successfully!");
        successCallBack(response);

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const singleStaffUser = createAsyncThunk(
  "singleStaffUser",
  async ({ staffId, successCallBack }, thunkAPI) => {
    try {
      const response = await dashboardService.singleStaffUser(staffId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const getTopAccountManager = createAsyncThunk(
  "getTopAccountManager",
  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.getTopAccountManager(payload);
    return response?.data?.data;
  }
);

export const viewClientDetailsCards = createAsyncThunk(
  "viewClientDetailsCards",
  async ({ userId, successCallBack }, thunkAPI) => {
    try {
      const response = await dashboardService.viewClientDetailsCards(userId);
      if (response?.data?.Succeeded) {
        successCallBack(response);

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const updateStaffUser = createAsyncThunk(
  `${BASE}/update-staff/staffId`,

  async ({ staffId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await dashboardService.updateStaffUser(staffId, payload);
      if (response?.data?.Succeeded) {
        showToast("Staff updated successfully!");
        successCallBack(response);
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const createStaffPassword = createAsyncThunk(
  `${BASE}/manage-staff-password/email/token`,

  async ({ email, token, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await dashboardService.createStaffPassword({
        email,
        token,
        payload,
      });
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Staff Password Created successfully!");
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const staffUserProfileUpdate = createAsyncThunk(
  "staffUserProfileUpdate",
  async ({ staffId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await dashboardService.staffUserProfileUpdate(
        staffId,
        payload
      );
      if (response?.data?.Succeeded) {
        showToast("Profile updated successfully!");
        successCallBack(response);
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async ({ staffId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await dashboardService.updatePassword(staffId, payload);
      if (response?.data?.Succeeded) {
        showToast("Password updated successfully!");
        successCallBack(response);
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const viewClientDetailsSales = createAsyncThunk(
  "viewClientDetailsSales",
  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.viewClientDetailsSales(payload);
    return response?.data?.data;
  }
);

export const ViewClientDetailsIncome = createAsyncThunk(
  "ViewClientDetailsIncome",
  /**
     * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
     * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
     * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
     * @param {string} typePrefix - A string prefix for action types related to this thunk.
     * @param {function} payloadCreator - A function that initiates the asynchronous logic.
     * @param {object} options - Additional options for customizing the behavior of the created thunk action.
     * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
     }
     */

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.ViewClientDetailsIncome(payload);
    return response?.data?.data;
  }
);

export const viewClientDetailsExpenses = createAsyncThunk(
  "viewClientDetailsExpenses",
  /**
       * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
       * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
       * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
       * @param {string} typePrefix - A string prefix for action types related to this thunk.
       * @param {function} payloadCreator - A function that initiates the asynchronous logic.
       * @param {object} options - Additional options for customizing the behavior of the created thunk action.
       * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
       }
       */

  async ({ payload }, thunkAPI) => {
    const response = await dashboardService.viewClientDetailsExpenses(payload);
    return response?.data?.data;
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllstore action.
     */
    reset: (state) => {
      state.getAllAdminCards = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getAllClients = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getAllBillingHistory = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getAllSubscriptions = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getAllClientSales = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getAllStaffUsers = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.createStaffUsers = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.deleteStaffUser = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.updateStaffUser = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.createStaffPassword = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.updatePassword = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getTopAccountManager = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.viewClientDetailsCards = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.ViewClientDetailsIncome = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.viewClientDetailsSales = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.viewClientDetailsExpenses = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.notficationSetting = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.toggleSetting = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.notficationMessages = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.markasRead = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.singleStaffUser = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.staffUserProfileUpdate = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllAdminCards action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllAdminCards.pending, (state) => {
        state.getAllAdminCards.isLoading = true;
        state.getAllAdminCards.message = "";
        state.getAllAdminCards.isError = false;
        state.getAllAdminCards.isSuccess = false;
        state.getAllAdminCards.data = null;
      })
      /**
       * Updates the state when getAllAdminCards action is fulfilled/successful.
       * Updates loading and success flags and sets getAllAdminCards data with the payload.
       */
      .addCase(getAllAdminCards.fulfilled, (state, action) => {
        state.getAllAdminCards.isLoading = false;
        state.getAllAdminCards.isSuccess = true;
        state.getAllAdminCards.data = action.payload;
      })
      /**
       * Updates the state when getAllAdminCards action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllAdminCards.rejected, (state, action) => {
        state.getAllAdminCards.message = action.payload?.message;
        state.getAllAdminCards.isLoading = false;
        state.getAllAdminCards.isError = true;
        state.getAllAdminCards.data = null;
      })

      .addCase(getAllBillingHistory.pending, (state) => {
        state.getAllBillingHistory.isLoading = true;
        state.getAllBillingHistory.message = "";
        state.getAllBillingHistory.isError = false;
        state.getAllBillingHistory.isSuccess = false;
        state.getAllBillingHistory.data = null;
      })
      /**
       * Updates the state when getAllBillingHistory action is fulfilled/successful.
       * Updates loading and success flags and sets getAllBillingHistory data with the payload.
       */
      .addCase(getAllBillingHistory.fulfilled, (state, action) => {
        state.getAllBillingHistory.isLoading = false;
        state.getAllBillingHistory.isSuccess = true;
        state.getAllBillingHistory.data = action.payload;
      })
      /**
       * Updates the state when getAllBillingHistory action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllBillingHistory.rejected, (state, action) => {
        state.getAllBillingHistory.message = action.payload?.message;
        state.getAllBillingHistory.isLoading = false;
        state.getAllBillingHistory.isError = true;
        state.getAllBillingHistory.data = null;
      })
      /**
       * Updates the state when getAllBillingHistory action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllSubscriptions.pending, (state) => {
        state.getAllSubscriptions.isLoading = true;
        state.getAllSubscriptions.message = "";
        state.getAllSubscriptions.isError = false;
        state.getAllSubscriptions.isSuccess = false;
        state.getAllSubscriptions.data = null;
      })
      /**
       * Updates the state when getAllSubscriptions action is fulfilled/successful.
       * Updates loading and success flags and sets getAllSubscriptions data with the payload.
       */
      .addCase(getAllSubscriptions.fulfilled, (state, action) => {
        state.getAllSubscriptions.isLoading = false;
        state.getAllSubscriptions.isSuccess = true;
        state.getAllSubscriptions.data = action.payload;
      })
      /**
       * Updates the state when getAllSubscriptions action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllSubscriptions.rejected, (state, action) => {
        state.getAllSubscriptions.message = action.payload?.message;
        state.getAllSubscriptions.isLoading = false;
        state.getAllSubscriptions.isError = true;
        state.getAllSubscriptions.data = null;
      })
      /**
       * Updates the state when getAllBillingHistory action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllClientSales.pending, (state) => {
        state.getAllClientSales.isLoading = true;
        state.getAllClientSales.message = "";
        state.getAllClientSales.isError = false;
        state.getAllClientSales.isSuccess = false;
        state.getAllClientSales.data = null;
      })
      /**
       * Updates the state when getAllClientSales action is fulfilled/successful.
       * Updates loading and success flags and sets getAllClientSales data with the payload.
       */
      .addCase(getAllClientSales.fulfilled, (state, action) => {
        state.getAllClientSales.isLoading = false;
        state.getAllClientSales.isSuccess = true;
        state.getAllClientSales.data = action.payload;
      })
      /**
       * Updates the state when getAllClientSales action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllClientSales.rejected, (state, action) => {
        state.getAllClientSales.message = action.payload?.message;
        state.getAllClientSales.isLoading = false;
        state.getAllClientSales.isError = true;
        state.getAllClientSales.data = null;
      })
      /**
       * Updates the state when getAllBillingHistory action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllStaffUsers.pending, (state) => {
        state.getAllStaffUsers.isLoading = true;
        state.getAllStaffUsers.message = "";
        state.getAllStaffUsers.isError = false;
        state.getAllStaffUsers.isSuccess = false;
        state.getAllStaffUsers.data = null;
      })
      /**
       * Updates the state when getAllStaffUsers action is fulfilled/successful.
       * Updates loading and success flags and sets getAllStaffUsers data with the payload.
       */
      .addCase(getAllStaffUsers.fulfilled, (state, action) => {
        state.getAllStaffUsers.isLoading = false;
        state.getAllStaffUsers.isSuccess = true;
        state.getAllStaffUsers.data = action.payload;
      })
      /**
       * Updates the state when getAllStaffUsers action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllStaffUsers.rejected, (state, action) => {
        state.getAllStaffUsers.message = action.payload?.message;
        state.getAllStaffUsers.isLoading = false;
        state.getAllStaffUsers.isError = true;
        state.getAllStaffUsers.data = null;
      })
      /**
       * Updates the state when getAllBillingHistory action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(createStaffUsers.pending, (state) => {
        state.createStaffUsers.isLoading = true;
        state.createStaffUsers.message = "";
        state.createStaffUsers.isError = false;
        state.createStaffUsers.isSuccess = false;
        state.createStaffUsers.data = null;
      })
      /**
       * Updates the state when createStaffUsers action is fulfilled/successful.
       * Updates loading and success flags and sets createStaffUsers data with the payload.
       */
      .addCase(createStaffUsers.fulfilled, (state, action) => {
        state.createStaffUsers.isLoading = false;
        state.createStaffUsers.isSuccess = true;
        state.createStaffUsers.data = action.payload;
      })
      /**
       * Updates the state when createStaffUsers action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(createStaffUsers.rejected, (state, action) => {
        state.createStaffUsers.message = action.payload?.message;
        state.createStaffUsers.isLoading = false;
        state.createStaffUsers.isError = true;
        state.createStaffUsers.data = null;
      })
      /**
       * Updates the state when getAllBillingHistory action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(deleteStaffUser.pending, (state) => {
        state.deleteStaffUser.isLoading = true;
        state.deleteStaffUser.message = "";
        state.deleteStaffUser.isError = false;
        state.deleteStaffUser.isSuccess = false;
        state.deleteStaffUser.data = null;
      })
      /**
       * Updates the state when deleteStaffUser action is fulfilled/successful.
       * Updates loading and success flags and sets deleteStaffUser data with the payload.
       */
      .addCase(deleteStaffUser.fulfilled, (state, action) => {
        state.deleteStaffUser.isLoading = false;
        state.deleteStaffUser.isSuccess = true;
        state.deleteStaffUser.data = action.payload;
      })
      /**
       * Updates the state when deleteStaffUser action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(deleteStaffUser.rejected, (state, action) => {
        state.deleteStaffUser.message = action.payload?.message;
        state.deleteStaffUser.isLoading = false;
        state.deleteStaffUser.isError = true;
        state.deleteStaffUser.data = null;
      })
      /**
       * Updates the state when getAllBillingHistory action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateStaffUser.pending, (state) => {
        state.updateStaffUser.isLoading = true;
        state.updateStaffUser.message = "";
        state.updateStaffUser.isError = false;
        state.updateStaffUser.isSuccess = false;
        state.updateStaffUser.data = null;
      })
      /**
       * Updates the state when updateStaffUser action is fulfilled/successful.
       * Updates loading and success flags and sets updateStaffUser data with the payload.
       */
      .addCase(updateStaffUser.fulfilled, (state, action) => {
        state.updateStaffUser.isLoading = false;
        state.updateStaffUser.isSuccess = true;
        state.updateStaffUser.data = action.payload;
      })
      /**
       * Updates the state when updateStaffUser action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateStaffUser.rejected, (state, action) => {
        state.updateStaffUser.message = action.payload?.message;
        state.updateStaffUser.isLoading = false;
        state.updateStaffUser.isError = true;
        state.updateStaffUser.data = null;
      })
      /**
       *
       * Updates the state while the getPosSingleStore action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(createStaffPassword.pending, (state) => {
        state.createStaffPassword.isLoading = true;
        state.createStaffPassword.message = "";
        state.createStaffPassword.isError = false;
        state.createStaffPassword.isSuccess = false;
        state.createStaffPassword.data = null;
      })
      /**
       * Updates the state when createStaffPassword action is fulfilled/successful.
       * Updates loading and success flags and sets createStaffPassword data with the payload.
       */
      .addCase(createStaffPassword.fulfilled, (state, action) => {
        state.createStaffPassword.isLoading = false;
        state.createStaffPassword.isSuccess = true;
        state.createStaffPassword.data = action.payload;
      })
      /**
       * Updates the state when createStaffPassword action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(createStaffPassword.rejected, (state, action) => {
        state.createStaffPassword.message = action.payload?.message;
        state.createStaffPassword.isLoading = false;
        state.createStaffPassword.isError = true;
        state.createStaffPassword.data = null;
      })
      /**
       * Updates the state when getAllBillingHistory action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updatePassword.pending, (state) => {
        state.updatePassword.isLoading = true;
        state.updatePassword.message = "";
        state.updatePassword.isError = false;
        state.updatePassword.isSuccess = false;
        state.updatePassword.data = null;
      })
      /**
       * Updates the state when updatePassword action is fulfilled/successful.
       * Updates loading and success flags and sets updatePassword data with the payload.
       */
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.updatePassword.isLoading = false;
        state.updatePassword.isSuccess = true;
        state.updatePassword.data = action.payload;
      })
      /**
       * Updates the state when updatePassword action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updatePassword.rejected, (state, action) => {
        state.updatePassword.message = action.payload?.message;
        state.updatePassword.isLoading = false;
        state.updatePassword.isError = true;
        state.updatePassword.data = null;
      })
      /**
       * Updates the state when getAllAdminCards action is fulfilled/successful.
       * Updates loading and success flags and sets getAllAdminCards data with the payload.
       */
      .addCase(getTopAccountManager.pending, (state) => {
        state.getTopAccountManager.isLoading = true;
        state.getTopAccountManager.message = "";
        state.getTopAccountManager.isError = false;
        state.getTopAccountManager.isSuccess = false;
        state.getTopAccountManager.data = null;
      })
      /**
       * Updates the state when getTopAccountManager action is fulfilled/successful.
       * Updates loading and success flags and sets getTopAccountManager data with the payload.
       */
      .addCase(getTopAccountManager.fulfilled, (state, action) => {
        state.getTopAccountManager.isLoading = false;
        state.getTopAccountManager.isSuccess = true;
        state.getTopAccountManager.data = action.payload;
      })
      /**
       * Updates the state when getTopAccountManager action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getTopAccountManager.rejected, (state, action) => {
        state.getTopAccountManager.message = action.payload?.message;
        state.getTopAccountManager.isLoading = false;
        state.getTopAccountManager.isError = true;
        state.getTopAccountManager.data = null;
      })
      /**
       * Updates the state while the getAllAdminCards action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllClients.pending, (state) => {
        state.getAllClients.isLoading = true;
        state.getAllClients.message = "";
        state.getAllClients.isError = false;
        state.getAllClients.isSuccess = false;
        state.getAllClients.data = null;
      })
      /**
       * Updates the state when getAllClients action is fulfilled/successful.
       * Updates loading and success flags and sets getAllClients data with the payload.
       */
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.getAllClients.isLoading = false;
        state.getAllClients.isSuccess = true;
        state.getAllClients.data = action.payload;
      })
      /**
       * Updates the state when getAllClients action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllClients.rejected, (state, action) => {
        state.getAllClients.message = action.payload?.message;
        state.getAllClients.isLoading = false;
        state.getAllClients.isError = true;
        state.getAllClients.data = null;
      })
      /**
       * Updates the state while the viewClientDetailsCards action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(viewClientDetailsCards.pending, (state) => {
        state.viewClientDetailsCards.isLoading = true;
        state.viewClientDetailsCards.message = "";
        state.viewClientDetailsCards.isError = false;
        state.viewClientDetailsCards.isSuccess = false;
        state.viewClientDetailsCards.data = null;
      })
      /**
       * Updates the state when viewClientDetailsCards action is fulfilled/successful.
       * Updates loading and success flags and sets viewClientDetailsCards data with the payload.
       */
      .addCase(viewClientDetailsCards.fulfilled, (state, action) => {
        state.viewClientDetailsCards.isLoading = false;
        state.viewClientDetailsCards.isSuccess = true;
        state.viewClientDetailsCards.data = action.payload;
      })
      /**
       * Updates the state when viewClientDetailsCards action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(viewClientDetailsCards.rejected, (state, action) => {
        state.viewClientDetailsCards.message = action.payload?.message;
        state.viewClientDetailsCards.isLoading = false;
        state.viewClientDetailsCards.isError = true;
        state.viewClientDetailsCards.data = null;
      })

      /**
       * Updates the state while the ViewClientDetailsIncome action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(ViewClientDetailsIncome.pending, (state) => {
        state.ViewClientDetailsIncome.isLoading = true;
        state.ViewClientDetailsIncome.message = "";
        state.ViewClientDetailsIncome.isError = false;
        state.ViewClientDetailsIncome.isSuccess = false;
        state.ViewClientDetailsIncome.data = null;
      })
      /**
       * Updates the state when ViewClientDetailsIncome action is fulfilled/successful.
       * Updates loading and success flags and sets ViewClientDetailsIncome data with the payload.
       */
      .addCase(ViewClientDetailsIncome.fulfilled, (state, action) => {
        state.ViewClientDetailsIncome.isLoading = false;
        state.ViewClientDetailsIncome.isSuccess = true;
        state.ViewClientDetailsIncome.data = action.payload;
      })
      /**
       * Updates the state when ViewClientDetailsIncome action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(ViewClientDetailsIncome.rejected, (state, action) => {
        state.ViewClientDetailsIncome.message = action.payload?.message;
        state.ViewClientDetailsIncome.isLoading = false;
        state.ViewClientDetailsIncome.isError = true;
        state.ViewClientDetailsIncome.data = null;
      })
      /**
       * Updates the state while the viewClientDetailsSales action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(viewClientDetailsSales.pending, (state) => {
        state.viewClientDetailsSales.isLoading = true;
        state.viewClientDetailsSales.message = "";
        state.viewClientDetailsSales.isError = false;
        state.viewClientDetailsSales.isSuccess = false;
        state.viewClientDetailsSales.data = null;
      })
      /**
       * Updates the state when viewClientDetailsSales action is fulfilled/successful.
       * Updates loading and success flags and sets viewClientDetailsSales data with the payload.
       */
      .addCase(viewClientDetailsSales.fulfilled, (state, action) => {
        state.viewClientDetailsSales.isLoading = false;
        state.viewClientDetailsSales.isSuccess = true;
        state.viewClientDetailsSales.data = action.payload;
      })
      /**
       * Updates the state when viewClientDetailsSales action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(viewClientDetailsSales.rejected, (state, action) => {
        state.viewClientDetailsSales.message = action.payload?.message;
        state.viewClientDetailsSales.isLoading = false;
        state.viewClientDetailsSales.isError = true;
        state.viewClientDetailsSales.data = null;
      })
      /**
       * Updates the state while the viewClientDetailsExpenses action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(viewClientDetailsExpenses.pending, (state) => {
        state.viewClientDetailsExpenses.isLoading = true;
        state.viewClientDetailsExpenses.message = "";
        state.viewClientDetailsExpenses.isError = false;
        state.viewClientDetailsExpenses.isSuccess = false;
        state.viewClientDetailsExpenses.data = null;
      })
      /**
       * Updates the state when viewClientDetailsExpenses action is fulfilled/successful.
       * Updates loading and success flags and sets viewClientDetailsExpenses data with the payload.
       */
      .addCase(viewClientDetailsExpenses.fulfilled, (state, action) => {
        state.viewClientDetailsExpenses.isLoading = false;
        state.viewClientDetailsExpenses.isSuccess = true;
        state.viewClientDetailsExpenses.data = action.payload;
      })
      /**
       * Updates the state when viewClientDetailsExpenses action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(viewClientDetailsExpenses.rejected, (state, action) => {
        state.viewClientDetailsExpenses.message = action.payload?.message;
        state.viewClientDetailsExpenses.isLoading = false;
        state.viewClientDetailsExpenses.isError = true;
        state.viewClientDetailsExpenses.data = null;
      })
      /**
       * Updates the state while the notficationSetting action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(notficationSetting.pending, (state) => {
        state.notficationSetting.isLoading = true;
        state.notficationSetting.message = "";
        state.notficationSetting.isError = false;
        state.notficationSetting.isSuccess = false;
        state.notficationSetting.data = null;
      })
      /**
       * Updates the state when notficationSetting action is fulfilled/successful.
       * Updates loading and success flags and sets notficationSetting data with the payload.
       */
      .addCase(notficationSetting.fulfilled, (state, action) => {
        state.notficationSetting.isLoading = false;
        state.notficationSetting.isSuccess = true;
        state.notficationSetting.data = action.payload;
      })
      /**
       * Updates the state when notficationSetting action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(notficationSetting.rejected, (state, action) => {
        state.notficationSetting.message = action.payload?.message;
        state.notficationSetting.isLoading = false;
        state.notficationSetting.isError = true;
        state.notficationSetting.data = null;
      })
      /**
       * Updates the state while the toggleSetting action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(toggleSetting.pending, (state) => {
        state.toggleSetting.isLoading = true;
        state.toggleSetting.message = "";
        state.toggleSetting.isError = false;
        state.toggleSetting.isSuccess = false;
        state.toggleSetting.data = null;
      })
      /**
       * Updates the state when toggleSetting action is fulfilled/successful.
       * Updates loading and success flags and sets toggleSetting data with the payload.
       */
      .addCase(toggleSetting.fulfilled, (state, action) => {
        state.toggleSetting.isLoading = false;
        state.toggleSetting.isSuccess = true;
        state.toggleSetting.data = action.payload;
      })
      /**
       * Updates the state when toggleSetting action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(toggleSetting.rejected, (state, action) => {
        state.toggleSetting.message = action.payload?.message;
        state.toggleSetting.isLoading = false;
        state.toggleSetting.isError = true;
        state.toggleSetting.data = null;
      })
      /**
       * Updates the state while the notficationMessages action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(notficationMessages.pending, (state) => {
        state.notficationMessages.isLoading = true;
        state.notficationMessages.message = "";
        state.notficationMessages.isError = false;
        state.notficationMessages.isSuccess = false;
        state.notficationMessages.data = null;
      })
      /**
       * Updates the state when notficationMessages action is fulfilled/successful.
       * Updates loading and success flags and sets notficationMessages data with the payload.
       */
      .addCase(notficationMessages.fulfilled, (state, action) => {
        state.notficationMessages.isLoading = false;
        state.notficationMessages.isSuccess = true;
        state.notficationMessages.data = action.payload;
      })
      /**
       * Updates the state when notficationMessages action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(notficationMessages.rejected, (state, action) => {
        state.notficationMessages.message = action.payload?.message;
        state.notficationMessages.isLoading = false;
        state.notficationMessages.isError = true;
        state.notficationMessages.data = null;
      })
      /**
       * Updates the state while the markasRead action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(markasRead.pending, (state) => {
        state.markasRead.isLoading = true;
        state.markasRead.message = "";
        state.markasRead.isError = false;
        state.markasRead.isSuccess = false;
        state.markasRead.data = null;
      })
      /**
       * Updates the state when markasRead action is fulfilled/successful.
       * Updates loading and success flags and sets markasRead data with the payload.
       */
      .addCase(markasRead.fulfilled, (state, action) => {
        state.markasRead.isLoading = false;
        state.markasRead.isSuccess = true;
        state.markasRead.data = action.payload;
      })
      /**
       * Updates the state when markasRead action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(markasRead.rejected, (state, action) => {
        state.markasRead.message = action.payload?.message;
        state.markasRead.isLoading = false;
        state.markasRead.isError = true;
        state.markasRead.data = null;
      })
      /**
       * Updates the state while the singleStaffUser action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(singleStaffUser.pending, (state) => {
        state.singleStaffUser.isLoading = true;
        state.singleStaffUser.message = "";
        state.singleStaffUser.isError = false;
        state.singleStaffUser.isSuccess = false;
        state.singleStaffUser.data = null;
      })
      /**
       * Updates the state when singleStaffUser action is fulfilled/successful.
       * Updates loading and success flags and sets singleStaffUser data with the payload.
       */
      .addCase(singleStaffUser.fulfilled, (state, action) => {
        state.singleStaffUser.isLoading = false;
        state.singleStaffUser.isSuccess = true;
        state.singleStaffUser.data = action.payload;
      })
      /**
       * Updates the state when singleStaffUser action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(singleStaffUser.rejected, (state, action) => {
        state.singleStaffUser.message = action.payload?.message;
        state.singleStaffUser.isLoading = false;
        state.singleStaffUser.isError = true;
        state.singleStaffUser.data = null;
      })
      /**
       * Updates the state while the staffUserProfileUpdate action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(staffUserProfileUpdate.pending, (state) => {
        state.staffUserProfileUpdate.isLoading = true;
        state.staffUserProfileUpdate.message = "";
        state.staffUserProfileUpdate.isError = false;
        state.staffUserProfileUpdate.isSuccess = false;
        state.staffUserProfileUpdate.data = null;
      })
      /**
       * Updates the state when staffUserProfileUpdate action is fulfilled/successful.
       * Updates loading and success flags and sets staffUserProfileUpdate data with the payload.
       */
      .addCase(staffUserProfileUpdate.fulfilled, (state, action) => {
        state.staffUserProfileUpdate.isLoading = false;
        state.staffUserProfileUpdate.isSuccess = true;
        state.staffUserProfileUpdate.data = action.payload;
      })
      /**
       * Updates the state when staffUserProfileUpdate action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(staffUserProfileUpdate.rejected, (state, action) => {
        state.staffUserProfileUpdate.message = action.payload?.message;
        state.staffUserProfileUpdate.isLoading = false;
        state.staffUserProfileUpdate.isError = true;
        state.staffUserProfileUpdate.data = null;
      });
  },
});

export const { reset } = dashboardSlice?.actions;
export default dashboardSlice.reducer;
