import React from "react";
import Navbar from "../../../components/common/components/Navbar/Navbar";
import Footer from "../../../components/common/components/Footer/Footer";
import PasswordSuccess from "./password-success";
import { useResetPassword } from "./use-reset-password.hook";
import CreatePasswordForm from "../../../components/CreatePasswordForm/CreatePasswordForm";

/**
 * ResetPassword component.
 * Renders a form to reset the user's password and redirects to a success page upon successful password reset.
 *
 * @returns {JSX.Element} JSX code for rendering the ResetPassword component.
 */

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    isPasswordValid,
    passwordUpdated,
    validatePassword,
  } = useResetPassword();

  // Redirect to password success page if password is updated
  if (passwordUpdated) {
    return <PasswordSuccess />;
  }
  return (
    <div className="w-full flex flex-col justify-between min-h-screen">
      <div>
        <Navbar />
        <div className="max-w-[480px] mx-auto py-12">
          {/* heading */}
          <h2 className="text-3xl font-semibold mb-3 text-text-primary mt-3">
            Reset Password
          </h2>
          {/* form */}
          <CreatePasswordForm
            register={register}
            errors={errors}
            onSubmit={handleSubmit(onSubmit)}
            isPasswordValid={isPasswordValid}
            validatePassword={validatePassword}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;

// Sample Usage:
// import ResetPassword from '.path/ResetPassword';
// <ResetPassword />
