import React, { useState } from "react";
import { SelectGroup } from "../../../../../common/components";
import CustomDatePicker from "../../../../../common/components/CustomDatePicker/CustomDatePicker";

const data = [
  { name: "Japan", value: 40293, color: "#5BBAC0" },
  { name: "France", value: 30293, color: "#DC67CE" },
  { name: "America", value: 25293, color: "#1D96FF" },
  { name: "South Korea", value: 20293, color: "#53B1FD" },
  { name: "Indonesia", value: 20293, color: "#2BE3B7" },
];

const total = 5000 * 10;
const ProgressBar = ({ name, value, color }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const widthPercentage = Math.min(100, (value / total) * 100);

  return (
    <div className="flex flex-col items-start  relative mt-2">
      <div
        className="w-full bg-gray-200 rounded-full h-7 dark:bg-gray-700"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div
          className="h-7 rounded-md transition-all duration-300 ease-in-out"
          style={{ width: `${widthPercentage}%`, backgroundColor: color }}
        />
        {showTooltip && (
          <div
            className="absolute -translate-x-1/2 left-1/2 bottom-full mb-2 px-2  text-white text-xs rounded shadow-lg"
            style={{ backgroundColor: color }}
          >
            {value.toLocaleString()} SAR
          </div>
        )}
      </div>
      <div className="flex justify-between w-full mt-1.5">
        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {name}
        </span>
        <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {value.toLocaleString()} SAR
        </span>
      </div>
    </div>
  );
};

const CustomProgressBar = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };
  return (
    <div>
      <p className="flex justify-center mt-2 text-text-primary font-medium text-base">
        Subscribers By Location{" "}
      </p>
      <div className="flex items-center  mb-2 ">
        <div className="flex items-center flex-grow pl-2 justify-between text-text-primary font-medium text-base">
          <CustomDatePicker
            initialStartDate={startDate}
            initialEndDate={endDate}
            onChange={(selection) => {
              handleDateChange(selection);
            }}
            className="w-12"
          />
          <SelectGroup
            htmlFor="role"
            inputName="role"
            options={Role}
            textColor="text-text-primary"
            className="pr-8 "
          />{" "}
        </div>
      </div>
      <div className=" mt-10 px-2">
        {data?.map((item, index) => (
          <ProgressBar
            key={index}
            name={item.name}
            value={item.value}
            color={item.color}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomProgressBar;
const Role = [
  { value: "3", label: "Sales By Location" },
  { value: "1", label: "Pakistan" },
  { value: "2", label: "India" },
  { value: "4", label: "Saudia Arabia" },
];
