import api from "../../services/api";

/**
 * Creates suppliers API endpoints based on the specified base path.
 * @param {string} basePath - The base path for suppliers endpoints.
 * @returns {Object} Object containing suppliers API methods.
 */
const createSuppliersApi = (basePath) => ({
  /**
   * get all suppliers
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting suppliers
   *    order: order for sorting suppliers by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the supplier response.
   */
  getAllSuppliers: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * get a single supplier
   * @param {object} id - id of the supplier
   * @returns {Promise} A Promise that resolves to the supplier response.
   */
  getSingleSupplier: async (supplierId) => {
    const response = await api().get(`${basePath}/${supplierId}`);
    return response;
  },

  /**
   * update a single supplier
   * @param {object} id - id of the supplier
   * @returns {Promise} A Promise that resolves to the supplier response.
   */
  updateSupplier: async (supplierId, payload) => {
    const response = await api().put(`${basePath}/${supplierId}`, payload);
    return response;
  },

  /**
   * add a supplier
   * @param {object} id - id of the supplier
   * @returns {Promise} A Promise that resolves to the supplier response.
   */
  addSupplier: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * delete a supplier
   * @param {object} id - id of the supplier
   * @returns {Promise} A Promise that resolves to the supplier response.
   */
  deleteSupplier: async (supplierId) => {
    const response = await api().delete(`${basePath}/${supplierId}`);
    return response;
  },
  /**
   * undo a deleted  supplier
   * @param {object} id - id of the supplier
   * @returns {Promise} A Promise that resolves to the supplier response.
   */
  undoSupplier: async (supplierId) => {
    const response = await api().get(`${basePath}/undo-supplier/${supplierId}`);
    return response;
  },
});

// Export the createsuppliersApi

/**
 * Example usage:
 * import createsuppliersApi from './path/to/api/suppliersApi';
 *
 * const suppliersApi = createsuppliersApi('suppliers');
 *
 * const getAllsuppliers = async (payload) => {
 *   try {
 *     const response = await suppliersApi.getAllsuppliers(payload);
 *     // Handle response
 *   } catch (error) {
 *     // Handle error
 *   }
 * };
 */

export default createSuppliersApi;
