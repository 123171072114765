import React, { useEffect, useState } from "react";
import CustomSearch from "../../../../../common/components/CustomSearch/CustomSearch";
import { CustomButton, CustomTable } from "../../../../../common/components";
import { Plus } from "../../../../../common/Icons/fontawesome";
import { useAdminUsers } from "./useAdminUsers";
import CustomAdminModel from "../../../../../common/components/AdminModal/CustomAdminModal";
import AddNewUser from "./AddNewUser/AddNewUser";
import DeleteAdminUser from "./DeleteAdminUser/DeleteAdminUser";
import AdminEditUser from "./AdminEditUser/AdminEditUser";
import { EditIcon, EyeIcon, TrashIcon } from "../../../../../common/Icons";
import PaginationBar from "../../../../../common/components/PaginationBar/PaginationBar";
import { useDispatch } from "react-redux";
import useDebounce from "../../../../../../hooks/useDebounce";
import { setSearchQuery } from "../../../../../../features/GlobalSearch/GlobalSearchSlice";

function AdminUsers() {
  const {
    headers,
    tableBody,
    closeModal,
    openModal,
    selectedItem,
    activeModal,
    openAddNewUser,
    handleDelete,
    setSelectedItem,
    staffUseroading,
    currentPage,
    setCurrentPage,
    handleSearchChange,
    setUpdateClient,
    totalPages
  } = useAdminUsers();
  
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const dispatch = useDispatch();

  // Effect to dispatch the debounced search value
  useEffect(() => {
    dispatch(setSearchQuery(debouncedSearchValue));
  }, [debouncedSearchValue, dispatch]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <div className="p-3">
      <div className="flex items-center space-x-4 ">
        <div className="flex items-center flex-grow">
        <form className="relative flex " action="#" method="GET">
        <CustomSearch
          placeholder="Search..."
          inputName="search"
          paddingY="py-2"
          onChange={handleInputChange}
        />
      </form>
        </div>

        <CustomButton
          bgColor="!bg-surface-primary"
          hoverTextColor="text-white"
          className="hover:!text-white"
          icon={<Plus color="white" />}
          width="w-fit"
          border="border"
          text="Add New User"
          padding="px-2 py-3"
          textColor="text-white"
          onClick={openAddNewUser}
        />
      </div>
      <div className="h-[calc(70vh_-_15vh)] flex flex-col justify-between overflow-auto">
        <CustomTable
          tableHeader={headers}
          tableBody={tableBody}
          setSelectedItem={setSelectedItem}
          loading={staffUseroading}
          dropdownOptions={[
            {
              text: "Edit",
              icon: <EditIcon className="w-6" />,
              onClick: () => openModal(selectedItem, "edit"),
            },
            {
              text: "Change Status",
              textColor: "text-red-500",
              icon: <TrashIcon className="w-6" />,
              onClick: () => openModal(selectedItem, "remove"),
            },
          ]}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          />
      </div>
      {activeModal === "edit" && (
        <CustomAdminModel
          isOpen={true}
          onClose={closeModal}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
          title="Edit User"
        >
          <AdminEditUser
            user={selectedItem}
            onClose={closeModal}
            setUpdateClient={setUpdateClient}
          />
        </CustomAdminModel>
      )}

      {activeModal === "remove" && (
        <CustomAdminModel
          isOpen={true}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[500px]"
          onClose={closeModal}
          title="Delete User"
        >
          <DeleteAdminUser
            user={selectedItem}
            onClose={closeModal}
            onDelete={handleDelete}
          />
        </CustomAdminModel>
      )}
      {activeModal === "AddNewUser" && (
        <CustomAdminModel
          isOpen={true}
          onClose={closeModal}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
          title="Add New User"
        >
          <AddNewUser onClose={closeModal} setUpdateClient={setUpdateClient} />
        </CustomAdminModel>
      )}
    </div>
  );
}

export default AdminUsers;
