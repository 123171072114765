import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * Custom hook for tabs base content
 *
 * @returns {Object}
 *   active: Currently selected tab
 *   changeTab: A function to handle tab change
 *   TabContent: A function to render content for selected tab
 */

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const useTabs = (initialTab, tabs) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(initialTab);

  const changeTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const TabContent = () => tabs[activeTab].content();

  const TabHeader = ({
    padding = "py-3 px-5",
    showHeading = true,
    position = "center",
  }) => {
    return (
      <div className="">
        <div className="border-b border-gray-200">
          <nav
            className={`-mb-px flex items-center justify-${position}`}
            aria-label="Tabs"
          >
            {tabs.map((tab, index) => (
              <span
                key={tab.title}
                onClick={() => changeTab(index)}
                className={classNames(
                  activeTab === index
                    ? "border-surface-primary text-text-primary font-bold"
                    : "border-transparent text-text-secondary hover:border-gray-300 hover:text-gray-700 font-medium",
                  `whitespace-nowrap border-b-3 ${padding} text-sm cursor-pointer flex flex-col items-center space-y-3`
                )}
              >
                {tab.icon && (
                  <tab.icon
                    className={`${
                      activeTab === index
                        ? "fill-surface-primary stroke-surface-primary"
                        : index !== 0
                        ? "stroke-text-secondary"
                        : "fill-text-secondary stroke-text-secondary"
                    }`}
                  />
                )}
                <span className={`${!showHeading ? "lg:block hidden" : ""}`}>
                  {t(tab.title)}
                </span>
              </span>
            ))}
          </nav>
        </div>
      </div>
    );
  };

  return { activeTab: tabs[activeTab], changeTab, TabContent, TabHeader };
};

export default useTabs;

// sameple usage

// import useTabs from "./path/to/useTabs.js";
// const tabs = [
//     { title: 'Tab 1', content: () => <div>Content for Tab 1</div> },
//     { title: 'Tab 2', content: () => <div>Content for Tab 2</div> },
//     { title: 'Tab 3', content: () => <div>Content for Tab 3</div> },
//   ];

//   const { activeTab, changeTab, TabContent } = useTabs(0, tabs);

// return (
//     <div>
//       <div className="flex">
//         {tabs.map((tab, index) => (
//           <button
//             key={index}
//             onClick={() => changeTab(index)}
//             className={`${
//               activeTab === index ? 'bg-gray-400' : 'bg-gray-200'
//             } px-4 py-2 rounded-md mr-2`}
//           >
//             {tab.title}
//           </button>
//         ))}
//       </div>
//       <div className="mt-4">
//         <TabContent />
//       </div>
//     </div>
//   );
