import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePosOrder,
  getAllPosOrders,
  handleDuplicateData,
} from "../../../../features/PosOrder/PosOrderSlice";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

// Constants for headers and dropdownOptions can be defined outside the hook if they don't depend on the hook's state or props.
const headers = [
  { label: "Invoice No", key: "invoiceno" },
  { label: "Invoice Date", key: "invoicedate" },
  { label: "Created by", key: "createdby" },
  { label: "Customer", key: "customer" },
  { label: "Store", key: "store" },
  { label: "Table Number", key: "tablenumber" },
  { label: "Tax Amount", key: "taxamount" },
  { label: "Total", key: "total" },
  { label: "Payment Method", key: "paymentmethod" },
  { label: "Status", key: "status" },
];

const dropdownOptions = [
  { label: "Checkout", value: "checkout" },
  { label: "Edit", value: "edit" },
  { label: "Duplicate", value: "duplicate" },
  { label: "Delete", value: "delete" },

];

export const usePosSaveOrder = ({
  setSelectedItem,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [tableBodySaved, setTableBodySaved] = useState([]);
  const navigate = useNavigate();

  const {
    data: orderList,
    isLoading: orderListLoading,
  } = useSelector((state) => state.posOrder.getAllPosOrders);
  const currentDate = format(new Date(), "MM/dd/yyyy");
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const branchName = storedUserData?.user?.store?.branchName;
  const fullName = storedUserData?.user?.fullName;

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllPosOrders({ payload }));
  }, []);
  const successDeleteOrder = () => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllPosOrders({ payload }));
  };
  useEffect(() => {
      const savedOrders = orderList
        ?.filter((order) => order?.status === "SAVED")
        ?.map((order) => ({
          ...order,
          invoiceno: order?.invoiceNumber || "-",
          invoicedate: order?.invoiceDate || "-",
          createdby: order?.posUser?.createdBy || "-",
          customer: order?.customer?.name || "-",
          store: order?.store?.branchName || "-",
          tablenumber: order.table?.position || "-",
          taxamount: order?.taxAmount || "-",
          total: order?.totalAmount || "-",
          paymentmethod: order.paymentStatus
            ? order.paymentStatus
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")
            : "-",
          status: order.status
            ? order.status
                .split("_")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")
            : "-",
          orderData: order,
        }));

      setTableBodySaved(savedOrders);
    
  }, [ orderListLoading, orderList]);

  const deleteOrder = (order) => {
    const { id: orderId } = order;
    dispatch(deletePosOrder({ orderId, successCallBack: successDeleteOrder }));
  };
  const openModal = (order) => {
    setSelectedItem(order);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedItem(null);
  };

  const duplicateOrder = (order,type="edit") => {
    dispatch(
      handleDuplicateData({
        ...order,
        orderedItems: order.orderedItems?.map((o) => ({
          price: o.item.price,
          id: o.item.id,
          image: o.item.image,
          englishName: o.item.englishName,
          salePrice: o.item.salePrice,
          quantity: o.quantity,
          note: o.note,
          ...(o.vItem
            ? { selections: o.vItem.variations, vItemId: o.vItem.id }
            : {}),
        })),
      })
    );
    navigate("/pos/dashboard", { state: { type, id: order.id } });
  };

  const dropdownOptionsWithActions = dropdownOptions?.map((option) => ({
    ...option,
    action: (order) => {
      if (option.value === "checkout") {
        openModal(order);
      } else if (option.value === "edit") {
        return duplicateOrder(order);
      } else if (option.value === "duplicate") {
        return duplicateOrder(order,"duplicate");
      } else if (option.value === "delete") {
        return deleteOrder(order);
      }
    },
  }));

  return {
    isOpen,
    dropdownOptions,
    headers,
    tableBodySaved,
    closeModal,
    openModal,
    dropdownOptionsWithActions,
    orderListLoading,
    fullName,
    branchName,
    currentDate,
    duplicateOrder,
  };
};

export default usePosSaveOrder;
