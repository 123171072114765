import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../../common/components/Footer/Footer";
import CustomButton from "../../../common/components/Button/Button";
import { CheckMark } from "../../../common/Icons";
import Navbar from "../../../common/components/Navbar/Navbar";

function ActivateAccount() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams()

  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const handleButtonClick = () => {
    navigate(`/set-staff-password?token=${token}&email=${email}`);
  };
  return (
    <div className="flex flex-col min-h-screen justify-between overflow-hidden">
      <Navbar />

      <div className="w-full flex flex-col justify-between items-center">
        <div className="lg:max-w-[480px] mx-auto flex flex-col items-center justify-center">
          {/* tick icon */}
          <CheckMark className="mx-auto" />
          {/* heading */}
          <h2 className="text-3xl font-semibold my-3 text-center text-text-primary">
            Steps to activate your account{" "}
          </h2>
          <p className="text-text-secondary text-base font-normal text-center">
            "Welcome aboard! We're thrilled to have you join our team." Please
            continue the steps to activate your account
          </p>
          <CustomButton
            text="Continue"
            type="submit"
            className="mt-6"
            onClick={handleButtonClick}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ActivateAccount;
