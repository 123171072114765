import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useAdminLogin from "./useAdminLogin";
import { CustomButton, InputGroup } from "../../common/components";
import { Eye, EyeSlash } from "../../common/Icons/fontawesome";
// import FacebookLoginButton from "./FacebookLoginButton";

/**
 * AdminLoginForm component.
 * Renders a form for user login including email, password fields, and social login options.
 *
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} JSX code for rendering the AdminLoginForm component.
 */

const AdminLoginForm = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, errors, isValid, isSubmitting } =
    useAdminLogin();

  return (
    <div className="max-w-[480px] min-w-[480px] mx-auto">
      {/* heading */}
      <h2 className="text-3xl font-semibold mb-3 text-center text-text-primary">
        {t(`login.title`)}
      </h2>
      <p className="text-text-secondary text-base font-normal text-center">
        {t(`login.welcome`)}
      </p>
      {/* social logins and form fields */}
      <div className="space-y-4 mt-3">
        {/* login form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* email */}
          <InputGroup
            htmlfor="email"
            labelText={t(`login.email`)}
            inputName="email"
            placeholder={t(`login.email_placeholder`)}
            inputType="email"
            register={register}
            errors={errors}
          />

          {/* password */}
          <InputGroup
            htmlfor="password"
            labelText={t(`login.password`)}
            inputName="password"
            placeholder={t(`login.password_placeholder`)}
            inputType={showPassword ? "text" : "password"}
            register={register}
            errors={errors}
          >
            <span
              className="cursor-pointer absolute end-3 top-6 pt-1"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? (
                <Eye color="gray-600" />
              ) : (
                <EyeSlash color="gray-700" />
              )}
            </span>
          </InputGroup>

          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center gap-2">
              <InputGroup
                htmlfor="remember"
                inputName="remember"
                inputType="checkbox"
                register={register}
                errors={errors}
              />
              <label
                htmlFor="remember"
                className="mt-1 block text-xs font-normal text-text-primary"
              >
                {t(`login.remember_me`)}
              </label>
            </div>
            <Link
              to="/forget-password"
              className="text-sm underline hover:text-text-link"
            >
              {t(`login.forgot_password`)}
            </Link>
          </div>
          <CustomButton
            text={t(`login.title`)}
            type="submit"
            disabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
          />
        </form>
      </div>
    </div>
  );
};

export default AdminLoginForm;

// Example usage of AdminLoginForm component:
// import  AdminLoginForm  from 'path/to/login'
// <AdminLoginForm userType="client" />
