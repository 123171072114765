function RestaurantIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M29 11.975V11.925C28.9946 11.9044 28.9904 11.8836 28.9875 11.8625V11.825C28.975 11.8 28.975 11.775 28.9625 11.75V11.725L27.175 5.45C27.0518 5.03397 26.7983 4.66851 26.4517 4.40746C26.1051 4.1464 25.6839 4.00357 25.25 4H6.75C6.31613 4.00357 5.89488 4.1464 5.54831 4.40746C5.20174 4.66851 4.94819 5.03397 4.825 5.45L3.0375 11.725V11.75C3.025 11.775 3.025 11.8 3.0125 11.825V11.8625C3.00957 11.8836 3.0054 11.9044 3 11.925V14C3 14.7762 3.18073 15.5418 3.52786 16.2361C3.875 16.9303 4.37902 17.5343 5 18V26C5 26.5304 5.21071 27.0391 5.58579 27.4142C5.96086 27.7893 6.46957 28 7 28H25C25.5304 28 26.0391 27.7893 26.4142 27.4142C26.7893 27.0391 27 26.5304 27 26V18C27.621 17.5343 28.125 16.9303 28.4721 16.2361C28.8193 15.5418 29 14.7762 29 14V11.975ZM5 13H11V14C11.0005 14.516 10.8678 15.0233 10.6149 15.473C10.3621 15.9228 9.99746 16.2997 9.55636 16.5674C9.11527 16.835 8.6126 16.9844 8.09691 17.0011C7.58123 17.0177 7.06996 16.9011 6.6125 16.6625C6.54335 16.6077 6.4678 16.5615 6.3875 16.525C5.96211 16.2552 5.61184 15.8823 5.36926 15.4408C5.12667 14.9994 4.99965 14.5037 5 14V13ZM19 14C19 14.7956 18.6839 15.5587 18.1213 16.1213C17.5587 16.6839 16.7956 17 16 17C15.2044 17 14.4413 16.6839 13.8787 16.1213C13.3161 15.5587 13 14.7956 13 14V13H19V14ZM25.6125 16.525C25.5322 16.5615 25.4566 16.6077 25.3875 16.6625C24.93 16.9011 24.4188 17.0177 23.9031 17.0011C23.3874 16.9844 22.8847 16.835 22.4436 16.5674C22.0025 16.2997 21.6379 15.9228 21.3851 15.473C21.1322 15.0233 20.9995 14.516 21 14V13H27V14C27.0003 14.5037 26.8733 14.9994 26.6307 15.4408C26.3882 15.8823 26.0379 16.2552 25.6125 16.525Z" />
    </svg>
  );
}

export default RestaurantIcon;
