import React from "react";
import {
  UserList,
  Notifications,
  LockSimple,
  Users,
} from "../../../../../common/Icons";
import useSettingSideBar from "./useSettingSideBar";
import { DefaultImg } from "../../../../../../assets/images";
import { ROLE } from "../../../../../../enums/role";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = ({ setActiveComponent, activeComponent }) => {
  const { userName, profilePic } = useSettingSideBar();
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);

  const handleItemClick = (itemName) => {
    setActiveComponent(itemName);
  };

  const getNavigation = () => {
    const baseNavigation = [
      { name: "Profile", icon: UserList },
      { name: "Notifications", icon: Notifications },
      { name: "Password", icon: LockSimple },
    ];

    if (storedUserData?.user?.role === ROLE.SUPER_ADMIN) {
      baseNavigation.splice(1, 0, { name: "Users", icon: Users });
    }

    return baseNavigation;
  };

  const navigation = getNavigation();

  return (
    <>
      <div className="flex  w-[260px] flex-col gap-y-2 overflow-y-auto rounded-lg bg-neutral-200 backdrop-blur-1xl px-4 pb-4">
        <div className="flex py-3 shrink-0 items-center border-b border-neutral-200 -mx-2 mt-2">
          <div className="flex items-center gap-1  border-b border-gray-200">
            <img
              src={profilePic || DefaultImg}
              alt="User Profile"
              className="w-10 h-10 rounded-full"
            />{" "}
            <div>
              <div className="text-sm font-medium text-gray-800 pl-1">
                {userName}{" "}
              </div>
              <div className="text-xs font-normal text-gray-500 pl-1">
                (Tag Management)
              </div>
            </div>
            <span className="flex-shrink-0 ml-auto gap-2 -mt-4 bg-green-600 w-2.5 h-2.5 rounded-full"></span>
          </div>
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-2">
            {navigation?.map((item) => (
              <li key={item.name}>
                <button
                  type="button"
                  onClick={() => handleItemClick(item.name)}
                  className={classNames(
                    activeComponent === item.name
                      ? "bg-white text-text-link shadow-sm"
                      : "text-text-secondary hover:text-text-link hover:bg-white",
                    "group flex items-center gap-x-2 rounded-md py-2 px-3 text-sm leading-6 font-semibold w-full text-left"
                  )}
                >
                  <item.icon
                    className="flex-shrink-0 h-6 w-6"
                    stroke={
                      activeComponent === item.name ? "#0086FF" : "#667085"
                    }
                  />
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
