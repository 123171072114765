import { createSlice } from "@reduxjs/toolkit";

/**
 * Modal Slice for managing modal state.
 *
 * @typedef {Object} PaymentsModalSlice - State object representing the payments modal properties.
 * @property {boolean} isModalOpen - Flag indicating whether the payments modal is open or closed.
 * @property {JSX.Element} content - JSX content to be displayed in the payments modal.
 * @property {string} title - Title for the payments modal.
 * @property {string} type - Type of the payments modal.
 * @property {boolean} editable - Flag indicating whether the payments modal content is editable.
 *
 * @param {PaymentsModalSlice} state - Current state of the paymentsModal.
 * @param {Object} action - Action object to modify the paymentsModal state.
 * @param {string} action.type - Type of action.
 * @param {Object} action.payload - Payload data for the action.
 * @returns {void} No return value. Modifies the state based on the action.
 */

const paymentsModalSlice = createSlice({
  name: "paymentsModal",
  initialState: {
    isModalOpen: false,
    title: "",
    activeStep: 1,
    type: null,
    editable: false,
    onClose: null,
    selectedOption: "POINTS",
  },
  reducers: {
    openModal: (state, action) => {
      const { title, activeStep, type, editable } = action.payload;
      state.isModalOpen = true;
      state.content = action.payload;
      state.title = title || state.selectedOption || "";
      state.activeStep = activeStep || state.activeStep || 1;
      state.type = type || null;
      state.editable = editable || false;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
      state.content = null;
      state.title = "";
      state.activeStep = 1;
      state.type = null;
      state.editable = false;
      state.selectedOption = "POINTS";
    },
    setSelectedOption: (state, action) => {
      const selectedOption = action.payload;
      state.selectedOption = selectedOption;
    },
    updateTitle: (state, action) => {
      const title = action.payload;
      state.title = title;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
  },
});

export const {
  openModal,
  closeModal,
  setSelectedOption,
  updateTitle,
  setActiveStep,
  activeStep,
  selectedOption,
  type,
  title,
  isModalOpen,
} = paymentsModalSlice.actions;
export default paymentsModalSlice.reducer;

// Sample Usage:
// import { openModal, closeModal } from "path/to/paymentsModalSlice";
//
// const dispatch = useDispatch();
//
//  Open the modal
// dispatch(openModal({
//   title: "Edit User",
//   type: "edit",
//   editable: true,
//   handleEdit: handleEditFunction,
//   onClose: handleCloseFunction,
//    Other modal content...
// }));
//
// Close the modal
// dispatch(closeModal());
