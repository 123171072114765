import { z } from "zod";

const validateCreditCardNumber = (number) => {
  // Remove all spaces from the input
  const trimmed = number.replace(/\s+/g, "");

  // Check for valid length
  if (trimmed.length < 13 || trimmed.length > 19) return false;

  // Perform Luhn algorithm check
  let sum = 0;
  let shouldDouble = false;
  for (let i = trimmed.length - 1; i >= 0; i--) {
    let digit = parseInt(trimmed.charAt(i));
    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
};

const validateExpiryDate = (expiry) => {
  if (!expiry.match(/^\d{2}\/\d{2}$/)) return false;
  const [month, year] = expiry.split("/").map(Number);
  const currentYear = new Date().getFullYear() % 100; // get last two digits of the current year
  const currentMonth = new Date().getMonth() + 1; // get the current month (0-indexed)
  if (month < 1 || month > 12) return false;
  if (year < currentYear || (year === currentYear && month < currentMonth))
    return false;
  return true;
};

const validateCvv = (cvv) => /^\d{3,4}$/.test(cvv);

export const setPlanSchema = z.object({
  card: z
    .string()
    .min(15, { message: "Card number must be at least 15 digits long!" })
    .max(19, { message: "Card number must be no more than 19 digits long!" })
    .refine(validateCreditCardNumber, {
      message: "Invalid credit card number!",
    }),
  expiry: z.string().refine(validateExpiryDate, {
    message: "Invalid expiry date!",
  }),
  cvv: z.string().refine(validateCvv, {
    message: "Invalid CVV!",
  }),
  discount: z.string().optional(),
});
