// Setting.jsx
import React, { useState } from "react";
import PosUpdateProfile from "./PosUpdateProfile/PosUpdateProfile";
import PosSettingLayout from "./PosSettingLayout/PosSettingLayout";
import CustomAdminModel from "../../common/components/AdminModal/CustomAdminModal";
import { useTranslation } from "react-i18next";

const Setting = ({ isModalOpen, setModalOpen }) => {
  const [activeComponent, setActiveComponent] = useState("General");
  const { t } = useTranslation();

  return (
    <CustomAdminModel
      title={t("pos_dashboard.settings")}
      isOpen={isModalOpen}
      width="min-[280px]:w-[80%] min-[1080px]:w-[800px]"
      onClose={() => setModalOpen(false)}
      className="modal-custom-size"
      showFooter={false}
    >
      <div className="flex overflow-hidden">
        <PosSettingLayout
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
        >
          {activeComponent === t("pos_dashboard.general") && (
            <PosUpdateProfile setModalOpen={setModalOpen} />
          )}

          {/* Add other components here */}
        </PosSettingLayout>
      </div>
    </CustomAdminModel>
  );
};

export default Setting;
