import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    lng: "en", // Default language
    resources: {
      en: {
        //Wattoo
        translation: {
          landingpage: {
            features: {
              feature: "Features",
              text: "Unlock possibilities with a user-friendly interface and cutting-edge functionality.",
              inventory: "Inventory",
              invoices: "Invoices",
              directory: "Directory",
              report: "Report",
              invtrf: "Inventory Transfer",
              int1: "Track and record the movement of inventory between different locations or stores.",
              int2: "Automated updating of stock levels across locations upon transfer.",
              int3: "Generate transfer reports for accountability and record-keeping.",
              stocktaking: "Stocktaking",
              stk1: "Facilitate periodic physical counts of inventory to ensure accuracy.",
              stk2: "Compare physical counts with system records to identify discrepancies.",
              stk3: "Update inventory levels based on actual stocktaking results.",
              inventorytransaction: "Inventory Transaction",
              invtrf1:
                "Monitor all inventory-related transactions including sales, purchases, and returns.",
              invtrf2:
                "Real-time updating of inventory levels after each transaction.",
              invtrf3: "Detailed transaction history for each inventory item.",
            },

            invoices: {
              salesinvoices: "Sales Invoices",
              salesinvoices1: "Create and manage sales invoices with ease.",
              salesinvoices2:
                "Track payments, partial payments, and overdue invoices.",
              salesinvoices3:
                "Customize invoice templates for branding purposes.",
              purchaseinvoices: "Purchase Invoices",
              purchaseinvoices1:
                "Record and manage purchase orders and invoices from suppliers.",
              purchaseinvoices2: "Track payment statuses and due dates.",
              purchaseinvoices3:
                "Integration with inventory for automatic stock level updates upon purchase.",
              expenseinvoices: "Expense Invoices",
              expenseinvoices1: "Log and categorize business expenses.",
              expenseinvoices2:
                "Manage recurring expenses and one-time payments.",
              expenseinvoices3:
                "Attach supporting documents to expense records.",
            },
            faqsHeader: {
              tagline:
                "Have questions? We've got answers. Check out our list of frequently asked questions to find the information you need.",
              title: "Frequently Asked Questions",
            },
            invoiceInventoryManagement: {
              question1:
                "How do I manage sales expenses and purchase invoices?",
              answer1:
                "as TagDev offers a Simple Accounting system to help you calculate your costs and expenses quickly and accurately. Invoices can be managed through the 'Sales Invoices' and 'Purchase Invoices' and 'Expenses Invoices' sections, where you can view, create, edit, delete, and search for invoices. You also have options like showing details, printing, duplicating, and processing returns",

              question2:
                "How can I handle inventory transfers and stocktaking?",
              answer2:
                "Go to the 'Inventory Transfer' and 'Stocktaking' sections to manage these aspects. You can add, edit, delete, and search for transfers or stocktaking records, and access detailed options.",
            },
            reportsAnalytics: {
              question: "How do I access different types of business reports?",
              answer:
                "Navigate to the Reports section to access and export various reports such as sales, purchases, taxes, profit & loss, expenses, and inventory. You can apply filters and select dates for customized reporting",
            },

            userStoreManagement: {
              question: "How do I manage user roles and store details?",
              answer:
                "In the 'Manage Users' section, you can add, edit, delete, and search for users, as well as manage their roles and permissions. For store management, use the 'Manage Stores' section to add, edit, and manage store details.",
            },

            promotionsSettings: {
              question1: "How can I set up promotions for my products?",
              answer1:
                "Use the 'Manage Promotions' section to create and edit promotions. You can set up promotions for selected products, all products, and define the discount as percentages or fixed amounts.",

              question2: "How do I change general settings and manage devices?",
              answer2:
                "In the 'General Settings' section, you can view and edit various settings, including managing connected devices and configuring new ones",
            },

            subscriptionBilling: {
              question1: "How do I set or change my subscription plan?",
              answer1:
                "To set or change your subscription plan, go to Subscription Settings, choose your plan, add any additional branches if needed, select your payment method, and confirm your subscription.",

              question2: "Can I add multiple branches to my subscription?",
              answer2:
                "Yes, you can add additional branches to your subscription plan during the setup or modification of your subscription.",
            },
            dashboardAnalytics: {
              question1: "How can I view analytics for specific dates?",
              answer1:
                "On the dashboard, use the calendar feature to select and view analytics for your desired dates or store or Casher or more",

              question2:
                "What kind of sales analytics can I view on the dashboard?",
              answer2:
                "The dashboard provides an overview of sales, costs, net income, expenses, and sales categorized by items, categories, stores, cashiers, and sales forecasts.",
            },

            directory: {
              customerdirectory: "Customer Directory",
              customerdirectory1:
                "Maintain a detailed database of customer information.",
              customerdirectory2:
                "Track customer purchase history and preferences.",
              customerdirectory3:
                "Segment customers for targeted marketing and promotions.",
              supplierdirectory: "Supplier Directory",
              supplierdirectory1:
                "Manage supplier contact information and transaction history.",
              supplierdirectory2:
                "Evaluate supplier performance based on delivery times, prices, and quality.",
              itemsdirectory: "Items Directory",
              itemsdirectory1:
                "Catalogue all items with descriptions, images, and barcodes.",
              itemsdirectory2:
                "Define item categories for easy management and reporting.",
              categorydirectory:
                "Category Directory (Simple Item, Variable Item, Compositing Item, & Raw Item)",
              categorydirectory1:
                "Organize inventory items into logical categories.",
              categorydirectory2:
                "Customizable categories to suit different types of merchandise.",
              categorydirectory3: "Analyze sales and stock levels by category.",
            },
            general: {
              question1: "How do I sign up for the POS system?",
              answer1:
                "You can sign up using Google, Facebook, or email. Just follow the instructions to agree to the Terms of Use and Privacy Policy and confirm your email.",

              question2:
                "What should I do if I haven't received my confirmation code?",
              answer2:
                "If you don't receive a confirmation code in your email, you can request to resend the code through the sign-up interface.",

              question3: "How do I complete my business profile?",
              answer3:
                "After creating your account and password, you can complete your business profile by uploading a picture and providing the necessary business details.",

              question4:
                "How do I manage messages within the Dashboard system?",
              answer4:
                "You can manage your messages by navigating to the Messages section, where you can create new messages, view, send, search, and delete messages. You can also add attachments to your messages.",

              question5:
                "Can I transfer my business data (Item) from my current platform into TagDev platform?",
              answer5:
                "Yes, Account managers will take care of transferring all items from your old system to your new system.",

              question6: "Can I check the platform from my phone?",
              answer6:
                "Yes, you can download the TagDev Application from the Appstore or Google Play Store.",

              question7:
                "Does TagDev provide cashier devices and barcode readers?",
              answer7:
                "Yes, we provide devices at a lower price than the market price.",

              question8:
                "Does TagDev comply with Zakat, Tax, and Customs Authority?",
              answer8: "Yes.",

              question9: "Are TagDev platform updates paid?",
              answer9:
                "All updates are free and automatically set up on TagDev subscribers’ systems.",

              question10:
                "Can I print if the system is not connected to the Internet?",
              answer10:
                "Printing is possible if the POS and printer are connected to the same local network (Wi-Fi/Ethernet).",
            },

            reports: {
              salesreports: "Sales Reports",
              salesreports1:
                "Detailed analysis of sales performance over time.",
              salesreports2:
                "Breakdown by item, category, cashier, and time period.",
              salesreports3: "Identify best-selling products.",
              purchasereports: "Purchase Reports",
              purchasereports1: "Track purchasing trends and expenses.",
              purchasereports2:
                "Forecast future inventory needs based on historical data.",
              taxesreports: "Taxes Reports",
              taxesreports1: "Automate tax calculations based on sales data.",
              taxesreports2: "Generate tax reports for compliance and filing.",
              profitlossreports: "Profit & Loss Reports",
              profitlossreports1:
                "Comprehensive overview of revenues, costs, and expenses.",
              profitlossreports2:
                "Identify profitable and unprofitable areas of the business.",
              profitlossreports3:
                "Periodic comparison for financial health assessment.",
              expensereports: "Expense Reports",
              expensereports1: "Categorize and track all business expenses.",
              expensereports2:
                "Monitor spending patterns and identify cost-saving opportunities.",
              expensereports3:
                "Facilitate budget management and financial planning.",
              inventoryreports: "Inventory Reports",
              inventoryreports1:
                "Monitor stock levels, turnover rates, and order history.",
              inventoryreports2: "Reports on stock aging and shelf life.",
              inventoryreports3:
                "Analyze inventory efficiency and optimize stock levels.",
            },

            buttons: {
              signin: "Sign In",
              getstarted: "Get Started",
              letstalk: "Let's Talk",
              contactus: "Contact Us",
              contacttext:
                "Get in touch with us! We're here to assist you. Whether you have questions, feedback, or need support, our team is ready to help.",
              information: "Contact Information",
              name: "Name",
              entername: "Enter Name",
              storeName: "Store Name",
              enterstore: "Enter Store Name",
              phone: "Phone Number",
              enterphone: "Enter Phone Number",
              bussniess: "What is your business type",
              bussniessholder: "Select Bussniess type",
              message: "Message",
              messagetype: "Enter Message",
              sendmessage: "Send Message",
              fullymanaged: "Fully Managed Your Store.",
              pointsale: "Cloud point of sale",
              dashboardanaltics: "Dashboard analytics overview",
              items: "Items & category directory (simple & variable item)",
              invoices: "Unlimited invoices",
              sales: "Sales invoices management",
              customer: "Customer management",
              advacne: "Advance reporting",
              user: "User management (1 user only)",
              support: "24/7 support",
              everything: "Everything in Basic Plan",
              category:
                "Items & category directory (simple, Raw, Compositing, & Variable item)",
              stocking: "Stocking management",
              promotions: "Promotions",
              management: "User management (1 user only)",
              inventory: "Inventory transaction management",
              table: "Table management",
              pols: "Loyalty points",
              ai: "AI report",
              addmore: "Add more branch",
              account: "General Account Management",
              billing: "Subscription and Billing",
              store_management: "Store Management: 1 Store only ",
              everything_in_professional_plan:
                "  Everything in Professional Plan  ",
              users_management: "Users Management",
              choose_plan: "Choose Plan",
              basic_plan: "Basic Plan",
              enterprise_plan: "Enterprise Plan",
              professional_plan: "Professional Plan",
              popular: "Popular",
              ar: "The World's Best POS System, With Fast & Friendly Support",
              plan: "Choose the Plan",
            },

            navbar: {
              features: "Features",
              pricing: "Pricing",
              buisnesstype: "Business Type",
              faq: "FAQ",
              contactus: "Contact Us",
            },
            hero: {
              heading: "Work With The Best POS System For Your Business",
              text: "Overcome all challenges in the retail sector with TAG Dev, offering customized technology solutions to improve the performance of various types of businesses.",
            },
            oneplace: {
              heading: "Manage Everything in One Place",
              text: "Your All-in-One POS Solution – Elevate your retail efficiency and growth",
            },

            text: "“More than a POS system – it's the foundation of your business's success in the competitive retail world”",
            tabssection: {
              heading:
                "“More than a POS system – it's the foundation of your business's success in the competitive retail world”",
              words:
                "Optimizing Operation ...,Growth ...,POS Technology ...,Simplify Sales ...,Manage Inventory ...",
              tabs: {
                securecloud: {
                  title: "Secure Cloud System",
                  heading: "Secure Cloud System",
                  text: "Say goodbye to bulky devices, maintenance hassles, and data backup fees. Leave it all for the TagDev team; we store your data in the cloud to keep it safe and available, allowing you access anywhere, anytime, and from any device.",
                },

                offlineoperation: {
                  title: "Continuous Operation Offline",
                  heading: "Continuous Operations Even When Offline",
                  text: "In the offline mode from TagDev, your business continues even when the internet goes down. Never worry and keep receiving your customers' orders, these orders will be synced automatically when the POS (Point of Sale) goes online again.",
                },

                aidata: {
                  title: "AI Data Analysis",
                  heading: "AI Data Analysis",
                  text: "The best business decisions come from accurate reporting and sound data analysis. TagDev provides you with AI important performance reports that enable you to track your sales and get valuable information that helps you in making informed decisions.",
                },
                continuousdevelopment: {
                  title: "Continuous Development",
                  heading: "Continuous Development",
                  text: "Nothing stops our constant desire to develop and improve. We guarantee you the continuous updating of your system. We listen to our customers and develop our system in line with their needs and aspirations.",
                },
                viaphone: {
                  title: "Manage via Phone",
                  heading: "Manage Business Via Phon",
                  text: "Easily track sales, manage inventory, report daily and add items—all from your phone.",
                },
              },
            },
            whytagdev: {
              heading: "Why TagDev?",
              text: "Your All-in-One POS Solution – Elevate your retail efficiency and growth",
              cards: {
                one: {
                  heading: "24/7 Support",
                  text: "Phone, live Chat, and email support at no cost.",
                },
                two: {
                  heading: "No Hidden Cost",
                  text: "Don’t worry about hidden cost because no price hikes either.",
                },
                three: {
                  heading: "Free Data Migration",
                  text: "Switching platforms? Our team will get you set up.",
                },
              },
            },
            footer: {
              heading: "Grow Your Business With TagDev",
              text: "Contact us to try the system no payment is required until i’ts trial period expires",
              links: {
                lettalk: "Let's talk",
                getstarted: "Get Started",
                businesstype: "Business Type",
                restaurant: "Restaurant",
                coffeeshop: "Coffee Shop",
                shop: "Shop",
                supermarket: "Supermarket",
                foodtruck: "Food Trucks",
                giftshop: "Gift Shops",
                hairdresser: "Hairdressers",
                carservice: "Car Services",
                expressservices: "Express Services",
                company: "Company",
                about: "About Us",
                careers: "Careers",
                faq: "FAQ",
                link: "Links",
                features: "Features",
                pricing: "Pricing",
                contactus: "Contact Us",
                legal: "Legal",
                terms: "Terms of Service",
                privacy: "Privacy and Policy",
                downloadpos: "Download Our POS App",
                downloaddashboard: "Download Our Dashboard App",
                copyright: "Copyright © 2023 TagDev. All rights reserved.",
                workwith: "Work with",
                thebest: "The Best POS System ",
                possystem: "For Your Business",
                overcome:
                  "Overcome all challenges in the retail sector with TAG Dev, offering customized technology solutions to improve the performance of varioustypes of businesses",
                growth:
                  " Your All-in-One POS Solution – Elevate your retail efficiency and growth",
                manage: "Manage Everything",
                oneplace: "in One Place",
                optimize: "Optimizing Operation ...",
                grow: "Growth ...",
                technology: "POS Technology ...",
                sales: "Simplify Sales ...",
                inventory: "Manage Inventory ...",
                personal: "Tag Dev Your Personal",
                assistant: "Assistant To ",
                secure: "Secured Cloud System",
                operation: "Continuous Operation Offline",
                data: "AI Data Analysis",
                developemnt: "Continuous Development",
                phone: "Manage via Phone",
                securetext:
                  "Say goodbye to bulky devices, maintenance hassles, and data backup fees. Leave it all for the TagDev team; we store your data in the cloud to keep it safe and available, allowing you access anywhere, anytime, and from any device",
                operationheading: "Continuous Operations Even When Offline",
                operationtext:
                  "In the offline mode from TagDev, your business continues even when the internet goes down. Never worry and keep receiving your customers' orders, these orders will be synced automatically when the POS (Point of Sale) goes online again",
                datatext:
                  "The best business decisions come from accurate reporting and sound data analysis. TagDev provides you with AI important performance reports that enable you to track your sales and get valuable information that helps you in making informed decisions.",
                developemnttext:
                  "Nothing stops our constant desire to develop and improve. We guarantee you the continuous updating of your system. We listen to our customers and develop our system in line with their needs and aspirations.",
                phoneheading: "Manage Business Via Phone",
                phonetext:
                  "Easily track sales, manage inventory, report daily and add items—all from your phone.",
                headingone:
                  "“More than a POS system – it's the foundation of your",
                headingtwo: "business's success",
                headingthree: "in the competitive retail world”",
              },
            },
            bar: {
              signIn: "Sign In to Dashboard",
              pos: "Sign In to POS",
              postext:
                "Start your dealings with your customers through our point of sale platform",
              signIntext:
                "Start managing and controlling your branches through our platform",
              readmore: "Read More",
            },
          },
          modal: {
            add: "Add",
            edit: "Edit",
            delete: "Delete",
            cancel: "Cancel",
          },

          buttons: {
            add: "Add",
            save: "Save",
            export: "Export",
            signup_with_google: "Signup with Google",
            signup_with_facebook: "Signup with Facebook",
            or: "or",
            continue: "Continue",
            need_help: "Need Help?",
            save_changes: "Save Changes",
            cancel: "Cancel",
            reset: "Reset",
            delete: "Delete",
            select_language: "Select Language",
            dismiss: "Dismiss",
            signout: "Sign Out",
            send: "Send",
            show_details: "Show Details",
            edit: "Edit",
            duplicate: "Duplicate",
            print: "Print",
            return: "Return",
            drag_or_click: "Drag or click to upload",
            done: "Done",
            add_value: "Add value",
            delivered: "Delivered",
          },
          validations: {
            supported_format: "Supported format",
            unsupported_format:
              "Unsupported file format. Please select a JPG, JPEG, or PNG file.",
            file_size: "File is too large. Max size allowed is 2MB.",
          },
          login: {
            title: "Login",
            welcome: "Welcome Back! Sign in to access your account",
            login_with_google: "Login with Google",
            login_with_facebook: "Login with Facebook",
            or: "Or",
            email: "Email",
            email_placeholder: "Enter email",
            password: "Password",
            password_placeholder: "Enter password",
            remember_me: "Remember me",
            forgot_password: "Forgot password?",
            account_confirmation: "Don't have an account?",
            validations: {
              email_error: "Email is required!",
              email_error_invalid: "Please use a valid email address!",
              password_error: "Password is required!",
              password_error_min: "Password must be at least 8 characters long",
              password_error_uppercase:
                "Password must contain at least one uppercase letter",
              password_error_special_char:
                "Password must contain at least one special character",
              password_error_alphabet:
                "Password must contain at least one alphabet character",
            },
          },
          recover_account: {
            name: "Recover Account",
            description:
              "Enter the email address associated with your account and we'll send you a link to reset your password.",
            email: "Email",
            enter_email: "Enter Email",
          },
          signup: {
            title: "Sign Up",
            welcome: "Get started using TagDev",
            or: "or",
            email: "Email",
            email_placeholder: "Enter Email",
            already_have_account: "Already have an account?",
            agreement: "By creating an account, you agree to our",
            terms_and_conditions: "Terms and Conditions",
            privacy_notice: "Privacy Notice",
            and: "and",
          },
          confirm_email: {
            title: "Confirm Your Email",
            instructions:
              "We've sent an email with a code to {{email}}, please enter it below to create your account.",
            didnt_receive_code: "Didn’t receive the code?",
            resend_code: "Resend Code",
            image_heading: "Maximize Your Sales Momentum",
            image_description:
              "Elevate Your Business and Maximize Your Revenue Potential. Get Started Today!",
          },
          create_password: {
            title: "Create Your Password",
          },
          create_password_form: {
            new_password: "New Password",
            confirm_password: "Confirm Password",
            enter_new_password: "Enter New Password",
            enter_password_again: "Enter Password Again",
            enter_current_password: "Enter Current Password",
            use_at_least_8_characters: "Use at least 8 characters",
            use_mix_of_letters_numbers_special_chars:
              "Use a mix of letters, numbers, and special characters (e.g.: #$%)",
            create_password: "Create Password",
            current_password: "Current Password",
            save_changes: "Save Changes",
            email: " Email",
            enter_email: " Enter Email",
          },
          profile_layout: {
            start_create_account: "Start Creating Your Account",
            your_email: "Your Email",
          },
          stepper: {
            create_password: "Create Password",
            complete_profile: "Complete Profile",
            complete_business_profile: "Complete Business Profile",
            setup_your_plan: "Setup Your Plan",
          },
          create_account: {
            complete_profile: "Complete Profile",
            heading: "Complete Profile",
            upload_picture: "Upload Picture",
            change_picture: "Change Picture",
            fullname: "Full Name",
            enter_name: "Enter Full Name",
            arabicname: "Arabic Name",
            enter_arabic_name: "Enter Arabic Name",
            phonenumber: "Phone Number",
            enter_phone_number: "Enter Phone Number",
            continue: "Continue",
            image_upload_error: "Error uploading image",
            image_upload_info:
              "*png, .jpeg files up to 2 MB, at least 400px by 400px",
          },
          business_profile: {
            complete_business_profile: "Complete Business Profile",
            heading: "Complete Business Profile",
            company_info: "Company Info",
            company_name: "Company Name",
            enter_company_name: "Enter Company Name",
            company_address: "Company Address",
            enter_company_address: "Enter Company Address",
            website: "Website",
            commercial_register: "Commercial Register",
            enter_commercial_register: "Enter Commercial Register",
            vat_number: "VAT Number",
            enter_vat_number: "Enter VAT Number",
            business_type: "Type of your business",
            select_business_type: "Select Business Type",
            continue: "Continue",
            coffee_shop: "Coffee shop",
            restaurant: "Restaurant",
            shop: "Shop",
            super_market: "Super Market",
            food_truck: "Food Truck",
            gift_shop: "Gift Shop",
            hair_dressers: "Hair Dressers",
            car_services: "Car Services",
            express_services: "Express Services",
            delete_and_start: "Delete Data and Start Over",
            invoice_template: "Invoice Template",
            edit_template: "Edit Template of Invoice",
          },
          client_dashboard: {
            overview: "Overview",
            messages: "Messages",
            invoices: {
              name: "Invoices",
              sales: {
                name: "Sales",
                create_invoice: "Create Invoice",
                customer: "Customer",
                add_new_customer: "Add New Customer",
                add_customer: "Add Customer",
                select_customer: "Select Customer",
                store: "Store",
                select_store: "Select Store",
                sale_date: "Sale Date",
                payment_method: "Payment Method",
                select_payment_method: "Select Payment Method",
                item_details: "Item Details",
                item: "Item",
                select_item: "Select Item",
                available_quantity: "Available Quantity",
                quantity: "Quantity",
                enter_quantity: "Enter Quantity",
                unit_price: "Unit Price",
                before_vat: "Before VAT",
                vat_amount: "VAT Amount",
                total: "Total",
                note: "Note",
                enter_note: "Enter Note",
                tabs: {
                  all: "All",
                  paid: "Paid",
                  pos_transaction: "POS Transaction",
                },
                validations: {
                  customer_error: "Customer is required!",
                  store_error: "Store is required!",
                  date_error: "Date is required!",
                  paymentmethod_error: "Payment Method is required!",
                  item_error: "Item is required!",
                  quantity_error: "Quantity is required!",
                  unitprice_error: "Unit Price is required!",
                  beforetax_error: "Before Tax is required!",
                  taxamount_error: "Tax Amount is required!",
                  subtotal_error: "Subtotal is required!",
                  note_error: "Note is required!",
                },
              },
              purchase: {
                name: "Purchase",
                create_invoice: "Create Invoice",
                supplier: "Supplier",
                add_new_supplier: "Add New Supplier",
                add_supplier: "Add Supplier",
                choose_supplier: "Choose Supplier",
                store: "Store",
                select_store: "Select Store",
                supply_date: "Supply Date",
                payment_method: "Payment Method",
                select_payment_method: "Select Payment Method",
                payment_status: "Payment Status",
                select_payment_status: "Select Payment Status",
                item_details: "Item Details",
                item: "Item",
                select_item: "Select Item",
                available_quantity: "Available Quantity",
                quantity: "Quantity",
                enter_quantity: "Enter Quantity",
                unit_price: "Unit Price",
                before_tax: "Before Tax",
                tax_amount: "Tax Amount",
                subtotal: "Subtotal",
                note: "Note",
                enter_note: "Enter Note",
                tabs: {
                  all: "All",
                  fully_paid: "Fully Paid",
                  partially_paid: "Partially Paid",
                },
                validations: {
                  supplierid_error: "Supplier is required!",
                  store_error: "Store is required!",
                  date_error: "Date is required!",
                  paymentmethod_error: "Payment Method is required!",
                  paymentStatus_error: "Payment Status is required!",
                  item_error: "Item is required!",
                  quantity_error: "Quantity is required!",
                  quantity_error_max: "Maximum quantity allowed is 1,000,000!",
                  unitprice_error: "Unit Price is required!",
                  beforetax_error: "Before Tax is required!",
                  taxamount_error: "Tax Amount is required!",
                  subtotal_error: "Subtotal is required!",
                  note_error: "Note is required!",
                  only_numbers_error: "Only numbers are allowed!",
                },
              },
              expense: {
                name: "Expense",
                create_invoice: "Create Invoice",
                expense_name: "Expense Name",
                enter_expense_name: "Enter Expense Name",
                invoice_no: "Invoice Number",
                enter_invoice_no: "Enter Invoice Number",
                store: "Store",
                select_store: "Select Store",
                supply_date: "Supply Date",
                payment_method: "Payment Method",
                select_payment_method: "Select Payment Method",
                expense_details: "Expense Details",
                expense_category: "Expense Category",
                select_expense_category: "Select Expense Category",
                amount: "Amount",
                enter_amount: "Enter Amount",
                before_tax: "Before Tax",
                enter_before_tax_price: "Enter Before Tax Price",
                tax_amount: "Tax Amount",
                enter_tax_amount: "Enter Tax Amount",
                total: "Total",
                enter_total_amount: "Enter Total Amount",
                note: "Note",
                enter_note: "Enter Note",
                sure: "Are you sure you want to delete this invoice?",
                validations: {
                  expensename_error: "Expense Name is required!",
                  expensename_error_min:
                    "Expense Name must be at least 3 characters long",
                  expensename_error_max:
                    "Expense Name must be no longer than 100 characters",
                  invoicenumber_error: "Invoice Number is required!",
                  invoicenumber_error_invalid: "Only numbers are allowed!",
                  invoicenumber_error_min:
                    "Invoice Number must be at least 3 digits long!",
                  max_number_error: "Maximum number allowed is 1,000,000!",
                  store_error: "Store is required!",
                  date_error: "Date is required!",
                  paymentmethod_error: "Payment Method is required!",
                  expensecategory_error: "Expense Category is required!",
                  amount_error: "Amount is required!",
                  beforetax_error: "Before Tax is required!",
                  taxamount_error: "Tax Amount is required!",
                  total_error: "Total is required!",
                  note_error: "",
                },
              },
            },
            inventory: {
              name: "Inventory",
              inventory_transfer: {
                name: "Inventory Transfer",
                sure: "Are you sure you want to delete this inventory transfer?",
                add_inventory: "Add Transfer",
                add_more: "Add More Item",
                storefrom: "Store (From)",
                select_storefrom: "Select Store(from)",
                storeto: "Store (To)",
                select_storeto: "Select Store(to)",
                date: "Date",
                item: "Item",
                select_item: "Select Item",
                unitmeasure: "Unit of Measure",
                quantity: "Quantity",
                enter_quantity: "Enter Quantity",
                total_quantity: "Total quantity",
                number_unit: "Number Unit of Measure",
                enter_number_unit: "Enter Number Unit Of Measure",
                description: "Description",
                enter_description: "Enter Description",
                validations: {
                  storeto_error: "Store to is required!",
                  storefrom_error: "Store from is required!",
                  date_error: "Date is required!",
                  itemid_error: "Item is required!",
                  unitmeasure_error: "Unit measure is required!",
                  numberunit_error: "Number of units is required!",
                  quantity_error: "Quantity is required!",
                  description_error: "Description is required!",
                },
              },
              stocktaking: {
                name: "Stocktaking",
                sure: "Are you sure you want to delete this inventory transfer?",
                create_stocktaking: "Create Stocktaking",
                count_name: "Count Name",
                enter_count_name: "Enter Count Name",
                description: "Description",
                enter_description: "Enter Description",
                date: "Date",
                store: "Store",
                select_store: "Select Store",
                item_details: "Item Details",
                detail: "Detail",
                item: "Item",
                select_item: "Select Item",
                unitmeasure: "Total Number Unit of Measure",
                expected: "Expected",
                quantity: "Quantity",
                enter_quantity: "Enter Quantity",
                cost_price: "Cost Price",
                note: "Note",
                enter_note: "Enter Note",
                validations: {
                  countName_error: "Count Name is required!",
                  countName_error_max: "Maximum 100 characters are allowed!",
                  description_error: "Description is required!",
                  description_error_max: "Maximum 200 characters are allowed",
                  date_error: "Date is required!",
                  store_error: "Store is required!",
                  only_allowed: "Only numbers are allowed!",
                  item_error: "Item is required!",
                  expected_error: "Expected value must be a number!",
                  quantity_error: "Quantity is required!",
                  quantity_error_max: "Maximum quantity allowed is 1,000,000!",
                  costPrice_error: "Cost Price must be a number!",
                  note_error: "",
                },
              },
              inventory_transaction: {
                name: "Inventory Transaction",
                inventory_transfer: "Inventory Transfer",
                stocktaking: "Stocktaking",
                sale_invoices: "Sale Invoices",
                purchase_invoices: "Purchase Invoices",
              },
            },
            directory: {
              name: "Directory",
              customer: {
                name: "Customer",
                add_customer: "Add Customer",
                customer_name: "Customer Name",
                enter_name: "Enter Customer Name",
                email: "Email",
                enter_email: "Enter Email",
                phone_number: "Phone Number",
                enter_phone: "Enter Phone Number",
                select_gender: "Select Gender",
                male: "Male",
                female: "Female",

                delete: "Customer deleted successfully!",
                sure: "Are you sure you want to delete this customer?",
                validations: {
                  name_error: "Full Name must be at least 3 characters long!",
                  name_error_max: "Maximum 100 characters are allowed!",
                  email_error: "Email address is required!",
                  email_error_valid: "Please use a valid email address!",
                  phone_error: "Phone number is required",
                  phone_format:
                    "Mobile number can only contain digits and may start with a '+'",
                  phone_format_plus:
                    "Mobile number cannot start with 0 after the '+'",
                  phone_min: "Mobile number must have at least 7 digits",
                  phone_max: "Mobile number cannot have more than 15 digits",
                  gender_error: "Gender is required!",
                },
              },
              supplier: {
                name: "Suppliers",
                sure: "Are you sure want to delete this supplier?",
                add_supplier: "Add Supplier",
                english_name: "English Name",
                company_name: "Company Name",
                arabic_name: "Arabic Name",
                enter_english_name: "Enter English Name",
                enter_company_name: "Enter Company Name",
                email: "Email",
                enter_email: "Enter Your Email",
                phone_number: "Phone Number",
                enter_address: "Address",
                enter_addreess: "Enter Your Address", // Note: This key is duplicated with a typo. Consider removing or correcting one.
                enter_phone: "Enter Your Phone Number",
                vat_number: "VAT Number",
                enter_vatnumber: "Enter VAT Number",
                delete: "The customer has been deleted successfully!",
                validations: {
                  englishname:
                    "The full name must be at least 3 characters long!",
                  name_error_max: "A maximum of 100 characters is allowed!",
                  email_error: "Email is required!",
                  email_error_valid: "Please use a valid email address!",
                  phone_error: "Phone number must start with '+'!",
                  phone_error_max: "Phone number must not exceed 15 digits!",
                  phone_error_regex:
                    "Phone number must start with + followed by numbers!",
                  error_address: "Address is required",
                  error_address_max: "Address must not exceed 100 characters!",
                  company_error: "Company name is required!",
                  company_error_max:
                    "Company name must not exceed 100 characters!",
                  vat_error:
                    "VAT number must consist of exactly 15 characters.",
                },
              },
              items: {
                name: "Items",
                sure: "Are you sure you want to delete this item?",
                simple_item: {
                  name: "Simple Item",
                  english_name: "English Name",
                  enter_english_name: "Enter Item Name",
                  arabic_name: "Arabic Name",
                  enter_arabic_name: "Enter Arabic Name",
                  description: "Description",
                  enter_description: "Enter Description",
                  select_store: "Select Store",
                  item_cost: "Item Cost",
                  enter_item_cost: "Enter Item Cost",
                  select_category: "Select Category",
                  sale_price: "Sale Price (without tax)",
                  enter_sale_price: "Enter Sale Price",
                  enter_barcode: "Enter Barcode",
                  create_barcode_num: "Generate Barcode Number",
                  validations: {
                    englishname_error: "English Name is required!",
                    englishname_error_min:
                      "English Name must be at least 3 characters long!",
                    englishname_error_max:
                      "English Name must be no longer than 100 characters!",
                    arabicname_error: "Arabic Name is required!",
                    arabicname_error_min:
                      "Arabic Name must be at least 3 characters long!",
                    arabicname_error_max:
                      "Arabic Name must be no longer than 100 characters!",
                    description_error: "Description is required!",
                    description_error_min:
                      "Description must be at least 3 characters long!",
                    description_error_max:
                      "Description must be no longer than 500 characters!",
                    store_error: "Store is required!",
                    itemcost_error: "Item cost is required!",
                    itemcost_error_min: "Item cost must be at least 1!",
                    itemcost_error_max: "Maximum number allowed is 1,000,000!",
                    category_error: "Category is required!",
                    saleprice_error: "Sale Price is required!",
                    saleprice_error_min: "Sale Price must be at least 1!",
                    saleprice_error_max: "Maximum number allowed is 1,000,000!",
                    number_only_error: "Only numbers are allowed!",
                  },
                },
                variable_item: {
                  name: "Variable Item",
                  item_name: "Item Name",
                  enter_item_name: "Enter Item Name",
                  arabic_name: "Arabic Name",
                  enter_arabic_name: "Item Arabic Name",
                  description: "Description",
                  enter_description: "Enter Item Description",
                  store: "Store",
                  select_store: "Select Store",
                  category: "Category",
                  select_category: "Select Category",
                  variables: "Variables",
                  variable: "Variable",
                  variable_name: "Variable Name",
                  select_variable_name: "Select Variable Name",
                  value: "Value",
                  enter_value: "Enter Value",
                  add_another_variable: "Add another Variable",
                  validations: {
                    englishname_required_error: "English Name is required!",
                    englishname_error_min:
                      "English Name must be at least 3 characters long!",
                    englishname_error_max:
                      "English Name must be no longer than 100 characters!",
                    arabicname_required_error: "Arabic Name is required!",
                    arabicname_error_min:
                      "Arabic Name must be at least 3 characters long!",
                    arabicname_error_max:
                      "Arabic Name must be no longer than 100 characters!",
                    description_required_error: "Description is required!",
                    description_error_min:
                      "Description must be at least 3 characters long!",
                    description_error_max:
                      "Description must be no longer than 500 characters!",
                    store_required_error: "Store is required!",
                    category_required_error: "Category is required!",
                    variablename_required_error: "Variable Name is required!",
                    value_required_error: "Value is required!",
                  },
                },
                compositing_item: {
                  name: "Compositing Item",
                  item_name: "Item Name",
                  arabic_item_name: "Arabic Item Name",
                  description: "Description",
                  select_store: "Select Store",
                  select_category: "Select Category",
                  sale_price_without_tax: "Sale Price (without tax)",
                  compositing_product: "Compositing Product",
                  raw_items: "Raw Items",
                  select_raw_items: "Select Raw Items",
                  unit_of_measure: "Unit of Measure",
                  per_unit_price: "Per Unit Price",
                  number_unit_of_measure: "Number Unit of Measure",
                  item_cost: "Item Cost",
                  total_cost: "Total Cost",
                  composition_label: "Composition",
                  add_another_composition: "Add Another Composition",
                  create_sku_number: "Create SKU Number",

                  enter_item_name: "Enter Item Name",
                  enter_arabic_item_name: "Enter Arabic Item Name",
                  enter_description: "Enter Description",
                  enter_sale_price: "Enter Sale Price",
                  enter_number_unit_of_measure: "Enter Number Unit of Measure",
                  validations: {
                    englishname_error: "English Name is required!",
                    englishname_error_min:
                      "English Name must be at least 3 characters long!",
                    englishname_error_max:
                      "English Name must be no longer than 100 characters!",
                    arabicname_error: "Arabic Name is required!",
                    arabicname_error_min:
                      "Arabic Name must be at least 3 characters long!",
                    arabicname_error_max:
                      "Arabic Name must be no longer than 100 characters!",
                    description_error: "Description is required!",
                    description_error_min:
                      "Description must be at least 3 characters long!",
                    description_error_max:
                      "Description must be no longer than 500 characters!",
                    store_error: "Store is required!",
                    category_error: "Category is required!",
                    saleprice_error: "Sale Price is required!",
                    saleprice_error_min: "Sale Price must be at least 1!",
                    saleprice_error_max: "Maximum number allowed is 1,000,000!",
                    totalcost_error: "Total cost is required!",
                    totalcost_error_min: "Total cost must be at least 1!",
                    totalcost_error_max: "Maximum number allowed is 1,000,000!",
                    itemcost_error: "Item cost is required!",
                    itemcost_error_min: "Item cost must be at least 1!",
                    itemcost_error_max: "Maximum number allowed is 1,000,000!",
                    rawitem_error: "Raw Item is required!",
                    unitmeasure_error: "Unit measure is optional",
                    perunitprice_error: "Per unit price is required!",
                    perunitprice_error_min:
                      "Per unit price must be at least 1!",
                    perunitprice_error_max:
                      "Maximum number allowed is 1,000,000!",
                    numberunit_error: "Number of unit is required!",
                    numberunit_error_min: "Number of unit must be at least 1!",
                    numberunit_error_max:
                      "Maximum number allowed is 1,000,000!",
                    number_only_error: "Only numbers are allowed!",
                  },
                },

                raw_item: {
                  name: "Raw Item",
                  item_name: "Item Name",
                  enter_item_name: "Enter Item Name",
                  arabic_item_name: "Arabic Item Name",
                  enter_arabic_item_name: "Enter Arabic Item Name",
                  description: "Description",
                  enter_description: "Enter Description",
                  select_store: "Select Store",
                  item_cost: "Item Cost",
                  enter_item_cost: "Enter Item Cost",
                  sale_price: "Sale Price (without tax)",
                  enter_sale_price: "Enter Sale Price",
                  select_category: "Select Category",
                  unit_of_measure: "Unit of Measure",
                  number_unit_of_measure: "Number Unit of Measure",
                  enter_number_unit_of_measure: "Enter Number Unit of Measure",
                  create_sku_number: "Create SKU Number",
                  validations: {
                    englishname_required_error: "English Name is required!",
                    englishname_error_min:
                      "English Name must be at least 3 characters long!",
                    englishname_error_max:
                      "English Name must be no longer than 100 characters!",
                    arabicname_required_error: "Arabic Name is required!",
                    arabicname_error_min:
                      "Arabic Name must be at least 3 characters long!",
                    arabicname_error_max:
                      "Arabic Name must be no longer than 100 characters!",
                    description_required_error: "Description is required!",
                    description_error_min:
                      "Description must be at least 3 characters long!",
                    description_error_max:
                      "Description must be no longer than 500 characters!",
                    store_error_min: "Store is required!",
                    itemcost_number_only_error: "Only numbers are allowed!",
                    itemcost_error_min: "Item cost is required!",
                    itemcost_error_max: "Maximum number allowed is 1,000,000!",
                    saleprice_number_only_error: "Only numbers are allowed!",
                    saleprice_error_min: "Sale Price is required!",
                    saleprice_error_max: "Maximum number allowed is 1,000,000!",
                    category_error_min: "Category is required!",
                    unitmeasure_error_min: "Unit of measure is required!",
                    numberunit_number_only_error: "Only numbers are allowed!",
                    numberunit_error_min: "Number of unit is required!",
                    numberunit_error_max:
                      "Maximum number allowed is 1,000,000!",
                  },
                },
              },
              category: {
                name: "Category",
                add_category: "Add Category",
                english_name: "English Name",
                arabic_name: "Arabic Name",
                enter_english_name: "Enter English Name",
                enter_arabic_name: "Enter Arabic Name",
                view_this_category: "View This Category in POS",
                sure: "Are you sure you want to delete this category?",
                validations: {
                  englishname_error:
                    "English name must be at least 3 characters long!",
                  englishname_error_max: "Maximum 100 characters are allowed!",
                },
              },
            },
            report: {
              name: "Report",
              sales: "Sales",
              purchase: "Purchase",
              taxes: "Taxes",
              profit_loss: "Profit & Loss",
              expenses: "Expense",
              inventory: "Inventory",
            },
            management: {
              name: "Management",
              setting: "Settings",
              users: {
                name: "Users",
                sure: "Are you sure you want to delete this user?",
                add_user: "Add User",
                user_name: "Name",
                enter_name: "Enter Name",
                email: "Email",
                enter_email: "Enter Email",
                create_password: "Create Password",
                enter_password: "Enter Password",
                code: "POS Fast Access Code",
                enter_code: "Enter Code",
                access_to_dashboard: "Access to Dashboard",
                access_to_pos: "Access to POS",
                store: "Store",
                select_store: "Select Store",
                select_role: "Select Role",
                roles: {
                  cashier: "Cashier",
                  sales_manager: "Sales Manager",
                  system_manager: "System Manager",
                },
                validations: {
                  name_error: "Name must be at least 3 characters long!",
                  name_error_max: "Maximum 100 characters are allowed!",
                  email_error: "Email address is required!",
                  email_error_valid: "Please use a valid email address!",
                  password_error:
                    "Password must be at least 8 characters long!",
                  password_error_uppercase:
                    "Password must contain at least one uppercase letter!",
                  password_error_special:
                    "Password must contain at least one special character!",
                  password_error_numeric:
                    "Password must contain at least one numerical digit!",
                  role_error: "Role is required!",
                  store_error: "You must select at least one store!",
                  code_error: "Code must be a 4-digit number!",
                  only_numbers_error: "Only numbers are allowed!",
                },
              },
              stores: {
                name: "Stores",
                sure: "Are you sure you want to delete this store?",
                add_store: "Add Store",
                branch_name: "Name of Branch",
                store_name: "Store Name",
                enter_name: "Enter Name",
                store_address: "Store Address",
                street_name: "Street Name",
                enter_street_name: "Enter Street Name",
                building_no: "Building No",
                enter_building_no: "Enter Building No",
                district_name: "District Name",
                enter_district_name: "Enter District Name",
                city: "City",
                enter_city: "Enter City",
                zip_code: "ZIP Code",
                enter_zip_code: "Enter ZIP Code",
                validations: {
                  max_100: "Maximum 100 characters are allowed!",
                  branchname_error:
                    "Branch name must be at least 3 characters long!",
                  streetname_error:
                    "Street name must be at least 3 characters long!",
                  districtname_error:
                    "District name must be at least 3 characters long!",
                  cityname_error:
                    "City name must be at least 3 characters long!",
                  zipcode_error_min: "Zip code must be 5 digits long!!",
                  zipcode_error_max: "Maximum 5 digits are allowed!",
                  buildingno_error: "Building number is required!",
                  buildingno_error_max: "Maximum 4 digits are allowed!",
                  only_numbers_error: "Only numbers are allowed!",
                  buildingno_only_alphanumeric:"Building Number must be at least 1 character long!",

                },
              },
              payments: {
                name: "Payments",
                add_payment: "Add Payment Method",
                what_kind: "What kind of payment?",
                sure: "Are you sure you want to delete this Payment Method?",
                points: {
                  name: "Points",
                  value_per_point: "Value Per Point",
                  value_sub_title:
                    "”Each 1 Saudi riyal value equals how many points ”",
                  enter_value_per_point: "Enter Value Per Point",
                  point_per_value: "Point Per Value",
                  point_sub_title:
                    "“Each 1 Point equals how many Saudi Riyal “",
                  enter_points_per_value: "Enter Point Per Value",
                  min_replacement_app: "Minimum Replacement Application",
                  enter_min_replacement_app:
                    "Enter Minimum Replacement Application",
                  min_replacement_app_subtitle:
                    "“How much is the lowest invoice value accepted”",
                  min_replacement_point: "Minimum Replacement Point",
                  enter_minimum_replacement_point:
                    "Enter Minimum Replacement Point",
                  min_replacement_point_subtitle:
                    "“How much is the lowest invoice value accepted”",
                  validations: {
                    only_numbers: "Only numbers are allowed!",
                    value_per_point_min_error: "Value per point is required!",
                    point_per_value_min_error: "Point per value is required!",
                    min_replacement_app_min_error:
                      "Minimum replacement application is required!",
                    min_replacement_points_min_error:
                      "Minimum replacement points is required!",
                  },
                },
                delivery_company: {
                  name: "Delivery Companies",
                  company: "Company",
                  add_company: "Add Company",
                  delivery_company_name: "Delivery Company Name",
                  enter_delivery_company: "Enter Delivery Company Name",
                  fee_percentage: "Fee Percentage",
                  enter_fee: "Enter Fee Percentage",
                  ammount_added: "Amount Added",
                  enter_amount: "Enter Amount Added",
                  add_another: "Add Another Company",
                  add_method: "Add Method",
                  validations: {
                    company_name_min_error:
                      "Company name must be at least 3 characters long!",
                    company_name_max_error:
                      "Maximum 30 characters are allowed!",
                    fee_percentage_invalid_type_error:
                      "Only numbers are allowed!",
                    fee_percentage_min_error: "Fee percentage is required!",
                    amount_added_invalid_type_error:
                      "Only numbers are allowed!",
                    amount_added_min_error: "Amount added is required!",
                  },
                },
                cash: {
                  name: "Cash",
                  text: "You are about to add Cash as payment method to your system. Every transaction with this method will noted/mentioned/marked as cash. You can remove or modify it anytime.",
                },
                card: {
                  name: "Card",
                  title: "Choose method allowed in you system",
                  american_express: "American Express",
                  visa: "Visa",
                  master_card: "Master Card",
                  wise: "Wise",
                  revolut: "Revolut",
                },
              },
              promotions: {
                name: "Promotions",
                sure: "Are you sure you want to delete this Promotion?",
                add_promotions: "Add Promotions",
                enter_english_name: "Enter English Name",
                english_name: "English Name",
                start_date: "Start Date",
                end_date: "End Date",
                promotion_type: "Promotion Type",
                promotion_store: "Select Store",
                promotion_select_item: "Select Item",
                promotion_value: "Promotion Value",
                promotion_all_product: "All Product",
                promotion_product: "Product",
                select_type: "Select Type",
                validations: {
                  name_error_min:
                    "Promotion Name must be  more than 3 characters!",
                  name_error_max:
                    "Promotion Name must be no more than 100 characters!",
                  start_date_error: "Start Date is required!",
                  end_date_error: "End Date is required!",
                  type_error: "Type is required!",
                  percentage_error: "Percentage is required!",
                  only_numbers: "Only numbers are allowed!",
                  percentage_limit:
                    "Percentage must be a number between 1 and 100",
                  store_error: "At least one store must be selected",
                  choose_error: "Choose Single Product or All Product",
                  select_product_error:
                    "You must select Single product or check 'All Product",
                  enter_promotion_value: "Enter Promotion Value",
                  value_error_max: "Value must be no more than 10 characters",
                },
              },
              settings: {
                profile: {
                  name: "Profile",
                  profile_pic: "Profile Picture",
                  english_name: "English Name",
                  enter_english_name: "Enter English Name",
                  arabic_name: "Arabic Name",
                  enter_arabic_name: "Enter Arabic Name",
                  email: "Email",
                  enter_email: "Enter Email",
                  phone: "Phone",
                  enter_phone: "Enter Phone",
                  change_picture: "Change Picture",
                  supported_format: "Supported Format",
                  validations: {
                    englishname_error:
                      "English Name must be at least 3 characters long!",
                    englishname_error_max:
                      "Maximum 100 characters are allowed!",
                    arabicname_error:
                      "Arabic name must be at least 3 characters long!",
                    arabicname_error_max: "Maximum 100 characters are allowed!",
                    email_error: "Email is required!",
                    email_error_valid: "Please use a valid email address!",
                    phone_error: "Phone Number must be at least 3 digits long!",
                    phone_error_max:
                      "Phone Number should not exceed 15 digits!",
                    phone_error_format:
                      "Phone Number must start with + and be followed by numeric digits!",
                    img_error: "Image is required!",
                    img_error_dimensions:
                      "Image dimensions must be at least 400px by 400px!",
                    img_err_size: "File size must not exceed 2 MB!",
                    img_err_type:
                      "Invalid file type. Only PNG, JPEG and PDF are allowed!",
                    img_err_cumpolsory: "Uploading an image is mandatory!",
                  },
                },
                business_info: {
                  name: "Business Info",
                  validations: {
                    companyname_error:
                      "Company name must be at least 3 characters long!",
                    companyname_error_max:
                      "Maximum 100 characters are allowed!",
                    companyaddress_error:
                      "Company address must be at least 3 characters long!",
                    companyaddress_error_max:
                      "Maximum 100 characters are allowed!",
                    website_error: "Invalid website!",
                    commercialregister_error:
                      "Commercial register must be at least 3 characters long!",
                    commercialregister_error_max:
                      "Maximum 100 characters are allowed!",
                    vatnumber_error:
                      "VAT number must be exactly 15 digits long!",
                    businesstype_error: "Business type is required!",
                  },
                },
                subscription: "Subscription",
                password: {
                  name: "Password",
                  validations: {
                    currentpassword_error: "Current password is required!",
                    newpassword_error:
                      "Password must be at least 8 characters long!",
                    newpassword_error_uppercase:
                      "Password must contain at least one uppercase letter!",
                    newpassword_error_special:
                      "Password must contain at least one special character!",
                    newpassword_error_numeric:
                      "Password must contain at least one numerical digit!",
                    confirmpassword_error: "Confirm Password is required!",
                    passwords_match_error: "Passwords don't match!",
                  },
                },
              },
            },
            trial_info: {
              days: "days",
              days_left: "Your trial will end in",
              plan_info: "Your Basic plan is a free trial.",
              upgrade_button: "Upgrade Plan",
            },
            subscription_info: {
              days: "days",
              days_left: "Your subscription will end in",
              plan_info: "Your plan is ",
              upgrade_button: "Upgrade Plan",
              monthly: "Monthly",
              yearly: "Yearly",
            },
          },
          pos_dashboard: {
            home_button: "Home",
            order_button: "Order",
            table_button: "Table",
            drawer_button: "Drawer",
            categories: "Categories",
            Search_item: "Search item...",
            items: "Items",
            order_detail: "Order Detail",
            save_order: "Save Order",
            takeaway: "Takeaway",
            dine_in: "Dine In",
            no_item: "No Item Added Yet",
            empty_state_for_order:
              "There are currently no items to display in this table. You can add new items to populate this list",
            add_table: "Add Table",
            add_customer: "Add Customer",
            add_discount: "Add Discount",
            sub_total: "Sub Total",
            discount: "Discount",
            vat: "VAT",
            total: "Total",
            paynow: "Pay Now ",
            choose_promotion: "Choose Promotion",
            choose_discount: "Choose Discount",
            choose_customer: "Choose Customer",
            change: "Change",
            table_number: "Table Number",
            select_customer: "Selected Customer",
            choose_table: "Choose Table",
            available: "Avaialble",
            reserved: "Reserved",
            selected: "Selected",
            selected_tables: " Selected Table: ",
            none: "None",
            enter_fast_access_code: "Enter Fast Access Code",
            saved: "Saved",
            orders: "Orders",
            numbers: "Numbers",
            add_new_table: "Add New Table",
            reset_table: "Reset Table",
            no_table_added: "No Table Added",
            add_table_text:
              "Start to add your first table by clicking the button below",
            add_table_label: " What kind of table you want to add? *Select one",
            how_many: "How many ?",
            cancel: "Cancel",
            select_how_many_table: "Select how many Tables",
            one: "1",
            two: "2",
            three: "3",
            four: "4",
            five: "5",
            six: "6",
            seven: "7",
            eight: "8",
            nine: "9",
            zero: "0",
            backspace: "←",
            dot: ".",
            online: "Online",
            enter_store_code: "Enter Store Code",
            use_email_password: "Use Email & Password",
            confirm: "Confirm",
            login_account: "Login to Your Account",
            pos_email: "Email",
            pos_password: "Password",
            pos_remember_me: "Remember me",
            pos_login: "Login",
            use_code: "Use Code",
            copyright: "Copyright © 2023 TagDev",
            download_our_app: "Download Our App",
            login_to_your_store: "Login to your Store",
            are_you_sure_about_choosing_the_store:
              "Are you sure about choosing the",
            branch: "branch",
            question: "?",
            yes_choose: "Yes,Choose",
            Cancel: "Cancel",
            checkout: "Checkout",
            choose_payment_method: "Choose Payment Method",
            split_equally: "Split Equally",
            split_by_item: "Split By Item",
            full_pay: "Full Pay",
            cash: "Cash",
            card: "Card",
            points: "Points",
            delivery_order: "Delivery Order",
            fee_percentage: "Fee Percentage",
            amount_added: "Amount Added",
            choose_method: "Choose Method",
            choose_card: "Choose Card",
            selected_customer: "Select Customer",
            paying: "Paying",
            add_part: "Add Part",
            payment_method: "Payment Method",
            payments: "Payments",
            customer: "Customer",
            created_by: "Created by",
            service: "Service",
            time: "Time",
            date: "Date",
            order_no: "Order No",
            payment_successfull: "Payment Successful!",
            discount_applied: "Discount applied",
            done: "Done",
            service_type: "Service Type",
            save_promotion: "Save Promotion",
            add_new_customer: "Add New Customer",
            add_note: "Add Note",
            remove_note: "Remove Note",
            item: "Item",
            name: "Name",
            save_changes: "Save Changes",
            split_invoice: "Split Invoice",
            possibility_to_choose_table: "Possibility To Choose The Table",
            devices: "Devices",
            attendance_history: "Attendance History",
            terms_and_conditions: "Terms & Conditions",
            settings: "Settings",
            general: "General",
            language: "Language",
            select_gender: "Select Gender",
            phone_number: "Phone Number",
            enter_phone_number: "Enter Phone Number",
            customer_name: "Customer Name",
            enter_customer_name: "Enter Customer Name",
            save_customer: "Save Customer",
            email: "Email",
            enter_email: "Enter Email",
            gender_required: "Gender is required!",
            phone_number_format:
              "Phone Number must start with + and be followed by numeric digits!",
            phone_number_length: "Phone Number should not exceed 20 digits!",
            phone_number_min_length:
              "Phone Number must be at least 3 digits long!",
            invalid_email: "Please use a valid email address!",
            email_required: "Email address is required!",
            full_name_required: "Full Name is required!",
          },
          footer: {
            copyright: "Copyright",
          },
        },
      },
      ar: {
        translation: {
          pos_dashboard: {
            select_gender: "اختر الجنس",
            phone_number: "رقم الهاتف",
            enter_phone_number: "أدخل رقم الهاتف",
            customer_name: "اسم العميل",
            enter_customer_name: "أدخل اسم العميل",
            save_customer: "حفظ العميل",
            add_new_customer: "إضافة عميل جديد",
            email: "البريد الإلكتروني",
            enter_email: "أدخل البريد الإلكتروني",
            add_customer: "إضافة عميل",
            gender_required: "الجنس مطلوب!",
            phone_number_format: "يجب أن يبدأ رقم الهاتف بـ + ويتبعه أرقام!",
            phone_number_length: "يجب ألا يتجاوز طول رقم الهاتف 20 رقمًا!",
            phone_number_min_length:
              "يجب أن يكون رقم الهاتف على الأقل 3 أرقام!",
            invalid_email: "الرجاء استخدام عنوان بريد إلكتروني صالح!",
            email_required: "عنوان البريد الإلكتروني مطلوب!",
            full_name_required: "الاسم الكامل مطلوب!",
            general: "عام",
            language: "اللغة",
            save_promotion: "حفظ الترقية!",
            choose_promotion: "اختيار الترقية",
            add_note: "إضافة ملاحظة",
            remove_note: "إزالة الملاحظة",
            item: "العنصر",
            name: "اسم",
            save_changes: "حفظ التغييرات",
            split_invoice: "تقسيم الفاتورة",
            possibility_to_choose_table: "إمكانية اختيار الطاولة",
            devices: "الأجهزة",
            attendance_history: "سجل الحضور",
            terms_and_conditions: "الشروط والأحكام",
            settings: "الإعدادات",
            service_type: "نوع الخدمة",
            discount_applied: "تم تطبيق الخصم",
            done: "تم",
            payment_method: "طريقة الدفع",
            payments: "المدفوعات",
            order_detail: "تفاصيل الطلب",
            customer: "العميل",
            created_by: "تم الإنشاء بواسطة",
            service: "الخدمة",
            time: "الوقت",
            date: "التاريخ",
            order_no: "رقم الطلب",
            payment_successfull: "تمت عملية الدفع بنجاح!",
            add_part: "إضافة قسم",
            paying: "الدفع",
            selected_customer: "اختر العميل",
            fee_percentage: "نسبة الرسوم",
            amount_added: "المبلغ المضاف",
            choose_method: "اختر الطريقة",
            choose_customer: "اختر العميل",
            choose_card: "اختر البطاقة",
            cash: "نقداً",
            card: "بطاقة",
            points: "نقاط",
            delivery_order: "طلب التوصيل",
            choose_payment_method: "اختر طريقة الدفع",
            split_equally: "تقسيم بالتساوي",
            split_by_item: "تقسيم حسب العنصر",
            full_pay: "الدفع الكامل",
            checkout: "اتمام الشراء",
            yes_choose: "نعم، اختر",
            Cancel: "إلغاء",
            question: "؟",
            are_you_sure_about_choosing_the_store:
              "تسجيل الدخول إلى حسابك في المتجر",
            branch: "تسجيل الدخول إلى فرعك",
            login_to_your_store: "تسجيل الدخول إلى متجرك",
            download_our_app: "قم بتنزيل تطبيقنا",
            pos_login: "تسجيل الدخول",
            use_code: "استخدم الرمز",
            copyright: "حقوق النشر © ٢٠٢٣ TagDev",
            pos_remember_me: "تذكرني",
            pos_email: "البريد الإلكتروني",
            pos_password: "كلمة المرور",
            confirm: "تأكيد",
            use_email_password: "استخدم البريد الإلكتروني وكلمة المرور",
            home_button: "الرئيسية",
            order_button: "طلب",
            table_button: "طاولة",
            drawer_button: "درج",
            categories: "الفئات",
            Search_item: "ابحث عن عنصر...",
            items: "عناصر",
            save_order: "حفظ الطلب",
            takeaway: "طلب خارجي",
            dine_in: "تناول في المطعم",
            no_item: "لم يتم إضافة عناصر بعد",
            empty_state_for_order:
              "لا توجد عناصر لعرضها في هذا الجدول حالياً. يمكنك إضافة عناصر جديدة لملء هذه القائمة",
            add_table: "إضافة طاولة",
            add_discount: "إضافة خصم",
            sub_total: "المجموع الفرعي",
            discount: "خصم",
            vat: "ضريبة القيمة المضافة",
            total: "المجموع",
            paynow: "ادفع الآن",
            choose_discount: "اختر الخصم",
            change: "تغيير",
            table_number: "رقم الطاولة",
            select_customer: "العميل المحدد",
            choose_table: "اختر الطاولة",
            available: "متاحة",
            reserved: "محجوزة",
            selected: "مُحددة",
            selected_tables: " :الطاولات المُحددة",
            none: "بلا شيء",
            enter_fast_access_code: "أدخل رمز الوصول السريع",
            saved: "تم الحفظ",
            orders: "الطلبات",
            numbers: "أرقام",
            add_new_table: "إضافة جدول جديد",
            reset_table: "إعادة تعيين الجدول ",
            add_table_text: "لم يتم إضافة أي جدول",
            add_table_label: "ابدأ بإضافة جدولك الأول بالنقر على الزر أدناه",
            no_table_added: "لم يتم إضافة أي جدول",
            how_many: "كم عددهم؟",
            cancel: "إلغاء",
            select_how_many_table: "اختر عدد الجداول",
            one: "١",
            two: "٢",
            three: "٣",
            four: "٤",
            five: "٥",
            six: "٦",
            seven: "٧",
            eight: "٨",
            nine: "٩",
            zero: "٠",
            dot: ".",
            backspace: "←",
            online: "عبر الإنترنت",
            enter_store_code: "أدخل رمز المتجر",
            login_account: "تسجيل الدخول إلى حسابك",
          },
          //Wattoo
          landingpage: {
            features: {
              feature: "الميزات",
              text: "افتح إمكانيات بواجهة مستخدم سهلة الاستخدام ووظائف متطورة",
              inventory: "المخزون",
              invoices: "الفواتير",
              directory: "الدليل",
              report: "تقرير",
              invtrf: "نقل المخزون",
              int1: "تتبع وتسجيل حركة المخزون بين المواقع أو المتاجر المختلفة",
              int2: "تحديث مستويات المخزون تلقائيًا عبر المواقع عند النقل",
              int3: "إنشاء تقارير النقل للمساءلة وحفظ السجلات",
              stocktaking: "الجرد",
              stk1: "تسهيل الجرد الفعلي الدوري للمخزون لضمان الدقة",
              stk2: "مقارنة الجرد الفعلي بسجلات النظام لتحديد الفروقات",
              stk3: "تحديث مستويات المخزون بناءً على نتائج الجرد الفعلي",
              inventorytransaction: "معاملة المخزون",
              invtrf1:
                "مراقبة جميع المعاملات المتعلقة بالمخزون بما في ذلك المبيعات والمشتريات والإرجاع",
              invtrf2: "تحديث مستويات المخزون في الوقت الفعلي بعد كل معاملة",
              invtrf3: "تاريخ مفصل لكل عنصر في المخزون",
            },
            general: {
              question1: "كيف يمكنني التسجيل في نظام نقاط البيع؟",
              answer1:
                "يمكنك التسجيل باستخدام جوجل أو فيسبوك أو البريد الإلكتروني. فقط اتبع التعليمات للموافقة على شروط الاستخدام وسياسة الخصوصية وتأكيد بريدك الإلكتروني.",

              question2: "ماذا يجب أن أفعل إذا لم أتلق رمز التأكيد؟",
              answer2:
                "إذا لم تتلق رمز التأكيد في بريدك الإلكتروني، يمكنك طلب إعادة إرسال الرمز من خلال واجهة التسجيل.",

              question3: "كيف أكمل ملف الأعمال الخاص بي؟",
              answer3:
                "بعد إنشاء حسابك وكلمة المرور، يمكنك إكمال ملف الأعمال الخاص بك عن طريق تحميل صورة وتقديم التفاصيل التجارية اللازمة.",

              question4: "كيف يمكنني إدارة الرسائل داخل نظام لوحة التحكم؟",
              answer4:
                "يمكنك إدارة رسائلك عن طريق الانتقال إلى قسم الرسائل، حيث يمكنك إنشاء رسائل جديدة، عرض، إرسال، بحث وحذف الرسائل. يمكنك أيضًا إضافة مرفقات إلى رسائلك.",

              question5:
                "هل يمكنني نقل بيانات أعمالي (العنصر) من منصتي الحالية إلى منصة TagDev؟",
              answer5:
                "نعم، سيعتني مدراء الحسابات بنقل جميع العناصر من نظامك القديم إلى نظامك الجديد.",

              question6: "هل يمكنني التحقق من المنصة من هاتفي؟",
              answer6:
                "نعم، يمكنك تحميل تطبيق TagDev من متجر التطبيقات أو متجر جوجل بلاي.",

              question7: "هل توفر TagDev أجهزة الكاشير وقارئات الباركود؟",
              answer7: "نعم، نحن نوفر الأجهزة بأسعار أقل من أسعار السوق.",

              question8: "هل تتوافق TagDev مع هيئة الزكاة والضريبة والجمارك؟",
              answer8: "نعم.",

              question9: "هل تحديثات منصة TagDev مدفوعة؟",
              answer9:
                "جميع التحديثات مجانية ويتم إعدادها تلقائيًا على أنظمة مشتركي TagDev.",

              question10:
                "هل يمكنني الطباعة إذا لم يكن النظام متصلًا بالإنترنت؟",
              answer10:
                "الطباعة ممكنة إذا كانت نقاط البيع والطابعة متصلة بنفس الشبكة المحلية (Wi-Fi/إيثرنت).",
            },
            subscriptionBilling: {
              question1: "كيف يمكنني إعداد أو تغيير خطة الاشتراك الخاصة بي؟",
              answer1:
                "لإعداد أو تغيير خطة الاشتراك الخاصة بك، انتقل إلى إعدادات الاشتراك، اختر خطتك، أضف أي فروع إضافية إذا لزم الأمر، اختر طريقة الدفع الخاصة بك، و أكد اشتراكك.",

              question2: "هل يمكنني إضافة فروع متعددة إلى اشتراكي؟",
              answer2:
                "نعم، يمكنك إضافة فروع إضافية إلى خطة الاشتراك الخاصة بك أثناء إعداد أو تعديل اشتراكك.",
            },
            dashboardAnalytics: {
              question1: "كيف يمكنني عرض التحليلات لتواريخ محددة؟",
              answer1:
                "في لوحة التحكم، استخدم ميزة التقويم لتحديد وعرض التحليلات للتواريخ أو المتجر أو أمين الصندوق أو أكثر التي تريدها.",

              question2:
                "ما نوع تحليلات المبيعات التي يمكنني عرضها في لوحة التحكم؟",
              answer2:
                "توفر لوحة التحكم نظرة عامة على المبيعات والتكاليف والدخل الصافي والنفقات والمبيعات المصنفة حسب العناصر والفئات والمتاجر وأمناء الصندوق وتوقعات المبيعات.",
            },
            faqsHeader: {
              tagline:
                "هل لديك أسئلة؟ لدينا الإجابات. تحقق من قائمتنا للأسئلة الشائعة للعثور على المعلومات التي تحتاجها.",
              title: "الأسئلة الشائعة",
            },
            reportsAnalytics: {
              question: "كيف يمكنني الوصول إلى أنواع مختلفة من تقارير الأعمال؟",
              answer:
                "انتقل إلى قسم التقارير للوصول إلى تصدير مختلف التقارير مثل المبيعات والمشتريات والضرائب والربح والخسارة والنفقات والمخزون. يمكنك تطبيق الفلاتر وتحديد التواريخ للحصول على تقارير مخصصة.",
            },
            userStoreManagement: {
              question: "كيف يمكنني إدارة أدوار المستخدمين وتفاصيل المتجر؟",
              answer:
                "في قسم 'إدارة المستخدمين'، يمكنك إضافة، تحرير، حذف والبحث عن المستخدمين، وكذلك إدارة أدوارهم وصلاحياتهم. لإدارة المتجر، استخدم قسم 'إدارة المتاجر' لإضافة، تحرير وإدارة تفاصيل المتجر.",
            },
            promotionsSettings: {
              question1: "كيف يمكنني إعداد العروض الترويجية لمنتجاتي؟",
              answer1:
                "استخدم قسم 'إدارة العروض الترويجية' لإنشاء وتحرير العروض الترويجية. يمكنك إعداد العروض الترويجية للمنتجات المختارة، جميع المنتجات، وتحديد الخصم كنسب مئوية أو مبالغ ثابتة.",

              question2: "كيف يمكنني تغيير الإعدادات العامة وإدارة الأجهزة؟",
              answer2:
                "في قسم 'الإعدادات العامة'، يمكنك عرض وتحرير مختلف الإعدادات، بما في ذلك إدارة الأجهزة المتصلة وتكوين الأجهزة الجديدة.",
            },
            invoiceInventoryManagement: {
              question1: "كيف يمكنني إدارة نفقات المبيعات وفواتير الشراء؟",
              answer1:
                "تقدم TagDev نظام محاسبة بسيط لمساعدتك في حساب تكاليفك ونفقاتك بسرعة ودقة. يمكن إدارة الفواتير من خلال أقسام 'فواتير المبيعات' و'فواتير الشراء' و'فواتير النفقات'، حيث يمكنك عرض، إنشاء، تحرير، حذف والبحث عن الفواتير. لديك أيضًا خيارات مثل عرض التفاصيل، الطباعة، التكرار ومعالجة المرتجعات.",

              question2: "كيف يمكنني التعامل مع تحويلات المخزون والجرد؟",
              answer2:
                "انتقل إلى أقسام 'نقل المخزون' و'الجرد' لإدارة هذه الجوانب. يمكنك إضافة، تحرير، حذف والبحث عن سجلات النقل أو الجرد، والوصول إلى الخيارات التفصيلية.",
            },

            reports: {
              salesreports: "تقارير المبيعات",
              salesreports1: "تحليل مفصل لأداء المبيعات بمرور الوقت",
              salesreports2:
                "تفصيل حسب العنصر والفئة وأمين الصندوق والفترة الزمنية",
              salesreports3: "تحديد المنتجات الأكثر مبيعًا",
              purchasereports: "تقارير الشراء",
              purchasereports1: "تتبع اتجاهات الشراء والنفقات",
              purchasereports2:
                "التنبؤ باحتياجات المخزون المستقبلية بناءً على البيانات التاريخية",
              taxesreports: "تقارير الضرائب",
              taxesreports1: "أتمتة حسابات الضرائب بناءً على بيانات المبيعات",
              taxesreports2: "إنشاء تقارير ضريبية للامتثال والتقديم",
              profitlossreports: "تقارير الربح والخسارة",
              profitlossreports1: "نظرة شاملة على الإيرادات والتكاليف والنفقات",
              profitlossreports2: "تحديد المناطق المربحة وغير المربحة في العمل",
              profitlossreports3: "مقارنة دورية لتقييم الصحة المالية",
              expensereports: "تقارير النفقات",
              expensereports1: "تصنيف وتتبع جميع النفقات التجارية",
              expensereports2: "مراقبة أنماط الإنفاق وتحديد فرص توفير التكاليف",
              expensereports3: "تسهيل إدارة الميزانية والتخطيط المالي",
              inventoryreports: "تقارير المخزون",
              inventoryreports1:
                "مراقبة مستويات المخزون ومعدلات الدوران وسجل الطلبات",
              inventoryreports2: "تقارير عن عمر المخزون وصلاحية الرفوف",
              inventoryreports3: "تحليل كفاءة المخزون وتحسين مستويات المخزون",
            },

            invoices: {
              salesinvoices: "فواتير المبيعات",
              salesinvoices1: "إنشاء وإدارة فواتير المبيعات بسهولة",
              salesinvoices2:
                "تتبع المدفوعات، المدفوعات الجزئية، والفواتير المتأخرة",
              salesinvoices3: "تخصيص قوالب الفواتير لأغراض العلامة التجارية",
              purchaseinvoices: "فواتير الشراء",
              purchaseinvoices1:
                "تسجيل وإدارة أوامر الشراء والفواتير من الموردين",
              purchaseinvoices2: "تتبع حالات الدفع وتواريخ الاستحقاق",
              purchaseinvoices3:
                "التكامل مع المخزون لتحديث مستويات المخزون تلقائيًا عند الشراء",
              expenseinvoices: "فواتير النفقات",
              expenseinvoices1: "تسجيل وتصنيف النفقات التجارية",
              expenseinvoices2: "إدارة النفقات المتكررة والمدفوعات لمرة واحدة",
              expenseinvoices3: "إرفاق المستندات الداعمة لسجلات النفقات",
            },

            directory: {
              customerdirectory: "دليل العملاء",
              customerdirectory1:
                "الحفاظ على قاعدة بيانات مفصلة لمعلومات العملاء",
              customerdirectory2: "تتبع تاريخ الشراء وتفضيلات العملاء",
              customerdirectory3:
                "تقسيم العملاء للتسويق الموجه والعروض الترويجية",
              supplierdirectory: "دليل الموردين",
              supplierdirectory1:
                "إدارة معلومات الاتصال وسجل المعاملات مع الموردين",
              supplierdirectory2:
                "تقييم أداء الموردين بناءً على أوقات التسليم والأسعار والجودة",
              itemsdirectory: "دليل العناصر",
              itemsdirectory1:
                "فهرسة جميع العناصر مع الأوصاف والصور والرموز الشريطية",
              itemsdirectory2: "تعريف فئات العناصر للإدارة والتقارير السهلة",
              categorydirectory:
                "دليل الفئات (عنصر بسيط، عنصر متغير، عنصر مركب، عنصر خام)",
              categorydirectory1: "تنظيم عناصر المخزون في فئات منطقية",
              categorydirectory2:
                "فئات قابلة للتخصيص لتناسب أنواع مختلفة من البضائع",
              categorydirectory3: "تحليل المبيعات ومستويات المخزون حسب الفئة",
            },

            buttons: {
              signin: "تسجيل الدخول",
              getstarted: " ابدأ الآن",
              letstalk: "دعونا نتحدث",
              contactus: "تواصل معنا",
              contacttext:
                "تواصل معنا! نحن هنا لمساعدتك. سواء كانت لديك أسئلة، ملاحظات، أو تحتاج إلى دعم، فإن فريقنا جاهز لمساعدتك.",
              information: "معلومات الاتصال",
              name: "الاسم",
              entername: "أدخل الاسم",
              storeName: "اسم المتجر",
              enterstore: "أدخل اسم المتجر",
              phone: "رقم الهاتف",
              enterphone: "أدخل رقم الهاتف",
              bussniess: "ما هو نوع عملك",
              bussniessholder: "اختر نوع العمل",
              message: "رسالة",
              messagetype: "أدخل الرسالة",
              sendmessage: "إرسال الرسالة",
              fullymanaged: "إدارة متكاملة لمتجرك.",
              pointsale: "كاشير سحابي",
              dashboardanaltics: "نظرة عامة على تحليلات لوحة القيادة",
              items: "دليل العناصر والفئات (عنصر بسيط ومتغير)",
              invoices: "فواتير غير محدودة",
              sales: "إدارة فواتير المبيعات",
              customer: "إدارة العملاء",
              advacne: "تقارير متقدمة",
              user: "إدارة المستخدمين (مستخدم واحد فقط)",
              support: "دعم على مدار الساعة",
              everything: "كل شيء في الخطة الأساسية",
              category: "دليل العناصر والفئات (عنصر بسيط، خام، مركب، ومتغير)",
              stocking: "إدارة المخزون",
              promotions: "العروض الترويجية",
              management: "إدارة المستخدمين (مستخدم واحد فقط)",
              inventory: "إدارة معاملات المخزون",
              table: "إدارة الطاولات",
              pols: "نقاط الولاء",
              ai: "تقارير الذكاء الاصطناعي",
              addmore: "إضافة فرع جديد",
              account: "إدارة الحسابات العامة",
              billing: "الاشتراكات والفواتير",
              store_management: "إدارة المتجر: متجر واحد فقط",
              everything_in_professional_plan: "كل شيء في الخطة المهنية",
              users_management: "إدارة المستخدمين",
              choose_plan: "اختر الخطة",
              basic_plan: "الخطة الأساسية",
              enterprise_plan: "خطة المؤسسة",
              professional_plan: "الخطة المهنية",
              popular: "شائع",
              ar: "أفضل نظام نقاط البيع في العالم، مع دعم سريع وودود",
              plan: "اختر الخطة",
            },

            navbar: {
              features: "الميزات",
              pricing: "الأسعار",
              buisnesstype: "نوع العمل",
              faq: "الأسئلة الشائعة",
              contactus: "اتصل بنا",
            },
            hero: {
              heading: "   اعمل مع أفضل نظام نقاط البيع لأعمالك",
              text: "تغلب على جميع التحديات في قطاع التجزئة مع TAG Dev ، من خلال تقديم حلول  تقنية مخصصة لتحسين أداء مختلف أنواع الأعمال.",
            },
            oneplace: {
              heading: "إدارة كل شيء في مكان واحد",
              text: "(          ارفع كفاءة ونمو أعمالك التجارية – حل نقاط البيع الشامل",
            },
            text: "أكثر من نظام نقاط بيع - إنها الأساس لنجاح عملك في عالم التجزئة التنافسي",
            tabssection: {
              heading:
                "أكثر من نظام نقاط بيع - إنها الأساس لنجاح عملك في عالم التجزئة التنافسي",
              words:
                "تحسين العمليات ...،النمو ...،تكنولوجيا نقاط البيع ...،تبسيط المبيعات ...،إدارة المخزون ...",
              tabs: {
                securecloud: {
                  title: "نظام السحابة الآمنة",
                  heading: "نظام السحابة الآمنة",
                  text: "قل وداعًا للأجهزة الضخمة، متاعب الصيانة، ورسوم النسخ الاحتياطي للبيانات. اترك كل ذلك لفريق TagDev؛ نحن نخزن بياناتك في السحابة للحفاظ على سلامتها وتوافرها، مما يتيح لك الوصول إليها في أي وقت ومن أي مكان ومن أي جهاز.",
                },
                offlineoperation: {
                  title: "تشغيل مستمر دون انترنت",
                  heading: "تشغيل مستمر حتى عند عدم توفر الإنترنت",
                  text: "في وضع عدم الاتصال من TagDev، يستمر عملك حتى عند انقطاع الإنترنت. لا تقلق واستمر في استقبال طلبات العملاء، ستتم مزامنة هذه الطلبات تلقائيًا عندما يعود الاتصال بنقاط البيع (نقطة البيع) مرة أخرى.",
                },
                aidata: {
                  title: "تحليل البيانات بالذكاء الاصطناعي",
                  heading: "تحليل البيانات بالذكاء الاصطناعي",
                  text: "أفضل القرارات التجارية تأتي من التقارير الدقيقة وتحليل البيانات السليم. يوفر لك TagDev تقارير أداء مهمة بالذكاء الاصطناعي تمكنك من تتبع مبيعاتك والحصول على معلومات قيمة تساعدك في اتخاذ القرارات المستنيرة.",
                },
                continuousdevelopment: {
                  title: "تطوير مستمر",
                  heading: "تطوير مستمر",
                  text: "لا شيء يوقف رغبتنا المستمرة في التطوير والتحسين. نضمن لك التحديث المستمر لنظامك. نستمع لعملائنا ونعمل على تطوير نظامنا بما يتماشى مع احتياجاتهم وتطلعاتهم.",
                },
                viaphone: {
                  title: "الإدارة عبر الهاتف",
                  heading: "إدارة العمل عبر الهاتف",
                  text: "تتبع المبيعات بسهولة، إدارة المخزون، تقارير يومية وإضافة عناصر - كل ذلك من هاتفك.",
                },
              },
            },
            whytagdev: {
              heading: "لماذا TagDev؟",
              text: "حل نقاط البيع الكل في واحد - ارفع كفاءة ونمو أعمالك التجارية",
              cards: {
                one: {
                  heading: "دعم على مدار الساعة",
                  text: "دعم عبر الهاتف، الدردشة الحية، والبريد الإلكتروني مجانًا.",
                },
                two: {
                  heading: "   بدون تكاليف مخفية",
                  text: "لا تقلق بشأن التكاليف الخفية لأنه لا توجد زيادات في الأسعار.",
                },
                three: {
                  heading: "نقل البيانات مجانًا",
                  text: "تغيير المنصات؟ سيساعدك فريقنا على الإعداد.",
                },
              },
            },
            bar: {
              signIn: "تسجيل الدخول إلى لوحة التحكم",
              pos: "تسجيل الدخول إلى نقاط البيع",
              signIntext: "ابدأ في إدارة ومراقبة فروعك من خلال منصتنا",
              postext:
                "ابدأ معاملاتك مع عملائك من خلال منصة نقاط البيع الخاصة بنا",
              readmore: "اقرأ المزيد",
            },

            footer: {
              heading: "نمِ عملك مع TagDev",
              text: "اتصل بنا لتجربة النظام دون دفع حتى انتهاء فترة التجربة",
              links: {
                getstarted: "ابدأ الآن",
                lettalk: "دعونا نتحدث",
                businesstype: "نوع الأعمال ",
                restaurant: "المطعم",
                coffeeshop: "مقهى",
                shop: "متجر",
                supermarket: "سوبرماركت",
                foodtruck: "شاحنات الطعام",
                giftshop: "محلات الهدايا",
                hairdresser: "صالونات الحلاقة",
                carservice: "خدمات السيارات",
                expressservices: "الخدمات السريعة",
                company: "الشركة",
                about: " من نحن",
                careers: "الوظائف",
                faq: "الأسئلة الشائعة",
                link: "الروابط",
                features: "الميزات",
                pricing: "الأسعار",
                contactus: "اتصل بنا",
                legal: "القانوني",
                terms: "شروط الخدمة",
                privacy: "الخصوصية والسياسة",
                downloadpos: "قم بتنزيل تطبيق نقاط البيع الخاص بنا   ",
                downloaddashboard: " قم بتنزيل تطبيق لوحة التحكم الخاصة بنا",
                copyright: "حقوق النشر © 2023 TagDev. جميع الحقوق محفوظة.",
                workwith: "اعمل مع",
                thebest: "أفضل نظام نقاط بيع",
                possystem: "لعملك",
                overcome:
                  "تغلب على جميع التحديات في قطاع التجزئة مع TAG Dev، الذي يقدم حلول تكنولوجية مخصصة لتحسين أداء مختلف أنواع الأعمال.",
                manage: "إدارة كل شيء",
                oneplace: "في مكان واحد",
                growth:
                  "حل نقاط البيع الكل في واحد – ارفع كفاءة ونمو أعمالك التجاري",
                optimize: "تحسين العمليات ...",
                grow: "النمو ...",
                technology: "تكنولوجيا نقاط البيع ...",
                sales: "تبسيط المبيعات ...",
                inventory: "إدارة المخزون ...",
                personal: "Tag Dev شخصي لك",
                assistant: "مساعد لـ",
                secure: "نظام السحابة الآمنة",
                operation: "تشغيل مستمر دون اتصال بالإنترنت",
                data: "تحليل البيانات بالذكاء الاصطناعي",
                developemnt: "تطوير مستمر",
                phone: "الإدارة عبر الهاتف",
                securetext:
                  "قل وداعًا للأجهزة الضخمة، متاعب الصيانة، ورسوم النسخ الاحتياطي للبيانات. اترك كل ذلك لفريق TagDev؛ نحن نخزن بياناتك في السحابة للحفاظ على سلامتها وتوافرها، مما يتيح لك الوصول إليها في أي وقت ومن أي مكان ومن أي جهاز",
                operationheading: "تشغيل مستمر حتى عند عدم توفر الإنترنت",
                operationtext:
                  "في وضع عدم الاتصال من TagDev، يستمر عملك حتى عند انقطاع الإنترنت. لا تقلق واستمر في استقبال طلبات العملاء، ستتم مزامنة هذه الطلبات تلقائيًا عندما يعود الاتصال بنقاط البيع (نقطة البيع) مرة أخرى.",
                datatext:
                  "أفضل القرارات التجارية تأتي من التقارير الدقيقة وتحليل البيانات السليم. يوفر لك TagDev تقارير أداء مهمة بالذكاء الاصطناعي تمكنك من تتبع مبيعاتك والحصول على معلومات قيمة تساعدك في اتخاذ القرارات المستنيرة.",
                developemnttext:
                  "لا شيء يوقف رغبتنا المستمرة في التطوير والتحسين. نضمن لك التحديث المستمر لنظامك. نستمع لعملائنا ونعمل على تطوير نظامنا بما يتماشى مع احتياجاتهم وتطلعاتهم.",
                phoneheading: "إدارة العمل عبر الهاتف",
                phonetext:
                  "تتبع المبيعات بسهولة، إدارة المخزون، تقارير يومية وإضافة عناصر - كل ذلك من هاتفك.",
                headingone: "أكثر من نظام نقاط بيع – إنه الأساس لنجاح",
                headingtwo: "نجاح عملك",
                headingthree: "في عالم التجزئة التنافسي",
              },
            },
          },
          contactus: {
            contactus: "Contact Us",
            contacttext:
              "Get in touch with us! We're here to assist you. Whether you have questions, feedback, or need support, our team is ready to help.",
            information: "Contact Information",
            faq: "Frequently Asked Questions",
            faqtext:
              "Have questions? We've got answers. Check out our list of frequently asked questions to find the information you need.",
          },
          //Naveed kmaaal end

          buttons: {
            add: "إضافة",
            save: "حفظ",
            export: "تصدير",
            signup_with_google: "التسجيل بواسطة جوجل",
            signup_with_facebook: "التسجيل بواسطة فيسبوك",
            or: "أو",
            continue: "متابعة",
            need_help: "هل تحتاج إلى مساعدة؟",
            save_changes: "حفظ التغييرات",
            cancel: "إلغاء",
            reset: "إعادة تعيين",
            delete: "حذف",
            select_language: "اختر اللغة",
            dismiss: "رفض",
            signout: "تسجيل الخروج",
            send: "إرسال",
            show_details: "إظهار التفاصيل",
            edit: "تعديل",
            duplicate: "نسخ",
            print: "طباعة",
            return: "العودة",
            drag_or_click: "اسحب أو انقر للتحميل",
            done: "تم",
            add_value: "إضافة قيمة",
            delivered: "Delivered",
          },
          validations: {
            supported_format: "التنسيق المدعوم",
            unsupported_format:
              "تنسيق الملف غير مدعوم. يرجى تحديد ملف بتنسيق JPG، JPEG، أو PNG",
            file_size:
              "حجم الملف كبير جدًا. الحجم الأقصى المسموح به هو 10 ميغابايت.",
          },
          login: {
            title: "تسجيل الدخول",
            welcome: "مرحبًا مرة أخرى! قم بتسجيل الدخول للوصول إلى حسابك",
            login_with_google: "تسجيل الدخول بواسطة جوجل",
            login_with_facebook: "تسجيل الدخول بواسطة فيسبوك",
            or: "أو",
            email: "البريد الإلكتروني",
            email_placeholder: "أدخل البريد الإلكتروني",
            password: "كلمة المرور",
            password_placeholder: "أدخل كلمة المرور",
            remember_me: "تذكرني",
            forgot_password: "هل نسيت كلمة المرور؟",
            account_confirmation: "ليس لديك حساب؟",
            validations: {
              email_error: "البريد الإلكتروني مطلوب!",
              email_error_invalid: "الرجاء استخدام عنوان بريد إلكتروني صالح!",
              password_error: "كلمة المرور مطلوبة!",
              password_error_min:
                "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
              password_error_uppercase:
                "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل",
              password_error_special_char:
                "يجب أن تحتوي كلمة المرور على رمز خاص واحد على الأقل",
              password_error_alphabet:
                "يجب أن تحتوي كلمة المرور على حرف أبجدي واحد على الأقل",
            },
          },
          recover_account: {
            name: "استعادة الحساب",
            description:
              "أدخل عنوان البريد الإلكتروني المرتبط بحسابك وسنرسل لك رابطًا لإعادة تعيين كلمة المرور الخاصة بك.",
            email: "البريد الإلكتروني",
            enter_email: "ادخل البريد الإلكتروني",
          },
          signup: {
            title: "التسجيل",
            welcome: "ابدأ باستخدام TagDev",
            or: "أو",
            email: "البريد الإلكتروني",
            email_placeholder: "أدخل البريد الإلكتروني",
            already_have_account: "هل لديك حساب بالفعل؟",
            agreement: "بإنشاء حساب، أنت توافق على",
            terms_and_conditions: "الشروط والأحكام",
            privacy_notice: "إشعار الخصوصية",
            and: "و",
          },
          confirm_email: {
            title: "تأكيد بريدك الإلكتروني",
            instructions:
              "لقد قمنا بإرسال رمز عبر البريد الإلكتروني إلى {{email}}، الرجاء إدخاله أدناه لإنشاء حسابك.",
            didnt_receive_code: "لم تتلقى الرمز؟",
            resend_code: "إعادة إرسال الرمز",
            image_heading: "قم بتعظيم زخم مبيعاتك",
            image_description:
              "قم برفع مستوى عملك وقم بتعظيم إمكانات إيراداتك. ابدأ اليوم!",
          },
          create_password: {
            title: "إنشاء كلمة المرور الخاصة بك",
          },
          create_password_form: {
            email: "البريد الإلكتروني",
            enter_email: "ادخل البريد الإلكتروني",
            new_password: "كلمة المرور الجديدة",
            confirm_password: "تأكيد كلمة المرور",
            enter_new_password: "أدخل كلمة المرور الجديدة",
            enter_password_again: "أدخل كلمة المرور مرة أخرى",
            enter_current_password: "أدخل كلمة السر الحالية",
            use_at_least_8_characters: "استخدم على الأقل 8 أحرف",
            use_mix_of_letters_numbers_special_chars:
              "استخدم مزيجًا من الأحرف والأرقام والرموز الخاصة (مثل: #$%)",
            create_password: "إنشاء كلمة المرور",
            current_password: "كلمة السر الحالية",
            save_changes: "حفظ التغييرات",
          },
          profile_layout: {
            start_create_account: "ابدأ في إنشاء حسابك",
            your_email: "بريدك الإلكتروني",
          },
          stepper: {
            create_password: "إنشاء كلمة المرور",
            complete_profile: "إكمال الملف الشخصي",
            complete_business_profile: "إكمال ملف العمل",
            setup_your_plan: "إعداد خطتك",
          },
          create_account: {
            complete_profile: "اكمل الملف الشخصي",
            heading: "اكمل الملف الشخصي",
            upload_picture: "تحميل الصورة",
            change_picture: "تغيير الصورة",
            fullname: "الاسم الكامل",
            enter_name: "أدخل الاسم الكامل",
            arabicname: "الاسم بالعربية",
            enter_arabic_name: "أدخل الاسم بالعربية",
            phonenumber: "رقم الهاتف",
            enter_phone_number: "أدخل رقم الهاتف",
            continue: "استمر",
            image_upload_error: "خطأ في تحميل الصورة",
            image_upload_info:
              "*ملفات png, .jpeg تصل إلى 10 ميجابايت، على الأقل 400px في 400px",
          },
          business_profile: {
            complete_business_profile: "ملف تعريف العمل الكامل",
            heading: "ملف تعريف العمل الكامل",
            company_info: "معلومات الشركة",
            company_name: "اسم الشركة",
            enter_company_name: "ادخل اسم الشركة",
            company_address: "عنوان الشركة",
            enter_company_address: "ادخل عنوان الشركة",
            website: "الموقع الإلكتروني",
            commercial_register: "السجل التجاري",
            enter_commercial_register: "ادخل السجل التجاري",
            vat_number: "رقم الضريبة على القيمة المضافة (VAT)",
            enter_vat_number: "ادخل رقم الضريبة على القيمة المضافة (VAT)",
            business_type: "نوع عملك",
            select_business_type: "اختر نوع العمل",
            continue: "استمرار",
            coffee_shop: "مقهى",
            restaurant: "مطعم",
            shop: "متجر",
            super_market: "سوبر ماركت",
            food_truck: "شاحنة طعام",
            gift_shop: "متجر هدايا",
            hair_dressers: "صالونات تصفيف الشعر",
            car_services: "خدمات السيارات",
            express_services: "خدمات سريعة",
            delete_and_start: "حذف البيانات والبدء من جديد",
            invoice_template: "قالب الفاتورة",
            edit_template: "تحرير قالب الفاتورة",
          },
          client_dashboard: {
            overview: "نظرة عامة",
            messages: "الرسائل",
            invoices: {
              name: "الفواتير",
              sales: {
                name: "المبيعات",
                create_invoice: "إنشاء فاتورة",
                customer: "العميل",
                add_new_customer: "إضافة عميل جديد",
                add_customer: "إضافة عميل",
                select_customer: "اختر العميل",
                store: "المتجر",
                select_store: "اختر المتجر",
                sale_date: "تاريخ البيع",
                payment_method: "طريقة الدفع",
                select_payment_method: "اختر طريقة الدفع",
                item_details: "تفاصيل العنصر",
                item: "العنصر",
                select_item: "اختر العنصر",
                available_quantity: "الكمية المتاحة",
                quantity: "الكمية",
                enter_quantity: "أدخل الكمية",
                unit_price: "سعر الوحدة",
                before_vat: "قبل الضريبة",
                vat_amount: "مبلغ الضريبة",
                total: "الإجمالي",
                note: "ملاحظة",
                enter_note: "أدخل الملاحظة",
                tabs: {
                  all: "الكل",
                  paid: "المدفوعة",
                  pos_transaction: "عملية نقطة البيع",
                },
                validations: {
                  customer_error: "العميل مطلوب!",
                  store_error: "المتجر مطلوب!",
                  date_error: "التاريخ مطلوب!",
                  paymentmethod_error: "طريقة الدفع مطلوبة!",
                  item_error: "العنصر مطلوب!",
                  quantity_error: "الكمية مطلوبة!",
                  unitprice_error: "سعر الوحدة مطلوب!",
                  beforetax_error: "الضريبة مطلوبة قبل الضريبة!",
                  taxamount_error: "مبلغ الضريبة مطلوب!",
                  subtotal_error: "المجموع الفرعي مطلوب!",
                  note_error: "الملاحظة مطلوبة!",
                },
              },

              purchase: {
                name: "الشراء",
                create_invoice: "إنشاء فاتورة",
                supplier: "المورّد",
                add_new_supplier: "إضافة مورّد جديد",
                add_supplier: "إضافة مورد",
                choose_supplier: "اختر المورّد",
                store: "المتجر",
                select_store: "اختر المتجر",
                supply_date: "تاريخ التوريد",
                payment_method: "طريقة الدفع",
                select_payment_method: "اختر طريقة الدفع",
                payment_status: "حالة الدفع",
                select_payment_status: "اختر حالة الدفع",
                item_details: "تفاصيل العنصر",
                item: "العنصر",
                select_item: "اختر العنصر",
                available_quantity: "الكمية المتوفرة",
                quantity: "الكمية",
                enter_quantity: "أدخل الكمية",
                unit_price: "سعر الوحدة",
                before_tax: "قبل الضريبة",
                tax_amount: "مبلغ الضريبة",
                subtotal: "المجموع الفرعي",
                note: "ملاحظة",
                enter_note: "أدخل الملاحظة",
                tabs: {
                  all: "الكل",
                  fully_paid: "مدفوع بالكامل",
                  partially_paid: "مدفوع جزئياً",
                },
                validations: {
                  supplierid_error: "المورّد مطلوب!",
                  store_error: "المتجر مطلوب!",
                  date_error: "التاريخ مطلوب!",
                  paymentmethod_error: "طريقة الدفع مطلوبة!",
                  paymentStatus_error: "حالة الدفع مطلوبة!",
                  item_error: "العنصر مطلوب!",
                  quantity_error: "الكمية مطلوبة!",
                  quantity_error_max:
                    "الحد الأقصى للكمية المسموح به هو 1،000،000!",
                  unitprice_error: "سعر الوحدة مطلوب!",
                  beforetax_error: "قبل الضريبة مطلوب!",
                  taxamount_error: "مبلغ الضريبة مطلوب!",
                  subtotal_error: "المجموع الفرعي مطلوب!",
                  note_error: "الملاحظة مطلوبة!",
                  only_numbers_error: "يُسمح فقط بالأرقام!",
                },
              },
              expense: {
                name: "المصروفات",
                create_invoice: "إنشاء فاتورة",
                expense_name: "اسم المصروف",
                enter_expense_name: "ادخل اسم المصروف",
                invoice_no: "رقم الفاتورة",
                enter_invoice_no: "ادخل رقم الفاتورة",
                store: "المتجر",
                select_store: "اختر المتجر",
                supply_date: "تاريخ التوريد",
                payment_method: "طريقة الدفع",
                select_payment_method: "اختر طريقة الدفع",
                expense_details: "تفاصيل المصروف",
                expense_category: "تصنيف المصروف",
                select_expense_category: "اختر تصنيف المصروف",
                amount: "المبلغ",
                enter_amount: "ادخل المبلغ",
                before_tax: "قبل الضريبة",
                enter_before_tax_price: "ادخل سعر قبل الضريبة",
                tax_amount: "مبلغ الضريبة",
                enter_tax_amount: "ادخل مبلغ الضريبة",
                total: "المجموع",
                enter_total_amount: "ادخل المجموع",
                note: "ملاحظة",
                enter_note: "ادخل ملاحظة",
                sure: "هل أنت متأكد أنك تريد حذف هذه الفاتورة؟",
                validations: {
                  expensename_error: "اسم المصروف مطلوب!",
                  expensename_error_min:
                    "يجب أن يكون اسم المصروف على الأقل 3 أحرف",
                  expensename_error_max: "يجب ألا يزيد اسم المصروف عن 100 حرف",
                  invoicenumber_error: "رقم الفاتورة مطلوب!",
                  invoicenumber_error_invalid: "يُسمح فقط بالأرقام!",
                  invoicenumber_error_min:
                    "يجب أن يكون رقم الفاتورة على الأقل 3 أرقام!",
                  max_number_error: "الحد الأقصى المسموح به هو 1،000،000!",
                  store_error: "المتجر مطلوب!",
                  date_error: "التاريخ مطلوب!",
                  paymentmethod_error: "طريقة الدفع مطلوبة!",
                  expensecategory_error: "تصنيف المصروف مطلوب!",
                  amount_error: "المبلغ مطلوب!",
                  beforetax_error: "المبلغ قبل الضريبة مطلوب!",
                  taxamount_error: "مبلغ الضريبة مطلوب!",
                  total_error: "المجموع مطلوب!",
                  note_error: "الملاحظة مطلوبة!",
                },
              },
            },
            inventory: {
              name: "المخزون",
              inventory_transfer: {
                name: "نقل المخزون",
                sure: "هل أنت متأكد أنك تريد حذف عملية نقل الجرد هذه؟",
                add_inventory: "إضافة نقل",
                add_more: "إضافة المزيد من العناصر",
                storefrom: "المتجر (من)",
                select_storefrom: "اختر المتجر (من)",
                storeto: "المتجر (إلى)",
                select_storeto: "اختر المتجر (إلى)",
                date: "التاريخ",
                item: "العنصر",
                select_item: "اختر العنصر",
                unitmeasure: "وحدة القياس",
                quantity: "الإجمالية",
                enter_quantity: "أدخل الكمية",
                total_quantity: "الكمية الإجمالية",
                number_unit: "عدد وحدات القياس",
                enter_number_unit: "ادخل عدد وحدات القياس",
                description: "الوصف",
                enter_description: "ادخل الوصف",

                validations: {
                  storeto_error: "المتجر المقصود (الوجهة) مطلوب!",
                  storefrom_error: "المتجر المصدر مطلوب!",
                  date_error: "التاريخ مطلوب!",
                  itemid_error: "العنصر مطلوب!",
                  unitmeasure_error: "وحدة القياس مطلوبة!",
                  numberunit_error: "عدد الوحدات مطلوب!",
                  quantity_error: "الكمية مطلوبة!",
                  description_error: "الوصف مطلوب!",
                },
              },
              stocktaking: {
                name: "الجرد",
                sure: "هل أنت متأكد أنك تريد حذف عملية الجرد هذه؟",
                create_stocktaking: "إنشاء جرد",
                count_name: "اسم العد",
                enter_count_name: "ادخل اسم العد",
                description: "الوصف",
                enter_description: "ادخل الوصف",
                date: "التاريخ",
                store: "المتجر",
                select_store: "اختر المتجر",
                item_details: "تفاصيل العنصر",
                detail: "تفاصيل",
                item: "العنصر",
                select_item: "اختر العنصر",
                unitmeasure: "وحدة القياس",
                expected: "المتوقع",
                quantity: "الكمية",
                enter_quantity: "ادخل الكمية",
                cost_price: "سعر التكلفة",
                note: "ملاحظة",
                enter_note: "ادخل الملاحظة",
                validations: {
                  countName_error: "اسم العد مطلوب",
                  countName_error_max: "الحد الأقصى لعدد الأحرف هو 100",
                  description_error: "الوصف مطلوب!",
                  description_error_max: "الحد الأقصى لعدد الأحرف هو 200",
                  date_error: "التاريخ مطلوب!",
                  store_error: "المتجر مطلوب!",
                  item_error: "العنصر مطلوب!",
                  expected_error: "يجب أن تكون القيمة المتوقعة رقمًا",
                  quantity_error: "الكمية مطلوبة!",
                  quantity_error_max: "الحد الأقصى للكمية هو 1،000،000",
                  costPrice_error: "يجب أن يكون سعر التكلفة رقمًا",
                  note_error: "",
                },
              },

              inventory_transaction: {
                name: "عملية المخزون",
                inventory_transfer: "نقل المخزون",
                stocktaking: "جرد المخزون",
                sale_invoices: "فواتير البيع",
                purchase_invoices: "فواتير الشراء",
              },
            },
            directory: {
              name: "الدليل",
              customer: {
                add_customer: "إضافة عميل",
                name: "العميل",
                customer_name: "اسم العميل",
                enter_name: "ادخل اسم العميل",
                email: "البريد الإلكتروني",
                enter_email: "ادخل البريد الإلكتروني",
                phone_number: "رقم الهاتف",
                enter_phone: "ادخل رقم الهاتف",
                select_gender: "اختر الجنس",
                male: "ذكر",
                female: "أنثى",
                delete: "تم حذف العميل بنجاح!",
                sure: "هل أنت متأكد أنك تريد حذف هذا العميل؟",
                validations: {
                  name_error: "يجب أن يكون الاسم الكامل على الأقل 3 أحرف!",
                  name_error_max: "يسمح بحد أقصى 100 حرف!",
                  email_error: "البريد الإلكتروني مطلوب!",
                  email_error_valid: "يرجى استخدام عنوان بريد إلكتروني صالح!",
                  phone_error: "رقم الجوال مطلوب",
                  phone_format:
                    "يمكن أن يحتوي رقم الجوال على أرقام فقط ويمكن أن يبدأ بعلامة '+'",
                  phone_format_plus:
                    "لا يمكن أن يبدأ رقم الجوال بصفر بعد علامة '+'",
                  phone_min: "يجب أن يحتوي رقم الجوال على 7 أرقام على الأقل",
                  phone_max: "لا يمكن أن يحتوي رقم الجوال على أكثر من 15 رقمًا",
                  gender_error: "الجنس مطلوب!",
                },
              },
              supplier: {
                name: "الموردين",
                sure: "هل أنت متأكد أنك تريد حذف هذا المورد؟",
                add_supplier: "إضافة مورد",
                english_name: "الاسم بالإنجليزية",
                company_name: "اسم الشركة",
                arabic_name: "الاسم بالعربية",
                enter_english_name: "ادخل الاسم بالإنجليزية",
                enter_company_name: "أدخل اسم الشركة",
                email: "البريد الإلكتروني",
                enter_email: "ادخل البريد الإلكتروني",
                phone_number: "رقم الهاتف",
                enter_address: "العنوان",
                enter_addreess: "العنوان",
                enter_phone: "ادخل رقم الهاتف",
                vat_number: "رقم الضريبة المضافة",
                enter_vatnumber: "ادخل رقم الضريبة المضافة",
                delete: "تم حذف العميل بنجاح!",
                validations: {
                  englishname: "يجب أن يكون الاسم الكامل على الأقل 3 أحرف!",
                  name_error_max: "يسمح بحد أقصى 100 حرف!",
                  email_error: "البريد الإلكتروني مطلوب!",
                  email_error_valid: "يرجى استخدام عنوان بريد إلكتروني صالح!",
                  phone_error: "يجب أن يبدأ رقم الهاتف ب '+'!",
                  phone_error_max: "يجب ألا يتجاوز رقم الهاتف 15 رقمًا!",
                  phone_error_regex:
                    "يجب أن يبدأ رقم الهاتف بـ + ويتبعه أرقامًا!",
                  error_address: "العنوان مطلوب",
                  error_address_max: "يجب ألا يتجاوز العنوان 100 حرف!",
                  company_error: "اسم الشركة مطلوب!",
                  company_error_max: "يجب ألا يتجاوز اسم الشركة 100 حرف!",
                  vat_error:
                    "يجب أن يكون رقم ضريبة القيمة المضافة مكونًا من 15 حرفًا بالضبط.",
                },
              },
              items: {
                name: "العناصر",
                sure: "هل أنت متأكد أنك تريد حذف هذا العنصر؟",
                simple_item: {
                  name: "العنصر البسيط",
                  english_name: "الاسم بالإنجليزية",
                  enter_english_name: "أدخل الاسم بالإنجليزية",
                  arabic_name: "الاسم بالعربية",
                  enter_arabic_name: "أدخل الاسم بالعربية",
                  description: "الوصف",
                  enter_description: "أدخل الوصف",
                  select_store: "اختر المتجر",
                  item_cost: "تكلفة العنصر",
                  enter_item_cost: "أدخل تكلفة العنصر",
                  select_category: "اختر الفئة",
                  sale_price: "سعر البيع",
                  enter_sale_price: "أدخل سعر البيع",
                  enter_barcode: "أدخل الرمز الشريطي",
                  create_barcode_num: "إنشاء رقم الرمز الشريطي",
                  validations: {
                    englishname_error: "الاسم بالإنجليزية مطلوب!",
                    englishname_error_min:
                      "يجب أن يكون الاسم بالإنجليزية على الأقل 3 أحرف!",
                    englishname_error_max:
                      "يجب ألا يزيد الاسم بالإنجليزية عن 100 حرف!",
                    arabicname_error: "الاسم بالعربية مطلوب!",
                    arabicname_error_min:
                      "يجب أن يكون الاسم بالعربية على الأقل 3 أحرف!",
                    arabicname_error_max:
                      "يجب ألا يزيد الاسم بالعربية عن 100 حرف!",
                    description_error: "الوصف مطلوب!",
                    description_error_min:
                      "يجب أن يكون الوصف على الأقل 3 أحرف!",
                    description_error_max: "يجب ألا يزيد الوصف عن 500 حرف!",
                    store_error: "المتجر مطلوب!",
                    itemcost_error: "تكلفة العنصر مطلوبة!",
                    itemcost_error_min: "يجب أن تكون تكلفة العنصر على الأقل 1!",
                    itemcost_error_max: "العدد الأقصى المسموح به هو 1,000,000!",
                    category_error: "الفئة مطلوبة!",
                    saleprice_error: "سعر البيع مطلوب!",
                    saleprice_error_min: "يجب أن يكون سعر البيع على الأقل 1!",
                    saleprice_error_max:
                      "العدد الأقصى المسموح به هو 1,000,000!",
                    number_only_error: "الأرقام فقط مسموح بها!",
                  },
                },
                variable_item: {
                  name: "عنصر متغير",
                  item_name: "اسم العنصر",
                  enter_item_name: "أدخل اسم العنصر",
                  arabic_name: "الاسم بالعربي",
                  enter_arabic_name: "أدخل الاسم بالعربي",
                  description: "الوصف",
                  enter_description: "أدخل الوصف",
                  store: "المتجر",
                  select_store: "اختر المتجر",
                  category: "الفئة",
                  select_category: "اختر الفئة",
                  variables: "متغيرات",
                  variable: "متغير",
                  variable_name: "اسم المتغير",
                  select_variable_name: "اختر اسم المتغير",
                  value: "القيمة",
                  enter_value: "أدخل القيمة",
                  add_another_variable: "أضف متغيرًا آخر",
                  validations: {
                    englishname_required_error: "الاسم بالإنجليزية مطلوب!",
                    englishname_error_min:
                      "يجب أن يكون الاسم بالإنجليزية ثلاثة أحرف على الأقل!",
                    englishname_error_max:
                      "يجب ألا يزيد الاسم بالإنجليزية عن 100 حرف!",
                    arabicname_required_error: "الاسم بالعربية مطلوب!",
                    arabicname_error_min:
                      "يجب أن يكون الاسم بالعربية ثلاثة أحرف على الأقل!",
                    arabicname_error_max:
                      "يجب ألا يزيد الاسم بالعربية عن 100 حرف!",
                    description_required_error: "الوصف مطلوب!",
                    description_error_min:
                      "يجب أن يكون الوصف ثلاثة أحرف على الأقل!",
                    description_error_max: "يجب ألا يزيد الوصف عن 500 حرف!",
                    store_required_error: "المتجر مطلوب!",
                    category_required_error: "الفئة مطلوبة!",
                    variablename_required_error: "اسم المتغير مطلوب!",
                    value_required_error: "القيمة مطلوبة!",
                  },
                },
                compositing_item: {
                  name: "عنصر التركيب",
                  item_name: "اسم العنصر",
                  arabic_item_name: "اسم العنصر بالعربي",
                  description: "الوصف",
                  select_store: "اختر المتجر",
                  select_category: "اختر الفئة",
                  sale_price_without_tax: "سعر البيع (بدون ضريبة)",
                  compositing_product: "التكوين",
                  raw_items: "العناصر الخام",
                  select_raw_items: "اختر العناصر الخام",
                  unit_of_measure: "وحدة القياس",
                  per_unit_price: "سعر الوحدة",
                  number_unit_of_measure: "عدد وحدات القياس",
                  item_cost: "تكلفة العنصر",
                  total_cost: "التكلفة الكلية",
                  composition_label: "التكوين",
                  add_another_composition: "أضف تكوين آخر",
                  create_sku_number: "إنشاء رقم SKU",
                  enter_item_name: "أدخل اسم العنصر",
                  enter_arabic_item_name: "أدخل اسم العنصر بالعربي",
                  enter_description: "أدخل الوصف",
                  enter_sale_price: "أدخل سعر البيع",
                  enter_number_unit_of_measure: "أدخل عدد وحدات القياس",

                  validations: {
                    englishname_error: "اسم اللغة الإنجليزية مطلوب!",
                    englishname_error_min:
                      "يجب أن يكون اسم اللغة الإنجليزية على الأقل 3 أحرف!",
                    englishname_error_max:
                      "يجب ألا يتجاوز اسم اللغة الإنجليزية 100 حرف!",
                    arabicname_error: "اسم اللغة العربية مطلوب!",
                    arabicname_error_min:
                      "يجب أن يكون اسم اللغة العربية على الأقل 3 أحرف!",
                    arabicname_error_max:
                      "يجب ألا يتجاوز اسم اللغة العربية 100 حرف!",
                    description_error: "الوصف مطلوب!",
                    description_error_min:
                      "يجب أن يكون الوصف على الأقل 3 أحرف!",
                    description_error_max: "يجب ألا يتجاوز الوصف 500 حرف!",
                    store_error: "المتجر مطلوب!",
                    category_error: "الفئة مطلوبة!",
                    saleprice_error: "سعر البيع مطلوب!",
                    saleprice_error_min: "يجب أن يكون سعر البيع على الأقل 1!",
                    saleprice_error_max:
                      "الحد الأقصى للعدد المسموح به هو 1،000،000!",
                    totalcost_error: "التكلفة الإجمالية مطلوبة!",
                    totalcost_error_min:
                      "يجب أن تكون التكلفة الإجمالية على الأقل 1!",
                    totalcost_error_max:
                      "الحد الأقصى للعدد المسموح به هو 1،000،000!",
                    itemcost_error: "تكلفة العنصر مطلوبة!",
                    itemcost_error_min: "يجب أن تكون تكلفة العنصر على الأقل 1!",
                    itemcost_error_max:
                      "الحد الأقصى للعدد المسموح به هو 1،000،000!",
                    rawitem_error: "العنصر الخام مطلوب!",
                    unitmeasure_error: "وحدة القياس اختيارية",
                    perunitprice_error: "سعر الوحدة مطلوب!",
                    perunitprice_error_min:
                      "يجب أن يكون سعر الوحدة على الأقل 1!",
                    perunitprice_error_max:
                      "الحد الأقصى للعدد المسموح به هو 1،000،000!",
                    numberunit_error: "عدد الوحدات مطلوب!",
                    numberunit_error_min:
                      "يجب أن يكون عدد الوحدات على الأقل 1!",
                    numberunit_error_max:
                      "الحد الأقصى للعدد المسموح به هو 1،000،000!",
                    number_only_error: "الأرقام فقط مسموح بها!",
                  },
                },
                raw_item: {
                  name: "العنصر الأساسي",
                  item_name: "اسم العنصر",
                  enter_item_name: "أدخل اسم العنصر",
                  arabic_item_name: "اسم العنصر بالعربي",
                  enter_arabic_item_name: "أدخل اسم العنصر بالعربي",
                  description: "الوصف",
                  enter_description: "أدخل الوصف",
                  select_store: "اختر المتجر",
                  item_cost: "تكلفة العنصر",
                  enter_item_cost: "أدخل تكلفة العنصر",
                  sale_price: "سعر البيع",
                  enter_sale_price: "أدخل سعر البيع",
                  select_category: "اختر الفئة",
                  unit_of_measure: "وحدة القياس",
                  number_unit_of_measure: "عدد وحدات القياس",
                  enter_number_unit_of_measure: "أدخل عدد وحدات القياس",
                  create_sku_number: "إنشاء رقم SKU",
                  validations: {
                    englishname_required_error: "الاسم بالإنجليزية مطلوب!",
                    englishname_error_min:
                      "يجب أن يكون الاسم بالإنجليزية ثلاثة أحرف على الأقل!",
                    englishname_error_max:
                      "يجب ألا يزيد الاسم بالإنجليزية عن 100 حرف!",
                    arabicname_required_error: "الاسم بالعربية مطلوب!",
                    arabicname_error_min:
                      "يجب أن يكون الاسم بالعربية ثلاثة أحرف على الأقل!",
                    arabicname_error_max:
                      "يجب ألا يزيد الاسم بالعربية عن 100 حرف!",
                    description_required_error: "الوصف مطلوب!",
                    description_error_min:
                      "يجب أن يكون الوصف ثلاثة أحرف على الأقل!",
                    description_error_max: "يجب ألا يزيد الوصف عن 500 حرف!",
                    store_error_min: "المتجر مطلوب!",
                    itemcost_number_only_error: "يسمح بالأرقام فقط!",
                    itemcost_error_min: "تكلفة العنصر مطلوبة!",
                    itemcost_error_max: "الحد الأقصى المسموح به هو 1,000,000!",
                    saleprice_number_only_error: "يسمح بالأرقام فقط!",
                    saleprice_error_min: "سعر البيع مطلوب!",
                    saleprice_error_max: "الحد الأقصى المسموح به هو 1,000,000!",
                    category_error_min: "الفئة مطلوبة!",
                    unitmeasure_error_min: "وحدة القياس مطلوبة!",
                    numberunit_number_only_error: "يسمح بالأرقام فقط!",
                    numberunit_error_min: "عدد الوحدات مطلوب!",
                    numberunit_error_max:
                      "الحد الأقصى المسموح به هو 1,000,000!",
                  },
                },
              },

              category: {
                name: "الفئة",
                add_category: "إضافة فئة",
                english_name: "الاسم بالإنجليزية",
                arabic_name: "الاسم بالعربية",
                enter_english_name: "ادخل الاسم بالإنجليزية",
                enter_arabic_name: "ادخل الاسم بالعربية",
                view_this_category: "عرض هذه الفئة في نقاط البيع",
                sure: "هل أنت متأكد أنك تريد حذف هذه الفئة؟",
                validations: {
                  englishname_error:
                    "يجب أن يكون الاسم بالإنجليزية على الأقل 3 أحرف!",
                  englishname_error_max: "يسمح بحد أقصى 100 حرف!",
                },
              },
            },
            report: {
              name: "تقرير",
              sales: "المبيعات",
              purchase: "الشراء",
              taxes: "الضرائب",
              profit_loss: "الربح والخسارة",
              expenses: "المصروفات",
              inventory: "المخزون",
            },
            management: {
              name: "الإدارة",
              setting: "الإعدادات",
              users: {
                name: "المستخدمين",
                sure: "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
                add_user: "إضافة مستخدم",
                user_name: "الاسم",
                enter_name: "أدخل الاسم",
                email: "البريد الإلكتروني",
                enter_email: "أدخل البريد الإلكتروني",
                create_password: "إنشاء كلمة المرور",
                enter_password: "أدخل كلمة المرور",
                code: "رمز الوصول السريع لنقاط البيع",
                enter_code: "أدخل الرمز",
                access_to_dashboard: "الوصول إلى لوحة التحكم",
                access_to_pos: "الوصول إلى نقاط البيع",
                store: "المتجر",
                select_store: "اختر المتجر",
                select_role: "اختر الدور",
                roles: {
                  cashier: "أمين صندوق",
                  sales_manager: "مدير مبيعات",
                  system_manager: "مدير النظام",
                },
                validations: {
                  name_error: "!يجب أن يكون الاسم على الأقل 3 أحرف",
                  name_error_max: "!يسمح بحد أقصى 100 حرف",
                  email_error: "!البريد الإلكتروني مطلوب",
                  email_error_valid: "!يرجى استخدام عنوان بريد إلكتروني صالح",
                  password_error: "!يجب أن تكون كلمة المرور على الأقل 8 أحرف!",
                  password_error_uppercase:
                    "!يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل",
                  password_error_special:
                    "!يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل",
                  password_error_numeric:
                    "!يجب أن تحتوي كلمة المرور على رقم واحد على الأقل",
                  role_error: "!الدور مطلوب",
                  store_error: "يجب عليك اختيار متجر واحد على الأقل!",
                  code_error: "!يجب أن يكون الرمز رقمًا مكونًا من 4 أرقام",
                  only_numbers_error: "!يُسمح فقط بالأرقام",
                },
              },
              stores: {
                name: "المتاجر",
                sure: "هل أنت متأكد أنك تريد حذف هذا المتجر؟",
                add_store: "إضافة متجر",
                branch_name: "اسم الفرع",
                enter_name: "أدخل الاسم",
                store_name: "اسم متجر",
                store_address: "عنوان المتجر",
                street_name: "اسم الشارع",
                enter_street_name: "أدخل اسم الشارع",
                building_no: "رقم المبنى",
                enter_building_no: "أدخل رقم المبنى",
                district_name: "اسم الحي",
                enter_district_name: "أدخل اسم الحي",
                city: "المدينة",
                enter_city: "أدخل المدينة",
                zip_code: "الرمز البريدي",
                enter_zip_code: "أدخل الرمز البريدي",
                validations: {
                  max_100: "يسمح بحد أقصى 100 حرف!",
                  branchname_error: "يجب أن يكون اسم الفرع على الأقل 3 أحرف!",
                  streetname_error: "يجب أن يكون اسم الشارع على الأقل 3 أحرف!",
                  districtname_error: "يجب أن يكون اسم الحي على الأقل 3 أحرف!",
                  cityname_error: "يجب أن يكون اسم المدينة على الأقل 3 أحرف!",
                  zipcode_error_min: "الرمز البريدي مطلوب",
                  zipcode_error_max: "الحد الأقصى للأرقام 12",
                  buildingno_error: "رقم المبنى مطلوب!",
                  buildingno_error_max: "يسمح بحد أقصى 4 أرقام!",
                  only_numbers_error: "يُسمح فقط بالأرقام!",
                },
              },

              payments: {
                name: "المدفوعات",
                add_payment: "إضافة طريقة دفع",
                what_kind: "ما نوع الدفع؟",
                sure: "هل أنت متأكد أنك تريد حذف طريقة الدفع هذه؟",
                points: {
                  name: "النقاط",
                  value_per_point: "قيمة كل نقطة",
                  value_sub_title: "”كم تعادل قيمة كل 1 ريال سعودي من النقاط ”",
                  enter_value_per_point: "أدخل قيمة كل نقطة",
                  point_per_value: "نقطة لكل قيمة",
                  point_sub_title: "“كم تعادل كل نقطة من الريال السعودي “",
                  enter_points_per_value: "أدخل عدد النقاط لكل قيمة",
                  min_replacement_app: "أدنى قيمة لاستبدال الطلب",
                  enter_min_replacement_app: "أدخل أدنى قيمة لاستبدال الطلب",
                  min_replacement_app_subtitle:
                    "“ما هي أقل قيمة للفاتورة المقبولة”",
                  min_replacement_point: "أدنى قيمة لاستبدال النقاط",
                  enter_minimum_replacement_point:
                    "أدخل أدنى قيمة لاستبدال النقاط",
                  min_replacement_point_subtitle:
                    "“ما هي أقل قيمة للفاتورة المقبولة”",
                  validations: {
                    only_numbers: "يُسمح بالأرقام فقط!",
                    value_per_point_min_error: "قيمة كل نقطة مطلوبة!",
                    point_per_value_min_error: "نقطة لكل قيمة مطلوبة!",
                    min_replacement_app_min_error:
                      "أدنى قيمة لاستبدال الطلب مطلوبة!",
                    min_replacement_points_min_error:
                      "أدنى قيمة لاستبدال النقاط مطلوبة!",
                  },
                },
                delivery_company: {
                  name: "شركات التوصيل",
                  company: "شركة",
                  add_company: "إضافة شركة",
                  delivery_company_name: "اسم شركة التوصيل",
                  enter_delivery_company: "أدخل اسم شركة التوصيل",
                  fee_percentage: "نسبة الرسوم",
                  enter_fee: "أدخل نسبة الرسوم",
                  ammount_added: "المبلغ المضاف",
                  enter_amount: "أدخل المبلغ المضاف",
                  add_another: "أضف شركة أخرى",
                  add_method: "أضف طريقة",
                  validations: {
                    company_name_min_error:
                      "يجب أن يكون اسم الشركة 3 أحرف على الأقل!",
                    company_name_max_error: "يُسمح بحد أقصى 30 حرفًا!",
                    fee_percentage_invalid_type_error: "يُسمح بالأرقام فقط!",
                    fee_percentage_min_error: "نسبة الرسوم مطلوبة!",
                    amount_added_invalid_type_error: "يُسمح بالأرقام فقط!",
                    amount_added_min_error: "المبلغ المضاف مطلوب!",
                  },
                },
                cash: {
                  name: "نقد",
                  text: "أنت على وشك إضافة النقد كطريقة دفع في نظامك. كل معاملة باستخدام هذه الطريقة ستُسجل/يُشار إليها/تُعلّم كمعاملة نقدية. يمكنك إزالتها أو تعديلها في أي وقت.",
                },
                card: {
                  name: "بطاقة",
                  title: "اختر الطريقة المسموح بها في نظامك",
                  american_express: "أمريكان إكسبريس",
                  visa: "فيزا",
                  master_card: "ماستر كارد",
                  wise: "وايز",
                  revolut: "ريفولوت",
                },
              },
              promotions: {
                name: "العروض الترويجية",
                sure: "هل أنت متأكد أنك تريد حذف هذا العرض الترويجي؟",
                add_promotions: "إضافة عرض ترويجي",
                enter_english_name: "أدخل الاسم بالإنجليزية",
                english_name: "الاسم بالإنجليزية",
                start_date: "تاريخ البدء",
                end_date: "تاريخ الانتهاء",
                promotion_type: "نوع الترويج",
                promotion_store: "حدد المتجر",
                promotion_value: "قيمة الترويج",
                select_type: "اختر النوع",
                promotion_select_item: "اختر عنصر",
                promotion_all_product: "جميع المنتجات",
                promotion_product: "المنتج",
                validations: {
                  name_error_min: "يجب أن يكون اسم العرض أكثر من 3 أحرف!",
                  name_error_max: "يجب ألا يتجاوز اسم العرض 100 حرف!",
                  start_date_error: "تاريخ البدء مطلوب!",
                  end_date_error: "تاريخ الانتهاء مطلوب!",
                  type_error: "نوع الترويج مطلوب!",
                  percentage_error: "النسبة مطلوبة!",
                  only_numbers: "يُسمح بالأرقام فقط!",
                  percentage_limit: "يجب أن تكون النسبة رقمًا بين 1 و 100",
                  store_error: "يجب اختيار متجر واحد على الأقل",
                  select_type: "",
                  choose_error: "اختر منتجًا واحدًا أو جميع المنتجات",
                  select_product_error:
                    "يجب عليك تحديد منتج واحد أو تحديد 'جميع المنتجات'",
                  enter_promotion_value: "أدخل قيمة العرض الترويجي",
                  value_error_max: "يجب ألا تتجاوز القيمة 10 أحرف",
                },
              },
              settings: {
                profile: {
                  name: "الملف الشخصي",
                  profile_pic: "صورة الملف الشخصي",
                  english_name: "الاسم بالإنجليزية",
                  enter_english_name: "ادخل الاسم بالإنجليزية",
                  arabic_name: "الاسم بالعربية",
                  enter_arabic_name: "ادخل الاسم بالعربية",
                  email: "البريد الإلكتروني",
                  enter_email: "ادخل البريد الإلكتروني",
                  phone: "رقم الهاتف",
                  enter_phone: "ادخل رقم الهاتف",
                  change_picture: "تغيير الصورة",
                  supported_format: "التنسيق المدعوم",
                  validations: {
                    englishname_error:
                      "يجب أن يكون الاسم بالإنجليزية على الأقل 3 أحرف",
                    englishname_error_max: "يسمح بحد أقصى 100 حرف",
                    arabicname_error:
                      "يجب أن يكون الاسم بالعربية على الأقل 3 أحرف",
                    arabicname_error_max: "يسمح بحد أقصى 100 حرف",
                    email_error: "البريد الإلكتروني مطلوب",
                    email_error_valid: "يرجى استخدام عنوان بريد إلكتروني صالح",
                    phone_error: "يجب أن يكون رقم الهاتف على الأقل 3 أرقام",
                    phone_error_max: "يجب ألا يتجاوز رقم الهاتف 15 رقمًا",
                    phone_error_format:
                      "يجب أن يبدأ رقم الهاتف ب '+' ويتبعه أرقام صحيحة",
                    img_error: "الصورة مطلوبة",
                    img_error_dimensions:
                      "أبعاد الصورة يجب أن تكون على الأقل 400 بكسل في 400 بكسل",
                    img_err_size: "يجب ألا يتجاوز حجم الملف 2 ميغابايت",
                    img_err_type:
                      "نوع الملف غير صالح. يُسمح فقط بالملفات من نوع PNG و JPEG",
                    img_err_cumpolsory: "يجب تحميل صورة",
                  },
                },
                business_info: {
                  name: "معلومات الشركة",
                  validations: {
                    companyname_error:
                      "اسم الشركة يجب أن يكون على الأقل 3 أحرف!",
                    companyname_error_max: "يسمح بحد أقصى 100 حرف!",
                    companyaddress_error:
                      "عنوان الشركة يجب أن يكون على الأقل 3 أحرف!",
                    companyaddress_error_max: "يسمح بحد أقصى 100 حرف!",
                    website_error: "موقع الويب غير صالح!",
                    commercialregister_error:
                      "سجل الشركات يجب أن يكون على الأقل 3 أحرف!",
                    commercialregister_error_max: "يسمح بحد أقصى 100 حرف!",
                    vatnumber_error:
                      "يجب أن يكون رقم الضريبة على القيمة المضافة بطول 15 حرفًا بالضبط.",
                    businesstype_error: "نوع العمل مطلوب!",
                  },
                },

                subscription: "الاشتراك",
                password: {
                  name: "كلمة المرور",
                  validations: {
                    currentpassword_error: "!كلمة المرور الحالية مطلوبة",
                    newpassword_error:
                      "!يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
                    newpassword_error_uppercase:
                      "!يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل",
                    newpassword_error_special:
                      "!يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل",
                    newpassword_error_numeric:
                      "!يجب أن تحتوي كلمة المرور على رقم واحد على الأقل",
                    confirmpassword_error: "!تأكيد كلمة المرور مطلوب",
                    passwords_match_error: "!كلمات المرور غير متطابقة",
                  },
                },
              },
            },
            trial_info: {
              days: "أيام",
              days_left: "تنتهي فترة التجربة خلال",
              plan_info: "الخطة الأساسية مجانية لفترة تجربية.",
              upgrade_button: "ترقية الخطة",
            },
            subscription_info: {
              days: "أيام",
              days_left: "ستنتهي اشتراكك في",
              plan_info: "خطة اشتراكك هي ",
              upgrade_button: "ترقية الخطة",
              monthly: "شهري",
              yearly: "سنوي",
            },
          },

          footer: {
            copyright: "حقوق النشر",
          },
        },
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
