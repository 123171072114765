import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPosTables } from "../../../../features/PosTables/PosTablesSlice";
/**
 * Custom hook for managing table selection functionality in the POS system.
 * Provides methods for fetching POS tables data, managing table selection, and styling tables.
 * @returns {Object} An object containing:
 *   - tableStyles: Object containing styles for different table shapes (rectangle, square, circle).
 *   - getTableStyle: Function to determine the style of a table based on its status (available, selected, reserved).
 *   - toggleTableSelection: Function to toggle the selection of a table.
 *   - tables: Array of objects representing the tables with their statuses.
 *   - posTablesListLoading: Boolean indicating whether POS tables data is currently being loaded.
 */
export const useChooseTable = () => {
  const dispatch = useDispatch();
  const { data: posTablesList, isLoading: posTablesListLoading } = useSelector(
    (state) => state.posTables.getAllPosTables
  );

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllPosTables({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (posTablesList) {
      const dynamicTablesData = posTablesList.map((table) => ({
        id: table.id,
        position: table.position,
        type: table.type.toLowerCase(),
        isReserved: table.isReserved,
        status: table.isReserved ? "reserved" : "available",
      }));
      setTables(dynamicTablesData);
    }
  }, [posTablesList]);

  const [tables, setTables] = useState([]);

  const toggleTableSelection = (id) => {
    setTables(
      tables.map((table) => ({
        ...table,
        status: table.id === id && !table.isReserved ? "selected" : "available",
      }))
    );
  };
  
  const tableStyles = {
    rectangle: {
      width: "220px",
      height: "90px",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px",
      position: "relative",
    },
    square: {
      width: "90px",
      height: "90px",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px",
      position: "relative",
    },
    circle: {
      width: "90px",
      height: "90px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px",
      position: "relative",
    },
  };
  const getTableStyle = (table) => {
    const baseStyles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px",
      cursor: table.isReserved ? "not-allowed" : "pointer",
      backgroundColor: table.status === "selected" ? "#0086FF" : "#fff",
      border: "1px solid #ddd",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    };

    if (table.isReserved) {
      baseStyles.backgroundColor = "#2BE3B7";
    }

    const typeStyle = tableStyles[table.type]; // Accessing the style based on the table's type
    return { ...typeStyle, ...baseStyles }; // Merging type-specific style with base styles
  };

  return {
    getTableStyle,
    toggleTableSelection,
    tables,
    posTablesListLoading,
  };
};
