import React from "react";
import { Controller } from "react-hook-form";
import _ from "lodash";

const CreditCardInput = ({
  control,
  name,
  htmlFor = "",
  labelText = "",
  placeholder = "",
  errors = {},
  className = "",
}) => {
  const handleInputChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    inputValue = inputValue.substring(0, 16); // Limit to 16 digits
    return inputValue.replace(/(\d{4})(?=\d)/g, "$1 ").trim(); // Add space after every 4 digits
  };

  return (
    <div className={`relative space-y-1 ${className}`}>
      {labelText && (
        <label
          htmlFor={htmlFor}
          className="block text-sm font-medium text-gray-700"
        >
          {labelText}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <input
            {...field}
            type="text"
            placeholder={placeholder}
            onChange={(e) => field.onChange(handleInputChange(e))}
            className={`block w-full px-4 py-2 text-text-primary bg-white border border-border-primary rounded-lg focus:border-border-primary focus:ring-0 focus:outline-none ${
              _.get(errors, name) ? "!border-red-500" : ""
            }`}
            autoComplete="off"
          />
        )}
      />
      {_.get(errors, name) && (
        <p className="text-red-500 text-sm">{_.get(errors, name).message}</p>
      )}
    </div>
  );
};

export default CreditCardInput;
