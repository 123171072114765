import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

/**
 * LandingPagesLayout component.
 * Renders the main layout with a header, page content (using React Router Outlet), and a footer.
 *
 * @returns {JSX.Element} JSX code for rendering the Main component.
 */

const LandingPageLayout = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
};

export default LandingPageLayout;
