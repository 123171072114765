import api from "../../services/api";

/**
 * Creates stores API endpoints based on the specified base path.
 * @param {string} basePath - The base path for authentication endpoints.
 * @returns {Object} Object containing stores API methods.
 */
const createAdminSalesApi = (basePath) => ({
  /**
   * get single store
   * @param {object} storeId storeId to retrieve a store
   * @returns {Promise} A Promise that resolves to the store response.
   */
  getSingleClientSale: async (id) => {
    const response = await api().get(`${basePath}/view-client-sales/${id}`);
    return response;
  },
});

// Export the createStoresApi

/**
 * Example usage:
 * import createStoresApi from './path/to/api/posstoresApi';
 *
 * const storesApi = createStoresApi('stores');
 *
 * const getAllPosStores = async (payload) => {
 *   try {
 *     const response = await storesApi.addStore(payload);
      // Handle response
 *   } catch (error) {
      // Handle error
 *   }
 * };
 */

export default createAdminSalesApi;
