// Import the 'api' module for making HTTP requests
import api from "../../services/api";

/**
 * Function to upload a single file using a multipart/form-data request.
 * @param {FormData} payload - FormData containing the file to be uploaded.
 * @returns {Promise} - A promise that resolves with the HTTP response data.
 */
const uploadSingleFile = async (payload) => {
  try {
    // Send a POST request to the '/file-upload/upload' endpoint with the provided FormData payload
    const response = await api().post("/file-upload/upload", payload, {
      headers: { "Content-Type": "multipart/form-data" }, // Set the content type to multipart/form-data
    });

    return response.data; // Return the HTTP response data (usually contains information about the uploaded file)
  } catch (error) {
    throw error; // Propagate any errors that occur during the file upload
  }
};

/**
 * Object that encapsulates the function for uploading files.
 * Provides a function to upload a single file using a FormData payload.
 */
const uploadFileService = {
  uploadSingleFile, // Function for uploading a single file
};

export default uploadFileService; // Export the uploadFileService object
