import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  getAllCustomers,
  addCustomer,
} from "../../../../features/Customers/CustomersSlice";

/**
 * Custom hook for handling customers related functionalities.
 *
 * @returns {Object}
 *   customerList: Array containing customer information for all customers.
 *   isLoading: Data loading state for all customers.
 *   singleCustomer: Object containing customer information.
 *   singleCustomerLoading: Data loading state for single customer information.
 */
const useCustomersList = (closeModal = null, setSelectedCustomer) => {
  const dispatch = useDispatch();

  const { data: customerList, isLoading: customerListLoading } = useSelector(
    (state) => state.customers.getAllCustomers
  );

  const [isOpen, setIsOpen] = useState(false);
  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsOpen(false);
    reset();
  };

  const handleCancel = () => {
    reset();
    setSelectedCustomer(null);
    setIsOpen(false);
    closeModal();
  };

  const onSaveCoustmerSubmit = () => {
    setIsOpen(false);
    closeModal();
  };

  const onSubmit = async (data) => {
    const payload = {
      name: data?.name,
      email: data?.email,
      phoneNumber: data.phonenumber,
      gender: data?.gender,
    };

    // Dispatch addCustomer action with payload
    dispatch(addCustomer({ payload, successCallBack: onSuccessAddCustomer }));
  };
  const onSuccessAddCustomer = (response) => {
    setSelectedCustomer(response?.data?.data);
    closeModal();
  };
  const handleCloseDropdown = (event) => {
    if (
      isOpen &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  // Ref for the dropdown element
  const dropdownRef = React.useRef();

  // useEffect to add event listener on mount and cleanup on unmount
  React.useEffect(() => {
    // Attach event listener
    document.addEventListener("mousedown", handleCloseDropdown);

    // Cleanup event listener
    return () => {
      document.removeEventListener("mousedown", handleCloseDropdown);
    };
  }, [isOpen]);
  // validation schema for add/edit customer form

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(),
  });

  /**
   * Handles the api call for getting all customers
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting customers
   *    order: order for sorting customers by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCustomers({ payload }));
  }, [dispatch]);

  return {
    customerList,
    customerListLoading,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    isSubmitting,
    watch,
    isOpen,
    handleSelectCustomer,
    handleCancel,
    setIsOpen,
    onSubmit,
    onSaveCoustmerSubmit,
    dropdownRef
  };
};

export default useCustomersList;
