import api from "../../services/api";

/**
 * Creates PosTables API endpoints based on the specified base path.
 * @param {string} basePath - The base path for PosTables endpoints.
 * @returns {Object} Object containing PosTables API methods.
 */
const createPosTablesApi = (basePath) => ({
  /**
   * get all PosTables
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting PosTables
   *    order: order for sorting PosTables by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the PosTables response.
   */
  getAllPosTables: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * get a single PosTable
   * @param {object} tableId - id of the PosTable
   * @returns {Promise} A Promise that resolves to the PosTable response.
   */
  getSinglePosTable: async (tableId) => {
    const response = await api().get(`${basePath}/${tableId}`);
    return response;
  },

  /**
   * update a single PosTable
   * @param {object} tableId - id of the PosTable
   * @returns {Promise} A Promise that resolves to the PosTable response.
   */
  updatePosTable: async (payload) => {
    const response = await api().post(`${basePath}/update-position`, payload);
    return response;
  },

  /**
   * add a PosTable
   * @param {object} tableId - id of the PosTable
   * @returns {Promise} A Promise that resolves to the PosTable response.
   */
  addPosTable: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * delete a PosTable
   * @param {object} tableId - id of the PosTable
   * @returns {Promise} A Promise that resolves to the PosTable response.
   */
  deletePosTable: async (tableId) => {
    const response = await api().delete(`${basePath}/${tableId}`);
    return response;
  },

  resetPosTable: async () => {
    const response = await api().get(`${basePath}/reset-table`);
    return response;
  },
});

// Export the createPosTablesApi

/**
 * Example usage:
 * import createPosTablesApi from './path/to/api/PosTablesApi';
 *
 * const PosTablesApi = createPosTablesApi('PosTables');
 *
 * const getAllPosTables = async (payload) => {
 *   try {
 *     const response = await PosTablesApi.getAllPosTables(payload);
 *     // Handle response
 *   } catch (error) {
 *     // Handle error
 *   }
 * };
 */

export default createPosTablesApi;
