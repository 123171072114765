/**
 * Manages state for user authentication.
 * Defines initial state for each user action.
 */

// Import necessary functions and modules from Redux Toolkit

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createAuthApi from "./AuthService";
import { showToast } from "../../components/common/utils/showToast.util";

/**
 * Initial state structure defining various properties related to authentication processes.
 * Each property represents a specific authentication action/status with its associated data, error, success, loading, and message.
 * Properties such as login, signUp, verifyOtp, sendOtp, forgetPassword, createPassword, resetPassword, createAccount, and loginAndSignUpWithOAuth
 * store data, error, success, loading, and message status for corresponding authentication actions.
 */
const initialState = {
  login: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  forgetPassword: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  createPassword: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  resetPassword: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  changePassword: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  verifyOtp: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  sendOtp: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  loginAndSignUpWithOAuth: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  signUp: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  createAccount: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  bussniessAccount: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getProfile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateProfile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the authentication process.
 * These action types are prefixed with the 'auth' base path for better organization.
 */
const BASE = "auth";

export const ActionTypes = {
  LOGIN: `${BASE}/login`, // Action type for user login
  SIGN_UP: `${BASE}/signUp`, // Action type for user sign up
  VERIFY_OTP: `${BASE}/verifyOtp`, // Action type for OTP verification
  SEND_OTP: `${BASE}/sendOtp`, // Action type for sending OTP
  FORGET_PASSWORD: `${BASE}/forgetPassword`, // Action type for forgetting password
  CREATE_PASSWORD: `${BASE}/createPassword`, // Action type for creating a new password
  RESET_PASSWORD: `${BASE}/resetPassword`, // Action type for resetting the password
  CHANGE_PASSWORD: `${BASE}/changePassword`, // Action type for changin the password
  CREATE_ACCOUNT: `${BASE}/createAccount`, // Action type for creating a user account/profile
  CREATE_BUSSNIESS_ACCOUNT: `${BASE}/bussniessAccount`, // Action type for creating a user account/profile
  LOGIN_AND_SIGNUP_WITH_OAUTH: `${BASE}/loginAndSignUpWithOAuth`, // Action type for OAuth login/signup
  GET_SINGLE_PROFILE: `${BASE}/getProfile`, // Action type
  UPDATE_PROFILE: `${BASE}/updateProfile`, // Action type
};

// Creating an instance of the authentication service with a base URL 'auth'
const authService = createAuthApi("auth");

/**
 * Initiates the login process for a user.
 * @param {object} payload - Data containing user credentials for login.
 * @param {function} successCallBack - Callback function upon successful login.
 */
export const login = createAsyncThunk(
  ActionTypes.LOGIN,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   * payload:
   * {
  "email": "client@gmail.com",
  "password": "Pass@1234"
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await authService.login(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        return response;
      } else {
        showToast(response?.data?.message, "error");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the sign-up process for a new user.
 * @param {object} payload - User data for the sign-up process.
 * @param {function} successCallBack - Callback function upon successful sign-up.
 * @param {string} callBackMessage - Message to be sent back after successful sign-up.
 * @param {Object} thunkAPI - Redux Thunk API object used to control async flow and dispatch actions.
 * payload:
 * {
  "email": "client45567@gmail.com",
  "role": "CLIENT"
}
 */
export const signUp = createAsyncThunk(
  ActionTypes.SIGN_UP,
  async ({ payload, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.signUp(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);

        showToast(response.data.data.otp.message);

        return response;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the OTP verification process.
 * @param {object} payload - Data containing OTP for verification.
 * @param {function} successCallBack - Callback function upon successful OTP verification.
 * @param {string} callBackMessage - Message to be sent back after OTP verification.
 * @param {Object} thunkAPI - Redux Thunk API object used to control async flow and dispatch actions.
 * payload - Thunk payload:
 * {
  "email": "client@gmail.com",
  "otpCode": 123456
}
 */
export const verifyOtp = createAsyncThunk(
  ActionTypes.VERIFY_OTP,
  async ({ payload, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.verifyOtp(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast(response?.data?.message);
        return response;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the OTP sending process.
 * @param {object} payload - Data containing user information for OTP generation.
 * @param {function} successCallBack - Callback function upon successful OTP sending.
 * @param {string} callBackMessage - Message to be sent back after OTP sending.
 * @param {Object} thunkAPI - Redux Thunk API object used to control async flow and dispatch actions.
 * payload - Callback
 * {
  "email": "client@user.com"
}
 */
export const sendOtp = createAsyncThunk(
  ActionTypes.SEND_OTP,
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await authService.sendOtp(payload);
      if (response?.data?.Succeeded) {
        showToast(response?.data?.message);
        successCallBack(response);

        return response;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the process to recover forgotten password.
 * @param {object} payload - Data containing necessary information for password recovery.
 * @param {function} successCallBack - Callback function upon successful password recovery.
 * @param {string} callBackMessage - Message to be sent back after password recovery.
 * @param {Object} thunkAPI - Redux Thunk API object used to control async flow and dispatch actions.
 * payload:
 * {
  "email": "client@gmail.com"
}
 */
export const forgetPassword = createAsyncThunk(
  ActionTypes.FORGET_PASSWORD,
  async ({ payload, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.forgetPassword(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast(response?.data?.message);

        return response;
      } else {
        showToast(response?.data?.message, "error");
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const getProfile = createAsyncThunk(
  "auth/getProfile",
  async ({ payload, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.getProfile(payload);
      if (response?.data?.Succeeded) {
        if (successCallBack) successCallBack(response.data);
        return response.data;
      } else {
        // showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

//UPDATE PROFILE
export const updateProfile = createAsyncThunk(
  ActionTypes.UPDATE_PROFILE,
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await authService.updateProfile({
        id: payload.id,
        data: payload.data,
      });
      if (response?.data?.Succeeded) {
        return response?.data?.data;
      } else {
        showToast("Profile Updated Successfully!");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the process to create a new password.
 * @param {object} payload - Data containing user information for creating a new password.
 * @param {function} successCallBack - Callback function upon successful password creation.
 * @param {string} callBackMessage - Message to be sent back after creating a new password.
 * @param {Object} thunkAPI - Redux Thunk API object used to control async flow and dispatch actions.
 * payload:
 * {
  "password": "Pass@1234",
  "passwordConfirm": "Pass@1234",
  "email": "client@gmail.com"
}
 */
export const createPassword = createAsyncThunk(
  ActionTypes.CREATE_PASSWORD,
  async ({ payload, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.createPassword(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Password created successfully!");
        return response;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the process to reset a password.
 * @param {object} payload - Data containing user information for password reset.
 * @param {function} successCallBack - Callback function upon successful password reset.
 * @param {string} callBackMessage - Message to be sent back after password reset.
 * @param {Object} thunkAPI - Redux Thunk API object used to control async flow and dispatch actions.
 * payload:
 * {
  "token": "here-is-your",
  "password": "Pass@1234",
  "passwordConfirm": "pass@1234"
}
 */
export const resetPassword = createAsyncThunk(
  ActionTypes.RESET_PASSWORD,
  async ({ payload, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.resetPassword(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast(response?.data?.message);

        return response;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the process to change password.
 * @param {object} payload - Data containing necessary information for changing password.
 * @param {function} successCallBack - Callback function upon successful changing password.
 * @param {string} callBackMessage - Message to be sent back after changing password.
 * @param {Object} thunkAPI - Redux Thunk API object used to control async flow and dispatch actions.
 * payload:
 * {
  "email": "client@gmail.com",
  "currentPassword": "*****",
  "newPassword": "Pass@1234",
  "passwordConfirm": "Pass@1234"
}
 */
export const changePassword = createAsyncThunk(
  "user/changePassword",
  async ({ payload, successCallback }, { rejectWithValue }) => {
    try {
      const response = await authService.changePassword(payload);

      if (response?.data?.Succeeded) {
        showToast("Password changed successfully!");
        successCallback(response);

        return response.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

/**
 * Initiates the process to create a user account.
 * @param {object} payload - Data containing user information to create a new account.
 * @param {function} successCallBack - Callback function upon successful account creation.
 * @param {string} callBackMessage - Message to be sent back after creating a new account.
 * payload: {account:
 * {
  "fullName": "Zayed Ali",
  "arabicName": "زايد علي",
  "phoneNumber": "+9232478788",
  "profilePic": "hjkhjkhsdajkhdjkasjk",
  "userId": 1
}
 * @param {Object} thunkAPI - Redux Thunk API object used to control async flow and dispatch actions.
 */
export const createAccount = createAsyncThunk(
  ActionTypes.CREATE_ACCOUNT,
  async ({ payload, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.createAccount(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Profile created successfully!");

        return response;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the process to create a user account.
 * @param {object} payload - Data containing user information to create a new account.
 * @param {function} successCallBack - Callback function upon successful account creation.
 * @param {string} callBackMessage - Message to be sent back after creating a new account.
 * @param {Object} thunkAPI - Redux Thunk API object used to control async flow and dispatch actions.
 * payload:
 * {
  "companyName": "zapta tech",
  "companyAddress": "D.H.A Phase 4, Lahore, Punjab Pakistan",
  "website": "www.zapta.com",
  "commercialRegister": "8U9H34H4FF4RB",
  "vatNumber": 6371271238,
  "typeOfBusiness": "COFFEE_SHOP",
  "userId": 1
}
 */
export const bussniessAccount = createAsyncThunk(
  ActionTypes.CREATE_BUSSNIESS_ACCOUNT,
  async ({ payload, successCallBack, callBackMessage }, thunkAPI) => {
    try {
      const response = await authService.bussniessAccount(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Business profile created successfully!");
        return response;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the OAuth login/signup process.
 * @param {object} loginType - Type of login (e.g., 'google', 'facebook').
 * @param {string} email - User email.
 * @param {string} accessToken - OAuth access token for login/signup.
 * @param {function} successCallBack - Callback function upon successful OAuth login/signup.
 * @param {Object} thunkAPI - Redux Thunk API object used to control async flow and dispatch actions.
 */
export const loginAndSignUpWithOAuth = createAsyncThunk(
  ActionTypes.LOGIN_AND_SIGNUP_WITH_OAUTH,
  async ({ loginType, email, accessToken, successCallBack }, thunkAPI) => {
    try {
      const response = await authService.loginAndSignUpWithOAuth({
        loginType,
        email,
        accessToken,
      });

      if (response.Succeeded) {
        showToast("You are successfully logged in!");
        successCallBack(response.data);
        return response.data;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for authentication-related state management.
 * - `name`: Name of the slice (auth)
 * - `initialState`: Initial state defining properties for various authentication actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (login, signUp, verifyOtp, etc.).
 */
export const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    /**
     * Resets the state for the login action.
     */
    reset: (state) => {
      state.login = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the login action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(login.pending, (state) => {
        state.login.isLoading = true;
        state.login.message = "";
        state.login.isError = false;
        state.login.isSuccess = false;
        state.login.data = null;
      })
      /**
       * Updates the state when login action is fulfilled/successful.
       * Updates loading and success flags and sets login data with the payload.
       */
      .addCase(login.fulfilled, (state, action) => {
        state.login.isLoading = false;
        state.login.isSuccess = true;
        state.login.data = action.payload;
      })
      /**
       * Updates the state when login action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(login.rejected, (state, action) => {
        state.login.message = action.payload?.message;
        state.login.isLoading = false;
        state.login.isError = true;
        state.login.data = null;
      })
      /**
       * Updates the state while the forgetPassword action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(forgetPassword.pending, (state) => {
        state.forgetPassword.isLoading = true;
        state.forgetPassword.message = "";
        state.forgetPassword.isError = false;
        state.forgetPassword.isSuccess = false;
        state.forgetPassword.data = null;
      })
      /**
       * Updates the state when forgetPassword action is fulfilled/successful.
       * Updates loading and success flags and sets forgetPassword data with the payload.
       */
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.forgetPassword.isLoading = false;
        state.forgetPassword.isSuccess = true;
        state.forgetPassword.data = action.payload;
      })
      /**
       * Updates the state when forgetPassword action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(forgetPassword.rejected, (state, action) => {
        state.forgetPassword.message = action.payload?.message;
        state.forgetPassword.isLoading = false;
        state.forgetPassword.isError = true;
        state.forgetPassword.data = null;
      })
      /**
       * Updates the state while the loginAndSignUpWithOAuth action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(loginAndSignUpWithOAuth.pending, (state) => {
        state.loginAndSignUpWithOAuth.isLoading = true;
        state.loginAndSignUpWithOAuth.message = "";
        state.loginAndSignUpWithOAuth.isError = false;
        state.loginAndSignUpWithOAuth.isSuccess = false;
        state.loginAndSignUpWithOAuth.data = null;
      })
      /**
       * Updates the state when loginAndSignUpWithOAuth action is fulfilled/successful.
       * Updates loading and success flags and sets loginAndSignUpWithOAuth data with the payload.
       */
      .addCase(loginAndSignUpWithOAuth.fulfilled, (state, action) => {
        state.loginAndSignUpWithOAuth.isLoading = false;
        state.loginAndSignUpWithOAuth.isSuccess = true;
        state.loginAndSignUpWithOAuth.data = action.payload;
      })
      /**
       * Updates the state when loginAndSignUpWithOAuth action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(loginAndSignUpWithOAuth.rejected, (state, action) => {
        state.loginAndSignUpWithOAuth.message = action.payload?.message;
        state.loginAndSignUpWithOAuth.isLoading = false;
        state.loginAndSignUpWithOAuth.isError = true;
        state.loginAndSignUpWithOAuth.data = null;
      })
      /**
       * Updates the state while the signUp action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(signUp.pending, (state) => {
        state.signUp.isLoading = true;
        state.signUp.message = "";
        state.signUp.isError = false;
        state.signUp.isSuccess = false;
        state.signUp.data = null;
      })
      /**
       * Updates the state when signup action is fulfilled/successful.
       * Updates loading and success flags and sets signup data with the payload.
       */
      .addCase(signUp.fulfilled, (state, action) => {
        state.signUp.isLoading = false;
        state.signUp.isSuccess = true;
        state.signUp.data = action.payload;
      })
      /**
       * Updates the state when signup action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(signUp.rejected, (state, action) => {
        state.signUp.message = action.payload?.message;
        state.signUp.isLoading = false;
        state.signUp.isError = true;
        state.signUp.data = null;
      })
      /**
       * Updates the state while the verifyOtp action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(verifyOtp.pending, (state) => {
        state.verifyOtp.isLoading = true;
        state.verifyOtp.message = "";
        state.verifyOtp.isError = false;
        state.verifyOtp.isSuccess = false;
        state.verifyOtp.data = null;
      })
      /**
       * Updates the state when verifyOpt action is fulfilled/successful.
       * Updates loading and success flags and sets verifyOpt data with the payload.
       */
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.verifyOtp.isLoading = false;
        state.verifyOtp.isSuccess = true;
        state.verifyOtp.data = action.payload;
      })
      /**
       * Updates the state when verifyOtp action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(verifyOtp.rejected, (state, action) => {
        state.verifyOtp.message = action.payload?.message;
        state.verifyOtp.isLoading = false;
        state.verifyOtp.isError = true;
        state.verifyOtp.data = null;
      })
      /**
       * Updates the state while the createPassword action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(createPassword.pending, (state) => {
        state.createPassword.isLoading = true;
        state.createPassword.message = "";
        state.createPassword.isError = false;
        state.createPassword.isSuccess = false;
        state.createPassword.data = null;
      })
      /**
       * Updates the state when createPassword action is fulfilled/successful.
       * Updates loading and success flags and sets createPassword data with the payload.
       */
      .addCase(createPassword.fulfilled, (state, action) => {
        state.createPassword.isLoading = false;
        state.createPassword.isSuccess = true;
        state.createPassword.data = action.payload;
      })
      /**
       * Updates the state when createPassword action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(createPassword.rejected, (state, action) => {
        state.createPassword.message = action.payload?.message;
        state.createPassword.isLoading = false;
        state.createPassword.isError = true;
        state.createPassword.data = null;
      })
      /**
       * Updates the state while the createAccount action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(createAccount.pending, (state) => {
        state.createAccount.isLoading = true;
        state.createAccount.message = "";
        state.createAccount.isError = false;
        state.createAccount.isSuccess = false;
        state.createAccount.data = null;
      })
      /**
       * Updates the state when createAccount action is fulfilled/successful.
       * Updates loading and success flags and sets createAccount data with the payload.
       */
      .addCase(createAccount.fulfilled, (state, action) => {
        state.createAccount.isLoading = false;
        state.createAccount.isSuccess = true;
        state.createAccount.data = action.payload;
      })
      /**
       * Updates the state when createAccount action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(createAccount.rejected, (state, action) => {
        state.createAccount.message = action.payload?.message;
        state.createAccount.isLoading = false;
        state.createAccount.isError = true;
        state.createAccount.data = null;
      })
      /**
       * Updates the state while the createAccount action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(bussniessAccount.pending, (state) => {
        state.bussniessAccount.isLoading = true;
        state.bussniessAccount.message = "";
        state.bussniessAccount.isError = false;
        state.bussniessAccount.isSuccess = false;
        state.bussniessAccount.data = null;
      })
      /**
       * Updates the state when bussniessAccount action is fulfilled/successful.
       * Updates loading and success flags and sets bussniessAccount data with the payload.
       */
      .addCase(bussniessAccount.fulfilled, (state, action) => {
        state.bussniessAccount.isLoading = false;
        state.bussniessAccount.isSuccess = true;
        state.bussniessAccount.data = action.payload;
      })
      /**
       * Updates the state when bussniessAccount action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(bussniessAccount.rejected, (state, { error }) => {
        state.bussniessAccount.message = error?.message ?? "";
        state.bussniessAccount.isLoading = false;
        state.bussniessAccount.isError = true;
        state.bussniessAccount.data = null;
      })
      /**
       * Updates the state while the resetPassword action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.isLoading = true;
        state.resetPassword.message = "";
        state.resetPassword.isError = false;
        state.resetPassword.isSuccess = false;
        state.resetPassword.data = null;
      })
      /**
       * Updates the state when resetPassword action is fulfilled/successful.
       * Updates loading and success flags and sets resetPassword data with the payload.
       */
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPassword.isLoading = false;
        state.resetPassword.isSuccess = true;
        state.resetPassword.data = action.payload;
      })
      /**
       * Updates the state when resetPassword action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPassword.message = action.payload?.message;
        state.resetPassword.isLoading = false;
        state.resetPassword.isError = true;
        state.resetPassword.data = null;
      })
      /**
       * Updates the state while the changePassword action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(changePassword.pending, (state) => {
        state.changePassword.isLoading = true;
        state.changePassword.message = "";
        state.changePassword.isError = false;
        state.changePassword.isSuccess = false;
        state.changePassword.data = null;
      })
      /**
       * Updates the state when changePassword action is fulfilled/successful.
       * Updates loading and success flags and sets changePassword data with the payload.
       */
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePassword.isLoading = false;
        state.changePassword.isSuccess = true;
        state.changePassword.data = action.payload;
      })
      /**
       * Updates the state when changePassword action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(changePassword.rejected, (state, action) => {
        state.changePassword.isLoading = false;
        state.changePassword.isError = true;
        state.changePassword.data = null;
      })
      /**
       * Updates the state while the sendOtp action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(sendOtp.pending, (state) => {
        state.sendOtp.isLoading = true;
        state.sendOtp.message = "";
        state.sendOtp.isError = false;
        state.sendOtp.isSuccess = false;
        state.sendOtp.data = null;
      })
      /**
       * Updates the state when sendOtp action is fulfilled/successful.
       * Updates loading and success flags and sets sendOtp data with the payload.
       */
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.sendOtp.isLoading = false;
        state.sendOtp.isSuccess = true;
        state.sendOtp.data = action.payload;
      })
      /**
       * Updates the state when sendOtp action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(sendOtp.rejected, (state, action) => {
        state.sendOtp.message = action.payload?.message;
        state.sendOtp.isLoading = false;
        state.sendOtp.isError = true;
        state.sendOtp.data = null;
      })
      /**
       * Updates the state while the sendOtp action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getProfile.pending, (state) => {
        state.getProfile.isLoading = true;
        state.getProfile.message = "";
        state.getProfile.isError = false;
        state.getProfile.isSuccess = false;
        state.getProfile.data = null;
      })
      /**
       * Updates the state when getProfile action is fulfilled/successful.
       * Updates loading and success flags and sets getProfile data with the payload.
       */
      .addCase(getProfile.fulfilled, (state, action) => {
        state.getProfile.isLoading = false;
        state.getProfile.isSuccess = true;
        state.getProfile.data = action.payload;
      })
      /**
       * Updates the state when getProfile action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getProfile.rejected, (state, action) => {
        state.getProfile.message = action.payload?.message;
        state.getProfile.isLoading = false;
        state.getProfile.isError = true;
        state.getProfile.data = null;
      })

      /**
       * Updates the state while the sendOtp action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(updateProfile.pending, (state) => {
        state.updateProfile.isLoading = true;
        state.updateProfile.message = "";
        state.updateProfile.isError = false;
        state.updateProfile.isSuccess = false;
        state.updateProfile.data = null;
      })
      /**
       * Updates the state when updateProfile action is fulfilled/successful.
       * Updates loading and success flags and sets updateProfile data with the payload.
       */
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.updateProfile.isLoading = false;
        state.updateProfile.isSuccess = true;
        state.updateProfile.data = action.payload;
      })
      /**
       * Updates the state when updateProfile action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateProfile.rejected, (state, action) => {
        state.updateProfile.message = action.payload?.message;
        state.updateProfile.isLoading = false;
        state.updateProfile.isError = true;
        state.updateProfile.data = null;
      });
  },
});

/**
 * Destructures the reset action from the authSlice actions.
 * - `reset`: Action function to reset the authentication-related state.
 */

// sample usage

// import { reset } from './yourAuthSlicePath';

// Using the reset action
// const resetAuth = () => {
//   // Dispatching the reset action to reset authentication state
//   store.dispatch(reset());
// };

export const { reset } = authSlice.actions;

/**
 * Exports the default reducer generated by authSlice.
 * This reducer handles state updates for authentication-related actions.
 */

// sample usage

// import authReducer from "./yourAuthSlicePath";

// Using the default reducer
// const initialState = {
// Your initial state for authentication
// };

// Creating a store with the authReducer handling authentication-related actions
// const store = configureStore({
// reducer: {
// auth: authReducer,
// other reducers...
// },
// preloadedState: {
// auth: initialState,
// other preloaded states...
// },
// });
export default authSlice.reducer;
