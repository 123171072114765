import api from "../../services/api";

/**
 * Creates dashboard API endpoints based on the specified base path.
 * @param {string} basePath - The base path for dashboard endpoints.
 * @returns {Object} Object containing dashboard API methods.
 */

const createClientDashboardApi = (basePath) => ({
  /**
   * get client sale and cost
   * @param {object} payload
   *    {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
        }
   * @returns {Promise} A Promise that resolves to the client sale and cost response.
   */
  getClientSalesCost: async (payload) => {
    const response = await api().post(`${basePath}/client-sale-cost`, payload);
    return response;
  },
  /**
   * get client-net-income
   * @param {object} payload
   *    {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
        }
   * @returns {Promise} A Promise that resolves to the client-net-income response.
   */
  getClientNetIncome: async (payload) => {
    const response = await api().post(`${basePath}/client-net-income`, payload);
    return response;
  },
  /**
   * get client-expense
   * @param {object} payload
   *    {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
        }
   * @returns {Promise} A Promise that resolves to the client-expense response.
   */
  getClientExpense: async (payload) => {
    const response = await api().post(`${basePath}/client-expense`, payload);
    return response;
  },
  /**
   * get client-sales-by-items
   * @param {object} payload
   *    {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
        }
   * @returns {Promise} A Promise that resolves to the client-sales-by-items response.
   */
  getClientSalesByItems: async (payload) => {
    const response = await api().post(
      `${basePath}/client-sales-by-items`,
      payload
    );
    return response;
  },
  /**
   * get client-sales-by-categories
   * @param {object} payload
   *    {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
            "id", 1,
        }
   * @returns {Promise} A Promise that resolves to the client-sales-by-categories response.
   */
  getClientSalesByCategories: async (payload) => {
    const response = await api().post(
      `${basePath}/client-sales-by-categories`,
      payload
    );
    return response;
  },
  /**
   * get client-sales-by-stores
   * @param {object} payload
   *    {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
            "id", 1,
        }
   * @returns {Promise} A Promise that resolves to the client-sales-by-stores response.
   */
  getClientSalesByStores: async (payload) => {
    const response = await api().post(
      `${basePath}/client-sales-by-stores`,
      payload
    );
    return response;
  },
  /**
   * get client-sales-by-cashier
   * @param {object} payload
   *    {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
            "id", 1,
        }
   * @returns {Promise} A Promise that resolves to the client-sales-by-cashier response.
   */
  getClientSalesByCachier: async (payload) => {
    const response = await api().post(
      `${basePath}/client-sales-by-cashier`,
      payload
    );
    return response;
  },
});

// Export the createClientDashboardApi

/**
 * Example usage:
 * import createClientDashboardApi from './path/to/api/clientDashboardApi';
 *
 * const clientDashboardApi = createClientDashboardApi('customers');
 *
 * const getClientSalesCost = async (payload) => {
 *   try {
 *     const response = await clientDashboardApi.getClientSalesCost(payload);
 *     // Handle response
 *   } catch (error) {
 *     // Handle error
 *   }
 * };
 */

export default createClientDashboardApi;
