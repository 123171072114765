import * as React from "react";

function PlusIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   
    >
      <path
        d="M10.5625 9.375V9.4375H10.625H16.875C17.0242 9.4375 17.1673 9.49676 17.2727 9.60225C17.3782 9.70774 17.4375 9.85082 17.4375 10C17.4375 10.1492 17.3782 10.2923 17.2727 10.3977C17.1673 10.5032 17.0242 10.5625 16.875 10.5625H10.625H10.5625V10.625V16.875C10.5625 17.0242 10.5032 17.1673 10.3977 17.2727C10.2923 17.3782 10.1492 17.4375 10 17.4375C9.85082 17.4375 9.70774 17.3782 9.60225 17.2727C9.49676 17.1673 9.4375 17.0242 9.4375 16.875V10.625V10.5625H9.375H3.125C2.97582 10.5625 2.83274 10.5032 2.72725 10.3977C2.62176 10.2923 2.5625 10.1492 2.5625 10C2.5625 9.85082 2.62176 9.70774 2.72725 9.60225C2.83274 9.49676 2.97582 9.4375 3.125 9.4375H9.375H9.4375V9.375V3.125C9.4375 2.97582 9.49676 2.83274 9.60225 2.72725C9.70774 2.62176 9.85082 2.5625 10 2.5625C10.1492 2.5625 10.2923 2.62176 10.3977 2.72725C10.5032 2.83274 10.5625 2.97582 10.5625 3.125V9.375Z"
        fill="black"
        stroke="#1A1A1A"
        strokeWidth="0.125"
      />
    </svg>
  );
}

export default PlusIcon;
