/**
 * Computes conditions based on search parameters and a query string.
 * This function parses each search parameter and builds a condition object
 * or array depending on the presence of nested parameters. It supports nested
 * conditions through dot notation in parameter names. The function ensures
 * the result is either an array of conditions or an empty object if no valid
 * conditions are found.
 *
 * @param {Array<string>} searchParams - An array of parameter names, possibly
 * including dot notation for nested properties.
 * @param {string} query - The query string used to match against the parameters.
 * @returns {Array<Object>|Object} - An array of condition objects if there are
 * valid conditions, or an empty object if no conditions are met.
 */

export function generatePayloadCondition(searchParams, query, tabs = {}) {
  if (query === "") {
    return tabs;
  }

  const conditions = Object.entries({
    ...searchParams.reduce((acc, param) => {
      let currentObj = acc;
      if (param.includes(".")) {
        const keys = param.split(".");
        keys.forEach((key, index) => {
          if (!currentObj[key]) {
            currentObj[key] = {};
          }
          if (index === keys.length - 1) {
            currentObj[key] = { $ILike: query };
          }
          currentObj = currentObj[key];
        });
      } else {
        acc[param] = { $ILike: query };
      }
      return acc;
    }, {}),
  }).map(([key, value]) => ({ [key]: value, ...tabs }));

  return conditions.length === 0 ? {} : conditions;
}

// Sample usage

// import { generatePayloadCondition } from "./path/to/generatePayloadCondition"
// useEffect(() => {
//   const searchParams = ['user.name', 'user.email'];
//   const query = 'john';
//   const conditions = generatePayloadCondition(searchParams, query);
//   console.log(conditions); // logs the conditions to be applied based on input
//   // other code using conditions
// }, [searchParams, query]);
