export function formatEnum(enumValue) {
  if (!enumValue) return '';
  
  // Split the enum value by underscores and capitalize each word
  const words = enumValue.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  
  // Join the words with spaces
  return words.join(' ');
}

// Example usage:
// formatEnum('FIXED_AMOUNT') will return 'Fixed Amount'
// formatEnum('PERCENTAGE_DISCOUNT') will return 'Percentage Discount'
