import * as React from "react";

function CoinIcon(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M15.5086 4.88203C14.5656 3.26562 13.275 2.375 11.875 2.375H8.125C6.725 2.375 5.43438 3.26562 4.49141 4.88203C3.61016 6.39219 3.125 8.39062 3.125 10.5C3.125 12.6094 3.61016 14.6078 4.49141 16.118C5.43438 17.7344 6.725 18.625 8.125 18.625H11.875C13.275 18.625 14.5656 17.7344 15.5086 16.118C16.3898 14.6078 16.875 12.6094 16.875 10.5C16.875 8.39062 16.3898 6.39219 15.5086 4.88203ZM15.6086 9.875H13.1086C13.0598 8.59247 12.8044 7.32621 12.3523 6.125H14.7422C15.2203 7.17031 15.5359 8.46875 15.6086 9.875ZM13.9992 4.875H11.7547C11.4936 4.42212 11.1789 4.00242 10.8172 3.625H11.875C12.6562 3.625 13.3906 4.09375 13.9992 4.875ZM4.375 10.5C4.375 6.77344 6.09375 3.625 8.125 3.625C10.1562 3.625 11.875 6.77344 11.875 10.5C11.875 14.2266 10.1562 17.375 8.125 17.375C6.09375 17.375 4.375 14.2266 4.375 10.5ZM11.875 17.375H10.8195C11.1812 16.9976 11.496 16.5779 11.757 16.125H14.0016C13.3906 16.9062 12.6562 17.375 11.875 17.375ZM14.7422 14.875H12.3531C12.8052 13.6738 13.0605 12.4075 13.1094 11.125H15.6094C15.5359 12.5312 15.2203 13.8297 14.7422 14.875Z"
        fill={props?.stroke ? "#0086FF" : "#667085"}
      />
    </svg>
  );
}

export default CoinIcon;
