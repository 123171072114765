// Helper function for formatting dates
export const formatDateTime = (dateTimeString, format = "mm-dd-yyyy") => {
  if (!dateTimeString) {
    return "-";
  }

  const date = new Date(dateTimeString);
  let formattedDate = "";

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  switch (format) {
    case "mm-dd-yyyy":
      formattedDate = `${month}-${day}-${year}`;
      break;
    case "dd-mm-yyyy":
      formattedDate = `${day}-${month}-${year}`;
      break;
    case "yyyy-mm-dd":
      formattedDate = `${year}-${month}-${day}`;
      break;
    case "dd/mm/yyyy":
      formattedDate = `${day}/${month}/${year}`;
      break;
    case "mm/dd/yyyy":
      formattedDate = `${month}/${day}/${year}`;
      break;
    case "mmm dd, yyyy":
      formattedDate = `${date.toLocaleString("default", {
        month: "short",
      })} ${day}, ${year}`;
      break;
    case "mmm dd":
      formattedDate = `${date.toLocaleString("default", {
        month: "short",
      })}, ${day}`;
      break;
    default:
      formattedDate = `${month}-${day}-${year}`;
      break;
  }

  return formattedDate;
};

// sample usage
// import formatDateTime from "./path/to/dateFormatter.js"

// (formatDateTime(new Date(), "mm-dd-yyyy"));
