import * as React from "react";

function CodeSandBox(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="CodesandboxLogo">
        <path
          id="Vector"
          d="M17.5 14.3512V6.64805C17.4994 6.53678 17.4695 6.42763 17.4132 6.33162C17.357 6.23561 17.2764 6.15612 17.1797 6.10117L10.3047 2.23398C10.2121 2.1805 10.107 2.15234 10 2.15234C9.89303 2.15234 9.78795 2.1805 9.69531 2.23398L2.82031 6.10117C2.72356 6.15612 2.643 6.23561 2.58676 6.33162C2.53052 6.42763 2.5006 6.53678 2.5 6.64805V14.3512C2.5006 14.4624 2.53052 14.5716 2.58676 14.6676C2.643 14.7636 2.72356 14.8431 2.82031 14.898L9.69531 18.7652C9.78795 18.8187 9.89303 18.8469 10 18.8469C10.107 18.8469 10.2121 18.8187 10.3047 18.7652L17.1797 14.898C17.2764 14.8431 17.357 14.7636 17.4132 14.6676C17.4695 14.5716 17.4994 14.4624 17.5 14.3512V14.3512Z"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M17.4141 6.32812L10.0703 10.5L2.58594 6.32812"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M10.0703 10.5L10 18.8438"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M13.8281 4.21094L10 6.36719L6.17188 4.21094"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M13.75 16.8281V12.5234L17.5 10.4141"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_6"
          d="M6.25 16.8281V12.5234L2.5 10.4141"
          stroke={props?.stroke ? "#0086FF" : "#667085"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default CodeSandBox;
