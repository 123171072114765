import React from "react";

const Tooltip = ({
  text = "",
  arrowPosition = "center",
  tooltipPosition = "-top-10",
}) => {
  const arrowClass =
    arrowPosition === "left"
      ? "left-0 ml-3"
      : arrowPosition === "right"
      ? "right-0 mr-3"
      : "w-full left-0";

  return (
    <div
      className={`hidden absolute -top-10 group-hover:block ${tooltipPosition}`}
    >
      <div className="relative ">
        <div className="bg-black text-white text-md rounded py-2 px-4 right-0 bottom-full">
          {text}
          <svg
            className={`absolute text-black h-2 top-full ${arrowClass}`}
            x="0px"
            y="0px"
            viewBox="0 0 255 255"
            xmlSpace="preserve"
          >
            <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
