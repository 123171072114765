import React from "react";
import { useTranslation } from "react-i18next";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PosSettingSidebar = ({
  setSidebarOpen,
  setActiveComponent,
  activeComponent,
}) => {
  const { t } = useTranslation();

  const navigation = [
    { name: t("pos_dashboard.general") },
    { name: t("pos_dashboard.devices") },
    { name: t("pos_dashboard.attendance_history") },
    { name: t("pos_dashboard.terms_and_conditions") },
  ];

  const handleItemClick = (itemName) => {
    setActiveComponent(itemName);
    if (window.innerWidth < 1024) {
      setSidebarOpen(false);
    }
  };

  return (
    <>
      <div className="flex h-[500px] w-[270px] flex-col gap-y-2 overflow-y-auto bg-neutral-200 backdrop-blur-1xl px-4 pb-4 ">
        <nav className="flex flex-1 flex-col mt-3">
          <ul className="flex flex-1 flex-col gap-y-2">
            {navigation.map((item) => (
              <li key={item.name}>
                <button
                  type="button"
                  onClick={() => handleItemClick(item.name)}
                  className={classNames(
                    activeComponent === item.name
                      ? "bg-white text-text-link shadow-sm"
                      : "text-text-secondary hover:text-text-link hover:bg-white",
                    "group flex items-center gap-x-2 rounded-md py-2 px-3 text-sm leading-6 font-semibold w-full text-left"
                  )}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default PosSettingSidebar;
