import * as React from "react";

function LockIcon(props) {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <rect width="96" height="96" rx="48" fill="#F2F4F7" />
      <path
        d="M63 39H57V34.5C57 32.1131 56.0518 29.8239 54.364 28.136C52.6761 26.4482 50.3869 25.5 48 25.5C45.6131 25.5 43.3239 26.4482 41.636 28.136C39.9482 29.8239 39 32.1131 39 34.5V39H33C32.2044 39 31.4413 39.3161 30.8787 39.8787C30.3161 40.4413 30 41.2044 30 42V63C30 63.7957 30.3161 64.5587 30.8787 65.1213C31.4413 65.6839 32.2044 66 33 66H63C63.7957 66 64.5587 65.6839 65.1213 65.1213C65.6839 64.5587 66 63.7957 66 63V42C66 41.2044 65.6839 40.4413 65.1213 39.8787C64.5587 39.3161 63.7957 39 63 39ZM42 34.5C42 32.9087 42.6321 31.3826 43.7574 30.2574C44.8826 29.1321 46.4087 28.5 48 28.5C49.5913 28.5 51.1174 29.1321 52.2426 30.2574C53.3679 31.3826 54 32.9087 54 34.5V39H42V34.5ZM63 63H33V42H63V63ZM50.25 52.5C50.25 52.945 50.118 53.38 49.8708 53.75C49.6236 54.12 49.2722 54.4084 48.861 54.5787C48.4499 54.749 47.9975 54.7936 47.561 54.7068C47.1246 54.62 46.7237 54.4057 46.409 54.091C46.0943 53.7763 45.88 53.3754 45.7932 52.939C45.7064 52.5025 45.751 52.0501 45.9213 51.639C46.0916 51.2278 46.38 50.8764 46.75 50.6292C47.12 50.382 47.555 50.25 48 50.25C48.5967 50.25 49.169 50.4871 49.591 50.909C50.0129 51.331 50.25 51.9033 50.25 52.5Z"
        fill="#FF474C"
      />
    </svg>
  );
}

export default LockIcon;
