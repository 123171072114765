import React from "react";
import { PageNotFound } from "../../Icons";

const UnautherizedPage = () => {
  return (
    <div className="w-screen bg-gray-50 flex items-center">
      <div className="container flex flex-col items-center justify-between px-5 text-gray-700">
        <div className="w-full lg:w-1/2 mx-8 ">
          {" "}
          <svg
            viewBox="0 0 24 24"
            class="w-20 h-20 text-red-500 stroke-current"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8V12V8ZM12 16H12.01H12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              strokewidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <p className="text-2xl md:text-3xl font-light leading-normal mb-8">
            You don't have access to this page!
          </p>
          <a
            href="/"
            className="px-5 inline py-3 text-sm font-medium leading-5 shadow-2xl text-white transition-all duration-400 border border-transparent rounded-lg focus:outline-none bg-green-600 active:bg-red-600 hover:bg-red-700"
          >
            Back to homepage
          </a>
        </div>
        <div className="w-2/4 lg:flex lg:justify-end lg:w-2/4  mx-auto">
          <PageNotFound />
        </div>
      </div>
    </div>
  );
};

export default UnautherizedPage;
