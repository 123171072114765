import React, { useState } from "react";
import { Card, CustomButton } from "../../../common/components";
import useCustomerOrderDetail from "./useCustomerOrderDetail";

function CustomerOrderDetail({
  selectedItem,
  closeModal,
  setIsCheckoutModalOpen,
  duplicateOrder,
  refundOrder,
}) {
  const {
    orderedItems,
    total,
    taxAmount,
    discountAmount,
    totalAmount,
    handleCompleteClick,
  } = useCustomerOrderDetail({
    selectedItem,
    closeModal,
    setIsCheckoutModalOpen,
  });
  const [showNotes, setShowNotes] = useState(false);
  const toggleNotes = (itemId) => {
    setShowNotes((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const orderStatus = selectedItem?.status;
  return (
    <div className="p-2">
      {/* Order Details */}
      <Card className="mb-2 !p-3">
        <div className="font-semibold text-sm">Order Detail</div>
        <div className="flex flex-col min-h-42 overflow-auto max-h-[200px] p-0 grayScrollNoBg">
          {orderedItems?.map((item) => (
            <div
              key={item?.id}
              className="flex justify-between items-center py-1"
            >
              <div className="flex items-center">
                <img
                  src={item?.item?.image}
                  alt={item?.item?.englishName}
                  className="h-14 w-14 object-cover mr-2"
                />
                <div>
                  <div className="font-semibold text-text-primary">
                    {item?.item?.englishName}
                  </div>
                  {item.item.type === "SIMPLE" && (
                    <span className="text-gray-600 text-sm">
                      {item?.quantity}x -Item
                    </span>
                  )}
                  {item.item.type === "COMPOSITING" && (
                    <span className="text-gray-600 text-sm">
                      {item?.quantity}x -Item
                    </span>
                  )}
                  {item?.item?.type === "VARIABLE" && item?.vItem && (
                    <span className="text-gray-600 text-sm">
                      {`${Math?.abs(item?.quantity)}x -`}
                      {Object.values(item?.vItem?.variations)?.join("-")}
                    </span>
                  )}
                  {item?.note && (
                    <div className="flex flex-col items-start">
                      <button
                        className="text-text-link text-sm font-medium px-1"
                        onClick={() => toggleNotes(item?.id)}
                      >
                        {showNotes[item?.id] ? "Hide Notes" : "See Notes"}
                      </button>
                      {showNotes[item?.id] && (
                        <div className="flex items-center gap-2">
                          <textarea
                            placeholder="No Note"
                            className="w-32 h-8 rounded border"
                            value={item?.note}
                            readOnly
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <span className="text-text-primary text-sm font-semibold mr-2">
                {(item?.item.type === "SIMPLE" ||
                item?.item.type === "COMPOSITING"
                  ? Math.abs(item?.item?.salePrice) * Math.abs(item?.quantity)
                  : Math.abs(item?.vItem?.salePrice) *
                    Math.abs(item?.vItem?.quantity)
                ).toFixed(2)}{" "}
                SAR
              </span>
            </div>
          ))}
        </div>
      </Card>

      {/* Customer */}
      <Card className="mb-2 !p-3">
        <div className="font-semibold mb-2 text-sm">Customer</div>
        <div className="text-gray-600">{selectedItem?.customer ?? "-"}</div>
      </Card>

      {/* Service Type */}
      <Card className="mb-2 !p-3">
        <div className="font-semibold mb-2 text-sm">Service Type</div>
        <div className="text-gray-600">
          {selectedItem?.orderType === "TAKEAWAY" && "Take away"}
          {selectedItem?.orderType === "DINE_IN" && "Dine In"}
          {!["TAKEAWAY", "DINE_IN"]?.includes(selectedItem?.orderType) &&
            (selectedItem?.orderType ?? "-")}
          {selectedItem?.orderType !== "TAKEAWAY" && (
            <p className="font-medium">
              Table No: {selectedItem?.tablenumber ?? "-"}
            </p>
          )}
        </div>
      </Card>

      {/* Payment Method */}
      <Card className="mb-2 !p-3">
        <div className="font-semibold mb-2 text-sm">Payment</div>
        <div className="flex justify-between mb-2 text-sm">
          <span>Payment Method</span>
          <span>{selectedItem?.paymentStatus ?? "-"}</span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span>Sub Total</span>
          <span>{totalAmount.toFixed(2) ?? "0"} SAR</span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span>Discount Applied</span>
          <span>{discountAmount.toFixed(2) ?? "0"} SAR</span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span>VAT</span>
          <span>{taxAmount.toFixed(2) ?? "0"} SAR</span>
        </div>
        <div className="flex justify-between font-semibold text-sm">
          <span>Total</span>
          <span>{total.toFixed(2) ?? "0"} SAR</span>
        </div>
      </Card>
      <div className="flex justify-end mt-2 gap-2">
        {orderStatus === "Saved" && (
          <>
            <CustomButton
              width="20px"
              text="Complete"
              bgColor="bg-white"
              textColor="text-text-primary"
              border="border"
              hoverBgColor="bg-gray-400"
              onClick={handleCompleteClick}
              className={` hover:bg-gray-100  `}
            />
            <CustomButton
              width="20px"
              text="Duplicate"
              bgColor="bg-white"
              textColor="text-text-primary"
              border="border"
              hoverBgColor="!bg-gray-400"
              onClick={() => duplicateOrder(selectedItem,"duplicate")}
              className={` hover:bg-gray-100  `}
            />
          </>
        )}

        {orderStatus === "Completed" && (
          <>
            <CustomButton
              width="20px"
              text="Return"
              bgColor="bg-white"
              textColor="text-text-primary"
              border="border"
              hoverBgColor="!bg-gray-200"
              onClick={() => refundOrder(selectedItem)}
              className={` hover:bg-gray-100  `}
            />
            <CustomButton
              width="20px"
              text="Duplicate"
              bgColor="bg-white"
              textColor="text-text-primary"
              border="border"
              hoverBgColor="!bg-gray-400"
              onClick={() => duplicateOrder(selectedItem)}
              className={` hover:bg-gray-100  `}
            />
            <CustomButton
              type="submit"
              width="20px"
              text="Print"
              bgColor="bg-sky-blue"
              textColor="text-white"
              className={` hover:bg-gray-100  `}
            />
          </>
        )}

        {orderStatus === "On Process" && (
          <>
            <CustomButton
              width="20px"
              text="Complete"
              bgColor="bg-white"
              textColor="text-text-primary"
              border="border"
              hoverBgColor="bg-gray-400"
              onClick={handleCompleteClick}
              className={` hover:bg-gray-100  `}
            />
            {/* <CustomButton
              width="20px"
              text="Duplicate"
              bgColor="bg-white"
              textColor="text-text-primary"
              border="border"
              className={` hover:bg-gray-100  `}
              onClick={() => duplicateOrder(selectedItem)}
            /> */}
          </>
        )}
      </div>
    </div>
  );
}

export default CustomerOrderDetail;
