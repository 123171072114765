import * as React from "react";

function PlusIcon({ stroke = "#667085" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Bell">
        <path
          id="Vector"
          d="M4.39062 8.12509C4.38958 7.38413 4.53514 6.65029 4.81892 5.96584C5.1027 5.28138 5.5191 4.65983 6.04412 4.13699C6.56914 3.61414 7.19242 3.20034 7.87805 2.91941C8.56368 2.63847 9.29812 2.49596 10.0391 2.50009C13.1328 2.52352 15.6094 5.09384 15.6094 8.1954V8.75009C15.6094 11.547 16.1953 13.172 16.7109 14.0626C16.7657 14.1574 16.7946 14.265 16.7947 14.3745C16.7948 14.4841 16.7661 14.5917 16.7115 14.6866C16.6569 14.7816 16.5783 14.8605 16.4836 14.9155C16.3889 14.9705 16.2814 14.9997 16.1719 15.0001H3.82812C3.7186 14.9997 3.6111 14.9705 3.51639 14.9155C3.42169 14.8605 3.3431 14.7816 3.28851 14.6866C3.23391 14.5917 3.20523 14.4841 3.20532 14.3745C3.20542 14.265 3.2343 14.1574 3.28906 14.0626C3.80468 13.172 4.39062 11.547 4.39062 8.75009V8.12509Z"
        stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.5 15V15.625C7.5 16.288 7.76339 16.9239 8.23223 17.3928C8.70107 17.8616 9.33696 18.125 10 18.125C10.663 18.125 11.2989 17.8616 11.7678 17.3928C12.2366 16.9239 12.5 16.288 12.5 15.625V15"
        stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

export default PlusIcon;
