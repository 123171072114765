import * as React from "react";

function StackIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="Stack">
        <path
          id="Vector"
          d="M2.5 13.75L10 18.125L17.5 13.75"
          stroke={props.strokeColor ? props.strokeColor : "#475467"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M2.5 10L10 14.375L17.5 10"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M2.5 6.25L10 10.625L17.5 6.25L10 1.875L2.5 6.25Z"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default StackIcon;
