import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../../context/UserContext";
import { Card, TamaraIcon } from "../../../common/Icons";
import {
  CreditCardInput,
  CvvInput,
  ExpiryDateInput,
  Loader,
  Tooltip,
} from "../../../common/components";
import CustomButton from "../../../common/components/Button/Button";
import InputGroup from "../../../common/components/InputGroup/InputGroup";
import InputLabel from "../../../common/components/InputLabel/InputLabel";
import FinishScreen from "../FinishScreen/FinishScreen";
import ProfileLayout from "../Layout";
import SelectPlanCards from "./SelectPlanCards";
import useSetPlan from "./useSetPlan";
import { formatDateTime } from "../../../../helpers/dateFormatter";
import { useNavigate } from "react-router-dom";

export const SetPlan = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let selectedOption = "Card";
  const { user } = useUserContext();

  const handleOptionChange = () => {};

  const {
    showFinish,
    handleSubmit,
    setBranchCount,
    branchCount,
    setActiveTab,
    activeTab,
    setSelectedPlan,
    selectedPlan,
    additionalBranchPrice,
    totalPrice,
    tax,
    totalPriceBeforeTax,
    // discountPrice,
    register,
    errors,
    handleFreeTrialSubscription,
    isFreeTrialLoading,
    control,
    isSubmitting,
    pricingData,
    isLoading,
    calculatePricing,
    priceSummaryLoading,
  } = useSetPlan();

  const [endDate, setEndDate] = useState("");
  const isClient = user?.role === "CLIENT";

  useEffect(() => {
    if (user && isClient) {
      if (!user?.isProfileCompleted) {
        navigate("/create-account");
      }
      if (user?.isProfileCompleted && !user?.isBusinessDetailCompleted) {
        navigate("/business-profile");
      }
      if (
        user?.isProfileCompleted &&
        user?.isBusinessDetailCompleted &&
        (user?.isFreeTrial
          ? user?.freeTrial?.isFreeTrialEnded
          : !user?.isSubscriptionPlanCompleted)
      ) {
        navigate("/set-plan");
      }
    }
  }, [isClient, user, navigate]);

  useEffect(() => {
    if (user && user?.isLoginVerified) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  useEffect(() => {
    const calculateEndDate = () => {
      const currentDate = new Date();
      let endDate;

      if (activeTab === "MONTHLY") {
        endDate = new Date(currentDate.setDate(currentDate.getDate() + 31));
      } else if (activeTab === "ANNUAL") {
        endDate = new Date(currentDate.setDate(currentDate.getDate() + 365));
      }

      return endDate;
    };

    const endDate = calculateEndDate();
    setEndDate(endDate?.toLocaleDateString());
  }, [activeTab]);

  return (
    <div>
      <form>
        {showFinish ? (
          <FinishScreen />
        ) : (
          <ProfileLayout activePage={t("stepper.setup_your_plan")}>
            <div className="bg-white border rounded-lg p-4 mx-auto xl:w-2/3 w-11/12 mt-12 ">
              <div className="space-y-4 !mt-2">
                <SelectPlanCards
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  branchCount={branchCount}
                  setBranchCount={setBranchCount}
                  additionalBranchPrice={additionalBranchPrice}
                  pricingData={pricingData}
                  isLoading={isLoading}
                  user={user}
                />
                <hr className="border-t-2 border-gray-100 px-2 my-4" />
                <div className="flex flex-col">
                  <InputLabel
                    className="text-text-primary !font-semibold !text-base"
                    htmlfor="payment method"
                    text="Payment Method"
                  />
                  <div className="flex mt-3 gap-3">
                    {paymentTypes?.map((item, index) => (
                      <div
                        key={item?.value}
                        className={`px-3 py-1 flex items-center border gap-4 rounded-lg relative max-w-[400px] w-full ${
                          selectedOption === item.value &&
                          "border-sky-blue bg-info-50"
                        } `}
                      >
                        <div
                          onClick={() => handleOptionChange(item?.value)}
                          className="absolute inset-0 bg-transparent z-20 cursor-pointer"
                        />

                        <item.icon
                          stroke={selectedOption === item.value}
                          className="!w-8 !h-8"
                        />

                        <InputGroup
                          inputName="simple"
                          labelText={item?.name}
                          inputType="radio"
                          className="flex items-center justify-between w-full !space-y-0"
                          padding="p-0"
                          checked={selectedOption === item?.value}
                          onChange={handleOptionChange}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col w-full mt-4">
                  {/* crdit card  */}
                  <CreditCardInput
                    control={control}
                    name="card"
                    htmlFor="card"
                    labelText="Card Number"
                    placeholder="Enter Card Number"
                    errors={errors}
                  />

                  <div className="flex w-full gap-3 mt-4">
                    <ExpiryDateInput
                      control={control}
                      name="expiry"
                      htmlFor="expiry"
                      placeholder="MM/YY"
                      errors={errors}
                      className="flex-1"
                    />

                    <CvvInput
                      control={control}
                      name="cvv"
                      htmlFor="cvv"
                      placeholder="CVV"
                      errors={errors}
                      className="flex-1"
                    />
                  </div>
                  {/* <InputGroup
                    htmlfor="name"
                    labelText="Discount"
                    inputName="discount"
                    placeholder="Enter Code"
                    className="mt-4"
                    // register={register}
                    // errors={errors}
                  /> */}
                </div>
              </div>
            </div>

            <div className="bg-white border rounded-lg p-6 mx-auto mt-8 xl:w-2/3 w-11/12">
              <h4 className="text-text-primary font-semibold mb-5">Summary</h4>
              {priceSummaryLoading ? (
              <div className="flex items-center justify-center">
                <Loader />
              </div>
            ) : (
              <div className="flex flex-col w-full mt-4 space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-text-secondary font-normal text-base">
                    Total Before Tax
                  </span>
                  <span className="text-text-primary text-base font-semibold">
                    {totalPriceBeforeTax ?? "0"} SAR
                  </span>
                </div>
                {/* <div className="flex justify-between items-center">
                <span className="text-text-secondary font-normal text-base">
                  Total Discount
                </span>
                <span className="text-text-primary text-base font-semibold">
                  {discountPrice ?? "0"} SAR
                </span>
              </div> */}
                <div className="flex justify-between items-center">
                  <span className="text-text-secondary font-normal text-base">
                    Tax Amount
                  </span>
                  <span className="text-text-primary text-base font-semibold">
                    {tax ?? "0"} SAR
                  </span>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <span className="text-text-primary font-semibold">
                    Subtotal
                  </span>
                  <span className="text-text-link text-lg font-semibold">
                    {totalPrice ?? "0"} SAR
                  </span>
                </div>
              </div>
            )}
            {/* divider */}

              <hr className="border-t-2 border-gray-100 px-2 my-4" />
              <span className=" text-text-secondary text-sm font-normal">
                By confirming your subscription, you allow TagDev co to charge
                your card for this payment and future payments in accordance
                with their terms. You can always cancel your subscription.
              </span>

              <div className="flex items-center gap-3">
                <CustomButton
                  text={`Confirm Subscription to ${formatDateTime(
                    endDate,
                    "mmm dd, yyyy"
                  )}`}
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  className="mt-6"
                />
                <CustomButton
                  text="14-Day Free Trial"
                  className="mt-6 whitespace-nowrap relative group"
                  onClick={() => handleFreeTrialSubscription()}
                  width="w-56"
                  bgColor="bg-border-primary"
                  textColor="text-text-primary"
                  isLoading={isFreeTrialLoading}
                  disabled={
                    (user && user?.freeTrial?.isFreeTrialEnded) ||
                    isFreeTrialLoading
                  }
                >
                  {" "}
                  {user && user?.freeTrial?.isFreeTrialEnded && (
                    <Tooltip text="You cannot utilize free trial more than once!" />
                  )}
                </CustomButton>
              </div>
            </div>
          </ProfileLayout>
        )}
      </form>
    </div>
  );
};
const paymentTypes = [
  { name: "Card", value: "Card", icon: Card },
  // { name: "Tamara", value: "Tamara", icon: TamaraIcon },
];
