import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SettingsIcon,
  CrossIcon,
  Warning,
  BlueBulb,
  RocketIcon,
  RedIcon,
} from "../../../../../common/Icons";
import {
  markasRead,
  notficationMessages,
} from "../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import AdminNotificationSetting from "../AdminNotificationSetting/AdminNotificationSetting";
import useAdminNavNotification from "./useAdminNavNotification";
import { Dialog } from "@headlessui/react";

const iconList = [Warning, BlueBulb, RocketIcon];

const getRandomIcon = () => {
  const randomIndex = Math.floor(Math.random() * iconList.length);
  return iconList[randomIndex];
};

const AdminNavNotifications = ({ isNotificationOpen, setNotificationOpen }) => {
  const { data, isLoading } = useSelector(
    (state) => state.dashboard.notficationMessages
  );

  const { notifications } = useAdminNavNotification({ data });
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("All");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const onClose = () => setNotificationOpen(false);

  const unreadNotifications = notifications?.filter(
    (notification) => !notification?.read
  );

  const handleSettingsClick = () => {
    setIsSettingsOpen(true);
  };

  const handleBackClick = () => {
    setIsSettingsOpen(false);
  };

  const handleMarkAllAsRead = () => {
    dispatch(markasRead());
  };

  useEffect(() => {
    dispatch(notficationMessages());
  }, [dispatch]);

  return (
    <Dialog
      open={isNotificationOpen}
      onClose={onClose}
      className="fixed z-10 inset-0"
    >
      {/* Custom Overlay */}
      <div
        className="fixed inset-0 bg-gray-300 bg-opacity-25 transition-opacity"
        onClick={onClose}
      ></div>

      <div className="flex items-end justify-center px-4 text-end mt-8 mr-20 sm:block sm:p-0">
        <span className="hidden sm:inline-block" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-baseline sm:max-w-lg sm:w-full sm:py-3">
          {!isSettingsOpen ? (
            <div>
              <div className="">
                <div className="flex justify-between items-center border-b pb-2">
                  <div className="flex space-x-4 px-8">
                    <button
                      onClick={() => setActiveTab("All")}
                      className={`${
                        activeTab === "All"
                          ? "text-blue-500 border-b-2 border-blue-500"
                          : "text-gray-500"
                      }`}
                    >
                      All
                    </button>
                    <button
                      onClick={() => setActiveTab("Unread")}
                      className={`${
                        activeTab === "Unread"
                          ? "text-blue-500 border-b-2 border-blue-500"
                          : "text-gray-500"
                      }`}
                    >
                      Unread
                    </button>
                  </div>
                  <div className="flex space-x-2 px-3">
                    <button
                      className="text-blue-500"
                      onClick={handleMarkAllAsRead}
                    >
                      Mark All as Read
                    </button>
                    <button onClick={handleSettingsClick}>
                      <SettingsIcon />
                    </button>
                  </div>
                </div>
                <div className="min-h-[300px] max-h-[500px] overflow-auto">
                  {(activeTab === "All"
                    ? notifications
                    : unreadNotifications
                  )?.map((notification) => {
                    const Icon = notification.icon;
                    return (
                      <div
                        key={notification.id}
                        className={`flex border-b rounded shadow-sm border-gray-400 py-6 ${
                          !notification?.read ? "bg-blue-100" : "bg-white"
                        }`}
                      >
                        <div className="flex-shrink-0 px-4">
                          <Icon className="h-6 w-6" />
                        </div>
                        <div className="flex-grow">
                          <p className="text-sm text-text-secondary break-words">
                            {notification?.message}
                          </p>
                          <p className="text-xs text-text-secondary mt-1">
                            {notification?.time}
                          </p>
                        </div>
                        {!notification.read && (
                          <div className="ml-auto mr-2 flex items-center space-x-1">
                            <span className="mr-2">
                              <RedIcon />
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <AdminNotificationSetting handleBackClick={handleBackClick} />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default AdminNavNotifications;
