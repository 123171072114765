import React from "react";

function CarServiceIcon(props) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M30.3335 14H28.9835L25.5085 6.18751C25.3528 5.83347 25.0972 5.53255 24.7731 5.32162C24.4489 5.11068 24.0702 4.99892 23.6835 5.00001H8.9835C8.59675 4.99892 8.21808 5.11068 7.89392 5.32162C7.56975 5.53255 7.31417 5.83347 7.1585 6.18751L3.6835 14H2.3335C2.06828 14 1.81393 14.1054 1.62639 14.2929C1.43885 14.4804 1.3335 14.7348 1.3335 15C1.3335 15.2652 1.43885 15.5196 1.62639 15.7071C1.81393 15.8947 2.06828 16 2.3335 16H3.3335V26C3.3335 26.5304 3.54421 27.0391 3.91928 27.4142C4.29436 27.7893 4.80306 28 5.3335 28H8.3335C8.86393 28 9.37264 27.7893 9.74771 27.4142C10.1228 27.0391 10.3335 26.5304 10.3335 26V24H22.3335V26C22.3335 26.5304 22.5442 27.0391 22.9193 27.4142C23.2944 27.7893 23.8031 28 24.3335 28H27.3335C27.8639 28 28.3726 27.7893 28.7477 27.4142C29.1228 27.0391 29.3335 26.5304 29.3335 26V16H30.3335C30.5987 16 30.8531 15.8947 31.0406 15.7071C31.2281 15.5196 31.3335 15.2652 31.3335 15C31.3335 14.7348 31.2281 14.4804 31.0406 14.2929C30.8531 14.1054 30.5987 14 30.3335 14ZM10.3335 20H8.3335C8.06828 20 7.81393 19.8947 7.62639 19.7071C7.43885 19.5196 7.3335 19.2652 7.3335 19C7.3335 18.7348 7.43885 18.4804 7.62639 18.2929C7.81393 18.1054 8.06828 18 8.3335 18H10.3335C10.5987 18 10.8531 18.1054 11.0406 18.2929C11.2281 18.4804 11.3335 18.7348 11.3335 19C11.3335 19.2652 11.2281 19.5196 11.0406 19.7071C10.8531 19.8947 10.5987 20 10.3335 20ZM22.3335 20C22.0683 20 21.8139 19.8947 21.6264 19.7071C21.4389 19.5196 21.3335 19.2652 21.3335 19C21.3335 18.7348 21.4389 18.4804 21.6264 18.2929C21.8139 18.1054 22.0683 18 22.3335 18H24.3335C24.5987 18 24.8531 18.1054 25.0406 18.2929C25.2281 18.4804 25.3335 18.7348 25.3335 19C25.3335 19.2652 25.2281 19.5196 25.0406 19.7071C24.8531 19.8947 24.5987 20 24.3335 20H22.3335Z" />
    </svg>
  );
}

export default CarServiceIcon;
