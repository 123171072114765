import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useUserContext } from "../../../../../context/UserContext";
import { setPlanSchema } from "../../../../Signup/ClientProfile/SetPlan/Validator";
import {
  calculatePricingSummary,
  getPricing,
  upgradePlan,
} from "../../../../../features/ClientSubscriptions/ClientSubscriptionsSlice";
import { getAllStaffUsers } from "../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { getAllSubscriptions } from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";

/**
 * Custom hook for managing the create account form.
 * @returns {Object} An object containing methods and states for form handling and image upload validation.
 */
export const useUpgradeFreeTrail = ({ closeModal, selectedItem }) => {
  const dispatch = useDispatch();
  const { user } = useUserContext();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(setPlanSchema),
  });
  const isLoading = useSelector(
    (state) => state.clientSubscriptions.getPricing.isLoading
  );
  const pricingData = useSelector(
    (state) => state.clientSubscriptions.getPricing.data
  );
  const { data: calculatedPrice, isLoading: priceSummaryLoading } = useSelector(
    (state) => state.clientSubscriptions.calculatePricingSummary
  );

  const [selectedPlan, setSelectedPlan] = useState("Basic");
  const [selectedAccountManager, setSelectedAccountManager] = useState("");
  const [activeTab, setActiveTab] = useState("MONTHLY");
  const [branchCount, setBranchCount] = useState(0);

  const [additionalBranchPrice, setAdditionalBranchPrice] = useState(0);
  const matchedPlan = pricingData?.find(
    (plan) => plan.plan_tenure === activeTab && plan.name === selectedPlan
  );

  // Extract the amount from the matched plan
  const planId = matchedPlan ? matchedPlan.planId : null;
  const totalPriceBeforeTax = calculatedPrice?.totalBeforeTax;
  const tax = calculatedPrice?.taxAmount;
  const totalPrice = calculatedPrice?.subTotal;

  useEffect(() => {
    setAdditionalBranchPrice(0);
    setBranchCount(0);
  }, [selectedPlan]);

  useEffect(() => {
    dispatch(getPricing({ type: activeTab }));
  }, [dispatch, activeTab]);

  useEffect(() => {
    setAdditionalBranchPrice(
      activeTab === "MONTHLY" ? 71 * branchCount : 850 * branchCount
    );
  }, [branchCount, activeTab]);

  const calculatePricing = () => {
    const payload = {
      planId: planId,
      plan_tenure: activeTab,
      totalBranches: branchCount,
    };

    dispatch(
      calculatePricingSummary({
        payload: payload,
        successCallBack: onSuccessCalculatePricing,
      })
    );
  };

  useEffect(() => {
    if (planId) {
      calculatePricing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan, activeTab, planId, branchCount]);

  const onSuccessCalculatePricing = () => {};
  //kaam mera end
  useEffect(() => {
    dispatch(getPricing({ type: activeTab }));
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (pricingData && selectedPlan) {
      const plan = pricingData.find((p) => p.name === selectedPlan);
      if (plan) {
        setAdditionalBranchPrice(plan.additionalBranchPrice);
      }
    }
  }, [pricingData, selectedPlan]);

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: { id: "DESC" },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStaffUsers({ payload }));
  }, []);

  const { data: staffUser } = useSelector(
    (state) => state.dashboard.getAllStaffUsers
  );
  const staffUserOptions = [
    { value: "", label: "Select Account Manager" },
    ...(staffUser?.data?.data
      ?.filter((user) => user.role === "ACCOUNT_MANAGER")
      ?.map((user) => ({
        value: user.id,
        label: user.fullName,
      })) || []),
  ];
  const handleSelectChange = (event) => {
    setSelectedAccountManager(event.target.value);
  };

  const onSubmit = () => {
    const subscriptionData = {
      plan_tenure: activeTab,
      planId: planId,
      branches: branchCount,
      // paymentMethod: "987.5",
      userId: selectedItem?.clientUser?.id,
      totalBeforeTax: totalPriceBeforeTax,
      taxAmount: tax,
      subTotal: totalPrice,
      managedBy: selectedAccountManager,
    };

    // Disable the button immediately after click
    setIsButtonDisabled(true);

    // Dispatch the action
    dispatch(
      upgradePlan({
        payload: subscriptionData,
        successCallBack: onSubscriptionSuccess,
      })
    );

    // Re-enable the button after 3 seconds
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000); // 3 seconds delay
  };

  const onSubscriptionSuccess = async (data) => {
    closeModal();
    const fetchAllSubscriptions = () => {
      dispatch(
        getAllSubscriptions({
          payload: {
            page: 1,
            pageSize: 10,
            sortColumn: "id",
            order: { id: "DESC" },
            condition: {},
            attributes: {},
            periodId: 0,
          },
        })
      );
    };
    fetchAllSubscriptions();
  };

  return {
    onSubmit,
    handleSubmit,
    register,
    errors,
    isSubmitting,
    setBranchCount,
    branchCount,
    setActiveTab,
    activeTab,
    setSelectedPlan,
    selectedPlan,
    additionalBranchPrice,
    totalPrice,
    tax,
    totalPriceBeforeTax,
    control,
    pricingData,
    isLoading,
    calculatePricing,
    priceSummaryLoading,
    user,
    staffUserOptions,
    handleSelectChange,
    selectedAccountManager,
    isButtonDisabled,
  };
};

export default useUpgradeFreeTrail;
