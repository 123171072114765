import React from "react";
import InputLabel from "../InputLabel/InputLabel";
import _ from "lodash";
import { useTranslation } from "react-i18next";

/**
 * InputGroup component.
 * Renders a group of input elements with a label, input field, error message, and optional children.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.htmlfor - The HTML for attribute.
 * @param {string} props.labelText - The text for the input label.
 * @param {string} props.inputType - The type of input (e.g., text, checkbox).
 * @param {string} props.placeholder - The placeholder text for the input.
 * @param {string} props.inputName - The name of the input element.
 * @param {Object} props.register - The input registration object from React Hook Form.
 * @param {Object} props.errors - The error object from React Hook Form.
 * @param {string} props.value - The value of the input element.
 * @param {Function} props.onChange - The function to handle input changes.
 * @param {string} props.className - The additional CSS classes for the input.
 * @param {React.ReactNode} props.children - Optional children components.
 * @param {boolean} props.focusOnType - Whether to apply focus ring when typing.
 * @returns {JSX.Element} JSX code for rendering the InputGroup component.
 */

const InputGroup = ({
  htmlFor = "",
  labelText = "",
  inputType = "text",
  placeholder = "",
  inputName = "",
  register = () => {},
  errors = {},
  value,
  onChange,
  className = "",
  children,
  defaultValue = "",
  checked,
  readOnly,
  bgColor,
  disabled = false,
  extraLabel = "",
  focusOnType = false,
  minDate = null,
}) => {
  const { t } = useTranslation();

  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Get today's date in YYYY-MM-DD format if minDate is true
  const today = minDate === true ? getTodayDate() : null;

  // Use the provided minDate if it's a string, otherwise use today or null
  const minDateValue = typeof minDate === "string" ? minDate : today;

  return (
    <div className={`relative space-y-1 ${className}`}>
      {labelText && (
        <InputLabel
          htmlFor={htmlFor}
          text={labelText}
          extraLabel={extraLabel}
        />
      )}
      {inputType !== "textarea" ? (
        <input
          type={inputType}
          name={inputName}
          placeholder={t(placeholder)}
          checked={checked}
          defaultValue={defaultValue}
          value={value}
          readOnly={readOnly}
          className={`${
            inputType === "checkbox" || inputType === "radio"
              ? "text-text-link border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              : "block w-full px-4 py-2 text-text-primary bg-white border border-border-primary rounded-lg focus:border-border-primary focus:ring-0 focus:outline-none"
          } ${bgColor} ${inputType === "checkbox" && "rounded"} ${
            _.get(errors, inputName) ? "!border-red-500" : ""
          } ${focusOnType ? "focus:ring-2 focus:ring-blue-500" : ""}`}
          onChange={onChange}
          autocomplete="new-password"
          {...register(inputName)}
          disabled={disabled}
          {...(inputType === "date" && minDateValue && { min: minDateValue })}
        />
      ) : (
        <textarea
          rows="6"
          name={inputName}
          placeholder={t(placeholder)}
          defaultValue={defaultValue}
          value={value}
          className={`block w-full px-4 py-2 appearance-none text-text-primary bg-white border rounded-lg focus:border-border-primary focus:ring-0 focus:outline-none h-28 resize-none ${
            _.get(errors, inputName)
              ? "border-red-500"
              : "border-border-primary"
          } ${focusOnType ? "focus:ring-2 focus:ring-blue-500" : ""}`}
          onChange={onChange}
          autoComplete="off"
          {...register(inputName)}
        />
      )}

      {children}
      {_.get(errors, inputName) && (
        <p className="text-red-500 text-sm">
          {t(_.get(errors, inputName).message)}
        </p>
      )}
    </div>
  );
};

export default InputGroup;

// Sample Usage:
// import InputGroup from '.path/InputGroup';
// <InputGroup
//   labelText="Email"
//   inputType="email"
//   placeholder="Enter your email"
//   inputName="email"
//   register={register}
//   errors={errors}
//   focusOnType={true}
// />
