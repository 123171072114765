import api from "../../../services/api";

/**
 * Creates expense invoices API endpoints based on the specified base path.
 * @param {string} basePath - The base path for expense invoices endpoints.
 * @returns {Object} Object containing expense invoices API methods.
 */
const createExpenseInvoiceApi = (basePath) => ({
  /**
   * Retrieves all expense invoices.
   * @param {object} payload - Payload containing query parameters.
   * @param {number} payload.page - Current page.
   * @param {number} payload.pageSize - Number of items per page.
   * @param {string} payload.sortColumn - Column ID for sorting expense invoices.
   * @param {string} payload.order - Order for sorting expense invoices by ascending or descending.
   * @param {object} payload.condition - Additional conditions for filtering.
   * @param {object} payload.attributes - Additional attributes for customization.
   * @returns {Promise} A Promise that resolves to the expense invoices response.
   */
  getAllExpenseInvoices: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * Retrieves a single expense invoice.
   * @param {string} invoiceId - ID of the expense invoice.
   * @returns {Promise} A Promise that resolves to the expense invoice response.
   */
  getSingleExpenseInvoice: async (invoiceId) => {
    const response = await api().get(`${basePath}/${invoiceId}`);
    return response;
  },

  /**
   * Updates a single expense invoice.
   * @param {string} invoiceId - ID of the expense invoice.
   * @param {object} payload - Payload containing updated data.
   * @returns {Promise} A Promise that resolves to the expense invoice response.
   */
  updateExpenseInvoice: async (invoiceId, payload) => {
    const response = await api().put(`${basePath}/${invoiceId}`, payload);
    return response;
  },

  /**
   * Adds an expense invoice.
   * @param {object} payload - Payload containing data for the new expense invoice.
   * @returns {Promise} A Promise that resolves to the expense invoice response.
   */
  addExpenseInvoice: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * Deletes an expense invoice.
   * @param {string} invoiceId - ID of the expense invoice to be deleted.
   * @returns {Promise} A Promise that resolves to the deletion response.
   */
  deleteExpenseInvoice: async (invoiceId) => {
    const response = await api().delete(`${basePath}/${invoiceId}`);
    return response;
  },
  /**
   * udno a deleted expense invoice.
   * @param {string} invoiceId - ID of the expense invoice to be undo.
   * @returns {Promise} A Promise that resolves to the undo response.
   */
  undoExpenseInvoice: async (invoiceId) => {
    const response = await api().get(
      `${basePath}/undo-expense-invoice/${invoiceId}`
    );
    return response;
  },
});

// Export the createExpenseInvoiceApi

/**
 * Example usage:
 * import createExpenseInvoiceApi from './path/to/api/expenseInvoiceApi';
 *
 * const expenseInvoiceApi = createExpenseInvoiceApi('expenseInvoices');
 *
 * const getAllExpenseInvoices = async (payload) => {
 *   try {
 *     const response = await expenseInvoiceApi.getAllExpenseInvoices(payload);
      // Handle response
 *   } catch (error) {
      // Handle error
 *   }
 * };
 */

export default createExpenseInvoiceApi;
