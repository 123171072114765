import * as React from "react";

function HomeIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="HouseLine">
        <path
          id="Vector"
          d="M16.875 16.875V9.02344C16.8736 8.93694 16.8549 8.8516 16.82 8.77244C16.7851 8.69328 16.7348 8.6219 16.6719 8.5625L10.4219 2.88281C10.3067 2.77741 10.1562 2.71895 10 2.71895C9.84384 2.71895 9.69334 2.77741 9.57812 2.88281L3.32812 8.5625C3.26523 8.6219 3.21486 8.69328 3.17998 8.77244C3.14509 8.8516 3.1264 8.93694 3.125 9.02344V16.875"
          stroke={props.strokeColor ? props.strokeColor : "#475467"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M1.25 16.875H18.75"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M11.875 16.875V12.5C11.875 12.3342 11.8092 12.1753 11.6919 12.0581C11.5747 11.9408 11.4158 11.875 11.25 11.875H8.75C8.58424 11.875 8.42527 11.9408 8.30806 12.0581C8.19085 12.1753 8.125 12.3342 8.125 12.5V16.875"
          stroke={props.strokeColor ? props.strokeColor : "#667085"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default HomeIcon;
