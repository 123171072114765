import React from "react";

function PaymentSucces() {
  return (
    <svg
      width="178"
      height="160"
      viewBox="0 0 178 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M175.083 83.1034C175.083 104.598 166.303 123.974 152.226 137.9C138.451 151.675 119.378 160 98.3373 160C77.448 160 58.3751 151.523 44.4489 137.9C30.3714 123.974 21.5918 104.598 21.5918 83.1034C21.5918 40.568 55.9532 6.20654 98.3373 6.20654C140.721 6.20654 175.083 40.7193 175.083 83.1034Z"
        fill="#EAEEF9"
      />
      <path
        d="M168.422 32.6962C171.849 32.6962 174.628 29.9176 174.628 26.4899C174.628 23.0623 171.849 20.2837 168.422 20.2837C164.994 20.2837 162.215 23.0623 162.215 26.4899C162.215 29.9176 164.994 32.6962 168.422 32.6962Z"
        fill="#EAEEF9"
      />
      <path
        d="M173.505 8.47682C175.845 8.47682 177.743 6.57922 177.743 4.23841C177.743 1.8976 175.845 0 173.505 0C171.164 0 169.266 1.8976 169.266 4.23841C169.266 6.57922 171.164 8.47682 173.505 8.47682Z"
        fill="#EAEEF9"
      />
      <path
        d="M24.0133 32.5447C26.3541 32.5447 28.2517 30.6471 28.2517 28.3063C28.2517 25.9655 26.3541 24.0679 24.0133 24.0679C21.6725 24.0679 19.7749 25.9655 19.7749 28.3063C19.7749 30.6471 21.6725 32.5447 24.0133 32.5447Z"
        fill="#EAEEF9"
      />
      <path
        d="M8.12866 114.286C12.4759 114.286 16 110.762 16 106.414C16 102.067 12.4759 98.543 8.12866 98.543C3.78144 98.543 0.257324 102.067 0.257324 106.414C0.257324 110.762 3.78144 114.286 8.12866 114.286Z"
        fill="#EAEEF9"
      />
      <path
        d="M152.225 42.8384V137.9C138.45 151.675 119.377 160 98.3366 160C77.4473 160 58.3744 151.523 44.4482 137.9V42.8384H152.225Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M100.24 91.3584L79.1543 70.2725L71.983 77.4438L93.0688 98.5297L100.24 91.3584Z"
        fill="#039855"
      />
      <path
        opacity="0.5"
        d="M117.536 59.79L86.0679 91.2583L93.2392 98.4296L124.708 66.9613L117.536 59.79Z"
        fill="#039855"
      />
      <path
        d="M125.887 111.41H70.7876V118.373H125.887V111.41Z"
        fill="#D5DAE5"
      />
      <path
        d="M125.887 126.698H70.7876V133.661H125.887V126.698Z"
        fill="#D5DAE5"
      />
      <path
        d="M112.414 142.138H84.2588V149.101H112.414V142.138Z"
        fill="#D5DAE5"
      />
    </svg>
  );
}

export default PaymentSucces;
