import React from "react";
import { Truck } from "../../../../common/Icons";
import { useTranslation } from "react-i18next";
import { useFullPay } from "./useFullPay";

function FullPayByDeliveryOrder() {
  const {
    paymentMethodsList,
    selectedCompany,
    handleSelectionChange,
    total,
    totalAmount,
    taxAmount,
    discountAmount,
  } = useFullPay();

  const { t } = useTranslation();

  const deliveryCompanies =
    paymentMethodsList
      ?.find((method) => method?.type === "DELIVERY_COMPANIES")
      ?.paymentDeliveryNotes.map((note) => note.delivery) || [];

  return (
    <div className="bg-white rounded-lg p-4">
      <span className="mb-4 text-sm font-medium">
        {t("pos_dashboard.choose_method")}
      </span>
      <fieldset>
        {deliveryCompanies?.map((company) => (
          <div
            key={company?.companyName}
            className="flex items-center justify-between mb-4 border p-3 rounded-lg"
            onClick={() => handleSelectionChange(company)}
          >
            <label
              htmlFor={company?.companyName}
              className="flex items-center gap-2 text-sm font-medium cursor-pointer"
            >
              <Truck className="text-lg text-gray-600" />
              {company?.companyName}
            </label>
            <input
              id={company.companyName}
              name="companyaddress"
              type="radio"
              value={company?.companyName}
              checked={selectedCompany?.companyName === company?.companyName}
              onChange={() => handleSelectionChange(company)}
              className="form-radio h-4 w-4 text-blue-400  border-gray-300 focus:ring-blue-400 cursor-pointer"
              style={{ accentColor: "blue" }}
            />
          </div>
        ))}
      </fieldset>
      <div className="bg-white  rounded-lg mt-[35px]  p-2 w-full">
        <hr className="border mb-2" />
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {" "}
            {t("pos_dashboard.sub_total")}
          </span>
          <span className="text-sm font-medium">{totalAmount ?? "0"} SAR</span>
        </div>
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {" "}
            {t("pos_dashboard.discount")}
          </span>
          <span className="text-sm font-medium">
            {discountAmount ?? "0"} SAR
          </span>
        </div>
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {t("pos_dashboard.fee_percentage")}
          </span>
          <span className="text-sm font-medium">
            {selectedCompany?.feePercentage ?? "0"} SAR
          </span>
        </div>
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {t("pos_dashboard.amount_added")}
          </span>
          <span className="text-sm font-medium">
            {selectedCompany?.amountAdded ?? "0"} SAR
          </span>
        </div>

        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {" "}
            {t("pos_dashboard.vat")}
          </span>
          <span className="text-sm font-medium">{taxAmount ?? "0"} SAR</span>
        </div>
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {" "}
            {t("pos_dashboard.total")}
          </span>
          <span className="text-sm font-medium">{total ?? "0"} SAR</span>
        </div>
        <hr className="border mb-2" />
      </div>{" "}
    </div>
  );
}

export default FullPayByDeliveryOrder;
