import React from "react";
import CustomAdminModal from "../../../common/components/AdminModal/CustomAdminModal";
import CustomButton from "../../../common/components/Button/Button";
import SelectGroup from "../../../common/components/SelectDropdown/SelectDropdown";
import { Check } from "../../../common/Icons/fontawesome";
import useAddTable from "./useAddTable";
import { useTranslation } from "react-i18next";

const AddTable = ({ isOpen, onClose, onAddTable }) => {
  const {
    tableCount,
    isFormValid,
    validationError,
    selectTable,
    handleTableCountChange,
    tableStyles,
    submitTable,
    handleCancel,
    isSelected,
  } = useAddTable(onAddTable, onClose);
  const { t } = useTranslation();
  return (
    <CustomAdminModal
      isOpen={isOpen}
      onClose={onClose}
      title={t("pos_dashboard.add_new_table")}
      width="min-[280px]:w-[80%] min-[1080px]:w-[800px]"
    >
      <div className="flex flex-wrap p-4">
        <span className="mb-2 mt-2 pl-2 text-text-primary text-sm font-medium">
          {t("pos_dashboard.add_table_label")}{" "}
        </span>
        <div className="flex space-x-4 mb-2 p-2 overflow-auto">
          {["rectangle", "square", "circle"].map((type) => (
            <div
              key={type}
              className={`h-[230px] w-[240px] overflow-auto border rounded-lg bg-surface-gray p-5 ${
                isSelected(type)
                  ? "border-text-link bg-info-50 text-text-link"
                  : "bg-surface-gray"
              } cursor-pointer`}
              onClick={() => selectTable(type)}
            >
              {isSelected(type) && (
                <div className="flex justify-end">
                  <Check color="blue" />
                </div>
              )}
              <CustomButton
                text={t("pos_dashboard.numbers")}
                customStyles={tableStyles[type]}
                textColor="text-text-primary"
                bgColor="bg-white"
                border="border"
                hoverBgColor="bg-surface-gray"
                className={`mt-2 ${isSelected(type) ? "" : "bg-gray-200"}`}
              />
              <div className="text-sm font-medium mt-6 text-center">
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </div>
              <div className="text-sm font-normal mt-2 text-center">
                {type === "rectangle"
                  ? "(Capacity: 10 - 12)"
                  : type === "square"
                  ? "(Capacity: 4)"
                  : "(Capacity: 4 - 6)"}
              </div>
            </div>
          ))}
        </div>
        <span className="mb-2 mt-2 pl-2 text-text-primary text-sm font-medium">
          {t("pos_dashboard.how_many")}
        </span>
        <SelectGroup
          inputName="tableCount"
          options={[
            { value: "", label: t("pos_dashboard.select_how_many_table") },
            { value: "1", label: t("pos_dashboard.one") },
            { value: "2", label: t("pos_dashboard.two") },
            { value: "3", label: t("pos_dashboard.three") },
            { value: "4", label: t("pos_dashboard.four") },
            { value: "5", label: t("pos_dashboard.five") },
          ]}
          value={tableCount}
          onChange={handleTableCountChange}
          className="w-full mb-4 rtl:bg-left"
        />
        {validationError && (
          <div className="text-red-500">
            {validationError.tableCount?.message ||
              validationError.selectedTable?.message}
          </div>
        )}
        <div className="mt-4 flex flex-row gap-2 justify-end items-end w-full">
          <CustomButton
            text={t("pos_dashboard.cancel")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            onClick={handleCancel}
            bgColor="bg-white"
            hoverBgColor="bg-gray-200"
          />
          <CustomButton
            text={t("pos_dashboard.add_table")}
            width="w-fit"
            onClick={submitTable}
            disabled={!isFormValid}
          />
        </div>
      </div>
    </CustomAdminModal>
  );
};

export default AddTable;
