/**
 * Single source for all images.
 * Contains various images for different purposes.
 * @returns {Object} An object containing imported images.
 */

import LoginBgImage from "./LoginBgImage.png";
import PosLoginMainImage from "./PosLoginMainImage.png";
import PosLoginBgImage from "./PosLoginBgImage.png";
import SignUpMainImage from "./SignUpImage.png";
import StepperDividerImage from "./StepperDividerImage.png";
import ImageIcon from "./ImageIcon.png";
import DefaultImg from "./DefaultImg.png";
import Barcode from "./Barcode.png";
import AmericanExpress from "./AmericanExpress.png";
import MadaCard from "./MadaCard.png";
import CircleCards from "./CircleCards.png";
import Tamara from "./Tamara.png";
import DashboardIcon from "./DashboardIcon.png";
import PosIcon from "./PosIcon.png";
import HeroBgLandingPage from "./HeroBgLandingPage.png";
import HeroSectionImage from "./HeroSectionImage.png";
import GooglePay from "./GooglePay.png";
import VisaPay from "./VisaPay.png";
import Tabby from "./Tabby.png";
import ZakatTax from "./ZakatTax.png";
import AppleStore from "./AppleStore.png";
import StcPay from "./StcPay.png";
import OncePlaceItem1 from "./OncePlaceItem1.png";
import OncePlaceItem2 from "./OncePlaceItem2.png";
import OncePlaceItem3 from "./OncePlaceItem3.png";
import OncePlaceItem4 from "./OncePlaceItem4.png";
import OncePlaceItem5 from "./OncePlaceItem5.png";
import Headline from "./Headline.png";
import CloudSystem from "./CloudSystem.png";
import PhoneBusiness from "./PhoneBusiness.png";
import ContinuosDevelopment from "./ContinuosDevelopment.png";
import AiAnalysis from "./AiAnalysis.png";
import OfflineOperations from "./OfflineOperations.png";
import Headphone from "./Headphone.png";
import Tag from "./Tag.png";
import Dollars from "./Dollars.png";
import FooterLandingPage from "./FooterLangingPage.png";
import Facebook from "./Facebook.png";
import Instagram from "./Instagram.png";
import Twitter from "./Twitter.png";
import Youtube from "./Youtube.png";
import GlobeSimple from "./GlobeSimple.png";
import ChoosePlanTopLines from "./ChoosePlanTopLines.png";
import ChoosePlanBottomLines from "./ChoosePlanBottomLines.png";
import PointArrow from "./PointArrow.png";
import InventoryFeature from "./InventoryFeature.png";
import InvoicesFeature from "./InvoicesFeature.png";
import DirectoryFeature from "./DirectoryFeature.png";
import ReportFeature from "./ReportFeature.png";
import PosMainCard from "./PosMainCard.png";
import NetIncomeCard from "./NetIncomeCard.png";
import SalesAndCostCard from "./SalesAndCostCard.png";
import ChocolateBoxCard from "./ChocolateBoxCard.png";
import PosMainCardMobile from "./PosMainCardMobile.png";
import NetIncomeCardMobile from "./NetIncomeCardMobile.png";
import SalesAndCostCardMobile from "./SalesAndCostCardMobile.png";
import ChocolateBoxCardMobile from "./ChocolateBoxCardMobile.png";
import PosImg from "./PosImg.png";

export {
  DefaultImg,
  LoginBgImage,
  PosLoginMainImage,
  PosLoginBgImage,
  SignUpMainImage,
  StepperDividerImage,
  ImageIcon,
  PosImg,
  Barcode,
  AmericanExpress,
  MadaCard,
  CircleCards,
  Tamara,
  DashboardIcon,
  PosIcon,
  HeroBgLandingPage,
  HeroSectionImage,
  GooglePay,
  Tabby,
  VisaPay,
  AppleStore,
  ZakatTax,
  StcPay,
  OncePlaceItem1,
  OncePlaceItem2,
  OncePlaceItem3,
  OncePlaceItem4,
  OncePlaceItem5,
  Headline,
  CloudSystem,
  PhoneBusiness,
  ContinuosDevelopment,
  AiAnalysis,
  OfflineOperations,
  Headphone,
  Tag,
  Dollars,
  FooterLandingPage,
  Facebook,
  Instagram,
  Twitter,
  Youtube,
  GlobeSimple,
  ChoosePlanTopLines,
  ChoosePlanBottomLines,
  PointArrow,
  InventoryFeature,
  InvoicesFeature,
  DirectoryFeature,
  ReportFeature,
  PosMainCard,
  NetIncomeCard,
  SalesAndCostCard,
  ChocolateBoxCard,
  PosMainCardMobile,
  NetIncomeCardMobile,
  SalesAndCostCardMobile,
  ChocolateBoxCardMobile,
};
