import * as React from "react";

function Xcross(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="XCircle">
    <path id="Vector" d="M13.3006 11.7028L13.2982 11.7005L11.5913 10L13.2982 8.29954L13.3159 8.28187L13.3318 8.26252C13.5102 8.04514 13.6014 7.76917 13.5876 7.48828C13.5738 7.20739 13.456 6.94168 13.2572 6.74283L12.9036 7.09638L13.2572 6.74283C13.0583 6.54397 12.7926 6.42619 12.5117 6.4124C12.2308 6.3986 11.9549 6.48978 11.7375 6.66819L11.7181 6.68407L11.7005 6.70181L10 8.40874L8.29954 6.70181L8.28187 6.68407L8.26252 6.66819C8.04514 6.48978 7.76917 6.3986 7.48828 6.4124C7.2074 6.42619 6.94169 6.54397 6.74283 6.74283C6.54398 6.94168 6.4262 7.20739 6.4124 7.48828C6.39861 7.76916 6.48978 8.04514 6.66819 8.26252L6.68407 8.28187L6.70181 8.29954L8.40874 10L6.70181 11.7005L6.7018 11.7005L6.69944 11.7028C6.48935 11.915 6.37149 12.2014 6.37149 12.5C6.37149 12.7986 6.48935 13.085 6.69944 13.2972L6.69936 13.2972L6.70713 13.3048C6.91983 13.5104 7.20413 13.6254 7.5 13.6254C7.79588 13.6254 8.08017 13.5104 8.29288 13.3048L8.29294 13.3048L8.29954 13.2982L10 11.5913L11.7005 13.2982L11.7004 13.2983L11.7071 13.3048C11.9198 13.5104 12.2041 13.6254 12.5 13.6254C12.7959 13.6254 13.0802 13.5104 13.2929 13.3048L13.293 13.3048L13.3006 13.2972C13.5107 13.085 13.6285 12.7986 13.6285 12.5C13.6285 12.2014 13.5107 11.915 13.3006 11.7028ZM5.76378 3.66004C7.01757 2.82229 8.49161 2.37509 9.99953 2.375C12.0207 2.379 13.9579 3.18368 15.3871 4.61287C16.8163 6.04206 17.621 7.9793 17.625 10.0005C17.6249 11.5084 17.1777 12.9824 16.34 14.2362C15.5021 15.4901 14.3113 16.4675 12.918 17.0446C11.5247 17.6217 9.99155 17.7727 8.51244 17.4785C7.03333 17.1843 5.67469 16.4581 4.60831 15.3917C3.54194 14.3253 2.81573 12.9667 2.52152 11.4876C2.2273 10.0085 2.3783 8.47533 2.95542 7.08204C3.53254 5.68875 4.50986 4.49789 5.76378 3.66004Z" fill="#D92D20" stroke="#D92D20"/>
    </g>
    </svg>
    
  );
}

export default Xcross;
