import { useState } from "react";

export const usePosDrawer = () => {
  const [storeCode, setStoreCode] = useState(Array(4).fill(""));

  // Function to submit the store code
  const submitStoreCode = () => {
    if (storeCode.some((val) => val === "")) {
      return;
    }
  };

  // Function to handle button click and navigation

  // Function to handle keypad button presses

  const handleKeypadPress = (key, storeCode) => {
    if (storeCode) {
      setStoreCode((prevCode) => {
        const nextCode = [...prevCode];
        const firstEmptyIndex = nextCode.findIndex((val) => val === "");
        if (key === "←") {
          if (firstEmptyIndex === -1) nextCode[nextCode.length - 1] = "";
          else if (firstEmptyIndex > 0) nextCode[firstEmptyIndex - 1] = "";
        } else if (firstEmptyIndex !== -1) {
          nextCode[firstEmptyIndex] = key;
        }
        return nextCode;
      });
    } else {
      setStoreCode((prevCode) => {
        const nextCode = [...prevCode];
        const firstEmptyIndex = nextCode.findIndex((val) => val === "");
        if (key === "←") {
          if (firstEmptyIndex === -1) nextCode[nextCode.length - 1] = "";
          else if (firstEmptyIndex > 0) nextCode[firstEmptyIndex - 1] = "";
        } else if (firstEmptyIndex !== -1) {
          nextCode[firstEmptyIndex] = key;
        }
        return nextCode;
      });
    }
  };
  // Add event listener to handle clicks outside the language dropdown

  /**
   * Custom hook for handling the Point of Sale (POS) login functionality.
   * This hook provides methods and states for managing the POS login form and behavior.
   * @returns {Object} An object containing:
   *   - dropdownOpen: State to track whether the language dropdown is open.
   *   - selectedLanguage: State to store the selected language.
   *   - storeCode: State to store the store code input.
   *   - fastAccessCode: State to store the fast access code input.
   *   - isFastAccess: State to track whether fast access mode is enabled.
   *   - toggleDropdown: Function to toggle the language dropdown's visibility.
   *   - handleSelectLanguage: Function to handle language selection.
   *   - dropdownRef: Reference to the language dropdown element.
   *   - errorMessage: State to store error messages.
   *   - submitStoreCode: Function to handle submission of the store code.
   *   - submitFastAccessCode: Function to handle submission of the fast access code.
   *   - toggleFastAccess: Function to toggle fast access mode.
   *   - handleButtonClick: Function to navigate to a different route.
   *   - handleKeypadPress: Function to handle keypad button presses.
   */
  return {
    storeCode,
    handleKeypadPress,
    submitStoreCode,
  };
};
