import * as React from "react";

function TrendUp(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M18.2501 9.375L18.2501 9.37656C18.2502 9.40124 18.2429 9.42539 18.2293 9.44597C18.2166 9.46523 18.1987 9.48056 18.1779 9.49028C18.1609 9.49638 18.1431 9.49966 18.1251 9.49998C18.0892 9.49932 18.0547 9.48566 18.0282 9.46156L15.9793 7.40493L15.6255 7.04973L15.2712 7.40456L10.7186 11.9651C10.7184 11.9653 10.7181 11.9655 10.7179 11.9657C10.6931 11.9899 10.6598 12.0035 10.6251 12.0035C10.5904 12.0035 10.5571 11.99 10.5323 11.9658C10.5321 11.9655 10.5319 11.9653 10.5316 11.9651L7.85417 9.27977L7.50035 8.92492L7.14628 9.27953L1.97217 14.4614C1.94612 14.4851 1.91252 14.4987 1.87736 14.4999C1.84126 14.4984 1.80671 14.4851 1.77904 14.462C1.75817 14.4379 1.74658 14.4071 1.74658 14.375C1.74658 14.3403 1.76019 14.3069 1.78447 14.2821C1.78465 14.2819 1.78484 14.2817 1.78503 14.2815L7.40664 8.65993C7.40681 8.65977 7.40698 8.6596 7.40715 8.65943C7.43197 8.63511 7.46534 8.62148 7.5001 8.62148C7.53478 8.62148 7.56807 8.63505 7.59286 8.65925C7.5931 8.65948 7.59333 8.65971 7.59356 8.65993L10.271 11.3452L10.6246 11.6998L10.9787 11.3457L15.0958 7.22855L15.4501 6.87433L15.0952 6.52078L13.0407 4.4741C13.0225 4.45332 13.0102 4.42807 13.0049 4.40092C12.9999 4.37485 13.0016 4.34797 13.0098 4.32282C13.0202 4.30231 13.0357 4.28481 13.055 4.27207C13.0763 4.25794 13.1013 4.25028 13.1268 4.25H18.1251C18.1582 4.25 18.19 4.26317 18.2135 4.28661C18.2369 4.31005 18.2501 4.34185 18.2501 4.375L18.2501 9.375Z"
        stroke="#667085"
      />
    </svg>
  );
}

export default TrendUp;
