import React, { Children, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useStorage from "../components/common/utils/localStorage.util";
import { useUserContext } from "../context/UserContext";

const PublicRoute = ({ component: Component, ...rest }) => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const isStoreSelected = useStorage.getItem("isStoreSelected");

  const isAdmin =
    user?.role === "SUPER_ADMIN" ||
    user?.role === "ACCOUNTING" ||
    user?.role === "ACCOUNT_MANAGER";

  const isPosUser =
    user?.role === "CASHIER" ||
    user?.role === "SALES MANAGER" ||
    user?.role === "SYSTEM MANAGER";

  const isClient = user?.role === "CLIENT";

  const isPosUserOnClient = user?.isPosUser && isPosUser;

  useEffect(() => {
    if (
      !isPosUserOnClient &&
      isPosUser &&
      user?.posUserLoggedinFromEmail &&
      !isStoreSelected
    ) {
      navigate("/pos/stores", { replace: true });
    }
  }, [
    isPosUser,
    isPosUserOnClient,
    isStoreSelected,
    navigate,
    user?.posUserLoggedinFromEmail,
  ]);

  if (user && isClient) {
    if (!user?.isProfileCompleted) {
      <Navigate to="/create-account" replace />;
      return <Component {...rest} />;
    }
    if (user?.isProfileCompleted && !user?.isBusinessDetailCompleted) {
      <Navigate to="/business-profile" replace />;
      return <Component {...rest} />;
    }
    if (
      user?.isProfileCompleted &&
      user?.isBusinessDetailCompleted &&
      (user?.isFreeTrial
        ? user?.freeTrial?.isFreeTrialEnded
        : !user?.isSubscriptionPlanCompleted)
    ) {
      <Navigate to="/set-plan" replace />;
      return <Component {...rest} />;
    }
  }

  if (user) {
    if (
      !isPosUserOnClient &&
      isPosUser &&
      user?.posUserLoggedinFromEmail &&
      !isStoreSelected
    ) {
      return <Navigate to="/pos/stores" replace />;
    }
    if (isPosUser && isStoreSelected) {
      return <Navigate to="/pos/dashboard" replace />;
    }
    if (isPosUserOnClient) {
      return <Navigate to="/dashboard" replace />;
    }

    if (isClient) {
      return <Navigate to="/dashboard" replace />;
    }

    if (isAdmin) {
      return <Navigate to="/admin/dashboard" replace />;
    }
  }

  return <Component {...rest} />;
};

export default PublicRoute;
