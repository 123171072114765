import React from "react";
import { CustomButton, Loader } from "../../../common/components";
import {
  BlueIndicator,
  GreenIndicator,
  WhiteIndicator,
} from "../../../common/Icons";
import { Check } from "../../../common/Icons/fontawesome";
import { useChooseTable } from "./useChooseTable";
import { useTranslation } from "react-i18next";

function ChooseTable({
  closeModal,
  selectedTables,
  setSelectedTables,
}) {
  const { tableStyles, getTableStyle, toggleTableSelection,posTablesListLoading, tables } =
    useChooseTable();

  const handleChooseTable = () => {
    const selected = tables?.filter((table) => table.status === "selected");
    setSelectedTables(selected[0]);
    closeModal();
  };

  const { t } = useTranslation();

  if (posTablesListLoading) {
    return (
      <div className="justify-center items-center flex my-44 ">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-wrap px-16 py-8 gap-4 h-[450px] overflow-auto bg-gray-100 posbackground-image">
        {tables?.map((table) => (
          <div
            key={table.id}
            style={getTableStyle(table)}
            onClick={() => toggleTableSelection(table.id)}
          >
            {table.status === "selected" && <Check color="white" />}
            {table.position}
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between py-1 px-4">
        <div className="flex items-center">
          <WhiteIndicator />
          <span className="text-sm font-normal ml-2 mr-2">
            {t("pos_dashboard.available")}
          </span>
          <GreenIndicator />
          <span className="text-sm font-normal ml-2 mr-2">
            {t("pos_dashboard.reserved")}
          </span>
          <BlueIndicator />
          <span className="text-sm font-normal ml-2 mr-2">
            {t("pos_dashboard.selected")}
          </span>

          <span className="text-sm font-normal ml-32 max-w-[800px] overflow-auto">
            <Check /> {t("pos_dashboard.selected_tables")}{" "}
            {tables
              .filter((table) => table.status === "selected")
              .map((table) => `Table No. ${table.position}`)
              .join(", ") || "None"}
          </span>
        </div>

        <div>
          <CustomButton
            text={t("pos_dashboard.choose_table")}
            width="w-fit"
            padding="py-3 px-4"
            onClick={handleChooseTable}
          />
        </div>
      </div>
    </div>
  );
}

export default ChooseTable;
