import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CurrencySign,
  VerticalBlackCoin,
} from "../../../../common/Icons";
import { useSplitByItem } from "./useSplitByItem";
import ProgressBar from "../../../../common/components/ProgressBar/ProgressBar";
import { CustomButton } from "../../../../common/components";
import { SearchBox } from "../../../../../assets/images/svgs";

function SplitByItem({
  setCheckOutVisible,
  setOrderCheckoutPaid,
  setIsCheckoutModalOpen,
}) {
  const {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    handleSelectItem,
    selectedItems,
    items,
    paymentMethodsList,
    handleCheckout,
    selectedItemsPrice,
    totalAmount,
    isButtonDisabled
  } = useSplitByItem(
    setCheckOutVisible,
    setOrderCheckoutPaid,
    setIsCheckoutModalOpen
  );
  const { t } = useTranslation();
  const getIconComponent = (type) => {
    switch (type) {
      case "CASH":
        return CurrencySign;
      case "CARD":
        return Card;
      case "POINTS":
        return VerticalBlackCoin;
      default:
        return null;
    }
  };

  const paymentMethodOptions = paymentMethodsList
    ?.filter((method) => method.type !== "DELIVERY_COMPANIES")
    ?.map((method) => ({
      method: method.type.toLowerCase(),
      label: t(`pos_dashboard.${method.type.toLowerCase()}`),
      IconComponent: getIconComponent(method.type),
    }));

  // Check if there are valid payment methods available
  const hasValidPaymentMethods =
    paymentMethodOptions && paymentMethodOptions.length > 0;

  return (
    <div className="">
      <div className="flex flex-col">
        <span className="mb-4 text-sm font-medium">
          {t("pos_dashboard.items")}
        </span>
        <div className="max-h-[100px] h-auto overflow-auto">
          {items?.map((item) => (
            <div
              key={item.id}
              className="flex items-center mb-2 border rounded-lg p-2 rtl:gap-2"
            >
              <input
                type="checkbox"
                checked={
                  selectedItems.some((list) => list.id === item.id) ||
                  item.paymentStatus === "paid"
                }
                onChange={() => handleSelectItem(item.id)}
                disabled={item.paymentStatus === "paid"}
                className="form-checkbox h-4 w-4 rounded-sm"
              />
              <span
                className={`ml-5 flex-1 text-text-primary font-medium text-sm ${
                  item.paymentStatus === "paid" ? "text-yellow-500" : ""
                }`}
              >
                {item.name}
                {item.paymentStatus === "paid" && (
                  <span className="text-yellow-500"> (Paid)</span>
                )}
              </span>
              <span
                className="text-text-primary font-medium text-sm"
                style={{
                  textDecoration:
                    item.paymentStatus === "paid" ? "line-through" : "none",
                }}
              >
                {item.price} SAR
              </span>
            </div>
          ))}
        </div>
      </div>
      <p className="my-2 text-sm font-medium">Choose Payment Method</p>
      {/* Check and display payment methods or a fallback message */}
      {hasValidPaymentMethods ? (
        <div>
          <div className="grid grid-cols-2 gap-4 mb-2">
            {paymentMethodOptions?.map((option) => (
              <div
                key={option?.method}
                className={`flex items-center justify-between border p-4 rounded-lg ${
                  selectedPaymentMethod === option.method
                    ? "border-blue-500 bg-blue-100"
                    : "border-gray-300 bg-white"
                }`}
                onClick={() => setSelectedPaymentMethod(option.method)}
              >
                <div className="flex items-center gap-2">
                  <option.IconComponent
                    className={`text-lg ${
                      selectedPaymentMethod === option.method
                        ? "text-blue-600"
                        : "text-text-primary"
                    }`}
                  />
                  <span
                    className={`ml-2 ${
                      selectedPaymentMethod === option.method
                        ? "text-blue-700 font-medium"
                        : "text-text-primary font-medium"
                    }`}
                  >
                    {option.label}
                  </span>
                </div>
                <div
                  className={`h-5 w-5 rounded-full border-2 p-1 ${
                    selectedPaymentMethod === option.method
                      ? "border-blue-500 bg-blue-500"
                      : "border-gray-300"
                  }`}
                >
                  {selectedPaymentMethod === option.method && (
                    <div className="h-2 w-2 bg-white rounded-full"></div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="border p-2 mt-32 rounded-lg">
            <ProgressBar
              current={
                selectedItemsPrice +
                items
                  .filter((item) => item.paymentStatus === "paid")
                  .reduce((acc, item) => acc + parseFloat(item.price), 0)
              }
              total={totalAmount}
              trackColor="bg-gray-300"
              textColor="text-text-primary"
              height="h-1.5"
              rounded="rounded"
              label={t("pos_dashboard.paying")}
              showLabel={true}
              showProgressText={true}
              />
          </div>
          <div className="w-full pt-2">
            <CustomButton
              text={t("pos_dashboard.checkout")}
              type="button"
              onClick={handleCheckout}
              disabled={isButtonDisabled}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center mt-12">
          <img
            src={SearchBox}
            alt="Search Box"
            className="ml-32 w-[50%] h-[150px] rounded-lg"
          />
          <p className="text-center text-lg text-red-500 px-12 pb-12 ">
            {" "}
            Please add payment method firstly to proceed with payments.
          </p>
        </div>
      )}
    </div>
  );
}

export default SplitByItem;
