// Import the 'api' module for making HTTP requests
import api from "../../services/api";

/**
 * Function to perform Point of Sale (POS) user login with email.
 * @param {object} data - User login data including email and password.
 * @returns {Promise} - A promise that resolves with the HTTP response.
 */
const posLogin = async (data) => {
  // Send a POST request to the 'pos-user/signIn-with-email' endpoint with the provided data
  const response = await api().post("pos-user/signIn-with-email", data);
  return response; // Return the HTTP response
};

/**
 * Function to perform store login with a store code.
 * @param {object} data - Store login data including the store code.
 * @returns {Promise} - A promise that resolves with the HTTP response.
 */
const storecode = async (data) => {
  // Send a POST request to the 'stores/signIn-with-storeCode' endpoint with the provided data
  const response = await api().post("stores/signIn-with-storeCode", data);
  return response; // Return the HTTP response
};

/**
 * Function to perform POS user login with a fast code.
 * @param {object} data - User login data including the fast code.
 * @returns {Promise} - A promise that resolves with the HTTP response.
 */
const fastcode = async (data) => {
  // Send a POST request to the 'pos-user/signIn-with-fast-code' endpoint with the provided data
  const response = await api().post("pos-user/signIn-with-fast-code", data);
  return response; // Return the HTTP response
};

const posUpdateProfile = async ({ profileId, payload }) => {
  const response = await api().put(`/pos-user/${String(profileId)}`, payload);
  return response.data;
};

const getPosProfile = async ({ userId }) => {
  const response = await api().get(`/pos-user/${userId}`);
  return response.data;
};


/**
 * Function to perform POS user login with a fast code.
 * @param {object} data - User login data including the fast code.
 * @returns {Promise} - A promise that resolves with the HTTP response.
 */
const deletePosUser = async (id) => {
  const response = await api().delete(`/pos-user/${id}`);
  return response.data;
};
/**
 * Object that encapsulates functions for POS login-related operations.
 * Provides functions for email, store code, and fast code login.
 */
const posLoginService = {
  posLogin, // Function for email login
  storecode, // Function for store code login
  fastcode, // Function for fast code login
  posUpdateProfile,
  getPosProfile,
  deletePosUser,
};

export default posLoginService; // Export the posLoginService object
