import * as React from "react";

function FluentBoxIcon(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M8.65375 2.22264C9.5203 1.88933 10.4797 1.88933 11.3462 2.22264L17.5487 4.60764C17.9024 4.7438 18.2064 4.98389 18.4209 5.29629C18.6353 5.60869 18.75 5.97873 18.75 6.35764V14.6401C18.75 15.0191 18.6353 15.3891 18.4209 15.7015C18.2064 16.0139 17.9024 16.254 17.5487 16.3901L11.3462 18.7764C10.4797 19.1097 9.5203 19.1097 8.65375 18.7764L2.4525 16.3901C2.09866 16.2542 1.79434 16.0142 1.57966 15.7018C1.36499 15.3893 1.25005 15.0192 1.25 14.6401V6.35764C1.25005 5.97858 1.36499 5.60844 1.57966 5.29602C1.79434 4.98361 2.09866 4.7436 2.4525 4.60764L8.65375 2.22264ZM10.8975 3.38889C10.3198 3.16668 9.6802 3.16668 9.1025 3.38889L7.3725 4.05514L14.36 6.70014L16.795 5.65764L10.8975 3.38764V3.38889ZM17.5 6.71389L10.625 9.66264V17.6976C10.7175 17.6739 10.8075 17.6451 10.8975 17.6101L17.1 15.2251C17.2179 15.1797 17.3192 15.0995 17.3906 14.9953C17.462 14.8911 17.5001 14.7677 17.5 14.6414V6.71639V6.71389ZM9.375 17.6964V9.66264L2.5 6.71514V14.6401C2.49987 14.7665 2.53802 14.8899 2.60943 14.9941C2.68083 15.0983 2.78214 15.1784 2.9 15.2239L9.1025 17.6089C9.1925 17.6439 9.2825 17.6726 9.375 17.6964ZM3.205 5.65764L10 8.57014L12.705 7.41014L5.62125 4.72764L3.205 5.65764Z"
        fill={props?.stroke ? "#0086FF" : "#667085"}
      />
    </svg>
  );
}

export default FluentBoxIcon;
