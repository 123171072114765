import * as React from "react";

function CaretUpDown(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1922 13.3078C14.2503 13.3659 14.2964 13.4348 14.3279 13.5107C14.3593 13.5865 14.3755 13.6679 14.3755 13.75C14.3755 13.8321 14.3593 13.9135 14.3279 13.9893C14.2964 14.0652 14.2503 14.1341 14.1922 14.1922L10.4422 17.9422C10.3842 18.0003 10.3152 18.0464 10.2393 18.0779C10.1635 18.1093 10.0821 18.1255 10 18.1255C9.91788 18.1255 9.83655 18.1093 9.76067 18.0779C9.6848 18.0464 9.61587 18.0003 9.55782 17.9422L5.80782 14.1922C5.69055 14.0749 5.62466 13.9159 5.62466 13.75C5.62466 13.5842 5.69055 13.4251 5.80782 13.3078C5.9251 13.1905 6.08416 13.1247 6.25001 13.1247C6.41586 13.1247 6.57492 13.1905 6.6922 13.3078L10 16.6164L13.3078 13.3078C13.3659 13.2497 13.4348 13.2036 13.5107 13.1722C13.5865 13.1407 13.6679 13.1245 13.75 13.1245C13.8321 13.1245 13.9135 13.1407 13.9893 13.1722C14.0652 13.2036 14.1342 13.2497 14.1922 13.3078ZM6.6922 6.69219L10 3.3836L13.3078 6.69219C13.4251 6.80947 13.5842 6.87535 13.75 6.87535C13.9159 6.87535 14.0749 6.80947 14.1922 6.69219C14.3095 6.57492 14.3754 6.41586 14.3754 6.25C14.3754 6.08415 14.3095 5.92509 14.1922 5.80782L10.4422 2.05782C10.3842 1.99971 10.3152 1.95361 10.2393 1.92215C10.1635 1.8907 10.0821 1.87451 10 1.87451C9.91788 1.87451 9.83655 1.8907 9.76067 1.92215C9.6848 1.95361 9.61587 1.99971 9.55782 2.05782L5.80782 5.80782C5.69055 5.92509 5.62466 6.08415 5.62466 6.25C5.62466 6.41586 5.69055 6.57492 5.80782 6.69219C5.9251 6.80947 6.08416 6.87535 6.25001 6.87535C6.41586 6.87535 6.57492 6.80947 6.6922 6.69219Z"
        fill="#667085"
      />
    </svg>
  );
}

export default CaretUpDown;
