import React from "react";
import { CrossIcon } from "../../Icons";
import CustomButton from "../Button/Button";

const getStatusColor = (status) => {
  switch (status) {
    case "Completed":
    case "Success":
    case "Active":
      return "bg-green-200 text-green-800";
    case "On Process":
      return "bg-blue-200 text-blue-800";
    case "Refund":
    case "Return":
      return "bg-orange-200 text-orange-800";
    case "Inactive":
      return "bg-red-200 text-red-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

const CustomAdminModel = ({
  title,
  children,
  isOpen,
  onClose,
  width,
  height,
  showHeader = true,
  subtitleText = { value: "", show: false },
  statusText = { value: "", show: false },
}) => {
  if (!isOpen) return null;

  // Use getStatusColor to determine the class based on statusText.value
  const statusClass =
    statusText.show && statusText.value
      ? getStatusColor(statusText.value.trim())
      : "";

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-40">
      <div
        className={`relative w-full h-full md:h-auto mx-auto my-6 ${width}`}
        style={{ height, overflowY: "auto" }}
      >
        <div className="relative bg-white rounded-lg shadow">
          {showHeader && (
            <div className="flex justify-between items-start px-4 pt-4 pb-2 rounded-t border-b">
              <div className="">
                <h3 className="text-xl font-medium text-gray-900">{title}</h3>
                {subtitleText.show && subtitleText.value && (
                  <p className="text-sm text-gray-500">{subtitleText.value}</p>
                )}
              </div>
              <div className="flex items-center gap-2">
                {statusText.show && statusText.value && (
                  <div
                    className={`px-auto py-auto ${statusClass} inline-block rounded px-2 py-1 text-sm`}
                  >
                    {statusText.value}
                  </div>
                )}
                <CustomButton
                  onClick={onClose}
                  bgColor="bg-transparent"
                  icon={<CrossIcon />}
                  width="w-fit"
                  border="border-none"
                  padding="p-2"
                />
              </div>
            </div>
          )}
          <div >{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomAdminModel;
