import React from "react";

function NoTable() {
  return (
    <div>
      <svg
        width="173"
        height="164"
        viewBox="0 0 173 164"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M91.8547 152.119C133.894 152.119 167.986 118.282 167.986 76.4997C167.986 34.7171 133.894 0.880859 91.8547 0.880859C49.8157 0.880859 15.7231 34.7171 15.7231 76.4997C15.7231 118.282 49.8157 152.119 91.8547 152.119Z"
          fill="#EAEEF9"
        />
        <path
          d="M150.171 66.6318V112.9C150.171 122.513 142.353 130.331 132.612 130.331H51.3539C41.7413 130.331 33.9231 122.641 33.7949 113.028C33.7949 112.9 33.7949 112.9 33.7949 112.772V66.6318C33.7949 66.5036 33.7949 66.5036 33.7949 66.3754C33.7949 66.1191 33.7949 65.8628 33.9231 65.6064C34.0513 65.2219 34.1794 64.9656 34.3076 64.5811L56.0961 22.9266C56.8651 21.2605 58.5313 20.3633 60.3256 20.3633H123.512C125.307 20.3633 126.845 21.2605 127.742 22.9266L149.53 64.5811C149.658 64.8374 149.787 65.2219 149.915 65.6064C150.171 65.8628 150.171 66.2473 150.171 66.6318Z"
          fill="#D5DDEA"
        />
        <g filter="url(#filter0_d_4569_28981)">
          <path
            d="M150.171 66.6308V117.642C150.171 124.691 144.532 130.33 137.354 130.33H46.6117C39.5625 130.33 33.7949 124.691 33.7949 117.642V66.3745C33.7949 66.1181 33.7949 65.8618 33.9231 65.6055H63.1453C67.503 65.6055 71.0917 69.066 71.0917 73.5519C71.0917 75.7307 71.9889 77.7814 73.3987 79.1912C74.9367 80.7292 76.7311 81.4983 79.0381 81.4983H105.056C109.414 81.4983 113.002 78.0377 113.002 73.5519C113.002 71.373 113.9 69.3223 115.31 67.9125C116.848 66.3745 118.642 65.6055 120.821 65.6055H149.915C150.171 65.8618 150.171 66.2463 150.171 66.6308Z"
            fill="url(#paint0_linear_4569_28981)"
          />
        </g>
        <path
          d="M166.041 40.3574C168.943 40.3574 171.295 38.0047 171.295 35.1025C171.295 32.2003 168.943 29.8477 166.041 29.8477C163.138 29.8477 160.786 32.2003 160.786 35.1025C160.786 38.0047 163.138 40.3574 166.041 40.3574Z"
          fill="#EAEEF9"
        />
        <path
          d="M160.786 23.3102C162.768 23.3102 164.375 21.7035 164.375 19.7215C164.375 17.7395 162.768 16.1328 160.786 16.1328C158.804 16.1328 157.197 17.7395 157.197 19.7215C157.197 21.7035 158.804 23.3102 160.786 23.3102Z"
          fill="#EAEEF9"
        />
        <path
          d="M15.4442 35.1012C17.4261 35.1012 19.0329 33.4945 19.0329 31.5125C19.0329 29.5305 17.4261 27.9238 15.4442 27.9238C13.4622 27.9238 11.8555 29.5305 11.8555 31.5125C11.8555 33.4945 13.4622 35.1012 15.4442 35.1012Z"
          fill="#EAEEF9"
        />
        <path
          d="M7.3693 114.052C11.0501 114.052 14.034 111.068 14.034 107.387C14.034 103.707 11.0501 100.723 7.3693 100.723C3.68848 100.723 0.70459 103.707 0.70459 107.387C0.70459 111.068 3.68848 114.052 7.3693 114.052Z"
          fill="#EAEEF9"
        />
        <defs>
          <filter
            id="filter0_d_4569_28981"
            x="11.7949"
            y="54.6055"
            width="160.376"
            height="108.725"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="11" />
            <feGaussianBlur stdDeviation="11" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4569_28981"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4569_28981"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_4569_28981"
            x1="91.9451"
            y1="64.1083"
            x2="91.9451"
            y2="131.028"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FDFEFF" />
            <stop offset="0.9964" stop-color="#ECF0F5" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default NoTable;
