// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clientService from "./client";
import { showToast } from "../../components/common/utils/showToast.util";

/**
 * Initial state structure defining various properties related to client settings processes.
 * Each property represents a specific setting action/status with its associated data, error, success, loading, and message.
 * Properties such as getBusinessDetails, updateBusinessDetails
 * store data, error, success, loading, and message status for corresponding settings actions.
 */
const initialState = {
  getClientProfile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateClientProfile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteClientProfile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleClientProfile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Initiates the get profile detail process for a user.
 * @param {id} profileId - profile id
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const getClientProfile = createAsyncThunk(
  "getClientProfile",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await clientService.getClientProfile(payload);
      if (response.data) {
        successCallBack(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the get profile detail process for a user.
 * @param {id} profileId - profile id
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const getSingleClientProfile = createAsyncThunk(
  "getSingleClientProfile",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ profileId, successCallBack }, thunkAPI) => {
    const response = await clientService.getSingleClientProfile(profileId);
    return successCallBack(response?.data?.data);
  }
);

/**
 * Initiates the update profile detail process for a user.
 * @param {id} profileId - profile id
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const updateClientProfile = createAsyncThunk(
  "updateClientProfile",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ profileId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await clientService.updateClientProfile(
        profileId,
        payload
      );
      successCallBack(response);
      console.log('response :', response);
      showToast("Client Info updated successfully!");
      return response?.data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the update profile detail process for a user.
 * @param {id} profileId - profile id
 * @param {function} successCallBack - Callback function upon successful api response.
 */
export const deleteClientProfile = createAsyncThunk(
  "deleteClientProfile",

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   */
  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ profileId, successCallBack }, thunkAPI) => {
    try {
      const response = await clientService.deleteClientProfile(profileId);
      successCallBack(response);
      showToast("Status Updated Successfully!");
      return response?.data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for client setting related state management.
 * - `name`: Name of the slice (clientSettings)
 * - `initialState`: Initial state defining properties for various client setting actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getBusinessDetails, updateBusinessDetails, etc.).
 */
export const clientSlice = createSlice({
  name: "client",
  initialState,

  reducers: {
    /**
     * Resets the state for the client setting action.
     */
    reset: (state) => {
      state.getClientProfile = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.updateClientProfile = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.deleteClientProfile = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleClientProfile = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getClientProfile action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getClientProfile.pending, (state) => {
        state.getClientProfile.isLoading = true;
        state.getClientProfile.message = "";
        state.getClientProfile.isError = false;
        state.getClientProfile.isSuccess = false;
        state.getClientProfile.data = null;
      })
      /**
       * Updates the state when getClientProfile action is fulfilled/successful.
       * Updates loading and success flags and sets getClientProfile data with the payload.
       */
      .addCase(getClientProfile.fulfilled, (state, action) => {
        state.getClientProfile.isLoading = false;
        state.getClientProfile.isSuccess = true;
        state.getClientProfile.data = action.payload;
      })
      /**
       * Updates the state when getClientProfile action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getClientProfile.rejected, (state, action) => {
        state.getClientProfile.isLoading = false;
        state.getClientProfile.isError = true;
        state.getClientProfile.data = null;
      })
      /**
       * Updates the state while the updateClientProfile action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(updateClientProfile.pending, (state) => {
        state.updateClientProfile.isLoading = true;
        state.updateClientProfile.message = "";
        state.updateClientProfile.isError = false;
        state.updateClientProfile.isSuccess = false;
        state.updateClientProfile.data = null;
      })
      /**
       * Updates the state when updateClientProfile action is fulfilled/successful.
       * Updates loading and success flags and sets updateClientProfile data with the payload.
       */
      .addCase(updateClientProfile.fulfilled, (state, action) => {
        state.updateClientProfile.isLoading = false;
        state.updateClientProfile.isSuccess = true;
        state.updateClientProfile.data = action.payload;
      })
      /**
       * Updates the state when updateClientProfile action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateClientProfile.rejected, (state, action) => {
        state.updateClientProfile.isLoading = false;
        state.updateClientProfile.isError = true;
        state.updateClientProfile.data = null;
      })
      /**
       * Updates the state while the deleteClientProfile action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(deleteClientProfile.pending, (state) => {
        state.deleteClientProfile.isLoading = true;
        state.deleteClientProfile.message = "";
        state.deleteClientProfile.isError = false;
        state.deleteClientProfile.isSuccess = false;
        state.deleteClientProfile.data = null;
      })
      /**
       * Updates the state when deleteClientProfile action is fulfilled/successful.
       * Updates loading and success flags and sets deleteClientProfile data with the payload.
       */
      .addCase(deleteClientProfile.fulfilled, (state, action) => {
        state.deleteClientProfile.isLoading = false;
        state.deleteClientProfile.isSuccess = true;
        state.deleteClientProfile.data = action.payload;
      })
      /**
       * Updates the state when deleteClientProfile action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(deleteClientProfile.rejected, (state, action) => {
        state.deleteClientProfile.isLoading = false;
        state.deleteClientProfile.isError = true;
        state.deleteClientProfile.data = null;
      })

      .addCase(getSingleClientProfile.pending, (state) => {
        state.getSingleClientProfile.isLoading = true;
        state.getSingleClientProfile.message = "";
        state.getSingleClientProfile.isError = false;
        state.getSingleClientProfile.isSuccess = false;
        state.getSingleClientProfile.data = null;
      })
      /**
       * Updates the state when getSingleClientProfile action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleClientProfile data with the payload.
       */
      .addCase(getSingleClientProfile.fulfilled, (state, action) => {
        state.getSingleClientProfile.isLoading = false;
        state.getSingleClientProfile.isSuccess = true;
        state.getSingleClientProfile.data = action.payload;
      })
      /**
       * Updates the state when getSingleClientProfile action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleClientProfile.rejected, (state, action) => {
        state.getSingleClientProfile.isLoading = false;
        state.getSingleClientProfile.isError = true;
        state.getSingleClientProfile.data = null;
      });
  },
});

/**
 * Destructures the reset action from the clientSettingsSlice actions.
 * - `reset`: Action function to reset the client setting related state.
 */

// sample usage

// import { reset } from './yourclientSettingsSlicePath';

// Using the reset action
// const reset = () => {
//   store.dispatch(reset());
// };

export const { reset } = clientSlice.actions;

/**
 * Exports the default reducer generated by clientSettingsSlice.
 * This reducer handles state updates for client settingrelated actions.
 */

// sample usage

// import clientSettingsReducer from "./yourclientSettingsSlicePath";

// Using the default reducer
// const initialState = {
// Your initial state for client setting// };

// Creating a store with the authReducer handling client settingrelated actions
// const store = configureStore({
// reducer: {
// clientSettingsReducer: clientSettingsReducer,
// other reducers...
// },
// preloadedState: {
// auth: initialState,
// other preloaded states...
// },
// });
export default clientSlice.reducer;
