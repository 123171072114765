import * as React from "react";

function ArrowDown(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="CaretDown">
        <path
          id="Vector"
          d="M17.875 8.25L11 15.125L4.125 8.25"
          stroke={props.stroke ? "#ffffff" : "#1A1A1A"}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default ArrowDown;
