import * as React from "react";

function LeftArrow(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow-left">
        <path
          id="Icon"
          d="M15.8334 9.99984H4.16669M4.16669 9.99984L10 15.8332M4.16669 9.99984L10 4.1665"
          stroke="#475467"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default LeftArrow;
