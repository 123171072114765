export function camelToPascalWithSpaces(string) {
  let pascalWithSpaces = "";
  for (let i = 0; i < string.length; i++) {
    if (string[i] === string[i].toUpperCase() && i !== 0) {
      pascalWithSpaces += " " + string[i];
    } else {
      pascalWithSpaces += string[i];
    }
  }
  return pascalWithSpaces.charAt(0).toUpperCase() + pascalWithSpaces.slice(1);
}
