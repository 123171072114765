import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  getProfile,
  updateProfile,
} from "../../../../../../features/Auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../../../common/utils/users.utils";
import {
  singleStaffUser,
  staffUserProfileUpdate,
} from "../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { formatEnum } from "../../../../../../helpers/formatEnum";

function useUpdateProfile(onClose) {
  const dispatch = useDispatch();
  const [adminName, setAdminName] = useState(null);
  const [arabicAdminName, setArabicAdminName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [adminId, setAdminId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const profileSchema = z.object({
    fullName: z
      .string()
      .min(3, "English Name must be at least 3 characters long")
      .max(100, "Maximum 100 characters are allowed!"),
    arabicName: z
      .string()
      .min(3, "Arabic Name must be at least 3 characters long")
      .max(100, "Arabic Name must be no longer than 100 characters"),
    email: z
      .string()
      .min(1, "Email is required!")
      .email("Invalid email format"),
    phone: z
      .string()
      .min(3, "Phone Number must be at least 3 digits long")
      .max(20, "Phone Number should not exceed 20 digits!")
      .regex(
        /^\+\d+$/,
        "Phone Number must start with + and be followed by numeric digits!"
      ),
    admin: z.string().min(1, "Role is required!"),
  });

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: zodResolver(profileSchema),
  });
  // Watch for changes in form values
  const formValues = watch();

  // Function to set form changed status
  useEffect(() => {
    // Check if any field in the form has changed
    const formChanged =
      formValues.fullName !== adminName ||
      formValues.arabicName !== arabicAdminName ||
      formValues.email !== email ||
      formValues.phone !== phone;
    setIsFormChanged(formChanged);
  }, [formValues, adminName, arabicAdminName, email, phone]);

  // Fetch user data from local storage and load the user's profile
  const storedUserData = getUser("user");
  const parsedData = JSON.parse(storedUserData);
  const id = parsedData?.user?.profile?.id;
  const staffIdFromData = parsedData?.user?.id;
  const role = parsedData?.user?.role;  

  useEffect(() => {
    if (role === "SUPER_ADMIN") {
      setAdminId(id);
      fetchUserProfile(id);
    } else {
      setStaffId(staffIdFromData);
      fetchStaffUserProfile(staffIdFromData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, staffIdFromData, role]);

  // Fetch user profile using the API
  const fetchUserProfile = (id) => {
    setIsFetching(true);
    dispatch(getProfile({ payload: { id } }))
      .then((response) => {
        const userData = response.payload.data;
        setAdminName(userData.fullName);
        setArabicAdminName(userData.arabicName);
        setPhone(userData.phoneNumber);
        setEmail(userData.user.email);
        reset({
          fullName: userData.fullName,
          arabicName: userData.arabicName,
          email: userData.user.email,
          phone: userData.phoneNumber,
          admin: "Admin",
        });
        setIsFetching(false);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
        setIsFetching(false);
      });
  };

  // Fetch staff user profile using the API
  const fetchStaffUserProfile = (staffId) => {
    setIsFetching(true);
    dispatch(singleStaffUser({ staffId, successCallBack }));
  };
  const successCallBack = (response) => {
    const userData = response?.data?.data;
    setAdminName(userData.fullName);
    setArabicAdminName(userData.arabicName);
    setPhone(userData.phoneNumber);
    setEmail(userData.email);
    reset({
      fullName: userData.fullName,
      arabicName: userData.arabicName,
      email: userData.email,
      phone: userData.phoneNumber,
      admin: formatEnum(userData.role),
    });
    setIsFetching(false);
  };

  // Form submission handler
  const onSubmit = async (formData) => {
    setIsLoading(true);
    if (role === "SUPER_ADMIN") {
      return dispatch(
        updateProfile({
          payload: { id: adminId, data: formData },
        })
      )
        .then(() => {
          setIsLoading(false);
          onClose();
          window.location.reload();
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      return dispatch(
        staffUserProfileUpdate({
          paylaod: formData,
          staffId: staffId
        })
      )

    }
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    adminName,
    arabicAdminName,
    phone,
    email,
    isFormChanged,
    isLoading,
    isFetching,
  };
}

export default useUpdateProfile;
