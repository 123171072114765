import LoginView from "../../components/Login/LoginView";
import LoginForm from "../../components/Login/LoginForm";

/**
 * Login component.
 * Renders the login view and form for users to log in.
 *
 * @returns {JSX.Element} JSX code for rendering the Login component.
 */

const Login = () => {
  return (
    <LoginView>
      <LoginForm userType="client" />
    </LoginView>
  );
};

export default Login;

// Sample Usage:
// import Login from '.path/Login';
// <Login />
